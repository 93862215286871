<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div class="card__top">
  <div class="card__main">

    <button mat-icon-button class="remove-btn" *ngIf="type === 'EDIT'"
            [title]="'translations.global.button.remove' | transloco"
            [hidden]="isPublic"
            (click)="onRemoveClick($event)">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>

    <div class="item icon-top icon-top" title="Completed" *ngIf="completed">
      <mat-icon aria-hidden="true" class="mat__icon-check">
        check_circle_outline
      </mat-icon>
    </div>
    <div class="item icon" [class.icon--slim]="size === 'SLIM'">
      <mat-icon
        aria-hidden="true"
        class="mat__icon"
        *ngIf="!authorDetails?.displayConfig.organizationName && !authorDetails?.displayConfig.authorName">
        insert_drive_file_outline
      </mat-icon>
      <ng-container *ngIf="authorDetails?.displayConfig.organizationName || authorDetails?.displayConfig.authorName">
        <img
          class="item__author-image"
          alt="" width="50" height="50"
          [src]="authorDetails.displayConfig.organizationName ? getOrgImageUrl() : authorDetails.userImageUrl"
          *ngIf="(authorDetails.userImageUrl && authorDetails.displayConfig.authorName) ||
                 authorDetails.displayConfig.organizationName">
        <div
          [title]="authorDetails.name"
          class="item__author-initials"
          *ngIf="!authorDetails.userImageUrl &&
                  authorDetails.displayConfig.authorName &&
                  !authorDetails.displayConfig.organizationName">
          <p>{{ authorDetails.name.charAt(0) }}</p>
        </div>
      </ng-container>
    </div>
    <div class="card__info-box item">
      <div class="card__header-box">
        <span class="card__header" [title]="title" #titleElement>{{ title }}</span>
      </div>
      <p class="card__description" [title]="description">
        {{ description }}
      </p>
    </div>
  </div>

  <ptl-card-progress
    *ngIf="completedItemsCount !== undefined && totalItemsCount !== undefined"
    class="playlist-progress"
    [class.playlist-progress-floating]="favorite === true || favorite === false || type === 'EDIT'"
    [started]="started"
    [completed]="completed"
    [completedCount]="completedItemsCount"
    [totalCount]="totalItemsCount"
    [hidden]="isPublic"
  >
  </ptl-card-progress>

  <ptl-card-footer [isAdmin]="isAdmin"
                   [hidden]="isPublic"
                   class="card__footer"
                   (cloneClicked)="onDuplicateClick()"
                   (membersClicked)="onMembersClick()"
                   (editClicked)="onEditClick()"
                   [canViewMembers]="permission?.canViewAnalytics"
                   [canEdit]="permission?.canEdit"
                   [organizationDomain]="(organizationData$ | async)?.domain"
                   [uid]="playlistUid"
                   [title]="title"
                   [status]="status"
                   [syndicated]="syndicated"
                   [likeDetails]="likeInfoDetails"
                   [infoType]="resourceType"
                   [displayedType]="resourceType"
                   [editMode]="inHomeLayoutEditor"
                   [canClone]="treatAsPlaylistSectionCard || permission?.canClone && type === 'EDIT'">

  </ptl-card-footer>
</div>
