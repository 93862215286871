import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '../../../../../../shared/services/translation/translation.service';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';

@Component({
  templateUrl: './check-in-dialog.component.html',
  styleUrls: ['./check-in-dialog.component.scss'],
})
export class CheckInDialogComponent {

  @ViewChild('checkInData') checkInData: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  title: string;
  url: string;
  startDate: Date;
  endDate: Date;
  filename = 'qr-code.png';

  constructor(
    private translationService: TranslationService,
    private dialogRef: MatDialogRef<CheckInDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { eventTitle: string; eventUrl: string; eventStartDate?: Date; eventEndDate?: Date}
  ) {
    if (this.data) {
      this.title = data.eventTitle;
      this.startDate = data.eventStartDate;
      if (data.eventEndDate && !this.isSameDay(data.eventEndDate, data.eventStartDate)) {
        this.endDate = data.eventEndDate;
      }
      this.url = data.eventUrl
    }
    if (this.title) {
      this.filename = this.title.replace(' ', '-') + '-' + this.filename;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  downloadQRCode(): void {
    html2canvas(this.checkInData.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = this.filename;
      this.downloadLink.nativeElement.click();
    })
  }

  private isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }
}
