/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'ptl-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  isLoading: boolean;

  constructor(private loadingService: LoaderService) {}

  ngOnInit(): void {
    this.loadingService.isLoading$.subscribe((value) => {
      this.isLoading = value;
    });
  }
}
