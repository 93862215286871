<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  [attr.role]="!hidePreview ? 'heading' : null"
  [attr.aria-level]="!hidePreview ? '2' : null"
  [class.new-version]="newVersionEnabled"
  class="preview-element
    preview-element-type-{{ content?.type }}
    preview-element-position-{{ content?.position || 'CENTER' }}"
  #htmlEl>
</div>

