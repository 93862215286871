/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
	DiagnosticsRetake,
	EditorContent,
	EditorJsContent,
	EditorJsDeletedContent,
	EditorJsResource,
	ExternalContentSummary,
	Resource,
	ResourceApprovalMethod,
	ResourceCardType,
	ResourceTag
} from '../../../../shared/models';
import { ReviewerDetails } from '../../../playlist/models';
import { SupportedLanguage } from '../../../../shared/models/languages/languages.model';
import {
	EventCardTime,
	EventCardTypeData,
	EventReminder,
	EventTickets
} from './resource-event-admin.state.model';

export class LoadResourceDetails {
	static readonly type = '[Resource admin] Load Resource Details';

	constructor(
		public isProjectResource: boolean,
		public playlistUri: string,
		public resourceUri: string,
		public groupUri: string,
		public publisherUri: string,
		public packageUri: string,
		public pageUri: string,
		public languageCode?: string
	) {
	}
}

export class SetEventType {
	static readonly type = '[Resource admin] Set event type';

	constructor( public type: EventCardTypeData ) {
	}
}


export class SetEventTime {
	static readonly type = '[Resource admin] Set event time';

	constructor( public time: EventCardTime ) {
	}
}


export class SetEventReminders {
	static readonly type = '[Resource admin] Set event reminders';

	constructor( public reminders: EventReminder[] ) {
	}
}


export class SetEventTickets {
	static readonly type = '[Resource admin] Set event tickets';

	constructor( public tickets: EventTickets ) {
	}
}


export class SetContentThumbnail {
	static readonly type = '[Resource edit] Set thumbnail';

	constructor( public thumbnail: string ) {
	}
}

export class UpdateHeadline {
	static readonly type = '[Resource admin] Update headline';

	constructor( public headline: string ) {
	}
}

export class ChangeAutoSaveStatus {
	static readonly type = '[Resource admin] change auto save status';

	constructor( public status: boolean ) {
	}
}

export class UpdateTimeRequired {
	static readonly type = '[Resource admin] Update time required';

	constructor( public timeRequired: number ) {
	}
}

export class DeleteResource {
	static readonly type = '[Resource admin] Delete resource';

	constructor( public resourceUid: string ) {
	}
}

export class FilterTags {
	static readonly type = '[Resource admin] Filter tags';

	constructor( public text: string ) {
	}
}

export class AssociateTag {
	static readonly type = '[Resource admin] Associate tag';

	constructor( public resourceId: string, public title: string ) {
	}
}

export class AssociateTagsBulk {
	static readonly type = '[Resource admin] Associate tags bulk';

	constructor( public resourceId: string, public tags: ResourceTag[] ) {
	}
}

export class DissociateTag {
	static readonly type = '[Resource admin] Dissociate tag';

	constructor( public resourceId: string, public tag: ResourceTag ) {
	}
}

export class UpdateApprovalMethod {
	static readonly type = '[Resource admin] Update approval method';

	constructor( public resourceId: string, public request: ResourceApprovalMethod ) {
	}
}

export class AssociateReviewer {
	static readonly type = '[Resource admin] Associate reviewer';

	constructor( public resourceId: string, public reviewer: ReviewerDetails ) {
	}
}

export class DissociateReviewer {
	static readonly type = '[Resource admin] Dissociate reviewer';

	constructor( public resourceId: string, public reviewer: ReviewerDetails ) {
	}
}

export class PublishCard {
	static readonly type = '[Resource admin] Publish card';

	constructor( public resourceId: string ) {
	}
}

export class UnPublishCard {
	static readonly type = '[Resource admin] Unpublish card';

	constructor( public resourceId: string ) {
	}
}

export class UpdateSubheader {
	static readonly type = '[Resource admin] Update subheader';

	constructor( public newSubheader: string ) {
	}
}

export class UpdateUri {
	static readonly type = '[Resource admin] Update URI';

	constructor( public resourceId: string, public newUri: string ) {
	}
}


export class SaveUpdates {
	static readonly type = '[Resource admin] Save resource Updates';

	constructor( public groupUri?: string, public preventRedirection?: boolean ) {
	}
}


export class RedirectToPlaylistOrResourcePage {
	static readonly type = '[Resource admin] Navigate to playlist or resource page';

	constructor( public resourceUri?: string, public groupUri?: string, public cardType?: ResourceCardType ) {
	}
}

export class SetResourceCurrentLanguage {
	static readonly type = '[Resource admin] Set current language';

	constructor( public supportedLanguage: SupportedLanguage, public index: number ) {
	}
}

export class ResetResourceCurrentLanguage {
	static readonly type = '[Resource admin] reset current language';

	constructor(
		public isProjectResource: boolean,
		public playlistUri: string,
		public resourceUri: string,
		public groupUri: string,
		public publisherUri: string,
		public packageUri: string,
		public pageUri: string,
		public activeLanguageCode?: string
	) {
	}
}

export class ResetResourceAdminState {
	static readonly type = '[Resource admin] Reset state';
}

export class SaveSection {
	static readonly type = '[Resource admin] saving section';

	constructor(
		public sectionUid: string,
		public resource: Resource,
		public languageCode: string
	) {
	}
}

export class SetAutoSaveInProgress {
	static readonly type = '[Resource admin] enable auto save';

	constructor( public autosave: boolean ) {
	}
}

export class SaveBlock {
	static readonly type = '[Resource admin] saving block';

	constructor(
		public cardUid: string,
		public sectionUid: string,
		public resource: EditorJsResource,
		public languageCode: string
	) {
	}
}

export class UpdateBlock {
	static readonly type = '[Resource admin] updating block';

	constructor(
		public cardUid: string,
		public sectionUid: string,
		public blockUid: string,
		public resource, // TODO #Hrant should declare type
		public languageCode: string
	) {
	}
}

export class DeleteBlock {
	static readonly type = '[Resource admin] deleting block';

	constructor(
		public cardUid: string,
		public sectionUid: string,
		public blockUid: string,
		public withoutStoreUpdate: boolean,
		public resource: EditorJsDeletedContent,
		public languageCode: string
	) {
	}
}

export class InitAutoSaveSections {
	static readonly type = '[Resource admin] Init auto save sections on update';

	constructor(
		public sectionUid: string,
		public resource: Resource,
		public languageCode: string
	) {
	}
}

export class SetWebLinkContent {
	static readonly type = '[Resource admin] Set weblink content';

	constructor( public content: ExternalContentSummary ) {
	}
}

export class CreateEmptySection {
	static readonly type = '[Resource admin] Create additional empty section';
}

export class DeleteExistingSection {
	static readonly type = '[Resource admin] Delete existing additional section';

	constructor( public sectionUid: string ) {
	}
}

export class AddDynamicContentToExistingSection {
	static readonly type = '[Resource admin] Add dynamic content to existing section';

	constructor(
		public sectionUid: string,
		public content: EditorContent,
		public languageCode: string
	) {
	}
}

export class UpdateDynamicContentOfExistingSection {
	static readonly type = '[Resource admin] Update dynamic content of existing section';

	constructor(
		public sectionUid: string,
		public contentIndex: number,
		public updatedContent: EditorContent,
		public oldContent: EditorContent,
		public languageCode: string
	) {
	}
}

export class RemoveDynamicContentFromExistingSection {
	static readonly type = '[Resource admin] Remove dynamic content from existing section';

	static newContent(
		sectionUid: string,
		languageCode: string,
		contentIndex: number
	) {
		return new RemoveDynamicContentFromExistingSection(sectionUid, languageCode, contentIndex);
	}

	static existingContent(
		sectionUid: string,
		languageCode: string,
		contentUid: string
	) {
		return new RemoveDynamicContentFromExistingSection(sectionUid, languageCode, undefined, contentUid);
	}

	constructor(
		public sectionUid: string,
		public languageCode: string,
		public dynamicContentIndex: number,
		public dynamicContentUid?: string
	) {
	}
}

export class RemoveAllContentFromExistingSection {
	static readonly type = '[Resource admin] Remove all content from existing section';

	constructor(
		public sectionUid: string,
		public languageCode: string
	) {
	}
}

export class AddDynamicContentToMainSection {
	static readonly type = '[Resource admin] Add dynamic content to main section';

	constructor(
		public content: EditorContent,
		public languageCode: string
	) {
	}
}

export class AddDynamicBlockToMainSection {
	static readonly type = '[Resource admin] Add dynamic block to main section';

	constructor(
		public content: EditorJsContent,
		public languageCode: string
	) {
	}
}

export class AddDynamicBlockContentToMainSection {
	static readonly type = '[Resource admin] Add dynamic block content to main section';

	constructor(
		public content: EditorContent,
		public languageCode: string
	) {
	}
}

export class UpdateDynamicBlockOfMainSection {
	static readonly type = '[Resource admin] Update dynamic block of main section';

	constructor(
		public index: number,
		public content: EditorContent,
		public languageCode?: string
	) {
	}
}

export class UpdateDynamicContentOfMainSection {
	static readonly type = '[Resource admin] Update dynamic content of main section';

	constructor(
		public contentIndex: number,
		public updatedContent: EditorContent,
		public oldContent: EditorContent,
		public languageCode?: string
	) {
	}
}

export class RemoveDynamicContentFromMainSection {
	static readonly type = '[Resource admin] Remove dynamic content from main section';

	constructor(
		public contentIndex: number,
		public languageCode: string
	) {
	}
}

export class RemoveDynamicBlockFromMainSection {
	static readonly type = '[Resource admin] Remove dynamic block content from main section';

	constructor(
		public index: number,
		public blockUid: string,
		public withoutStoreUpdate: boolean,
		public languageCode: string
	) {
	}
}

export class RemoveAllContentFromMainSection {
	static readonly type = '[Resource admin] Remove all content from main section';

	constructor( public languageCode: string ) {
	}
}

export class ClearAutoSaveInterval {
	static readonly type = '[Resource admin] Clear auto save interval';

}

export class UpdateDiagnosticsRetake {
	static readonly type = '[Resource admin] Update diagnostics retake';

	constructor( public request: DiagnosticsRetake ) {
	}
}

export class RandomizeQuestions {
	static readonly type = '[Resource admin] Update randomize question';

	constructor( public randomize: boolean ) {
	}
}
