<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->
<mat-form-field class="user-search-input-field" appearance="outline">
  <mat-icon matPrefix>search</mat-icon>
  <input
    matInput
    [attr.aria-label]="('translations.reviews.label.searchByName' | transloco)"
    placeholder="{{ 'translations.reviews.label.searchByName' | transloco }}"
    (paste)="onTextPaste($event)"
    (keyup)="onSearchInputChange($event)">
</mat-form-field>
