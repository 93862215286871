<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="moment-tags">
  <div class="moment-tags__main">
    <div class="moment-tags__main-content">
      <div class="moment-tags__tags">
        <div class="moment-tags__tags-item has-action-btn" *ngFor="let tag of tags; let i = index">
          <div class="bold">{{ tag?.title }}</div>
          <span
            role="button"
            class="moment-tags__tags-item-remove"
            matTooltip="Remove tag"
            [attr.aria-label]="('translations.global.ariaLabels.removeTag' | transloco)"
            (click)="deleteTag(i)"
            (keydown.enter)="deleteTag(i)"
            tabindex="0">
            <mat-icon class="chip-item-icon" aria-hidden="true" [inline]="true">clear</mat-icon>
          </span>
        </div>
        <div>
          <div
            class="moment-tags__framework-container"
            *ngIf="!addingTag"
            role="button"
            (click)="$event.stopPropagation(); addingTag = true"
            (keydown.enter)="$event.stopPropagation(); addingTag = true"
            [attr.aria-label]="('translations.global.ariaLabels.addTag' | transloco)"
            tabindex="0">
            <span class="moment-tags__framework-btn">{{ 'translations.folio.button.addTag' | transloco }}</span>
          </div>
          <div class="moment-tags__framework-container" *ngIf="addingTag">
            <span>{{ 'translations.folio.label.addTag.from' | transloco }}</span>
            <span
              role="button"
              class="moment-tags__framework-btn"
              [ngClass]="{'is_disabled': isFrameworksEmpty}"
              [attr.aria-label]="('translations.global.ariaLabels.addTagsFromFramework' | transloco)"
              (click)="onFrameworkBtnClick()"
              (keydown.enter)="onFrameworkBtnClick()"
              tabindex="0">
              <span> {{ 'translations.framework.button.framework' | transloco }} </span>
            </span>
            <span> {{ 'translations.folio.label.addTag.or' | transloco }} </span>
            <span role="button"
                  [attr.aria-label]="('translations.global.ariaLabels.addNewTags' | transloco)"
                  *ngIf="!addingNewTag" class="moment-tags__framework-btn"
                  (click)="onNewTagBtnClick()" (keydown.enter)="onNewTagBtnClick()" tabindex="0">
              <span> {{ 'translations.folio.button.newTag' | transloco }} </span>
            </span>
          </div>
          <form
            *ngIf="addingNewTag"
            class="moment-tags__form"
            [formGroup]="tagForm"
            (ngSubmit)="submitTag()"
            [class.is_disabled]="tags?.length && tags?.length >= 5"
            #tagFormElement>
            <mat-form-field appearance="outline" class="section-tags__add-tag-input">
              <input
                matInput
                type="text"
                required
                name="tagName"
                placeholder="Add new tags"
                [attr.aria-label]="('translations.global.ariaLabels.addNewTags' | transloco)"
                formControlName="tagName"
                [matAutocomplete]="auto"
                (blur)="onBlurTagInput()"
                #tagInputElement>
              <mat-autocomplete
                [panelWidth]="450"
                #auto="matAutocomplete"
                [displayWith]="disabledAutocomplete"
                (optionSelected)="onTagSelect($event)">
                <mat-option *ngFor="let tag of resourceTags" [value]="tag.title">
                  {{tag.title}}
                </mat-option>
              </mat-autocomplete>
              <mat-spinner *ngIf="loadingTags" [diameter]="14"></mat-spinner>
              <mat-error *ngIf="tagForm && tagForm.controls.tagName.hasError('required')">
                {{ 'translations.nameRequired' | transloco }}
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
