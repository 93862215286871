/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, HostListener, Inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { Observable, Subscription } from 'rxjs';
import { Organization } from '../../models/organization';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../../page-modules/account/services/account-data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddWorkspacesComponent } from '../../../page-modules/account/components/add-workspace/add-workspaces.component';
import { UserDetails } from '../../../user-auth/models';
import { filter } from 'rxjs/operators';
import { AUTH_SERVICE, BasicAuthService } from '../../../user-auth/services/basic-auth.service';
import { CookieHelper } from '../../helpers/cookie-helper';
import { SnackbarHelper } from '../../helpers/snackbar-helper';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ptl-workspaces-sidebar',
  templateUrl: './workspaces-sidebar.component.html',
  styleUrls: ['./workspaces-sidebar.component.scss'],
})
export class WorkspacesSidebarComponent implements OnInit, OnDestroy {

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(UserAuthState.userDetailsData)
  private userDetailsData$: Observable<UserDetails>;

  domain: string;
  expanded: boolean;
  organization: Organization;
  userData: UserDetails;
  showSideBar: boolean;

  @Input() placeExpandButtonLower: boolean;

  private organizationDataSubscription: Subscription;
  private userDataSubscription: Subscription;

  constructor(
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    @Inject(AUTH_SERVICE) private authService: BasicAuthService,
    public dialogRef: MatDialog,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
  ) {
    this.organizationDataSubscription = this.organizationData$
      .pipe(filter(data => !!data))
      .subscribe(data => {
        this.organization = data;
        this.domain = data.domain;
      });

    this.userDataSubscription = this.userDetailsData$.pipe(
    ).subscribe(data => {
      this.userData = data;
    });
  }

  ngOnInit() {
    this.showSideBar = this.canShowSidebar();
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
    this.userDataSubscription?.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const target = event.target as Element;
    if (!target.closest('.workspaces-sidebar')) {
      this.collapseSidebar();
    }
  }

  expandWorkspaceSidebar() {
    this.expanded = !this.expanded;
    this.updateSidebarPosition(this.expanded);
  }

  addNewWorkspace() {
    this.dialogRef.open(AddWorkspacesComponent, this.getDialogConfigs());
    this.collapseSidebar();
  }

  loadWorkspace(domain: string, organizationUid: string) {
    if (domain === this.domain) {
      return;
    }
    this.authService.finalizeFolioLogin(organizationUid, false, '').subscribe(({ isSuccess, value, error }) => {
      if (isSuccess) {
        CookieHelper.SetCookie('FolioAccessToken', value.accessToken, 2);
        this.collapseSidebar();
        location.href = 'https://' + domain;
      } else if (error) {
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
      }
    });
  }

  private canShowSidebar() {
    const workspaces = this.userData ? this.userData.workspaces ? this.userData.workspaces : [] : [];
    if (!workspaces.length) {
      return false;
    }
    if (workspaces.length > 1) {
      return !!workspaces.find(workspace => workspace.type !== 'FOLIO') ;
    }
    return false;
  }

  private collapseSidebar() {
    this.expanded = false;
    this.updateSidebarPosition(this.expanded);
  }

  private getDialogConfigs(): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '100%';
    dialogConfig.maxWidth = '56.25rem';
    dialogConfig.maxHeight = '90vh';

    return dialogConfig;
  }

  private updateSidebarPosition(expanded: boolean) {
    const sidebar = document.querySelector('mat-sidenav') as HTMLElement;
    const offset = expanded ? '3.75rem' : '';
    if (sidebar) {
      sidebar.style.marginLeft = offset;
    }
  }

}
