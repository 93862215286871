/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { Page } from 'src/app/shared/models/page';
import { FrameworkTrackerSectionTag, SectionReorder } from 'src/app/shared/models/page/page.model';
import { ObservableResult } from 'src/app/shared/store';
import { JobEntity } from '../components/view/shared/jobs-section/jobs.model';
import { Moment } from '../models/moment.model';
import {
  AchievementCertificateBlock,
  FolioBadges,
  FolioCoverUpdateRequest,
  FolioModel,
  FolioMyActivity,
  FolioSection,
  FolioSectionItemRequest,
  FolioSectionRequest,
  FolioShowcaseRequest,
  PublicFolioBadge
} from '../store/folio.state.model';
import { FolioProject } from '../../project/models/project.model';

export const FOLIO_DATA_SERVICE =
  new InjectionToken<FolioDataService>('[Folio] FolioDataService');


export interface FolioDataService {

  getUserFolio(languageCode?: string): ObservableResult<FolioModel>;

  updateUserFolioShowcase(request: FolioShowcaseRequest): ObservableResult<void>;

  updateUserFolioCoverImage(request: FolioCoverUpdateRequest): ObservableResult<{ uploadUrl: string }>;

  deleteUserFolioCoverImage(): ObservableResult<void>;

  reorderFolioSections(request: SectionReorder): ObservableResult<void>;

  updateFolioSection(sectionUid: string, request: FolioSectionRequest, languageCode?: string): ObservableResult<FolioSection>;

  enableFolioSectionItemVisibility(sectionUid: string, request: FolioSectionItemRequest): ObservableResult<void>;

  disableFolioSectionItemVisibility(sectionUid: string, request: FolioSectionItemRequest): ObservableResult<void>;

  deleteCertificate(uid: string): ObservableResult<void>;

  addMomentToFolioSection(sectionUid: string, momentUid: string): ObservableResult<void>;

  addTagToFolioSection(sectionUid: string, tagUid: string): ObservableResult<void>;

  removeMomentFromFolioSection(sectionUid: string, momentUid: string): ObservableResult<void>;

  removeTagFromFolioSection(sectionUid: string, tagUid: string): ObservableResult<void>;

  getUserFolioProjects(folioPublicId: string, page: number, size: number): ObservableResult<Page<FolioProject>>;

  getFolioProjects(page: number, size: number): ObservableResult<Page<FolioProject>>;

  getFolioMoments(sectionUid: string, page: number, size: number): ObservableResult<Page<Moment>>;

  getFolioBadges(sectionUid: string, page: number, size: number): ObservableResult<FolioBadges[]>;

  getFolioActivities(
    page: number,
    size: number,
    order: 'ASC' | 'DESC',
    dataRange: string,
    tags: string[] | null
  ): ObservableResult<Page<FolioMyActivity>>;

  getFolioSkillsActivities(
    page: number,
    size: number,
    tagId: string
  ): ObservableResult<Page<FolioMyActivity>>;

  getFolioGrowths(sectionUid: string, page: number, size: number): ObservableResult<FrameworkTrackerSectionTag[]>;

  getPublicUserFolio(publicId: string, languageCode?: string): ObservableResult<FolioModel>;

  getPublicFolioGrowths(publicId: string, sectionUid: string, page: number, size: number): ObservableResult<FrameworkTrackerSectionTag[]>;

  getPublicFolioMoments(publicId: string, sectionUid: string, page: number, size: number): ObservableResult<Page<Moment>>;

  getPublicFolioProjects(publicId: string, page: number, size: number): ObservableResult<Page<FolioProject>>;

  getPublicFolioBadges(publicId: string, sectionUid: string, page: number, size: number): ObservableResult<FolioBadges[]>;

  getPublicFolioCertificates(publicId: string, sectionUid: string, page: number, size: number):
    ObservableResult<Page<AchievementCertificateBlock>>;

  getFolioUserPublicBadge(userBadgeId: string): ObservableResult<PublicFolioBadge>;

  getFolioJobs(page: number, languageCode?: string): ObservableResult<JobEntity[]>;
}
