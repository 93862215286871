<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<form class="add-question__section" [formGroup]="question">
  <div class="add-question__section__grid">
    <div formArrayName="options">
      <div class="add-question__section-table">
        <div class="add-question__section-column add-question__section-column--grid">
          <h2
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.chooseOptions' | transloco">
            {{ 'translations.chooseOptions' | transloco }}</h2>
        </div>
        <div class="add-question__section-column add-question__section-column--narrow">
          <h2
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.score' | transloco">
            {{ 'translations.score' | transloco }}</h2>
        </div>
      </div>
      <div
        cdkDropList
        [cdkDropListData]="question.controls['options']['controls']"
        (cdkDropListDropped)="dropOption($event)">
        <div
          cdkDrag
          cdkDragLockAxis="y"
          *ngFor="let item of question.controls['options']['controls']; let i = index"
          [formGroupName]="i"
          [cdkDragData]="item">

          <div class="add-question__section-table" [class.add-question-section-table-new]="newVersionEnabled">
            <div
              cdkDragHandle
              [class.is_disabled]="!isDefaultLanguage"
              class="add-question__section-column add-question__section-column--icons add-question__section-column-icon-grid add-question__section-column__hoverable">
              <i class="material-icons" aria-hidden="true" *ngIf="!newVersionEnabled">unfold_more</i>

              <svg class="svg svg-icon" *ngIf="newVersionEnabled" [attr.aria-label]="('translations.global.ariaLabels.icons.draggable' | transloco)">
                <use ogSvgUnify="assets/sprite/sprite.svg#drag-indicator"></use>
              </svg>
            </div>
            <div class="add-question__section-column add-question__section-column--width" formGroupName="value">
              <mat-form-field class="add-question__section-field no-padding color-gray" appearance="outline" [class.small-field]="newVersionEnabled">
                <input
                  matInput
                  type="text"
                  [attr.aria-label]="('translations.global.ariaLabels.enterOptionTitle' | transloco)"
                  formControlName="value">
                <mat-error *ngIf="item.controls['value'].controls['value'].hasError('required')">
                  {{ 'translations.valueRequiredError' | transloco }}
                </mat-error>
                <mat-error *ngIf="item.controls['value'].controls['value'].hasError('maxlength')">
                  {{ 'translations.maxLengthError' | transloco }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="add-question__section-column" [class.is_disabled]="!isDefaultLanguage">
              <mat-form-field class="add-question__section-field no-padding color-gray" appearance="outline" [class.small-field]="newVersionEnabled">
                <input
                  matInput
                  type="number"
                  min="0"
                  [attr.aria-label]="('translations.global.ariaLabels.enterOptionScore' | transloco)"
                  pattern="^\d+$"
                  formControlName="score">
              </mat-form-field>
            </div>
            <span #removeCategory class="cdk-visually-hidden"
                  tabindex="0"
                  [attr.aria-label]="'translations.global.ariaLabels.removeOption' | transloco">
            {{ 'translations.removeOption' | transloco }}</span>
            <button
              type="button"
              [class.is_disabled]="!isDefaultLanguage"
              class="add-question__section-column add-question__section-column-remove add-question__section-column-remove-grid"
              *ngIf="i > 0"
              [title]="removeCategory.innerHTML"
              [attr.aria-label]="('translations.global.ariaLabels.removeOption' | transloco)"
              (click)="removeOption(i)">
              <mat-icon aria-hidden="true" [inline]="true" *ngIf="!newVersionEnabled">highlight_off</mat-icon>
              <svg class="svg svg-icon" *ngIf="newVersionEnabled" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
              </svg>
            </button>
          </div>

          <ng-container *ngIf="item.controls['instructions'] && newVersionEnabled">
            <ptl-question-instruction
              [content]="item.controls['instructions'].value"
              (contentRemoval)="onContentRemoval($event, item.controls['instructions'].value, i)"
              (contentUpdate)="onContentUpdate($event, item.controls['instructions'].value, i)">
            </ptl-question-instruction>
          </ng-container>
        </div>
      </div>

      <button
        *ngIf="!newVersionEnabled && question.value.options.length < 7"
        mat-button
        [class.is_disabled]="!isDefaultLanguage"
        class="add-question__section-add-new"
        type="button"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.clickToAddOption' | transloco"
        (click)="addNewOption()">
        <i class="material-icons" aria-hidden="true">add_circle_outline</i>
        <span>{{ 'translations.clickToAddOption' | transloco }}</span>
      </button>
      <button
        *ngIf="newVersionEnabled && question.value.options.length < 7"
        mat-button
        [class.is_disabled]="!isDefaultLanguage"
        class="add-new-question-btn"
        type="button"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.clickToAddOption' | transloco"
        (click)="addNewOption()">
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
        </svg>
        <span>{{ 'translations.clickToAddOption' | transloco }}</span>
      </button>
    </div>
  </div>
</form>
