/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface LikeInfoDetailsMessage {
  infoType: 'PLAYLIST' | 'CARD' | 'NONE';
  vote: boolean;
  totalLikes: number;
  uid: string;
}

@Injectable()
export class LikeInfoSubscriptionService {
  private messageSource = new BehaviorSubject<LikeInfoDetailsMessage>({
    infoType: 'NONE',
    vote: false,
    totalLikes: 0,
    uid: '',
  });

  getCurrentMessage(): Observable<LikeInfoDetailsMessage> {
    return this.messageSource.asObservable();
  }

  sendMessage(message: LikeInfoDetailsMessage) {
    this.messageSource.next(message);
  }
}
