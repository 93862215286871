import { InjectionToken } from '@angular/core';
import { ObservableResult } from '../../store';
import { HierarchicalList } from '../../models';

export const GROUP_LIST_DATA_SERVICE =
  new InjectionToken<GroupListDataService>('[group-list] groupListDataService');

/** The service handling group list CRUD operations. */
export interface GroupListDataService {

  createGroupList(title: string): ObservableResult<HierarchicalList>;

  addGroupListElement(listUid: string, parentUid: string, groupUid: string): ObservableResult<HierarchicalList>;

  removeGroupListElement(listUid: string, groupUid: string): ObservableResult<void>;

  uploadCsv(file: File): ObservableResult<HierarchicalList>;
}
