<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="currentEvent">
  <div class="review-status" *transloco
    [class.small-text-style]="textStyle === 'SMALL'"
    [class.medium-bold-text-style]="textStyle === 'MEDIUM_BOLD'"
    [class.large-bold-text-style]="textStyle === 'LARGE_BOLD'"
    [class.background-indicator]="backgroundIndicator"
    [ngClass]="{
      awaitsReview: currentEvent === ReviewEventType.SUBMITTED_FOR_REVIEW  || currentEvent === ReviewEventType.RESUBMITTED || currentEvent === ReviewEventType.REOPENED,
      approved: currentEvent === ReviewEventType.ACCEPTED,
      rejected: currentEvent === ReviewEventType.REJECTED_TO_REVIEW,
      changesRequest: currentEvent === ReviewEventType.REQUIRES_RESUBMISSION,
      closed: currentEvent === ReviewEventType.CLOSED,
      reOpened: currentEvent === ReviewEventType.REOPENED
    }">
    <ng-container *ngIf="prefixDate !== null; else noDateTemplate">
      <div
        class="review-status_updatedOn"
        *ngIf="prefixDate && !isAcceptOrRejected">
        {{ (currentEvent | learnerReviewStatus:'dateLabel':reviewEventStyle) | transloco }}
        {{ lastUpdatedOn | dateFormat:'D MMM YYYY' }}
        <ng-container *ngTemplateOutlet="lateSubmissionTemplate"></ng-container>
      </div>

      <div
        class="review-status_badge"
        [class.medium]="textStyle === 'MEDIUM_BOLD'"
        [class.large]="textStyle === 'LARGE_BOLD'"
        [ngClass]="{
            approved: currentEvent === ReviewEventType.ACCEPTED,
            rejected: currentEvent === ReviewEventType.REJECTED_TO_REVIEW,
            changesRequest: !reviewEventStyle && (currentEvent === ReviewEventType.REQUIRES_RESUBMISSION),
            needsReview: !reviewEventStyle && (currentEvent === ReviewEventType.SUBMITTED_FOR_REVIEW || currentEvent === ReviewEventType.RESUBMITTED),
            closed: currentEvent === ReviewEventType.CLOSED,
            reOpened: currentEvent === ReviewEventType.REOPENED,
          }">
        <ng-container [ngSwitch]="currentEvent">
          <ng-container *ngSwitchCase="ReviewEventType.ACCEPTED">
            <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.check' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
            </svg>
            {{ (currentEvent | learnerReviewStatus:'dateLabel':reviewEventStyle) | transloco }}
            {{ lastUpdatedOn | dateFormat:'D MMM YYYY' }}
            <ng-container *ngTemplateOutlet="lateSubmissionTemplate"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="ReviewEventType.REJECTED_TO_REVIEW">
            <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.close' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
            </svg>
            {{ (currentEvent | learnerReviewStatus:'dateLabel':reviewEventStyle) | transloco }}
            {{ lastUpdatedOn | dateFormat:'D MMM YYYY' }}
            <ng-container *ngTemplateOutlet="lateSubmissionTemplate"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="ReviewEventType.REQUIRES_RESUBMISSION">
            <svg *ngIf="!reviewEventStyle" class="svg" role="img"
                 [attr.aria-label]="('translations.global.ariaLabels.icons.warning' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#warning"></use>
            </svg>
            <mat-icon *ngIf="reviewEventStyle" aria-hidden="true" class="mat-icon warn">loop</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="ReviewEventType.SUBMITTED_FOR_REVIEW">
            <svg *ngIf="!reviewEventStyle" class="svg" role="img"
                 [attr.aria-label]="('translations.global.ariaLabels.icons.rateReview' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#rate-review"></use>
            </svg>
            <mat-icon *ngIf="reviewEventStyle" aria-hidden="true" class="mat-icon">forward</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="ReviewEventType.RESUBMITTED">
            <svg *ngIf="!reviewEventStyle" class="svg" role="img"
                 [attr.aria-label]="('translations.global.ariaLabels.icons.rateReview' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#rate-review"></use>
            </svg>
            <mat-icon *ngIf="reviewEventStyle" aria-hidden="true" class="mat-icon highlight">replay</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="ReviewEventType.CLOSED">
            <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.close' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
            </svg>
          </ng-container>
          <ng-container *ngSwitchCase="ReviewEventType.REOPENED">
            <svg class="svg" role="img"
                 [attr.aria-label]="('translations.global.ariaLabels.icons.refresh' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#refresh"></use>
            </svg>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isAcceptOrRejected">
          {{ (currentEvent | learnerReviewStatus:'badge':reviewEventStyle) | transloco }}
        </ng-container>
      </div>

      <div
        class="review-status_updatedOn"
        *ngIf="!prefixDate && !isAcceptOrRejected">
        {{ (currentEvent | learnerReviewStatus:'dateLabel':reviewEventStyle) | transloco }}
        {{ lastUpdatedOn | dateFormat:'D MMM YYYY' }}
        <ng-container *ngTemplateOutlet="lateSubmissionTemplate"></ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>

<!-- Templates -->
<ng-template #noDateTemplate>
  <div
    class="review-status_badge"
    [ngClass]="{
      approved: currentEvent === ReviewEventType.ACCEPTED,
      rejected: currentEvent === ReviewEventType.REJECTED_TO_REVIEW,
      changesRequest: currentEvent === ReviewEventType.REQUIRES_RESUBMISSION,
      needsReview: currentEvent === ReviewEventType.SUBMITTED_FOR_REVIEW || currentEvent === ReviewEventType.RESUBMITTED,
      closed: currentEvent === ReviewEventType.CLOSED,
      reOpened: currentEvent === ReviewEventType.REOPENED
    }">
    <svg class="svg" role="img" aria-hidden="true">
      <ng-container [ngSwitch]="currentEvent">
        <use ogSvgUnify="assets/sprite/sprite.svg#check" *ngSwitchCase="ReviewEventType.ACCEPTED"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#close" *ngSwitchCase="ReviewEventType.CLOSED"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#close" *ngSwitchCase="ReviewEventType.REJECTED_TO_REVIEW"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#warning" *ngSwitchCase="ReviewEventType.REQUIRES_RESUBMISSION"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#rate-review" *ngSwitchCase="ReviewEventType.SUBMITTED_FOR_REVIEW"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#rate-review" *ngSwitchCase="ReviewEventType.RESUBMITTED"></use>
        <use ogSvgUnify="assets/sprite/sprite.svg#refresh" *ngSwitchCase="ReviewEventType.REOPENED"></use>
      </ng-container>
    </svg>

    {{ (currentEvent | learnerReviewStatus:'dashboard':reviewEventStyle) | transloco }}
  </div>
</ng-template>

<ng-template #lateSubmissionTemplate>
  <div class="review-status_late-submission" *ngIf="isLateSubmission">
    ({{ 'translations.reviews.status.dateLabel.lateSubmission' | transloco }})
  </div>
</ng-template>


