import { Injectable } from '@angular/core';
import { OptionListDataService } from './option-list-data.service';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { RestClientService } from '../rest-client.service';
import { TranslationService } from '../translation/translation.service';
import { ObservableResult } from '../../store';
import { HierarchicalList } from '../../models';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class ApiOptionListDataService implements OptionListDataService {

  private optionList = Location.joinWithSlash(environment.apiRootUrl || '', 'lists');

  constructor(private client: RestClientService) {
  }

  createOptionList(title: string): ObservableResult<HierarchicalList> {
    const request = {
      title: title
    };
    return this.client.post<HierarchicalList>(this.optionList, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  addOptionListElement(listUid: string, parentUid: string, optionName: string): ObservableResult<HierarchicalList> {
    const request = {
      parentUid: parentUid,
      value: optionName
    }
    return this.client.post<HierarchicalList>(`${this.optionList}/${listUid}/elements`, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  updateOptionListElement(listUid: string, parentUid: string, optionName: string): ObservableResult<HierarchicalList>{
    const request = {
      value: optionName
    }
    return this.client.patch<HierarchicalList>(`${this.optionList}/${listUid}/elements/${parentUid}`, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }


  removeOptionListElement(listUid: string, parentUid: string): ObservableResult<void>{
    return this.client.delete<void>(`${this.optionList}/${listUid}/elements/${parentUid}`)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

}
