import { ProjectModule } from './../page-modules/project/project.module';
/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';

import { LoginFormComponent } from '../user-auth/components/login-form/login-form.component';
import { SignupFormComponent } from '../user-auth/components/signup-form/signup-form.component';
// eslint-disable-next-line max-len
import {
  AccountVerificationComponent
} from '../user-auth/components/account-verification/account-verification.component';

import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { UserAuthModule } from '../user-auth/user-auth.module';
import { SharedModule } from '../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

import { CommonModule } from '@angular/common';
import { MainComponent } from '../index/components/main/main.component';
import { environment } from '../../environments/environment';
import { EnvironmentCode } from '../../environments/environment.model';
import { LtiLaunchComponent } from '../user-auth/components/lti-launch/lti-launch.component';
// eslint-disable-next-line max-len
import {
  LtiRegistrationErrorComponent
} from '../user-auth/components/lti-registration-error/lti-registration-error.component';
// eslint-disable-next-line max-len
import {
  PasswordConfirmationAndRecoveryComponent
} from '../user-auth/components/password-confirmation-and-recovery/password-confirmation-and-recovery.component';
import { ForgotPasswordComponent } from '../user-auth/components/forgot-password/forgot-password.component';
import { EmailRecoveryComponent } from '../user-auth/components/email-recovery/email-recovery.component';
// eslint-disable-next-line max-len
import {
  RecoveryEmailVerificationComponent
} from '../user-auth/components/recovery-email-verification/recovery-email-verification.component';
import { IframeLogoutComponent } from '../user-auth/components/iframe-logout/iframe-logout.component';
// eslint-disable-next-line max-len
import {
  FolioAccountVerificationComponent
} from '../user-auth/components/folio-account-verification/folio-account-verification.component';
import { UnverifiedEmailComponent } from '../user-auth/components/unverified-email/unverified-email.component';
import { ClassOfLoginComponent } from '../user-auth/components/classof-auth/classof-login/classof-login.component';
import { ClassofSignupComponent } from '../user-auth/components/classof-auth/classof-singup/classof-signup.component';
import { AlumniLoginComponent } from '../user-auth/components/alumni-login/alumni-login.component';
import { AllowRegistrationsGuard } from '../shared/guards/allow-registrations.guard';
import { LtiPlaylistEditComponent } from '../user-auth/components/lti-playlist-edit/lti-playlist-edit.component';
import { InvitationJoinComponent } from '../user-auth/components/invitation-join/invitation-join.component';
import { FolioState } from '../page-modules/folio/store/folio.state';
import { MomentViewAllState } from '../page-modules/folio/store/moment/moment-view-all/moment-view-all.state';
import { MomentUpdateViewState } from '../page-modules/folio/store/moment/moment-update/moment-update.state';
import { CORE_MOMENT_DATA_SERVICE } from '../page-modules/folio/shared-moment-module/services/data.service';
import { momentServiceFactory } from '../page-modules/folio/shared-moment-module/shared-moment-module';
import { TranslationService } from '../shared/services/translation/translation.service';
import { RestClientService } from '../shared/services/rest-client.service';
import { UserAuthState } from '../user-auth/store/user-auth.state';
import { VerifyEmailComponent } from '../user-auth/components/verify-email/verify-email.component';
import { SamlSPLaunchComponent } from '../user-auth/components/saml-sp/saml-sp.component';
import { SamlSpErrorComponent } from '../user-auth/components/saml-sp/saml-sp-error/saml-sp-error.component';
import { publicFolioMatcher } from '../shared/paths/folio/folio-paths';
import {
  PrearrivalsLoginFormComponent
} from '../user-auth/components/prearrivals-login-form/prearrivals-login-form.component';
import { PrearrivalsEnabledGuard } from '../shared/guards/prearrivals-enabled.guard';
import { LanguagesState } from '../page-modules/admin/store/languages/languages.state';
import { LtiDeeplinkingComponent } from '../user-auth/components/lti-deeplinking/lti-deeplinking.component';
import {
  FolioPublicMomentContentComponent
} from '../page-modules/folio/components/view/public-moment/public-moment-content.component';
import { HELP_CENTER_DATA_SERVICE } from '../shared/services/help-center/help-center-data.service';
import * as SharedFactoryService from '../shared/shared-service-factory';
import { TranslocoModule } from '@ngneat/transloco';
import { FOLIO_DATA_SERVICE } from '../page-modules/folio/services/data.service';
import { PublicBadgeContentComponent } from '../page-modules/folio/components/view/public-badge-content/public-badge-content-new.component';

export const routes: Routes = [
  {
    path: 'signin',
    component: LoginFormComponent,
  },
  {
    path: 'support/signin',
    component: LoginFormComponent,
  },
  {
    path: 'signin/classof2020',
    component: ClassOfLoginComponent,
  },
  {
    path: 'graduate/signin',
    component: AlumniLoginComponent,
  },
  {
    path: 'signup',
    component: SignupFormComponent,
    canActivate: [AllowRegistrationsGuard],
  },
  {
    path: 'invitation/:invitationId/accept',
    component: SignupFormComponent,
  },
  {
    path: 'invitation/:invitationId/join',
    component: InvitationJoinComponent,
  },
  {
    path: 'signup/classof2020',
    component: ClassofSignupComponent,
    canActivate: [AllowRegistrationsGuard],
  },
  {
    path: 'account-verification',
    component: AccountVerificationComponent,
  },
  {
    path: 'lti/launch',
    component: LtiLaunchComponent,
  },
  {
    path: 'lti/deeplink',
    component: LtiDeeplinkingComponent,
  },
  {
    path: 'saml/signin',
    component: SamlSPLaunchComponent,
  },
  {
    path: 'saml/signin-error',
    component: SamlSpErrorComponent,
  },
  {
    path: 'lti/playlists/edit',
    component: LtiPlaylistEditComponent,
  },
  {
    path: 'lti/registration/error',
    component: LtiRegistrationErrorComponent,
  },
  {
    path: 'iframe-logout',
    component: IframeLogoutComponent,
  },
  {
    path: 'reset-password',
    component: PasswordConfirmationAndRecoveryComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-email',
    component: EmailRecoveryComponent,
  },
  {
    path: 'confirm-email',
    component: RecoveryEmailVerificationComponent,
  },
  {
    path: 'unverified-email',
    component: UnverifiedEmailComponent,
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
  },
  {
    path: 'verify-personal-email',
    component: PasswordConfirmationAndRecoveryComponent,
  },
  {
    path: 'verify-alumni-email',
    component: PasswordConfirmationAndRecoveryComponent,
  },
  {
    path: 'verify-organisation-email',
    component: PasswordConfirmationAndRecoveryComponent,
  },
  {
    path: 'verify-organisation-email-with-folio',
    component: PasswordConfirmationAndRecoveryComponent,
  },
  {
    path: 'verify-folio',
    component: FolioAccountVerificationComponent,
  },
  {
    path: 'verify-alumni',
    component: FolioAccountVerificationComponent,
  },
  {
    path: 'verify-pre-arrival',
    component: FolioAccountVerificationComponent,
  },
  {
    path: 'verify-pre-arrival-with-folio',
    component: FolioAccountVerificationComponent,
  },
  {
    path: 'prearrivals/signin',
    component: PrearrivalsLoginFormComponent,
    canActivate: [PrearrivalsEnabledGuard],
  },
  {
    path: 'prearrivals/forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [PrearrivalsEnabledGuard],
  },
  {
    path: 'prearrivals/signup',
    component: SignupFormComponent,
    canActivate: [AllowRegistrationsGuard, PrearrivalsEnabledGuard],
  },
  {
    path: 'folio/badge/:userBadgeId',
    component: PublicBadgeContentComponent,
  },
  {
    path: 'folio/moment/:momentId',
    component: FolioPublicMomentContentComponent,
  },
  {
    path: 'lti',
    loadChildren: () => import('../page-modules/lti/lti.module').then(m => m.LtiModule),
  },
  {
    matcher: publicFolioMatcher,
    loadChildren: () => import('../page-modules/folio/folio.module').then(m => m.FolioModule),
  },
  {
    path: 'folio/:folioPublicId/public/projects',
    loadChildren: () => import('../page-modules/project/project.module').then(m => m.ProjectModule),
  },
  {
    path: '',
    loadChildren: () => import('../app-frame/app-frame.module').then(m => m.AppFrameModule),
    canActivate: [AuthGuard],
  }
];

@NgModule({
  declarations: [
    MainComponent,
  ],
  imports: [
    NgxsModule.forRoot([
      FolioState,
      MomentViewAllState,
      MomentUpdateViewState,
      UserAuthState,
      LanguagesState,
    ]),
    UserAuthModule,
    SharedModule,
    MatButtonModule,
    MatCardModule,
    CommonModule,
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled'
    }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.env === EnvironmentCode.prod }),
    TranslocoModule,
  ],
  providers: [
    AuthGuard,
    {
      provide: FOLIO_DATA_SERVICE,
      useFactory: SharedFactoryService.folioServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: CORE_MOMENT_DATA_SERVICE,
      useFactory: momentServiceFactory,
      deps: [RestClientService, TranslationService],
    },
    {
      provide: HELP_CENTER_DATA_SERVICE,
      useFactory: SharedFactoryService.helpCenterServiceFactory,
      deps: [RestClientService, TranslationService]
    },
  ],
  entryComponents: [MainComponent],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
