/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Quiz, QuizResultDialogData, QuizSummary } from '@app/app/shared/models/editor/quiz-content.model';
import {
  DiagnosticQuestionResponse,
  Organization, DiagnosticFormattedQuestion
} from '@app/app/shared/models';
import { DATA_SERVICE, QuizDataService } from '@app/app/editor/services/data.service';
import { QuizReport, QuizReportResponse } from '../../../../models';
import { Select } from '@ngxs/store';
import { LearnerViewState } from '../../../../store/learner-view.state';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, take, filter } from 'rxjs/operators';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { PlaylistViewState } from '../../../../../playlist/store/view/playlist-view.state';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';

@Component({
  templateUrl: './quiz-results-dialog.component.html',
  styleUrls: ['./quiz-results-dialog.component.scss'],
})
export class QuizResultsDialogComponent implements OnInit, OnDestroy {

  @Select(LearnerViewState.currentLanguage)
  private currentLanguage$: Observable<string>;

  @Select(UserAuthState.organizationDetails)
  private organization$: Observable<Organization>;

  @Select(PlaylistViewState.playlistId)
  private playlistId$: Observable<string>;

  resultUid: string;
  quizTaken: boolean;
  quizId: string;
  resourceUid: string;
  isReviewResult: boolean;
  canRetake = false;
  totalRequiredCount: number;

  report: QuizReport;
  responses: QuizReportResponse[];
  questions: DiagnosticQuestionResponse[];
  quiz: Quiz;
  summary: QuizSummary;

  currentLanguage: string;
  organization: Organization;
  reportType: string;
  reportTypes = {
    DETAILED: 'DETAILED',
    SUMMARY: 'BRIEF',
  };

  responsesTotalCount: number;
  correctAnswersCount: number;
  correctAnswersPercent: number;
  requiredToPassPercent: number;
  quizPassed = false;

  private playlistId: string;
  private playlistIdSubscription: Subscription;
  private subscriptionEnd$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuizResultDialogData,
    @Inject(DATA_SERVICE) private dataService: QuizDataService,
    private dialogRef: MatDialogRef<QuizResultsDialogComponent>,
    private cdr: ChangeDetectorRef
  ) {
    this.quizId = this.data.quizId;
    this.resourceUid = this.data.resourceUid;
    this.quizTaken = this.data.quizTaken;
    this.resultUid = this.data.resultUid;
    this.summary = this.data.summary;
    this.canRetake = this.data.retake;
    this.totalRequiredCount = this.data.summary?.totalRequired;
    this.isReviewResult = this.data.isReviewResult;
    this.organization$.pipe(take(1)).subscribe(org => this.organization = org);
  }

  ngOnInit(): void {
    this.playlistIdSubscription = this.playlistId$
      .pipe(filter(playlistId => !!playlistId))
      .subscribe((playlistId: string) => this.playlistId = playlistId);
    this.loadQuiz();
    if (this.quizTaken) {
      this.resultUid = this.data.resultUid;
      this.currentLanguage$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(currentLanguage => {
        this.dataService.getQuizReport(this.playlistId, this.quizId, this.resultUid, currentLanguage)
          .subscribe(({ isSuccess, value }) => {
            if (isSuccess) {
              this.report = value;
              this.responses = value.responses;
              this.calculatedLearnerAnswersProgress();
            } else {
              this.close();
            }
          });
      });
    }
  }

  ngOnDestroy(): void {
    this.playlistIdSubscription?.unsubscribe();
    this.subscriptionEnd$.next();
  }

  close(): void {
    this.dialogRef.close();
  }

  retake(): void {
    this.dialogRef.close({ event: 'retake', resultUid: this.resultUid });
  }

  private loadQuiz(): void {
    this.currentLanguage$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(currentLanguage => {
      this.currentLanguage = currentLanguage;
      this.dataService.getQuiz(this.resourceUid, this.quizId, currentLanguage).subscribe(
        ({ isSuccess, value }) => {
          if (isSuccess) {
            value.questions = this.formatQuestionsIntoDiagnosticQuestion(value);
            value.instruction = LanguageCodeHelper.getDataByUserLanguageCode(value.instructions, this.organization, this.currentLanguage);
            this.quiz = value;
            this.reportType = this.reportTypes[this.quiz.settings.resultDisplayPolicy];
            this.cdr.detectChanges();
          }
        }
      );
    });
  }

  private formatQuestionsIntoDiagnosticQuestion(value: Quiz): DiagnosticQuestionResponse[] {
    let formattedQuestions = value.questions.filter(question => !!question);

    formattedQuestions = formattedQuestions.map((question: DiagnosticQuestionResponse) => ({
        ...question,
        instruction: LanguageCodeHelper.getDataByUserLanguageCode(question.instructions, this.organization, this.currentLanguage),
        value: LanguageCodeHelper.getDataByUserLanguageCode(question.values, this.organization, this.currentLanguage),
        choices: question.options.map(option =>
          ({
            ...option,
            optionUid: option.uid,
            value: LanguageCodeHelper.getDataByUserLanguageCode(question.values, this.organization, this.currentLanguage),
          })),
        title: LanguageCodeHelper.getDataByUserLanguageCode(question.instructions, this.organization, this.currentLanguage).value,
        feedback: question.feedback,
      } as DiagnosticFormattedQuestion
    ));

    return formattedQuestions
  }

  private calculatedLearnerAnswersProgress(): void {
    this.responsesTotalCount = this.responses.length;
    this.correctAnswersCount = this.responses.filter((item) => item.answeredCorrectly).length;
    this.correctAnswersPercent = Math.round(this.correctAnswersCount * 100 / this.totalRequiredCount);
    this.requiredToPassPercent = Math.round(this.totalRequiredCount * 100 / this.responsesTotalCount);
    this.quizPassed = this.correctAnswersPercent >= 100;
  }
}
