/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Resource,
  DisplayConfigRequest,
  PlaylistCardShort,
  ResourceTag,
  PlaylistMainSection, Playlist, PlaylistLayoutType, PlaylistStandardSection, PlaylistSectionsReorder
} from '../../../../shared/models';
import { SupportedLanguage } from '../../../../shared/models/languages/languages.model';
import { PaletteAction } from '../../components/create/playlist-palette/playlist-palette.component';
import { EditorContent, ImageUploadContent } from '../../../../editor/models';

export class SetCurrentLanguage {
  static readonly type = '[Playlist resource editor] Set current language';

  constructor(public supportedLanguage: SupportedLanguage, public index: number) { }
}

export class ResetCurrentLanguage {
  static readonly type = '[Playlist resource editor] reset current language';
}

export class LoadPlaylistResourceDetails {
  static readonly type = '[Playlist resource editor] Load Playlist Resource Details';

  constructor(
    public playlistUri: string,
    public publisherUri: string,
    public packageUri: string,
    public pageUri: string,
  ) { }
}

export class LoadProjectPlaylistResourceDetails {
  static readonly type = '[Playlist resource editor] Load Project Playlist Resource Details';

  constructor(
    public playlistUri: string
  ) { }
}

export class SetPlaylistResourceDetails {
  static readonly type = '[Playlist resource editor] Set Playlist Resource Details';

  constructor(public playlist: Playlist) { }
}

export class RedirectToPlaylistEdit {
  static readonly type = '[Playlist resource editor] Redirect to Playlist Edit Page';

  constructor(public playlistUri: string, public formattedUri: string) { }
}

export class RedirectToProjectEdit {
  static readonly type = '[Playlist resource editor] Redirect to Project Edit Page';

  constructor(public folioPublicId: string, public playlistUri: string) { }
}

export class RedirectToPlaylistView {
  static readonly type = '[Playlist resource editor] Redirect to Playlist View Page';

  constructor(public playlistUri: string, public formattedUri: string) { }
}


export class SaveNewCardDataToPlaylistSection {
  static readonly type = '[Playlist resource editor] Save new Resource to playlist';

  constructor(public resource: Resource) { }
}

export class SavePlaylistSectionIndexForNewCard {
  static readonly type = '[Playlist resource editor] Add new section idx to new card in playlist';

  constructor(public sectionIndex: number,
              public newMainSection?: PlaylistMainSection,
              public newStandardSections?: PlaylistStandardSection[]) { }
}

export class ResetNewCardData {
  static readonly type = '[Playlist resource editor] Reset new card data ';
}

export class SaveSectionIndexAndTypeForAddedItem {
  static readonly type = '[Playlist resource editor] Save section idx and type for added item where should appear';

  constructor(public sectionIndex: number, public type: PaletteAction) { }
}

export class RemoveSectionIndexAndTypeForAddedItem {
  static readonly type = '[Playlist resource editor] Remove section idx and type for added item where should appear';

}

export class InitAutoSave {
  static readonly type = '[Playlist Resource edit] Init Auto Save';

  constructor(public languageCode: string) {
  }
}


export class ResetPlaylistState {
  static readonly type = '[Playlist Resource edit] Reset state';

  constructor(
    public keepParentPlaylistDetails: boolean,
    public pageId?: string,
    public pageSectionId?: string,
    public targetGroups?: string,
  ) {
  }
}

export class UpdatePlaylistHeadline {
  static readonly type = '[Playlist Resource edit] Update headline';

  constructor(public headline: string, public languageCode: string) {
  }
}

export class SavePlaylistHeadline {
  static readonly type = '[Playlist Resource edit] Save headline';

  constructor(public headline: string, public languageCode: string) {
  }
}

export class UpdatePlaylistTimeRequired {
  static readonly type = '[Playlist Resource edit] Update time required';

  constructor(public timeRequired: number) {
  }
}

export class UpdatePlaylistAuthorVisibility {
  static readonly type = '[Playlist Resource edit] Update author visibility';

  constructor(public request: DisplayConfigRequest) {
  }
}

export class AddInitialContentToMainSection {
  static readonly type = '[Playlist Resource edit] Add initial content to main section';

  constructor(public position: PlaylistLayoutType) {
  }
}

export class UpdatePlaylistMainSectionCards {
  static readonly type = '[Playlist Resource edit] Update main sectsion cards';

  constructor(public cards: PlaylistCardShort[]) {
  }
}

export class UpdatePlaylistAdditionalSectionCards {
  static readonly type = '[Playlist Resource edit] Update additional section cards';

  constructor(public cards: PlaylistCardShort[], public sectionUid: string) {
  }
}


export class ChangeAutosaveStatus {
  static readonly type = '[Playlist Resource edit] change autosave status';

  constructor(public status: boolean) {
  }
}


export class SavePlaylistUpdates {
  static readonly type = '[Playlist Resource edit] Save playlist resource Updates';

  constructor(public isProject: boolean, public folioPublicId:string, public languageCode?: string) {
  }
}

export class SaveCurrentChanges {
  static readonly type = '[Playlist Resource edit] Save Current Changes';

  constructor(public languageCode: string) {
  }
}

export class RefreshPlaylist {
  static readonly type = '[Playlist Resource edit] Refresh playlist resource';

  constructor(
    public playlistUri: string,
    public publisherUri: string,
    public packageUri: string,
    public pageUri: string,
    public languageCode?: string
  ) {
  }
}


export class UpdateUri {
  static readonly type = '[Playlist Resource edit] Update URI';

  constructor(public value: string) {
  }
}

export class UpdateSubheader {
  static readonly type = '[Playlist Resource edit] Update subheader';

  constructor(public subheader: string, public languageCode?: string) {
  }
}

export class NavigateToRootPage {
  static readonly type = '[Playlist Resource edit] Navigate to root page';
}

export class RedirectToPlaylistViewPage {
  static readonly type = '[Playlist Resource edit] Navigate to playlist view page';

  constructor(public uri?: string, public formattedUri?: string) {
  }
}


export class UpdateContentAtMainSection {
  static readonly type = '[Playlist Resource edit] Update content at main section';

  constructor(
    public updatedContentIndex: number,
    public updateContent: EditorContent,
    public oldContent: EditorContent,
    public languageCode: string,
  ) {
  }
}

export class RemoveContentAtMainSection {
  static readonly type = '[Playlist Resource edit] Remove content at main section';

  constructor(public dynamicContentIndex: number, public languageCode: string) {
  }
}

export class AddContentAtMainSection {
  static readonly type = '[Playlist Resource edit] Add content at main section';

  constructor(public content: EditorContent, public languageCode: string) {
  }
}

export class TogglePositionOnMainPlaylistSection {
  static readonly type = '[Playlist Resource edit] Toggle image position on main section';

  constructor(public languageCode: string) {
  }
}

export class CreateSection {
  static readonly type = '[Playlist Resource edit] Create additional section';
}

export class DeleteNewSection {
  static readonly type = '[Playlist Resource edit] Delete new additional section';

  constructor(public cards: boolean, public sectionUid: string) {
  }
}

export class AddDynamicContentToNewSection {
  static readonly type = '[Playlist Resource edit] Add dynamic content to new section';

  constructor(public sectionIndex: number, public content: EditorContent, public languageCode: string) {
  }
}

export class UpdateDynamicContentOfNewSection {
  static readonly type = '[Playlist Resource edit] Update dynamic content of new section';

  constructor(
    public sectionIndex: number,
    public updatedContentIndex: number,
    public updateContent: EditorContent,
    public oldContent: EditorContent,
    public languageCode: string
  ) {
  }
}

export class RemoveDynamicContentFromNewSection {
  static readonly type = '[Playlist Resource edit] Remove dynamic content from new section';

  constructor(public sectionIndex: number, public dynamicContentIndex: number, public languageCode: string) {
  }
}

export class ReorderPlaylistSections {
  static readonly type = '[Playlist Resource edit] Reorder playlist sections';

  constructor(public reorder: PlaylistSectionsReorder) {
  }
}

export class RemoveAllContentFromNewSection {
  static readonly type = '[Playlist Resource edit] Remove all content from new section';

  constructor(public sectionIndex: number, public languageCode: string) {
  }
}

export class SetPlaylistCoverImage {
  static readonly type = '[Playlist Resource edit] Set cover image to playlist main section';

  constructor(public image?: File, public languageCode?: string) {
  }
}

export class SetPlaylistCoverImageUrl {
  static readonly type = '[Playlist Resource edit] Set cover image url to playlist main section';

  constructor(public imageUploadContent?: ImageUploadContent, public languageCode?: string) {
  }
}

export class FireNewSectionPaletteAction {
  static readonly type = '[Playlist Resource edit] Set playlist section palette action';

  constructor(public sectionIndex: number, public action: PaletteAction) {
  }
}

export class FireMainSectionPaletteAction {
  static readonly type = '[Playlist Resource edit] Set playlist main section palette action';

  constructor(public action: PaletteAction) {
  }
}

export class ChangeMainSectionCardLayoutType {
  static readonly type = '[Playlist Resource edit] Change main section card layout type';

  constructor(
    public playlistUid: string,
    public mainSectionUid: string,
    public cardsLayoutType: PlaylistStandardSection['cardsLayoutType']
  ) {
  }
}

export class ChangeSectionCardLayoutType {
  static readonly type = '[Playlist Resource edit] Change section card layout type';

  constructor(
    public playlistUid: string,
    public sectionUid: string,
    public sectionIndex: number,
    public cardsLayoutType: PlaylistStandardSection['cardsLayoutType']
  ) {
  }
}

export class ChangeSectionCardsOrdering {
  static readonly type = '[Playlist Resource edit] Change section cards ordering';

  constructor(
    public playlistUid: string,
    public sectionUid: string,
    public sectionIndex: number,
    public cardsIds: string[]
  ) {
  }
}

export class ChangeMainSectionCardsOrdering {
  static readonly type = '[Playlist Resource edit] Change main section cards ordering';

  constructor(
    public playlistUid: string,
    public sectionUid: string,
    public cardsIds: string[]
  ) {
  }
}

export class ChangeCardPositionSection {
  static readonly type = '[Playlist Resource edit] Change card position section';

  constructor(
    public playlistUid: string,
    public fromSectionUid: string,
    public toSectionUid: string,
    public cardUid: string,
  ) {
  }
}

export class CreateNewCardInSection {
  static readonly type = '[Playlist Resource edit] Create new card in section';

  constructor(public playlistSectionIndex: number) {
  }
}

export class CreateNewEventCardInSection {
  static readonly type = '[Playlist Resource edit] Create new event card in section';

  constructor(public playlistSectionIndex: number) {
  }
}

export class NavigateToEventCardCreation {
  static readonly type = '[Playlist Event card edit] Navigate to event card creation page';

  constructor(public uri: string, public formattedUri: string) {
  }
}


export class NavigateToResourceCreation {
  static readonly type = '[Playlist Resource edit] Navigate to resource creation page';

  constructor(public uri: string, public formattedUri: string, public isProjectResource: boolean, public folioPublicId: string) {
  }
}

export class CreateNewCardInMainSection {
  static readonly type = '[Playlist Resource edit] Create new card in main section';
}

export class CreateNewSection {
  static readonly type = '[Playlist Resource edit] Create new section in playlist';
}


export class CreateNewEventCardInMainSection {
  static readonly type = '[Playlist Resource edit] Create new event card in main section';
}

export class RemoveCardFromSection {
  static readonly type = '[Playlist Resource edit] Remove card from standard section';

  constructor(
    public sectionIndex: number,
    public playlistId: string,
    public sectionId: string,
    public cardId: string
  ) {
  }
}

export class RemoveCardFromMainSection {
  static readonly type = '[Playlist Resource edit] Remove card from main section';

  constructor(
    public playlistUid: string,
    public sectionUid: string,
    public uid: string
  ) {
  }
}

export class AssociateNewTag {
  static readonly type = '[Playlist Resource edit] Associate new tag';

  constructor(public playlistId: string, public title: string) {
  }
}

export class DissociateTag {
  static readonly type = '[Playlist Resource edit] Dissociate tag';

  constructor(public playlistUid: string, public tag: ResourceTag) {
  }
}

export class FilterTags {
  static readonly type = '[Playlist Resource edit] Filter tags';

  constructor(public text: string) {
  }
}

export class AssociateExistingTag {
  static readonly type = '[Playlist Resource edit] Associate existing tag';

  constructor(public playlistId: string, public tag: ResourceTag) {
  }
}

export class AssociateNewFeedTag {
  static readonly type = '[Playlist Resource edit] Associate new feed tag';

  constructor(public playlistId: string, public title: string) {
  }
}

export class DissociateFeedTag {
  static readonly type = '[Playlist Resource edit] Dissociate feed tag';

  constructor(public playlistUid: string, public tag: ResourceTag) {
  }
}

export class DissociateAllFeedTags {
  static readonly type = '[Playlist Resource edit] Dissociate all feed tags';

  constructor(public playlistUid: string) {
  }
}

export class FilterFeedTags {
  static readonly type = '[Playlist Resource edit] Filter feed tags';

  constructor(public text: string) {
  }
}

export class AssociateExistingFeedTag {
  static readonly type = '[Playlist Resource edit] Associate existing feed tag';

  constructor(public playlistId: string, public tag: ResourceTag) {
  }
}

export class DissociateCategory {
  static readonly type = '[Playlist Resource edit] Dissociate category';

  constructor(public playlistUid: string, public categoryTag: ResourceTag) {
  }
}

export class FilterCategories {
  static readonly type = '[Playlist Resource edit] Filter categories';

  constructor(public text: string) {
  }
}

export class AssociateCategory {
  static readonly type = '[Playlist Resource edit] Associate category';

  constructor(public playlistId: string, public categoryTag: ResourceTag) {
  }
}

export class CreateNewDiagnosticsCardMainSection {
  static readonly type = '[Playlist Resource edit] Create new diagnostics in main section';
}

export class RedirectToDiagnosticsCreation {
  static readonly type = '[Playlist Resource edit] Navigate to diagnostics creation page';

  constructor(public diagnosticUri: string, public formattedUri: string) {
  }
}

export class CreateNewDiagnosticsSection {
  static readonly type = '[Playlist Resource edit] Create new diagnostics in section';

  constructor(public sectionIndex: number) {
  }
}

export class SavePlaylistAndNavigateToSubPlaylistCreation {
  static readonly type = '[Playlist Resource edit] Save playlist and navigate to sub-playlist creation';

  constructor(public sectionIndex?: number) {
  }
}


