import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpecialEmbedsHelper } from '@app/app/shared/helpers/embeds/special-embeds.helper';
import { take } from 'rxjs/operators';
import {
	EmbeddableContentDetails,
	EMBEDS_CONTENT_GENERATOR_SERVICE,
	EmbedsContentGeneratorService
} from '@app/app/editor/services/embeds-content-generator.service';
import { DEFAULT_URL_VALIDATION_PATTERN } from '@app/app/shared/constants/constants';
import { EditorContent, ExternalContentSummary, MediaContent } from '@app/app/shared/models';

@Component({
	selector: 'ptl-form-link-input',
	templateUrl: './form-link-input.component.html',
	styleUrls: ['./form-link-input.component.scss']
})
export class FormLinkInputComponent {
	private _linkInputActive: boolean;
	@Input()
	set linkInputActive( value ) {
		this._linkInputActive = value;
	}

	get linkInputActive() {
		return this._linkInputActive;
	}

	/** Emits data on new content from child components */
	@Output() contentCreated = new EventEmitter<EditorContent>();
	/** Emits data on new regular link insertion from child components */
	@Output() regularLinkCreated = new EventEmitter<string>();

	mediaUrlData?: FormGroup;
	mediaEmbeddableContentLoading: boolean;

	constructor(
		private fb: FormBuilder,
		@Inject(EMBEDS_CONTENT_GENERATOR_SERVICE) public embedsService: EmbedsContentGeneratorService ) {
		this.mediaUrlData = this.fb.group({
			contentUrl: ['', [Validators.required, Validators.pattern(DEFAULT_URL_VALIDATION_PATTERN)]]
		});
	}

	onLinkEnter() {
		if ( this.mediaUrlData?.valid ) {
			this.mediaEmbeddableContentLoading = true;
			const url = SpecialEmbedsHelper.correctUrl(`${ this.mediaUrlData.get('contentUrl')?.value }`);

			this.embedsService
			.retrieveEmbeddableContentDetails(encodeURI(url))
			.pipe(take(1))
			.subscribe(( { value } ) => {
				let contentDetails: EmbeddableContentDetails | undefined;

				if ( value ) {
					contentDetails = value;
				} else {
					contentDetails = SpecialEmbedsHelper.generateEmbedFromUrl(url);
				}

				if ( contentDetails ) {
					this.onMediaContentAdded({
						type: 'EXTERNAL_CONTENT_SUMMARY',
						url: contentDetails.url,
						authorName: contentDetails.authorName,
						logoUrl: contentDetails.logoUrl,
						title: contentDetails.title,
						description: contentDetails.description,
						publicationDate: contentDetails.publicationDate,
						thumbnailUrl: contentDetails.thumbnailUrl,
						siteName: contentDetails.siteName,
						html: contentDetails.html
					} as ExternalContentSummary);
				} else {
					this.onRegularLinkAdded(url);
				}
			});
		}
	}

	onMediaContentAdded( mediaContentData: MediaContent ) {
		this.contentCreated.emit(mediaContentData);
	}

	onRegularLinkAdded( link: string ) {
		this.regularLinkCreated.emit(link);
	}
}
