/*
 * Copyright (C) 2021 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LearnerPlaylistSummary } from '../../../../../shared/models/playlist/learner-playlist-summary.model';
import { PAGES_DATA_SERVICE, PageDataService } from '../../../services/data.service';
import { SearchPage } from '../../../../../shared/models/page/page.model';
import { ContentType } from '../../../../../shared/models/content-store/content-store.model';
import { canTriggerSearch } from '../../../../../shared/helpers/content-helper';

@Component({
  selector: 'ptl-content-store-add',
  templateUrl: './content-store-add.component.html',
  styleUrls: ['./content-store-add.component.scss'],
})
export class ContentStoreAddComponent implements OnInit, OnDestroy {

  @Output() contentSelected = new EventEmitter<{ content: SearchPage | LearnerPlaylistSummary; type: ContentType }>();

  searchString: '';
  loadingSearch: boolean;
  searchResult: SearchPage[] | LearnerPlaylistSummary[] = [];
  contentType: ContentType = 'PLAYLIST';

  page = 0;
  pageSize = 10;
  totalPages: number;

  private searchInputSubject$ = new Subject<void>();
  private searchInputSubscription: Subscription;

  constructor(
    @Inject(PAGES_DATA_SERVICE) private pageDataService: PageDataService
  ) {
    this.searchInputSubscription = this.searchInputSubject$
      .pipe(debounceTime(500)).subscribe(() => this.fireSearch());
  }


  ngOnInit() {
    if (this.contentType === 'PLAYLIST') {
      this.searchPlaylist();
    } else {
      this.searchPage();
    }
  }

  ngOnDestroy() {
    this.searchInputSubscription?.unsubscribe();
  }

  selectContent(content: LearnerPlaylistSummary | SearchPage) {
    this.contentSelected.emit({ content: content, type: this.contentType });
  }

  onChangeContentType() {
    this.searchString = '';
    this.searchResult = [];
    this.page = 0;
    setTimeout(() => {
      this.loadContent();
    }, 0)
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        if (this.searchString) {
          this.loadingSearch = true;
          this.searchInputSubject$.next();
        }
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      if (this.searchString) {
        this.loadingSearch = true;
        this.searchInputSubject$.next();
      }
    }
  }

  onSizeChange(size: number): void {
    this.pageSize = size;
    this.page = 0;
    this.loadContent();
  }

  onPageChange(page: number): void {
    this.page = page;
    this.loadContent();
  }

  private fireSearch() {
    this.page = 0;
    this.loadContent();
  }

  private loadContent() {
    if (this.contentType === 'PLAYLIST') {
      this.searchPlaylist();
    } else {
      this.searchPage();
    }
  }

  private searchPage() {
    this.pageDataService.searchContentStorePage(this.page, this.pageSize, this.searchString).subscribe(({ value }) => {
      if (value) {
        this.searchResult = value.content;
        this.totalPages = value.numberOfPages;
      }
      this.loadingSearch = false;
    });
  }

  private searchPlaylist() {
    this.pageDataService.getYourPlaylists(this.page, this.pageSize, this.searchString, null)
      .subscribe(({ value }) => {
      if (value) {
        this.searchResult = value.content;
        this.totalPages = value.numberOfPages;
      }
      this.loadingSearch = false;
    });
  }
}
