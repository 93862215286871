<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<medium-editor
  [tabIndex]="-1"
  #mediumEditor
  [(editorModel)]="editorInput"
  [editorOptions]="editorOptions"
  [editorPlaceholder]="textPlaceholder">
</medium-editor>
