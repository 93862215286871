/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Directive({
  selector: '[ogScrollNearEnd]',
})
export class ScrollNearEndDirective implements OnInit {
  // threshold in PX when to emit before page end scroll
  @Input() threshold = 120;

  @Output() nearEnd: EventEmitter<void> = new EventEmitter<void>();

  private window!: Window;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    // save window object for type safety
    this.window = window;
  }

  @HostListener('window:scroll', ['$event.target'])
  private windowScrollEvent(event: KeyboardEvent) {
    const heightOfWholePage = this.window.document.documentElement.scrollHeight;
    const currentScrolledY = this.window.scrollY;

    // height of opened window - shrinks if console is opened
    const innerHeight = this.window.innerHeight;
    // how big in pixels the element is
    const heightOfElement = this.el.nativeElement.scrollHeight;

    const spaceOfElementAndPage = heightOfWholePage - heightOfElement;
    const scrollToBottom =
      heightOfElement - innerHeight - currentScrolledY + spaceOfElementAndPage;

    if ( scrollToBottom < this.threshold ) {
      this.nearEnd.emit();
    }
  }
}