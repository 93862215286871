/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import {
  ChartEditorContent,
  PIReport,
  PITeamRoleScore,
  PITeamRoleScoreReport
} from '../../../../shared/models/pi/pi.model';
import { PI_DATA_SERVICE, PIDataService } from '../../../../shared/services/pi/pi-data.service';
import { ChartsHelper } from '../../../../shared/helpers/charts-helper';

@Component({
  selector: 'ptl-pi-team-role-chart',
  templateUrl: './pi-team-role-chart.component.html',
  styleUrls: ['./pi-team-role-chart.component.scss'],
})
export class PersonalIndicatorTeamRoleChartComponent implements OnInit {

  @Input() content: ChartEditorContent;

  @Input() showStaticData: boolean;

  @Input() isPIReport: boolean;

  @Input() reports: PIReport[];

  piReport: PIReport[];
  chartData: PITeamRoleScoreReport;

  completionDate1: Date;
  completionDate2: Date;

  constructor(
    @Inject(PI_DATA_SERVICE) private piDataService: PIDataService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {

    if (!this.showStaticData && this.content) {
      if (this.isPIReport && this.reports) {
        this.setReportData(this.reports);
        return;
      }
      this.piDataService.getPIReports(this.content.piCardId).subscribe(({ isSuccess, value }) => {
        if (isSuccess && value.reports.length) {
          this.setReportData(value.reports);
        }
      });
    }
  }

  private setReportData(reports: PIReport[]) {
    this.piReport = reports;
    this.formatData(this.piReport[0].teamRoleScoreReport.teamRoleScores);
    this.chartData = this.piReport[0].teamRoleScoreReport;
    this.completionDate1 = this.piReport[0].completedOn;

    if (this.piReport.length > 1) {
      this.completionDate2 = this.piReport[1].completedOn;
    }

    this.cd.detectChanges();
  }


  private formatData(traits: PITeamRoleScore[]) {
    traits = traits.sort((a, b) => b.value - a.value);
    for (let i = 0; i < traits.length; i++) {
      const trait = traits[i];
      const descriptions = ChartsHelper.getPIChartsDescriptionsByLanguageCode(trait.name, this.content);
      trait.description = descriptions.full;
      trait.shortDescription = descriptions.short;
      if (!trait.shortDescription.length) {
        traits.splice(i, 1);
        i--;
        continue;
      }

      trait.value = +trait.value.toFixed(0);
      trait.key = trait.name.toLowerCase();
      trait.name = trait.name.replace(/_/g, ' ');
      if (this.piReport.length > 1) {
        trait.previousValue = +this.piReport[1].teamRoleScoreReport.teamRoleScores[i].value.toFixed(0);
      } else {
        trait.previousValue = 0;
      }
    }
  }
}
