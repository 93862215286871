/*
 * Copyright (C) 2021 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Playlist, UserSummary } from '../../../../../../shared/models';

@Component({
  selector: 'ptl-discovery-share-prepare-submit',
  templateUrl: './share-discovery-prepare-submit.component.html',
  styleUrls: ['./share-discovery-prepare-submit.component.scss'],
})
export class ShareDiscoveryPrepareSubmitComponent implements OnInit {

  @Input() playlist: Playlist;

  @Output() reviewPlaylist = new EventEmitter<string>();

  @Output() canceledSharePlaylist = new EventEmitter<void>();

  userSummary: UserSummary;
  frameworkPanelOpen = false;

  ngOnInit() {
    this.userSummary = {
      firstName: this.playlist.authorDetails?.name,
      lastName: this.playlist.authorDetails?.name,
      profileImage: this.playlist.authorDetails?.userImageUrl,
      email: '',
      _id: '',
    };
  }

  cancelShare() {
    this.canceledSharePlaylist.emit();
  }

  submitShare() {
    this.reviewPlaylist.emit(this.playlist._id);
  }
}
