<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="add-question__section">
  <div class="add-question__section__slider">
    <div [class.is_disabled]="!isDefaultLanguage">
      <span class="add-question__section__text"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.sliderScoreFrom' | transloco">
        {{ 'translations.sliderScoreFrom' | transloco }}</span>
      <mat-form-field
        class="add-question__section-field add-question__section-field--small no-padding color-gray"
        appearance="outline"
        [formGroup]="question.controls['options']['controls'][0]">
        <input
          matInput
          type="number"
          pattern="^\d+$"
          min="0"
          formControlName="score">
      </mat-form-field>
      <span class="add-question__section__text"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.sliderScoreTo' | transloco">
        {{ 'translations.sliderScoreTo' | transloco }}</span>
      <mat-form-field
        class="add-question__section-field add-question__section-field--small no-padding color-gray"
        appearance="outline"
        [formGroup]="question.controls['options']['controls'][1]">
        <input
          matInput
          type="number"
          min="0"
          pattern="^\d+$"
          formControlName="score">
      </mat-form-field>
      <span class="add-question__section__text-after"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.onlyIntegers' | transloco">
        {{ 'translations.onlyIntegers' | transloco }}</span>
    </div>
    <div class="add-question__section__slider__slide">
      <h2
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.sliderOptions' | transloco">
        {{ 'translations.sliderOptions' | transloco }}</h2>
      <div class="add-question__section__slider__slide-wrapper">
        <div class="add-question__section__slider__slide-values">
          <div class="add-question__section__slider__slide-value">{{ question.value.options[0].score }}</div>
          <div class="add-question__section__slider__slide-value">{{ question.value.options[1].score }}</div>
        </div>
        <div class="add-question__section__slider__slide-visual">
          <div class="slide-border slide-border--1"></div>
          <div class="slide-border slide-border--2"></div>
          <div class="slide-border slide-border--3"></div>
          <div class="slide-border slide-border--4"></div>
          <div class="slide-border slide-border--5"></div>
        </div>
      </div>
    </div>
    <div class="add-question__section__slider__text-wrapper">
      <mat-form-field class="add-question__section__slider__text-field color-gray"
                      appearance="outline"
                      [formGroup]="question.controls['options']['controls'][0]">
        <ng-container formGroupName="value">
        <textarea
          matInput
          rows="3"
          formControlName="value"
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.enterTextForOptionDisplayedOnLeft' | transloco"
          placeholder="Enter text for option displayed on left"></textarea>
        </ng-container>
      </mat-form-field>

      <mat-form-field class="add-question__section__slider__text-field color-gray"
                      appearance="outline"
                      [formGroup]="question.controls['options']['controls'][1]">
        <ng-container formGroupName="value">
          <textarea
            matInput
            rows="3"
            formControlName="value"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.enterTextForOptionDisplayedOnRight' | transloco"
            placeholder="Enter text for option displayed on right"></textarea>
        </ng-container>
      </mat-form-field>
    </div>
    <div class="add-question__section__slider-correct-buttons" *ngIf="isQuiz" [class.is_disabled]="!isDefaultLanguage">
      <div class="add-question__section__slider__radio-field">
        <mat-radio-button
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.correctAnswer' | transloco"
          [checked]="question.controls['options']['controls'][0]['value']['correct']"
          (change)="onCorrectAnswerChange('left')"
          color="primary">
          <span>
            {{ 'translations.correctAnswer' | transloco }}</span>
        </mat-radio-button>
      </div>
      <div class="add-question__section__slider__radio-field">
        <mat-radio-button
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.correctAnswer' | transloco"
          [checked]="question.controls['options']['controls'][1]['value']['correct']"
          (change)="onCorrectAnswerChange('right')"
          color="primary">
          <span>{{ 'translations.correctAnswer' | transloco }}</span>
        </mat-radio-button>
      </div>
    </div>

    <ng-container *ngIf="question.value.options[0].instructions && newVersionEnabled">
      <div class="add-question__section__slider-instructions">
        <div class="add-add-question__section__instruction-field">
          <ptl-editor
            class="thin grid-instruction no-outline"
            [placeholder]="'translations.enterInstruction' | transloco"
            [link]="false"
            [upload]="false"
            [separator]="false"
            (contentRemoval)="onContentRemoval($event, question.value.options[0].instructions, 0)"
            (contentUpdate)="onContentUpdate($event, question.value.options[0].instructions, 0)"
            [content]="question.value.options[0].instructions">
          </ptl-editor>
        </div>
        <div class="add-add-question__section__instruction-field">
          <ptl-editor
            class="thin grid-instruction no-outline"
            [placeholder]="'translations.enterInstruction' | transloco"
            [link]="false"
            [upload]="false"
            [separator]="false"
            (contentRemoval)="onContentRemoval($event, question.value.options[1].instructions, 1)"
            (contentUpdate)="onContentUpdate($event, question.value.options[1].instructions, 1)"
            [content]="question.value.options[1].instructions">
          </ptl-editor>
        </div>
      </div>
    </ng-container>
  </div>
</div>
