<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="!newVersionEnabled">
  <div class="quiz-questions">
    <div
      tabindex="0"
      class="quiz-questions__header"
      [class.quiz-questions__header--expanded]="expanded"
      [attr.aria-label]="('translations.global.ariaLabels.expandQuestions' | transloco)"
      (click)="expanded = !expanded"
      (keydown.enter)="expanded = !expanded">
      <span class="bold-text">{{ 'translations.quiz.questions.title' | transloco }}</span>
      <span *ngIf="!expanded" class="light-text">{{ formattedQuestions.length }}</span>
      <span *ngIf="!expanded && formattedQuestions.length !== 1"
            class="light-text">&nbsp;{{ 'translations.quiz.questions.addedLabel.single' | transloco }}</span>
      <span *ngIf="!expanded && formattedQuestions.length === 1"
            class="light-text">&nbsp;{{ 'translations.quiz.questions.addedLabel.multiple' | transloco }}</span>
      <mat-icon aria-hidden="true" *ngIf="!expanded" class="icon">chevron_right</mat-icon>
    </div>

    <div class="quiz-questions__body" *ngIf="expanded">

      <ptl-questions
        [isQuiz]="true"
        [languageCode]="languageCode"
        [formattedQuestions]="formattedQuestions"
        [currentLanguage]="currentLanguage"
        [questionTypes]="questionTypes"
        (addedQuestion)="addQuestion($event)"
        (updatedQuestion)="updateQuestion($event)"
        (copyQuestion)="copyQuestion($event)"
        (reorderedQuestions)="reorderQuestions($event)"
        (feedbackRemoved)="onFeedbackRemoved($event)"
        (deletedQuestion)="deleteQuestion($event)">
      </ptl-questions>

      <mat-icon tabindex="0"
                class="icon"
                [attr.aria-label]="('translations.global.ariaLabels.collapseQuestions' | transloco)"
                (keydown.enter)="expanded=false"
                (click)="expanded=false">keyboard_arrow_up
      </mat-icon>
    </div>

  </div>

</ng-container>

<ng-container *ngIf="newVersionEnabled">

  <ptl-questions
    [isQuiz]="true"
    [languageCode]="languageCode"
    [formattedQuestions]="formattedQuestions"
    [currentLanguage]="currentLanguage"
    [questionTypes]="questionTypes"
    (addedQuestion)="addQuestion($event)"
    (updatedQuestion)="updateQuestion($event)"
    (copyQuestion)="copyQuestion($event)"
    (reorderedQuestions)="reorderQuestions($event)"
    (feedbackRemoved)="onFeedbackRemoved($event)"
    (deletedQuestion)="deleteQuestion($event)">
  </ptl-questions>

</ng-container>
