/*
 * Copyright (C) 2024 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RoleFilterTypes } from '@app/app/page-modules/explore/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as ExploreActions from './explore.action';
import { ExploreStateModel, INITIAL_EXPLORE_STATE } from './explore.state.model';
import { SidebarSection } from '@app/app/shared/models/sidebar-tree/sidebar-tree.model';

@State<ExploreStateModel>({
  name: 'explore',
  defaults: INITIAL_EXPLORE_STATE
})
@Injectable()
export class ExploreState {

  @Selector()
  static termFilter({ termFilter }: ExploreStateModel): string {
    return termFilter;
  }

  @Selector()
  static categoryFilter({ categoryFilter }: ExploreStateModel): string {
    return categoryFilter;
  }

  @Selector()
  static roleFilters({ roleFilters }: ExploreStateModel): RoleFilterTypes[] {
    return roleFilters;
  }

  @Selector()
  static exploreSideBarSections({ exploreSideBarSections }: ExploreStateModel): SidebarSection[] {
    return exploreSideBarSections;
  }

  @Selector()
  static categoryUidsQueryParam({ categoryUidsQueryParam }: ExploreStateModel): string[] {
    return categoryUidsQueryParam;
  }

  @Action(ExploreActions.ClearFilteringAndSetTermFilter)
  clearFilteringAndSetTermFilter(
    { patchState }: StateContext<ExploreStateModel>,
    { term }: ExploreActions.ClearFilteringAndSetTermFilter
  ) {
    patchState({
      categoryFilter: undefined,
      termFilter: term
    });
  }

  @Action(ExploreActions.SetExploreCategoryFilter)
  setCategoryFilter(
    { getState, patchState }: StateContext<ExploreStateModel>,
    { categoryUid }: ExploreActions.SetExploreCategoryFilter
  ) {

    const currentValue = getState().categoryFilter;

    if (currentValue !== categoryUid) {
      patchState({
        termFilter: '',
        categoryFilter: categoryUid
      });
    }
  }

  @Action(ExploreActions.SetExploreRoleFilter)
  setExploreRoleFilter(
    { patchState, getState }: StateContext<ExploreStateModel>,
    { roleFilter }: ExploreActions.SetExploreRoleFilter
  ) {
    let value = getState().roleFilters;

    if (value.findIndex((it) => it === roleFilter) !== -1) {
      value = value.filter(((it) => it !== roleFilter))
    } else {
      value = value.concat(roleFilter)
    }

    patchState({
      roleFilters: value
    })
  }

  @Action(ExploreActions.ResetExploreState)
  resetExploreState(
    { patchState }: StateContext<ExploreStateModel>,
  ) {
    patchState(
      INITIAL_EXPLORE_STATE
    );
  }

  @Action(ExploreActions.SetExploreSideBarSections)
  setSectionsExploreState(
    { patchState }: StateContext<ExploreStateModel>,
    { exploreSideBarSections }: ExploreActions.SetExploreSideBarSections
  ): void {
    patchState({
      exploreSideBarSections: exploreSideBarSections
    }
    );
  }

  @Action(ExploreActions.SetCategoryUidsQueryParam)
  setCategoryUids(
    { patchState }: StateContext<ExploreStateModel>,
    { categoryUidsQueryParam }: ExploreActions.SetCategoryUidsQueryParam
  ): void {
    patchState({
      categoryUidsQueryParam: categoryUidsQueryParam
    }
    );
  }
}
