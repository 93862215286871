<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<mat-card class="skeleton__item-action-container">
  <mat-card-title class="skeleton__card-top">
    <div class="card-avatar"></div>
    <mat-card-subtitle class="slide-row"></mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="slide-row skeleton__content-header"></div>
    <div>
      <div class="skeleton__content-image"></div>
      <div class="skeleton__content-text-container">
        <ul class="skeleton__content-text-list">
          <li class="slide-row skeleton__content-text small"></li>
          <li class="slide-row skeleton__content-text big"></li>
          <li class="slide-row skeleton__content-text big"></li>
          <li class="slide-row skeleton__content-text big"></li>
        </ul>
      </div>
    </div>
  </mat-card-content>
</mat-card>
