import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { ROLES_DATA_SERVICE, RolesDataService } from 'src/app/page-modules/admin/services/roles/roles-data.service';
import { LanguageCodeHelper } from 'src/app/shared/helpers/language-code-helper';
import { OrganizationRoles } from 'src/app/shared/models';
import { UserAuthState } from 'src/app/user-auth/store/user-auth.state';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  NotRequest,
  RolesRequest
} from '../../../models/admin/boolean-filters.model';
import { TranslationService } from '../../../services/translation/translation.service';

@Component({
  selector: 'ptl-role-filter',
  templateUrl: './role-filter.component.html',
  styleUrls: ['./role-filter.component.scss']
})
export class RoleFilterComponent implements OnInit {

  @Input() selectedFilters: BooleanFilter[];
  @Output() filterDeleted = new EventEmitter<void>();
  @Output() filterSaved = new EventEmitter<BooleanFilter>();

  expanded = true;
  roleIs = true;
  roles: { role: OrganizationRoles; value: boolean; label: string }[] = [];

  constructor(
    @Inject(ROLES_DATA_SERVICE) private rolesDataService: RolesDataService,
    private translationService: TranslationService,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initRoles();
  }

  setRoleIs() {
    this.roleIs = true;
  }

  setRoleIsNot() {
    this.roleIs = false;
  }

  deleteFilter() {
    this.filterDeleted.emit();
  }

  applyFilter() {
    const request = this.getRequest(this.roles.filter(role => role.value === true).map(role => role.role._id));
    const message = this.getMessage(this.roles.filter(role => role.value === true)
    .map(role => LanguageCodeHelper.getDataByLanguageCode(role.role.name).value));
    this.filterSaved.emit({ request: request, message: message, type: 'ROLE' });
  }

  private initRoles() {
    const organizationUid = this.store.selectSnapshot(UserAuthState.organizationDetails)._id;
    this.rolesDataService.loadOrganizationRoles(organizationUid)
      .subscribe(({ isSuccess, value }) => {
        if(isSuccess) {
          value.forEach(role => {
            this.roles.push({role: role, value: false, label: LanguageCodeHelper.getDataByLanguageCode(role.name).value})
          })
          this.roles.sort((a, b) => a.label.localeCompare(b.label));
          this.getSettingsFromFilters();
          this.cdr.detectChanges();
        }
    });
  }

  private getRequest(roles: string[]): BooleanQueryRequest {
    const request: RolesRequest = { type: BooleanQueryType.ROLES, roles:  roles}
    if (this.roleIs) {
      return request as BooleanQueryRequest;
    } else {
      return { type: BooleanQueryType.NOT, arg: request } as BooleanQueryRequest;
    }
  }

  private getMessage(labels: string[]): string {
    if (this.roleIs) {
      return this.translationService.getTranslation('translations.filter.role.name') + ' '
        + this.translationService.getTranslation('filter.is') + ' '
        + labels.join(' ' + this.translationService.getTranslation('filter.or') + ' ');
    } else {
      return this.translationService.getTranslation('translations.filter.role.name') + ' '
        + this.translationService.getTranslation('filter.isNot') + ' '
        + labels.join(' ' + this.translationService.getTranslation('filter.andNot') + ' ');
    }
  }

  private getSettingsFromFilters() {
    if (this.selectedFilters) {
      const statusFilter = this.selectedFilters.find(f => f.type === 'ROLE');
      if (statusFilter) {
        const booleanRequest = (statusFilter.request as BooleanQueryRequest);
        let rolesIds: string[] = [];
        if (booleanRequest.type === BooleanQueryType.NOT) {
          this.roleIs = false;
          rolesIds = ((booleanRequest as NotRequest).arg as RolesRequest).roles;
        } else {
          this.roleIs = true;
          rolesIds = (booleanRequest as RolesRequest).roles;
        }
        this.setRoleSelected(rolesIds);
      }
    }
  }

  setRoleSelected(roleIds: string[]) {
    this.roles = this.roles.map(r => {
      if (roleIds.includes(r.role._id)) {
        r.value = true;
      }
      return r;
    });
  }

  changeRole(role: OrganizationRoles) {
    this.roles = this.roles.map(r => {
      if (role === r.role) {
        r.value = !r.value;
      }
      return r;
    });
  }
}

