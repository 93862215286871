/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { LikeInfoDataService } from './like-info-data.service';

@Injectable()
export class MockLikeInfoDataService implements LikeInfoDataService {
  votePlaylist(playlistUid: string): ObservableResult<number> {
    return ObservableResult.ofSuccess(100);
  }

  unvotePlaylist(playlistUid: string): ObservableResult<number> {
    return ObservableResult.ofSuccess(100);
  }

  voteResource(resourceUid: string): ObservableResult<number> {
    return ObservableResult.ofSuccess(100);
  }

  unvoteResource(resourceUid: string): ObservableResult<number> {
    return ObservableResult.ofSuccess(100);
  }
}
