<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="!newVersionEnabled">
  <div class="form-section">
    <form
      class="form-section__form-expanded"
      *ngIf="expanded"
      #fileUploadFormHtml
      tabindex="0"
      [attr.aria-label]="('translations.global.ariaLabels.fileUploadForm' | transloco)"
      [formGroup]="fileUploadForm"
      (ngSubmit)="saveForm()">
      <div class="form-section__box form-section__box--add">
        <mat-form-field class="form-section__form-field mat-no-border mat-full-width">
          <mat-label #fileUploadText>{{ 'translations.instruction' | transloco }}</mat-label>
          <input
            matInput
            type="text"
            required
            formControlName="title"
            [placeholder]="fileUploadText.innerHTML">
        </mat-form-field>
      </div>
      <div class="form-section__box form-section__form-upload form-section__box--no-border">
        <div class="file-size-box">
          <div class="file-size-title">{{ 'translations.maxUploadedFileSize.title' | transloco }}</div>
          <mat-form-field class="file-size-select">
            <mat-select formControlName="maxFileSizeMb"
                        [attr.aria-label]="('translations.global.ariaLabels.selectMaxUploadFileSize' | transloco)">
              <mat-option [value]="2">{{ 'translations.maxUploadedFileSize.label.2mb' | transloco }}</mat-option>
              <mat-option [value]="3">{{ 'translations.maxUploadedFileSize.label.3mb' | transloco }}</mat-option>
              <mat-option [value]="5">{{ 'translations.maxUploadedFileSize.label.5mb' | transloco }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>{{ 'translations.supportedFileTypes' | transloco: { supportedFileTypes: getSupportedFileTypes() } }}</div>
      </div>

      <div class="form-section__footer">
        <div class="form-section__footer-right">
          <button
            mat-button
            class="form-section__footer-btn"
            color="warn"
            type="button"
            title="Remove element"
            *ngIf="canRemoveItem"
            [attr.aria-label]="('translations.global.ariaLabels.deleteFileUploadForm' | transloco)"
            (click)="removeForm()">{{ 'translations.global.button.delete' | transloco }}
          </button>
          <button
            mat-flat-button
            class="form-section__footer-btn uppercase"
            color="primary"
            title="Save"
            type="submit"
            [attr.aria-label]="('translations.global.ariaLabels.addFileUploadForm' | transloco)">{{ 'translations.addToForm' | transloco }}
          </button>
        </div>
      </div>

    </form>

    <div class="form-section__form-contracted" *ngIf="!expanded">
      <div class="form-section__form-headline">
        <div class="form-section__form-contracted-text" tabindex="0">
          <span class="uppercase">{{ 'translations.form' | transloco }}</span>{{ fileUploadForm.value.title }}
        </div>
        <div class="form-section__form-contracted-actions">
          <button
            mat-button
            class="form-section__save-btn"
            title="Edit"
            color="primary"
            [attr.aria-label]="('translations.global.ariaLabels.editFileUploadForm' | transloco)"
            (click)="expandForm()">{{ 'translations.global.button.edit' | transloco }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="newVersionEnabled">


  <div class="form-section-new" (focusin)="focused=true" (focusout)="focused=false" (click)="expandForm()"
       (keydown.enter)="$event.stopPropagation()">
    <form
      class="form-block"
      [class.has-error]="hasError"
      #fileUploadFormHtml
      tabindex="0"
      [class.is_focused]="focused"
      [attr.aria-label]="('translations.global.ariaLabels.fileUploadForm' | transloco)"
      [formGroup]="fileUploadForm"
      (ngSubmit)="saveForm()">
      <div class="form-block-inner">
        <div class="form-block-title-box">
          <input
            type="text"
            class="form-block-input-field no-border full-width no-padding medium bold"
            required
            formControlName="title"
            [placeholder]="'translations.instruction' | transloco">
        </div>
        <ng-container *ngIf="expanded">
          <div class="form-block-help-text-box extra-small"></div>
          <div class="form-section-box small">
            <div class="file-size-box">
              <div class="file-size-title small">{{ 'translations.maxUploadedFileSize.title' | transloco }}</div>
              <mat-form-field class="file-size-select small">
                <mat-select formControlName="maxFileSizeMb"
                            [attr.aria-label]="('translations.global.ariaLabels.selectMaxUploadFileSize' | transloco)">
                  <mat-option
                    [value]="fileUploadSizes[0]">{{ 'translations.maxUploadedFileSize.label.5mb' | transloco }}
                  </mat-option>
                  <mat-option
                    [value]="fileUploadSizes[1]">{{ 'translations.maxUploadedFileSize.label.10mb' | transloco }}
                  </mat-option>
                  <mat-option
                    [value]="fileUploadSizes[2]">{{ 'translations.maxUploadedFileSize.label.25mb' | transloco }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="small">{{ 'translations.supportedFileTypes' | transloco: { supportedFileTypes: getSupportedFileTypes() } }}
            </div>
          </div>

          <button mat-icon-button type="button" class="form-edit-icon" [attr.aria-label]="('translations.global.ariaLabels.icons.file' | transloco)">
            <svg class="svg" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#upload"></use>
            </svg>
          </button>
        </ng-container>

        <span class="setting-text extra-small"
              *ngIf="!expanded">{{ 'translations.global.button.settings' | transloco }}</span>

      </div>
      <div class="form-block-buttons" *ngIf="expanded">
        <button
          mat-flat-button
          class="form-button-remove small f_delete-block"
          type="button"
          [title]="'translations.global.button.delete' | transloco "
          color="warn"
          *ngIf="canRemoveItem"
          [attr.aria-label]="('translations.global.ariaLabels.deleteTextBoxForm' | transloco)"
          (click)="removeForm()">
          <svg class="svg svg-icon icon-left" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
          </svg>
          <span>{{ 'translations.global.button.delete' | transloco }}</span>
        </button>
        <button
          mat-flat-button
          class="form-button-save small btn-with-loader f_update-block"
          [title]="!form?.uid ? ('translations.addToForm' | transloco) : ('translations.global.button.update' | transloco)"
          color="primary"
          type="submit"
          [attr.aria-label]="('translations.global.ariaLabels.addTextBoxForm' | transloco)">
          <svg class="svg svg-icon icon-left" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
          </svg>
          <span>{{ !form?.uid ? ('translations.addToForm' | transloco) : ('translations.global.button.update' | transloco) }}</span>
          <mat-spinner class="is-right" *ngIf="saveInProgress" [diameter]="20"></mat-spinner>
        </button>
        <button
          mat-flat-button
          class="form-button-save small f_cancel-block"
          [title]="'translations.global.button.cancel' | transloco"
          color="accent"
          type="button"
          (click)="collapseForm($event)"
          [attr.aria-label]="('translations.global.ariaLabels.collapseForm' | transloco)">
          <span>{{ 'translations.global.button.cancel' | transloco }}</span>
        </button>
      </div>

    </form>
  </div>
</ng-container>
