/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditorContent, ResourceTag } from '../../../../../../models';
import { Moment } from '../../../../../../../page-modules/folio/models';
import { LearnerLoggedItemRequestTimeLogPeriod } from '../../../../../../models/editor/collector-content.model';


export interface CollectorDetailDialogData {
  moment: Moment;
  logTemplate: EditorContent[];
  logTitle: string;
  tags: ResourceTag[];
  timestamp: LearnerLoggedItemRequestTimeLogPeriod;
  loggedDate: Date;
}

@Component({
  templateUrl: './collector-detail-dialog.component.html',
  styleUrls: ['./collector-detail-dialog.component.scss'],
})
export class CollectorDetailDialogComponent {

  moment: Moment;
  logTemplate: EditorContent[];
  momentTitle: string;
  logTitle: string;
  tags: ResourceTag[];
  timestamp: LearnerLoggedItemRequestTimeLogPeriod;
  loggedDate: Date;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CollectorDetailDialogData) {
    this.moment = data.moment;
    this.logTemplate = data.logTemplate;
    this.momentTitle = this.moment ? this.moment.title : '';
    this.logTitle = data.logTitle;
    this.tags = data.tags;
    this.timestamp = data.timestamp;
    this.loggedDate = data.loggedDate;
  }
}
