/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, Output, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../../../../shared/services/tags/tag-data.service';
import {
  CollectorConfigRequest,
  CollectorEvidencesType,
  CollectorLogFramework,
  CollectorLogFrameworksResponse,
  CollectorLogType, CollectorTagsResponse,
  CollectorTimeUnit
} from '../../../../../shared/models/editor/collector-content.model';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CollectorFormLogComponent } from '../collector-form-log/collector-form-log.component';
import { EditorContent, ResourceTag } from '../../../../../shared/models';
import { LanguageCodeHelper } from '../../../../../shared/helpers/language-code-helper';

@Component({
  selector: 'ptl-collector-form-content',
  templateUrl: './collector-form-content.component.html',
  styleUrls: ['./collector-form-content.component.scss'],
})
export class CollectorFormContentComponent implements OnInit, OnDestroy {

  @Input() collectorConfig: CollectorConfigRequest;

  @Input() collectorTags: CollectorTagsResponse;

  @Input() collectorFrameworks: CollectorLogFrameworksResponse[];

  @Output() collectorFormContent = new EventEmitter<CollectorConfigRequest>();

  frameworkTagsPanelOpen: boolean;
  newTagName: string;
  selectedTags: ResourceTag[] = [];
  selectedFrameworks: CollectorLogFramework[] = [];

  tagsCountError = false;
  tagsCount = 0;
  modalIndex: number;

  tagsType: 'SELECT_FRAMEWORK' | 'SET_TAGS' = 'SELECT_FRAMEWORK';

  private dialogSubscription: Subscription;

  constructor(
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    private dialog: MatDialog
  ) {
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const targetElement = event.target as Element;
    if (!targetElement.closest('.f_popup') && !targetElement.closest('.f_popup-title')) {
      this.hidePopup();
    }
  }

  ngOnInit() {
    if (!this.collectorConfig) {
      this.collectorConfig = this.getDefaultCollectorConfig();
    } else {
      this.setTagsFrameworksData();
    }
  }

  ngOnDestroy() {
    this.dialogSubscription?.unsubscribe();
  }

  showModal(index: number) {
    if (this.modalIndex === index) {
      this.modalIndex = undefined;
      return;
    }
    this.frameworkTagsPanelOpen = false;
    this.modalIndex = index;
  }

  onTimeStampChange(value: boolean) {
    this.collectorConfig.timeLogConfig.requireTimestamp = value;
    this.emitUpdate();
    this.hidePopup();
  }

  onLogTypeChange(value: CollectorLogType) {
    this.collectorConfig.logType = value;
    if (value === 'MOMENTS') {
      this.collectorConfig.evidencesType = 'MOMENTS';
    } else if (value === 'FORMS') {
      this.collectorConfig.evidencesType = 'FORMS';
    } else {
      this.collectorConfig.evidencesType = 'FORMS';
    }
    this.emitUpdate();
    this.hidePopup();
  }

  onNumberOfLogsChange(value: CollectorTimeUnit) {
    this.collectorConfig.timeLogConfig.requiredTimeUnit = value;
    this.emitUpdate();
    this.hidePopup();
  }

  onEvidencesTypeChange(value: CollectorEvidencesType) {
    this.collectorConfig.evidencesType = value;
    this.emitUpdate();
    this.hidePopup();
  }

  onFrameworkTypeChange(value: 'SELECT_FRAMEWORK' | 'SET_TAGS') {
    this.tagsType = value;
    this.selectedTags = [];
    this.selectedFrameworks = [];
    this.hidePopup();
  }

  onInputChange() {
    this.tagsCountError = false;
    this.emitUpdate();
  }

  onFrameworkBtnClick() {
    this.frameworkTagsPanelOpen = true;
  }

  openFormTemplates() {
    this.dialogSubscription = this.dialog.open(CollectorFormLogComponent, {
      width: '75rem',
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      backdropClass: 'collector-form-dialog-backdrop',
      data: this.collectorConfig.logTemplate,
    }).afterClosed().subscribe((content: EditorContent[]) => {
      if (content) {
        this.collectorConfig.logTemplate = content;
        this.emitUpdate();
      }
    });
  }

  addNewTag() {
    this.tagService.createTag({ title: this.newTagName }).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.onAddTagBulk([value]);
          this.newTagName = '';
          this.frameworkTagsPanelOpen = false;
        } else {
          this.tagService.findTagsWithTitleTerm(this.newTagName).subscribe((result) => {
            if (result.isSuccess) {
              for (const tag of result.value) {
                if (tag.title === this.newTagName) {
                  this.onAddTagBulk([tag]);
                  this.newTagName = '';
                  this.frameworkTagsPanelOpen = false;
                  break;
                }
              }
            }
          });
        }
      }
    );
  }

  deleteTag(tag: ResourceTag) {
    if (tag._id) {
      this.selectedTags = this.selectedTags.filter(item => item._id !== tag._id);
    } else {
      this.selectedTags = this.selectedTags.filter(item => item.title !== tag.title);
    }
    this.emitUpdate();
  }

  deleteFramework(framework: ResourceTag) {
    if (framework._id) {
      this.selectedFrameworks = this.selectedFrameworks.filter(item => item._id !== framework._id);
    } else {
      this.selectedFrameworks = this.selectedFrameworks.filter(item => item.title !== framework.title);
    }
    this.emitUpdate();
  }

  onAddTagBulk(tags: ResourceTag[]) {
    this.selectedTags = this.selectedTags.concat(tags);
    this.emitUpdate();
  }

  onAddFramework(frameworks: CollectorLogFramework[]) {
    this.selectedFrameworks = this.selectedFrameworks.concat(frameworks);
    this.emitUpdate();
  }

  private setTagsFrameworksData() {
    if (this.collectorFrameworks.length) {
      for (const framework of this.collectorFrameworks) {
        this.selectedFrameworks.push(framework.framework);
      }
      this.tagsCount = this.collectorFrameworks[0].minRequiredTags ? this.collectorFrameworks[0].minRequiredTags : 0;
      this.tagsType = 'SELECT_FRAMEWORK';
      this.selectedTags = [];
    } else {
      this.tagsType = 'SET_TAGS';
      this.selectedFrameworks = [];
      this.selectedTags = this.collectorTags ? this.collectorTags.tags : [];
      this.tagsCount = this.collectorTags.minRequiredTags ? this.collectorTags.minRequiredTags : 0;
    }
    this.emitUpdate();
  }

  private hidePopup() {
    this.modalIndex = undefined;
  }

  private emitUpdate() {
    /* eslint-disable */
    if (this.selectedTags.length) {
      this.collectorConfig.frameworks = null;
      this.collectorConfig.tags = {
        tagUids: this.getSelectedTagUids(),
        minRequiredTags: this.tagsCount ? this.tagsCount : 0,
      };
    } else if (this.selectedFrameworks.length) {
      this.collectorConfig.tags = null;
      const frameworks = [];
      for (const framework of this.selectedFrameworks) {
        frameworks.push({
          minRequiredTags: this.tagsCount ? this.tagsCount : 0,
          frameworkUid: framework._id,
        });
      }
      this.collectorConfig.frameworks = frameworks.length ? frameworks : null;
    } else {
      this.collectorConfig.frameworks = null;
      this.collectorConfig.tags = null;
    }

    this.collectorConfig.definedTagsCount = this.tagsCount;

    if (!this.collectorConfig.timeLogConfig.minTime || this.collectorConfig.timeLogConfig.minTime < 0) {
      this.collectorConfig.timeLogConfig.minTime = 1;
    }

    if (this.collectorConfig.timeLogConfig.maxTime !== null &&
      this.collectorConfig.timeLogConfig.maxTime < this.collectorConfig.timeLogConfig.minTime) {
      this.collectorConfig.timeLogConfig.maxTime = this.collectorConfig.timeLogConfig.minTime;
    }

    /* eslint-enable */
    this.collectorFormContent.emit(this.collectorConfig);
  }

  private getSelectedTagUids() {
    const tagIds = [];
    for (const tag of this.selectedTags) {
      tagIds.push(tag._id);
    }
    return tagIds;
  }

  private getDefaultCollectorConfig(): CollectorConfigRequest {
    return {
      timeLogConfig: {
        minTime: undefined,
        maxTime: undefined,
        requiredTimeUnit: 'HOURS',
        requireTimestamp: false,
      },
      logType: 'MOMENTS',
      evidencesType: 'MOMENTS',
      frameworks: null,
      tags: null,
      requiredNumberOfLogs: 0,
      logTemplate: [],
    };
  }

}
