<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="playlist-card-required-icon" [class.is-required]="required"
  [ngStyle]="{'top': (offsetTop / 16) + 'rem', 'left': (offsetLeft / 16) + 'rem'}"
  [attr.aria-label]="('translations.global.ariaLabels.cardIsRequired' | transloco)"
>
  <span title="Required" class="icon-box"></span>
</div>
