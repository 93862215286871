<!--
  ~ Copyright (C) 2019 - present by Potentially
  ~
  ~ Please see distribution for license.
  -->

<ptl-media-content-view
  [position]="content?.position || 'CENTER'"
  (removeMediaContent)="onCloseClick()"
  [isExternalContent]="true"
  [canRemoveItem]="canRemoveItem"
  (mediaContentReplaced)="onReplaceMediaContent($event)"
  (regularLinkReplaced)="onReplaceRegularLink($event)"
  (updateMediaPosition)="onUpdateMediaPosition($event)">
  <div class="content-media__oembed content-media__oembed--disabled" #oembedEl></div>
  <div class="external-content-summary--content">
    <p
      tabindex="0"
      class="external-content-summary--caption"
      [attr.aria-label]="('translations.global.ariaLabels.openUrl' | transloco: {url: content?.url})"
      (click)="openUrl()"
      (keydown.enter)="openUrl()">
      {{ content?.url }}
    </p>
  </div>
</ptl-media-content-view>

