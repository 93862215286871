import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { ROLES_DATA_SERVICE, RolesDataService } from 'src/app/page-modules/admin/services/roles/roles-data.service';
import { RoleSearchAutocompleteComponent } from 'src/app/shared/components/role-search-autocomplete/role-search-autocomplete.component';
import { LanguageCodeHelper } from 'src/app/shared/helpers/language-code-helper';
import { LanguageValue, OrganizationRoles } from 'src/app/shared/models';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from 'src/app/shared/services/toast-notifications/toast-service';
import { TranslationService } from '../../../../../../shared/services/translation/translation.service';

@Component({
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.scss'],
})
export class EditRoleDialogComponent implements OnInit, OnDestroy {

  @Input() checkedMemberIds: string[];

  @Output() refreshList = new EventEmitter<number>();

  loadingRoles: boolean;
  searchString: string;
  selectedRoles: OrganizationRoles[] = [];
  searchedRoles: OrganizationRoles[] = [];

  private rolesSearchPage = 0;
  private rolesSearchPageSize = 10;
  private searchInputSubscription: Subscription;
  private searchInputSubject = new Subject<string>();

  @ViewChild('roleSearchAutocomplete') private roleSearchAutocomplete: RoleSearchAutocompleteComponent;

  constructor(
    private translationService: TranslationService,
    private dialogRef: MatDialogRef<EditRoleDialogComponent>,
    @Inject(ROLES_DATA_SERVICE) private rolesDataService: RolesDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: { checkedMemberIds: string[] }
  ) {
    if (this.data) {
      this.checkedMemberIds = data.checkedMemberIds;
    }
  }

  ngOnInit() {
    this.searchInputSubscription = this.searchInputSubject
      .pipe(debounceTime(500)).subscribe(() => this.fireSearch(true));
  }

  ngOnDestroy() {
    this.searchInputSubscription?.unsubscribe();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSearchInputChange(searchValue: string) {
    this.searchString = searchValue;
    this.loadingRoles = true;
    this.searchInputSubject.next(searchValue);
  }

  onSearchLoadingMore(): void {
    this.fireSearch(false);
  }

  onRoleSelected(data: OrganizationRoles) {
    this.selectedRoles =
      this.selectedRoles.findIndex((value) => value._id === data._id) === -1
      ? this.selectedRoles.concat(data)
      : this.selectedRoles;
  }

  removeSelectedRole(role: OrganizationRoles) {
    this.selectedRoles = this.selectedRoles.filter((r) => r !== role);
  }

  private fireSearch(override: boolean): void {
    this.rolesSearchPage = override ? 0 : this.rolesSearchPage + 1;
    if (!this.searchString) {
      this.searchString = '';
    }
    this.rolesDataService.loadRolesByPageWithSearch(this.searchString, this.rolesSearchPage, this.rolesSearchPageSize)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          if (override) {
            this.searchedRoles = value.content;
          } else {
            this.searchedRoles = this.searchedRoles.concat(value.content);
          }
        }
        this.roleSearchAutocomplete.canLoadMore = value.totalNumberOfElement > this.searchedRoles.length;
        this.roleSearchAutocomplete.isLoadingMore = false;
        this.loadingRoles = false;
      });
  }

  removeRoles() {
    this.rolesDataService.removeMultipleRolesFromMultipleUsers(
      this.selectedRoles.map(it => it._id), this.checkedMemberIds
    ).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        this.toastService.showSuccess(this.translationService.getTranslation('successes.successRemoveRolesFromUsers'));
      } else {
        this.toastService.showFail(error);
      }
      this.closeDialog();
      this.refreshList.emit(this.checkedMemberIds.length);
    });
  }

  addRoles() {
    this.rolesDataService.assignMultipleRolesToMultipleUsers(
      this.selectedRoles.map(it => it._id), this.checkedMemberIds
    ).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        this.toastService.showSuccess(this.translationService.getTranslation('successes.successAssignRolesToUsers'));
      } else {
        this.toastService.showFail(error);
      }
      this.closeDialog();
      this.refreshList.emit(this.checkedMemberIds.length);
    });
  }

  getNameOfRole(roleNames: LanguageValue[]): string {
    return LanguageCodeHelper.getDataByLanguageCode(roleNames).value;
  }
}
