<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<main
  class="main"
  [ngStyle]="{
    'background-image': (organizationData$ | async)?.landingPage.backgroundImage ? 'url(&quot;' +  (organizationData$ | async)?.landingPage.backgroundImage + '&quot;)' : '',
    'background-color': (organizationData$ | async)?.landingPage.useBackgroundColourFill ? (organizationData$ | async)?.landingPage.backgroundColour : '',
  }"
  [class.dynamic-background-color]="(organizationData$ | async)?.landingPage.useBackgroundColourFill"
  [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">

  <div class="maintenance-layer" *ngIf="showMaintenanceOverlay">
    <div class="maintenance-layer-inner" [innerHTML]="'translations.maintenance.overlayText' | transloco"></div>
  </div>

  <div class="login__form-container"
       [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
    <mat-card class="main__login"
              [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
      <img
        class="logo__image" width="240" height="80"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
        src="{{ (organizationData$ | async)?.landingPage.landingLogo || (organizationData$ | async)?.logo }}"
        alt="{{(organizationData$ | async)?.name}} Logo"/>
      <div class="login__form-inner"
           [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">

        <div class="login__university-info">
          <h1 class="title">{{ (organizationData$ | async)?.name }}</h1>
          <p class="description">{{ (organizationData$ | async)?.landingPage.welcomeMessage }}</p>
        </div>
      </div>
      <ngx-invisible-recaptcha #captchaElem
                               [siteKey]="reCaptchaSiteKeyV2"
                               (success)="proceedJoin($event)"
                               (reset)="resetCaptcha()"
                               [ngModel]="reCaptchaElement"
                               [ngModelOptions]="{ standalone: true }"
                               [useGlobalDomain]="false">
      </ngx-invisible-recaptcha>
      <button
        mat-flat-button
        type="submit"
        class="form__submit"
        color="primary"
        [disabled]="!joinReady"
        (click)="onJoinButtonClick()">
        <span class="uppercase">
          {{ 'translations.public.button.join' | transloco }} {{ (organizationData$ | async)?.name }}</span>
      </button>
      <div
        *ngIf="false"
        class="login__powered-content"
        [attr.aria-label]="('translations.global.ariaLabels.poweredBy' | transloco: {organizationName: (organizationData$ | async)?.name})"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'">
        {{ 'translations.global.label.poweredBy' | transloco }}
        <a href="https://potential.ly"
           [class.is_light]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
           class="login__powered-content-link"
           [attr.aria-label]="('translations.global.ariaLabels.open' | transloco: {title: (organizationData$ | async)?.name})">
          <img
            class="login__powered-image" width="40" height="40"
            src="https://static.yuna.potential.ly/public/images/icons/potentially_logo.png"
            alt=""/>
          {{ 'translations.public.title.logoText' | transloco }}
        </a>
      </div>
    </mat-card>
  </div>
</main>

