/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetReviewDetails, SetReviewDetails } from './review.actions';

export interface ReviewStateModel {
  reviewUid: string;
  userUid: string;
  cardUid: string;
  playlistUid: string;
  learnerFirstName: string;
  learnerLastName: string;
  learnerImage: string;
}

export const INITIAL_STATE: ReviewStateModel = {
  reviewUid: undefined,
  userUid: undefined,
  cardUid: undefined,
  playlistUid: undefined,
  learnerFirstName: undefined,
  learnerLastName: undefined,
  learnerImage: undefined,
};

@State<ReviewStateModel>({
  name: 'review',
  defaults: INITIAL_STATE,
})
@Injectable()
export class ReviewState {
  @Action(SetReviewDetails)
  setReviewDetails({ patchState, getState }: StateContext<ReviewStateModel>,
                   reviewDetails: SetReviewDetails
  ) {
    const state = getState();
    patchState({
      ...state,
      reviewUid: reviewDetails.reviewUid,
      userUid: reviewDetails.userUid,
      cardUid: reviewDetails.cardUid,
      playlistUid: reviewDetails.playlistUid,
      learnerFirstName: reviewDetails.learnerFirstName,
      learnerLastName: reviewDetails.learnerLastName,
      learnerImage: reviewDetails.learnerImage,
    });
  }

  @Action(GetReviewDetails)
  getReviewDetails({ getState }: StateContext<ReviewStateModel>) {
    return getState();
  }
}
