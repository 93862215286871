<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div class="chart-section">
  <div class="chart-section__top">
    <div class="chart-section__select-option">
      <span *ngIf="!isEditing">
        {{ selectedChartTitle }}
        <span>{{ 'translations.chartTitle' | transloco }}</span>
      </span>
    </div>
    <div class="chart-section__actions">
      <button
        *ngIf="isEditing"
        mat-flat-button
        class="uppercase"
        title="save"
        type="button"
        color="primary"
        [attr.aria-label]="('translations.global.ariaLabels.saveChart' | transloco)"
        (click)="saveChart()">{{ 'translations.global.button.save' | transloco }}
      </button>
      <button
        *ngIf="!isEditing"
        mat-flat-button
        class="uppercase"
        title="save"
        type="button"
        color="primary"
        [attr.aria-label]="('translations.global.ariaLabels.editChart' | transloco)"
        (click)="editChart()">{{ 'translations.global.button.edit' | transloco }}
      </button>
      <button
        mat-icon-button
        class="chart-section__delete-btn"
        title="Edit card"
        [attr.aria-label]="('translations.global.ariaLabels.deleteChart' | transloco)"
        *ngIf="canRemoveItem"
        type="button"
        (click)="deleteChart()">
        <mat-icon aria-hidden="true">clear</mat-icon>
      </button>
    </div>
  </div>


  <div *ngIf="isEditing" class="chart-section-container">
    <div class="chart-section-top">
      <div class="chart-section-top-left">
        <div class="tab-bar-container">
          <div class="tab-bar-container-inner">
            <div class="option-button small"
                 type="button"
                 [class.selected]="selectedChartType === chart.type"
                 (click)="onChartChange(chart.type)"
                 (keydown.enter)="onChartChange(chart.type)"
                 *ngFor="let chart of charts">
              {{ chart.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="chart-section-top-right">
        <mat-slide-toggle color="primary"
                          [(ngModel)]="percentageShown"
                          class="custom-toggle mat-slide-custom">
          <span class="small">{{ 'translations.diagnostics.labels.percentage' | transloco }}</span>
        </mat-slide-toggle>
      </div>
    </div>

    <div class="chart-section-body">
      <div class="chart-section-progress">
        <img class="chart-radial" *ngIf="selectedChartType === 'RADIAL'" src="../assets/chart-radial.jpg" alt="chart">
        <img class="chart-bar" *ngIf="selectedChartType === 'BAR'" src="../assets/chart-bar.jpg" alt="chart">
      </div>

      <div class="chart-section-colors">
        <div
          cdkDropList
          [cdkDropListData]="learnerGoals"
          (cdkDropListDropped)="dropOption($event)"
          class="chart-section-learner-list chart-section-learner-list_icons">
          <div cdkDrag cdkDragLockAxis="y" class="chart-section-learner-item"
               *ngFor="let learnerGoal of learnerGoals; let first = first; let last = last">
            <button mat-icon-button cdkDragHandle>
              <svg class="svg" role="img"
                   [attr.aria-label]="('translations.global.ariaLabels.icons.draggable' | transloco)">
                <use ogSvgUnify="assets/sprite/sprite.svg#drag-indicator"></use>
              </svg>
            </button>
            <div class="chart-section-learner-item-title">{{learnerGoal.title}}</div>
            <div class="color-code-block">
              <span class="color-code-preview" [style.background-color]="learnerGoal.color"></span>
              <input
                readonly
                class="color-code-picker"
                [attr.aria-label]="('translations.global.ariaLabels.selectGoalBadgeColor' | transloco)"
                [(colorPicker)]="learnerGoal.color"
                [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                [style.background]="learnerGoal.color"/>
              <span class="color-code-value small">{{learnerGoal.color}}</span>
            </div>
            <div class="chart-section__learner-item-checkbox">
              <button mat-icon-button class="visibility-icon"
                      [attr.aria-label]="('translations.global.ariaLabels.tapToShowHideChartSkill' | transloco: {
                       title: learnerGoal.title,
                       state:  learnerGoal.checked ? ('translations.global.ariaLabels.hideState' | transloco) :
                       ('translations.global.ariaLabels.showState' | transloco)
                       })"
                      (click)="onCheckboxChange(learnerGoal)">
                <svg class="svg" role="img" *ngIf="!learnerGoal.checked"
                     [attr.aria-label]="('translations.global.ariaLabels.icons.openedEye' | transloco)">
                  <use ogSvgUnify="assets/sprite/sprite.svg#visibility-off"></use>
                </svg>
                <svg class="svg" role="img" *ngIf="learnerGoal.checked"
                     [attr.aria-label]="('translations.global.ariaLabels.icons.closedEye' | transloco)">
                  <use ogSvgUnify="assets/sprite/sprite.svg#visibility"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="chart-section__body chart-section__body-preview" *ngIf="!isEditing">
    <img *ngIf="selectedChartType === 'RADIAL'" src="../assets/chart-radial.jpg" width="302" height="302" alt="chart">
    <img *ngIf="selectedChartType === 'BAR'" src="../assets/chart-bar.jpg" width="813" height="365" alt="chart">
  </div>

</div>


