<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="like-info__item-container" [ngClass]="{
  'large' : isLarge,
   'vertical': isVertical }">
  <button
    mat-icon-button
    class="like-info__vote-btn"
    [ngClass]="{
        'edit-mode' : editMode,
        'large' : isLarge,
        'mat-menu-item': isActionButton && showButtonTitle
      }"
    [title]="!editMode ? ((vote ? 'Unlike ' : 'Like ') + title) : 'Vote disabled'"
    (click)="onVote(); $event.stopPropagation();"
    (keydown.enter)="$event.stopPropagation();">
    <i class="ptl-icon-facebook-like" aria-hidden="true" *ngIf="!(vote && !editMode)"></i>
    <img
      class="like-icon"
      alt="" width="20" height="20"
      *ngIf="(vote && !editMode)"
      [src]=" isActionButton ? '/assets/icons/like-filled-royal.svg' : '/assets/icons/like-filled.svg'">
    <span *ngIf="isActionButton && showButtonTitle">
        {{ 'translations.global.button.like' | transloco }} {{ totalLikes ? '(' + totalLikes + ')' : '' }}
      </span>
    <span *ngIf="isActionButton && !showButtonTitle">
        {{ totalLikes ? totalLikes : '' }}
      </span>
  </button>
  <div
    *ngIf="!isActionButton"
    class="like-info__total-likes"
    [title]="getLikeCountTitle()"
    [style.visibility]="totalLikes ? 'visible' : 'hidden'">{{ totalLikes ? totalLikes : '' }}</div>
</div>
