/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { SnackbarHelper } from '../../helpers/snackbar-helper';
import { TranslationService } from '../../services/translation/translation.service';
import {
  EVENT_CARDS_DATA_SERVICE,
  EventCardsDataService
} from '../../../page-modules/event-cards/services/data.service';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '@app/app/shared/services/toast-notifications/toast-service';
import { ResourceAdminState } from '../../../page-modules/resource/store/admin/resource-admin.state';
import * as ResourceAdminActions from '../../../page-modules/resource/store/admin/resource-admin.actions';
import { EventTicketsDetails, Resource } from '../../models';
import { DialogService } from '@app/app/shared/helpers/dialog/dialog.service';
import { EventCardsHelper } from '@app/app/shared/helpers/event-cards-helper';
import {
  EventCardTime,
  EventTickets,
  EventTicketsType
} from '../../../page-modules/resource/store/admin/resource-event-admin.state.model';
import { TimeZoneDisplay, TimezoneHelper, TimeZoneMap } from '@app/app/shared/helpers/timezone/timezone-helper';

const DATE_PICKER_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

class DateRangeAdapter extends NativeDateAdapter {

  public override format(date: Date, displayFormat: string): string {
    return moment(date).format(displayFormat);
  }
}

@Component({
  selector: 'ptl-event-tickets',
  templateUrl: './event-tickets.component.html',
  styleUrls: ['./event-tickets.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: DateRangeAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_FORMATS,
    },
  ],
})
export class EventCardsTicketsComponent implements OnInit, OnDestroy {

  @Select(ResourceAdminState.resource)
  private resource$: Observable<Resource>;

  @Input() editingEnabled: boolean;
  @Input() enrollment = false;
  @Input() enrollmentTickets?: EventTickets | EventTicketsDetails;
  @Input() enrollmentTime: EventCardTime;
  @Output() enrollmentTicketsUpdated = new EventEmitter<EventTicketsDetails>();
  @Output() enrollmentTicketsToggled = new EventEmitter<boolean>();
  /** Emits when tickets was modified or saved. */
  @Output() ticketsWasUpdatedOrSaved = new EventEmitter<boolean>();

  ticketsEnabled = false;
  eventTicketsQuantity: number;
  eventTicketsAvailability: EventTicketsType = 'UNLIMITED';
  dateError = false;
  timeError = false;
  isButtonClicked = false;
  minDate = new Date();
  startedDate: Date;
  endedDate: Date;
  startedTime: string;
  endedTime: string;
  timeSelect: string[] = [];
  timeZones: TimeZoneDisplay[] = TimezoneHelper.getTimeZoneDisplayList();
  timeZonesMapList: TimeZoneMap = TimezoneHelper.getMapFormattedDisplayTimezoneList();
  selectedTimezone: string;
  endDateActive = false;
  waitingListEnabled = false;
  dataHasUpdated: boolean;
  shouldDisplayDataVal = false;
  eventTimeError = false;

  private resource: Resource;
  private preventDateCorrection = false;
  private eventTimeFromState: EventCardTime;
  private contentId: string;
  private eventTicketsFromState: EventTickets;
  private contentSubscription: Subscription;

  constructor(
    private store: Store,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
    private dialogService: DialogService,
    @Inject(EVENT_CARDS_DATA_SERVICE) private eventCardsDataService: EventCardsDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService
  ) {
  }

  ngOnInit() {
    this.onDataSave();
    this.buildTimeSelect();
    this.setDefaultState();
    if (!this.enrollment) {
      this.contentSubscription = this.resource$.subscribe(resource => {
        this.resource = resource;
        this.contentId = this.resource?._id;
        this.eventTimeFromState = this.resource?.time;
        this.eventTicketsFromState = this.resource?.tickets;
        this.setStateFromContentSubscription(this.eventTicketsFromState, this.eventTimeFromState);
        this.validateEventTime();
      });
    } else {
      this.ticketsEnabled = true;
      this.eventTimeFromState = this.enrollmentTime;
      this.eventTicketsFromState = this.enrollmentTickets as EventTickets;
      this.setStateFromContentSubscription(this.enrollmentTickets, this.enrollmentTime);
    }
  }

  ngOnDestroy() {
    this.contentSubscription?.unsubscribe();
  }

  setDefaultState() {
    const date = new Date().toString();
    this.selectedTimezone = date.substr(date.indexOf('GMT'), 8);
  }

  setStateFromContentSubscription(ticketsSettings: EventTickets | EventTicketsDetails, timeSettings: EventCardTime) {
    if (timeSettings) {
      this.setSelectedTimeZone(timeSettings.timeZone);
      this.setDefaultDateAndTime(timeSettings);
    }
    if (ticketsSettings) {
      this.ticketsEnabled = true;
      this.eventTicketsQuantity = ticketsSettings.quantity;
      this.eventTicketsAvailability = ticketsSettings.availability;
      this.waitingListEnabled = ticketsSettings.waitingList;
      if (ticketsSettings?.time) {
        this.setSelectedDateAndTime(ticketsSettings.time.start, 'start');
        if (ticketsSettings.time.end) {
          this.endDateActive = true;
          this.setSelectedDateAndTime(ticketsSettings.time.end, 'end');
        }
        this.setSelectedTimeZone(ticketsSettings.time.timeZone);
      }
    }
  }


  onDateInputFocus() {
    this.preventDateCorrection = true;
  }

  onDateInputBlur() {
    this.preventDateCorrection = false;
    this.onDateChanged();
  }

  onDateChanged() {
    this.onDataUpdate();
    if (this.preventDateCorrection) {
      return;
    }
    this.validateDateInputs();
  }

  onTimeUpdate() {
    this.onDataUpdate();
    this.timeError = false;
    if (this.startedTime && this.endedTime) {
      this.validateTimeInputs(true);
    }
  }

  update() {
    const isValidData = this.validateSendingData();
    if (!isValidData) {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'selectEventStartDate');
      return;
    }
    if (!this.eventTicketsAvailability) {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'eventChooseTickets');
      return;
    }
    if (this.eventTicketsAvailability === 'LIMITED' && !this.eventTicketsQuantity) {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'eventChooseTicketsQuantity');
      return;
    }
    if (this.dateError) {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'eventStartDateError');
      return;
    }
    if (this.timeError) {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'eventStartTimeError');
      return;
    }
    if (!this.selectedTimezone) {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'eventTimezoneError');
      return;
    }
    if (this.endDateActive && !this.isValidEndDate()) {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'eventTicketsEndDateError');
      return;
    }
    if (this.eventTimeError) {
      SnackbarHelper.showTranslatableSnackBar(
        this.ngZone,
        this.snackBar,
        this.translationService,
        'eventStartTimeOverlapsOnEventTicketsError'
      );
      return;
    }

    this.isButtonClicked = true;
    const request = {
      availability: this.eventTicketsAvailability,
      quantity: this.eventTicketsAvailability === 'LIMITED' ? this.eventTicketsQuantity : null,
      time: {
        ...this.getRequestDate(),
        timeZone: this.selectedTimezone,
      },
      waitingList: this.waitingListEnabled
    };
    if (this.enrollment === false) {
      this.eventCardsDataService.setEventTickets(this.contentId, request)
        .subscribe(({ isSuccess, value, error }) => {
          this.isButtonClicked = false;
          if (!isSuccess) {
            this.toastService.showFail(error);
            return;
          }
          this.onDataSave();
          this.store.dispatch(new ResourceAdminActions.SetEventTickets(value));
          this.toastService.showSuccess(this.translationService.getTranslation('successes.successUpdateEventTickets'));
        });
    } else {
      this.enrollmentTicketsUpdated.emit(request);
      this.isButtonClicked = false;
    }
  }

  cancel() {
    this.isButtonClicked = true;
    this.ticketsEnabled = false;
    this.eventTicketsQuantity = undefined;
    this.eventTicketsAvailability = undefined;
    this.dateError = false;
    this.timeError = false;
    this.startedDate = undefined;
    this.endedDate = undefined;
    this.startedTime = undefined;
    this.endedTime = undefined;
    this.selectedTimezone = undefined;
    this.endDateActive = false;
    this.waitingListEnabled = false;
    this.setDefaultState();
    this.setStateFromContentSubscription(this.eventTicketsFromState, this.eventTimeFromState);
    this.onDataSave();
    this.isButtonClicked = false;
    this.eventTimeError = false;
  }

  selectAvailability(type: EventTicketsType) {
    this.onDataUpdate();
    this.eventTicketsAvailability = type;
  }

  onDataSave() {
    this.dataHasUpdated = false;
    this.ticketsWasUpdatedOrSaved.emit(this.dataHasUpdated);
  }

  onDataUpdate() {
    this.dataHasUpdated = true;
    this.ticketsWasUpdatedOrSaved.emit(this.dataHasUpdated);
  }

  switchTicketsToggle(e) {
    if (this.ticketsEnabled) {
      e.source.checked = true;
      this.dialogService.showConfirmDialog(
        this.translationService.getTranslation('dialog.title.eventTicketsDisable'),
        this.translationService
      ).then(confirmed => {
        if (confirmed && !this.enrollment) {
          this.deleteTickets();
        }
      });
    } else {
      this.onDataUpdate();
      this.ticketsEnabled = true;
    }
    this.enrollmentTicketsToggled.emit(e.checked)
  }

  showEndDateForm() {
    this.onDataUpdate();
    this.endDateActive = true;
    this.onDateChanged();
    this.onTimeUpdate();
  }

  hideEndDateForm() {
    this.onDataUpdate();
    this.endDateActive = false;
    this.onDateChanged();
    this.onTimeUpdate();
  }

  toggleWaitingList() {
    this.onDataUpdate();
    this.waitingListEnabled = !this.waitingListEnabled;
  }

  shouldDisplayData() {
    return this.ticketsEnabled && this.editingEnabled;
  }

  private deleteTickets() {
    this.eventCardsDataService.deleteEventTickets(this.contentId)
      .subscribe(({ isSuccess, value, error }) => {
        this.isButtonClicked = false;
        if (!isSuccess) {
          this.toastService.showFail(error);
          return;
        }
        this.onDataSave();
        this.ticketsEnabled = false;
        this.store.dispatch(new ResourceAdminActions.SetEventTickets(value));
        this.toastService.showSuccess(this.translationService.getTranslation('successes.successUpdateEventTickets'));
      });
  }

  private setDefaultDateAndTime(eventTime: EventCardTime) {
    const current = moment();
    const eventStart = moment(eventTime.start).format('YYYY-MM-DD');
    const daysDiff = moment(eventStart).diff(moment(current.format('YYYY-MM-DD')), 'days');
    let startDate;
    let startTime;
    if (daysDiff > 0) {
      const roundUpNextHour = current.minute() || current.second() || current.millisecond() ?
        current.add(1, 'hour').startOf('hour') : current.startOf('hour');
      startDate = moment(eventTime.start).subtract(daysDiff, 'days');
      startTime = cloneDeep(roundUpNextHour);
      this.startedDate = new Date(startDate.format('YYYY-MM-DD'));
      this.startedDate.setHours(0, 0, 0, 0);
      this.startedTime = startTime.format('HH:mm');
    } else {
      const next15Minutes = moment().add(15, 'minutes');
      next15Minutes.minutes(Math.floor(next15Minutes.minutes() / 15) * 15);
      startDate = moment(eventTime.start);
      startTime = cloneDeep(next15Minutes);
      this.startedDate = new Date(startDate.format('YYYY-MM-DD'));
      this.startedDate.setHours(0, 0, 0, 0);
      this.startedTime = startTime.format('HH:mm');
    }
    const endDate = moment(eventTime.start);
    this.endedDate = new Date(endDate.format('YYYY-MM-DD'));
    this.endedDate.setHours(0, 0, 0, 0);
    this.endedTime = endDate.format('HH:mm');
  }

  private buildTimeSelect() {
    const minutes = ['00', '15', '30', '45'];
    for (let i = 0; i < 24; i++) {
      let hour = `${i}`;
      if (i < 10) {
        hour = `0${i}`;
      }
      for (const minute of minutes) {
        this.timeSelect.push(`${hour}:${minute}`);
      }
    }
  }

  private setSelectedTimeZone(timeZone: string) {
    if (!timeZone) {
      return;
    }
    if (timeZone === 'GMT') {
      this.selectedTimezone = 'GMT+0000';
    } else {
      this.selectedTimezone = timeZone.replace(':', '');
    }
  }

  private setSelectedDateAndTime(selectedTime: string, type: string) {
    const dateArray = selectedTime.split(' ');
    if (type === 'start') {
      this.startedDate = new Date(dateArray[0]);
      this.startedDate.setHours(0, 0, 0, 0);
      this.startedTime = dateArray[1];
    } else {
      this.endedDate = new Date(dateArray[0]);
      this.endedDate.setHours(0, 0, 0, 0);
      this.endedTime = dateArray[1];
    }
  }

  private validateTimeInputs(shouldValidateEventTime: boolean) {
    let startedDate;
    let endedDate;
    if (!this.startedDate && !this.endedDate) {
      startedDate = new Date('01/01/2020 ' + this.startedTime).getTime();
      endedDate = new Date('01/01/2020 ' + this.endedTime).getTime();
      if (startedDate >= endedDate) {
        this.timeError = true;
      }
    } else {
      startedDate = new Date(this.startedDate).getTime();
      endedDate = new Date(this.endedDate).getTime();
      if (startedDate !== endedDate) {
        this.timeError = false;
      } else {
        startedDate = new Date('01/01/2020 ' + this.startedTime).getTime();
        endedDate = new Date('01/01/2020 ' + this.endedTime).getTime();
        this.timeError = (startedDate >= endedDate);
      }
    }
    if (shouldValidateEventTime) {
      this.validateEventTime();
    }
  }

  private validateDateInputs() {
    this.dateError = false;
    this.eventTimeError = false;
    if (this.endDateActive) {
      if (this.startedDate && this.endedDate) {
        const startedDate = new Date(new Date(this.startedDate).toDateString()).getTime();
        const endedDate = new Date(new Date(this.endedDate).toDateString()).getTime();
        this.dateError = (startedDate > endedDate);
        if (this.startedTime && this.endedTime) {
          this.validateTimeInputs(false);
        }
      } else {
        this.dateError = true;
      }
    } else {
      if (this.startedDate) {
        if (!this.startedTime) {
          this.timeError = true;
        }
      } else {
        this.dateError = true;
      }
    }
    this.validateEventTime();
  }

  private validateEventTime() {
    this.eventTimeError = false;
    if (!this.dateError && !this.timeError) {
      if (this.endDateActive) {
        if (!EventCardsHelper.validateNewEventTicketsTime(
          this.startedDate,
          this.startedTime,
          this.endedDate,
          this.endedTime,
          this.selectedTimezone,
          this.eventTimeFromState
        )
        ) {
          this.eventTimeError = true;
        }
      } else {
        if (!EventCardsHelper.validateNewEventTicketsTime(
          this.startedDate,
          this.startedTime,
          undefined,
          undefined,
          this.selectedTimezone,
          this.eventTimeFromState
        )
        ) {
          this.eventTimeError = true;
        }
      }
    }
  }

  private validateSendingData() {
    if (this.endDateActive) {
      return !(!this.startedDate || !this.endedDate || !this.startedTime || !this.endedTime || !this.selectedTimezone);
    } else {
      return !(!this.startedDate || !this.startedTime || !this.selectedTimezone);
    }
  }

  private isValidEndDate() {
    if (!this.eventTimeFromState && this.enrollment) {
      return true;
    }

    if (!this.eventTimeFromState) {
      return false;
    }
    const eventStartDate = moment(this.eventTimeFromState.start);
    const dateObject = new Date(this.endedDate);
    const dateString = `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()} ${this.endedTime}`;
    const ticketEndDate = moment(new Date(dateString));
    return ticketEndDate.isSameOrBefore(eventStartDate);
  }

  private getRequestDate() {
    let dateObject = new Date(this.startedDate);
    let dateString = `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()} ${this.startedTime}`;
    const startDate = moment(new Date(dateString)).format('YYYY-MM-DD HH:mm');
    if (this.endDateActive) {
      dateObject = new Date(this.endedDate);
      dateString = `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()} ${this.endedTime}`;
      const endDate = moment(new Date(dateString)).format('YYYY-MM-DD HH:mm');
      return {
        start: startDate,
        end: endDate,
      };
    } else {
      return {
        start: startDate,
        end: undefined,
      };
    }
  }
}
