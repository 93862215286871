<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="bar-chart-content" [class.without-icon]="!iconExists">
  <div class="bar-chart-content-inner">
    <div class="bar-chart-items">
      <div class="grid-lines-element"><span></span></div>
      <div class="bar-chart-row-item"
           [class.single-item]="datasets?.length === 1"
           *ngFor="let label of chartSectionItems; let i = index">
        <div class="bar-chart-title"
             (keydown.enter)="scrollTo(label.sectionUid)"
             (click)="scrollTo(label.sectionUid)">
          {{ label.title }}
        </div>
        <div class="bar-chart-row">
          <div class="bar-chart-icon" *ngIf="iconExists">
            <img src="{{ chartContent?.items[i].icon.iconImageUrl }}"
                 width="30"
                 height="30"
                 alt=""
                 *ngIf="chartContent?.items[i].icon && chartContent?.items[i].icon.iconImageUrl"/>
          </div>
          <div class="bar-chart-progress-box">
            <div class="bar-chart-progress-item" *ngFor="let data of datasets; let j = index;">
              <div class="bar-chart-progress-item-runner" [style.width]=" data.data[i]+ '%'" [style.background]=" data.backgroundColor[i]"></div>
              <div class="hovered-box">
                <div class="hovered-box-title">
                  <ng-container *ngIf="j === 0">
                    <span [style.background]=" data.backgroundColor[i]"></span> {{completedEndText}} {{ chartContent?.showPercentage ? data.data[i] + '%' : '' }}
                  </ng-container>
                  <ng-container *ngIf="j === 1">
                    <span [style.background]=" data.backgroundColor[i]"></span> {{completedStartText}} {{ chartContent?.showPercentage ? data.data[i] + '%' : '' }}
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bar-chart-percentage" *ngIf="chartContent?.showPercentage">
      <div class="percentage-item" *ngFor="let item of [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]">{{item}}%</div>
    </div>
    <div class="bar-chart-icons" *ngIf="chartContent?.iconXMax || chartContent?.iconXMin">
      <div class="icon-box min-icon">
        <img src="{{ chartContent?.iconXMin }}" width="30" height="30" aria-hidden="true" alt=""/>
      </div>
      <div class="icon-box max-icon">
        <img src="{{ chartContent?.iconXMax }}" width="30" height="30" aria-hidden="true" alt=""/>
      </div>
    </div>
  </div>

</div>
