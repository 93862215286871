import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileName'
})
export class PictureNamePipe implements PipeTransform {

    transform(url: string): string {
        const urlObject = new URL(url);
        const path = urlObject.pathname;
        const parts = path.split('/');
        const pictureName = parts[parts.length - 1];
        return pictureName;
    }
}