import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string | Date, format: string = 'YYYY-MM-DD HH:mm'): string {
    if (!value) {
      return '';
    }

    const utcDate = moment.utc(value);

    const localDate = utcDate.add(this.getLocalTimeZone(), 'hours');

    return localDate.format(format);
  }

  private getLocalTimeZone(): number {
    return new Date().getTimezoneOffset() / -60;
  }
}
