/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';

@Component({
  templateUrl: './iframe-logout.component.html',
  styleUrls: ['./iframe-logout.component.scss'],
})
export class IframeLogoutComponent {

  domainName: string;
  constructor() {
    this.domainName = location.hostname;
  }


}
