import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BooleanFilterType, CardHeaders, FormHeaders } from '../../../../../shared/models/admin/members.model';
import {
  ActivityRequest, AndRequest, BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  CardStatusRequest,
  CompletionStatus, FormStatusRequest,
  GroupsRequest,
  NotRequest,
  PlaylistStatusRequest, ResourcesStatusRequest,
  RolesRequest
} from '../../../../../shared/models/admin/boolean-filters.model';
import { MembersState } from '../store/members.state';
import { CardFilter, PlaylistFilter } from '../store/members.state.model';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { UpdateCardFilters, UpdateMemberFilters, UpdatePlaylistFilters } from '../store/members.action';
import { FiltersHelper } from '../../../../filters-helper';


@Component({
  selector: 'ptl-boolean-member-filters',
  templateUrl: './boolean-member-filters.component.html',
  styleUrls: ['./boolean-member-filters.component.scss'],
})
export class BooleanMemberFiltersComponent implements OnInit, OnDestroy {

  @Input() isCardPage: boolean;
  @Input() isPlaylistPage: boolean;
  @Input() cardUid: string;
  @Input() playlistUid: string;
  @Input() cardHeaders: CardHeaders[];
  @Input() formHeaders: FormHeaders[];
  @Output() filterChanged = new EventEmitter<AndRequest>();

  @Select(MembersState.memberFilters)
  private filters$: Observable<BooleanFilter[]>;
  @Select(MembersState.cardFilters)
  private cardFilters$: Observable<CardFilter[]>;
  @Select(MembersState.playlistFilters)
  private playlistFilters$: Observable<PlaylistFilter[]>;
  private filtersSubscription: Subscription;

  currentFilter: BooleanFilterType;
  expanded = false;
  filters: BooleanFilter[] = [];

  excludedCards: string[];
  excludedCardStatuses: string[];
  excludedFormsTitle: string[];
  excludedFormTitleStatuses: string[];
  excludedPlaylists: string[];
  excludedPlaylistStatuses: string[];
  excludedGroups: string[];
  excludedGroupStatuses: string[];
  excludedRoles: string[];
  excludedRoleStatuses: string[];
  excludedActivityStatuses: string[];
  excludedResourceStatuses: string[];
  excludedFormStatuses: string[];

  constructor(private store: Store) {}

  ngOnInit() {
    this.initFilters();
  }

  ngOnDestroy() {
    this.filtersSubscription?.unsubscribe();
  }

  filterSelected(filterType: BooleanFilterType) {
    this.currentFilter = filterType;
  }

  filterSaved(f: BooleanFilter) {
    this.currentFilter = undefined;
    this.expanded = false;
    this.filters = [...this.filters, f];
    this.updateFilters();
  }

  filterCancelled() {
    this.currentFilter = undefined;
  }

  onExpand() {
    this.expanded = true;
  }

  onCollapse() {
    this.expanded = false;
  }

  onRemoveFilter(i: number) {
    this.filters = this.filters.filter((_, index) => index !== i);
    this.updateFilters();
  }

  private applyFilters() {
    this.exclude();
    const request = {
      type: BooleanQueryType.AND,
      args: this.filters.flatMap(f => f.request)
    } as AndRequest;
    this.filterChanged.emit(request);
  }

  private exclude() {
    const requests = this.filters.flatMap(f => f.request);

    this.excludedCards = FiltersHelper.extractIf(this.isCardRequest, this.getUid)(requests);
    this.excludedCardStatuses = FiltersHelper.extractIf(this.isCardRequest, this.getStatus, this.getOppositeStatus)(requests)

    this.excludedFormsTitle = FiltersHelper.extractIf(this.isFormRequest, this.getUid)(requests);
    this.excludedFormTitleStatuses = FiltersHelper.extractIf(this.isFormRequest, this.getStatus, this.getOppositeStatus)(requests)

    this.excludedPlaylists = FiltersHelper.extractIf(this.isPlaylistRequest, this.getUid)(requests);
    this.excludedPlaylistStatuses = FiltersHelper.extractIf(this.isPlaylistRequest, this.getStatus, this.getOppositeStatus)(requests)

    this.excludedGroups = FiltersHelper.extractIf(this.isGroupRequest, this.getGroups)(requests);
    this.excludedGroupStatuses = FiltersHelper.extractIf(
      this.isGroupRequest,
      () => 'IN_GROUP',
      () => 'NOT_IN_GROUP')(requests);

    this.excludedRoles = FiltersHelper.extractIf(this.isRoleRequest, this.getRoles)(requests);
    this.excludedRoleStatuses = FiltersHelper.extractIf(
      this.isRoleRequest,
      () => 'HAS_ROLE',
      () => 'WITHOUT_ROLE')(requests);

    this.excludedActivityStatuses = FiltersHelper.extractIf(this.isActivityRequest, this.getActivity)(requests);
    this.excludedResourceStatuses = FiltersHelper.extractIf(this.isResourceRequest, this.getStatus)(requests);
    this.excludedFormStatuses = FiltersHelper.extractIf(this.isResourceRequest, this.getStatus)(requests);
  }

  private isFormRequest(request: BooleanQueryRequest): request is FormStatusRequest {
    return request.type === BooleanQueryType.FORM_STATUS;
  }

  private isCardRequest(request: BooleanQueryRequest): request is CardStatusRequest {
    return request.type === BooleanQueryType.CARD_STATUS;
  }

  private isPlaylistRequest(request: BooleanQueryRequest): request is PlaylistStatusRequest {
    return request.type === BooleanQueryType.PLAYLIST_STATUS;
  }

  private isGroupRequest(request: BooleanQueryRequest): request is GroupsRequest {
    return request.type === BooleanQueryType.GROUPS;
  }

  private isRoleRequest(request: BooleanQueryRequest): request is RolesRequest {
    return request.type === BooleanQueryType.ROLES;
  }

  private isActivityRequest(request: BooleanQueryRequest): request is ActivityRequest {
    return request.type === BooleanQueryType.ACTIVITY;
  }

  private isResourceRequest(request: BooleanQueryRequest): request is ResourcesStatusRequest {
    return request.type === BooleanQueryType.RESOURCES_STATUS;
  }

  private getStatus(request: CardStatusRequest | PlaylistStatusRequest | ResourcesStatusRequest | FormStatusRequest): CompletionStatus {
    return request.status;
  }

  private getOppositeStatus(request: CardStatusRequest | PlaylistStatusRequest | FormStatusRequest): string {
    switch (request.status) {
      case 'STARTED':
        return 'NOT_STARTED';
      case 'COMPLETED':
        return 'NOT_COMPLETED';
    }
  }

  private getUid(request: CardStatusRequest | PlaylistStatusRequest | FormStatusRequest): string {
    return request.uid;
  }

  private getGroups(request: GroupsRequest): string[] {
    return request.groups;
  }

  private getRoles(request: RolesRequest): string[] {
    return request.roles;
  }

  private getActivity(request: ActivityRequest): string {
    return request.operator === 'AFTER' ? 'ACTIVE' : 'INACTIVE'
  }

  private initFilters() {
    if (this.isCardPage) {
      this.filtersSubscription = this.cardFilters$.pipe(
        filter(data => !!data),
      ).subscribe( cardFilters => {
          this.filters = cardFilters.find(cardFilter => cardFilter.cardUid === this.cardUid)?.value ?? [];
          this.applyFilters();
        }
      )
    } else if (this.isPlaylistPage) {
      this.filtersSubscription = this.playlistFilters$.pipe(
        filter(data => !!data)
      ).subscribe( playlistFilters => {
          this.filters = playlistFilters.find(playlistFilter => playlistFilter.playlistUid === this.playlistUid)?.value ?? [];
          this.applyFilters();
        }
      )
    } else {
      this.filtersSubscription = this.filters$.pipe(
        filter(data => !!data)
      ).subscribe( filters => {
          this.filters = filters;
          this.applyFilters();
        }
      )
    }
  }

  private updateFilters() {
    if (this.isCardPage) {
      this.store.dispatch(new UpdateCardFilters(this.cardUid, this.filters));
    } else if (this.isPlaylistPage) {
      this.store.dispatch(new UpdatePlaylistFilters(this.playlistUid, this.filters));
    } else {
      this.store.dispatch(new UpdateMemberFilters(this.filters));
    }
  }
}
