<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div tabindex="0" aria-labelledby="dragDropQuestionAria">

  <div class="question-aria" id="dragDropQuestionAria">
    {{ 'translations.dragDropQuestionAria' | transloco }}
  </div>

  <ptl-resource-preview class="question-description"
                        [previewContent]="[{ type: 'PARAGRAPH', content: getTranslatedValue(question.instructions) }]">
  </ptl-resource-preview>
  <h3 tabindex="0">{{ 'translations.chooseAnswers' | transloco }}</h3>
  <div class="drag-drop-question-aria" cdkScrollable>
    <div
      #notSelectedItems="cdkDropList"
      class="question-card__options"
      cdkDropList
      [cdkDropListConnectedTo]="[selectedItems]"
      [cdkDropListData]="notSelectedAnswers"
      [cdkDropListOrientation]="'vertical'"
      (cdkDropListDropped)="onListDrop($event, 'not-selected')">
      <div
        cdkDrag
        *ngFor="let item of notSelectedAnswers, index as i"
        [cdkDragData]="item"
        tabindex="0"
        (keydown.enter)="onDragEnter(item, false)"
        (keydown.space)="onDragEnter(item, false)"
        [attr.aria-label]="('translations.global.ariaLabels.dragOption' | transloco: {number: (i + 1), text: ': ' + getTranslatedValue(item.values)})"
        class="question-card__option">
        <mat-icon aria-hidden="true">open_with</mat-icon>
        {{ getTranslatedValue(item.values) }}
      </div>
    </div>
    <h3>{{ 'translations.dragTheAnswersHere' | transloco }}</h3>
    <div class="question-card__options selected">
      <div
        class="question-card__options-inner"
        cdkDropList
        #selectedItems="cdkDropList"
        [cdkDropListConnectedTo]="[notSelectedItems]"
        [cdkDropListData]="selectedAnswers"
        [cdkDropListOrientation]="'vertical'"
        (cdkDropListDropped)="onListDrop($event, 'selected')">
        <div
          cdkDrag
          *ngFor="let item of selectedAnswers, index as i"
          [cdkDragData]="item"
          [attr.aria-label]="('translations.global.ariaLabels.dragSelectedOption' | transloco) + ' ' + (i + 1) + ': ' + getTranslatedValue(item.values)"
          tabindex="0"
          (keydown.enter)="onDragEnter(item, true)"
          (keydown.space)="onDragEnter(item, true)"
          class="question-card__option">
          <mat-icon aria-hidden="true">open_with</mat-icon>
          {{ getTranslatedValue(item.values) }}
        </div>
      </div>
      <div
        *ngFor="let item of options?.slice(0, question?.selectionLimit); let i = index"
        [attr.tabindex]="draggedOption ? 0 : -1"
        (keydown.enter)="onDragFinished(i)"
        [attr.aria-label]="('translations.global.ariaLabels.dropItemInPosition' | transloco: {positionNumber: (i + 1)})"
        #droppedContainer
        class="question-card__option slot">
        {{ i + 1 }}
      </div>
    </div>
  </div>
</div>

