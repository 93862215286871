import { Injectable } from '@angular/core';
import { SharedListsDataService } from './lists-data.service';
import { ObservableResult } from '../../store';
import { QuestionFormList } from '../../models/lists/list.model';

const mochQuestionFormData: QuestionFormList = {
  _id: 'm35GglHjOGooeUK3',
  organizationUid: '1l8ijFYL77QWEEL1',
  title: [
    {
      languageCode: 'en',
      value: '456'
    }
  ],
  creatorUid: '283d2e08-95c2-4f78-80f8-b93c6af1a865',
  createdAt: '2024-02-19T05:46:49.397028047',
  lastUpdatedAt: '2024-02-19T05:46:49.397030527',
  root: {
    uid: 'jLt8nMrLQX5dOZL9',
    value: [
      {
        languageCode: 'en',
        value: 'Please select'
      }
    ],
    sublist: []
  }
};

@Injectable()
export class MockSharedListsDataService implements SharedListsDataService {
  createFormQuestionTextList(title: string): ObservableResult<QuestionFormList> {
    return ObservableResult.ofSuccess(mochQuestionFormData);
  }

  getFormListByOptionUid(optionsListUid: string): ObservableResult<QuestionFormList> {
    return ObservableResult.ofSuccess(mochQuestionFormData)
  }

  searchListsByTerm(optionsListUid: string): ObservableResult<QuestionFormList> {
    return ObservableResult.ofSuccess(mochQuestionFormData)
  }

  addElementToFormQuestionList(listUid: string, data: {
    parentUid: string;
    value: string;
  }): ObservableResult<QuestionFormList> {
    return ObservableResult.ofSuccess()
  }

  updateFormQuestionListElementByElementUid(listUid: string, elementUid: string, data: {
    value: string;
  }): ObservableResult<QuestionFormList> {
    return ObservableResult.ofSuccess(mochQuestionFormData)
  }

  updateFormQuestionListTitle(listUid: string, data: { title: string }): ObservableResult<QuestionFormList> {
    return ObservableResult.ofSuccess(mochQuestionFormData)
  }

  deleteFormQuestionListElement(listUid: string, elementUid: string): ObservableResult<null> {
    return ObservableResult.ofSuccess()
  }
}
