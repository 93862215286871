/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { RxStomp, RxStompConfig, StompHeaders } from '@stomp/rx-stomp';

import { Injectable } from '@angular/core';
import { AccessTokenService } from 'src/app/user-auth/services/access-token.service';
import { environment } from 'src/environments/environment';
import { WebSocketTopic } from '../../models/websocket/websocket.model';
import { WebsocketService } from './websocket.service';
import { EnvironmentCode } from 'src/environments/environment.model';
import { VersionHelper } from '../../helpers/version.helper';

const DEFAULT_RECONNECT_DELAY = 10_000;
const MAX_RECONNECT_DELAY = 160_000;

@Injectable()
export class ApiWebsocketService implements WebsocketService {
  private stompConfig: RxStompConfig;
  private rxStomp: RxStomp;
  private reconnectDelay = DEFAULT_RECONNECT_DELAY;
  private newVersionEnabled = VersionHelper.newVersionEnabled();

  constructor(private accessTokenService: AccessTokenService) {
    this.stompConfig = {
      beforeConnect: (rxStomp: RxStomp): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
          if (this.getJwtToken()) {
            this.stompConfig.connectHeaders =
              this.getAuthorizationStompHeader();
            this.stompConfig.reconnectDelay = this.reconnectDelay;
            this.rxStomp.configure(this.stompConfig);
            resolve();
          } else {
            reject();
          }
          if (this.reconnectDelay < MAX_RECONNECT_DELAY) {
            this.reconnectDelay = this.reconnectDelay * 2;
          }
        });
      },
      brokerURL: this.getWebSocketServiceBrokerUrl(),
      connectionTimeout: 2_000,
      heartbeatIncoming: 100_000,
      heartbeatOutgoing: 100_000,
      reconnectDelay: DEFAULT_RECONNECT_DELAY,
      discardWebsocketOnCommFailure: true
    };
    this.rxStomp = new RxStomp();
    this.rxStomp.configure(this.stompConfig);
    this.rxStomp.connected$.subscribe(() => {
       this.reconnectDelay = DEFAULT_RECONNECT_DELAY;
       this.stompConfig.reconnectDelay = this.reconnectDelay;
       this.rxStomp.configure(this.stompConfig);
    });
    this.openConnection();
  }

  openConnection() {
    if (this.newVersionEnabled && this.getJwtToken()) {
      if (!this.rxStomp.active) {
        this.rxStomp.activate();
      }
    }
  }

  subscribeToTopic(topic: WebSocketTopic) {
    this.openConnection();
    return this.rxStomp.watch(topic);
  }

  closeConnection() {
    this.rxStomp.deactivate();
  }

  private getWebSocketServiceBrokerUrl(): string {
    const host = environment.apiRootUrl.substring(
      environment.apiRootUrl.indexOf(':')
    );
    if (environment.env === EnvironmentCode.prod) {
      return `wss${host}/stomp`;
    }
    return `wss${host}stomp`;
  }

  private getAuthorizationStompHeader(): StompHeaders {
    return { 'X-Authorization': `Bearer ${this.getJwtToken()}` };
  }

  private getJwtToken(): string {
    return this.accessTokenService.getAccessToken();
  }
}
