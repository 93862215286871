<!--
  ~ Copyright (C) 2019 - present by Potentially
  ~
  ~ Please see distribution for license.
  -->

<div class="create-moment__actions" *ngIf="formExpanded && !paddingReduced">
  <button
    mat-icon-button
    class="create-moment__actions-btn"
    title="{{ 'translations.global.ariaLabels.clear' | transloco: {title: ''} }}"
    type="button"
    [attr.aria-label]="('translations.global.ariaLabels.clear' | transloco: {title: ''})"
    (click)="clearMoment()">
    <i class="ptl-icon-trash" aria-hidden="true" ></i>
  </button>
</div>

<div class="create-moment__main-container table-row">
  <div
    class="create-moment__main"
    [class.create-moment__main--padding-reduced]="paddingReduced">

    <div class="create-moment__editor" [class.create-moment__editor--padding-reduced]="paddingReduced">
      <ptl-editor
        class="no-outline"
        role="group"
        [placeholder]="placeholderMessage"
        [title]="placeholderMessage"
        [content]="moment?.content"
        [streamVideoBytePlaceholder]="true"
        (contentUpdate)="onContentUpdate($event)"
        (contentRemoval)="onContentRemoval($event)"
        (contentCreated)="onContentCreation($event)"
        (inputFocus)="onFocus()">
      </ptl-editor>
    </div>

  </div>
</div>

<div class="table-row">
  <div
    class="create-moment__actions-wrapper"
    [class.create-moment__actions-wrapper--padding-reduced]="paddingReduced"
    *ngIf="formExpanded">
    <ptl-moment-actions (mediaContentAdded)="onContentCreation($event)"></ptl-moment-actions>
  </div>

  <div
    class="create-moment__footer"
    [class.create-moment__footer--padding-reduced]="paddingReduced"
    *ngIf="formExpanded && !frameworkTagsPanelOpen">

    <ptl-moment-add-tags
      [tags]="moment?.tags"
      (addTag)="onAddTag($event)"
      (openFrameworkTags)="onToggleFrameworkTagsPanel($event)"
      (removeTag)="onRemoveTag($event)">
    </ptl-moment-add-tags>

    <div class="create-moment__footer-actions">
      <div class="create-moment__footer-btn-container">
        <button
          mat-stroked-button
          color="primary"
          class="create-btn uppercase"
          type="button"
          [disabled]="loading"
          (click)="saveMoment()">
          <ng-container *ngIf="!loading">
            {{ submitLabel }}
          </ng-container>
          <ng-container *ngIf="loading">
            <mat-spinner [diameter]="20"></mat-spinner>
          </ng-container>
        </button>
      </div>
    </div>
  </div>

  <ptl-add-framework-tags
    *ngIf="formExpanded && frameworkTagsPanelOpen"
    [momentTags]="moment?.tags"
    [isDialogPage]="paddingReduced"
    (closeFrameworkTags)="onToggleFrameworkTagsPanel($event)"
    (addTagBulk)="onAddTagBulk($event)">
  </ptl-add-framework-tags>
</div>
