/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RedirectHelper } from '../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { MY_PAGES_SIDEBAR_SECTIONS, SIDEBAR_SECTIONS } from '../../shared/constants/constants';
import { ContentHelper } from '../../shared/helpers/content-helper';
import { getClientId } from '../../shared/helpers/development-domains.helper';
import { LanguageCodeHelper } from '../../shared/helpers/language-code-helper';
import { isPublicFolioUrl } from '../../shared/paths/folio/folio-paths';
import { RestClientService } from '../../shared/services/rest-client.service';
import { TranslationService } from '../../shared/services/translation/translation.service';
import { ObservableResult, SuccessResult } from '../../shared/store';
import {
  AccountInvitationJoinRequest,
  FINALIZATION_URL,
  OnBoardingDataRequest,
  UserAccountCreationRequest,
  UserDetails
} from '../models';
import { FinalizationType, LoginFinalizationResponse, TokenValidationResponse } from '../models/login-response.model';
import { LtiResponse, SamlSPResponse } from '../models/lti.model';
import { UserAuthState } from '../store/user-auth.state';
import { AccessTokenService } from './access-token.service';
import { BasicAuthService } from './basic-auth.service';
import { ProjectHelper } from '../../page-modules/project/helpers/project.helper';
import { Organization } from '../../shared/models';
import { WEBSOCKET_SERVICE, WebsocketService } from '../../shared/services/websocket/websocket.service';

export const USER_IS_LOGGED_IN = 'user_is_logged_in';

const getLogoutRedirectUrl = () => {
  const hostname = window.location.hostname;
  if ( hostname.includes('localhost') ) {
    return 'http://localhost:4200';
  }
  return `https://${hostname}`;
};

/** Basic API authentication integration service. */
@Injectable()
export class ApiBasicAuthAuthService implements BasicAuthService {


  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;
  organization: Organization;

  constructor(
    @Inject(WEBSOCKET_SERVICE) private websocketService: WebsocketService,
    private router: Router,
    private restClient: RestClientService,
    private activatedRoute: ActivatedRoute,
    private dialogRef: MatDialog,
    private ngZone: NgZone,
    private translationService: TranslationService,
    private accessTokenService: AccessTokenService
  ) {
    this.organizationData$.subscribe(data => {
      this.organization = data;
    });
  }

  createAccount(
    accountDetails: UserAccountCreationRequest,
    orgId: string,
    organizationDomain: string,
    orgName: string
  ): ObservableResult<void> {
    return this.restClient
      .post<UserDetails>(
        Location.joinWithSlash(environment.apiRootUrl || '', 'users/register'),
        { ...accountDetails, organizationUid: orgId, organizationDomain }
      )
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(( err ) => ObservableResult.ofError(err.error?.message))
      );
  }

  createAccountWithInvitationToken(
    accountDetails: UserAccountCreationRequest,
    invitationId: string,
    invitationToken: string,
    organizationDomain: string
  ): ObservableResult<void> {
    return this.restClient
      .post<UserDetails>(
        Location.joinWithSlash(environment.apiRootUrl || '',
          `users/invitations/${invitationId}/register?token=${invitationToken}`),
        { ...accountDetails, organizationDomain }
      )
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(( err ) => ObservableResult.ofError(err.error?.message))
      );
  }

  verifyAccount( verificationToken: string ): ObservableResult<void> {
    return this.restClient.post<void>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `users/verification?token=${verificationToken}`))
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(val => ObservableResult.ofError(val)));
  }

  resendVerificationEmail(
    email: string,
    organizationUid: string,
    captchaResponseV3?: string,
    captchaResponseV2?: string
  ): ObservableResult<void> {
    return this.restClient.post<void>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `users/verification/resend?email=${email}&orgId=${organizationUid}`),
      { captchaResponseV3, captchaResponseV2 })
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(( err ) => {
          if ( err.status === 404 ) {
            return ObservableResult.ofError(this.translationService.getTranslation('errors.errorEmailNotFound'));
          }
          return ObservableResult.ofError(this.translationService.getTranslation('errors.errorFailedResendVerifyLink'));
        }));
  }

  // Fetches the details of the currently logged in user, using their access token
  fetchUserDetails(): ObservableResult<UserDetails> {
    const decodedToken: { sub: string } = jwtDecode(this.getAccessToken());

    return this.restClient.get<UserDetails>(
      environment.realmUrl + 'account'
    ).pipe(
      map(( { body } ) => {
        return new SuccessResult({
          uid: decodedToken.sub,
          firstName: body.firstName,
          lastName: body.lastName,
          email: body.email,
          imageUrl: 'assets/profile_image.png',
          roles: [],
          organization: {
            _id: body.attributes.company_uid[0],
            name: body.attributes.company_name[0],
          },
        } as UserDetails);
      })
    );
  }

  authenticate( email: string, password: string, organizationDomain: string ): ObservableResult<UserDetails> {
    const body = new HttpParams()
      .set('client_id', getClientId(organizationDomain))
      .set('username', email)
      .set('password', password)
      .set('grant_type', 'password');

    return this.restClient.post(
      environment.realmUrl + 'protocol/openid-connect/token',
      body,
      undefined,
      { 'Content-Type': 'application/x-www-form-urlencoded' }
    ).pipe(
      tap(response => this.saveAccessToken(response.body.access_token)),
      map(() => new SuccessResult()),
      switchMap(() => this.fetchUserDetails()),
      catchError(( { message } ) => ObservableResult.ofError(message))
    );
  }

  getLtiToken( requestId: string, isPlaylist: boolean ): ObservableResult<LtiResponse> {
    const urlPath = isPlaylist ? '/playlists' : '';
    return this.restClient.get<LtiResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '', `lti${urlPath}/token?requestId=${requestId}`)
    ).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGettingToken')))
    );
  }

  getSamlSPToken( requestId: string ): ObservableResult<SamlSPResponse> {
    return this.restClient.get<SamlSPResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '', `saml/token?requestId=${requestId}`)
    ).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGettingToken')))
    );
  }

  unauthenticate( redirectUri?: string ): ObservableResult<void> {
    this.websocketService.closeConnection();

    const clearStorageAndRedirect = () => {
      this.removeAccessToken();
      localStorage.removeItem(USER_IS_LOGGED_IN);
      this.dialogRef.closeAll();
      window.sessionStorage.removeItem(MY_PAGES_SIDEBAR_SECTIONS);
      window.sessionStorage.removeItem(SIDEBAR_SECTIONS);
      LanguageCodeHelper.removeContentLanguageCode();
      const publicUrls = this.getPublicUrls();
      if ( !publicUrls.includes(document.location.pathname)
        && !isPublicFolioUrl(document.location.pathname)
        && !ProjectHelper.isProjectPublicView()
        && document.location.pathname.indexOf('/invitation/') === -1 ) {
        this.logoutRedirect(redirectUri ? redirectUri : 'signin');
      }
    };

    return this.restClient.get(
      Location.joinWithSlash(environment.apiRootUrl || '', 'users/auth/logout')
    ).pipe(
      tap(
        () => clearStorageAndRedirect(),
        () => clearStorageAndRedirect()
      ),
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.authorizeError')))
    );
  }

  finalize( email?: string ): ObservableResult<LoginFinalizationResponse> {
    localStorage.setItem(USER_IS_LOGGED_IN, 'true');
    return this.restClient.post<LoginFinalizationResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '', FINALIZATION_URL)
    ).pipe(
      tap(response => {
        if ( response.body.accessToken ) {
          this.saveAccessToken(response.body.accessToken);
        }
      }),
      map(( response ) => new SuccessResult(response.body)),
      catchError(( error: HttpErrorResponse ) => this.handleErrorOnFinalize(error, email))
    );
  }

  finalizeFolioLogin( destinationOrganization: string, overrideToken: boolean, email?: string ):
    ObservableResult<LoginFinalizationResponse> {
    localStorage.setItem(USER_IS_LOGGED_IN, 'true');
    return this.restClient.post<LoginFinalizationResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '', `users/auth/logins/${destinationOrganization}`)
    ).pipe(
      tap(response => {
        if ( overrideToken ) {
          this.saveAccessToken(response.body.accessToken);
        }
      }),
      switchMap(response => ObservableResult.ofSuccess(response.body)),
      catchError(( error: HttpErrorResponse ) => this.handleErrorOnFinalize(error, email))
    );
  }

  sendOnBoardData( data: OnBoardingDataRequest ): ObservableResult<UserDetails> {
    return this.restClient.patch<UserDetails>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'users/details/onboardings'),
      data
    ).pipe(
      switchMap(res => ObservableResult.ofSuccess(res.body)),
      catchError(error => ObservableResult.ofError(error)));
  }

  validateInvitationToken( invitationId: string, token: string ): ObservableResult<TokenValidationResponse> {
    return this.restClient.post<TokenValidationResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `users/invitations/${invitationId}/${token}/validate`),
      {},
      {},
    ).pipe(
      switchMap(res => ObservableResult.ofSuccess(res.body)),
      catchError(error => ObservableResult.ofError(error)));
  }

  joinOrganizationWithInvitationToken(
    request: AccountInvitationJoinRequest,
    invitationId: string,
    invitationToken: string
  ): ObservableResult<void> {
    return this.restClient
      .post<UserDetails>(
        Location.joinWithSlash(environment.apiRootUrl || '',
          `users/invitations/${invitationId}/join?token=${invitationToken}`),
        request
      )
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(( err ) => ObservableResult.ofError(err.error?.message))
      );
  }

  private logoutRedirect( uri: string ) {
    const uriAfterRedirect = ContentHelper.isFrameMode() ?
      `post_logout_redirect_uri=${getLogoutRedirectUrl()}/iframe-logout`
      : `post_logout_redirect_uri=${getLogoutRedirectUrl()}/${uri}`;
    document.location.href =
    `${environment.realmUrl}protocol/openid-connect/logout?` +
    `client_id=${getClientId(this.organization?.domain)}&${uriAfterRedirect}`;
  }

  private handleErrorOnFinalize( response: HttpErrorResponse, email?: string ) {
    const message = this.translationService.getTranslation('errors.finalizeError');
    if ( response.status === 500 ) {
      return ObservableResult.ofError(response.error?.message);
    }
    if ( response.status === 403 ) {
      if ( response.error?.message === null ) {
        return ObservableResult.ofError(message);
      }
      if ( response.error?.message.includes('There are no workspaces found for given user') ||
        response.error?.message.includes('There\'s no connection in ') ) {
        return ObservableResult.ofError(this.translationService.getTranslation('errors.finalizeWorkspaceError'));
      }
      if ( email ) {
        RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, `/verify-email?email=${email}`);
        return ObservableResult.ofError('');
      }
      return ObservableResult.ofError(this.translationService.getTranslation('errors.finalizeVerifyEmail'));
    }

    const error: LoginFinalizationResponse = response.error;

    if ( response.status === 400 && error?.redirect && error.type === FinalizationType.ORG_SAML_LOGIN_REQUIRED ) {
      location.href = error.redirect;
      return ObservableResult.ofError('');
    } else {
      return ObservableResult.ofError(message);
    }
  }

  private saveAccessToken( accessToken: string ): void {
    this.accessTokenService.saveAccessToken(accessToken);
  }

  private getAccessToken(): string {
    return this.accessTokenService.getAccessToken();
  }

  private removeAccessToken(): void {
    return this.accessTokenService.removeAccessToken();
  }

  private getPublicUrls(): string[] {
    return ['/signin', '/support/signin', '/iframe-logout', '/verify-folio', '/verify-personal-email', '/signup',
      '/verify-alumni', '/verify-alumni-email', '/verify-pre-arrival', '/verify-pre-arrival-with-folio',
      '/verify-organisation-email', 'verify-organisation-email-with-folio',
      '/reset-password', '/unverified-email', '/confirm-email', '/reset-email', '/forgot-password',
      '/signin/classof2020', '/prearrivals/signin', '/prearrivals/signup', '/prearrivals/forgot-password',
      '/account-verification', '/lti/registration/error', '/lti/playlists/edit', '/lti/launch', '/lti/deeplink', '/lti/course',
      'saml/signin', 'saml/signin-error', '/graduate/signin', '/signup/classof2020', '/verify-email'];
  }
}
