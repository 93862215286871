<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div class="create-moment__header" [class.create-moment__header--padding-reduced]="paddingReduced">
  <ptl-profile-image [userSummary]="userDetailsData$ | async"></ptl-profile-image>

  <h1 class="create-moment__profile-name"
      [attr.aria-label]="('translations.global.ariaLabels.profileName' | transloco: {firstName: (userDetailsData$ | async)?.firstName, lastName: (userDetailsData$ | async)?.lastName})">
    {{ (userDetailsData$ | async)?.firstName }} {{ (userDetailsData$ | async)?.lastName }}
  </h1>

  <ptl-new-moment-expand-btn (changeDialogSizeMode)="changeDialogSizeMode($event)"></ptl-new-moment-expand-btn>

</div>

<ptl-moment-details-form
  [moment]="moment"
  [userFirstName]="(userDetailsData$ | async)?.firstName"
  [loading]="loading"
  [submitLabel]="submitLabel"
  [isExpanded]="isExpanded"
  [paddingReduced]="paddingReduced"
  (addTag)="onAddTag($event)"
  (addTagBulk)="onAddTagBulk($event)"
  (removeTag)="onRemoveTag($event)"
  (momentSave)="saveMoment()"
  (momentClear)="clearMoment()"
  (contentUpdate)="onContentUpdate($event)"
  (contentRemoval)="onContentRemoval($event)"
  (contentCreated)="onContentCreation($event)"
></ptl-moment-details-form>
