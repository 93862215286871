<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="jungian-chart__content">

  <ng-container *ngIf="!showStaticData && piReport">
    <div class="jungian-chart__content-info" *ngIf="!isPIReport">
      <ptl-resource-preview [previewContent]="chart?.description"></ptl-resource-preview>
    </div>

    <div
      class="jungian-chart__icons-list"
      *ngIf="this.piReport && !isPIReport"
      tabindex="0"
      [attr.aria-label]="('translations.global.ariaLabels.openJungianCard' | transloco)"
      (click)="openJungianCard()"
      (keydown.enter)="openJungianCard()">
      <div class="jungian-chart__icon" *ngFor="let type of this.piReport[0]?.tmiType">
        <img src="assets/jungian/jungian-image-{{type | lowercase}}.png" alt="{{ type }}"/>
      </div>
    </div>


    <div class="jungian-chart__content-inner">
      <div class="chart-item">
        <div class="chart-left-info">
        </div>
        <div class="chart-progress header">
          <div class="chart-title">{{ 'translations.pi.chart.jungian.label.ambidextrous' | transloco }}</div>
        </div>
        <div class="chart-right-info">
        </div>
      </div>

      <div class="chart-item chart-item-1">
        <div class="chart-left-info">
          <div class="chart-title" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.extraversion' | transloco)">{{ 'translations.pi.chart.jungian.label.extraversion' | transloco }}</div>
        </div>
        <div class="chart-progress">
          <div class="progress-line">
            <div
              class="progress"
              [class.only-one-item]="chart?.IE.width === 0"
              [class.progress-current-side-left]="chart?.IE.currentSideLeft"
              [style.left]="chart?.IE.left + '%'"
              [style.width]="chart?.IE.width + '%'">
            </div>
          </div>
        </div>
        <div class="chart-right-info">
          <div class="chart-title" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.introversion' | transloco)">{{ 'translations.pi.chart.jungian.label.introversion' | transloco }}</div>
        </div>
      </div>

      <div class="chart-item chart-item-2">
        <div class="chart-left-info">
          <div class="chart-title" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.intuition' | transloco)">{{ 'translations.pi.chart.jungian.label.intuition' | transloco }}</div>
        </div>
        <div class="chart-progress">
          <div class="progress-line">
            <div
              class="progress"
              [class.only-one-item]="chart?.SN.width === 0"
              [class.progress-current-side-left]="chart?.SN.currentSideLeft"
              [style.left]="chart?.SN.left + '%'"
              [style.width]="chart?.SN.width + '%'">
            </div>
          </div>
        </div>
        <div class="chart-right-info">
          <div class="chart-title" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.sensing' | transloco)">{{ 'translations.pi.chart.jungian.label.sensing' | transloco }}</div>
        </div>
      </div>

      <div class="chart-item chart-item-3">
        <div class="chart-left-info">
          <div class="chart-title" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.feeling' | transloco)">{{ 'translations.pi.chart.jungian.label.feeling' | transloco }}</div>
        </div>
        <div class="chart-progress">
          <div class="progress-line">
            <div
              class="progress"
              [class.only-one-item]="chart?.TF.width === 0"
              [class.progress-current-side-left]="chart?.TF.currentSideLeft"
              [style.left]="chart?.TF.left + '%'"
              [style.width]="chart?.TF.width + '%'">
            </div>
          </div>
        </div>
        <div class="chart-right-info">
          <div class="chart-title" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.thinking' | transloco)">{{ 'translations.pi.chart.jungian.label.thinking' | transloco }}</div>
        </div>
      </div>

      <div class="chart-item chart-item-4">
        <div class="chart-left-info">
          <div class="chart-title" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.judging' | transloco)">{{ 'translations.pi.chart.jungian.label.judging' | transloco }}</div>
        </div>
        <div class="chart-progress">
          <div class="progress-line">
            <div
              class="progress"
              [class.only-one-item]="chart?.JP.width === 0"
              [class.progress-current-side-left]="chart?.JP.currentSideLeft"
              [style.left]="chart?.JP.left + '%'"
              [style.width]="chart?.JP.width + '%'">
            </div>
          </div>
        </div>
        <div class="chart-right-info">
          <div class="chart-title" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.perceiving' | transloco)">{{ 'translations.pi.chart.jungian.label.perceiving' | transloco }}</div>
        </div>
      </div>

    </div>
    <div class="jungian-chart__dates" *ngIf="completionDate2">
      <span class="jungian-chart__date"> {{ completionDate1 | dateFormat: 'DD/MM/yyyy, HH:mm' }}</span>
      <span class="jungian-chart__date">{{ completionDate2 | dateFormat: 'DD/MM/yyyy, HH:mm' }}</span>
    </div>
  </ng-container>

  <div class="jungian-chart__static-content" *ngIf="showStaticData">
    <img src="../assets/charts/jungian.png" alt="jungian chart"/>
  </div>
</div>
