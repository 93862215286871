/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ObservableResult } from '../../../shared/store';
import { RestClientService } from '../../../shared/services/rest-client.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '@app/environments/environment';
import { LinkablePlaylistSummary, SearchPlaylist } from '../../../shared/models';
import { Page } from '../../../shared/models/page';
import { GlobalSearchDataService } from './global-search-data.service';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { GlobalSearch, GlobalSearchPlaylist } from '../../../shared/models/global-search/global-search.model';
import { SearchExploreRequest } from '@app/app/shared/models/page/page.model';

/* eslint-disable max-len */
@Injectable()
export class ApiGlobalSearchDataService implements GlobalSearchDataService {

  constructor(private restClient: RestClientService, private translationService: TranslationService) {
  }

  searchContent(page: number, size: number, term: string): ObservableResult<Page<GlobalSearch>> {
    return this.restClient.post<Page<GlobalSearch>>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'search'),
      {
        term: term
      },
      {
        page: page.toString(),
        size: size.toString(),
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(err?.error?.message)));
  }

  searchExplore(page: number, size: number, request: SearchExploreRequest): ObservableResult<Page<GlobalSearchPlaylist>> {
    return this.restClient.post<Page<GlobalSearchPlaylist>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `search/explore?page=${page}&size=${size}`),
      request,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(err?.error?.message)));
  }

  searchExploreDynamicSection(sectionName: string, page: number, size: number, request: SearchExploreRequest): ObservableResult<Page<GlobalSearchPlaylist>> {
    return this.restClient.post<Page<GlobalSearchPlaylist>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `search/explore/${sectionName}?page=${page}&size=${size}`),
      request,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofError(err?.error?.message)));
  }

  searchPlaylists(page: number, term: string): ObservableResult<Page<SearchPlaylist>> {
    return this.restClient.get<Page<SearchPlaylist>>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'playlists/search'),
      {
        page: page.toString(),
        size: '10',
        term: term,
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetPlaylists'))));
  }

  searchLinkablePlaylists(page: number, term: string, excludedId?: string, pageSize: number = 10):
    ObservableResult<Page<LinkablePlaylistSummary>> {
      const params = {
          page: page.toString(),
          size: pageSize.toString(),
          term: term,
      };

      if (excludedId) {
          // @ts-ignore
          params.exclusions = excludedId;
      }

      return this.restClient.get<Page<LinkablePlaylistSummary>>(
          Location.joinWithSlash(environment.apiRootUrl || '', '/playlists/linkable'), params).pipe(
          switchMap(({body}) => ObservableResult.ofSuccess(body)),
          catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetLinkablePlaylists'))));
  }
}
