import { ChartDescription, ChartEditorContent } from '../models/pi/pi.model';
import { LanguageCodeHelper } from './language-code-helper';

export class ChartsHelper {

  static getPIChartsDescriptionsByLanguageCode( key: string, content: ChartEditorContent ): ChartDescription {
    const traitDescription = content.descriptions[key];
    if(!traitDescription) {
      return { full: [], short: [] };
    }
    const full = traitDescription.full[0];
    const short = (typeof traitDescription.short === 'string') ? traitDescription.short
      : LanguageCodeHelper.getDataByLanguageCode(traitDescription.short);
    return { full: full ? [full] : [], short: short ? [short] : [] };
  }

  static calculateProgress( completed: number, total: number ): number {
    let progress = Math.floor(completed * 100 / total);
    if(progress > 100) {
      progress = 100;
    }
    return progress;
  }
}
