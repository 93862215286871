/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, Inject, NgZone, OnInit } from '@angular/core';
import {
  CORE_MOMENT_DATA_SERVICE,
  MomentDataService
} from '../../../../../../page-modules/folio/shared-moment-module/services/data.service';
import { Moment, MomentCard, MomentCardViewType } from '../../../../../../page-modules/folio/models';
import { MomentCommonHelpers } from '../../../../../../page-modules/folio/shared-moment-module/services/common.helpers';
import { Page } from '../../../../../models/page';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CollectorConfig,
  CollectorEvidencesType,
  CollectorLogFrameworksResponse,
  LearnerCollectorSummary,
  LearnerLoggedItem,
  LearnerLoggedItemRequest,
  SubmittedMoment
} from '../../../../../models/editor/collector-content.model';
import {
  ADD_FROM_MOMENTS_DIALOG_DATE_FORMATS,
  AddFromMomentsDialogDateAdapter
} from './add-from-moments-dialog-date-adapter';
import { EditorContent, ResourceTag } from '../../../../../models';
import { MatSelectChange } from '@angular/material/select';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarHelper } from '../../../../../helpers/snackbar-helper';
import { TranslationService } from '../../../../../services/translation/translation.service';
import * as momentJs from 'moment';

@Component({
  selector: 'ptl-add-from-moments-dialog',
  templateUrl: './add-from-moments-dialog.component.html',
  styleUrls: ['./add-from-moments-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: AddFromMomentsDialogDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS, useValue: ADD_FROM_MOMENTS_DIALOG_DATE_FORMATS,
    },
  ],
})
export class AddFromMomentsDialogComponent implements OnInit {

  selectedTags: ResourceTag[] = [];
  selectedFramework: CollectorLogFrameworksResponse;
  selectedMoments: SubmittedMoment[] = [];
  availableMomentCards: MomentCard[] = [];

  evidenceTypeIsMoments: boolean;
  timestampActive = false;
  maxDate = new Date();

  dateError = false;
  dateErrorMessage = 'Dates shouldn\'t be later than present and starting date shouldn\'t be later than ending date';
  startedDate: Date;
  endedDate: Date;

  tagsError = false;
  frameworkError = false;
  minimumTagsCount: number;

  timeError = false;
  time: number;
  minTime: number;
  maxTime: number;

  collectorTags: ResourceTag[] = [];
  collectorFrameworks: CollectorLogFrameworksResponse[] = [];
  loggedTemplate: EditorContent[] = [];

  momentCards: MomentCard[] = [];
  momentType = 'ALL';
  orderBy = 'RECENT';
  tagSelectForm: FormGroup;

  collectorConfig: CollectorConfig;
  logTitle: string;

  private learnerCollectorSummary: LearnerCollectorSummary;
  private loggedItem: LearnerLoggedItem;
  private size = 10;
  private filtersActive: boolean;
  private momentCardsMap: { [uid: string]: MomentCard } = {};
  private preventDateCorrection = false;


  constructor(
    @Inject(CORE_MOMENT_DATA_SERVICE) private dataService: MomentDataService,
    private dialogRef: MatDialogRef<AddFromMomentsDialogComponent>,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: { config: CollectorConfig; summary: LearnerCollectorSummary; loggedItem: LearnerLoggedItem },
    private translationService: TranslationService,
    private fb: FormBuilder,
    private elementRef: ElementRef
  ) {

    // using for reset select after choose
    this.tagSelectForm = fb.group({
      selectField: [],
    });

    this.predefineData(this.data.config, this.data.summary, this.data.loggedItem);

  }

  ngOnInit() {
    this.dataService.getMoments(0, this.size).subscribe(({ isSuccess, value }) => {
      this.momentCards = [];
      if (isSuccess) {
        this.momentCards = this.getMomentCards(value);
        this.availableMomentCards = this.updatedAvailableMomentCards(this.selectedMoments);
      }
    });

    setTimeout(() => {
      document.getElementById('addFromMomentsContainer')?.focus();
    }, 1000)
  }


  onTagSelectionChange(event: MatSelectChange) {
    const tagExist = this.selectedTags.find(tag => tag._id === event.value._id);
    this.tagSelectForm.reset();
    if (tagExist) {
      return;
    }
    this.tagsError = false;
    this.selectedTags.push(event.value);
  }

  onFrameworkSelectionChange(event: MatSelectChange) {
    this.frameworkError = false;
    this.selectedFramework = event.value;
    this.minimumTagsCount = this.selectedFramework.minRequiredTags;
    this.collectorTags = this.selectedFramework.framework.tags;
    this.selectedTags = [];
  }

  deleteTag(tag: ResourceTag) {
    this.selectedTags = this.selectedTags.filter(item => item._id !== tag._id);
  }

  filterMoments() {
    this.filtersActive = true;

    this.dataService.searchMoments(this.momentType, this.orderBy, null, 0, this.size)
      .subscribe(({ isSuccess, value }) => {
        this.momentCards = [];
        if (isSuccess) {
          this.momentCards = this.getMomentCards(value);

          for (const momentCard of this.momentCards) {
            this.momentCardsMap[momentCard._id] = momentCard;
          }

          this.availableMomentCards = this.updatedAvailableMomentCards(this.selectedMoments);
        }
      });
  }

  onCardClick(momentCard: MomentCard) {
    const length = this.selectedMoments.length;
    this.selectedMoments = this.selectedMoments.filter(moment => momentCard._id !== moment.uid);

    if (length === this.selectedMoments.length) {
      this.selectedMoments.push(
        {
          title: momentCard.title,
          uid: momentCard._id,
          imageUrl: momentCard.image,
        } as SubmittedMoment);
    }

    this.availableMomentCards = this.updatedAvailableMomentCards(this.selectedMoments);
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onSave() {
    const isValid = this.validateData();
    if (!isValid) {
      this.elementRef.nativeElement.parentElement.scrollTo(0, 0);
      return;
    }
    this.dialogRef.close(this.getRequestData());
  }

  updatedAvailableMomentCards(newSelectedMoments: SubmittedMoment[]) {
    return this.availableMomentCards = this.momentCards.filter(moment => {
      for (const selectedMomentCard of newSelectedMoments) {
        if (selectedMomentCard.uid === moment._id) {
          return false;
        }
      }

      return true;
    });
  }

  getMomentCard(moment: SubmittedMoment) {
    const result = this.momentCardsMap[moment.uid];

    if (!result) {
      this.dataService.getMomentDetails(moment.uid).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          const { url, external, textOnly, text } = MomentCommonHelpers.extractMomentImageAndText(value);
          this.momentCardsMap[value._id] = {
            _id: value._id,
            title: moment.title,
            image: url,
            content: value.content,
            momentAddedToFolio: value.addedToFolio,
            tags: value.tags,
            imageExternal: external,
            textOnly: textOnly,
            text: text,
            viewType: 'COLLECTOR' as MomentCardViewType,
            isDisabled: false,
          };
        }
      });
    }

    return result;
  }

  onTimeChanged(event: Event) {
    this.timeError = false;
    const input = event.target as HTMLInputElement;
    this.time = input.valueAsNumber;

    if (this.time) {
      this.time = Math.floor(this.time);

      if (this.time < this.minTime) {
        this.time = this.minTime;
      } else if (this.time > this.maxTime) {
        this.time = this.maxTime;
      }

      input.valueAsNumber = this.time;
    }
  }

  onDateInputFocus() {
    this.preventDateCorrection = true;
  }

  onDateInputBlur() {
    this.preventDateCorrection = false;
    this.onDateChanged();
  }

  onDateChanged() {
    this.dateError = false;
    if (this.preventDateCorrection) {
      return;
    }

    this.maxDate = new Date();

    if (!this.startedDate && this.endedDate) {
      this.startedDate = new Date(this.endedDate);
    } else if (this.startedDate && !this.endedDate) {
      this.endedDate = new Date(this.startedDate);
    }
    this.validateDateInputs();
  }

  private parseDate(date: Date): string {
    return momentJs(date).format('YYYY-MM-DD');
  }

  private predefineData(config: CollectorConfig, summary: LearnerCollectorSummary, loggedItem: LearnerLoggedItem) {
    this.loggedItem = loggedItem;
    this.learnerCollectorSummary = summary;
    this.collectorConfig = config;
    this.predefineConfigVariablesData(config);
    this.predefineLoggedItem(loggedItem);
  }

  private getRequestData(): LearnerLoggedItemRequest {
    return {
      timeLogPeriod: {
        from: this.startedDate ? this.parseDate(this.startedDate) : undefined,
        to: this.endedDate ? this.parseDate(this.endedDate) : undefined,
        time: this.time ? this.time : undefined,
        timeUnit: this.collectorConfig.timeLogConfig.requiredTimeUnit,
      },
      moments: this.selectedMoments.length ? this.getSelectedMomentsIds() : [],
      tags: this.selectedTags.length ? this.getSelectedTagIds() : undefined,
      frameworkUid: this.selectedFramework ? this.selectedFramework.framework._id : undefined,
      formAnswers: !this.selectedMoments.length ? this.getFormAnswers() : undefined,
      title: this.logTitle?.trim(),
    };
  }

  private getFormAnswers() {
    const answers = [];
    for (const template of this.loggedTemplate) {
      if (template.type === 'FORM') {
        answers.push({
          formId: template.uid,
          answer: template.content.userAnswer ? template.content.userAnswer : null,
        });
      }
    }
    return answers;
  }

  private getSelectedMomentsIds() {
    const ids = [];
    for (const moment of this.selectedMoments) {
      ids.push(moment.uid);
    }
    return ids;
  }

  private getSelectedTagIds() {
    const ids = [];
    for (const tag of this.selectedTags) {
      ids.push(tag._id);
    }
    return ids;
  }

  private validateData(): boolean {
    this.timeError = false;
    if (this.minTime !== undefined && !this.time) {
      this.timeError = true;
      const timeUnits = this.collectorConfig.timeLogConfig.requiredTimeUnit.toLowerCase();
      const message = this.translationService.getTranslation('addLoggedItemsError').replace('{timeUnits}', timeUnits);
      SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, message);
      return false;
    }

    this.frameworkError = false;
    if (this.collectorFrameworks.length && !this.selectedFramework) {
      this.frameworkError = true;
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'addLoggedItemsSelectFramework');
      return false;
    }

    this.tagsError = false;
    if (this.collectorTags.length && this.minimumTagsCount !== 0 && this.selectedTags.length < this.minimumTagsCount) {
      this.tagsError = true;
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'addLoggedItemsSelectTags');
      return false;
    }

    this.dateError = false;
    if (this.timestampActive) {
      if (!this.startedDate || !this.endedDate) {
        this.dateError = true;
        SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'addLoggedItemsSelectDates');
        return false;
      }
      this.validateDateInputs();
      if (this.dateError) {
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, this.dateErrorMessage);
        return false;
      }
    }

    if (this.evidenceTypeIsMoments) {
      if (!this.selectedMoments.length) {
        SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'addLoggedItemsSelectMoments');
        return false;
      }
    }

    return true;
  }

  private getUserAnswer(formUid: string): string | undefined {
    if (this.loggedItem.answers && this.loggedItem.answers.length) {
      const answer = this.loggedItem.answers.find(item => item.formId === formUid);
      return answer ? answer.answer : '';
    } else {
      return undefined;
    }
  }

  private predefineLoggedItem(loggedItem: LearnerLoggedItem) {
    if (loggedItem) {
      const submittedPeriod = loggedItem.submittedTimeLogPeriod;
      if (submittedPeriod) {
        this.time = submittedPeriod.time ? submittedPeriod.time : undefined;
        this.startedDate = submittedPeriod.from ? new Date(submittedPeriod.from) : undefined;
        this.endedDate = submittedPeriod.to ? new Date(submittedPeriod.to) : undefined;
      }
      this.selectedMoments = (loggedItem.moments && loggedItem.moments.length) ? loggedItem.moments : [];
      this.selectedTags = (loggedItem.tags && loggedItem.tags.length) ? loggedItem.tags : [];
      this.selectedFramework = this.getFrameworkBySelectedTags(this.selectedTags);
      this.logTitle = loggedItem.title;
      if (this.selectedFramework) {
        this.minimumTagsCount = this.selectedFramework.minRequiredTags;
        this.collectorTags = this.selectedFramework.framework.tags;
      }
    }
  }

  private getFrameworkBySelectedTags(selectedTags: ResourceTag[]): CollectorLogFrameworksResponse | undefined {
    if (selectedTags.length) {
      const firstTag = selectedTags[0];
      for (const framework of this.collectorFrameworks) {
        const tags = framework.framework.tags;
        const tagExist = tags.find(tag => tag._id === firstTag._id);
        if (tagExist) {
          return framework;
        }
      }
      return undefined;
    } else {
      return undefined;
    }
  }

  private predefineConfigVariablesData(config: CollectorConfig) {
    if (config) {
      this.predefineLogTemplate(config.logTemplate);
      this.evidenceTypeIsMoments = this.getEvidenceType(config.evidencesType);
      this.collectorTags = config.logTags ? config.logTags.tags : [];
      this.collectorFrameworks = (config.logFrameworks && config.logFrameworks.length) ? config.logFrameworks : [];
      this.minimumTagsCount = config.logTags ? config.logTags.minRequiredTags : 0;
      this.timestampActive = config.timeLogConfig.requireTimestamp;
      if (config.type === 'TIME_LOG') {
        this.minTime = config.timeLogConfig.minTime ? config.timeLogConfig.minTime : 1;
        this.maxTime = config.timeLogConfig.maxTime ? Math.max(config.timeLogConfig.maxTime, this.minTime) : undefined;
      }
    }
  }

  private predefineLogTemplate(templates: EditorContent[]) {
    this.loggedTemplate = templates ? templates : [];
    if (this.loggedItem) {
      for (const template of this.loggedTemplate) {
        if (template.type === 'FORM') {
          template.content.userAnswer = this.getUserAnswer(template.uid);
        }
      }
    }

  }

  private getEvidenceType(type: CollectorEvidencesType) {
    return this.loggedItem ? (!this.loggedItem.answers) : (type === 'MOMENTS');
  }

  private getMomentCards(data: Page<Moment>): MomentCard[] {
    return data.content.map(moment => {
      const { url, external, textOnly, text } = MomentCommonHelpers.extractMomentImageAndText(moment);

      return {
        _id: moment._id,
        title: moment.title,
        image: url,
        content: moment.content,
        momentAddedToFolio: moment.addedToFolio,
        tags: moment.tags,
        imageExternal: external,
        textOnly: textOnly,
        text: text,
        viewType: 'COLLECTOR' as MomentCardViewType,
        isDisabled: false,
      };
    });
  }

  private validateDateInputs(): boolean {
    const startedDate = new Date(new Date(this.startedDate).toDateString());
    const endedDate = new Date(new Date(this.endedDate).toDateString());

    this.dateError = false;
    this.dateError = this.dateError || startedDate > this.maxDate;
    this.dateError = this.dateError || endedDate > this.maxDate;
    this.dateError = this.dateError || startedDate > endedDate;

    return !this.dateError;
  }
}
