<div @triggerChildAnimation>
  <div *ngIf="type === allToastTypes.PUSH_NOTIFICATION"
    class="push-notification"
    @slideIn
    @slideOut>
    <div *ngIf="isNotificationReferenceAvailable() else pushNotificationSimpleHeader"
      class="push-notification__header clickable"
      (click)="redirectToNotificationDetails()"
      (keydown.enter)="redirectToNotificationDetails()">
      <span class="header-content medium bold">
        {{ header }}
      </span>
    </div>

    <div class="push-notification__content-box">
      <div>
        <span class="push-notification__message small">
          {{ message }}
        </span>
      </div>
      <div class="push-notification__dismiss-container">
        <a class="push-notification__dismiss-btn small"
         [attr.aria-label]="('translations.global.button.dismiss' | transloco)"
         (click)="dissmiss(true)"
         (keydown.enter)="dissmiss(true)">
         {{ 'translations.global.button.dismiss' | transloco }}
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="type !== allToastTypes.PUSH_NOTIFICATION"
    class="toast-information"
    [class.success]="type === allToastTypes.SUCCESS"
    [class.fail]="type === allToastTypes.FAIL"
    @slideIn
    @slideOut>
    <div class="toast-information__icon-box">
      <svg class="toast-information__icon" width="20" height="20" x="0px" y="0px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <use *ngIf="type === allToastTypes.SUCCESS" ogSvgUnify="assets/sprite/sprite.svg#check"></use>
        <use *ngIf="type === allToastTypes.FAIL" ogSvgUnify="assets/sprite/sprite.svg#warning"></use>
      </svg>
    </div>
    <div class="toast-information__content small">
      {{ message }}
    </div>
    <button
      class="toast-information__close-btn"
      mat-icon-button
      type="button"
      [attr.aria-label]="('translations.global.button.dismiss' | transloco)"
      (click)="dissmiss(true)">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>
  </div>
</div>

<ng-template #pushNotificationSimpleHeader>
  <div
    class="push-notification__header">
    <span class="header-content medium bold">
      {{ header }}
    </span>
  </div>
</ng-template>
