/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UpcomingEventCardV1 } from '../../../../shared/models/event-card/event-card.model';
import { Organization, ResourceAuthorDetails, ResourcePublishedStatus } from '../../../../shared/models';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { TranslationService } from '../../../../shared/services/translation/translation.service';
import { RedirectHelper } from '../../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import {
  EventCardTime
} from '../../../../page-modules/resource/store/admin/resource-event-admin.state.model';
import { EventCardsHelper } from '../../../../shared/helpers/event-cards-helper';
import { PlaylistPublishedStatus } from '../../../../shared/models/playlist/learner-playlist-summary.model';

@Component({
  selector: 'ptl-playlist-upcoming-card-new',
  templateUrl: './playlist-upcoming-card-new.component.html',
  styleUrls: ['./playlist-upcoming-card-new.component.scss'],
})
export class PlaylistUpcomingCardNewComponent implements OnInit {

  @Input() type: 'VIEW' | 'EDIT';
  /** Card data */
  @Input() card: UpcomingEventCardV1;
  /** Card index in cards data which was got from API. */
  @Input() index: number;
  /** Cards layout type. */
  @Input() inHomeLayoutEditor: boolean;

  @Input() isAdmin: boolean;

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  contextMenuPosition = { x: '0px', y: '0px' };

  eventText: string;
  cardStatus: ResourcePublishedStatus;
  authorDetails: ResourceAuthorDetails;
  imageUrl: string;
  orgName: string;
  authorNameFirstChar: string;
  hasAuthorImage: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private translationService: TranslationService,
  ) {
  }

  ngOnInit() {
    if (this.card) {
      this.eventText = this.getEventText(this.card.time);
      if (this.card.authorDetails) {
        this.authorDetails = this.card.authorDetails;
        this.cardStatus = this.getEventCardStatus(this.card.status);
        this.imageUrl = this.getImageUrl();
        this.authorNameFirstChar = this.getAuthorNameFirstChar();
        this.hasAuthorImage = this.checkIfImageExists();
        this.orgName = this.getOrgName();
      }
    }
  }


  onCardClick() {
    if (this.inHomeLayoutEditor) {
      return;
    }
    RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute,{
      playlistUri: this.card.playlistUri,
      resourceUri: this.card.cardUri,
      groupUri: this.card.groupUri,
      formattedUri: this.card.formattedUri,
      pageNumberUri: 'page/1',
    }, 'RESOURCE');
  }

  onMembersPage() {
    if (this.inHomeLayoutEditor) {
      return;
    }
    RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute,{
      playlistUri: this.card.playlistUri,
      resourceUri: this.card.cardUri,
      groupUri: this.card.groupUri,
      formattedUri: this.card.formattedUri,
      pageNumberUri: 'page/1',
      extraUriParam: 'members'
    }, 'RESOURCE');
  }


  onCardEdit() {
    if (this.inHomeLayoutEditor) {
      return;
    }
    RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute,{
      playlistUri: this.card.playlistUri,
      resourceUri: this.card.cardUri,
      groupUri: this.card.groupUri,
      formattedUri: this.card.formattedUri,
      pageNumberUri: 'page/1',
      extraUriParam: 'edit'
    }, 'RESOURCE');
  }

  onContextMenu(event: MouseEvent) {
    if (this.card?.playlistUri) {
      event.preventDefault();
      const target = event.currentTarget as HTMLElement;
      const rect = target.getBoundingClientRect();
      this.contextMenuPosition.x = (event.clientX - rect.x) + 'px';
      this.contextMenuPosition.y = (event.clientY - rect.y) + 'px';
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  onOpenInNewTab() {
    if (this.card) {
      RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute,{
        playlistUri: this.card.playlistUri,
        resourceUri: this.card.cardUri,
        groupUri: this.card.groupUri,
        formattedUri: this.card.formattedUri,
        pageNumberUri: 'page/1',
        extraUriParam: 'edit'
      }, 'RESOURCE',
        '_blank');
    }
  }

  private getOrgName(): string {
    if (this.authorDetails.displayConfig.organizationName) {
      const orgName = this.authorDetails.organization;
      const changedOrgName = this.authorDetails.displayConfig.externalAuthor?.name;
      return changedOrgName ? changedOrgName : orgName;
    } else {
      return this.authorDetails.name;
    }
  }

  private checkIfImageExists() {
    if (this.authorDetails.userImageUrl && this.authorDetails.displayConfig.authorName) {
      return true;
    } else if (this.authorDetails.displayConfig.organizationName) {
      return true;
    }
    return false;
  }

  private getOrgImageUrl(): string {
    const orgImageUrl = this.authorDetails.orgLogoUrl;
    const uploadedOrgImageUrl = this.authorDetails.displayConfig.externalAuthor?.image;

    return uploadedOrgImageUrl ? uploadedOrgImageUrl : orgImageUrl;
  }

  private getImageUrl(): string {
    return this.authorDetails.displayConfig.organizationName ? this.getOrgImageUrl() : this.authorDetails.userImageUrl;
  }

  private getAuthorNameFirstChar(): string {
    return this.authorDetails.name.charAt(0);
  }

  private getEventText(eventTime: EventCardTime): string {
    return EventCardsHelper.getEventStartingText(eventTime, this.translationService);
  }

  private getEventCardStatus(status: { type: PlaylistPublishedStatus }) {
    if (!status) {
      return undefined;
    }
    return status.type;
  }

}
