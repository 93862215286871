/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */


import {
  AfterViewInit,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import { TINYMCE_OPTIONS } from '../tinymce-editor.options';
import { EditorComponent } from '@tinymce/tinymce-angular';

@Component({
  selector: 'ptl-tinymce-editor-dir',
  template: '<editor #editorBox [initialValue]="editorModel"  [init]="editorOptions"></editor>'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TinyMceEditorDirective implements OnChanges, OnInit, OnDestroy {

  private lastViewModel: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private element: HTMLElement;

  editorOptions: any;

  @Input() editorModel: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */

  @Input() options;
  @Input() editorPlaceholder: string;
  @Input() lastEditor: boolean;

  @Output() editorModelChange = new EventEmitter();

  @ViewChild('editorBox', { static: true }) editorBox: EditorComponent;


  constructor( private el: ElementRef ) {
    this.element = this.el.nativeElement
  }

  ngOnInit() {
    const options = this.options ?? TINYMCE_OPTIONS;
    this.editorOptions = {
      ...options,
      placeholder: this.editorPlaceholder ?? ''
    };
  }

  editableInput(): void {
    this.updateModel();
  }

  editableTableInput(): void {
    this.updateTableModel();
  }

  getElement(): HTMLElement {
    return this.element;
  }

  refreshView() {
    if ( this.editorBox && this.editorBox.editor ) {
      this.editorBox.editor.setContent(this.editorModel);
    }
  }

  ngOnChanges( changes ) {
    if ( this.isPropertyUpdated(changes, this.lastViewModel) ) {
      this.lastViewModel = this.editorModel;
      this.refreshView();
    }

    if ( this.isPropertyUpdated(changes, this.lastEditor) && this.element ) {
      const editor = this.element.firstElementChild;

      if ( this.lastEditor ) {
        editor.classList.add('last-editor');
      } else {
        editor.classList.remove('last-editor');
      }
    }
    if (
      changes.editorModel?.currentValue?.indexOf('<blockquote></blockquote>') !== -1 ||
      changes.editorModel?.currentValue?.indexOf('<blockquote class=""></blockquote>') !== -1 ) {
      this.removeBrokenBlockquote(changes.editorModel?.currentValue);
    }
  }

  /**
   * Emit updated model
   */
  updateModel() {
    const value = this.editorBox.editor.targetElm?.innerHTML;
    this.lastViewModel = value ? value : '';
    this.editorModelChange.emit(value);
  }

  updateTableModel() {
    const iframeWindow = this.editorBox.editor.iframeElement?.contentWindow;

    if ( iframeWindow ) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const tinymceInstance: any = iframeWindow['tinymce'];
      this.lastViewModel = tinymceInstance.innerHTML ? tinymceInstance.innerHTML : '';
      this.editorModelChange.emit(tinymceInstance.innerHTML);
    }
  }

  /**
   * Remove MediumEditor on destruction of directive
   */
  ngOnDestroy() {
    this.editorBox.editor?.destroy();
  }

  isPropertyUpdated( changes, viewModel ) {
    if ( !changes.hasOwnProperty('editorModel') ) {
      return false;
    }

    const change = changes.editorModel;

    if ( change.isFirstChange() ) {
      return true;
    }

    return !Object.is(viewModel, change.currentValue);
  }

  private removeBrokenBlockquote( currentValue: string ) {
    if ( currentValue ) {
      currentValue = currentValue.replace(/<blockquote><\/blockquote>/g, '');
      currentValue = currentValue.replace(/<blockquote class=""><\/blockquote>/g, '');
      this.editorModel = currentValue;
    }
    this.refreshView();
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  protected readonly TINYMCE_OPTIONS: any = TINYMCE_OPTIONS;
}
