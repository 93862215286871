<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-progress-bar
  *ngIf="showProgress"
  [class.not-started]="!started"
  matTooltip="{{ 'translations.global.label.progress' | transloco }}: {{progress}}%"
  matTooltipPosition="above"
  color="green"
  mode="determinate"
  [attr.aria-label]="('translations.global.ariaLabels.progress' | transloco)"
  class="progress-bar"
  value="{{ progress }}">
</mat-progress-bar>
