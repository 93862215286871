import { BooleanQueryRequest, BooleanQueryType, NotRequest } from '../shared/models/admin/boolean-filters.model';

export class FiltersHelper {

  static extractIf<T extends BooleanQueryRequest, R>(
    check: (request: BooleanQueryRequest) => request is T,
    extract: (request: T) => R | R[],
    extractOpposite: (request: T) => R | R[] = extract,
  ): (requests: BooleanQueryRequest[]) => R[] {
    return (requests: BooleanQueryRequest[]) => {
      const plain = requests.filter(this.isPlainRequest).filter(check).flatMap(extract);
      const opposite = requests.filter(this.isNotRequest).map(request => request.arg).filter(check).flatMap(extractOpposite);
      return [...new Set(plain.concat(opposite))];
    }
  }

  private static isNotRequest(request: BooleanQueryRequest): request is NotRequest {
    return request.type === BooleanQueryType.NOT;
  }

  private static isPlainRequest(request: BooleanQueryRequest): boolean {
    return request.type !== BooleanQueryType.NOT;
  }
}
