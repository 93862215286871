/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable, NgZone } from '@angular/core';
import { SnackbarHelper } from '@app/app/shared/helpers/snackbar-helper';
import { TranslationService } from '@app/app/shared/services/translation/translation.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ShareService {

  constructor(
    private translationService: TranslationService,
    private snackBar: MatSnackBar,
    private ngZone: NgZone
  ) {
  }

  shareAction(type: string, url: string) {
    if (type === 'facebook') {
      const facebookUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
      window.open(facebookUrl, '_blank', 'width=550,height=450');
    }

    if (type === 'twitter') {
      const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
      window.open(twitterUrl, '_blank', 'width=550,height=450');
    }

    if (type === 'linkedin') {
      const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
      window.open(linkedinUrl, '_blank', 'width=550,height=450');
    }

    if (type === 'copy') {
      navigator.clipboard.writeText(url);

      SnackbarHelper.showTranslatableSnackBar(
        this.ngZone, this.snackBar, this.translationService, 'shareUrlCopied'
      );
    }
  }

  shareCertificateToLinkedin(name: string,
                             organizationName: string,
                             issueMonth: string,
                             issueYear: string,
                             certId: string,
                             certUrl: string) {
    const shareCertificateUrl = 'https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME' +
      '&name=' + name +
      '&organizationName=' + organizationName +
      '&issueMonth=' + issueMonth +
      '&issueYear=' + issueYear +
      '&certUrl=' + certUrl +
      '&certId=' + certId

    window.open(shareCertificateUrl, '_blank', 'width=740,height=790');
  }
}
