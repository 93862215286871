/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

import { MomentEditorState } from '../moment-view-all/moment-view-all-state.model';

// -------------------------------------------------------------------------
export class OpenMomentForUpdate {
  static readonly type = '[Moment Update] Open moment for update';

  constructor(public id: string) { }
}

// -------------------------------------------------------------------------
export class LoadMomentToUpdate {
  static readonly type = '[Moment Update] Load Moment';

  constructor(public momentUid: string) {
  }
}

// -------------------------------------------------------------------------
export class TriggerMomentUpdate {
  static readonly type = '[Moment Update] Trigger Update';
}

// -------------------------------------------------------------------------
export class MomentUpdateChange {
  static readonly type = '[Moment Update] Update moment change';

  constructor(public momentEditorState: MomentEditorState) {
  }
}
