/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

// -------------------------------------------------------------------------
export class FindTagsByTitle {
  static readonly type = '[Moment Tags] find tags by title';

  constructor(public title: string) {
  }
}

// -------------------------------------------------------------------------
export class CreateTag {
  static readonly type = '[Moment Tags] create a tag';

  constructor(public title: string) {
  }
}
