<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<ng-container *ngIf="!newVersionEnabled">
  <ng-container *ngIf="expanded">
    <div class="quiz"
         #quizHtml
         tabindex="0"
         [attr.aria-label]="('translations.global.ariaLabels.thisIsQuizForm' | transloco)">
      <div class="quiz__header">
        <input
          mat-input
          class="quiz-title"
          type="text"
          placeholder="Enter quiz title"
          [(ngModel)]="quizTitle.value"
          (ngModelChange)="onQuizTitleUpdate($event)">
      </div>

      <div class="quiz__body">
        <ptl-quiz-instructions
          [instructions]="quiz.instructions"
          [languageCode]="languageCode"
          [organization]="organization"
          (mediumEditorChanged)="onInstructionValueChanged($event)">
        </ptl-quiz-instructions>

        <ptl-quiz-questions
          [questions]="quiz.questions"
          [quizUid]="quiz._id"
          [languageCode]="languageCode"
          [currentLanguage]="currentLanguage"
          [organization]="organization"
          (questionsChanged)="quizChanged = true">
        </ptl-quiz-questions>

        <ptl-quiz-settings
          [settings]="quiz.settings"
          [questionsCount]="quiz.questions.length"
          (settingsChanged)="onSettingsValueChanged($event)">
        </ptl-quiz-settings>
      </div>
    </div>
    <div class="quiz__footer">
      <div class="quiz__footer-buttons">
        <button
          mat-button
          class="quiz__footer__btn"
          color="warn"
          type="button"
          title="Delete"
          *ngIf="canRemoveItem"
          [attr.aria-label]="('translations.global.ariaLabels.deleteQuiz' | transloco)"
          (click)="onQuizElementRemoved()">{{ 'translations.global.button.delete' | transloco }}
        </button>
        <button
          mat-flat-button
          class="quiz__footer__btn uppercase"
          color="primary"
          type="button"
          title="Update"
          [attr.aria-label]="('translations.global.ariaLabels.updateQuiz' | transloco)"
          (click)="saveQuiz()">{{ 'translations.global.button.update' | transloco }}
        </button>
      </div>
    </div>
  </ng-container>
  <div class="quiz__contracted" *ngIf="!expanded">
    <div class="quiz__contracted-text">
      <span class="quiz__contracted-label uppercase">{{ 'translations.quiz.title.quiz' | transloco }}</span>
      <span>{{ quizTitle?.value }}</span>
    </div>
    <div class="quiz__contracted-actions">
      <button
        mat-button
        class="quiz__edit-btn"
        title="Edit"
        color="primary"
        [attr.aria-label]="('translations.global.ariaLabels.editQuiz' | transloco)"
        (click)="expanded = true;">{{ 'translations.global.button.edit' | transloco }}
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="newVersionEnabled">
  <ng-container *ngIf="expanded">

    <div class="quiz-container"
         #quizHtml
         tabindex="0"
         [attr.aria-label]="('translations.global.ariaLabels.thisIsQuizForm' | transloco)">
      <div class="quiz-container-inner"
           [class.is_focused]="focused">
        <div class="quiz-container-top-line">
          <div class="quiz-container-title large bold">{{'translations.quiz.title.quiz' | transloco}}</div>
          <div class="quiz-container-icon">
            <svg class="svg" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#quiz"></use>
            </svg>
          </div>
        </div>

        <div class="quiz-questions-container">
          <ptl-quiz-questions
            [questions]="quiz.questions"
            [quizUid]="quiz._id"
            [newVersionEnabled]="true"
            [languageCode]="languageCode"
            [currentLanguage]="currentLanguage"
            [organization]="organization"
            (questionsChanged)="quizChanged = true">
          </ptl-quiz-questions>
        </div>

        <div class="quiz-settings-container">

          <div class="headline large">{{'translations.quiz.title.quizSettings' | transloco}}</div>

          <div class="quiz-settings-title-box">
            <div class="title small bold">{{'translations.quiz.label.quizTitle' | transloco}}</div>
            <div class="description extra-small">{{'translations.quiz.description.quizTitle' | transloco}}</div>
            <input
              class="quiz-title-field"
              type="text"
              placeholder="Enter quiz title"
              [(ngModel)]="quizTitle.value"
              (ngModelChange)="onQuizTitleUpdate($event)">
          </div>
          <ptl-quiz-instructions
            class="quiz-settings-instructions-box"
            [newVersionEnabled]="true"
            [instructions]="quiz.instructions"
            [languageCode]="languageCode"
            [organization]="organization"
            (mediumEditorChanged)="onInstructionValueChanged($event)">
          </ptl-quiz-instructions>

          <ptl-quiz-settings
            class="quiz-settings-box"
            [newVersionEnabled]="true"
            [settings]="quiz.settings"
            [questionsCount]="quiz.questions.length"
            (settingsChanged)="onSettingsValueChanged($event)">
          </ptl-quiz-settings>
        </div>

        <div class="quiz-footer-buttons">
          <button
            mat-flat-button
            class="remove-button small"
            type="button"
            color="accent"
            *ngIf="canRemoveItem"
            [attr.aria-label]="('translations.global.ariaLabels.deleteQuiz' | transloco)"
            (click)="onQuizElementRemoved()">
            <svg class="svg svg-icon icon-left" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
            </svg>
            <span>{{ 'translations.global.button.delete' | transloco }}</span>
          </button>
          <button
            mat-flat-button
            class="save-button small btn-with-loader"
            title="Save"
            type="button"
            color="primary"
            (click)="saveQuiz()"
            [attr.aria-label]="('translations.global.ariaLabels.updateQuiz' | transloco)">
            <svg class="svg svg-icon icon-left" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
            </svg>
            <span>{{ 'translations.global.button.update' | transloco }}</span>
            <mat-spinner class="is-right" *ngIf="saveInProgress" [diameter]="20"></mat-spinner>
          </button>
          <button
            mat-flat-button
            class="form-button-save small"
            title="Save"
            color="accent"
            type="button"
            (click)="expanded = false"
            [attr.aria-label]="('translations.global.ariaLabels.collapseForm' | transloco)">
            <span>{{ 'translations.global.button.cancel' | transloco }}</span>
          </button>
        </div>
      </div>
    </div>

  </ng-container>

  <div class="form-section-new" *ngIf="!expanded" (click)="expanded = true" (keydown.enter)="$event.stopPropagation()">
    <form class="form-block">
      <div class="form-block-inner">
        <div class="form-block-title-box medium bold">
          {{ !quizTitle?.value ? ('translations.quiz.title.quiz' | transloco) : quizTitle?.value }}
        </div>
        <div class="form-block-help-text-box extra-small"></div>

        <span class="setting-text extra-small" *ngIf="!expanded">{{ 'translations.global.button.settings' | transloco }}</span>

      </div>

    </form>

  </div>


</ng-container>
