/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LoadableState } from '../../store';

@Component({
  selector: 'ptl-loadable-content',
  templateUrl: './loadable-content.component.html',
  styleUrls: ['./loadable-content.component.scss'],
})
export class LoadableContentComponent {

  /** Defines loadable state */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  @Input() state: LoadableState<any>;
  /** Defines the diameter of the spinner. (in pixels) */
  @Input() spinnerDiameter = 30;

}
