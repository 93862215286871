/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import { ResourceSubheaderUpdateRequest } from '../../models';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const SUBHEADER_DATA_SERVICE =
  new InjectionToken<SubheaderDataService>('[subheader] SubheaderDataService');

/** The service handling tag CRUD operations. */
export interface SubheaderDataService {
  updatePlaylistSubheader(playlistUid: string, subheader: ResourceSubheaderUpdateRequest, languageCode?: string): ObservableResult<void>;

  updateCardSubheader(cardUid: string, subheader: ResourceSubheaderUpdateRequest, languageCode?: string): ObservableResult<void>;
}
