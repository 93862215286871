/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import {
  AvailableSlot,
  BookableAppointment,
  BookableAppointmentRequest,
  BookableResource,
  BookableResourceRequest,
  BookableSchedule, BookableSlotAdmin, BookableSlotAdminRequest,
  BookedAppointment,
  BookedAppointmentsRequest,
  BookingRequest,
  BulkCancelBookingsRequest,
  ScheduleAvailabilityRequest,
  ScheduleRequest, ScheduleWithSlots
} from '../../models/editor/booking-content.model';
import { Observable } from 'rxjs';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const BOOKING_DATA_SERVICE =
  new InjectionToken<BookingDataService>('[resource] BookingDataService');

/** The service handling booking-related operations. */
export interface BookingDataService {

  createBookableAppointment(request: BookableAppointmentRequest,
                            cardUid: string,
                            languageCode?: string)
    : ObservableResult<BookableAppointment>;

  createBookableResource(request: BookableResourceRequest,
                         cardUid: string,
                         languageCode?: string): ObservableResult<BookableResource>;

  updateBookableResource(request: BookableResourceRequest,
                         bookableResourceUid: string,
                         cardUid: string,
                         languageCode?: string): ObservableResult<BookableResource>;

  getBookableAppointment(bookableAppointmentUid: string,
                         languageCode?: string)
    : ObservableResult<BookableAppointment>;

  createSchedule(request: ScheduleRequest,
                 bookableResourceUid: string,
                 cardUid: string,
                 languageCode?: string)
    : ObservableResult<BookableSchedule>;

  getSchedule(cardUid: string, resourceUid: string, languageCode?: string): ObservableResult<ScheduleWithSlots>;

  updateSchedule(request: ScheduleRequest,
                 bookableResourceUid: string,
                 cardUid: string,
                 bookableScheduleUid: string,
                 languageCode?: string)
    : ObservableResult<BookableSchedule>;

  setScheduleAvailability(request: ScheduleAvailabilityRequest,
                          bookableScheduleUid: string,
                          cardUid: string,
                          languageCode?: string):
    ObservableResult<BookableSlotAdmin>;

  updateScheduleAvailability(request: ScheduleAvailabilityRequest,
                             bookableResourceUid: string,
                             bookableScheduleUid: string,
                             cardUid: string,
                             apiScheduleBlockUid: string,
                             languageCode?: string):
    ObservableResult<BookableSlotAdmin>;

  deleteScheduleAvailability(
    bookableResourceUid: string,
    bookableScheduleUid: string,
    cardUid: string,
    apiScheduleBlockUid: string): ObservableResult<void>;

  getBookableSlots(bookableAppointmentId: string, from: string, to: string, languageCode?: string):
    ObservableResult<AvailableSlot[]>;

  placeBooking(bookableResourceUid: string,
               request: BookingRequest,
               languageCode: string): ObservableResult<BookedAppointment>;

  getAppointmentResources(bookableAppointmentUid: string,
                          cardUid: string,
                          languageCode?: string): ObservableResult<BookableResource[]>;

  disassociate(cardUid: string, appointmentUid: string, resourceUid: string): ObservableResult<void>;

  getBooking(bookingUid: string, languageCode?: string): ObservableResult<BookedAppointment>;

  updateBookableAppointment(appointmentUid: string,
                            request: BookableAppointmentRequest,
                            cardUid: string,
                            currentLanguage: string): ObservableResult<BookableAppointment>;

  updateResourceSlots(cardUid: string,
                      bookableResourceUid: string,
                      bookableScheduleUid: string,
                      request: BookableSlotAdminRequest[],
                      languageCode: string): ObservableResult<BookableSlotAdmin[]>;

  cancelBooking(bookingUid: string, languageCode?: string): ObservableResult<BookedAppointment>;

  getBookingICalEvent(bookingUid: string, languageCode?: string): Observable<string>;

  cancelBulkBookings(request: BulkCancelBookingsRequest, languageCode?: string): ObservableResult<boolean>;

  isBookingsDataAvailable(): ObservableResult<boolean>;

  getBookings(request: BookedAppointmentsRequest, languageCode?: string): ObservableResult<BookedAppointment[]>;

  getBookedAppointments(request: BookedAppointmentsRequest, languageCode?: string): ObservableResult<BookedAppointment[]>;
}
