<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="editor-toolbar-wrapper"
  [class.editor-toolbar-wrapper--active]="activeToolbarItem !== 'ALL'">
  <button
    mat-icon-button
    id="toolbarButton"
    class="editor-toolbar-wrapper__toolbar-button editor-toolbar-wrapper__toolbar-button--main f_editor-toolbar-add-button"
    [title]="toolbarVisible ? 'Close menu' : 'Add an image, video, embed, or new form'"
    [attr.aria-label]="toolbarVisible ? ('translations.global.ariaLabels.closeMenu' | transloco) : ('translations.global.ariaLabels.addAnImageVideoEmbedOrNewForm' | transloco)"
    [class.editor-toolbar-wrapper__toolbar-button--opened]="toolbarVisible"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    (click)="toggleToolbar()">
    <mat-icon aria-hidden="true" [inline]="true">add</mat-icon>
  </button>

  <div class="editor-toolbar-wrapper__add-buttons" id="toolbarFloatingButtonsContainer" *ngIf="toolbarVisible">

    <button mat-stroked-button
            class="editor-toolbar-wrapper__toolbar-action-button"
            [matTooltip]="'translations.editor.toolbar.label.addInteractive' | transloco"
            [attr.aria-label]="('translations.global.ariaLabels.addInteractiveForms' | transloco)"
            [class.is_hidden]="subItemsExpanded"
            *ngIf="activeToolbarItem === 'ALL' && form"
            (click)="onShowInteractiveContent()">
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
        <path
          d="M17 3c-.938 0-1.883.313-2.656.969A3.86 3.86 0 0013 6.906v21.532c-.441.16-.844.207-1.656.906C10.168 30.359 9 32.184 9 34.906c0 1.973.785 3.778 1.094 4.5v.032l2.812 5.593c.004.008-.004.024 0 .032 1.477 3.042 4.633 4.937 8 4.937H30c4.945 0 9-4.055 9-9V25c0-.82-.129-1.95-.688-3-.558-1.05-1.714-2-3.312-2-1.094 0-1.906.375-2.563.875-.109-.3-.164-.637-.312-.906C31.504 18.848 30.367 18 29 18a4.012 4.012 0 00-2.656.969C25.68 17.87 24.559 17 23 17c-.781 0-1.426.246-2 .594V7c0-1.266-.54-2.324-1.313-3-.773-.676-1.75-1-2.687-1zm0 2c.46 0 1 .176 1.375.5S19 6.266 19 7v16h2v-2c0-.875.66-2 2-2 1.34 0 2 1.125 2 2v1a.999.999 0 001 .984.999.999 0 001-.984c0-.535.133-1.066.406-1.406.274-.34.676-.594 1.594-.594.633 0 .996.258 1.375.938.379.68.625 1.78.625 3.062h2c0-.582.105-1.09.344-1.406.238-.317.62-.594 1.656-.594.902 0 1.246.34 1.563.938.316.597.437 1.48.437 2.062v16c0 3.855-3.145 7-7 7h-9.094c-2.62 0-5.09-1.5-6.218-3.844l-2.782-5.562c-.004-.008.004-.024 0-.032-.293-.687-.906-2.242-.906-3.656 0-2.18.832-3.32 1.656-4.031.824-.71 1.594-.906 1.594-.906A1 1 0 0015 29V6.906c0-.668.25-1.086.625-1.406C16 5.18 16.539 5 17 5zm4.906 24.969c-.043.008-.086.02-.125.031A1.002 1.002 0 0021 31v9c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879v-9a1 1 0 00-1.094-1.031zm5 0c-.043.008-.086.02-.125.031A1.002 1.002 0 0026 31v9c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879v-9a1 1 0 00-1.094-1.031zm5 0c-.043.008-.086.02-.125.031A1.002 1.002 0 0031 31v9c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879v-9a1 1 0 00-1.094-1.031z"/>
      </svg>
      {{ 'translations.editor.toolbar.button.addInteractive' | transloco }}
    </button>

    <button mat-stroked-button
            class="editor-toolbar-wrapper__toolbar-action-button"
            [matTooltip]="'translations.editor.toolbar.label.addMedia' | transloco"
            [attr.aria-label]="('translations.global.ariaLabels.addInteractiveForms' | transloco)"
            *ngIf="activeToolbarItem === 'ALL' && windowWidth <= 900"
            [class.is_hidden]="subItemsExpanded"
            (click)="onShowMediaContent()">
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
        <path
          d="M0 5v40h50V5zm2 2h46v26H37.32l-7-5h-5.906l-4.086-4.086-4.867.977-4.305-6.461L2 27.586zm35.5 6a4.516 4.516 0 00-4.5 4.5c0 2.473 2.027 4.5 4.5 4.5s4.5-2.027 4.5-4.5-2.027-4.5-4.5-4.5zm0 2c1.39 0 2.5 1.11 2.5 2.5S38.89 20 37.5 20 35 18.89 35 17.5s1.11-2.5 2.5-2.5zm-26.656 6.57l3.695 5.54 5.133-1.024L23.586 30h6.094l7 5H48v8H2V30.414z"/>
      </svg>
      {{ 'translations.editor.toolbar.button.addMedia' | transloco }}
    </button>

    <div class="editor-toolbar-wrapper__toolbar-action-buttons-box" *ngIf="showInteractiveContent">

      <button
        mat-stroked-button
        class="editor-toolbar-wrapper__toolbar-action-button"
        [matTooltip]="'translations.editor.toolbar.label.addTextForm' | transloco"
        *ngIf="activeToolbarItem === 'ALL' && form"
        [attr.aria-label]="('translations.global.ariaLabels.addTextForm' | transloco)"
        (click)="addEmptyForm()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="50" height="50" aria-hidden="true">
          <path
            d="M1.5 5a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-13zM2 6h12v5H2V6zm1 3v1h1V9H3zm2 0v1h1V9H5zm2 0v1h1V9H7z"/>
        </svg>
        {{ 'translations.editor.toolbar.button.addTextForm' | transloco }}
      </button>

      <button
        mat-stroked-button
        class="editor-toolbar-wrapper__toolbar-action-button"
        [matTooltip]="'translations.editor.toolbar.label.addCheckboxForm' | transloco"
        *ngIf="activeToolbarItem === 'ALL' && form && !textFormOnly"
        [attr.aria-label]="('translations.global.ariaLabels.addCheckboxForm' | transloco)"
        (click)="addCheckboxForm()">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
          <path
            d="M11 4c-3.855 0-7 3.145-7 7v28c0 3.855 3.145 7 7 7h28c3.855 0 7-3.145 7-7V11c0-3.855-3.145-7-7-7zm0 2h28c2.773 0 5 2.227 5 5v28c0 2.773-2.227 5-5 5H11c-2.773 0-5-2.227-5-5V11c0-2.773 2.227-5 5-5zm25.234 9.832l-13.32 15.723-8.133-7.586-1.363 1.465 9.664 9.015 14.684-17.324z"/>
        </svg>
        {{ 'translations.editor.toolbar.button.addCheckboxForm' | transloco }}
      </button>

      <button
        mat-stroked-button
        class="editor-toolbar-wrapper__toolbar-action-button"
        [matTooltip]="'translations.editor.toolbar.label.addUploadForm' | transloco"
        *ngIf="activeToolbarItem === 'ALL' && form && !textFormOnly"
        [attr.aria-label]="('translations.global.ariaLabels.addUploadForm' | transloco)"
        (click)="addUploadForm()">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
          <path
            d="M25 .594l-.719.687-8 8a1.014 1.014 0 000 1.438c.399.398 1.04.398 1.438 0L24 4.438V32c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879V4.437l6.281 6.282c.399.398 1.04.398 1.438 0a1.014 1.014 0 000-1.438l-8-8zM7 16v34h36V16H33a1.006 1.006 0 00-.879.496 1.01 1.01 0 000 1.008c.184.312.52.5.879.496h8v30H9V18h8c.36.004.695-.184.879-.496a1.01 1.01 0 000-1.008c-.184-.312-.52-.5-.879-.496z"/>
        </svg>
        {{ 'translations.editor.toolbar.button.addUploadForm' | transloco }}
      </button>

      <button
        mat-stroked-button
        class="editor-toolbar-wrapper__toolbar-action-button"
        [matTooltip]="'translations.editor.toolbar.label.addQuiz' | transloco"
        *ngIf="activeToolbarItem === 'ALL' && quiz"
        [attr.aria-label]="('translations.global.ariaLabels.addQuiz' | transloco)"
        (click)="onAddQuiz()">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
          <path
            d="M7 7c-1.645 0-3 1.355-3 3v30c0 1.645 1.355 3 3 3h36c1.645 0 3-1.355 3-3V10c0-1.645-1.355-3-3-3H7zm0 2h36c.565 0 1 .435 1 1v3H6v-3c0-.565.435-1 1-1zm1 1a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm3 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm3 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm-8 5h38v25c0 .565-.435 1-1 1H7c-.565 0-1-.435-1-1V15zm30.98 3.988a1 1 0 00-.812.457l-3.324 4.985-2.137-2.137a1 1 0 00-.717-.303 1 1 0 00-.697 1.717l2.883 2.883a1 1 0 00.709.416 1 1 0 00.023.004 1 1 0 00.1.004 1 1 0 00.01 0 1 1 0 00.01 0 1 1 0 00.917-.631l3.887-5.828a1 1 0 00-.852-1.567zM13 22a1 1 0 100 2h11a1 1 0 100-2H13zm23.98 6.988a1 1 0 00-.812.457l-3.324 4.985-2.137-2.137a1 1 0 00-.717-.303 1 1 0 00-.697 1.717l2.883 2.883a1 1 0 00.709.416 1 1 0 00.023.004 1 1 0 00.1.004 1 1 0 00.01 0 1 1 0 00.01 0 1 1 0 00.917-.631l3.887-5.828a1 1 0 00-.852-1.567zM13 32a1 1 0 100 2h11a1 1 0 100-2H13z"/>
        </svg>
        {{ 'translations.editor.toolbar.button.addQuiz' | transloco }}
      </button>

      <button
        mat-stroked-button
        class="editor-toolbar-wrapper__toolbar-action-button"
        [matTooltip]="'translations.editor.toolbar.label.addCollector' | transloco"
        *ngIf="activeToolbarItem === 'ALL' && collector"
        [attr.aria-label]="('translations.global.ariaLabels.addCollector' | transloco)"
        (click)="onInsertCollector()">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
          <path
            d="M7 2v46h36V15.41L29.184 2H7zm2 2h19v13h13v29H9V4zm21 1.58L39.707 15H30V5.58zm-5.898 17.856c-2.235 0-3.33 2.384-3.33 5.144 0 2.775.9 5.205 3.195 5.205 1.92 0 3.27-1.545 3.27-5.355 0-2.31-.69-4.993-3.135-4.994zm8.408.029c-2.145 0-4.246 1.53-4.291 5.205-.03 1.74.434 3.24 1.304 4.08.675.675 1.547 1.006 2.702 1.006.735 0 1.664-.181 2.1-.361-.002 0-.002-5.4 0-5.4h-2.716V29.6h.9v2.445c-.075.03-.212.045-.392.045-.915 0-1.857-.87-1.857-3.3 0-2.64 1.063-3.556 2.398-3.556.495 0 .87.077 1.154.227l.346-1.695c-.314-.15-.883-.301-1.648-.301zm-16.834.092v10.109h4.56v-1.664h-2.595v-8.445h-1.965zm8.351 1.588c.884 0 1.157 1.605 1.157 3.375 0 2.055-.317 3.554-1.141 3.554-.794 0-1.23-1.17-1.23-3.465 0-2.174.433-3.463 1.214-3.464z"/>
        </svg>
        {{ 'translations.editor.toolbar.button.addCollector' | transloco }}
      </button>
      <button
        mat-stroked-button
        class="editor-toolbar-wrapper__toolbar-action-button back-button"
        (click)="showInteractiveContent = false; subItemsExpanded = false">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
          <path
            d="M10.875 0a1.008 1.008 0 00-.594.281l-10 10a.999.999 0 000 1.438l10 10c.399.398 1.04.398 1.438 0a1.014 1.014 0 000-1.438L3.438 12H33c8.254 0 15 6.746 15 15 0 8.203-6.66 14.914-14.844 15H16v.688a1.025 1.025 0 000 .593V44h17.125c.055 0 .102-.031.156-.031C42.496 43.812 50 36.25 50 27s-7.504-16.813-16.719-16.969a1.169 1.169 0 00-.125-.031H3.437l8.282-8.281c.324-.301.41-.778.215-1.172A.996.996 0 0010.875 0zM0 42v2h2v-2zm4 0v2h2v-2zm4 0v2h2v-2zm4 0v2h2v-2z"/>
        </svg>
      </button>

    </div>

    <div class="editor-toolbar-wrapper__toolbar-action-buttons-box media-content"
         [class.is_hidden]="subItemsExpanded && windowWidth > 900" *ngIf="showMediaContent">
      <ptl-link-input
        class="editor-toolbar-wrapper__add-item"
        *ngIf="activeToolbarItem === 'LINK' && link || activeToolbarItem === 'ALL' && link"
        (mediaContentAdded)="onMediaContentAdded($event)"
        (regularLinkAdded)="onRegularLinkAdded($event)"
        (click)="selectToolbarItem('LINK')">
      </ptl-link-input>

      <ptl-file-upload
        [replace]="false"
        [uploadType]="'IMAGE'"
        class="editor-toolbar-wrapper__add-item f_image-upload"
        (mediaContentAdded)="onMediaContentAdded($event)"
        *ngIf="activeToolbarItem === 'ALL' && upload">
      </ptl-file-upload>

      <ptl-file-upload
        [replace]="false"
        [uploadType]="'FILE'"
        class="editor-toolbar-wrapper__add-item"
        (mediaContentAdded)="onMediaContentAdded($event)"
        *ngIf="activeToolbarItem === 'ALL' && upload">
      </ptl-file-upload>

      <ptl-file-upload
        [replace]="false"
        [uploadType]="'VIDEO'"
        class="editor-toolbar-wrapper__add-item"
        (mediaContentAdded)="onMediaContentAdded($event)"
        *ngIf="activeToolbarItem === 'ALL' && upload">
      </ptl-file-upload>

      <ptl-file-upload
        [replace]="false"
        [uploadType]="'AUDIO'"
        class="editor-toolbar-wrapper__add-item"
        (mediaContentAdded)="onMediaContentAdded($event)"
        *ngIf="activeToolbarItem === 'ALL' && upload">
      </ptl-file-upload>

      <button
        mat-stroked-button
        class="editor-toolbar-wrapper__toolbar-action-button"
        [matTooltip]="'translations.editor.toolbar.label.addEmbedCode' | transloco"
        *ngIf="activeToolbarItem === 'ALL' && embed"
        [attr.aria-label]="('translations.global.ariaLabels.addEmberCode' | transloco)"
        (click)="onInsertEmbedCode()">
        <mat-icon aria-hidden="true">code</mat-icon>
        {{ 'translations.editor.toolbar.button.addEmbedCode' | transloco }}
      </button>

      <button
        mat-stroked-button
        *ngIf="windowWidth <= 900"
        class="editor-toolbar-wrapper__toolbar-action-button back-button"
        (click)="showMediaContent = false; subItemsExpanded = false">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"  aria-hidden="true">
          <path
            d="M10.875 0a1.008 1.008 0 00-.594.281l-10 10a.999.999 0 000 1.438l10 10c.399.398 1.04.398 1.438 0a1.014 1.014 0 000-1.438L3.438 12H33c8.254 0 15 6.746 15 15 0 8.203-6.66 14.914-14.844 15H16v.688a1.025 1.025 0 000 .593V44h17.125c.055 0 .102-.031.156-.031C42.496 43.812 50 36.25 50 27s-7.504-16.813-16.719-16.969a1.169 1.169 0 00-.125-.031H3.437l8.282-8.281c.324-.301.41-.778.215-1.172A.996.996 0 0010.875 0zM0 42v2h2v-2zm4 0v2h2v-2zm4 0v2h2v-2zm4 0v2h2v-2z"/>
        </svg>
      </button>
    </div>
    <button
      mat-stroked-button
      class="editor-toolbar-wrapper__toolbar-action-button"
      [matTooltip]="'translations.editor.toolbar.label.addSeparator' | transloco"
      *ngIf="activeToolbarItem === 'ALL' && separator"
      [class.is_hidden]="subItemsExpanded"
      [attr.aria-label]="('translations.global.ariaLabels.addSeparator' | transloco)"
      (click)="onAddSeparator()">
      <span class="separator"></span>
    </button>

    <button
      mat-stroked-button
      class="editor-toolbar-wrapper__toolbar-action-button"
      [matTooltip]="'translations.editor.toolbar.label.addChart' | transloco"
      *ngIf="activeToolbarItem === 'ALL' && chart"
      [class.is_hidden]="subItemsExpanded"
      [attr.aria-label]="('translations.global.ariaLabels.addChart' | transloco)"
      (click)="onAddChart()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="64" height="64"  aria-hidden="true">
        <path
          d="M124 1c-1.7 0-3 1.3-3 3v27H94c-1.7 0-3 1.3-3 3v27H64c-1.7 0-3 1.3-3 3v27H34c-1.7 0-3 1.3-3 3v27H4c-1.7 0-3 1.3-3 3s1.3 3 3 3h30c1.7 0 3-1.3 3-3V97h27c1.7 0 3-1.3 3-3V67h27c1.7 0 3-1.3 3-3V37h27c1.7 0 3-1.3 3-3V4c0-1.7-1.3-3-3-3zm-37.139.643a3.193 3.193 0 00-.761.056l-38.5 9.602c-1 .3-1.9 1.1-2.2 2.1-.3 1 0 2.2.8 2.9l7.4 7.398L22.9 54.9c-1.2 1.2-1.2 3.1 0 4.2.6.6 1.3.9 2.1.9s1.5-.3 2.1-.9L60 25.9c.1-.1.1-.2.2-.3l.2-.2c.1-.1.1-.2.1-.3 0-.1.1-.2.1-.2 0-.1.1-.2.1-.3 0-.1.1-.2.1-.3v-1.2c0-.1-.1-.2-.1-.3 0-.1 0-.2-.1-.3 0-.1-.1-.2-.2-.3 0-.1-.1-.2-.1-.2-.1-.1-.2-.2-.2-.3-.1-.1-.1-.1-.1-.2l-5.7-5.7 28.5-7.1-7.1 28.5-6.4-6.4c-1.1-1.1-3.1-1.1-4.2 0l-63 63C.9 95 .9 96.9 2.1 98c.4.7 1.1 1 1.9 1 .8 0 1.5-.3 2.1-.9L67 37.2l8.1 8.1c.8.7 1.9 1 2.9.7 1-.3 1.8-1.1 2.1-2.2l9.6-38.5c.3-1 0-2.1-.8-2.8-.525-.525-1.275-.825-2.039-.857zM6.201 72.027A2.966 2.966 0 004.5 72.4c-1.4.8-1.9 2.7-1.1 4.1l.2.4c.6 1 1.6 1.5 2.6 1.5.5 0 1-.1 1.5-.4 1.4-.8 1.9-2.7 1.1-4.1l-.2-.4c-.5-.875-1.43-1.398-2.399-1.473z"/>
      </svg>
      {{ 'translations.editor.toolbar.button.addChart' | transloco }}
    </button>
  </div>
</div>
