/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Observable, of } from 'rxjs';

/* eslint-disable @typescript-eslint/no-explicit-any */

/** Represents a result which can succeed or error. */
export interface Result<T, E = string, D = string[]> {
  /** The successful result. */
  value?: T;
  /** The error. */
  error?: E;
  /** The uncompleted data forms. */
  dataForms?: D;
  /** True if the result is a success. */
  isSuccess: boolean;
}

/** Represents a successful result. */
export class SuccessResult<T = void> implements Result<T> {
  readonly isSuccess = true;

  constructor(public value?: T) {
  }
}

/** Represents an errored result. */
export class ErrorResult<T = string, D = string[]> implements Result<any, T, D> {
  readonly isSuccess = false;

  constructor(public error?: T, public dataForms?: D) {
  }
}

/** An observable which emits results of the given type. */
export class ObservableResult<T> extends Observable<Result<T>> {

  /**
   * Creates a new instance with the given success result.
   *
   * @param value the result
   * @return a new instance with the given success result
   */
  static ofSuccess<T = void>(value?: T): ObservableResult<T> {
    return of(new SuccessResult(value));
  }

  /**
   * Creates a new error instance with the given message.
   *
   * @param message the error message
   * @param dataForms the uncompleted data forms
   * @return a new error instance with the given message
   */
  static ofError(message: string, dataForms?: string[]): ObservableResult<any> {
    return of(new ErrorResult(message, dataForms));
  }
}
