/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { defaultLoadableState, LoadableState } from '../../../../shared/store';
import { Playlist } from '../../../../shared/models';

/** The Playlist view main state model. */
export interface PlaylistViewStateModel {
  /** The content for the Playlist view. */
  resource: LoadableState<Playlist>;
  /** Defines if card was opened */
  cardWasOpened: boolean;
}

/** Initial empty state of the lerner view. */
export const INITIAL_STATE: PlaylistViewStateModel = {
  resource: defaultLoadableState(),
  cardWasOpened: false,
};
