/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { UserDetails } from './user-details.model';

export enum FinalizationType {
  ORG_SAML_LOGIN_REQUIRED = 'ORG_SAML_LOGIN_REQUIRED',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
}

export interface LoginFinalizationResponse {
  accessToken?: string;
  message?: string;
  type?: FinalizationType;
  redirect?: string;
  user: UserDetails;
}

export interface TokenValidationResponse {
  email: string;
}
