<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ptl-media-content-view
  [position]="mediaUploadData?.position || 'CENTER'"
  [caption]="mediaUploadData?.caption"
  [url]="mediaUploadData?.url"
  [canRemoveItem]="canRemoveItem"
  [openUrlInTab]="mediaUploadData?.openUrlInTab"
  [isUploadedDocument]="mediaUploadData?.type === 'MEDIA_UPLOAD' && mediaUploadData?.mediaType === 'DOCUMENT'"
  [isUploadedImage]="mediaUploadData?.type === 'MEDIA_UPLOAD' && mediaUploadData?.mediaType === 'IMAGE'"
  [isUploadedVideo]="mediaUploadData?.type === 'MEDIA_UPLOAD' && (mediaUploadData?.mediaType === 'VIDEO' || mediaUploadData?.mediaType === 'STREAM_VIDEO')"
  [uploadedVideoType]="(mediaUploadData?.mediaType === 'VIDEO' || mediaUploadData?.mediaType === 'STREAM_VIDEO') ? mediaUploadData?.mediaType : undefined"
  [isUploadedAudio]="mediaUploadData?.type === 'MEDIA_UPLOAD' && mediaUploadData?.mediaType === 'AUDIO'"
  (removeMediaContent)="removeMedia()"
  (updateMediaPosition)="onUpdateMediaPosition($event)"
  (mediaContentReplaced)="onReplaceMediaContent($event)"
  (regularLinkReplaced)="onReplaceRegularLink($event)"
  (updateMediaCaption)="onUpdateMediaCaption($event)"
  (toolBarState)="toolBarState($event)"
  (updateMediaHyperlink)="onUpdateMediaHyperlink($event)">

  <ng-container *ngIf="mediaUploadData?.mediaType === 'IMAGE'">
    <img
      class="media-upload__browser-image"
      *ngIf="isNewEditorEnabled ? !isProgressBarVisible && !!imageSrc : !!mediaUploadData?.content || (!!uploadedMediaDataUrl && !markInMemoryUploadsAsNotUploaded)"
      alt="Uploaded image"
      [src]="imageSrc" />
    <ptl-uploads-placeholder
      *ngIf="isNewEditorEnabled ? isProgressBarVisible : !mediaUploadData?.content && (!uploadedMediaDataUrl || markInMemoryUploadsAsNotUploaded)"
      [dataLoaded]="!markInMemoryUploadsAsNotUploaded && !!uploadedMediaDataUrl"
      [mediaType]="mediaUploadData?.mediaType"
      [content]="mediaUploadData"
      (contentUploadProgressEmitter)="contentUploadProgress($event)">
    </ptl-uploads-placeholder>
  </ng-container>

  <ng-container *ngIf="mediaUploadData?.mediaType === 'VIDEO'">
    <video controls
           *ngIf="!!mediaUploadData?.content || (!!uploadedMediaDataUrl && !markInMemoryUploadsAsNotUploaded)"
           class="media-upload__browser-video"
           [src]="mediaUploadData?.content ? mediaUploadData?.content.url : uploadedMediaDataUrl">
    </video>
    <ptl-uploads-placeholder
      *ngIf="!mediaUploadData?.content && (!uploadedMediaDataUrl || markInMemoryUploadsAsNotUploaded)"
      [dataLoaded]="!markInMemoryUploadsAsNotUploaded && !!uploadedMediaDataUrl"
      [mediaType]="mediaUploadData?.mediaType"
      [content]="mediaUploadData">
    </ptl-uploads-placeholder>
  </ng-container>

  <ng-container *ngIf="mediaUploadData?.mediaType === 'STREAM_VIDEO'">
    <ptl-resource-stream-video-preview
      *ngIf="mediaUploadData?.content?.url"
      [content]="mediaUploadData">
    </ptl-resource-stream-video-preview>
    <div *ngIf="!mediaUploadData?.content?.url" class="media-upload__stream-video-placeholder">

      <div *ngIf="streamVideoBytePlaceholder">
        <video controls
               *ngIf="uploadedMediaDataUrl"
               class="media-upload__browser-video"
               [src]="uploadedMediaDataUrl">
        </video>
        <ptl-uploads-placeholder
          *ngIf="!uploadedMediaDataUrl"
          description="{{ 'translations.videoUploadInfo' | transloco }}"
          [dataLoaded]="uploadedMediaDataUrl"
          [mediaType]="mediaUploadData?.mediaType"
          [content]="mediaUploadData">
        </ptl-uploads-placeholder>
      </div>

      <div *ngIf="!streamVideoBytePlaceholder">
        <ptl-uploads-placeholder
          description="{{ 'translations.videoUploadInfo' | transloco }}"
          [dataLoaded]="uploadedMediaDataUrl"
          [mediaType]="mediaUploadData?.mediaType"
          [content]="mediaUploadData">
        </ptl-uploads-placeholder> 
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="mediaUploadData?.mediaType === 'AUDIO'">
    <audio controls
           *ngIf="isNewEditorEnabled ? !isProgressBarVisible : !!mediaUploadData?.content || (!!uploadedMediaDataUrl && !markInMemoryUploadsAsNotUploaded)"
           class="media-upload__browser-audio"
           [src]="mediaUploadData?.content ? mediaUploadData?.content.url : uploadedMediaDataUrl">
    </audio>
    <ptl-uploads-placeholder
      *ngIf="isNewEditorEnabled ? isProgressBarVisible : !mediaUploadData?.content && (!uploadedMediaDataUrl || markInMemoryUploadsAsNotUploaded)"
      [dataLoaded]="!markInMemoryUploadsAsNotUploaded && !!uploadedMediaDataUrl"
      [mediaType]="mediaUploadData?.mediaType"
      [content]="mediaUploadData"
      (contentUploadProgressEmitter)="contentUploadProgress($event)">
    </ptl-uploads-placeholder>
  </ng-container>

  <ng-container *ngIf="mediaUploadData?.mediaType === 'DOCUMENT'">
    <ptl-resource-document-preview
      *ngIf="!isProgressBarVisible"
      [isEditingContent]="true"
      [content]="mediaUploadData"
      [pdfDataUrl]="uploadedMediaDataUrl">
    </ptl-resource-document-preview>
    <ptl-uploads-placeholder
      *ngIf="isProgressBarVisible"
      [dataLoaded]="!markInMemoryUploadsAsNotUploaded && !!uploadedMediaDataUrl"
      [mediaType]="mediaUploadData?.mediaType"
      [content]="mediaUploadData"
      (contentUploadProgressEmitter)="contentUploadProgress($event)">
    </ptl-uploads-placeholder>
  </ng-container>

  <span *ngIf="!!mediaUploadData?.file">
    {{ mediaUploadData?.file.name }}
  </span>
</ptl-media-content-view>
