/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { ObservableResult } from '../../store';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';
import { BookingDataService } from './booking-data.service';
import {
  AvailableSlot,
  BookableAppointment,
  BookableAppointmentRequest,
  BookableResource,
  BookableResourceRequest,
  BookableSchedule, BookableSlotAdmin, BookableSlotAdminRequest,
  BookedAppointment,
  BookedAppointmentsRequest,
  BookingRequest,
  BulkCancelBookingsRequest,
  ScheduleAvailabilityRequest,
  ScheduleRequest, ScheduleWithSlots
} from '../../models/editor/booking-content.model';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TranslationService } from '../translation/translation.service';
import { forkJoin, Observable } from 'rxjs';
import * as moment from 'moment';
import { CalendarHelper } from '../../helpers/calendar-helper';

@Injectable()
export class ApiBookingDataService implements BookingDataService {
  private cardsUri = 'cards';
  private bookingsUri = 'bookings';
  private resourcesUri = 'resources';
  private appointmentsUri = 'appointments';
  private schedulesUri = 'schedules';
  private availabilityUri = 'availabilities';

  constructor(private client: RestClientService, private translationService: TranslationService) {
  }

  createBookableAppointment(request: BookableAppointmentRequest,
                            cardUid: string,
                            languageCode?: string): ObservableResult<BookableAppointment> {
    return this.client.post<BookableAppointment>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.cardsUri}/${cardUid}/${this.bookingsUri}/${this.appointmentsUri}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.addAppointment'))
      )
    );
  }

  createBookableResource(request: BookableResourceRequest,
                         cardUid: string,
                         languageCode?: string): ObservableResult<BookableResource> {
    return this.client.post<BookableResource>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.cardsUri}/${cardUid}/${this.bookingsUri}/${this.resourcesUri}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.addResource'))
      )
    );
  }

  updateBookableResource(request: BookableResourceRequest,
                         bookableResourceUid: string,
                         cardUid: string,
                         languageCode?: string): ObservableResult<BookableResource> {
    return this.client.put<BookableResource>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.cardsUri}/${cardUid}/${this.bookingsUri}/${this.resourcesUri}/${bookableResourceUid}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.addResource'))
      )
    );
  }

  getBookableAppointment(bookableAppointmentUid: string,
                         languageCode?: string)
    : ObservableResult<BookableAppointment> {
    return this.client.get<BookableAppointment>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.bookingsUri}/${this.appointmentsUri}/${bookableAppointmentUid}`),
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.getAppointment'))
      )
    );
  }

  createSchedule(request: ScheduleRequest,
                 bookableResourceUid: string,
                 cardUid: string,
                 languageCode?: string): ObservableResult<BookableSchedule> {
    return this.client.post<BookableSchedule>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.cardsUri}/${cardUid}/${this.bookingsUri}/${this.resourcesUri}/${bookableResourceUid}/${this.schedulesUri}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.addSchedule'))
      )
    );
  }

  setScheduleAvailability(request: ScheduleAvailabilityRequest,
                          bookableScheduleUid: string,
                          cardUid: string,
                          languageCode?: string):
    ObservableResult<BookableSlotAdmin> {
    return this.client.post<BookableSlotAdmin>(
      Location.joinWithSlash(environment.apiRootUrl ||
        '',
        `${this.cardsUri}/${cardUid}/`
        + `${this.bookingsUri}/${this.resourcesUri}/${this.schedulesUri}/`
        + `${bookableScheduleUid}/${this.availabilityUri}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.setAvailability'))
      )
    );
  }

  getBookableSlots(bookableAppointmentId: string, from: string, to: string, languageCode?: string)
    : ObservableResult<AvailableSlot[]> {
    return this.client.get<AvailableSlot[]>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.bookingsUri}/${this.appointmentsUri}/${bookableAppointmentId}/slots?from=${from}&to=${to}`),
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.getSlots'))
      )
    );
  }

  placeBooking(bookableResourceUid: string,
               request: BookingRequest,
               languageCode: string)
    : ObservableResult<BookedAppointment> {
    return this.client.post<BookedAppointment>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.bookingsUri}/${this.resourcesUri}/${bookableResourceUid}/booking`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.placeBooking'))
      )
    );
  }

  getAppointmentResources(bookableAppointmentUid: string, cardUid: string, languageCode?: string)
    : ObservableResult<BookableResource[]> {
    return this.client.get<BookableResource[]>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.cardsUri}/${cardUid}/${this.bookingsUri}/`
        + `${this.appointmentsUri}/${bookableAppointmentUid}/${this.resourcesUri}`),
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.getResources'))
      )
    );
  }

  disassociate(cardUid: string, appointmentUid: string, resourceUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.cardsUri}/${cardUid}/${this.bookingsUri}/`
        + `${this.appointmentsUri}/${appointmentUid}/${this.resourcesUri}/${resourceUid}`),
      null
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.disassociate'))
      )
    );
  }

  getBooking(bookingUid: string, languageCode?: string): ObservableResult<BookedAppointment> {
    return this.client.get<BookedAppointment>(
      Location.joinWithSlash(environment.apiRootUrl || '', `${this.bookingsUri}/${bookingUid}`),
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.getBooking'))
      )
    );
  }

  updateBookableAppointment(appointmentUid: string,
                            request: BookableAppointmentRequest, cardUid: string,
                            languageCode: string)
    : ObservableResult<BookableAppointment> {
    return this.client.put<BookableAppointment>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.cardsUri}/${cardUid}/${this.bookingsUri}/${this.appointmentsUri}/${appointmentUid}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.updateAppointment'))
      )
    );
  }

  getSchedule(cardUid: string, resourceUid: string, languageCode?: string): ObservableResult<ScheduleWithSlots> {
    return this.client.get<ScheduleWithSlots>(
      Location.joinWithSlash(environment.apiRootUrl || '', `${this.cardsUri}/${cardUid}/` +
        `${this.bookingsUri}/${this.resourcesUri}/${resourceUid}/${this.schedulesUri}`),
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.getSchedule'))
      )
    );
  }

  updateScheduleAvailability(request: ScheduleAvailabilityRequest,
                             bookableResourceUid: string,
                             bookableScheduleUid: string,
                             cardUid: string,
                             apiScheduleBlockUid: string,
                             languageCode?: string): ObservableResult<BookableSlotAdmin> {
    return this.client.put<BookableSlotAdmin>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.cardsUri}/${cardUid}/${this.bookingsUri}/` +
        `${this.resourcesUri}/${bookableResourceUid}/`
        + `${this.schedulesUri}/${bookableScheduleUid}/${this.availabilityUri}/${apiScheduleBlockUid}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.updateAvailability'))
      )
    );
  }

  updateSchedule(request: ScheduleRequest,
                 bookableResourceUid: string,
                 cardUid: string,
                 bookableScheduleUid: string,
                 languageCode?: string): ObservableResult<BookableSchedule> {
    return this.client.put<BookableSchedule>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.cardsUri}/${cardUid}/${this.bookingsUri}/` +
        `${this.resourcesUri}/${bookableResourceUid}/`
        + `${this.schedulesUri}/${bookableScheduleUid}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.updateSchedule'))
      )
    );
  }

  deleteScheduleAvailability(bookableResourceUid: string, bookableScheduleUid: string, cardUid: string,
                             apiScheduleBlockUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.cardsUri}/${cardUid}/${this.bookingsUri}/`
        + `${this.resourcesUri}/${bookableResourceUid}/${this.schedulesUri}/${bookableScheduleUid}/` +
        `${this.availabilityUri}/${apiScheduleBlockUid}`),
      null
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.deleteAvailability'))
      )
    );
  }

  updateResourceSlots(cardUid: string,
                      bookableResourceUid: string,
                      bookableScheduleUid: string,
                      request: BookableSlotAdminRequest[],
                      languageCode: string)
    : ObservableResult<BookableSlotAdmin[]> {
    return this.client.put<BookableSlotAdmin[]>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.cardsUri}/${cardUid}/${this.bookingsUri}/` +
        `${this.resourcesUri}/${bookableResourceUid}/`
        + `${this.schedulesUri}/${bookableScheduleUid}/${this.availabilityUri}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.updateSlots'))
      )
    );
  }

  cancelBooking(bookingUid: string, languageCode?: string): ObservableResult<BookedAppointment> {
    return this.client.post<BookedAppointment>(
      Location.joinWithSlash(environment.apiRootUrl || '', `${this.bookingsUri}/${bookingUid}/cancel`),
      null,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.cancelBooking'))
      )
    );
  }

  getBookingICalEvent(bookingUid: string, languageCode?: string): Observable<string> {
    const headers = CalendarHelper.getDefaultCalendarRequestHeaders(languageCode)

    return this.client.post<string>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `${this.bookingsUri}/${this.appointmentsUri}/booked/${bookingUid}/export/ics`),
      {},
      {},
      headers,
      'text'
    )
      .pipe(
        map(response => response.body as string),
        catchError(error => {
          console.error('Failed to download ICS', error);
          throw error;
        })
      );
  }

  cancelBulkBookings(request: BulkCancelBookingsRequest, languageCode?: string): ObservableResult<boolean> {
    return this.client.post<boolean>(
      Location.joinWithSlash(environment.apiRootUrl || '', `${this.bookingsUri}/appointments/cancel`),
      request,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.getBookings'))
      )
    );
  }

  isBookingsDataAvailable(): ObservableResult<boolean> {
    const startDateTime = moment().format('YYYY-MM-DD HH:mm');
    const endDateTime = moment().add(1, 'month').format('YYYY-MM-DD HH:mm');

    const bookingRequest: BookedAppointmentsRequest = {
      dateTimeFrom: startDateTime,
      dateTimeTo: endDateTime
    };

    const bookingsRequest = this.getBookings(bookingRequest);
    const appointmentRequest = this.getBookedAppointments(bookingRequest);

    return forkJoin([bookingsRequest, appointmentRequest]).pipe(
      switchMap(([bookingsResult, appointmentsResult]) => {
        if (bookingsResult.value.length || appointmentsResult.value.length) {
          return ObservableResult.ofSuccess(true);
        } else {
          return ObservableResult.ofSuccess(false);
        }
      }),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.checkDataAvailability'))
      )
    );
  }

  getBookings(request: BookedAppointmentsRequest, languageCode?: string): ObservableResult<BookedAppointment[]> {
    return this.client.post<BookedAppointment[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', `${this.bookingsUri}/appointments`),
      request,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.getBookings'))
      )
    );
  }

  getBookedAppointments(request: BookedAppointmentsRequest, languageCode?: string): ObservableResult<BookedAppointment[]> {
    return this.client.post<BookedAppointment[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', `${this.bookingsUri}/appointments/booked`),
      request,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() =>
        ObservableResult.ofError(this.translationService.getTranslation('bookings.errors.getBookings'))
      )
    );
  }
}
