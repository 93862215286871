/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import {
  ChartEditorContent,
  PIJungianChart,
  PIReport,
  PIReportAllTypes,
  PITeamRoleScoreReport
} from '../../../../shared/models/pi/pi.model';
import { PI_DATA_SERVICE, PIDataService } from '../../../../shared/services/pi/pi-data.service';
import { ChartsHelper } from '../../../../shared/helpers/charts-helper';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectHelper } from '../../../resource/store/editor/content/helpers/redirect.helper';

@Component({
  selector: 'ptl-pi-jungian-chart',
  templateUrl: './pi-jungian-chart.component.html',
  styleUrls: ['./pi-jungian-chart.component.scss'],
})
export class PersonalIndicatorJungianChartComponent implements OnInit {

  @Input() content: ChartEditorContent;

  @Input() showStaticData: boolean;

  @Input() reports: PIReport[];

  @Input() isPIReport: boolean;

  piReport: PIReport[];
  chartData: PITeamRoleScoreReport;
  chart: PIJungianChart;

  completionDate1: Date;
  completionDate2: Date;

  constructor(
    @Inject(PI_DATA_SERVICE) private piDataService: PIDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {

    if (!this.showStaticData && this.content) {
      if (this.isPIReport && this.reports) {
        this.setReportData(this.reports);
        return;
      }
      this.piDataService.getPIReports(this.content.piCardId).subscribe(({ isSuccess, value }) => {
        if (isSuccess && value.reports.length) {
          this.setReportData(value.reports);
        }
      });
    }
  }

  openJungianCard() {
    const tmiTypeString = this.piReport ? this.piReport[0].tmiType.join('').toLowerCase() : '';
    if (!tmiTypeString) {
      return;
    }
    RedirectHelper.redirectByUrl(
      this.ngZone, this.router, this.activatedRoute, `cards/${tmiTypeString}/page/1`, { relativeTo: this.activatedRoute }
    );
  }


  private setReportData(reports: PIReport[]) {
    this.piReport = reports;
    this.formatData(this.piReport[0].allTypes, this.piReport[0].tmiType);
    this.chartData = this.piReport[0].teamRoleScoreReport;
    this.completionDate1 = this.piReport[0].completedOn;

    if (this.piReport.length > 1) {
      this.completionDate2 = this.piReport[1].completedOn;
    }
    this.cd.detectChanges();
  }

  private formatData(types: PIReportAllTypes, tmiType: string[]) {
    const tmiTypeString = tmiType.join('');
    const descriptions = ChartsHelper.getPIChartsDescriptionsByLanguageCode(tmiTypeString, this.content);
    this.chart = {
      description: descriptions.full,
      shortDescription: descriptions.short,
      IE: {
        ...this.getPosition(types.IE, 'IE', tmiType[0], 0),
      },
      SN: {
        ...this.getPosition(types.SN, 'SN', tmiType[1], 1),
      },
      TF: {
        ...this.getPosition(types.TF, 'TF', tmiType[2], 2),
      },
      JP: {
        ...this.getPosition(types.JP, 'JP', tmiType[3], 3),
      },
    };
  }

  private getPosition(currentValue: number, type: string, typeName: string, typeIndex: number) {
    if (this.piReport.length === 1) {
      return this.getSingleItemPositions(currentValue, typeName);
    }
    return this.getMultipleItemPositions(currentValue, type, typeName, typeIndex);
  }

  private getSingleItemPositions(currentValue: number, typeName: string) {
    const isPositivePositionType = this.checkIfPositionTypePositive(typeName);
    const currentPosition = this.getPositionByValue(currentValue);
    return {
      left: isPositivePositionType ? currentPosition + 50 : 50 - currentPosition,
      currentSideLeft: false,
      width: 0,
    };
  }

  private getMultipleItemPositions(currentValue: number, type: string, typeName: string, typeIndex: number) {
    const isCurrentPositionPositive = this.checkIfPositionTypePositive(typeName);
    const isPreviousPositionPositive = this.checkIfPositionTypePositive(this.piReport[1].tmiType[typeIndex]);
    if (isCurrentPositionPositive === isPreviousPositionPositive) {
      return this.getOneSidePositions(currentValue, type, isCurrentPositionPositive);
    } else {
      return this.getTwoSidePositions(currentValue, type, isCurrentPositionPositive, isPreviousPositionPositive);
    }
  }

  private getOneSidePositions(currentValue: number, type: string, isPositive: boolean) {
    let currentPosition = this.getPositionByValue(currentValue);
    const previousValue = this.piReport[1].allTypes[type];
    const previousPosition = this.getPositionByValue(previousValue);
    let currentSideLeft;
    const width = this.getWidthByPositions(previousPosition, currentPosition);
    if (isPositive) {
      if (currentPosition > previousPosition) {
        currentPosition = previousPosition;
        currentSideLeft = false;
      } else {
        currentSideLeft = true;
      }
    } else {
      if (currentPosition < previousPosition) {
        currentPosition = previousPosition;
        currentSideLeft = false;
      } else {
        currentSideLeft = true;
      }
    }
    return {
      left: (isPositive ? currentPosition + 50 : 50 - currentPosition),
      currentSideLeft: currentSideLeft,
      width: width,
    };
  }

  private getTwoSidePositions(currentValue: number, type: string, isCurrentPositive: boolean, isPreviousPositive: boolean) {
    let currentPosition = this.getPositionByValue(currentValue);
    currentPosition = isCurrentPositive ? currentPosition + 50 : 50 - currentPosition;
    const previousValue = this.piReport[1].allTypes[type];
    let previousPosition = this.getPositionByValue(previousValue);
    previousPosition = isPreviousPositive ? 50 + previousPosition : 50 - previousPosition;
    const width = this.getWidthByPositions(previousPosition, currentPosition);
    let left;
    let currentSideLeft;
    if (isCurrentPositive && !isPreviousPositive) {
      left = previousPosition;
      currentSideLeft = false;
    } else {
      left = currentPosition;
      currentSideLeft = type;
    }
    return {
      left: left,
      currentSideLeft: currentSideLeft,
      width: width,
    };
  }

  private checkIfPositionTypePositive(typeName: string): boolean {
    const positiveTypeNames = ['I', 'S', 'T', 'P'];
    return positiveTypeNames.includes(typeName);
  }

  private getPositionByValue(value: number) {
    const positiveValue = Math.abs(value);
    return (positiveValue * 100) / 2;
  }

  private getWidthByPositions(previousPosition: number, currentPosition: number) {
    return Math.abs(previousPosition - currentPosition);
  }
}
