/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OnBoardingDataRequest } from '../../../../user-auth/models';
import { Subscription } from 'rxjs';
import { DEFAULT_URL_VALIDATION_PATTERN } from '../../../constants/constants';

@Component({
  selector: 'ptl-data-collection-dialog',
  templateUrl: './data-collection-dialog.component.html',
  styleUrls: ['./data-collection-dialog.component.scss'],
})
export class DataCollectionDialogComponent implements OnDestroy {
  isMainStep = true;
  affiliation: 'SCHOOL' | 'STUDENT' | 'GRADUATE' | 'INDEPENDENT' = 'SCHOOL';
  isLocationStep = false;
  onBoardDataForm: FormGroup;


  countryResults: string[] = [];

  private formSubscription: Subscription;

  private readonly countryList = [
    'United States',
    'United Kingdom',
    'China',
    'Canada',
    'United Arab Emirates',
    'Australia',
    'Andorra',
    'Afghanistan',
    'Antigua and Barbuda',
    'Anguilla',
    'Albania',
    'Armenia',
    'Angola',
    'Antarctica',
    'Argentina',
    'American Samoa',
    'Austria',
    'Aruba',
    'Azerbaijan',
    'Bosnia and Herzegovina',
    'Barbados',
    'Bangladesh',
    'Belgium',
    'Burkina Faso',
    'Bulgaria',
    'Bahrain',
    'Burundi',
    'Benin',
    'Saint Barthelemy',
    'Bermuda',
    'Brunei',
    'Bolivia',
    'Brazil',
    'Bahamas, The',
    'Bhutan',
    'Bouvet Island',
    'Botswana',
    'Belarus',
    'Belize',
    'Cocos (Keeling) Islands',
    'Congo, Democratic Republic of the',
    'Central African Republic',
    'Congo, Republic of the',
    'Switzerland',
    'Cote d\'Ivoire',
    'Cook Islands',
    'Chile',
    'Cameroon',
    'Colombia',
    'Costa Rica',
    'Cuba',
    'Cape Verde',
    'Curacao',
    'Christmas Island',
    'Cyprus',
    'Czech Republic',
    'Germany',
    'Djibouti',
    'Denmark',
    'Dominica',
    'Dominican Republic',
    'Algeria',
    'Ecuador',
    'Estonia',
    'Egypt',
    'Western Sahara',
    'Eritrea',
    'Spain',
    'Ethiopia',
    'Finland',
    'Fiji',
    'Falkland Islands (Islas Malvinas)',
    'Micronesia, Federated States of',
    'Faroe Islands',
    'France',
    'France, Metropolitan',
    'Gabon',
    'Grenada',
    'Georgia',
    'French Guiana',
    'Guernsey',
    'Ghana',
    'Gibraltar',
    'Greenland',
    'Gambia, The',
    'Guinea',
    'Guadeloupe',
    'Equatorial Guinea',
    'Greece',
    'South Georgia and the Islands',
    'Guatemala',
    'Guam',
    'Guinea-Bissau',
    'Guyana',
    'Hong Kong (SAR China)',
    'Heard Island and McDonald Islands',
    'Honduras',
    'Croatia',
    'Haiti',
    'Hungary',
    'Indonesia',
    'Ireland',
    'Israel',
    'Isle of Man',
    'India',
    'British Indian Ocean Territory',
    'Iraq',
    'Iran',
    'Iceland',
    'Italy',
    'Jersey',
    'Jamaica',
    'Jordan',
    'Japan',
    'Kenya',
    'Kyrgyzstan',
    'Cambodia',
    'Kiribati',
    'Comoros',
    'Saint Kitts and Nevis',
    'Korea, South',
    'Kuwait',
    'Cayman Islands',
    'Kazakhstan',
    'Laos',
    'Lebanon',
    'Saint Lucia',
    'Liechtenstein',
    'Sri Lanka',
    'Liberia',
    'Lesotho',
    'Lithuania',
    'Luxembourg',
    'Latvia',
    'Libya',
    'Morocco',
    'Monaco',
    'Moldova',
    'Montenegro',
    'Saint Martin',
    'Madagascar',
    'Marshall Islands',
    'Macedonia',
    'Mali',
    'Burma',
    'Mongolia',
    'Macau (SAR China)',
    'Northern Mariana Islands',
    'Martinique',
    'Mauritania',
    'Montserrat',
    'Malta',
    'Mauritius',
    'Maldives',
    'Malawi',
    'Mexico',
    'Malaysia',
    'Mozambique',
    'Namibia',
    'New Caledonia',
    'Niger',
    'Norfolk Island',
    'Nigeria',
    'Nicaragua',
    'Netherlands',
    'Norway',
    'Nepal',
    'Nauru',
    'Niue',
    'New Zealand',
    'Oman',
    'Panama',
    'Peru',
    'French Polynesia',
    'Papua New Guinea',
    'Philippines',
    'Pakistan',
    'Poland',
    'Saint Pierre and Miquelon',
    'Pitcairn Islands',
    'Puerto Rico',
    'Gaza Strip',
    'West Bank',
    'Portugal',
    'Palau',
    'Paraguay',
    'Qatar',
    'Reunion',
    'Romania',
    'Serbia',
    'Russia',
    'Rwanda',
    'Saudi Arabia',
    'Solomon Islands',
    'Seychelles',
    'Sudan',
    'Sweden',
    'Singapore',
    'Saint Helena, Ascension, and Tristan da Cunha',
    'Slovenia',
    'Svalbard',
    'Slovakia',
    'Sierra Leone',
    'San Marino',
    'Senegal',
    'Somalia',
    'Suriname',
    'South Sudan',
    'Sao Tome and Principe',
    'El Salvador',
    'Sint Maarten',
    'Syria',
    'Swaziland',
    'Turks and Caicos Islands',
    'Chad',
    'French Southern and Antarctic Lands',
    'Togo',
    'Thailand',
    'Tajikistan',
    'Tokelau',
    'Timor-Leste',
    'Turkmenistan',
    'Tunisia',
    'Tonga',
    'Turkey',
    'Trinidad and Tobago',
    'Tuvalu',
    'Taiwan, Province of China',
    'Tanzania',
    'Ukraine',
    'Uganda',
    'United States Minor Outlying Islands',
    'Uruguay',
    'Uzbekistan',
    'Holy See (Vatican City)',
    'Saint Vincent and the Grenadines',
    'Venezuela',
    'British Virgin Islands',
    'Virgin Islands',
    'Vietnam',
    'Vanuatu',
    'Wallis and Futuna',
    'Samoa',
    'Kosovo',
    'Yemen',
    'Mayotte',
    'South Africa',
    'Zambia',
    'Zimbabwe',
  ];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DataCollectionDialogComponent>) {
    this.updateFormGroup(this.affiliation);
  }


  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  onSubmit() {
    if (this.onBoardDataForm.valid) {
      const request: OnBoardingDataRequest = {};

      request.userCity = this.onBoardDataForm.get('yourCity').value;
      request.userCountry = this.onBoardDataForm.get('yourCountry').value;

      switch (this.affiliation) {
        case 'SCHOOL':
          request.typeOfUser = 'college_student';
          break;
        case 'STUDENT':
          request.typeOfUser = 'university_student';
          break;
        case 'GRADUATE':
          request.typeOfUser = 'graduate';
          break;
        case 'INDEPENDENT':
          request.typeOfUser = 'independent';
          break;
      }

      if (this.affiliation === 'SCHOOL') {
        request.institutionName = this.onBoardDataForm.get('schoolName').value;
        request.institutionCity = this.onBoardDataForm.get('schoolCity').value;
      } else if (this.affiliation === 'STUDENT' || this.affiliation === 'GRADUATE') {
        request.institutionName = this.onBoardDataForm.get('institutionName').value;

        if (this.onBoardDataForm.get('institutionWebsite').value) {
          request.institutionSite = this.onBoardDataForm.get('institutionWebsite').value;
        }

        if (this.affiliation === 'STUDENT') {
          request.startYear = this.onBoardDataForm.get('startYear').value;
          request.completionYear = this.onBoardDataForm.get('expectedGraduationYear').value;

          if (this.onBoardDataForm.get('personalInstitutionEmail').value) {
            request.institutionPersonalEmail = this.onBoardDataForm.get('personalInstitutionEmail').value;
          }
        } else {
          request.degree = this.onBoardDataForm.get('degree').value;
          request.degreeYear = this.onBoardDataForm.get('graduationYear').value;
        }
      }

      this.dialogRef.close(request);
    }
  }

  onNext(locationStep = false) {
    if (this.isMainStep) {
      this.updateFormGroup(this.affiliation);
    }

    this.isMainStep = false;
    this.isLocationStep = locationStep;

    if (this.isLocationStep) {
      for (const controlName in this.onBoardDataForm.controls) {
        if (controlName !== 'yourCity' &&
            controlName !== 'yourCountry' &&
            this.onBoardDataForm.controls[controlName].status === 'INVALID') {
          this.isLocationStep = false;
          this.onBoardDataForm.markAllAsTouched();
          break;
        } else {
          this.onBoardDataForm.controls[controlName].markAsUntouched();
        }
      }
    }
  }

  compare(controlName1: string, controlName2: string) {
    return (formGroup: FormGroup) => {
      const control1 = formGroup.controls[controlName1];
      const control2 = formGroup.controls[controlName2];

      if (control2.errors && !control2.errors.smaller) {
        return;
      }

      if (control1.value > control2.value) {
        control2.setErrors({ smaller: true });
      } else {
        control2.setErrors(undefined);
      }
    };
  }

  private setFormSubscription() {
    this.countryResults = [ ...this.countryList ];
    this.countryResults.sort();

    this.formSubscription = this.onBoardDataForm?.valueChanges.subscribe(data => {
      if (data) {
        const queryString = data.yourCountry ? data.yourCountry.toLowerCase() : '';
        this.countryResults = this.countryList.filter(country => country.toLowerCase().startsWith(queryString));
        this.countryResults.sort();
      }
    });
  }

  private updateFormGroup(affiliation: 'SCHOOL' | 'STUDENT' | 'GRADUATE' | 'INDEPENDENT') {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
      this.formSubscription = undefined;
    }

    const currentYear = new Date().getFullYear();

    switch (affiliation) {
      case 'SCHOOL':
        this.onBoardDataForm = this.fb.group({
          schoolName: ['', Validators.required],
          schoolCity: ['', Validators.required],
          yourCity: ['', Validators.required],
          yourCountry: ['', Validators.required],
        });
        break;
      case 'STUDENT':
        this.onBoardDataForm = this.fb.group({
          institutionName: ['', Validators.required],
          institutionWebsite: ['', Validators.pattern(DEFAULT_URL_VALIDATION_PATTERN)],
          startYear: [currentYear, [Validators.required, Validators.min(1990), Validators.max(currentYear)]],
          expectedGraduationYear: [currentYear, [Validators.required, Validators.min(currentYear), Validators.max(2100)]],
          personalInstitutionEmail: ['', Validators.email],
          yourCity: ['', Validators.required],
          yourCountry: ['', Validators.required],
        }, {
          validator: this.compare('startYear', 'expectedGraduationYear'),
        });
        break;
      case 'GRADUATE':
        this.onBoardDataForm = this.fb.group({
          institutionName: ['', Validators.required],
          institutionWebsite: ['', Validators.pattern(DEFAULT_URL_VALIDATION_PATTERN)],
          degree: ['', Validators.required],
          graduationYear: [currentYear, [Validators.required, Validators.min(1950), Validators.max(currentYear)]],
          yourCity: ['', Validators.required],
          yourCountry: ['', Validators.required],
        });
        break;
      case 'INDEPENDENT':
        this.onBoardDataForm = this.fb.group({
          yourCity: ['', Validators.required],
          yourCountry: ['', Validators.required],
        });
        break;
    }

    this.setFormSubscription();
  }
}
