/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Inject, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { FileUploadHelper } from '../../../shared/helpers/file-upload-helper';
import { ImageUploadNameResolver } from '../../../shared/helpers/image-upload-name-resolver';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import {
  FileUploadService,
  RESOURCES_FILE_UPLOAD_DATA_SERVICE
} from '../../../shared/services/file-upload/file-upload.service';
import { PageTitleService } from '../../../shared/services/page-title/page-title.service';
import { LoadableState, dataLoadedState, defaultLoadableState, errorState, loadingState } from '../../../shared/store';
import { FOLIO_DATA_SERVICE, FolioDataService } from '../services/data.service';
import * as FolioActions from './folio.action';
import { FolioModel, FolioStateModel, FolioUserBio, INITIAL_FOLIO_STATE } from './folio.state.model';
import { MomentUpdateViewState } from './moment/moment-update/moment-update.state';
import { MomentViewAllState } from './moment/moment-view-all/moment-view-all.state';

@State<FolioStateModel>({
  name: 'folio',
  defaults: INITIAL_FOLIO_STATE,
  children: [MomentViewAllState, MomentUpdateViewState],
})
@Injectable()
export class FolioState {

  @Selector()
  static folioState({ folio }: FolioStateModel): LoadableState<FolioModel> {
    return folio;
  }

  @Selector()
  static folio({ folio }: FolioStateModel): FolioModel {
    return folio.data;
  }

  @Selector()
  static folioBio({ folio }: FolioStateModel): FolioUserBio {
    return folio.data.bio;
  }

  @Selector()
  static displayHiddenItems({ displayHiddenItems }: FolioStateModel) {
    return displayHiddenItems;
  }

  @Selector()
  static folioMomentsUpdated({ folioMomentsUpdate }: FolioStateModel) {
    return folioMomentsUpdate;
  }

  constructor(
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private router: Router,
    @Inject(FOLIO_DATA_SERVICE) private folioDataService: FolioDataService,
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    private titleService: PageTitleService
  ) {
  }

  @Action(FolioActions.LoadUserFolio)
  loadUserFolio(
    { patchState }: StateContext<FolioStateModel>
  ) {

    patchState({
      folio: loadingState(),
    });

    return this.folioDataService.getUserFolio().pipe(
      tap(({ isSuccess, value, error }) => {
        if (isSuccess) {
          patchState({
            folio: dataLoadedState(value),
          });
        } else {
          patchState({
            folio: errorState(error),
          });
        }
      })
    );
  }

  @Action(FolioActions.ClearUserFolio)
  clearUserFolio(
    { patchState }: StateContext<FolioStateModel>
  ) {
    patchState({
      folio: defaultLoadableState(),
    });
  }

  @Action(FolioActions.LoadPublicUserFolio)
  loadPublicUserFolio(
    { patchState }: StateContext<FolioStateModel>,
    { publicId }: FolioActions.LoadPublicUserFolio
  ) {

    patchState({
      folio: loadingState(),
    });

    return this.folioDataService.getPublicUserFolio(publicId).pipe(
      tap(({ isSuccess, value, error }) => {
        if (isSuccess) {
          patchState({
            folio: dataLoadedState(value),
          });
          this.titleService.setTitle(`${value.bio.firstName} ${value.bio.lastName}`);
        } else {
          patchState({
            folio: errorState(error),
          });
        }
      })
    );
  }

  @Action(FolioActions.SwitchDisplayHiddenElements)
  switchApplicationMode(
    { patchState }: StateContext<FolioStateModel>,
    { mode }: FolioActions.SwitchDisplayHiddenElements
  ) {
    patchState({
      displayHiddenItems: mode
    });
  }

  @Action(FolioActions.FolioMomentsUpdate)
  updateFolioMoments(
    { getState, patchState }: StateContext<FolioStateModel>
  ) {
    const state = getState();
    patchState({
      folioMomentsUpdate: !state.folioMomentsUpdate
    });
  }

  @Action(FolioActions.UpdateUserFolioStatus)
  updateUserFolioStatus(
    { patchState, getState }: StateContext<FolioStateModel>,
    { isLive }: FolioActions.UpdateUserFolioStatus
  ) {
    return this.folioDataService.updateUserFolioShowcase({ isLive: isLive }).subscribe();
  }

  @Action(FolioActions.UpdateUserFolioCover)
  updateUserFolioCover(
    { patchState, getState }: StateContext<FolioStateModel>,
    { imageFile }: FolioActions.UpdateUserFolioCover
  ) {
    const request = {
      imageName: ImageUploadNameResolver.resolveImageName(imageFile.name),
      contentLength: imageFile.size
    }

    return this.folioDataService.updateUserFolioCoverImage(request).subscribe(({ isSuccess, value }) => {
      if (isSuccess && value) {
        if (imageFile.size > 0) {
          const filePath = FileUploadHelper.filePath(value.uploadUrl);
          this.fileUploadService.uploadFile(filePath, imageFile).subscribe();
        }
      }
    })
  }

  @Action(FolioActions.DeleteUserFolioCover)
  deleteUserFolioCover() {
    return this.folioDataService.deleteUserFolioCoverImage();
  }

  @Action(FolioActions.ReorderFolioSection)
  reorderFolioSection(
    { patchState, getState }: StateContext<FolioStateModel>,
    { sections }: FolioActions.ReorderFolioSection
  ) {
    return this.folioDataService.reorderFolioSections(sections).subscribe();
  }


  @Action(FolioActions.UpdateUserFolioLink)
  updateUserFolioLink(
    { patchState, getState }: StateContext<FolioStateModel>,
    { link }: FolioActions.UpdateUserFolioLink
  ) {
    return this.folioDataService.updateUserFolioShowcase({ publicId: link }).subscribe();
  }


  @Action(FolioActions.UpdateUserFolioIntroduction)
  updateUserFolioIntroduction(
    { patchState, getState }: StateContext<FolioStateModel>,
    { shortDescription }: FolioActions.UpdateUserFolioIntroduction
  ) {
    return this.folioDataService.updateUserFolioShowcase({ shortDescription: shortDescription }).subscribe();
  }

  @Action(FolioActions.UpdateFolioSection)
  updateFolioSection(
    { patchState, getState }: StateContext<FolioStateModel>,
    { sectionUid, request }: FolioActions.UpdateFolioSection
  ) {
    return this.folioDataService.updateFolioSection(sectionUid, request).pipe(
      tap(({ error }) => {
        if (error) {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      })
    );
  }

  @Action(FolioActions.EnableFolioSectionItemVisibility)
  enableFolioSectionItemVisibility(
    { patchState, getState }: StateContext<FolioStateModel>,
    { sectionUid, request }: FolioActions.EnableFolioSectionItemVisibility
  ) {
    return this.folioDataService.enableFolioSectionItemVisibility(sectionUid, request).pipe(
      tap(({ error }) => {
        if (error) {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      })
    );
  }

  @Action(FolioActions.DisableFolioSectionItemVisibility)
  disableFolioSectionItemVisibility(
    { patchState, getState }: StateContext<FolioStateModel>,
    { sectionUid, request }: FolioActions.DisableFolioSectionItemVisibility
  ) {
    return this.folioDataService.disableFolioSectionItemVisibility(sectionUid, request).pipe(
      tap(({ error }) => {
        if (error) {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      })
    );
  }
}
