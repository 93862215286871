<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div *ngIf="events.length > 0 else emptyList" class="upcoming__event-list-container">

  <div *ngIf="eventsOngoing.length > 0" class="upcoming__ongoing-container section">
    <div class="section-description large">
      {{ 'translations.upcomingEventsV2.ongoing' | transloco }}
    </div>
    <ptl-upcoming-event *ngFor="let event of eventsOngoing; let i = index;"
      [event]="event"
      [index]="i">
    </ptl-upcoming-event>
  </div>

  <div *ngIf="eventsIn7Days.length > 0" class="upcoming__7-days-container section">
    <div class="section-description large">
      {{ 'translations.upcomingEventsV2.next7Days' | transloco }}
    </div>
    <ptl-upcoming-event *ngFor="let event of eventsIn7Days; let i = index;"
      [event]="event"
      [index]="i + eventsOngoing.length">
    </ptl-upcoming-event>
  </div>

  <div *ngIf="eventsIn30Days.length > 0" class="upcoming__30-days-container section">
    <div class="section-description large">
      {{ 'translations.upcomingEventsV2.next30Days' | transloco }}
    </div>
    <ptl-upcoming-event *ngFor="let event of eventsIn30Days; let i = index;"
      [event]="event"
      [index]="i + eventsOngoing.length + eventsIn7Days.length">
    </ptl-upcoming-event>
  </div>

  <div *ngIf="eventsOther.length > 0" class="upcoming__other-days-container section">
    <div class="section-description large">
      {{ 'translations.upcomingEventsV2.moreThan30Days' | transloco }}
    </div>
    <ptl-upcoming-event *ngFor="let event of eventsOther; let i = index;"
      [event]="event"
      [index]="i + eventsOngoing.length + eventsIn7Days.length + eventsIn30Days.length">
    </ptl-upcoming-event>
  </div>

  <div class="upcoming-events-section-loader" *ngIf="showLoader">
    <div class="upcoming-events-section-loader-inner">
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  </div>

</div>

<ng-template #emptyList>
  <div class="empty-list-container">
    <h3 class="large bold">{{ 'translations.upcomingEventsV2.noUpcomingEventsFound' | transloco }}</h3>
  </div>
</ng-template>
