/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EditorContentUpdateEvent, MediumEditorData } from '../../../../models';
import { LanguageValue, Organization } from '../../../../../shared/models';
import { LanguageCodeHelper } from '../../../../../shared/helpers/language-code-helper';

@Component({
  selector: 'ptl-quiz-instructions',
  templateUrl: './quiz-instructions.component.html',
  styleUrls: ['./quiz-instructions.component.scss', './quiz-instructions-new.component.scss'],
})
export class QuizInstructionsComponent implements OnChanges {

  /** Receives instructions to edit */
  @Input() instructions: LanguageValue[];

  /** Emits the medium-editor content change event. */
  @Output() mediumEditorChanged = new EventEmitter<LanguageValue>();

  @Input() languageCode: string;

  @Input() organization: Organization;

  @Input() newVersionEnabled: boolean;

  expanded = false;

  content: MediumEditorData = { type: 'PARAGRAPH', content: '' };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.instructions && changes.instructions.currentValue) {
      this.content = {
        type: 'PARAGRAPH',
        content: LanguageCodeHelper.getDataByUserLanguageCode(
          changes.instructions.currentValue,
          this.organization,
          this.languageCode).value,
      };
    }
  }

  onMediumEditorUpdate(event: EditorContentUpdateEvent) {
    this.content = event.update.newContent as MediumEditorData;
    this.mediumEditorChanged.emit({
      value: this.content.content,
      languageCode: this.languageCode,
    });
  }

}
