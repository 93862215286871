<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="card-footer" [class.is_vertical]="orientation === 'VERTICAL'">
  <div class="card-footer-container">
    <div class="card-footer-actions">

      <ptl-like-info
        class="playlist-card__like-info"
        [likeDetails]="likeDetails"
        [isVertical]="orientation === 'VERTICAL'"
        [infoType]="infoType !== 'PLAYLIST' ? 'CARD' : 'PLAYLIST'"
        [title]="title"
        [uid]="uid"
        [editMode]="editMode">
      </ptl-like-info>

      <ng-content></ng-content>

      <button mat-icon-button class="mat-icon-button duplicate-icon"
              *ngIf="(canClone && !isIframeMode) || ((canClone && isIframeMode && isDevelopmentDomains))"
              [title]="'translations.clone.label.action' | transloco"
              [class.is_hidden]="syndicated && infoType === 'CARD'"
              [attr.aria-label]="('translations.clone.label.action' | transloco) + ' ' + title"
              (click)="onCloneClick(); $event.stopPropagation()">
        <mat-icon aria-hidden="true">content_copy</mat-icon>
      </button>

      <button mat-icon-button class="mat-icon-button edit-icon"
              *ngIf="(canEdit && !isIframeMode) || (canEdit && isIframeMode && isDevelopmentDomains)"
              [title]="'translations.global.button.edit' | transloco"
              [class.is_hidden]="syndicated && infoType === 'CARD'"
              [attr.aria-label]="('translations.global.button.edit' | transloco) + ' ' + title"
              (click)="onEditClick(uid); $event.stopPropagation()">
        <mat-icon aria-hidden="true">edit</mat-icon>
      </button>

      <button mat-icon-button class="mat-icon-button members-icon"
              *ngIf="(canViewMembers && !isIframeMode) || (canViewMembers && isIframeMode && isDevelopmentDomains)"
              [title]="'translations.members.button.members' | transloco"
              [class.is_hidden]="syndicated && infoType === 'CARD'"
              [attr.aria-label]="('translations.members.button.members' | transloco) + ' ' + title"
              (click)="onMembersClick(uid); $event.stopPropagation()">
        <mat-icon aria-hidden="true">group</mat-icon>
      </button>
    </div>

    <div class="card-footer-info">
      <div class="card-footer-info-status">
        <span *ngIf="status && status !== 'PUBLISHED'" class="status"
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.cardStatusUnpublished' | transloco: {cardName: title}">
          {{ 'translations.cardStatusUnpublished' | transloco }}
        </span>
        <span *ngIf="syndicated && isAdmin" class="status syndicated"
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.cardStatusSyndicated' | transloco: {cardName: title}">
          {{ 'translations.cardStatusSyndicated' | transloco }}
        </span>
      </div>
      <div class="card-footer-info-type" *ngIf="displayedType" [ngClass]="{ 'is-group' : displayedType === 'GROUP' }">
        <mat-icon aria-hidden="true" *ngIf="displayedType === 'RESOURCE'" title="Card">insert_drive_file</mat-icon>
        <mat-icon aria-hidden="true" *ngIf="displayedType === 'ASSESSMENT'" title="Feedback card">rule</mat-icon>
        <mat-icon aria-hidden="true" *ngIf="displayedType === 'PLAYLIST'" title="Playlist">reorder</mat-icon>
        <mat-icon aria-hidden="true" *ngIf="displayedType === 'WEBLINK'" title="Weblink">link</mat-icon>
        <mat-icon aria-hidden="true" *ngIf="displayedType === 'EVENT'" title="Event card">date_range</mat-icon>
        <mat-icon aria-hidden="true" *ngIf="displayedType === 'GROUP'" title="Card group">insert_drive_file</mat-icon>
      </div>
    </div>
  </div>
</div>
