import {Component, ElementRef, EventEmitter, Inject, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ImageCropDialogComponent} from '../image-crop-dialog/image-crop-dialog.component';
import {LanguageCodeHelper} from '../../helpers/language-code-helper';
import {
  AdvancedImageCropDialogComponent
} from '@app/app/shared/components/advanced-image-crop-dialog/advanced-image-crop-dialog.component';
import {ComponentType} from '@angular/cdk/overlay';

@Component({
  selector: 'ptl-cover-image-upload-dialog',
  templateUrl: './cover-image-upload-dialog.component.html',
  styleUrls: ['./cover-image-upload-dialog.component.scss']
})
export class CoverImageUploadDialogComponent {

  @Output() fileUploaded = new EventEmitter<void>();

  @ViewChild('uploadFolioInput') uploadFolioInput?: ElementRef;

  filesHovered = false;

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<CoverImageUploadDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                cropperShape: string;
                advancedImageCrop: boolean;
                resizeToHeight: number;
                aspectRatio: number;
              }) {
  }

  uploadUserImage(eventData: Event) {
    const selectedImage: File = (eventData.target as HTMLInputElement).files[0];
    this.handleImageUpload(selectedImage, eventData);
  }

  triggerUpload() {
    this.uploadFolioInput?.nativeElement.click();
  }

  onPhotoSelected(data: File) {
    this.handleImageUpload(data);
  }

  onFilesDropped(fileList: FileList): void {
    if (fileList.length === 1) {
      this.handleImageUpload(fileList.item(0));
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  private handleImageUpload(selectedImage: File, eventData?: Event) {
    if (!this.data?.advancedImageCrop) {
      this.openImageCropDialog<ImageCropDialogComponent>(ImageCropDialogComponent, selectedImage, eventData);
    } else {
      this.openImageCropDialog<AdvancedImageCropDialogComponent>(AdvancedImageCropDialogComponent, selectedImage, eventData);
    }
  }

  private openImageCropDialog<T>(dialogComponent: ComponentType<T>, selectedImage: File, eventData?: Event) {
    if (selectedImage) {
      const cropDialogRef = this.dialog.open(dialogComponent, {
        width: '90vw',
        minWidth: '15.625rem',
        maxWidth: '37.5rem',
        maxHeight: '80vh',
        position: {
          top: '10vh',
        },
        direction: LanguageCodeHelper.getBodyLanguageDir(),
        panelClass: 'ptl-mat-dialog',
        backdropClass: 'dialog-backdrop',
        data: {
          imageFile: selectedImage,
          cropperShape: this.data?.cropperShape ?? 'rectangle',
          resizeToHeight: this.data?.resizeToHeight,
          aspectRatio: this.data?.aspectRatio,
        }
      });

      const dialogSubscription = cropDialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.dialogRef.close(data);
        }
        if (eventData) {
          (eventData.target as HTMLInputElement).value = '';
        }
        dialogSubscription.unsubscribe();
      });
    }
  }
}
