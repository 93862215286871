/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import { PIQuestionData, PIReport, PIReportResponse, PISummary } from '../../models/pi/pi.model';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const PI_DATA_SERVICE =
  new InjectionToken<PIDataService>('[personality indicator] PIDataService');

export interface PIDataService {

  loadPISummary(resourceUid: string): ObservableResult<PISummary>;

  getPIQuestion(resourceUid: string, version: number): ObservableResult<PIQuestionData>;

  addPIQuestionAnswer(resourceUid: string, version: number, request: PIQuestionData): ObservableResult<PIQuestionData>;

  getPIReports(resourceUid: string): ObservableResult<PIReportResponse>;

  getPIReportByVersion(resourceUid: string, version: number): ObservableResult<PIReport>;

}
