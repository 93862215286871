import { AfterViewInit, Component, ElementRef, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Moment } from '../../../models';
import { CreateMoment } from '../../../../../app-frame/store/app-frame.actions';
import { Store } from '@ngxs/store';
import { MediaUploadData, MediumEditorData } from '../../../../../shared/models';
import { MomentCommonHelpers } from '../../services/common.helpers';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../../../shared/helpers/dialog/dialog.service';
import { TranslationService } from '../../../../../shared/services/translation/translation.service';

@Component({
  selector: 'ptl-new-moment-dialog',
  templateUrl: './new-moment-dialog.component.html',
  styleUrls: ['./new-moment-dialog.component.scss'],
})
export class NewMomentDialogComponent implements AfterViewInit, OnDestroy {
  moment: Moment = {
    title: '',
    tags: [],
    content: [],
    addedToFolio: false
  };

  loading = false;
  private dialogRefBackdropSubscription: Subscription;
  private dialogRefSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      addToFolio: boolean;
    },
    private dialogRef: MatDialogRef<NewMomentDialogComponent>,
    private dialogService: DialogService,
    private store: Store,
    private translationService: TranslationService,
    private componentContainer: ElementRef
  ) {
  }

  ngAfterViewInit(): void {

    this.focusEditor();

    this.dialogRefBackdropSubscription = this.dialogRef.backdropClick().subscribe(() => {
      if (this.checkIfWasChanged()) {
        this.showConfirmMessage();
      } else {
        this.dialogRef.close();
      }
    });

    this.dialogRefSubscription = this.dialogRef.keydownEvents().subscribe((event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        if (this.checkIfWasChanged()) {
          this.showConfirmMessage();
        } else {
          this.dialogRef.close();
        }
      }
    });
  }

  onChange(momentChange: Moment): void {
    this.moment = momentChange;
  }

  onMomentSave() {
    this.loading = true;

    if (this.data?.addToFolio) {
      this.moment.addedToFolio = true;
    }

    this.store.dispatch(new CreateMoment(this.moment)).toPromise().then(() => {
      this.loading = false;

      setTimeout(() => {
        this.dialogRef.close(true);
      }, 0)
    });
  }

  ngOnDestroy() {
    this.dialogRefBackdropSubscription?.unsubscribe();
    this.dialogRefSubscription?.unsubscribe();
  }

  private focusEditor() {
    setTimeout(() => {
      let editorElement = this.componentContainer.nativeElement.querySelector('.medium-editor-element') as HTMLElement;
      if (!editorElement) {
        editorElement = this.componentContainer.nativeElement.querySelector('.mce-content-body') as HTMLElement;
      }
      editorElement?.focus();
    }, 300);
  }

  private showConfirmMessage() {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.close'),
      this.translationService
    ).then(confirmed => {
      if (confirmed) {
        this.dialogRef.close();
      }
    });
  }

  private checkIfWasChanged(): boolean {
    const titleChanged = !!this.moment.title.trim();
    const tagsChanged = !!this.moment.tags.length;
    const contentChanged = this.checkIfContentWasChanged();
    return titleChanged || contentChanged || tagsChanged;
  }

  private checkIfContentWasChanged(): boolean {
    if (!this.moment.content.length) {
      return false;
    } else {
      let paragraphEmpty = true;
      let mediaEmpty = true;
      for (const contentItem of this.moment.content) {
        if (contentItem.type === 'PARAGRAPH') {
          const htmlContent = (contentItem as MediumEditorData).content;
          const trimmedString = MomentCommonHelpers.textExtractedFrom(htmlContent);
          paragraphEmpty = trimmedString.trim() === '';
        }
        if (contentItem.type === 'MEDIA_UPLOAD') {
          const file = (contentItem as MediaUploadData).file;
          if (file) {
            mediaEmpty = false;
          } else {
            mediaEmpty = true;
          }
        }
      }
      if (paragraphEmpty && mediaEmpty) {
        return false;
      } else {
        return true;
      }
    }
  }

}
