/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';

@Component({
  selector: 'ptl-skeleton-event-item',
  templateUrl: './skeleton-event-item.component.html',
  styleUrls: ['./skeleton-event-item.component.scss'],
})
export class SkeletonEventItemComponent {
}
