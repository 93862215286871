<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->


<div class="group-filter-container">
  <mat-card *ngIf="expanded" class="group-filter-settings">
    <div class="group-filter-settings__header">
      <span class="small">{{ 'translations.reviews.filter.group' | transloco }}</span>
      <button mat-icon-button [matMenuTriggerFor]="contextMenu" class="isBtn">
        <span>
          {{groupIs ? ('translations.reviews.filter.is' | transloco) : ('translations.reviews.filter.isNot' | transloco) }}
        </span>
        <mat-icon aria-hidden="true" class="icon">expand_more</mat-icon>
      </button>
      <span class="group-filter-delete" (click)="deleteFilter()" (keydown.enter)="deleteFilter()">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
        </svg>
      </span>
    </div>
    <div class="group-filter-settings__content">
      <ptl-group-search-autocomplete
        #groupSearchAutocomplete
        [loadingGroups]="loadingGroups"
        [placeholder]="('translations.reviews.label.searchForGroups' | transloco)"
        [groups]="foundGroups"
        (searchLoadedMore)="onGroupScroll()"
        (searchInputChanged)="onSearchInputChange($event)"
        (groupSelected)="onGroupSelected($event)"
      ></ptl-group-search-autocomplete>
      <div class="chips-list groups-list">
        <div class="chip-item chip-interactive"
             *ngFor="let group of activeGroups; let i = index" >
          <span class="chip-text" [matTooltip]="group.title"
                matTooltipClass="medium-tooltip">{{ group.title }}</span>
          <span
            tabindex="0"
            class="chip-item-icon delete-icon"
            (click)="onGroupRemoved(i)"
            (keydown.enter)="onGroupRemoved(i)">
            <svg class="svg" role="img" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
            </svg>
          </span>
        </div>
      </div>
      <div class="group-filter-settings__footer">
        <button
          mat-stroked-button
          class="group-filter-settings__btn"
          (click)="applyFilter()">
          {{'translations.global.button.apply' | transloco}}
        </button>
      </div>
    </div>
  </mat-card>
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="setGroupIs()"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="'translations.global.ariaLabels.groupIs' | transloco">
      <span>
        {{'translations.reviews.filter.is' | transloco }}
      </span>
    </button>
    <button mat-menu-item (click)="setGroupIsNot()"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="'translations.global.ariaLabels.groupIsNot' | transloco">
      <span>
        {{'translations.reviews.filter.isNot' | transloco }}
      </span>
    </button>
  </ng-template>
</mat-menu>
