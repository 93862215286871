import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { AUTH_SERVICE, BasicAuthService } from '../../services/basic-auth.service';
import { AccessTokenService } from '../../services/access-token.service';
import { USER_IS_LOGGED_IN } from '../../services/api-basic-auth.service';
import * as UserAuthActions from '../../store/user-auth.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { LTI_AUTH_SERVICE, LtiAuthService } from '../../services/lti-auth.service';

@Component({
  template: '',
})
export class LtiDeeplinkingComponent implements OnInit {

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    @Inject(AUTH_SERVICE) private authService: BasicAuthService,
    @Inject(LTI_AUTH_SERVICE) private ltiRequestService: LtiAuthService,
    private readonly accessTokenService: AccessTokenService
  ) {
    this.accessTokenService.removeAccessToken();
  }

  ngOnInit() {
    const requestId = this.activatedRoute.snapshot.queryParamMap.get('requestId');

    if (requestId) {
      this.authService.getLtiToken(requestId, true).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.accessTokenService.saveAccessToken(value.access_token);
          localStorage.setItem(USER_IS_LOGGED_IN, 'true');
          this.ltiRequestService.saveLtiRequestId(requestId);
          this.store.dispatch([
            new UserAuthActions.FetchUserDetails(),
            new UserAuthActions.RedirectToAuthenticatedAppSuccess(),
            new UserAuthActions.GetHomePageTitleAndUri()
          ]).toPromise().then(
            () => {
              RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/lti/course/edit');
            }
          )
        } else {
          RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/lti/registration/error');
        }
      })
    }
  }
}
