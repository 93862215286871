<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="form-wrapper">
  <div
    class="form-preview"
    role="form"
    tabindex="0"
    [attr.aria-label]="('translations.global.ariaLabels.textBoxFormTitle' | transloco: {
      title: formData?.content?.title,
      state: (submitted ? 'translations.global.ariaLabels.update' : 'translations.global.ariaLabels.markAsDone') | transloco
    })">
    <div class="form-preview__top">
      <div class="form-preview__top-title">
        <div class="title medium bold">{{ formData?.content?.title }}</div>
      </div>
    </div>
    <div class="form-preview__input" *ngIf="!isCheckboxForm">
      <ptl-resource-html-preview *ngIf="userAnswerText" [content]="submittedAnswerContent"></ptl-resource-html-preview>
    </div>

    <div class="form-preview__checkbox" *ngIf="isCheckboxForm">
      <mat-checkbox
        color="primary"
        [aria-label]="('translations.global.ariaLabels.checkForm' | transloco)"
        [(ngModel)]="isCheckboxChecked"
        [disabled]="submitted">
      </mat-checkbox>
      <div class="printable" [class.checked]="isCheckboxChecked"></div>
    </div>
    <div class="form-preview-notes"
         *ngIf="formData?.content?.notes?.length"
         [class.form-preview--active]="inputFocused">
      <div class="note-item" *ngFor="let note of formData?.content?.notes" tabindex="0">
        <div class="note-item-inner">
          <div class="note-message small">
            <ptl-resource-preview class="thin-preview" [previewContent]="note.message">
            </ptl-resource-preview>
          </div>
        </div>
        <div class="note-added-by extra-small italic">
          {{ note.addedBy }}, {{ note.addedOn | dateFormat: 'DD MMM yyyy HH:mm' }}
        </div>
      </div>
    </div>
  </div>
</div>
