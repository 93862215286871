/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { UserAuthState } from '../../store/user-auth.state';
import { Select } from '@ngxs/store';
import { Organization } from '../../../shared/models';
import { VersionHelper } from '../../../shared/helpers/version.helper';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';

@Component({
  selector: 'ptl-verification-template',
  templateUrl: './verification-template.component.html',
  styleUrls: ['./verification-template.component.scss'],
})
export class VerificationTemplateComponent {

  @Input() title: string;

  @Input() buttonName: string;

  @Input() showButton: boolean;

  @Output() buttonClick = new EventEmitter<void>();

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  showMaintenanceOverlay: boolean;
  newVersionEnabled = VersionHelper.newVersionEnabled();

  constructor( private maintenanceService: MaintenanceService ) {
    this.showMaintenanceOverlay = this.maintenanceService.showMaintenanceMessage();
  }

  onButtonClick() {
    this.buttonClick.emit();
  }
}
