/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { LikeInfoDetails } from '../../../models/like-info/like-info.model';
import { LIKE_INFO_DATA_SERVICE, LikeInfoDataService } from '../../../services/like-info/like-info-data.service';
import { LikeInfoSubscriptionService } from '../../../services/like-info/like-info-subscription.service';
import { Subscription } from 'rxjs';
import { ResourcePublishedStatus, ResourceType } from '../../../models';

export type LikeInfoType = 'PLAYLIST' | 'CARD';

@Component({
  selector: 'ptl-like-info',
  templateUrl: './like-info.component.html',
  styleUrls: ['./like-info.component.scss'],
})
export class LikeInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() uid: string;
  @Input() infoType: LikeInfoType;
  @Input() title: string;
  @Input() isVertical: boolean;
  @Input() isLarge: boolean;
  @Input() likeDetails: LikeInfoDetails;
  @Input() editMode: boolean;
  @Input() isActionButton: boolean;
  @Input() showButtonTitle: boolean;

  totalLikes: number;
  vote: boolean;

  private likeInfoSubscription: Subscription;

  constructor(
    @Inject(LIKE_INFO_DATA_SERVICE) private likeInfoDataService: LikeInfoDataService,
    private cd: ChangeDetectorRef,
    private likeInfoSubscriptionService: LikeInfoSubscriptionService
  ) {
  }

  ngOnInit() {
    this.likeInfoSubscription = this.likeInfoSubscriptionService.getCurrentMessage()?.subscribe((value) => {
      if (value.infoType === this.infoType && value.uid === this.uid && !this.editMode) {
        this.vote = value.vote;
        this.totalLikes = value.totalLikes;
        this.cd.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('likeDetails' in changes || 'editMode' in changes || 'uid' in changes || 'infoType' in changes) {
      if (this.likeDetails && !this.editMode && this.infoType && this.uid) {
        this.likeInfoSubscriptionService.sendMessage({
          infoType: this.infoType,
          vote: this.likeDetails.vote,
          totalLikes: this.likeDetails.totalLikes ? this.likeDetails.totalLikes : 0,
          uid: this.uid,
        });
      } else {
        this.vote = false;
        this.totalLikes = 0;
      }
    }
  }

  onVote() {
    if (!this.editMode) {
      if (this.infoType === 'PLAYLIST') {
        this.votePlaylist();
      } else if (this.infoType === 'CARD') {
        this.voteCard();
      }
    }
  }

  getLikeCountTitle() {
    const resourceType = this.infoType?.toLowerCase();
    const otherPeopleCount = this.totalLikes - (this.vote ? 1 : 0);
    const peopleString = otherPeopleCount > 0 ?
      (otherPeopleCount + (otherPeopleCount > 1 ? ' people' : ' person')) :
      '';

    if (this.editMode) {
      return '';
    } else if (this.vote) {
      return 'You' + (otherPeopleCount > 0 ? (' and ' + peopleString) : '') + ' liked this ' + resourceType;
    } else {
      return peopleString + ' liked this ' + resourceType;
    }
  }

  ngOnDestroy() {
    this.likeInfoSubscription?.unsubscribe();
  }

  private voteCard() {
    if (this.vote) {
      this.likeInfoDataService.unvoteResource(this.uid).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.likeInfoSubscriptionService.sendMessage({
            infoType: 'CARD',
            vote: false,
            totalLikes: value,
            uid: this.uid,
          });
        }
      });
    } else {
      this.likeInfoDataService.voteResource(this.uid).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.likeInfoSubscriptionService.sendMessage({
            infoType: 'CARD',
            vote: true,
            totalLikes: value,
            uid: this.uid,
          });
        }
      });
    }
  }

  private votePlaylist() {
    if (this.vote) {
      this.likeInfoDataService.unvotePlaylist(this.uid).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.likeInfoSubscriptionService.sendMessage({
            infoType: 'PLAYLIST',
            vote: false,
            totalLikes: value,
            uid: this.uid,
          });
        }
      });
    } else {
      this.likeInfoDataService.votePlaylist(this.uid).subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          this.likeInfoSubscriptionService.sendMessage({
            infoType: 'PLAYLIST',
            vote: true,
            totalLikes: value,
            uid: this.uid,
          });
        }
      });
    }
  }
}

