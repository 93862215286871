<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<ng-container>
  <ptl-data-load-error-panel
    *ngIf="displayErrorPanel"
    [errorMessage]="('translations.errors.errorResourceNotAvailable' | transloco)"
  ></ptl-data-load-error-panel>
</ng-container>
