/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { UserGroupsDataService } from './groups-data.service';
import {
  CreateUserGroups,
  UserGroupMember,
  UserGroups,
  UserGroupUploadResult
} from '../../../../shared/models/admin/group/user-groups.model';
import { ObservableResult } from '../../../../shared/store';
import { Page } from '../../../../shared/models/page';
import { delay } from 'rxjs/operators';

@Injectable()

export class MockUserGroupsDataService implements UserGroupsDataService {

  private userGroup: UserGroups = {
    _id: '0',
    title: 'GROUP title',
    organizationId: '0',
    memberCount: 10,
    type: 'TYPE',
    lastUpdatedOn: new Date(),
  };

  loadUserGroupsMembers(userGroupUid: string, page: number, size: number, term?: string): ObservableResult<Page<UserGroupMember>> {
    return ObservableResult.ofSuccess();
  }

  searchUserGroups(page: number, size: number, term?: string, playlistUid?: string): ObservableResult<Page<UserGroups>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: [this.userGroup],
    }).pipe(delay(2000));
  }

  bulkUploadUsers(csvFile: File, groupId: string): ObservableResult<UserGroupUploadResult> {
    return ObservableResult.ofSuccess();
  }

  loadSystemGroups(): ObservableResult<string[]> {
    return ObservableResult.ofSuccess();
  }

  loadReviewerGroupTypes(playlistUid?: string): ObservableResult<string[]> {
    return ObservableResult.ofSuccess();
  }

  createUserGroup(request: CreateUserGroups): ObservableResult<UserGroups> {
    return ObservableResult.ofSuccess();
  }

  updateUserGroup(userGroupUid: string, request: CreateUserGroups): ObservableResult<UserGroups> {
    return ObservableResult.ofSuccess();
  }

  loadUserGroup(userGroupUid: string, playlistUid?: string): ObservableResult<UserGroups> {
    return ObservableResult.ofSuccess();
  }

  getUserGroupsByType(type: string, playlistUid?: string): ObservableResult<UserGroups[]> {
    return ObservableResult.ofSuccess();
  }

  assignMultipleGroupsToMultipleUsers(groupUids: string[], userUids: string[]): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
  removeMultipleGroupsFromMultipleUsers(groupUids: string[], userUids: string[]): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
