/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DiagnosticQuestionOptionRequest,
  DiagnosticQuestionOptionResponse,
  DiagnosticQuestionResponse,
  LanguageValue,
  Organization
} from '../../models';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';
import { VersionHelper } from '../../helpers/version.helper';


@Component({
  selector: 'ptl-diagnostics-view-choice-question-type',
  templateUrl: './choice-question-type.component.html',
  styleUrls: [ './choice-question-type.component.scss' ],
})
export class DiagnosticsViewChoiceQuestionTypeComponent implements OnInit {

  /** Receives the question to answer */
  @Input() question: DiagnosticQuestionResponse;
  @Input() answeredQuestions;
  @Input() multipleChoice: boolean;
  @Input() disabled: boolean;
  @Input() currentLanguage: string;
  @Input() organization: Organization;

  /** Emits the answer when selected. */
  @Output() selectedAnswer = new EventEmitter<{
    uid: string;
    answer: DiagnosticQuestionOptionRequest;
  }>();

  answer: DiagnosticQuestionOptionRequest;
  instruction: string;
  options;
  newVersionEnabled = VersionHelper.newVersionEnabled();
  fadeOut: boolean;

  ngOnInit() {
    if ( this.question ) {
      this.instruction = LanguageCodeHelper
        .getDataByUserLanguageCode(this.question.instructions, this.organization, this.currentLanguage).value;
      if ( this.multipleChoice ) {
        this.options = [ ...this.question.options ].map(option => ({ ...option, selected: false }));
        const selectedOptions = this.answeredQuestions.selectedOptions ? this.answeredQuestions.selectedOptions : '';
        if ( selectedOptions ) {
          for ( const selectedOption of selectedOptions ) {
            for ( const option of this.options ) {
              if ( selectedOption.optionUid === option.uid ) {
                this.selectAnswer(option, true);
                break;
              }
            }
          }
        }
      } else {
        const selectedOption = this.answeredQuestions[this.question.uid] ?
          this.answeredQuestions[this.question.uid] : '';
        if ( selectedOption ) {
          const selectedOptionUid = (selectedOption && selectedOption[0]) ? selectedOption[0].optionUid : '';
          if ( selectedOptionUid ) {
            for ( const option of this.question.options ) {
              if ( selectedOptionUid === option.uid ) {
                this.selectAnswer(option, true);
                break;
              }
            }
          }
        }
      }
    }
  }

  selectAnswer(option: DiagnosticQuestionOptionResponse, override = false) {
    if ( this.disabled && !override ) {
      return;
    }

    if ( !this.newVersionEnabled ) {
      this.answer = option;
      this.selectedAnswer.emit({ uid: this.question.uid, answer: this.answer });
      return;
    }

    this.fadeOut = false;

    setTimeout(() => {
      this.fadeOut = true;
      this.answer = option;
      this.selectedAnswer.emit({ uid: this.question.uid, answer: this.answer });
    }, 100);
  }

  getTranslatedValue(values: LanguageValue[]) {
    return LanguageCodeHelper.getDataByUserLanguageCode(values, this.organization, this.currentLanguage).value;
  }
}
