/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

// -------------------------------------------------------------------------
import { MomentEditorState } from './moment-view-all-state.model';
import { Moment } from '../../../models';

export class TriggerMomentCreation {
  static readonly type = '[Moment] Trigger Creations';

  constructor(public shouldAddToFolio?: boolean) {
  }
}

// -------------------------------------------------------------------------
export class LoadMoments {
  static readonly type = '[Moment] Load Moments';

  constructor(public page: number, public size: number) {
  }
}

// -------------------------------------------------------------------------
export class MomentChange {
  static readonly type = '[Moment] Moment Change';

  constructor(public editorState: MomentEditorState) {
  }
}

// -------------------------------------------------------------------------
export class DeleteMoment {
  static readonly type = '[Moment] Delete Moment';

  constructor(public momentId: string) {
  }
}

// -------------------------------------------------------------------------
export class UpdateMoment {
  static readonly type = '[Moment] Update moment';

  constructor(public id: string) {
  }
}

export class UpdateMomentsList {
  static readonly type = '[Moment] Update moments list';

  constructor(public moment: Moment) {
  }
}

// -------------------------------------------------------------------------
export class AddMomentToFolio {
  static readonly type = '[Moment] Add moment to folio';

  constructor(public momentUid: string) {
  }
}

// -------------------------------------------------------------------------
export class RemoveMomentFromFolio {
  static readonly type = '[Moment] Removing moment to folio';

  constructor(public momentUid: string) {
  }
}

// -------------------------------------------------------------------------
export class SearchMoments {
  static readonly type = '[Moment] Search Moments';

  constructor(public momentType: string, public orderBy: string, public searchKey: string, public page: number, public size: number) {
  }
}

// -------------------------------------------------------------------------
export class LoadMomentsOnFolio {
  static readonly type = '[Moment] Load Moments if folio page';
}
