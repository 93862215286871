import { Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AppFrameState } from '../../../app-frame/store/app-frame.state';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { SideNavState } from '../store/side-nav.state';
import { CloseMobileNavMenu } from '../../../app-frame/store/app-frame.actions';
import {
  Organization,
  PlaylistCardShort,
  SidenavTreeSettingUpdatedItem,
  SidenavTreeSettingUpdatedTreeItem
} from '../../models';
import { SidebarCard, SidebarSection } from '../../models/sidebar-tree/sidebar-tree.model';
import { PAGES_DATA_SERVICE, PageDataService } from '../../../page-modules/pages/services/data.service';
import {
  SIDEBAR_TREE_DATA_SERVICE,
  SidebarTreeDataService
} from '../../services/sidebar-tree/sidebar-tree-data.service';
import { SIDEBAR_SECTIONS } from '../../constants/constants';
import { filter, takeUntil } from 'rxjs/operators';
import {
  ResetFindItemInSidebarTree,
  ResetSidebarItemChildrenUpdate,
  ResetSidebarItemUpdateData,
  ResetSidebarTree,
  ResetRemoveSidebarViewStackAfterId
} from '../store/side-nav.actions';
import { cloneDeep } from 'lodash-es';
import { SidebarTreeHelper } from '../../helpers/sidebar-tree/sidebar-tree-helper';
import { PageList } from '../../models/page/page.model';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { UserRole } from '../../../user-auth/models';
import { Router } from '@angular/router';
import { TranslationService } from '../../services/translation/translation.service';
import { ProjectHelper } from '../../../page-modules/project/helpers/project.helper';
import { ADMIN_DATA_SERVICE, AdminDataService } from '../../../page-modules/admin/services/data.service';

@Component({
  selector: 'ptl-main-side-nav-tree',
  templateUrl: './main-side-nav-tree.component.html',
  styleUrls: ['./main-side-nav-tree.component.scss'],
})
export class SideNavTreeMainComponent implements OnInit, OnDestroy {


  @Select(AppFrameState.isMobile)
  isMobile$: Observable<boolean>;

  @Select(AppFrameState.isMobileMenuExpanded)
  mobileMenuExpanded$: Observable<boolean>;

  @Select(SideNavState.showSidebarTree)
  showSidebarTree$: Observable<boolean>;

  @Select(SideNavState.showSidebarWorkspaceNav)
  showSidebarWorkspaceNav$: Observable<boolean>;

  @Select(UserAuthState.isDiscoveryPageAvailable)
  isDiscoveryPageAvailable$: Observable<boolean>;

  @Select(UserAuthState.userRoles)
  private userRoles$: Observable<UserRole[]>;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(UserAuthState.homePageUri)
  private homePageUri$: Observable<string>;

  @Select(SideNavState.resetSidebarTree)
  private resetSidebarTree$: Observable<boolean>;

  @Select(SideNavState.removeSidebarViewStackAfterId)
  private removeSidebarViewStackAfterId$: Observable<string>;

  @Select(SideNavState.getUpdatedItemData)
  private updatedItemData$: Observable<SidenavTreeSettingUpdatedItem>;

  @Select(SideNavState.getItemIdForUpdatingChildren)
  private updatedItemTree$: Observable<SidenavTreeSettingUpdatedTreeItem>;

  @Select(SideNavState.onSearchItemInTree)
  private onSearchItemInTree$: Observable<string>;

  @Select(UserAuthState.categoriesFeatureFlag)
  private categoriesFeatureFlag$: Observable<boolean>;

  sections: SidebarSection[] = [];
  stackView = true;
  isMobile = false;
  stackViewArrays: string[] = [];

  private discoveryPageAvailable: boolean;
  private userRoles: UserRole[] = [];
  private organization: Organization;
  private homePageUri: string;
  private subscriptionEnd$ = new EventEmitter<void>();
  private mobileSubscription: Subscription;
  private institutionalPlaylist: PlaylistCardShort;

  constructor(
    private router: Router,
    private store: Store,
    private translationService: TranslationService,
    @Inject(PAGES_DATA_SERVICE) private pageDataService: PageDataService,
    @Inject(SIDEBAR_TREE_DATA_SERVICE) private sidebarTreeService: SidebarTreeDataService,
    @Inject(ADMIN_DATA_SERVICE) private adminDataService: AdminDataService,
  ) {

    this.subscribeToRefreshSidebar();
    this.subscribeToSearchSidebarItem();
    this.subscribeToUpdateSidebarItem();
    this.subscribeToUpdateSidebarItemChildren();
    if ( this.stackView ) {
      this.subscribeToRemoveSiblingsUidFromStack();
    }

  }

  @HostListener('window:keyup.esc')
  onkeyup() {
    if ( this.isMobile ) {
      this.store.dispatch(new CloseMobileNavMenu());
    }
  }

  ngOnInit() {
    const isProjectResource = ProjectHelper.isProjectByUrl(this.router);
    if (isProjectResource) {
      // TODO add side bar navigation for project
    } else {
      this.homePageUri$.pipe(filter(data => !!data)).subscribe(
        data => {
          this.homePageUri = data;
          this.handleSidebar();
        }
      );
    }

    this.pageDataService.getInstitutionalLibraryPlaylist().subscribe(({value}) => {
      this.institutionalPlaylist = value;
    })

    combineLatest([this.userRoles$, this.isDiscoveryPageAvailable$, this.organizationData$])
      .pipe(
        filter(data => !!data[2]),
        takeUntil(this.subscriptionEnd$)
      ).subscribe(( data ) => {
      this.userRoles = data[0];
      this.discoveryPageAvailable = data[1]
      this.organization = data[2];
    });

    this.mobileSubscription = this.isMobile$.subscribe(( data ) => {
      this.isMobile = data;
    });
  }

  ngOnDestroy() {
    this.stackViewArrays = [];
    this.subscriptionEnd$?.emit();
    this.mobileSubscription?.unsubscribe();
  }

  onSideBarItemClicked( data: { card: SidebarCard; level: number } ) {
    if ( this.stackView ) {
      const index = data.level - 1;
      if ( !this.stackViewArrays[index] ) {
        this.stackViewArrays.push(data.card._id);
      } else {
        this.stackViewArrays.length = data.level;
        this.stackViewArrays[index] = data.card._id;
      }
    }
    this.saveSidebarInStore();
  }

  closeSidebarMenu() {
    this.store.dispatch(new CloseMobileNavMenu());
  }

  private handleSidebar() {
    if ( location.pathname === '/' || location.pathname.endsWith(this.homePageUri) ) {
      window.sessionStorage.removeItem(SIDEBAR_SECTIONS);
    }

    const sidebar = window.sessionStorage.getItem(SIDEBAR_SECTIONS);
    if ( sidebar ) {
      this.sections = JSON.parse(sidebar);
      this.stackViewArrays = [];
      this.handleStackViewAfterReload(this.sections[0]?.cards);
      this.translateSideNavSection(this.sections);
    } else {
      this.loadPageDataAndDrawSidebar();
    }
  }

  private subscribeToRefreshSidebar() {
    this.resetSidebarTree$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(shouldReset => {
      if ( shouldReset ) {
        if ( this.stackView ) {
          this.stackViewArrays = [];
        }
        this.store.dispatch(new ResetSidebarTree(false));
        this.loadPageDataAndDrawSidebar();
      }
    });
  }

  private subscribeToRemoveSiblingsUidFromStack() {
    this.removeSidebarViewStackAfterId$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(itemUid => {
      if ( itemUid ) {
        const index = this.stackViewArrays.findIndex(it => it === itemUid);
        if ( index ) {
          this.stackViewArrays.length = index + 1
        }
        this.store.dispatch(new ResetRemoveSidebarViewStackAfterId());
      }
    });
  }

  private subscribeToSearchSidebarItem() {
    this.onSearchItemInTree$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(itemUid => {
      if ( itemUid ) {
        if ( this.sections[0] && this.sections[0].cards?.length ) {
          if ( this.stackView ) {
            let activeSidebarItem = this.findActiveSidebarItem(0);
            // Handle home page case
            if ( !activeSidebarItem ) {
              activeSidebarItem = this.sections[0].cards[0];
              if ( !this.stackViewArrays.length ) {
                this.stackViewArrays.push(activeSidebarItem._id);
              }
            }

            const parentItem = this.findActiveSidebarItemParent(0, activeSidebarItem._id);
            if ( parentItem && activeSidebarItem ) {
              this.onSideBarSiblingsCollapse(activeSidebarItem, parentItem.cards);
            }

            let index = this.stackViewArrays.findIndex(it => it === itemUid);
            if ( !index ) {
              index = this.stackViewArrays.length;
            }
            if ( !this.stackViewArrays[index] ) {
              this.stackViewArrays.push(itemUid);
            } else {
              this.stackViewArrays.length = this.stackViewArrays.length - 1;
              this.stackViewArrays[index] = itemUid;
            }

            const result = SidebarTreeHelper.searchFirstLevel(activeSidebarItem, itemUid);
            if ( result ) {
              this.expandParentItemAndLoadChildren(result, activeSidebarItem);
            } else {
              if ( parentItem ) {
                parentItem.expanded = false;
              }
            }
            this.store.dispatch(new ResetFindItemInSidebarTree());
          } else {
            for ( const item of this.sections[0].cards ) {
              const result = SidebarTreeHelper.searchInTree(item, itemUid);
              if ( result ) {
                this.expandParentItemAndLoadChildren(result);
                this.store.dispatch(new ResetFindItemInSidebarTree());
                break;
              }
            }
          }
        }
      }
    });
  }

  private subscribeToUpdateSidebarItem() {
    this.updatedItemData$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(data => {
      if ( data && data.uid && data.options ) {
        if ( this.sections[0] && this.sections[0].cards?.length ) {
          for ( const item of this.sections[0].cards ) {
            const result = SidebarTreeHelper.searchInTree(item, data.uid);
            if ( result ) {
              if ( data.options.title ) {
                result.title = data.options.title;
              }
              if ( data.options.uri && data.options.oldUri ) {
                result.formattedUri = result.formattedUri.replace(data.options.oldUri, data.options.uri);
                result.requestUri = result.requestUri.replace(data.options.oldUri, data.options.uri);
              }

              if ( data.options.published !== undefined ) {
                result.published = data.options.published;
              }
              this.saveSidebarInStore();
              this.store.dispatch(new ResetSidebarItemUpdateData());
              break;
            }
          }
        }
      }
    });
  }

  private subscribeToUpdateSidebarItemChildren() {
    this.updatedItemTree$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(data => {
      if ( data && data.uid ) {
        if ( this.sections[0] && this.sections[0].cards?.length ) {
          for ( const item of this.sections[0].cards ) {
            const result = SidebarTreeHelper.searchInTree(item, data.uid);
            if ( result ) {
              if ( data.updateParent ) {
                this.store.dispatch(new ResetSidebarItemChildrenUpdate());
                if ( !result.parentId ) {
                  this.loadPageDataAndDrawSidebar();
                } else {
                  for ( const parentItem of this.sections[0].cards ) {
                    const parentResult = SidebarTreeHelper.searchInTree(parentItem, result.parentId);
                    if ( parentResult ) {
                      SidebarTreeHelper.loadChildData(this.sidebarTreeService, parentResult, () => {
                        parentResult.expanded = true;
                        this.saveSidebarInStore();
                      }, this.adminDataService);
                      break;
                    }
                  }
                }
              } else {
                SidebarTreeHelper.loadChildData(this.sidebarTreeService, result, () => {
                  this.saveSidebarInStore();
                  this.store.dispatch(new ResetSidebarItemChildrenUpdate());
                });
              }
              break;
            }
          }
        }
      }
    });
  }

  private expandParentItemAndLoadChildren( result: SidebarCard, resultParentItem?: SidebarCard ) {
    SidebarTreeHelper.loadChildData(this.sidebarTreeService, result, () => {
      if ( result.parentId ) {
        if ( this.stackView ) {
          resultParentItem.expanded = true;
          this.handleShowHideSiblings(
            result,
            resultParentItem.cards,
            resultParentItem.type === 'HOME_PAGE' || resultParentItem.uri?.endsWith(this.homePageUri)
          );
          this.saveSidebarInStore();
        } else {
          if ( this.sections[0] && this.sections[0].cards?.length ) {
            for ( const item of this.sections[0].cards ) {
              const parentItem = SidebarTreeHelper.searchInTree(item, result.parentId);
              if ( parentItem ) {
                parentItem.expanded = true;
                this.saveSidebarInStore();
                break;
              }
            }
          }
        }
      } else {
        result.expanded = true;
        this.saveSidebarInStore();
      }
    }, this.adminDataService);
  }

  private saveSidebarInStore() {
    window.sessionStorage.setItem(SIDEBAR_SECTIONS, JSON.stringify(this.sections));
  }

  private loadPageDataAndDrawSidebar() {
    window.sessionStorage.removeItem(SIDEBAR_SECTIONS);

    combineLatest([
      this.categoriesFeatureFlag$,
      this.pageDataService.getPages(0, 50)
    ]).pipe(
      takeUntil(this.subscriptionEnd$)
    ).subscribe(([categoriesEnabled, { isSuccess, value }]) => {
      if (isSuccess) {
        const allSections = cloneDeep(this.formatPagesToSidebar(value));
        if (!this.discoveryPageAvailable || categoriesEnabled) {
          allSections[1].staticLinks = allSections[1].staticLinks.filter(item => item.titleId !== 'explore');
        }
        this.sections = SidebarTreeHelper.excludeStaticLinksByRoles(allSections, this.organization, this.userRoles);
        if (this.sections[1] && !this.sections[1].staticLinks?.length) {
          this.sections.length = 1;
        }
        if (this.institutionalPlaylist) {
          this.contactInstitutionalPlaylist();
        }
        this.translateSideNavSection(this.sections);
        this.loadHomePageChildItems();
      }
    });
  }

  private loadHomePageChildItems() {
    const homePage = this.sections[0]?.cards?.find(item =>
      item.type === 'HOME_PAGE' || item.uri.endsWith(this.homePageUri)
    );
    if ( homePage ) {
      SidebarTreeHelper.loadChildData(this.sidebarTreeService, homePage, () => {
        this.saveSidebarInStore();
      }, this.adminDataService);
    }
  }

  private formatPagesToSidebar( pages: PageList[] ): SidebarSection[] {
    const sections: SidebarSection[] = [
      {
        title: '',
        titleId: '',
        cards: [],
      },
      {
        title: '',
        titleId: '',
        class: 'bottom-sticky-section',
        displayTopLineSeparator: true,
        staticLinks: [
          {
            title: 'Explore',
            titleId: 'explore',
            uri: localStorage.getItem('orgDiscoveryUrl'),
            icon: {
              // eslint-disable-next-line max-len
              svg: '<use xlink:href="assets/sprite/sprite.svg#explore"></use>',
              color: 'cod-gray',
            },
          },
          {
            title: 'Add new page',
            titleId: 'addNewPage',
            uri: 'pages/create',
            allowedRoles: ['ADMIN', 'ACCOUNT_OWNER'],
            icon: {
              // eslint-disable-next-line max-len
              svg: '<use xlink:href="assets/sprite/sprite.svg#add-circle"></use>',
              color: 'cod-gray',
            },
          },
        ],

      },
    ];
    for ( const page of pages ) {
      sections[0].cards.push({
        _id: page._id,
        title: page.title,
        requestUri: page.formattedUri ? page.formattedUri : '/pages/' + page.uri,
        formattedUri: page.type === 'HOME_PAGE' ? '' : (page.formattedUri ? page.formattedUri : '/pages/' + page.uri),
        type: page.type === 'HOME_PAGE' ? 'HOME_PAGE' : 'PAGE',
        showOnLeftNav: page.showOnLeftNav,
        published: page.published,
        uri: page.type === 'HOME_PAGE' ? '' : '/pages/' + page.uri,
        parentId: null,
        hasChild: true,
      });
    }
    return sections;
  }

  private handleShowHideSiblings( card: SidebarCard, cards: SidebarCard[], isHomePage: boolean ): void {
    if ( isHomePage ) {
      for ( const page of this.sections[0].cards ) {
        if ( page.type !== 'HOME_PAGE' && !page.uri.endsWith(this.homePageUri) ) {
          page.class = 'is_hidden';
        }
      }
    }
    if ( cards && cards.length ) {

      if ( cards.length < 6 ) {
        cards.map(item => item.class = '');
        return;
      }
      const visibleItems = this.findFourSiblingItems(card, cards);

      for ( let i = 0; i < cards.length; i++ ) {
        const item = cards[i];
        if ( visibleItems.includes(i) ) {
          item.class = '';
        } else {
          item.class = 'is_hidden';
        }
        if ( item.cards && item.cards.length ) {
          this.handleHideChildCards(item.cards);
        }
      }
    }
  }

  private handleHideChildCards( cards: SidebarCard[] ): void {
    if ( cards && cards.length ) {
      for ( const card of cards ) {
        card.expanded = false;
        card.class = 'is_hidden'
        if ( card.cards && card.cards.length ) {
          this.handleHideChildCards(card.cards);
        }
      }
    }
  }

  private findFourSiblingItems( item: SidebarCard, cards: SidebarCard[] ): number[] {
    const index = cards.findIndex(card => item._id === card._id);
    if ( index === -1 ) {
      return []; // Item not found in the array
    }

    // Determine the indexes of the four siblings
    const numPrevious = Math.min(index, 2);
    const numNext = Math.min(cards.length - 1 - index, 2);

    const siblingIndexes: number[] = [];

    for ( let i = index - numPrevious; i <= index + numNext; i++ ) {
      siblingIndexes.push(i);
    }

    return siblingIndexes;

  }

  private findActiveSidebarItemParent( index: number, activeItemId: string ): SidebarCard {
    const tmpArr = cloneDeep(this.stackViewArrays);
    const activeItemIndex = tmpArr.findIndex(it => it === activeItemId);
    // keep only items before active item
    if ( activeItemIndex !== -1 ) {
      tmpArr.length = activeItemIndex;
    }
    let card: SidebarCard;
    if ( index < tmpArr.length ) {
      const id = tmpArr[index];
      card = this.sections[0].cards.find(item => item._id === id);
      if ( card && card.cards?.length ) {
        return this.findActiveSidebarChildItem(card, tmpArr, index + 1);
      } else {
        return card;
      }
    }
    return card;
  }

  private findActiveSidebarItem( index: number ): SidebarCard {
    let card: SidebarCard;
    if ( index < this.stackViewArrays.length ) {
      const id = this.stackViewArrays[index];
      card = this.sections[0].cards.find(item => item._id === id);
      if ( card && card.cards?.length ) {
        return this.findActiveSidebarChildItem(card, this.stackViewArrays, index + 1);
      } else {
        return card;
      }
    }
    return card;
  }

  private findActiveSidebarChildItem( parent: SidebarCard, stackArray: string[], index: number ) {
    if ( index < stackArray.length ) {
      const id = stackArray[index];
      const card = parent.cards.find(item => item._id === id);
      if ( card && card.cards?.length ) {
        return this.findActiveSidebarChildItem(card, stackArray, index + 1);
      } else {
        return card;
      }
    }
    return parent;
  }

  private handleStackViewAfterReload( cards: SidebarCard[] ) {
    if ( cards && cards.length ) {
      for ( const card of cards ) {
        if ( card.class === '' || card.class === undefined ) {
          this.stackViewArrays.push(card._id);
          if ( card.cards && card.cards.length ) {
            this.handleStackViewAfterReload(card.cards);
          }
          break
        }
      }
    }
  }

  private onSideBarSiblingsCollapse( card: SidebarCard, cards: SidebarCard[] ): void {
    if ( cards && cards.length ) {
      for ( const item of cards ) {
        if ( item._id === card._id ) {
          item.class = ''
        } else {
          item.class = 'is_hidden'
        }
      }
    }
  }

  private contactInstitutionalPlaylist() {
    this.sections[0].cards.push(
      {
        displayTopLineSeparator: true,
        parentId: null,
        _id: this.institutionalPlaylist._id,
        title: this.institutionalPlaylist.header,
        uri: this.institutionalPlaylist.uri,
        formattedUri: this.institutionalPlaylist.formattedUri,
        type: 'PLAYLIST',
        showOnLeftNav: true,
        published: this.institutionalPlaylist.status === 'PUBLISHED',
      }
    );
  }

  private translateSideNavSection( sections: SidebarSection[] ) {
    sections.map(section => {
      if ( section.staticLinks ) {
        section.staticLinks.map(item => {
          if ( item.titleId ) {
            item.title = this.translationService.getSideNavSectionTranslation(item.titleId, item.title);
          }
        });
      }
    });
  }
}
