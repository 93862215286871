/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../shared/store';
import { InjectionToken } from '@angular/core';
import {
  AlumniRequest,
  AlumniUploadCsvRequest,
  AlumniUploadCsvResponse,
  EmailResponse,
  FolioAccountVerificationRequest,
  PersonalDataRequest,
  TokenVerification,
  UserNotificationsSettingsRequest,
  UserPrivacySettingsRequest, UsersUploadCsvRequest, UsersUploadCsvResponse
} from '../../../shared/models/account/account.model';
import { UserDetailsSummary } from '../../../user-auth/models';


/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const ACCOUNT_DATA_SERVICE = new InjectionToken<AccountDataService>('[Account] DataService');

/** The admin data service. */
export interface AccountDataService {

  getPersonalData(): ObservableResult<UserDetailsSummary>;

  updatePersonalData(userData: PersonalDataRequest): ObservableResult<UserDetailsSummary>;

  resetUserPassword(): ObservableResult<void>;

  resetPasswordByEmail(email: string, captchaResponseV3?: string, captchaResponseV2?: string): ObservableResult<void>;

  getEmailByToken(tokenVerification: TokenVerification): ObservableResult<EmailResponse>;

  resetUserEmail(): ObservableResult<void>;

  resetUserRecoveryEmail(email: string): ObservableResult<void>;

  updateUserPassword(password: string, token: string, captchaResponseV3?: string, captchaResponseV2?: string): ObservableResult<void>;

  updateUserEmail(email: string, token: string, captchaResponseV3?: string, captchaResponseV2?: string): ObservableResult<void>;

  updateUserRecoveryEmail(token: string): ObservableResult<void>;

  updateUserPrivacy(request: UserPrivacySettingsRequest): ObservableResult<void>;

  updateUserNotifications(request: UserNotificationsSettingsRequest): ObservableResult<void>;

  connectUserToWorkspace(email: string): ObservableResult<void>;

  connectUniversityEmail(universityEmail: string, folioAccountInitialization: boolean): ObservableResult<void>;

  leaveUserFromWorkspace(organizationUid: string): ObservableResult<void>;

  verifyWorkspaceEmail(request: FolioAccountVerificationRequest): ObservableResult<void>;

  confirmWorkspaceEmailWithPassword(request: FolioAccountVerificationRequest): ObservableResult<void>;

  alumniRequest(request: AlumniRequest): ObservableResult<void>;

  alumniUploadCsv(request: AlumniUploadCsvRequest): ObservableResult<AlumniUploadCsvResponse>;

  usersUploadCsv(request: UsersUploadCsvRequest): ObservableResult<UsersUploadCsvResponse>;

  setLanguage(code: string): ObservableResult<void>;

  updateJiscUsage(hasUsedCpd: boolean): ObservableResult<void>;
}
