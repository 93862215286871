<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  #htmlEl
  class="image-preview-element
  image-preview-element-{{ content?.type }}
  image-preview-element-position-{{ content?.position || 'CENTER' }}"
  *ngIf="content?.type === 'MEDIA_URL'">
</div>

<div
  class="image-preview-element
  image-preview-element-{{ content?.type }}
  image-preview-element-position-{{ content?.position || 'CENTER' }}"
  *ngIf="content?.type === 'MEDIA_UPLOAD'"
  [ngClass]="'image-preview-element--' + content?.type">
  <ptl-media-upload-image-preview
    role="banner"
    [attr.aria-label]="!!uploadedImage ? (content?.caption ?? ('translations.global.ariaLabels.previewImage' | transloco))
     : ('translations.global.ariaLabels.uploadImage' | transloco)"
    [uploadedImage]="uploadedImage"
    [link]="content?.url"
    [caption]="content?.caption"
    (openUrl)="openUrl()">
  </ptl-media-upload-image-preview>
</div>
