/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { MediumEditorDirective } from '../../../../../shared/medium-editor/medium-editor.directive';
import { MediumEditorData, MediumEditorToolbarEvent } from '../../../../models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EDITOR_OPTIONS } from './medium-editor.options';

@Component({
  selector: 'ptl-medium-editor',
  templateUrl: './medium-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediumEditorComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  /** Receives the MediumEditorData */
  @Input() contentData: MediumEditorData;

  @Input() lastEditor: boolean;

  @Input() firstEditor: boolean;

  @Input() disableAutoFocus: boolean;
  /** Outputs the various click and change events */
  @Output() mediumEditorUpdate = new EventEmitter<MediumEditorData>();
  /** Emits editor-toolbar show/hide event */
  @Output() toolbarUpdate = new EventEmitter<MediumEditorToolbarEvent>();
  /** Outputs the various click and change events */
  @Output() inputFocus = new EventEmitter<void>();
  /** Outputs the various click and change events */
  @Output() inputBlur = new EventEmitter<void>();

  /** Hooking up to MediumEditorDirective functions through ViewChild */
  @ViewChild('mediumEditor', { read: MediumEditorDirective, static: true }) mediumEditor: MediumEditorDirective;

  private editorUpdateSubject$ = new Subject<MediumEditorData>();

  private mediumEditorUpdateSubscription: Subscription;
  private mediumEditorKeyupSubscription: Subscription;
  private mediumEditorClickSubscription: Subscription;
  private mediumEditorBlurSubscription: Subscription;
  private editorInputSubscription: Subscription;
  private mediumEditorFocusSubscription: Subscription;
  private mediumEditorDragSubscription: Subscription;
  private mediumEditorDropSubscription: Subscription;

  editorInput = '';
  editorOptions = EDITOR_OPTIONS;

  constructor(private renderer: Renderer2) {
    this.editorInputSubscription = this.editorUpdateSubject$
      .pipe(
        debounceTime(100),
        distinctUntilChanged((data: MediumEditorData) => data.content === this.editorInput)
      )
      .subscribe((value: MediumEditorData) => this.mediumEditorUpdate.emit(value));
  }

  @HostListener('window:blur')
  private onBlur() {
    const html = this.mediumEditor?.getElement();

    if (html) {
      this.mediumEditor['editor'].trigger('blur', new CustomEvent('windowBlur'), html);
    }
  }

  ngOnInit() {
    if (this.contentData && !!this.contentData.content) {
      let content = JSON.stringify(this.contentData.content);
      const hasOpenedDivTag = content.indexOf('<div>') !== -1;
      const hasClosedDivTag = content.indexOf('</div>') !== -1;
      if ((!hasOpenedDivTag && hasClosedDivTag) || (hasOpenedDivTag && !hasClosedDivTag)) {
        content = content.replace(/<\/div>/g, '').replace(/<div>/g, '');
      }
      this.editorInput = JSON.parse(content);
    }
  }

  ngAfterViewInit() {

    this.loadSubscriptions();

    // auto-focus if no content present, note: 'editor' and 'el' are private members
    if (this.contentData && !this.contentData.content.length && !this.disableAutoFocus) {
      this.mediumEditor['editor'].selectElement(this.mediumEditor['el'].nativeElement.childNodes[0]);
    }
  }

  loadSubscriptions() {
    // subscribe to medium-editor-directive's editorModelChange event, and emmit changes
    this.mediumEditorUpdateSubscription =
      this.mediumEditor.editorModelChange.subscribe(() => {
        this.editorUpdateSubject$.next({
          uid: this.contentData.uid,
          type: 'PARAGRAPH',
          content: this.editorInput,
        } as MediumEditorData);
      });

    // subscribe to medium-editor's keyup event, note: 'editor' is a private member
    this.mediumEditorKeyupSubscription =
      this.mediumEditor['editor'].subscribe('editableKeyup', (event: KeyboardEvent, html: HTMLElement) => {
        // on keypress Enter, show the toolbar, else clear everything
        if (event.key === 'Enter') {
          this.showToolbar(html);
        } else {
          this.toolbarUpdate.emit({ type: 'HIDE_TOOLBAR' });
          this.clearBreakpointElements(html, true);
        }

      });

    // subscribe to medium-editor's click event, note: 'editor' is a private member
    this.mediumEditorClickSubscription =
      this.mediumEditor['editor'].subscribe('editableClick', (_: KeyboardEvent, html: HTMLElement) => {
        this.showToolbar(html);
      });

    this.mediumEditorBlurSubscription =
      this.mediumEditor['editor'].subscribe('blur', (event: FocusEvent, html: HTMLElement) => {
        if (event.type === 'windowBlur' && !!document.getElementById('toolbarFloatingButtonsContainer')) {
          return;
        }
        if (event.type === 'focus') {
          return;
        }
        if ((event.target as HTMLElement)?.className?.includes('mat-dialog-container')) {
          if (document.getElementById('toolbarButton')) {
            if (!document.getElementById('toolbarButton').className.includes('is_toolbar-over')) {
              this.toolbarUpdate.emit({ type: 'HIDE_TOOLBAR' });
              this.clearBreakpointElements(html, true);
              this.inputBlur.emit();
            }
          } else {
            this.toolbarUpdate.emit({ type: 'HIDE_TOOLBAR' });
            this.clearBreakpointElements(html, true);
            this.inputBlur.emit();
          }
        } else {
          if (!(event.target as HTMLElement)?.className?.includes('f_editor-toolbar-add-button')) {
            this.toolbarUpdate.emit({ type: 'HIDE_TOOLBAR' });
            this.clearBreakpointElements(html, true);
            this.inputBlur.emit();
          }
        }
      });

    // subscribe to medium-editor's focus event
    this.mediumEditorFocusSubscription = this.mediumEditor['editor'].subscribe('focus', () => this.inputFocus.emit());

    // subscribe to medium-editor's drag event
    this.mediumEditorDragSubscription =
      this.mediumEditor['editor'].subscribe('editableDrag', (event: DragEvent, html: HTMLElement) => {
        event.preventDefault();
        this.removeDragOverClasses(html);

        if (event.type === 'dragover') {
          const target = event.target as HTMLElement;

          if (target !== html) {
            target.classList.add('medium-editor-dragover');
          }
        }
      });

    // subscribe to medium-editor's drop event
    this.mediumEditorDropSubscription =
      this.mediumEditor['editor'].subscribe('editableDrop', (event: DragEvent, html: HTMLElement) => {
        event.preventDefault();
        this.removeDragOverClasses(html);
        this.clearBreakpointElements(html, true);

        const target = event.target as HTMLElement;

        // create a new html element <split> and insert in before our element position
        const newElement = this.renderer.createElement('split');
        const closestParagraph = target.closest('.me-editable div,p,h1,h2,h3');

        if (closestParagraph) {
          this.renderer.insertBefore(closestParagraph.parentElement, newElement, closestParagraph);
        }
      });
  }

  ngOnDestroy() {
    this.mediumEditorUpdateSubscription?.unsubscribe();
    this.mediumEditorKeyupSubscription?.unsubscribe();
    this.mediumEditorClickSubscription?.unsubscribe();
    this.mediumEditorBlurSubscription?.unsubscribe();
    this.editorInputSubscription?.unsubscribe();
    this.mediumEditorFocusSubscription?.unsubscribe();
    this.mediumEditorDragSubscription?.unsubscribe();
    this.mediumEditorDropSubscription?.unsubscribe();
  }

  ngOnChanges() {
    this.editorInput = JSON.parse(JSON.stringify(this.contentData.content));
  }

  showToolbar(html) {
    // clearing previous breakpoints, before adding new
    this.clearBreakpointElements(html, false);

    const toolbarEventObject: MediumEditorToolbarEvent = {
      type: 'SHOW_TOOLBAR',
    };

    // selection element where caret is present
    const caretEl = document.getSelection().anchorNode;

    // if caretEl present and has <br> OR it is the initial wrapper (.me-editable), make the magic
    const caretElValidator = caretEl && ((caretEl as HTMLElement).innerHTML === '<br>' || caretEl &&
      ((caretEl as HTMLElement).className && (caretEl as HTMLElement).className.includes('me-editable')));
    if (caretElValidator) {
      // create a new html element <split> and insert in before our caret position
      const newElement = this.renderer.createElement('split');
      const allSplitEl = html ? html.querySelectorAll('split') : [];
      Array.from(allSplitEl).map(el => {
        if ((el as HTMLElement).classList.contains('is_hidden')) {
          this.renderer.removeChild((el as HTMLElement).parentElement, el);
        }
      });
      const closestParagraph = (caretEl as HTMLElement).closest('.me-editable div,p,h1,h2,h3');

      if (closestParagraph) {
        this.renderer.insertBefore(closestParagraph.parentElement, newElement, closestParagraph);
      }

      toolbarEventObject.offsetTop = (caretEl as HTMLElement).offsetTop;
      this.toolbarUpdate.emit(toolbarEventObject);
      // triggering refresh of the editorInput
      this.mediumEditor['editor'].trigger('editableInput');
    } else {
      this.toolbarUpdate.emit({ type: 'HIDE_TOOLBAR' });
    }
  }

  insertLinkBeforeBreakpoint(link: string) {
    if (this.mediumEditor.getElement()) {
      // select all <split> elements
      const allSplitEl = this.mediumEditor.getElement().querySelectorAll('split');

      const paragraphElement = document.createElement('p');
      const linkElement = document.createElement('a');

      linkElement.innerText = link;

      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        link = 'http://' + link;
      }

      const openTarget = (link.indexOf(window.location.hostname) !== -1) ? '_self' : '_blank';
      linkElement.href = encodeURI(link);
      linkElement.target = openTarget;
      paragraphElement.appendChild(linkElement);

      if (allSplitEl.length > 0) {
        this.renderer.insertBefore(allSplitEl[0].parentElement, paragraphElement, allSplitEl[0]);
      } else {
        const parentElement = this.mediumEditor.getElement().firstElementChild;

        if (parentElement) {
          this.renderer.appendChild(parentElement, paragraphElement);
        }
      }

      // triggering refresh of the editorInput
      setTimeout(() => this.mediumEditor['editor'].trigger('editableInput'));
    }
  }

  removeBreakpointsIfPresent() {
    const html = this.mediumEditor.getElement();
    this.clearBreakpointElements(html, true);
  }

  private clearBreakpointElements(html: HTMLElement, emitUpdate: boolean) {
    // select all <split> elements
    const allSplitEl = html.querySelectorAll('split');
    // removing created <split> html element
    Array.from(allSplitEl).map(el => {
      this.renderer.removeChild(el.parentElement, el);
    });

    // triggering refresh of the editorInput
    setTimeout(() => this.mediumEditor['editor'].trigger('editableInput'));
  }

  private removeDragOverClasses(html: HTMLElement) {
    const items = html.querySelectorAll('.medium-editor-dragover');
    items.forEach(element => element.classList.remove('medium-editor-dragover'));
  }
}

