<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div class="toolbar-option-button-box" *ngIf="buttonType">
  <button *ngIf="!uploadType" mat-button class="toolbar-option-button" (click)="onOptionClick()" tabindex="0"
          aria-label="{{buttonAriaLabel}}">
    <span class="toolbar-option-icon" *ngIf="buttonIcon">
      <svg class="svg" aria-hidden="true">
        <use ogSvgUnify="{{buttonIcon}}"></use>
      </svg>
    </span>
    <span class="toolbar-option-title">
      {{ buttonTitle }}
    </span>
  </button>

  <button *ngIf="uploadType === 'IMAGE'" mat-button class="toolbar-option-button" (click)="onImageUploadClick()"
          tabindex="0" aria-label="{{buttonAriaLabel}}">
    <span class="toolbar-option-icon" *ngIf="buttonIcon">
      <svg class="svg" aria-hidden="true">
        <use ogSvgUnify="{{buttonIcon}}"></use>
      </svg>
    </span>
    <span class="toolbar-option-title">
      {{ buttonTitle }}
    </span>
  </button>

  <button *ngIf="uploadType === 'VIDEO'" mat-button class="toolbar-option-button" (click)="onVideoUploadClick()"
          tabindex="0" aria-label="{{buttonAriaLabel}}">
    <div class="mat-button-wrapper">
      <span class="toolbar-option-icon" *ngIf="buttonIcon">
        <svg class="svg" aria-hidden="true">
          <use ogSvgUnify="{{buttonIcon}}"></use>
        </svg>
      </span>
      <span class="toolbar-option-title">
        {{ buttonTitle }}
      </span>
    </div>
  </button>

  <label *ngIf="uploadType && uploadType !== 'IMAGE' && uploadType !== 'VIDEO'"
         class="mat-button mat-button-base toolbar-option-button" tabindex="0" aria-label="{{buttonAriaLabel}}">
    <div class="mat-button-wrapper">
      <span class="toolbar-option-icon" *ngIf="buttonIcon">
        <svg class="svg">
          <use ogSvgUnify="{{buttonIcon}}"></use>
        </svg>
      </span>
      <span class="toolbar-option-title">
        {{ buttonTitle }}
      </span>
    </div>
    <input
      #uploadInput
      class="f_file-upload file-upload"
      type="file"
      [accept]="fileUploadTypes"
      (change)="onFileChanged($event)">
  </label>

</div>

