<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<div class="image-crop-dialog__container">
  <div class="image-crop-dialog__cropper-container">


    <div class="image-crop-dialog__description" *ngIf="description">
      {{ description }}
    </div>

    <div *ngIf="!isAnimatedGif" class="image-crop-action-buttons-container">
      <button class="image-crop-action-button" (click)="rotateLeft()" mat-icon-button>
        <svg class="svg" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#rotate"></use>
        </svg>
      </button>

      <button class="image-crop-action-button" (click)="zoomIn()" mat-icon-button>
        <svg class="svg" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#zoom-in"></use>
        </svg>
      </button>

      <button class="image-crop-action-button" (click)="zoomOut()" mat-icon-button>
        <svg class="svg" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#zoom-out"></use>
        </svg>
      </button>

      <button class="image-crop-action-button" (click)="flipHorizontal()" mat-icon-button>
        <svg class="svg" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#flip-horizontal"></use>
        </svg>
      </button>

      <button class="image-crop-action-button" (click)="flipVertical()" mat-icon-button>
        <svg class="svg" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#flip-vertical"></use>
        </svg>
      </button>

      <button class="image-crop-action-button" (click)="resetCropper()" mat-flat-button>
        Reset
      </button>
    </div>

    <div *ngIf="!isAnimatedGif" class="cropper-container">
      <image-cropper
        #imageCropper
        [(transform)]="transform"
        [imageFile]="imageFile"
        [imageBase64]="imageBase64"
        [containWithinAspectRatio]="maintainAspectRatio"
        [maintainAspectRatio]="maintainAspectRatio"
        [aspectRatio]="aspectRatio"
        [roundCropper]="roundCropper"
        [resizeToWidth]="resizeToWidth"
        [resizeToHeight]="resizeToHeight"
        [allowMoveImage]="true"
        [backgroundColor]="backgroundColor"
        [format]="imageFormat"
        (imageLoaded)="imageLoaded()"
        (imageCropped)="updateCroppedImage($event)">
      </image-cropper>
    </div>
    
    <div *ngIf="isAnimatedGif" class="animated-gif-container">
      <img [src]="imageBase64" alt="Animated GIF" />
    </div>

    <div class="image-crop-dialog__footer">
      <div class="image-crop-dialog__button-container">
        <button
          color="accent"
          mat-flat-button
          class="image-crop-dialog__cancel-btn uppercase"
          title="Cancel"
          (click)="cancelCrop()">{{ 'translations.global.button.cancel' | transloco }}
        </button>
        <button
          color="primary"
          mat-flat-button
          class="image-crop-dialog__add-btn uppercase"
          title="Apply"
          (click)="saveCrop()">{{ 'translations.global.button.apply' | transloco }}
        </button>
      </div>
    </div>

    <div class="image-loader-spinner"
         *ngIf="isImageLoading">
      <mat-spinner
        [diameter]="40">
      </mat-spinner>
    </div>
  </div>
</div>
