<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="add-question__section add-question__section_info editor-box"
     [class.editor-box]="newVersionEnabled">
  <span class="cdk-visually-hidden" #addInfo>{{ 'translations.addInfo' | transloco }}</span>
  <ptl-editor *ngIf="!newVersionEnabled"
              class="add-question__section__rich"
              [link]="false"
              [upload]="false"
              [separator]="false"
              [placeholder]="addInfo.innerHTML"
              [content]="[content]"
              (contentUpdate)="onMediumEditorUpdate($event)">
  </ptl-editor>
</div>
