import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToArray'
})
export class StringToArrayPipe implements PipeTransform {

  transform(value: string, delimiter: string = ','): string[] {
    if (!value) {
      return [];
    }
    // Split the string by the provided delimiter and trim any extra spaces
    return value.split(delimiter).map(item => item.trim());
  }

}
