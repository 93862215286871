/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CloseMobileNavMenu } from '../../../app-frame/store/app-frame.actions';
import { AppFrameState } from '../../../app-frame/store/app-frame.state';
import { ADMIN_DATA_SERVICE, AdminDataService } from '../../../page-modules/admin/services/data.service';
import { RoleFilterTypes } from '../../../page-modules/explore/models';
import { ExploreState } from '../../../page-modules/explore/store/explore.state';
import { CreatePage } from '../../../page-modules/pages/store/pages.actions';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { AddPageTemplateComponent } from '../../components/add-page-template/add-page-template.component';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';
import { NewSectionsType, PageTemplateType } from '../../models/pages/page-section.model';
import { SidebarCard, SidebarSection } from '../../models/sidebar-tree/sidebar-tree.model';
import { TranslationService } from '../../services/translation/translation.service';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { Organization } from '../../models';

@Component({
  selector: 'ptl-side-nav-tree',
  templateUrl: './side-nav-tree-section.component.html',
  styleUrls: ['./side-nav-tree-section.component.scss'],
})
export class SideNavTreeSectionComponent implements OnInit, OnChanges, OnDestroy {

  @Select(AppFrameState.isMobile)
  isMobile$: Observable<boolean>;

  @Select(AppFrameState.isMobileMenuExpanded)
  mobileMenuExpanded$: Observable<boolean>;

  @Select(ExploreState.roleFilters)
  roleFilters$: Observable<RoleFilterTypes[]>;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @Input() sections: SidebarSection[];

  @Input() stackView: boolean;

  @Input() pageName: string;

  @Input() cardContentLoaded: { [key: string]: boolean };

  @Input() initialLoadCompleted = true;

  @Input() actionButtonEnabled = false;

  @Input() actionButtonText: string;

  @Input() isExploreSideNav: boolean;

  @Output() sidebarItemClicked = new EventEmitter<{ card: SidebarCard; level: number }>();

  @Output() loadItems: EventEmitter<string> = new EventEmitter<string>();

  @Output() actionButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output() exploreRoleFilterChanged: EventEmitter<RoleFilterTypes> = new EventEmitter<RoleFilterTypes>();

  private mobileMenuExpandedSubscription: Subscription;
  private mobileSubscription: Subscription;
  private exploreRoleFiltersSubscription: Subscription;

  isMobile = false
  exploreRoleFilters: RoleFilterTypes[];

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private activeRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private translationService: TranslationService,
    @Inject(ADMIN_DATA_SERVICE) private dataService: AdminDataService ) {
  }

  ngOnInit(): void {
    if ( this.sections ) {
      this.translateSideNavSection(this.sections);
    }

    this.mobileMenuExpandedSubscription = this.mobileMenuExpanded$.subscribe(( data ) => {
      if ( data ) {
        this.focusActiveItem()
      }
    });

    this.mobileSubscription = this.isMobile$.subscribe(( data ) => {
      this.isMobile = data;
    });

    this.exploreRoleFiltersSubscription = this.roleFilters$.subscribe((data) => {
      this.exploreRoleFilters = data;
    })
  }

  isRoleFilterChecked(roleFilter: RoleFilterTypes): boolean {
    return this.exploreRoleFilters.findIndex(it => it === roleFilter) !== -1;
  }

  loadSectionItems( sectionName: string ) {
    this.loadItems.emit(sectionName);
  }

  navigateTo( uri: string, titleId: string ): void {
    if ( titleId === 'addNewPage' ) {
      this.openSelectPageTemplate();
      return;
    }

    if ( uri ) {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activeRoute, uri);
    }

    if ( this.isMobile ) {
      setTimeout(() => {
        this.store.dispatch(new CloseMobileNavMenu());
      })
    }
  }

  isActiveRoute( route: string[] ): boolean {
    return route.some(( path: string ) => path === this.router.url);
  }

  ngOnDestroy(): void {
    this.mobileMenuExpandedSubscription?.unsubscribe();
    this.mobileSubscription?.unsubscribe();
    this.exploreRoleFiltersSubscription?.unsubscribe();
  }

  focusActiveItem() {
    setTimeout(() => {
      const isHideSideBar = document.querySelector('.f_hide-sidebar');
      const element = document.querySelector('.f_sidebar-static-item.is_active') as HTMLElement;
      if ( isHideSideBar && element ) {
        element?.focus();
      }
    }, 500)
  }

  onSideBarItemClicked( data: { card: SidebarCard; level: number } ) {
    this.sidebarItemClicked.emit(data);
  }

  ngOnChanges( changes: SimpleChanges ) {
    if ( this.cardContentLoaded ) {
      this.cardContentLoaded.saved = !!changes?.cardContentLoaded?.currentValue?.saved;
      this.cardContentLoaded.editOrManage = !!changes?.cardContentLoaded?.currentValue?.editOrManage;
    }
    if(changes.sections && changes.sections.currentValue && !changes.sections.firstChange) {
      this.translateSideNavSection(this.sections);
    }
  }

  trackByFn(index: number) {
    return index;
  }


  onActionButtonClick() {
    this.actionButtonClicked.emit();
  }

  onRoleFilterChecked(event: MouseEvent, roleFilter: RoleFilterTypes) {
    event.preventDefault();

    this.exploreRoleFilterChanged.emit(roleFilter);
  }

  private openSelectPageTemplate() {
    const dialogRef = this.dialog.open(AddPageTemplateComponent, {
      maxWidth: '56.25rem',
      width: '100%',
      disableClose: true,
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: ['ptl-mat-dialog', 'add-page-new-content-dialog'],
      backdropClass: 'dialog-backdrop',
      data: {
        addType: 'template'
      }
    });
    const dialogSubscription = dialogRef.afterClosed().subscribe(( data ) => {
      dialogSubscription.unsubscribe();
      if ( data ) {
        this.handleAddingPagesSections(data);
      }
    });
  }

  private handleAddingPagesSections( sectionType: NewSectionsType ) {
    const request = {
      type: 'STANDARD_PAGE',
      headline: 'New Page',
      template: this.getTemplateType(sectionType)
    };

    this.store.dispatch(new CreatePage(request));
  }

  private getTemplateType( sectionType: NewSectionsType ): PageTemplateType {
    switch ( sectionType ) {
      case 'ICBP':
        return 'IMAGE_CONTENT_FEATURED';
      case 'HP':
        return 'HEADLINE_FEATURED';
      case 'IP':
        return 'IMAGE_FEATURED';
      case 'CBP':
        return 'CONTENT_FEATURED';
      case 'CP':
        return 'CALLOUT_FEATURED';
      case 'CNP':
        return 'CALLOUT_FEATURED';
      default :
        return undefined;
    }
  }

  private translateSideNavSection( sections: SidebarSection[] ) {
    sections.map(section => {
      if ( section.titleId ) {
        section.title = this.translationService.getSideNavSectionTranslation(section.titleId, section.title);
      }
      if ( section.staticLinks ) {
        section.staticLinks.map(item => {
          if ( item.titleId ) {
            item.title = this.translationService.getSideNavSectionTranslation(item.titleId, item.title);
          }
        });
      }
    });
  }
}
