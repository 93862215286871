import { Injectable } from '@angular/core';

export const ACCESS_TOKEN_STORE_KEY = 'auth_access_token';

@Injectable()
export class AccessTokenService {

  saveAccessToken(accessToken: string): void {
    localStorage.setItem(ACCESS_TOKEN_STORE_KEY, accessToken);
  }

  getAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_STORE_KEY);
  }

  removeAccessToken(): void {
    localStorage.removeItem(ACCESS_TOKEN_STORE_KEY);
  }
}
