/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';

@Component({
  selector: 'ptl-classof-auth',
  templateUrl: './classof-auth.component.html',
  styleUrls: ['./classof-auth.component.scss'],

})
export class ClassofAuthComponent  {

  showMaintenanceOverlay: boolean;

  constructor( private maintenanceService: MaintenanceService ) {
      this.showMaintenanceOverlay = this.maintenanceService.showMaintenanceMessage();
  }

}
