import {Directive, Input, TemplateRef, HostListener, OnInit, ElementRef, ComponentRef, OnDestroy} from '@angular/core';
import {
  OverlayRef,
  Overlay,
  OverlayPositionBuilder
} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {TooltipComponent} from '../components/tooltip/tooltip.component';

@Directive({selector: '[ogAppTooltip]'})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('ogAppTooltip') content: string | TemplateRef<HTMLElement>;
  private overlayRef: OverlayRef;
  private showTooltip;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top'
          }
        ]);

      this.overlayRef = this.overlay.create({positionStrategy});
    }, 1000)
  }

  ngOnDestroy() {
    this.hide();
  }

  @HostListener('mousewheel')
  onScroll() {
    this.hide();
  }

  @HostListener('mouseenter')
  show() {
    this.showTooltip = setTimeout(() => {
      const tooltipPortal = new ComponentPortal(TooltipComponent);

      const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(
        tooltipPortal
      );
      if (typeof (this.content) === 'string') {
        tooltipRef.instance.text = this.content;
      } else {
        tooltipRef.instance.content = this.content;
      }
    }, 1000);

    if (!this.content) {
      this.hide();
    }
  }

  @HostListener('mouseleave')
  hide() {
    clearTimeout(this.showTooltip);
    this.overlayRef?.detach();
  }
}
