/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ObservableResult } from '../../../shared/store';
import { EventCardsDataService } from './data.service';
import { Resource } from '../../../shared/models';
import {
  EventCardTime,
  EventCardTypeRequest,
  EventReminderRequest,
  EventTickets,
  EventTicketsRequest
} from '../../resource/store/admin/resource-event-admin.state.model';
import { EventTicketAdminParticipateRequest, EventTicketAdminRegisterRequest } from 'src/app/shared/models/admin/members.model';
import { Observable } from 'rxjs';

@Injectable()
export class MockEventCardsDataService implements EventCardsDataService {

  setEventCardTypes(eventUid: string, request: EventCardTypeRequest): ObservableResult<EventCardTypeRequest> {
    return ObservableResult.ofSuccess();
  }

  setEventCardDates(eventUid: string, request: EventCardTime): ObservableResult<Resource> {
    return ObservableResult.ofSuccess();
  }

  setEventTickets(eventUid: string, request: EventTicketsRequest): ObservableResult<EventTickets> {
    return ObservableResult.ofSuccess();
  }

  deleteEventTickets(eventUid: string): ObservableResult<EventTickets> {
    return ObservableResult.ofSuccess();
  }

  setEventReminders(eventUid: string, request: EventReminderRequest): ObservableResult<Resource> {
    return ObservableResult.ofSuccess();
  }

  registerToEvent(eventUid: string): ObservableResult<Resource> {
    return ObservableResult.ofSuccess();
  }

  unregisterFromEvent(eventUid: string): ObservableResult<Resource> {
    return ObservableResult.ofSuccess();
  }

  attendToEvent(eventUid: string): ObservableResult<Resource> {
    return ObservableResult.ofSuccess();
  }

  attendToEventFromQrCode(eventUid: string, participationCode: string): ObservableResult<Resource> {
    return ObservableResult.ofSuccess();
  }

  adminRegisterToEventBulk(eventUid: string, request: EventTicketAdminRegisterRequest): ObservableResult<EventTickets> {
    return ObservableResult.ofSuccess();
  }

  adminUnregisterFromEventBulk(eventUid: string, usersUids: string[]): ObservableResult<EventTickets> {
    return ObservableResult.ofSuccess();
  }

  adminAttendToEventBulk(eventUid: string, request: EventTicketAdminParticipateRequest): ObservableResult<EventTickets> {
    return ObservableResult.ofSuccess();
  }

  adminMoveToWaitingListEventBulk(eventUid: string, usersUids: string[]): ObservableResult<EventTickets> {
    return ObservableResult.ofSuccess();
  }

  exportEventCalendarEvent(eventUid: string, languageCode?: string): Observable<string> {
    return Observable.create();
  }
}
