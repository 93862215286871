/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';

@Component({
  templateUrl: './lti-registration-error.component.html',
  styleUrls: ['./lti-registration-error.component.scss'],
})
export class LtiRegistrationErrorComponent {

}
