/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { Page as Pagination } from '../../models/page';
import {
  UpcomingEventCardV1,
  UpcomingEventCardV2,
  UpcomingEventFilter,
  UpcomingEventsTag
} from '../../models/event-card/event-card.model';
import { SharedUpcomingDataService } from './upcoming-data.service';
import { delay } from 'rxjs/operators';

@Injectable()
export class MockSharedUpcomingDataService implements SharedUpcomingDataService {

  private upcomingEvents: UpcomingEventCardV1[] = [
    {
      uid: 'FW8QSCVtIliUyXqM',
      headline: 'werewwerwerewrwerwe',
      cardUri: 'FW8QSCVtIliUyXqM',
      imageUrl: 'https://static.yuna.dev.potential.ly/public/open/1l8ijFYL77QWEEL1/resources' +
        '/FW8QSCVtIliUyXqM/thumbnails/1599124625250_original.jpeg',
      playlistUid: 'kqXVdgfqabLoNPjn',
      playlistUri: 'bbbbbbbbbb',
      playlistTitle: 'ma playlist1',
      authorDetails: {
        uid: '283d2e08-95c2-4f78-80f8-b93c6af1a865',
        name: 'Josephinaa Johns-harris-smiths',
        userImageUrl: 'https://static.yuna.dev.potential.ly/public/open/1l8ijFYL77QWEEL1' +
          '/user_images/NW8JjIHAlsTzU9nA/1595779934621_original.jpeg',
        organization: 'Potentially',
        orgLogoUrl: 'https://static.yuna.dev.potential.ly/public/open/1l8ijFYL77QWEEL1/1596563974996_original.png',
        displayConfig: {
          authorName: false,
          organizationName: true,
          externalAuthor: {
            name: 'dsfsdfs',
            image: 'https://static.yuna.dev.potential.ly/public/open/1l8ijFYL77QWEEL1' +
              '/playlists/kqXVdgfqabLoNPjn/authors/1594414070333_original.png',
          },
        },
      },
      likes: { vote: false, totalLikes: 0 },
      completed: false,
      time: { start: '2020-09-09 12:00', end: '2020-09-21 22:00', timeZone: 'GMT+04:00' },
      location: 'undefined',
      status: {
        type: 'NEW',
        on: '2020-08-27T15:33:23.67',
        by: '283d2e08-95c2-4f78-80f8-b93c6af1a865',
      },
      formattedUri: '',
      permission: {
        canEdit: true,
        canViewAnalytics: true,
        canClone: true,
        canDoMemberExports: true,
        canEditBadge: true,
        canEditGoal: true,
        canManageTargeting: true,
        canPublish: true,
      },
      groupUri: 'undefined',
    }, {
      uid: 'dXMgP63h9ndp5I03',
      headline: 'dsadsdas',
      cardUri: 'dXMgP63h9ndp5I03',
      imageUrl: 'https://static.yuna.dev.potential.ly/public/open/1l8ijFYL77QWEEL1/resources' +
        '/dXMgP63h9ndp5I03/thumbnails/1598877917052_original.jpeg',
      playlistUid: '97TBPAYKf6X3MEX3',
      playlistUri: 'career-readiness-i---explore',
      playlistTitle: 'Career Readiness I - Explore see',
      authorDetails: {
        uid: '283d2e08-95c2-4f78-80f8-b93c6af1a865',
        name: 'Josephinaa Johns-harris-smiths',
        userImageUrl: 'https://static.yuna.dev.potential.ly/public/open/1l8ijFYL77QWEEL1' +
          '/user_images/NW8JjIHAlsTzU9nA/1595779934621_original.jpeg',
        organization: 'Potentially',
        orgLogoUrl: 'https://static.yuna.dev.potential.ly/public/open/1l8ijFYL77QWEEL1/1596563974996_original.png',
        displayConfig: {
          authorName: true,
          organizationName: true,
          externalAuthor: {
            name: undefined,
            image: 'https://static.yuna.dev.potential.ly/public/open/1l8ijFYL77QWEEL1' +
              '/playlists/97TBPAYKf6X3MEX3/authors/1595607373356_original.png',
          },
        },
      },
      likes: { vote: true, totalLikes: 1 },
      completed: true,
      time: { start: '2020-10-08 06:25', end: '2020-10-14 06:30', timeZone: 'GMT+02:00' },
      location: 'rettertet fgdgdfgfdgfsdgfsd werwer',
      status: {
        type: 'PUBLISHED',
        on: '2020-08-18T13:31:02.967',
        by: '283d2e08-95c2-4f78-80f8-b93c6af1a865',
      },
      formattedUri: '',
      permission: {
        canEdit: true,
        canViewAnalytics: true,
        canClone: true,
        canDoMemberExports: true,
        canEditBadge: true,
        canEditGoal: true,
        canManageTargeting: true,
        canPublish: true,
      },
      groupUri: 'Tt9rB3t9Ob6PogFH',
    },
  ];

  private upcomingEventsContent = {
    content: this.upcomingEvents,
    totalNumberOfElement: 2,
    pageSize: 8,
    currentPageNumber: 1,
    hasContent: true,
    numberOfPages: 1,
  };

  private  upcomingEventsTagsContent = {
    content: [],
    totalNumberOfElement: 0,
    pageSize: 1,
    currentPageNumber: 1,
    hasContent: false,
    numberOfPages: 1,
  };

  getUpcomingEvents(page: number, size: number, frameworkId?: string, tagId?: string): ObservableResult<Pagination<UpcomingEventCardV1>> {
    return ObservableResult.ofSuccess(this.upcomingEventsContent).pipe(delay(1500));
  }

  getUpcomingEventsByDate(startDate: string, endDate: string): ObservableResult<UpcomingEventCardV1[]> {
    return ObservableResult.ofSuccess(this.upcomingEvents).pipe(delay(1500));
  }

  getUpcomingEventsBySkills(tags: string[], startDate?: string, endDate?: string): ObservableResult<Pagination<UpcomingEventCardV1>> {
    return ObservableResult.ofSuccess(this.upcomingEventsContent).pipe(delay(1500));
  }

  getUpcomingEventsTags(page: number, size: number, term?: string): ObservableResult<Pagination<UpcomingEventsTag>> {
    return ObservableResult.ofSuccess(this.upcomingEventsTagsContent).pipe(delay(1500));
  }

  hasUpcomingEvents(): ObservableResult<boolean> {
    return ObservableResult.ofSuccess(true);
  }

  getUpcomingEventsByFilter(page: number, size: number, filter: UpcomingEventFilter): ObservableResult<Pagination<UpcomingEventCardV2>> {
    return ObservableResult.ofSuccess();
  }

  getEventsEngagements(page: number, size: number, filter: UpcomingEventFilter): ObservableResult<Pagination<UpcomingEventCardV2>> {
    return ObservableResult.ofSuccess();
  }
}
