import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { VersionHelper } from '../../../../shared/helpers/version.helper';

@Component({
  selector: 'ptl-medium-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss'],
})
export class SeparatorComponent {

  @Input() canRemoveItem = true;

  /** Emits separator removing event */
  @Output() removeSeparator = new EventEmitter<void>();

  newVersionEnabled = VersionHelper.newVersionEnabled();

  showToolbar = false;

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const targetElement = event.target as Element;
    if (!targetElement.closest('.f_separator-toolbar') && !targetElement.closest('.f_separator-content')) {
      this.showToolbar = false;
    }
  }

  onRemoveSeparator(event: MouseEvent) {
    event?.stopPropagation();
    this.removeSeparator.emit();
  }
}
