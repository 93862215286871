/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { ObservableResult } from '../../store';
import { HelpCenterDataService } from './help-center-data.service';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { Page as Pagination } from '../../models/page';
import { TranslationService } from '../translation/translation.service';
import { PlaylistCardShort } from '../../models';

@Injectable()
export class ApiHelpCenterDataService implements HelpCenterDataService {

  constructor( private client: RestClientService, private translationService: TranslationService ) {
  }

  getHelpCenter( page: number, size: number ):
    ObservableResult<Pagination<PlaylistCardShort>> {
    const params = {
      page: page.toString(),
      size: size.toString(),
    };
    return this.client.get<Pagination<PlaylistCardShort>>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/help-center'),
      params
    ).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorLoadingHelpCenterData')))
    );
  }

  getHelpCenterTopics( page: number, size: number, tagId?: string, term?: string ):
    ObservableResult<Pagination<PlaylistCardShort>> {
    const params = {
      page: page.toString(),
      size: size.toString(),
      topics: tagId
    };
    if ( tagId && term ) {
      params['term'] = term;
    }
    return this.client.get<Pagination<PlaylistCardShort>>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/help-center/for'),
      params
    ).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorLoadingHelpCenterData')))
    );
  }

}
