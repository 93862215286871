<!--
  ~ Copyright (C) 2021 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="categories-view__header" *ngIf="title">
  <ng-container *ngIf="!isV2"><h1 [attr.aria-label]="title">{{ title }}</h1></ng-container>
  <ng-container *ngIf="isV2">
    <h1 [attr.aria-label]="'translations.global.ariaLabels.popularTopics' | transloco">
      {{ 'translations.popularTopics' | transloco }}</h1>
    <div class="categories-view__filter">
      <span class="filter-item">{{ 'translations.upcomingEvents.filter.button.lastWeek' | transloco }}</span> |
      <span class="filter-item active">{{ 'translations.upcomingEvents.filter.button.allTime' | transloco }}</span>
    </div>
  </ng-container>
</div>
<div class="categories-view__content">
  <div class="categories-view__item-container" role="list" *ngFor="let category of section?.categories">
    <mat-card
      role="listitem"
      class="categories-view__item"
      [title]="category.title"
      [class.card-b]="category.layout === 'CARD_B' && category.image"
      [class.text-only]="category.layout === 'TEXT' || !category.image"
      routerLink="{{ (isContentStore ? '/content-store' : discoveryPageUrl) }}"
      [queryParams]="{uri: category.uri, frameworkId: category.frameworkId, tagId: category.tagId}">

      <img
        *ngIf="category.layout !== 'TEXT' && category.image"
        class="categories-view__item-image"
        alt="" width="225" height="70"
        [src]="category.image">

      <span class="categories-view__item-info">{{category.title}}</span>
    </mat-card>
  </div>
</div>
