/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { CollectorDataService } from './collector-data.service';
import { ObservableResult } from '../../store';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import {
  Collector,
  CollectorUpdateRequest,
  LearnerCollectorSummary,
  LearnerLoggedItemRequest,
  LearnerLoggedItemSummary
} from '../../models/editor/collector-content.model';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';
import { SubmitPlaylistSummary } from '../../models/playlist/playlist-report.model';

@Injectable()
export class ApiCollectorDataService implements CollectorDataService {

  constructor(private client: RestClientService) {
  }

  updateCollector(cardUid: string, collectorUid: string, request: CollectorUpdateRequest, languageCode?: string)
    : ObservableResult<Collector> {
    return this.client.patch<Collector>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/collectors/${collectorUid}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error updating collector'))
    );
  }

  getCollector(cardUid: string, collectorUid: string, languageCode?: string): ObservableResult<Collector> {
    return this.client.get<Collector>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/collectors/${collectorUid}`),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error getting collector'))
    );
  }

  deleteCollector(cardUid: string, collectorUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/collectors/${collectorUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error deleting collector'))
    );
  }

  getLearnerCollectorSummary(playlistUid: string, collectorUid: string, languageCode?: string)
    : ObservableResult<LearnerCollectorSummary> {
    return this.client.get<LearnerCollectorSummary>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/collectors/${collectorUid}/summary`),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error getting learner collector summary'))
    );
  }

  getLearnerCollectorSummaryAsReviewer(playlistUid: string, collectorUid: string, reviewUid: string)
    : ObservableResult<LearnerCollectorSummary> {
    return this.client.get<LearnerCollectorSummary>(
      Location.joinWithSlash(environment.apiRootUrl || '',
      `playlists/${playlistUid}/collectors/${collectorUid}/summary/reviews/${reviewUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error getting learner collector summary'))
    );
  }

  addLogItem(learnerCollectorUid: string, request: LearnerLoggedItemRequest, languageCode?: string)
    : ObservableResult<LearnerLoggedItemSummary> {
    return this.client.post<LearnerLoggedItemSummary>(
      Location.joinWithSlash(environment.apiRootUrl || '', `collectors/evidences/users/${learnerCollectorUid}/evidences`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error adding log item'))
    );
  }

  deleteLogItem(learnerCollectorUid: string, learnerEvidenceUid: string): ObservableResult<LearnerLoggedItemSummary> {
    return this.client.delete<LearnerLoggedItemSummary>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `collectors/users/${learnerCollectorUid}/evidences/${learnerEvidenceUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error deleting log item'))
    );
  }

  updateLogItem(
    learnerCollectorUid: string,
    learnerEvidenceUid: string,
    request: LearnerLoggedItemRequest,
    languageCode?: string): ObservableResult<LearnerLoggedItemSummary> {
    return this.client.patch<LearnerLoggedItemSummary>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `collectors/users/${learnerCollectorUid}/evidences/${learnerEvidenceUid}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error updating log item'))
    );
  }

  submitLearnerCollectorSummary(playlistUid:string, learnerCollectorUid: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `collectors/users/${learnerCollectorUid}/submit?playlistUid=${playlistUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error submitting learning collector summary'))
    );
  }

  submitLearnerCollectorSummaryAndPlaylist(
    playlistUid: string,
    learnerCollectorUid: string,
    request: SubmitPlaylistSummary
  ): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `collectors/users/${learnerCollectorUid}/submit?playlistUid=${playlistUid}`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error submitting learning collector summary'))
    );
  }
}
