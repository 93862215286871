/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../shared/store';
import { LinkablePlaylistSummary, SearchPlaylist } from '../../../shared/models';
import { Injectable } from '@angular/core';
import { createDummySearchPlaylist } from '../../mock-utils';
import { Page } from '../../../shared/models/page';
import { GlobalSearchDataService } from './global-search-data.service';
import { GlobalSearch, GlobalSearchPlaylist } from '../../../shared/models/global-search/global-search.model';
import { SearchExploreRequest } from '@app/app/shared/models/page/page.model';

/* eslint-disable max-len */
@Injectable()
export class MockGlobalSearchDataService implements GlobalSearchDataService {


  searchContent(page: number, size: number, term: string): ObservableResult<Page<GlobalSearch>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: [],
    });
  }

  searchExplore(page: number, size: number, request: SearchExploreRequest): ObservableResult<Page<GlobalSearchPlaylist>> {
    return ObservableResult.ofSuccess();
  }

  searchExploreDynamicSection(sectionName: string, page: number, size: number, request: SearchExploreRequest):
    ObservableResult<Page<GlobalSearchPlaylist>>  {
    return ObservableResult.ofSuccess();
  }

  searchPlaylists(page: number): ObservableResult<Page<SearchPlaylist>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: 1,
      totalNumberOfElement: 1,
      content: [createDummySearchPlaylist('id1')],
    });
  }

  searchLinkablePlaylists(page: number, term: string, excludedId?: string, pageSize: number = 10):
    ObservableResult<Page<LinkablePlaylistSummary>> {
    return ObservableResult.ofSuccess({
      currentPageNumber: 0,
      pageSize: pageSize,
      totalNumberOfElement: 1,
      content: [{header: term, id: excludedId + '0'}],
    });
  }
}
