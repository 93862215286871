/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ImportUsersService } from './import-users.service';
import { ObservableResult } from 'src/app/shared/store';

@Injectable()
export class MockImportUsersService implements ImportUsersService {

  initializeLearnerCardForUsers(playlistUid: string, cardUid: string, usersUid: string[]): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  initializeLearnerPlaylistForUsers(playlistUid: string, usersUid: string[]): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

}