/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Inject, Injectable, NgZone } from '@angular/core';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Language,
  OrganizationLanguageSummary,
  SupportedLanguage
} from '../../../../shared/models/languages/languages.model';
import {
  LANGUAGES_DATA_SERVICE,
  LanguagesDataService
} from '../../../../shared/services/languages/languages-data.service';
import { TranslationService } from '../../../../shared/services/translation/translation.service';
import * as LanguagesActions from '../languages/languages.actions';
import { SnackbarHelper } from '../../../../shared/helpers/snackbar-helper';

/** The framework state definition. */
export interface LanguagesStateModel {
  languages: Language[];
  activeLanguages: Language[];
  organizationLanguageSummary: OrganizationLanguageSummary;
}

@State<LanguagesStateModel>({
  name: 'languages',
  defaults: {
    languages: [],
    activeLanguages: [],
    organizationLanguageSummary: undefined,
  },
})
@Injectable()
export class LanguagesState {
  @Selector()
  static languages( { languages }: LanguagesStateModel ): Language[] {
    return languages;
  }

  @Selector()
  static organizationLanguageSummary( { organizationLanguageSummary }: LanguagesStateModel ): OrganizationLanguageSummary {
    return organizationLanguageSummary;
  }

  @Selector()
  static organizationSupportedLanguages( { organizationLanguageSummary }: LanguagesStateModel ): SupportedLanguage[] {
    if(organizationLanguageSummary) {
      return organizationLanguageSummary.supportedLanguages
    }
    return [];
  }

  constructor(
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    @Inject(LANGUAGES_DATA_SERVICE) private languagesDataService: LanguagesDataService,
    private translationService: TranslationService
  ) {
  }


  @Action(LanguagesActions.LoadLanguages)
  loadLanguages(
    { patchState }: StateContext<LanguagesStateModel>
  ) {

    patchState({
      languages: undefined,
    });

    return this.languagesDataService.getLanguages().pipe(
      tap(( { isSuccess, value } ) => {
          if(isSuccess) {
            patchState({
              languages: value,
            });
          } else {
            patchState({
              languages: undefined,
            });
          }
        }
      ));
  }


  @Action(LanguagesActions.LoadOrganizationLanguages)
  loadOrganizationLanguages(
    { patchState }: StateContext<LanguagesStateModel>,
    { organizationId }: LanguagesActions.LoadOrganizationLanguages,
  ) {

    patchState({
      organizationLanguageSummary: undefined,
    });

    return this.languagesDataService.getOrganizationLanguages(organizationId).pipe(
      tap(( { isSuccess, value, error } ) => {
          if(isSuccess) {
            patchState({
              organizationLanguageSummary: value,
            });
          } else {
            patchState({
              organizationLanguageSummary: undefined,
            });
          }
        }
      ));
  }

  @Action(LanguagesActions.LoadOrganizationActiveLanguages)
  loadOrganizationActiveLanguages(
    { patchState }: StateContext<LanguagesStateModel>,
    { organizationId }: LanguagesActions.LoadOrganizationActiveLanguages,
  ) {

    patchState({
      activeLanguages: [],
    });

    return this.languagesDataService.getActiveOrganizationLanguages(organizationId).pipe(
      tap(( { isSuccess, value, error } ) => {
          if(isSuccess) {
            patchState({
              activeLanguages: value,
            });
          } else {
            patchState({
              activeLanguages: undefined,
            });
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        }
      ));
  }

  @Action(LanguagesActions.CreateOrganizationLanguages)
  createOrganizationLanguages(
    { patchState }: StateContext<LanguagesStateModel>,
    { organizationId, code }: LanguagesActions.CreateOrganizationLanguages,
  ) {

    return this.languagesDataService.createOrganizationLanguages(organizationId, code).pipe(
      tap(( { isSuccess, value, error } ) => {
          if(isSuccess) {
            patchState({
              organizationLanguageSummary: value,
            });
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'language.message.success.addedToOrganization'
            );
          } else {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        }
      ));
  }

  @Action(LanguagesActions.RemoveOrganizationLanguages)
  removeOrganizationLanguages(
    { patchState, getState }: StateContext<LanguagesStateModel>,
    { organizationId, code }: LanguagesActions.RemoveOrganizationLanguages,
  ) {

    const languageSummary = getState().organizationLanguageSummary;
    return this.languagesDataService.deleteOrganizationLanguages(organizationId, code).pipe(
      tap(( { isSuccess, error } ) => {
          if(isSuccess) {
            patchState({
              organizationLanguageSummary: {
                ...languageSummary,
                supportedLanguages: languageSummary.supportedLanguages.filter(it => it.language.code !== code)
              },
            });
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'language.message.success.removedFromOrganization'
            );
          } else {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        }
      ));
  }

  @Action(LanguagesActions.ActivateOrganizationLanguages)
  activateOrganizationLanguages(
    { patchState, getState }: StateContext<LanguagesStateModel>,
    { organizationId, code, isActive }: LanguagesActions.ActivateOrganizationLanguages,
  ) {

    const languageSummary = getState().organizationLanguageSummary;
    return this.languagesDataService.languageActivation(organizationId, code, isActive).pipe(
      tap(( { isSuccess, error } ) => {
          if(isSuccess) {
            patchState({
              organizationLanguageSummary: {
                ...languageSummary,
                supportedLanguages: languageSummary.supportedLanguages.map(item => {
                  if(item.language.code === code) {
                    item.isActive = isActive;
                  }
                  return item
                })
              },
            });
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService,
              isActive ? 'language.message.success.activeLanguage' : 'language.message.success.deactiveLanguage'
            );
          } else {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        }
      ));
  }


  @Action(LanguagesActions.SetDefaultOrganizationLanguages)
  setDefaultOrganizationLanguages(
    { patchState, getState }: StateContext<LanguagesStateModel>,
    { organizationId, code }: LanguagesActions.SetDefaultOrganizationLanguages,
  ) {

    const languageSummary = getState().organizationLanguageSummary;
    return this.languagesDataService.setDefaultLanguage(organizationId, code).pipe(
      tap(( { isSuccess, error } ) => {
          if(isSuccess) {
            patchState({
              organizationLanguageSummary: {
                ...languageSummary,
                defaultLanguage: languageSummary.supportedLanguages.find(it => it.language.code === code).language,
                supportedLanguages: languageSummary.supportedLanguages.map(item => {
                  if(item.language.code === code) {
                    item.isDefault = true;
                  }
                  return item
                })
              },
            });
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'language.message.success.setAsDefault'
            );
          } else {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        }
      ));
  }

}
