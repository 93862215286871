/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { HttpErrorResponse } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { EditorTransformer } from '../../../../../../shared/helpers/editor-transformer';
import { ImageUploadNameResolver } from '../../../../../../shared/helpers/image-upload-name-resolver';
import { SnackbarHelper } from '../../../../../../shared/helpers/snackbar-helper';
import { VersionHelper } from '../../../../../../shared/helpers/version.helper';
import {
  Diagnostics,
  MediaUploadData,
  PlaylistMainSection, PlaylistStandardSection,
  Resource,
  ResourceSection
} from '../../../../../../shared/models';
import { FileUploadService } from '../../../../../../shared/services/file-upload/file-upload.service';
import { TranslationService } from '../../../../../../shared/services/translation/translation.service';
import { UpdateSidebarItemChildren } from '../../../../../../shared/side-nav-tree/store/side-nav.actions';
import { LoadableState, ObservableResult, Result } from '../../../../../../shared/store';
import { RedirectHelper } from '../../../../../resource/store/editor/content/helpers/redirect.helper';
import { PlaylistMainSectionRequest, PlaylistUpdateRequest } from '../../../../models';
import { PlaylistResourceModel } from '../../../../models/playlist-resource.model';
import { PlaylistDataService } from '../../../../services/create/core/data.service';
import { CreatePlaylistResponse } from '../../../../services/create/core/models/create-playlist-response';
import { UpdatePlaylistResponse } from '../../../../services/create/core/models/update-playlist-response';
import {
  RedirectToPlaylistEdit,
  RedirectToPlaylistView,
  RedirectToProjectEdit,
  NavigateToEventCardCreation,
  NavigateToResourceCreation,
  RedirectToDiagnosticsCreation } from '../../playlist-creation.actions';
import {
  INITIAL_STATE,
  PlaylistCreationStateModel,
  ValidationError
} from '../../playlist-creation.state.model';
import { ProjectDataService } from './../../../../../project/services/data.service';
import { FolioState } from 'src/app/page-modules/folio/store/folio.state';

export class PlaylistSaveHelper {

  static saveUpdates(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    playlistDataService: PlaylistDataService,
    projectDataService: ProjectDataService,
    store: Store,
    fileUploadService: FileUploadService,
    isProject: boolean,
    folioPublicId: string,
    languageCode?: string,
  ) {
    const playlistContent = state.playlist;

    const validationErrors = this.getValidationErrors(playlistContent);
    if ( validationErrors.length ) {
      return patchState({
        validationErrors: validationErrors,
      });
    } else {
      patchState({
        validationErrors: [],
      });
    }

    if (!playlistContent.data._id) {
      if (isProject) {
        return this.createProject(
          projectDataService,
          playlistContent,
          languageCode
        ).pipe(
          switchMap(({ value, error }) => {
            if ( !error ) {
              const project = value.item;
              const file = playlistContent.data.mainSection.imageToUpload;
              const imageUrl = project.mainSection.imageUrl;
              const uploader = imageUrl
                ? () => {
                  return fileUploadService.uploadFileDirectly(value.uploadUrl, file, '',  true);
                }
                : () => ObservableResult.ofSuccess();

              return this.uploadFilesAndGoToProjectEdit(patchState, store, folioPublicId, project, uploader);
            } else {
              this.handlePlaylistErrorResponse(error, patchState);
              return of(null);
            }
          })
        )
      } else {
        return this.createPlaylist(
          playlistDataService,
          playlistContent,
          state.parentPlaylistDetails?.uid,
          state.parentPlaylistDetails?.sectionUid,
          languageCode
        ).pipe(
          switchMap(( { value, error } ) => {
            if ( !error ) {
              const playlist = value.item;
              const file = playlistContent.data?.mainSection.imageToUpload;
              const imageUrl = playlist.mainSection.imageUrl;
              const newVersionEnabled = VersionHelper.newVersionEnabled();
              if (newVersionEnabled) {
                if (playlistContent.data.pageId) {
                  store.dispatch(new UpdateSidebarItemChildren(playlistContent.data.pageId, false));
                }
              }
              const uploader = imageUrl
                ? () => {
                  return fileUploadService.uploadFileDirectly(value.uploadUrl, file, '', true);
                }
                : () => ObservableResult.ofSuccess();

               return this.uploadFilesAndGoToPlaylistEdit(patchState, store, playlist, uploader);
            } else {
              this.handlePlaylistErrorResponse(error, patchState);
              return of(null);
            }
          })
        );
      }
    } else {
      patchState({
        saveStatus: {
          autoSaveInProgress: false,
          saveInProgress: true,
        },
      });
      return this.updatePlaylist(playlistDataService, playlistContent, languageCode).pipe(
        tap(( { value, error } ) => {
          this.markSavingAsFinished(patchState);
          if ( !error ) {
            const observable = this.createUploadsObservable(playlistContent, value, fileUploadService);
            this.uploadFilesAndGoToPlaylistView(patchState, store, value, () => observable);
          } else {
            this.handlePlaylistErrorResponse(error, patchState);
          }
        })
      );
    }
  }

  static savePlaylistAndRedirectToSubPlaylistCreation(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    playlistDataService: PlaylistDataService,
    store: Store,
    snackBar: MatSnackBar,
    fileUploadService: FileUploadService,
    router: Router,
    ngZone: NgZone,
    activatedRoute: ActivatedRoute,
    translationService: TranslationService,
    sectionIndex: number
  ) {
    const playlistContent = state.playlist;

    return this.updatePlaylist(playlistDataService, playlistContent).pipe(
      tap(( { isSuccess, value, error } ) => {
        this.markSavingAsFinished(patchState);

        const newVersionEnabled = VersionHelper.newVersionEnabled();
        if (newVersionEnabled) {
          store.dispatch(new UpdateSidebarItemChildren(state.playlist.data._id, false));
        }

        if ( isSuccess ) {
          this.createUploadsObservable(playlistContent, value, fileUploadService).toPromise().then(() => {
            const playlist = state.playlist.data
            patchState({
              parentPlaylistDetails: {
                uid: playlist._id,
                uri: playlist.uri,
                sectionUid: sectionIndex === undefined ? playlist.mainSection.uid : playlist.standardSections[sectionIndex].uid,
              },
            });

            RedirectHelper.redirectByUrl(ngZone, router, activatedRoute, 'playlists', { queryParams: { embed: true } });
          });
        } else {
          this.handlePlaylistErrorResponse(error, patchState);
          patchState({
            activePaletteAction: false,
          });
          SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translationService, 'savingPlaylistError');
        }
      })
    );
  }

  static savePlaylistAndCreateCard(
    state: PlaylistCreationStateModel,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    playlistDataService: PlaylistDataService,
    projectDataService: ProjectDataService,
    store: Store,
    snackBar: MatSnackBar,
    ngZone: NgZone,
    translationService: TranslationService,
    action: 'NEW_CARD' | 'DIAGNOSTICS' | 'NEW_EVENT_CARD',
    fileUploadService: FileUploadService,
    sectionIndex?: number
  ): Observable<Result<Resource> | Result<Diagnostics> | undefined> {
    patchState({
      activePaletteAction: true,
    });

    const playlistContent = state.playlist;

    return this.updatePlaylist(playlistDataService, playlistContent).pipe(
      mergeMap(( { value, isSuccess } ) => {
        if ( isSuccess ) {
          this.createUploadsObservable(playlistContent, value, fileUploadService).toPromise().then();
          return this.createCardAndNavigate(
            action,
            playlistContent,
            sectionIndex,
            playlistDataService,
            projectDataService,
            store,
            patchState,
            snackBar,
            ngZone,
            translationService
          );
        } else {
          SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translationService, 'savingPlaylistError');
          patchState({
            activePaletteAction: false,
          });
          return undefined;
        }
      })
    );
  }

  private static createCardAndNavigate(
    action: 'NEW_CARD' | 'DIAGNOSTICS' | 'NEW_EVENT_CARD',
    playlistContent: LoadableState<PlaylistResourceModel>,
    sectionIndex: number,
    playlistDataService: PlaylistDataService,
    projectDataService: ProjectDataService,
    store: Store,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    snackBar: MatSnackBar,
    ngZone: NgZone,
    translationService: TranslationService
  ): Observable<Result<Diagnostics>> | Observable<Result<Resource>> {
    switch ( action ) {
      case 'NEW_CARD':
        return this.createEmptyCardAndNavigateThere(
          playlistContent, sectionIndex, playlistDataService, projectDataService, store, patchState, snackBar, ngZone, translationService
        );
      case 'NEW_EVENT_CARD':
        return this.createEmptyEventCardAndNavigateThere(
          playlistContent, sectionIndex, playlistDataService, store, patchState, snackBar, ngZone, translationService
        );
      case 'DIAGNOSTICS':
        return this.createEmptyAssessmentAndNavigateSomewhere(
          playlistContent, sectionIndex, playlistDataService, store, patchState, snackBar, ngZone, translationService
        );
    }
  }

  static createUploadsObservable(
    playlistContent: LoadableState<PlaylistResourceModel>,
    updatePlaylistResponse: UpdatePlaylistResponse,
    fileUploadService: FileUploadService
  ): Observable<Result<void>[]> {
    const fileUploadObservables: ObservableResult<void>[] = [];
    const coverImageFile = playlistContent.data.mainSection.imageToUpload;
    if (coverImageFile) {
      const coverImageUploadUrl = updatePlaylistResponse.coverImageUploadUrl;
      const coverImageUploadObservable = fileUploadService.uploadFileDirectly(coverImageUploadUrl, coverImageFile, '', true);
      fileUploadObservables.push(coverImageUploadObservable);
    }
    playlistContent.data.standardSections.concat(playlistContent.data.mainSection as PlaylistStandardSection)
      .flatMap(section => section.content)
      .filter(content => content.type === 'MEDIA_UPLOAD' && ( !content.uid || (content as MediaUploadData).file))
      .forEach(( content, index ) => {
        const file = (content as MediaUploadData).file;
        const uploadUrl = updatePlaylistResponse.uploadUrls[index];
        const fileUploadObservable = fileUploadService.uploadFileDirectly(uploadUrl, file, (content as MediaUploadData).uuid4,  false);
        fileUploadObservables.push(fileUploadObservable);
      });
    return forkJoin(fileUploadObservables);
  }

  static checkIfHasFile( playlistContent: LoadableState<PlaylistResourceModel>): boolean {
    const coverImageFile = playlistContent.data.mainSection.imageToUpload;
    if (coverImageFile) {
      return true;
    }
    const allSections = playlistContent.data.standardSections.concat(playlistContent.data.mainSection as PlaylistStandardSection)
    .flatMap(section => section.content)
    .filter(content => content.type === 'MEDIA_UPLOAD' && ( !content.uid || (content as MediaUploadData).file));

    return allSections.length > 0;
  }

  private static createPlaylist(
    playlistDataService: PlaylistDataService,
    playlistContent: LoadableState<PlaylistResourceModel>,
    parentPlaylistId?: string,
    sectionId?: string,
    languageCode?: string
  ): ObservableResult<CreatePlaylistResponse> {
    const mainSection = this.mapToMainSectionRequestDetails(playlistContent.data.mainSection);
    const playlist = playlistContent.data;
    return playlistDataService.createPlaylist({
      mainSection,
      embedded: parentPlaylistId ? { parentPlaylistId, sectionId } : undefined,
      pageId: playlist.pageId ?  playlist.pageId : undefined,
      pageSectionId: playlist.pageSectionId ?  playlist.pageSectionId : undefined,
      targetGroups: playlist.targetGroups ?  playlist.targetGroups : undefined,
      languageCode: languageCode,
    });
  }

  private static createProject(
    projectDataService: ProjectDataService,
    playlistContent: LoadableState<PlaylistResourceModel>,
    languageCode?: string
  ): ObservableResult<CreatePlaylistResponse> {
    const mainSection = this.mapToMainSectionRequestDetails(playlistContent.data.mainSection);
    return projectDataService.createProject({
      mainSection,
      languageCode: languageCode,
    });
  }

  static updatePlaylist(
    playlistDataService: PlaylistDataService,
    playlistContent: LoadableState<PlaylistResourceModel>,
    languageCode?: string
  ): ObservableResult<UpdatePlaylistResponse> {
    const mainSection = this.mapToMainSectionRequestDetails(playlistContent.data.mainSection);
    const request: PlaylistUpdateRequest = {
      mainSection,
      standardSections: playlistContent.data.standardSections.map(section => ({
        ...section,
        content: (section.content && section.content.length) ?
          section.content.map(content => EditorTransformer.transformEditorContentToMatchApiExpectations(content))
          : [],
        cards: (section.cards && section.cards.length) ?
          section.cards.map(card => card._id) : [],
      })),
    }
    return playlistDataService.updatePlaylist(
      playlistContent.data._id,
      request,
      languageCode
    );
  }

  static getPlaylistUpdatedSectionContent( section: PlaylistStandardSection, newSection: PlaylistStandardSection, sectionUid: string ) {
    if ( section.uid === sectionUid ) {
      const editorElements = document.getElementsByClassName('f_editor-element');
      for ( let i = 0; i < newSection.content.length; i++ ) {
        const content = newSection.content[i];

        if ( content.type === 'MEDIA_UPLOAD') {
            // Stream video content needs to be updated from autosave response to get proper retrieval urls
            section.content[i] = content;
        } else if ( content.type === 'PARAGRAPH' ) {
          if ( editorElements.length && editorElements[i] ) {
            const editableContent = editorElements[i].querySelector('.me-editable');
            if ( editableContent ) {
              section.content[i].content = editableContent.innerHTML;
            }
          }
          section.content[i].uid = content.uid;
        } else if ( content.type === 'TABLE' ) {
          section.content[i].uid = content.uid;
        } else {
          // Non stream content uploading ignored to not override content with delayed response from backend
          // section.content[i] = content;
        }
      }
    }
    return section.content;
  }

  private static uploadFilesAndGoToPlaylistView(
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    store: Store,
    updateResponse: Navigable,
    uploader: () => Observable<Result<void>[]>
  ) {
    uploader().toPromise()
      .then(() => {
        this.resetState(patchState);
        store.dispatch(new RedirectToPlaylistView(updateResponse.uri, updateResponse.formattedUri));
      });
  }

  private static uploadFilesAndGoToPlaylistEdit(
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    store: Store,
    updateResponse: Navigable,
    uploader: () => Observable<Result<void>>
  ) {
    return uploader().toPromise()
      .then(() => {
        this.resetState(patchState);
        store.dispatch(new RedirectToPlaylistEdit(updateResponse.uri, updateResponse.formattedUri));
      });
  }

  private static uploadFilesAndGoToProjectEdit(
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    store: Store,
    folioPublicId: string,
    updateResponse: Navigable,
    uploader: () => Observable<Result<void>>
  ) {
    return uploader().toPromise()
      .then(() => {
        this.resetState(patchState);
        store.dispatch(new RedirectToProjectEdit(folioPublicId, updateResponse.uri));
      });
  }

  private static markSavingAsFinished(patchState: (p: Partial<PlaylistCreationStateModel>)
    => Partial<PlaylistCreationStateModel>) {
    patchState({
      saveStatus: {
        autoSaveInProgress: false,
        saveInProgress: false,
      },
    });
  }

  private static getValidationErrors(playlistContent: LoadableState<PlaylistResourceModel>): ValidationError[] {
    const errors = [];
    if (!playlistContent.data.mainSection.title) {
      errors.push('EMPTY_HEADLINE');
    }
    return errors;
  }

  private static mapToMainSectionRequestDetails( mainSection: PlaylistMainSection )
    : PlaylistMainSectionRequest {
    return {
      uid: mainSection.uid,
      title: mainSection.title,
      content: (mainSection.content && mainSection.content.length) ?
        mainSection.content.map(content => EditorTransformer.transformEditorContentToMatchApiExpectations(content))
        : [],
      cardsLayoutType: mainSection.cardsLayoutType,
      layoutType: mainSection.layoutType,
      imageName: this.parseMainSectionImage(mainSection),
      imageContentLength: this.getImageContentLength(mainSection),
      cards: (mainSection.cards && mainSection.cards.length) ?
        mainSection.cards.map(card => card._id) : [],
    };
  }

  private static getImageContentLength( mainSection: PlaylistMainSection ): number | undefined {
    if ( mainSection.imageUrl ) {
      return 0;
    }
    const file = mainSection.imageToUpload;
    return file && file.size;
  }

  private static parseMainSectionImage( mainSection: PlaylistMainSection ): string {
    if ( mainSection.imageToUpload ) {
      return ImageUploadNameResolver.resolveImageName(mainSection.imageToUpload.name);
    } else if ( mainSection.imageUrl ) {
      return mainSection.imageUrl.original;
    } else {
      return null;
    }
  }

  private static createEmptyCardAndNavigateThere(
    playlistContent: LoadableState<PlaylistResourceModel>,
    sectionIndex: number,
    playlistDataService: PlaylistDataService,
    projectDataService: ProjectDataService,
    store: Store,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    snackBar: MatSnackBar,
    ngZone: NgZone,
    translationService: TranslationService,
  ) {
    const sectionUid = sectionIndex === -1 ? playlistContent.data.mainSection.uid : playlistContent.data.standardSections[sectionIndex].uid;
    const newVersionEnabled = VersionHelper.newVersionEnabled();
    const isProject = playlistContent.data.type === 'PROJECT';
    const observable = isProject ?
      projectDataService.createEmptyCard(playlistContent.data._id, sectionUid) :
      playlistDataService.createEmptyCard(playlistContent.data._id, sectionUid);
    const folioState = store.selectSnapshot(FolioState.folioState);

    return observable.pipe(
      tap(res => {
        if (res.isSuccess) {
          if (newVersionEnabled) {
            store.dispatch(new UpdateSidebarItemChildren(playlistContent.data._id, false));
          }
          store.dispatch(new NavigateToResourceCreation(
            res.value.settings.publication.uri,
            res.value.formattedUri,
            isProject,
            folioState.data?.publicId
          ));
        } else {
          this.handleCardCreationError(snackBar, ngZone, translationService, patchState);
        }
      })
    );
  }

  private static createEmptyEventCardAndNavigateThere(
    playlistContent: LoadableState<PlaylistResourceModel>,
    sectionIndex: number,
    dataService: PlaylistDataService,
    store: Store,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    snackBar: MatSnackBar,
    ngZone: NgZone,
    translationService: TranslationService,
  ) {
    const newVersionEnabled = VersionHelper.newVersionEnabled();
    const sectionUid = sectionIndex === -1 ? playlistContent.data.mainSection.uid : playlistContent.data.standardSections[sectionIndex].uid;
    return dataService.createEmptyEventCard(playlistContent.data._id, sectionUid).pipe(
      tap(res => {
        if (res.isSuccess) {
          if (newVersionEnabled) {
            store.dispatch(new UpdateSidebarItemChildren(playlistContent.data._id, false));
          }
          store.dispatch(new NavigateToEventCardCreation(res.value.settings.publication.uri, res.value.formattedUri));
        } else {
          this.handleCardCreationError(snackBar, ngZone, translationService, patchState);
        }
      })
    );
  }

  private static createEmptyAssessmentAndNavigateSomewhere(
    playlistContent: LoadableState<PlaylistResourceModel>,
    sectionIndex: number,
    dataService: PlaylistDataService,
    store: Store,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>,
    snackBar: MatSnackBar,
    ngZone: NgZone,
    translationService: TranslationService,
  ) {
    const sectionUid = sectionIndex === -1 ? playlistContent.data.mainSection.uid : playlistContent.data.standardSections[sectionIndex].uid;
    const newVersionEnabled = VersionHelper.newVersionEnabled();
    return dataService.createEmptyAssessment(playlistContent.data._id, sectionUid).pipe(
      tap(res => {
        if (res.isSuccess) {
          if (newVersionEnabled) {
            store.dispatch(new UpdateSidebarItemChildren(playlistContent.data._id, false));
          }
          store.dispatch(new RedirectToDiagnosticsCreation(res.value.settings.publication.uri, res.value.formattedUri));
        } else {
          this.handleCardCreationError(snackBar, ngZone, translationService, patchState);
        }
      })
    );
  }

  private static handleCardCreationError(
    snackBar: MatSnackBar,
    ngZone: NgZone,
    translationService: TranslationService,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>
  ) {
    SnackbarHelper.showTranslatableSnackBar(ngZone, snackBar, translationService, 'cardCreationError');
    patchState({
      activePaletteAction: false,
    });
  }

  private static resetState(patchState: (p: Partial<PlaylistCreationStateModel>)
    => Partial<PlaylistCreationStateModel>) {
    patchState({
      ...INITIAL_STATE,
    });
  }

  private static handlePlaylistErrorResponse(
    err: HttpErrorResponse | string,
    patchState: (p: Partial<PlaylistCreationStateModel>) => Partial<PlaylistCreationStateModel>
  ) {
    const failureReason =
      ((err as HttpErrorResponse).error?.status === 'CONFLICT' ||
        ((err as HttpErrorResponse).error?.message as string)?.match(/[Dd]uplicate/)) ? 'duplication' : 'error';
    patchState({
      saveStatus: {
        autoSaveInProgress: false,
        saveFailureReason: failureReason,
        saveInProgress: false,
      },
    });
  }
}

interface Navigable {
  uri: string;
  formattedUri: string;
}
