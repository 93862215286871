<ptl-mobile-menu-side-nav class="mobile-menu-side-nav"></ptl-mobile-menu-side-nav>

<div class="sidebar-tree-sections">
  <div *ngFor="let section of sections; trackBy: trackByFn"
    class="sidebar-section {{ section.class }}"
    [ngClass]="pageName"
    [attr.aria-label]="section.title">
    <div class="sidebar-item-separator" *ngIf="section.displayTopLineSeparator"></div>
    <div class="sidebar-item-headline" *ngIf="section.titleId">
      <div class="sidebar-item-headline-inner">

        <svg class="icon-svg icon svg" aria-hidden="true"
             *ngIf="section.icon && section.icon.svg"
             [ngClass]="section.icon.color ? 'item__icon--' + section.icon.color : 'no-icon'"
             [innerHTML]="section.icon.svg | safeHtml">
        </svg>
        <i
          class="material-icons icon" aria-hidden="true"
          *ngIf="section.icon && section.icon.name"
          [ngClass]="section.icon.color ? 'item__icon--' + section.icon.color : 'no-icon'">
          {{ section.icon.name }}
        </i>
        <i
          class="{{section.icon.class}} icon" aria-hidden="true"
          *ngIf="section.icon && section.icon.class"
          [ngClass]="section.icon.color ? 'item__icon--' + section.icon.color : 'no-icon'">
          <span *ngFor="let extraClass of section.icon.extraClasses" [ngClass]="extraClass"></span>
        </i>
        <span>{{ section.title }}</span>
      </div>
    </div>

    <ptl-side-nav-tree-item
      [level]="1"
      role="list"
      *ngIf="section.cards?.length"
      [stackView]="stackView"
      [cards]="section.cards"
      [isExploreSideNav]="isExploreSideNav"
      (sidebarItemClicked)="onSideBarItemClicked($event)">
    </ptl-side-nav-tree-item>

    <ng-container *ngFor="let link of section.staticLinks; trackBy: trackByFn">
      <a class="sidebar-static-item f_sidebar-static-item"
         tabindex="0"
         role="button"
         [matTooltip]="link.title"
         [ngClass]="{'is_active': isActiveRoute([link.uri])}"
         (click)="navigateTo(link.uri, link.titleId)"
         (keydown.enter)="navigateTo(link.uri, link.titleId)">
        <div class="sidebar-static-link small">

          <i *ngIf="!link.icon" class="icon no-icon"></i>
          <svg class="icon-svg icon svg" role="img"
               [attr.aria-label]="('translations.global.ariaLabels.icons.menuIconName' | transloco)"
               *ngIf="link.icon && link.icon.svg"
               [ngClass]="link.icon.color ? 'item__icon--' + link.icon.color : 'no-icon'"
               [innerHTML]="link.icon.svg | safeHtml">
          </svg>
          <i
            class="material-icons icon" aria-hidden="true"
            *ngIf="link.icon && link.icon.name"
            [ngClass]="link.icon.color ? 'item__icon--' + link.icon.color : 'no-icon'">
            {{ link.icon.name }}
          </i>
          <i
            class="{{link.icon.class}} icon" aria-hidden="true"
            *ngIf="link.icon && link.icon.class"
            [ngClass]="link.icon.color ? 'item__icon--' + link.icon.color : 'no-icon'">
            <span *ngFor="let extraClass of link.icon.extraClasses" [ngClass]="extraClass"></span>
          </i>
          <span>{{ link.title }}</span>
        </div>
      </a>
    </ng-container>

    <ng-container *ngIf="section.titleId === 'role-filter'">
      <div class="explore-role-filter-section">
        <div class="filter-section">
          <div class="filter-group-item">
            <div class="filter-group-item-inner">
              <mat-checkbox
                (click)="onRoleFilterChecked($event, 'ORGANIZATION')"
                [checked]="isRoleFilterChecked('ORGANIZATION')"
                [aria-label]="'translations.global.ariaLabels.tapToSelectRole' | transloco : {name: 'tood'}"
                [tabIndex]="0"
                color="primary">
                <div class="filter-name small">
                  {{ 'translations.explore.labels.filters.organizationFilter' | transloco: {organizationName: (organizationData$ | async).name} }}
                </div>
              </mat-checkbox>
            </div>
          </div>
          <div class="filter-group-item">
            <div class="filter-group-item-inner">
              <mat-checkbox
                (click)="onRoleFilterChecked($event, 'MEMBERS')"
                [checked]="isRoleFilterChecked('MEMBERS')"
                [aria-label]="'translations.global.ariaLabels.tapToSelectRole' | transloco : {name: 'topdo'}"
                [tabIndex]="0"
                color="primary">
                <div class="filter-name small">
                  {{ 'translations.explore.labels.filters.memberFilter' | transloco }}
                </div>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="section.hasShowMore && !cardContentLoaded[section.titleId] && initialLoadCompleted">
      <div
        (click)="loadSectionItems(section.titleId)"
        (keydown.enter)="loadSectionItems(section.titleId)" tabindex="0"
        class="sidebar-item-expand" role="button">
        <div class="sidebar-item-expand-text small bold">
          <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.arrowUp' | transloco)">
            <use ogSvgUnify="assets/sprite/sprite.svg#unfold-more"></use>
          </svg>
          {{ 'translations.sideNav.title.showMore' | transloco }}
        </div>
      </div>
    </ng-container>
    <div class="sidebar-item-separator" *ngIf="cardContentLoaded?.[section.titleId]"></div>

    <ng-container *ngIf="actionButtonEnabled">
      <div class="sidebar-action-button">
        <button mat-flat-button color="potentially" role="button" (click)="onActionButtonClick()">
          {{ actionButtonText }}
        </button>
      </div>
    </ng-container>

  </div>
</div>
