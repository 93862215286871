/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Component,
  EventEmitter,
  Output,
  Input
} from '@angular/core';

@Component({
  selector: 'ptl-pagination-block',
  templateUrl: './pagination-block.component.html',
  styleUrls: ['./pagination-block.component.scss'],
})
export class PaginationBlockComponent {

  @Output() sizeChanged = new EventEmitter<number>();

  @Output() pageChanged = new EventEmitter<number>();

  @Input() showPageSize: boolean;

  @Input() totalNumberOfElement: number;

  @Input() pageSize: number;

  @Input() totalPages: number;

  @Input() currentPage: number;

  @Input() startedFromZero: boolean;

  @Input() isPaginationVisible = true;

  onPageChange( page: number ): void {
    this.pageChanged.emit(page);
  }

  onSizeChange(): void {
    this.sizeChanged.emit(this.pageSize);
  }
}
