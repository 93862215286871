/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { PlaylistResourceCardsDataService } from './data.service';
import { ObservableResult } from '../../../../../shared/store';
import { LinkableCardSummary } from '../../../../../shared/models';
import { Page } from '../../../../../shared/models/page';

export class MockPlaylistResourceCardsDataService implements PlaylistResourceCardsDataService {

  private page: Page<LinkableCardSummary> = {
    content: [
      {
        header: 'test',
        id: '5555',
      },
    ],
    currentPageNumber: 0,
    totalNumberOfElement: 1,
    pageSize: 1,
  };

  searchLinkableCards(page: number, size: number, headlineFragment: string): ObservableResult<Page<LinkableCardSummary>> {
    return ObservableResult.ofSuccess(this.page);
  }
}
