/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../shared/store';
import { Injectable } from '@angular/core';
import { delay } from 'rxjs/operators';
import {
  Framework, FrameworkDetails,
  UpdateFrameworkRequest,
  OrganizationFramework, FrameworkTagDetails,
  FrameworkCategoriesTree
} from '../../../shared/models/admin/admin.model';
import { AdminDataService } from './data.service';
import {
  Page,
  PageRequest,
  SectionRequest,
  Section,
  SectionReorder,
  CategoryItemCreationRequest, CategoryItemCreationResponse
} from '../../../shared/models/page/page.model';
import { Playlist, ResourceTag } from '../../../shared/models';
import { DEFAULT_LANGUAGE_CODE } from '../../../shared/services/translation/translation.service';
import { Page as Pagination } from '../../../shared/models/page';

/* eslint-disable max-len */
@Injectable()

export class MockAdminDataService implements AdminDataService {
  private framework: Framework = {
    _id: '0',
    title: 'Pearson Javascript Course',
    description: 'Some description',
    imageUrl: '',
    organization: {
      _id: '0',
      name: 'Pearson',
    },
    tags: [{
      title: 'some Title',
      tagId: '1',
      description: '123',
      competency: {
        /** The framework tag competence UID. */
        competencyUid: '1',
        global: true,
        title: {
          languageCode: DEFAULT_LANGUAGE_CODE,
          value: 'title',
        },
        levels: [{
          title: [{
            languageCode: DEFAULT_LANGUAGE_CODE,
            value: 'title',
          }],
          description: [{
            languageCode: DEFAULT_LANGUAGE_CODE,
            value: 'description',
          }],
        }],
      },
    }],
    lastUpdatedAt: '',
    published: false,
    publishedDate: '',
    type: 'STANDARD'
  };

  private frameworks: Framework[] = [this.framework];

  private frameworkDetails: FrameworkDetails = {
    title: 'Some test title',
    description: 'Some test description',
    imageUrl: 'testImage',
    organization: {
      _id: 'M34223Cdsffds',
      name: 'Potential.ly',
    },
    tags: [{
      tagId: '2342340',
      description: 'Some test description',
      title: 'Tag title',
      children: [],
    }],
  };

  private tagDescendants: ResourceTag[] = [{
    _id: '2342340',
    title: 'Tag title',
  }, {
    _id: '3456354',
    title: 'Tag 23',
  }];

  private organizationFramework: OrganizationFramework = {
    name: 'test Freamework',
    id: '7264823',
    author: 'Pearson',
    on: false,
    published: false,
  };

  private categoryItemCreaionResponse: CategoryItemCreationResponse = {
    uploadUrl: '',
  };

  private frameworkCategoriesTree = [
    {
      _id: 'test1',
      title: 'Academic Professional Development',
      parentTagId: null,
      descendants: []
    },
    {
      _id: 'test2',
      title: 'Computer Science',
      parentTagId: null,
      descendants: []
    },

  ]

  /** Loads the frameworks for a user. */
  loadFrameworks(): ObservableResult<Framework[]> {
    return ObservableResult.ofSuccess(this.frameworks).pipe(delay(2000));
  }

  /** Loads the content store frameworks for a user. */
  loadContentStoreFrameworks(): ObservableResult<Framework> {
    return ObservableResult.ofSuccess(this.framework).pipe(delay(2000));
  }

  /** Loads the discovery frameworks for a user. */
  loadFrameworkCategories(): ObservableResult<Framework> {
    return ObservableResult.ofSuccess(this.framework).pipe(delay(2000));
  }

  /** Loads the discovery frameworks for a user. */
  loadFrameworkCategoriesTree(): ObservableResult<FrameworkCategoriesTree[]> {
    return ObservableResult.ofSuccess(this.frameworkCategoriesTree).pipe(delay(2000));
  }

  /** Loads the explore categories for a user. */
  loadCategoriesDescendantsTags(): ObservableResult<ResourceTag[]> {
    return ObservableResult.ofSuccess(this.tagDescendants).pipe(delay(2000));
  }

  loadCategoryChildren(categoryTagUid: string): ObservableResult<ResourceTag[]> {
    return ObservableResult.ofSuccess();
  }

  /** Loads the framework for a user by framework id. */
  loadFrameworkById(frameworkId: string): ObservableResult<FrameworkDetails> {
    return ObservableResult.ofSuccess(this.frameworkDetails).pipe(delay(2000));
  }

  /** Loads the framework tag descendants for a user by tag id. */
  loadFrameworkDescendants(frameworkId: string): ObservableResult<ResourceTag[]> {
    return ObservableResult.ofSuccess(this.tagDescendants).pipe(delay(2000));
  }

  /** Loads the framework tag descendants for a user by tag id. */
  loadFrameworkTagDescendants(frameworkId: string, tagId: string): ObservableResult<ResourceTag[]> {
    return ObservableResult.ofSuccess(this.tagDescendants).pipe(delay(2000));
  }

  /** Creates a new framework. */
  createFramework(): ObservableResult<void> {
    return ObservableResult.ofSuccess().pipe(delay(2000));
  }

  /** Updates the framework. */
  updateFramework(frameworkId: string, frameworkParams: UpdateFrameworkRequest): ObservableResult<Framework> {
    return ObservableResult.ofSuccess(this.framework).pipe(delay(2000));
  }

  /** Set the framework published or unpublished. */
  publishFramework(frameworkId: string, publish: boolean): ObservableResult<Framework> {
    return ObservableResult.ofSuccess(this.framework).pipe(delay(2000));
  }

  /** Loads all frameworks for all organizations. */
  loadOrganizationFrameworks(): ObservableResult<OrganizationFramework[]> {
    return ObservableResult.ofSuccess([this.organizationFramework]).pipe(delay(2000));
  }

  /** Adds framework to Organization. */
  addFrameworkToOrganization(frameworkId: string): ObservableResult<OrganizationFramework> {
    return ObservableResult.ofSuccess(this.organizationFramework).pipe(delay(2000));
  }

  /** Removes framework from Organization. */
  removeFrameworkFromOrganization(frameworkId: string): ObservableResult<void> {
    return ObservableResult.ofSuccess().pipe(delay(2000));
  }

  /** Sets organization framework status. */
  setOrganizationFrameworkStatus(frameworkId: string, status: boolean): ObservableResult<OrganizationFramework> {
    return ObservableResult.ofSuccess(this.organizationFramework).pipe(delay(2000));
  }

  /** Updates the page */
  updatePage(pageUid: string, pageBody: PageRequest): ObservableResult<Page> {
    return ObservableResult.ofSuccess();
  }

  /** Creates a new section */
  addSection(pageUid: string, sectionBody: SectionRequest): ObservableResult<Section> {
    return ObservableResult.ofSuccess();
  }

  /** Removes the section */
  removeSection(pageUid: string, sectionUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  /** Updates the section */
  updateSection(pageUid: string, sectionUid: string, section: SectionRequest): ObservableResult<Section> {
    return ObservableResult.ofSuccess();
  }

  /** Reorders sections on the page */
  reorderPageSections(pageUid: string, section: SectionReorder): ObservableResult<Section[]> {
    return ObservableResult.ofSuccess();
  }

  /** Returns latest playlists for given user from organization. */
  getLatestPlaylists(page: number, size: number, frameworkId?: string, tagId?: string):
    ObservableResult<Pagination<Playlist>> {
    return ObservableResult.ofSuccess();
  }

  getPlaylistsByTags(page: number, size: number, tagIds?: string, languageCode?: string): ObservableResult<Pagination<Playlist>> {
    return ObservableResult.ofSuccess();
  }

  /** Returns uncompleted learners playlists list */
  getUncompletedPlaylists(page: number, size: number): ObservableResult<Pagination<Playlist>> {
    return ObservableResult.ofSuccess();
  }

  getYourPlaylists(page: number, size: number, term?: string, languageCode?: string): ObservableResult<Pagination<Playlist>> {
    return ObservableResult.ofSuccess();
  }

  addCategory(pageUid: string, sectionUid: string, request: CategoryItemCreationRequest):
    ObservableResult<CategoryItemCreationResponse> {
    return ObservableResult.ofSuccess(this.categoryItemCreaionResponse).pipe(delay(2000));
  }

  updateCategory(pageUid: string, sectionUid: string, categoryId: string, request: CategoryItemCreationRequest):
    ObservableResult<CategoryItemCreationResponse> {
    return ObservableResult.ofSuccess(this.categoryItemCreaionResponse).pipe(delay(2000));
  }

  removeCategory(pageUid: string, sectionUid: string, categoryId: string):
    ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  cloneSection(pageUid: string, sectionUid: string): ObservableResult<Section> {
    return ObservableResult.ofSuccess();
  }

  createLtiFramework(): ObservableResult<void> {
    return ObservableResult.ofSuccess().pipe(delay(2000));
  }

  loadLtiFrameworks(requestId: string, organizationUid: string): ObservableResult<Framework[]> {
    return ObservableResult.ofSuccess();
  }
}
