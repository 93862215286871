/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { CreateFrameworkRequest, UpdateFrameworkRequest } from '../../../shared/models/admin/admin.model';
import {
  AddCompetenceLevelRequest,
  CreateCompetenceRequest
} from '../../../shared/models/competence/competence.model';

export class LoadFrameworks {
  static readonly type = '[Framework] Load frameworks';
}

export class LoadLtiFrameworks {
  static readonly type = '[Framework] Load lti frameworks';

  constructor(public organizationUid: string, public requestId: string) {}
}

export class LoadContentStoreFrameworks {
  static readonly type = '[Framework] Load content store frameworks';
}

export class LoadFrameworkCategories {
  static readonly type = '[Framework] Load Discovery frameworks';
}

export class LoadFrameworkDescendants {
  static readonly type = '[Framework] Load framework';

  constructor(public frameworkId: string) {}
}

export class LoadFrameworkTagDescendants {
  static readonly type = '[Framework] Load framework tag descendants';

  constructor(public frameworkId: string, public tagId: string) {}
}

export class LoadOrganizationFrameworkById {
  static readonly type = '[Framework] Load organization framework';

  constructor(public frameworkId: string) {}
}

export class RemoveSelectedFrameworkFromStore {
  static readonly type = '[Framework] Remove framework from store';
}

export class AddFramework {
  static readonly type = '[Framework] Add framework';

  constructor(public frameworkParams: CreateFrameworkRequest) {}
}

export class AddLtiFramework {
  static readonly type = '[Framework] Add Lti framework';

  constructor(public requestId: string,
              public organizationUid: string,
              public frameworkParams: CreateFrameworkRequest) {}
}

export class UpdateLtiFramework {
  static readonly type = '[Framework] Update LTi framework';

  constructor(public frameworkId: string,
              public organizationUid: string,
              public requestId: string,
              public frameworkParams: UpdateFrameworkRequest) {}
}

export class UpdateFramework {
  static readonly type = '[Framework] Update framework';

  constructor(public frameworkId: string,
              public frameworkParams: UpdateFrameworkRequest,
              public frameworkImageFile: File) {}
}

export class PublishFramework {
  static readonly type = '[Framework] Publish frameworks';

  constructor(public frameworkId: string, public publish: boolean) {}
}

export class LoadOrganizationFrameworks {
  static readonly type = '[Framework] Load all organization frameworks';
}

export class AddFrameworkToOrganization {
  static readonly type = '[Framework] Add framework to organization';

  constructor(public frameworkId: string) {}
}

export class RemoveFrameworkFromOrganization {
  static readonly type = '[Framework] Remove framework from organization';

  constructor(public frameworkId: string) {}
}

export class CreateTag {
  static readonly type = '[Framework] create a tag';

  constructor(public title: string) {
  }
}

export class EmptyAddTagState {
  static readonly type = '[Framework] empty add tag state';
}

export class FilterMainTags {
  static readonly type = '[Framework] Filter main tags';

  constructor(public text: string) {
  }
}

export class FilterCategories {
  static readonly type = '[Framework] Filter categories';

  constructor(public text: string) {
  }
}

export class FilterLtiTags {
  static readonly type = '[Framework] Filter lti tags';

  constructor(public organizationUid: string, public requestId: string, public text: string) {
  }
}

export class AssignCompetenceToTag {
  static readonly type = '[Framework] Assign competence to a tag';

  constructor(public tagUid: string, public competencyUid: string) {
  }
}

export class CreateCompetence {
  static readonly type = '[Framework] Create a competence';

  constructor(public request: CreateCompetenceRequest) {
  }
}

export class UpdateCompetence {
  static readonly type = '[Framework] Update a competence';

  constructor(public competenceUid: string, public request: CreateCompetenceRequest) {
  }
}

export class AddCompetenceLevel {
  static readonly type = '[Framework] Add a competence Level';

  constructor(public competenceUid: string, public request: AddCompetenceLevelRequest) {
  }
}

export class UpdateCompetenceLevel {
  static readonly type = '[Framework] Update a competence Level';

  constructor(
      public competenceUid: string,
      public competenceLevelUid: string,
      public request: AddCompetenceLevelRequest
  ) {
  }
}

export class LoadAllCompetencies {
  static readonly type = '[Framework] Load all competencies';

  constructor(public global: boolean) {
  }
}

export class EmptyNewCompetence {
  static readonly type = '[Framework] empty added competence';
}

export class EmptyCompetenceLevels {
  static readonly type = '[Framework] empty competence levels';
}


export class SetCompetenceAssignmentToFalse {
  static readonly type = '[Framework] Set Competence assignment to false';
}
