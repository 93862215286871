/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/** The app environment options. */
export interface Environment {
  /** Defines if the environment code. */
  env: EnvironmentCode;

  /** True if authentication should be disabled. */
  disableAuth: boolean;

  /** The data provider to use. */
  dataProvider: 'api' | 'mock';

  /** The key to use when integrating with IFramely. */
  iframelyApiKey: string;

  /** The MD5-encoded IFramely API key. See docs: https://iframely.com/docs/allow-origins */
  iframelyEncodedApiKey: string;

  /** The root URL of the API. */
  apiRootUrl: string;

  /** Defines the realm configuration */
  realmUrl: string;
  samlSpHost: string;
  clientId: string;
  nonce: string;

  /** Google Analytics */
  googleAnalyticsPropertyId: string;
  reCaptchaSiteKeyV2: string;
  reCaptchaSiteKeyV3: string;
}

export enum EnvironmentCode {
  local = 'local',
  dev = 'dev',
  devLocal = 'local-dev',
  prod = 'prod',
  test = 'test',
  testLocal = 'test-local',
}
