/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { FrameworkTag, FrameworkType } from '../../../models/admin/admin.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'ptl-frameworks-tags-list-tree',
  templateUrl: './framework-tags-list-tree.component.html',
  styleUrls: ['./framework-tags-list-tree.component.scss'],
})
export class FrameworkTagsListTreeComponent {
  @ViewChild('childTreeContainer') childTreeContainer: ElementRef;
  @ViewChild('inputElement') private inputElement: ElementRef;

  @Input() allTags: FrameworkTag[] = [];
  @Input() childrenTags: FrameworkTag[] = [];
  @Input() frameworkType: FrameworkType;

  @Output() childTagAdded = new EventEmitter<{ childTag: FrameworkTag; parentTag: FrameworkTag }>();
  @Output() tagDeleted = new EventEmitter<FrameworkTag>();

  editableTag: FrameworkTag;
  childrenFilteredTags: FrameworkTag[];
  private modifiedTagsMap: Map<string, FrameworkTag>;

  onChildSearchTextPaste( event: ClipboardEvent, currentSelectedTag: FrameworkTag ) {
    this.childrenFilteredTags = [];

    if ( event.type === 'paste' ) {
      setTimeout(() => {
        const searchValue = (event.target as HTMLInputElement).value;
        this.childrenFilteredTags = this.getFilteredChildren(searchValue, currentSelectedTag);
      }, 0);
    }
  }

  onChildSearchInputChange( event: KeyboardEvent, currentSelectedTag: FrameworkTag ) {
    const searchValue = (event.target as HTMLInputElement).value;
    this.childrenFilteredTags = this.getFilteredChildren(searchValue, currentSelectedTag);
  }

  getFilteredChildren( searchValue: string, currentSelectedTag: FrameworkTag ) {
    this.modifyTagsNotBelongingToParent(cloneDeep(this.allTags), currentSelectedTag);
    const modifiedTagsArray = Array.from(this.modifiedTagsMap.values());

    return modifiedTagsArray.filter(( item ) => {
      return item.title.toLowerCase().includes(searchValue.toLowerCase()) && item.title !== currentSelectedTag.title;
    })
  }

  openChildSearchInput( tag: FrameworkTag ) {
    this.editableTag = tag;
  }

  disabledAutocomplete(): string {
    return '';
  }

  onChildTagSelect( { option }: MatAutocompleteSelectedEvent ) {
    const tag = option.value as FrameworkTag;

    tag.parentTagId = null;
    tag.children = [];
    const childTag = JSON.parse(JSON.stringify(tag));
    const editableTag = JSON.parse(JSON.stringify(this.editableTag));
    this.childTagAdded.emit({ childTag: childTag, parentTag: editableTag });
    this.inputElement.nativeElement.value = '';
    this.editableTag = null;
  }

  closeInput() {
    this.editableTag = null;
  }

  onChildAdded( data: { childTag: FrameworkTag; parentTag: FrameworkTag } ) {
    const copyData = JSON.parse(JSON.stringify(data));
    this.childTagAdded.emit(copyData)
  }

  onDeleteItem( tag: FrameworkTag ) {
    this.tagDeleted.emit(tag)
  }

  onChildTagDeleteItem( tag: FrameworkTag ) {
    this.tagDeleted.emit(tag)
  }

  toggleChildrenView(event: Event) {
    const parentElem = (event.target as HTMLElement).closest('.f_framework-chip-item-row');
    parentElem.querySelector('.f_framework-chip-item-row-outer').classList?.toggle('is-hidden-children');
    parentElem.querySelector('.f_child-tree-container')?.classList.toggle('is-hidden');
  }

  private modifyTagsNotBelongingToParent(allTags, currentSelectedTag) {
    this.modifiedTagsMap = new Map<string, FrameworkTag>();

    this.modifyTags(allTags, 0)

    const currentElementParentId = currentSelectedTag.parentTagId;
    this.hasParent(currentElementParentId, this.modifiedTagsMap);
    this.modifiedTagsMap.delete(currentSelectedTag.tagId);
  }

  private hasParent(currentElementParentId, tags) {
    if (!currentElementParentId) {
      return;
    }

    this.hasParent(this.modifiedTagsMap.get(currentElementParentId).parentTagId, tags)
    this.modifiedTagsMap.delete(currentElementParentId);
  }

  private modifyTags( tags: FrameworkTag[], indentValue: number, tagId?: string ) {
    for ( const tag of tags ) {
      this.modifiedTagsMap.set(tag.tagId, tag);

      if ( tag.children && tag.children.length ) {
        this.modifyTags(tag.children, indentValue + 1, tag.tagId);
      }
    }
  }
}
