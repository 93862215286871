/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { QuizDataService } from './data.service';
import { Quiz, createQuiz, UpdateQuizRequest, QuizSummary } from '../../shared/models/editor/quiz-content.model';
import { ObservableResult } from '../../shared/store';
import { DiagnosticQuestionRequest, DiagnosticQuestionResponse } from '../../shared/models';
import { QuizResponse, QuizReport } from '../../page-modules/resource/models';

@Injectable({
  providedIn: 'root',
})
export class MockQuizDataService implements QuizDataService {

  getQuiz(): ObservableResult<Quiz> {
    return ObservableResult.ofSuccess(createQuiz());
  }

  getQuizSummary(cardUid: string, quizUid: string, userUid?: string, languageCode?: string): ObservableResult<QuizSummary> {
    return ObservableResult.ofSuccess();
  }

  getQuizUserSummary(cardUid: string, quizUid: string, userUid?: string, languageCode?: string): ObservableResult<QuizSummary> {
    return ObservableResult.ofSuccess();
  }

  getQuizSummaryAsReviewer(cardUid: string, quizUid: string, reviewUid: string): ObservableResult<QuizSummary> {
    return ObservableResult.ofSuccess();
  }

  deleteQuiz(): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateQuiz(cardUid: string, quizUid: string, request: UpdateQuizRequest): ObservableResult<Quiz> {
    return ObservableResult.ofSuccess(createQuiz());
  }

  postQuizResponse(playlistUid: string, quizUid: string, version: number, questionResponseRequest): ObservableResult<QuizResponse> {
    return ObservableResult.ofSuccess();
  }

  getQuizReport(playlistUid: string, quizUid: string, quizResponsesUid: string, languageCode?: string): ObservableResult<QuizReport> {
    return ObservableResult.ofSuccess();
  }

  retakeQuiz(playlistUid: string, quizUid: string, quizResponsesUid: string, nextVersion: number): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getQuizResponse(playlistUid: string, quizUid: string, quizResponsesUid: string): ObservableResult<QuizResponse> {
    return ObservableResult.ofSuccess();
  }

  addQuestion(cardUid: string, quizUid: string, question: DiagnosticQuestionRequest):
    ObservableResult<DiagnosticQuestionResponse> {
    return ObservableResult.ofSuccess();
  }

  updateQuestion(
    cardUid: string,
    quizUid: string,
    questionUid: string,
    question: DiagnosticQuestionRequest
  ): ObservableResult<DiagnosticQuestionResponse> {
    return ObservableResult.ofSuccess();
  }

  deleteQuestion(): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  clearQuestionFeedback(cardUid: string, quizUid: string, questionUid: string, languageCode?: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  copyQuestion(cardUid: string, quizUid: string, questionUid: string): ObservableResult<DiagnosticQuestionResponse> {
    return ObservableResult.ofSuccess();
  }

}
