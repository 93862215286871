/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Inject, Injectable } from '@angular/core';
import * as HeaderCountsActions from './header-counts.action';
import { MESSAGES_DATA_SERVICE, MessagesDataService } from '../../page-modules/messages/services/messages-data.service';
import { REVIEWS_DATA_SERVICE, ReviewsDataService } from '../../page-modules/reviews/services/data.service';
import { Notification } from '../../shared/models/notifications/notifications.model';
import {
  NOTIFICATIONS_DATA_SERVICE,
  NotificationsDataService
} from '../../shared/services/notifications/notifications-data.service';

export const INITIAL_HEADER_COUNTS_STATE: HeaderCountsStateModel = {
  unreadNotificationsCount: 0,
  unreadMessagesCount: 0,
  pendingReviewsCount: 0
};

export interface HeaderCountsStateModel {
  unreadNotificationsCount: number;
  unreadMessagesCount: number;
  pendingReviewsCount: number;
}

@State<HeaderCountsStateModel>(
  {
    name: 'headerCounts',
    defaults: INITIAL_HEADER_COUNTS_STATE
  }
)
@Injectable()
export class HeaderCountsState {
  @Selector()
  static unreadNotificationsCount({ unreadNotificationsCount }: HeaderCountsStateModel) {
    return unreadNotificationsCount;
  }

  @Selector()
  static unreadMessagesCount({ unreadMessagesCount }: HeaderCountsStateModel) {
    return unreadMessagesCount;
  }
  @Selector()
  static pendingReviewsCount({ pendingReviewsCount }: HeaderCountsStateModel) {
    return pendingReviewsCount;
  }

  constructor(
    @Inject(MESSAGES_DATA_SERVICE) private messagesDataService: MessagesDataService,
    @Inject(REVIEWS_DATA_SERVICE) private reviewsDataService: ReviewsDataService,
    @Inject(NOTIFICATIONS_DATA_SERVICE) private notificationsDataService: NotificationsDataService,
  ) {
  }

  @Action(HeaderCountsActions.UpdateNotificationsCount)
  updateNotificationsCount(
    { patchState }: StateContext<HeaderCountsStateModel>,
  ) {
    return this.notificationsDataService.loadNotificationsSnapshot().subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        const unreadNotifications = value.reduce((count: number, notification: Notification) =>
          notification.read ? count : count + 1, 0);
        patchState({
          unreadNotificationsCount: unreadNotifications
        });
      }
    });
  }

  @Action(HeaderCountsActions.UpdateMessagesCount)
  updateMessagesCount(
    { patchState }: StateContext<HeaderCountsStateModel>
  ) {
    return this.messagesDataService.loadReceivedMessages(0, 10, false).subscribe(
      ({ isSuccess, value }) => {
        if (isSuccess) {
          const messagesCount = value.content.length;
          patchState({
            unreadMessagesCount: messagesCount
          });
        }
      }
    )
  }

  @Action(HeaderCountsActions.UpdateReviewsCount)
  updateReviewsCount(
    { patchState }: StateContext<HeaderCountsStateModel>
  ) {
    return this.reviewsDataService.getUserReviewsCount().subscribe(
      ({ isSuccess, value }) => {
        if (isSuccess) {
          const reviewsCount = value.pendingCount;
          patchState({
            pendingReviewsCount: reviewsCount
          });
        }
      }
    )
  }
}
