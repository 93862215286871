/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { ObservableResult } from '../../store';
import { catchError, switchMap } from 'rxjs/operators';
import { RestClientService } from '../rest-client.service';
import {
    AddCompetenceLevelRequest, CompetenceLevel,
    CompetenceModel,
    CreateCompetenceRequest
} from '../../models/competence/competence.model';
import { CompetenceDataService } from './competence-data.service';
import { TranslationService } from '../translation/translation.service';

@Injectable()
export class ApiCompetenceDataService implements CompetenceDataService {

    private competenciesUrl = Location.joinWithSlash(environment.apiRootUrl || '', 'competencies');

    constructor(private client: RestClientService, private translationService: TranslationService) {
    }

    createCompetence(request: CreateCompetenceRequest): ObservableResult<CompetenceModel> {
        return this.client.post<CompetenceModel>(this.competenciesUrl, request)
            .pipe(
              switchMap(({ body }) => ObservableResult.ofSuccess(body)),
              catchError(err => ObservableResult.ofError(JSON.stringify(err)))
            );
    }

    updateCompetence(competenceUid: string, request: CreateCompetenceRequest): ObservableResult<CompetenceModel> {
        return this.client.patch<CompetenceModel>(this.competenciesUrl + `/${competenceUid}`, request)
            .pipe(
              switchMap(({ body }) => ObservableResult.ofSuccess(body)),
              catchError(err => ObservableResult.ofError(JSON.stringify(err)))
            );
    }

    addCompetenceLevel(
        competenceUid: string,
        request: AddCompetenceLevelRequest
    ): ObservableResult<CompetenceModel> {
        return this.client.post<CompetenceModel>(
            this.competenciesUrl + `/${competenceUid}/levels`,
            request
        ).pipe(
          switchMap(({ body }) => ObservableResult.ofSuccess(body)),
          catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorAddCompetenceLevel')))
        );
    }

    updateCompetenceLevel(
        competenceUid: string,
        competenceLevelUid: string,
        request: AddCompetenceLevelRequest
    ): ObservableResult<CompetenceLevel> {
        return this.client.patch<CompetenceLevel>(
            this.competenciesUrl + `/${competenceUid}/levels/${competenceLevelUid}`,
            request
        ).pipe(
            switchMap(({ body }) => ObservableResult.ofSuccess(body)),
            catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorUpdateCompetenceLevel')))
        );
    }

    getCompetenceById(competenceUid: string): ObservableResult<CompetenceModel> {
        return this.client.get<CompetenceModel>(this.competenciesUrl + `/${competenceUid}`)
            .pipe(
                switchMap(({ body }) => ObservableResult.ofSuccess(body)),
                catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetCompetence')))
            );
    }

    getOrganizationCompetenciesIncludingGlobal(global: boolean): ObservableResult<CompetenceModel[]> {
        return this.client.get<CompetenceModel[]>(this.competenciesUrl + `?global=${global}`)
            .pipe(
                switchMap(({ body }) => ObservableResult.ofSuccess(body)),
                catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetCompetenceList')))
            );
    }

    connectCompetenceToTag(tagUid: string, competencyUid: string): ObservableResult<void> {
        return this.client.post<void>(Location.joinWithSlash(
            environment.apiRootUrl || '', `tags/${tagUid}/competency/${competencyUid}`)).pipe(
                switchMap(({ body }) => ObservableResult.ofSuccess(body)),
                catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorAssignCompetence')))
            );
    }
}
