/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Component, EventEmitter,
  Inject, Input,
  NgZone, OnDestroy,
  OnInit, Output
} from '@angular/core';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpCenterItem, HelpCenterViewsType, ViewTopics } from '../../models/help-center/help-center.model';
import { HELP_CENTER_DATA_SERVICE, HelpCenterDataService } from '../../services/help-center/help-center-data.service';
import { Organization, PlaylistCardShort } from '../../models';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessTokenService } from '../../../user-auth/services/access-token.service';
import { AnalyticsFilterData } from '../../models/analytics/analytics.model';
import { AlertType } from '../../models/alert/alert.model';

@Component({
  selector: 'ptl-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {

  @Input() message: string;

  @Input() duration = 10000;

  @Input() textClickable = false;

  @Input() type: AlertType;

  @Output() actionClicked = new EventEmitter<void>();

  @Output() closeClicked = new EventEmitter<void>();

  private alertHideTimeout;

  ngOnInit() {
    this.alertHideTimeout = setTimeout(() => {
      this.onCloseClicked()
    }, this.duration);
  }

  ngOnDestroy() {
    clearTimeout(this.alertHideTimeout)
  }

  onActionClicked() {
    if ( this.textClickable ) {
      this.actionClicked.emit();
    }
  }

  onCloseClicked() {
    this.closeClicked.emit();
  }
}
