/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../../shared/services/rest-client.service';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { Quiz, QuizSummary, UpdateQuizRequest } from '../../shared/models/editor/quiz-content.model';
import { catchError, switchMap } from 'rxjs/operators';
import { ObservableResult } from '../../shared/store';
import { QuizDataService } from './data.service';
import { DiagnosticQuestionRequest, DiagnosticQuestionResponse, QuestionResponseRequest } from '../../shared/models';
import { QuizReport, QuizResponse } from '../../page-modules/resource/models';
import { TranslationService } from '../../shared/services/translation/translation.service';
import { LanguageCodeHelper } from '../../shared/helpers/language-code-helper';

@Injectable({
  providedIn: 'root',
})
export class ApiQuizDataService implements QuizDataService {

  constructor(private client: RestClientService, private translationService: TranslationService) {
  }

  getQuiz(cardUid: string, quizUid: string, languageCode?: string): ObservableResult<Quiz> {
    return this.client.get<Quiz>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/quizzes/${quizUid}`),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetQuiz')))
    );
  }

  getQuizSummary(cardUid: string, quizUid: string, languageCode?: string): ObservableResult<QuizSummary> {
    return this.client.get<QuizSummary>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/quizzes/${quizUid}/summary`),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetQuizSummary')))
    );
  }

  getQuizUserSummary(cardUid: string, quizUid: string, userUid: string, languageCode?: string): ObservableResult<QuizSummary> {
    return this.client.get<QuizSummary>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/quizzes/${quizUid}/summary/${userUid}`),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetQuizSummary')))
    );
  }

  getQuizSummaryAsReviewer(cardUid: string, quizUid: string, reviewUid: string): ObservableResult<QuizSummary> {
    return this.client.get<QuizSummary>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/quizzes/${quizUid}/summary/reviews/${reviewUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetQuizSummary')))
    );
  }

  deleteQuiz(cardUid: string, quizUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/quizzes/${quizUid}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorDeleteQuiz')))
    );
  }

  updateQuiz(cardUid: string, quizUid: string, request: UpdateQuizRequest, languageCode?: string): ObservableResult<Quiz> {
    return this.client.patch<Quiz>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/quizzes/${quizUid}`), request, null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => {
        let errorMessage = this.translationService.getTranslation('errors.errorUpdateQuiz');
        if (err?.status === 400) {
          errorMessage = errorMessage + ' ' + err.error?.message;
        }
        return ObservableResult.ofError(errorMessage);
      })
    );
  }

  postQuizResponse(playlistUid: string,
                   quizUid: string,
                   version: number,
                   req: QuestionResponseRequest,
                   languageCode?: string): ObservableResult<QuizResponse> {
    return this.client.post<QuizResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/quizzes/${quizUid}/responses?version=${version}`),
      req,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorPostQuizResponse')))
    );
  }

  getQuizResponse(playlistUid: string, quizUid: string, quizResponsesUid: string): ObservableResult<QuizResponse> {
    return this.client.get<QuizResponse>(Location.joinWithSlash(environment.apiRootUrl ||
      '', `playlists/${playlistUid}/quizzes/${quizUid}/responses/${quizResponsesUid}`))
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetQuizResponse')))
      );
  }

  getQuizReport(playlistUid: string, quizUid: string, quizResponsesUid: string, languageCode?: string): ObservableResult<QuizReport> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `playlists/${playlistUid}/quizzes/${quizUid}/responses/${quizResponsesUid}/report`
    );
    return this.client.get<QuizReport>(url,
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetQuizReport')))
    );
  }

  retakeQuiz(playlistUid: string, quizUid: string, quizResponsesUid: string, nextVersion: number): ObservableResult<void> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '',
      `playlists/${playlistUid}/quizzes/${quizUid}/responses/${quizResponsesUid}/retake?version=${nextVersion}`
    );
    return this.client.get<void>(url).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRetakeQuiz')))
    );
  }

  addQuestion(cardUid: string, quizUid: string, question: DiagnosticQuestionRequest, languageCode?: string):
    ObservableResult<DiagnosticQuestionResponse> {
    return this.client.post<DiagnosticQuestionResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `cards/${cardUid}/quizzes/${quizUid}/questions`),
      question,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddQuizQuestion')))
    );
  }

  updateQuestion(
    cardUid: string,
    quizUid: string,
    questionUid: string,
    question: DiagnosticQuestionRequest,
    languageCode?: string
  ): ObservableResult<DiagnosticQuestionResponse> {
    return this.client.patch<DiagnosticQuestionResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `cards/${cardUid}/quizzes/${quizUid}/questions/${questionUid}`),
      question,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateQuizQuestion')))
    );
  }

  deleteQuestion(cardUid: string, quizUid: string, questionUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `cards/${cardUid}/quizzes/${quizUid}/questions/${questionUid}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorDeleteQuizQuestion')))
    );
  }

  clearQuestionFeedback(cardUid: string, quizUid: string, questionUid: string, languageCode?: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `cards/${cardUid}/quizzes/${quizUid}/questions/${questionUid}/feedback`),
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorDeleteQuizQuestionFeedback')))
    );
  }

  copyQuestion(
    cardUid: string,
    quizUid: string,
    questionUid: string,
    languageCode?: string
  ): ObservableResult<DiagnosticQuestionResponse> {
    return this.client.post<DiagnosticQuestionResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `cards/${cardUid}/quizzes/${quizUid}/questions/${questionUid}/clone`),
      {},
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCloneQuizQuestion')))
    );
  }

}
