/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TagErrorObject, TagRequest, TagTypeResponse } from '../../../page-modules/playlist/models';
import { ResourceTag } from '../../models';
import { ObservableResult } from '../../store';
import { RestClientService } from '../rest-client.service';
import { TranslationService } from '../translation/translation.service';
import { MultiTagCreationRequest, TagDataService, TagInfo } from './tag-data.service';

@Injectable()
export class ApiTagDataService implements TagDataService {

  private tagsUrl = Location.joinWithSlash(environment.apiRootUrl || '', 'tags');

  constructor(private client: RestClientService, private translationService: TranslationService) {
  }

  createTag(request: TagRequest): ObservableResult<ResourceTag> {
    return this.client.post<ResourceTag>(this.tagsUrl, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(error => this.getObservableResultErrorJSONString(error.status))
      );
  }

  createTags(request: MultiTagCreationRequest): ObservableResult<ResourceTag[]> {
    return this.client.post<ResourceTag[]>(`${this.tagsUrl}/multi`, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorTagsCreation')))
      );
  }

  findTagsWithTitleTerm(text: string): ObservableResult<ResourceTag[]> {
    return this.client.get<ResourceTag[]>(
      `${this.tagsUrl}/search`,
      {
        term: text,
        page: '0',
        size: '10',
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorFindingTags')))
    );
  }

  findLtiTagsWithTitleTerm(organizationUid: string, requestId: string, text: string): ObservableResult<ResourceTag[]> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '',
      `lti/skills/${organizationUid}/skills?requestId=${requestId}`)
    return this.client.get<ResourceTag[]>(
       url,
      {
        term: text,
        page: '0',
        size: '10',
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorFindingTags')))
    );
  }

  extractTags(text: string): ObservableResult<string[]> {
    const data = { text: text };
    return this.client.post<string[]>(`${this.tagsUrl}/extract`, data)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorExtractingTags')))
      );
  }

  getTraitTags(page = 0, size = 25): ObservableResult<TagTypeResponse[]> {
    return this.client.get<TagTypeResponse[]>(
      `${this.tagsUrl}/traits?page=${page}&size=${size}`
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetTraitTags')))
    );
  }

  getTeamRoleTags(page = 0, size = 9): ObservableResult<TagTypeResponse[]> {
    return this.client.get<TagTypeResponse[]>(
      `${this.tagsUrl}/team-roles?page=${page}&size=${size}`
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetTeamRolesTags')))
    );
  }

  getJungianTypeTags(page = 0, size = 16): ObservableResult<TagTypeResponse[]> {
    return this.client.get<TagTypeResponse[]>(
      `${this.tagsUrl}/jungian-types?page=${page}&size=${size}`
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetJungianTags')))
    );
  }

  getBigSixTags(page = 0, size = 5): ObservableResult<TagTypeResponse[]> {
    return this.client.get<TagTypeResponse[]>(
      `${this.tagsUrl}/big-six?page=${page}&size=${size}`
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetBigSixTags')))
    );
  }

  findCategoriesWithTitleTerm(text: string): ObservableResult<ResourceTag[]> {
    return this.client.get<ResourceTag[]>(
      `${this.tagsUrl}/category/search/term`,
      {
        term: text,
        page: '0',
        size: '10',
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorFindingCategories')))
    );
  }

  createCategory(request: TagRequest): ObservableResult<ResourceTag> {
    return this.client.post<ResourceTag>(`${this.tagsUrl}/category`, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(error => this.getObservableResultErrorJSONString(error.status))
      );
  }

  findCategories(categoriesUid: string[]): ObservableResult<TagInfo[]> {
    return this.client.post<TagInfo[]>(
      `${this.tagsUrl}/category/search/uid`,
      {
        tags: categoriesUid
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorFindingCategories')))
    );
  }

  private getCreateTagsErrorObject(errorStatus: number): TagErrorObject {
    if (errorStatus === 409) {
      return { errorType: 'tagDuplicated', errorMessage: this.translationService?.getTranslation('errors.errorTagAlreadyExists') };
    }

    return { errorType: 'creationError', errorMessage: this.translationService?.getTranslation('errors.errorTagCreation') };
  }

  private getObservableResultErrorJSONString(errorStatus: number) {
    return ObservableResult.ofError(JSON.stringify(this.getCreateTagsErrorObject(errorStatus)));
  }
}
