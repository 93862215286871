<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<mat-card class="event-reminders__container">

  <div class="enable-reminders-toggle-container">
    <mat-slide-toggle
      class="ptl-slide-toggle"
      [disabled]="!editingEnabled"
      [attr.aria-label]="('translations.global.ariaLabels.eventRemindersEnableDisableToggle' | transloco)"
      [checked]="shouldDisplayData()"
      (change)="switchRemindersToggle($event)"
      (keydown.enter)="switchRemindersToggle($event)">
      <span hidden="true">
        {{ 'translations.eventRemindersEnableDisableToggle' | transloco }}
      </span>
    </mat-slide-toggle>
  </div>

  <div class="event-reminders__container-top">

    <div class="event-reminders__container-description">
      <h2
        class="title large"
        [attr.aria-label]="('translations.global.ariaLabels.eventRemindersTitleNew' | transloco)">
        {{ 'translations.eventRemindersTitleNew' | transloco }}
      </h2>
      <p *ngIf="!shouldDisplayData()"
        class="description small"
        [attr.aria-label]="('translations.global.ariaLabels.eventRemindersDescriptionDisabled' | transloco)">
        {{ 'translations.eventRemindersDescriptionDisabled' | transloco }}
      </p>
      <p *ngIf="shouldDisplayData()"
        class="description small"
        [attr.aria-label]="('translations.global.ariaLabels.eventRemindersDescriptionEnabled' | transloco)">
        {{ 'translations.eventRemindersDescriptionEnabled' | transloco }}
      </p>
    </div>

    <div *ngIf="shouldDisplayData()">
      <div class="event-reminders-option" *ngFor="let reminder of remindersList; let i = index; trackBy: trackByFn">
        <div class="event-reminder-option-select">
          <span
            class="small"
            [attr.aria-label]="('translations.global.ariaLabels.eventRemindersSendReminder' | transloco)">
            {{ 'translations.eventRemindersSendReminder' | transloco }}
          </span>
          <mat-select
            class="select-field small"
            [attr.aria-label]="('translations.global.ariaLabels.selectReminderTime' | transloco)"
            [value]="reminder"
            (selectionChange)="onReminderSelect($event, i)">
            <mat-option
              [value]="j"
              *ngFor="let hour of timeList; let j = index">
              {{hour.amount}} {{hour.name}}
            </mat-option>
          </mat-select>
          <span
            class="small"
            [attr.aria-label]="'translations.global.ariaLabels.eventRemindersBeforeEvent' | transloco">
            {{ 'translations.eventRemindersBeforeEvent' | transloco }}
          </span>
        </div>
        <div class="event-reminder-option-remove">
          <button
            mat-icon-button
            class="delete-button"
            title="Delete reminder"
            [attr.aria-label]="('translations.global.ariaLabels.deleteReminder' | transloco)"
            type="button"
            (click)="removeReminder(i)">
            <mat-icon aria-hidden="true">close</mat-icon>
          </button>
        </div>
      </div>

      <div class="event-reminders_add-reminder">
        <button
          type="button"
          [disabled]="remindersList.length > 4"
          class="event-reminder_add-btn small"
          [attr.aria-label]="('translations.global.ariaLabels.eventRemindersAddReminder' | transloco)"
          (click)="addNewReminder()">
          <svg class="svg" role="img" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
          </svg>
          <span>
            {{ 'translations.eventRemindersAddReminder' | transloco }}
          </span>
        </button>
      </div>

      <div class="event-reminders_update-button-box">
        <button
          [disabled]="isButtonClicked || !dataHasUpdated"
          mat-flat-button
          class="event-reminders_cancel-button uppercase"
          [attr.aria-label]="('translations.global.ariaLabels.eventRemindersCancelButton' | transloco)"
          type="button"
          (click)="cancel()">
          <span>
            {{ 'translations.global.button.cancel' | transloco }}
          </span>
        </button>
        <button
          [disabled]="isButtonClicked || !dataHasUpdated"
          mat-flat-button
          class="event-reminders_update-button uppercase"
          [attr.aria-label]="('translations.global.ariaLabels.eventRemindersUpdateButton' | transloco)"
          type="button"
          (click)="update()">
          <span>
            {{ 'translations.global.button.update' | transloco }}
          </span>
        </button>
      </div>
    </div>

  </div>
</mat-card>
