<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<ng-container *ngIf="quiz && quizTaken">
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-header__top">
      <h1 class="large header-title"
          [attr.aria-label]="('translations.global.ariaLabels.quizResultDialog' | transloco : {
            name: quiz.title
          })">
        {{quiz.title | getDataByLangCode}}
      </h1>

      <button mat-icon-button
              type="button"
              class="dialog-header__close-icon"
              tabindex="0"
              [attr.aria-label]="('translations.global.ariaLabels.closePublishPage' | transloco: {title: ''})"
              (click)="close()">
        <svg class="svg close-svg svg-icon" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
        </svg>
      </button>
    </div>
  </div>

  <mat-dialog-content class="quiz-dialog-container" [class.footer-enabled]="canRetake" *ngIf="responses">

    <div class="quiz-results-info">
      <div class="quiz-results__result">
        <span class="quiz-result-info__indicator-icon passed" *ngIf="quizPassed">{{ 'translations.quizReport.quizPassed' | transloco }}</span>
        <span class="quiz-result-info__indicator-icon failed" *ngIf="!quizPassed">{{ 'translations.quizReport.quizNotPassed' | transloco }}</span>
      </div>
      <div class="quiz-info">
        <div class="quiz-info-text">
         <span>{{ 'translations.yourScore' | transloco }}</span>
         <span class="quiz-info-score" [class]="quizPassed ? 'passed' : 'failed'">{{correctAnswersCount}}/{{responsesTotalCount}} ({{ correctAnswersPercent }}%)</span>
        </div>
        <div class="quiz-info-text bold" *ngIf="!quizPassed">
          {{
            'translations.quiz.label.toPassQuiz' | transloco: {
              requiredCount: summary?.totalRequired,
              allCount: summary?.totalQuestions,
              percentage: requiredToPassPercent,
            }
          }}
        </div>
      </div>
    </div>

    <ptl-quiz-results-report
      [reportType]="reportType"
      [quiz]="quiz"
      [isReviewResult]="isReviewResult"
      [responses]="responses"
      [currentLanguage]="currentLanguage"
      [organization]="organization">
    </ptl-quiz-results-report>

  </mat-dialog-content>

  <mat-dialog-actions align="end" *ngIf="canRetake">
    <button
      class="quiz-retake-btn"
      mat-flat-button
      [attr.aria-label]="('translations.global.ariaLabels.retakeQuiz' | transloco)"
      (click)="retake()">
      {{ 'translations.quiz.label.retakeQuiz' | transloco }}
    </button>
  </mat-dialog-actions>

</ng-container>
