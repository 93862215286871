<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="!newVersionEnabled">
  <div class="uploads-placeholder__container">
    <mat-icon aria-hidden="true" class="uploads-placeholder__icon" [ngClass]="{'animated' : !dataLoaded}">
      {{dataLoaded ? ( mediaType === 'IMAGE' ? 'insert_photo' : (mediaType === 'VIDEO' || mediaType === 'STREAM_VIDEO' ? 'movie' : 'description')) : 'autorenew'}}
    </mat-icon>
  </div>
</ng-container>

<ng-container *ngIf="newVersionEnabled">
  <div class="uploads-placeholder__container" [class.uploads-placeholder__container--height-max]="mediaType === 'IMAGE'">
    <p *ngIf="dataLoaded && description" class="uploads-placeholder__description">{{ description }}</p>
    <ptl-progress-bar-upload 
      [content]="content"
      (contentUploadProgressEmitter)="contentUploadProgress($event)">
    </ptl-progress-bar-upload>
  </div>
</ng-container>
