/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */


export function copyToClipboard(value: string) {
  const textarea = document.createElement('textarea');
  textarea.style.position = 'fixed';
  textarea.style.left = '0';
  textarea.style.top = '0';
  textarea.style.opacity = '0';
  textarea.value = value;
  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();
  /* eslint-disable */
  document.execCommand('copy'); // copy passed value
  /* eslint-enable */
  document.body.removeChild(textarea);
}
