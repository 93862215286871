import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {GroupElement, NestedChildData, TextElement} from '../../../../models';

@Component({
  selector: 'ptl-app-group-list-drop-down',
  templateUrl: './group-list-drop-down.component.html',
  styleUrls: ['./group-list-drop-down.component.scss']
})
export class GroupListDropDownComponent {

  @Input() option: TextElement[] | GroupElement[]
  @Input() selectedElement: {uId: string}
  @Input() uId: string
  @Input() i = 0
  @Input() groupNumber = 0
  @Output() groupSelectedData = new EventEmitter()

  onGroupSelect(data: NestedChildData) {
    this.groupSelectedData.emit(data)
  }

}
