/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UserAuthState } from '../../user-auth/store/user-auth.state';
import { Organization } from '../models/organization';
import { switchMap } from 'rxjs/operators';
import { LoadOrganizationDetails } from '../../user-auth/store/user-auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AllowRegistrationsGuard implements CanActivate {

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;


  constructor(private store: Store) {
  }

  /**
   * Checks if organization allow registration.
   * If not, the user will not be allowed to access the guarded page.
   *
   */
  canActivate(): Observable<boolean> {

    return this.organizationData$.pipe(switchMap(organization => {
      if (organization === undefined) {
        return this.store.dispatch(LoadOrganizationDetails).pipe(switchMap(() => {
          return this.organizationData$.pipe(switchMap(org => this.approveOrDeny(org)));
        }));
      }
      return this.approveOrDeny(organization);
    }));
  }


  private approveOrDeny(organization: Organization): Observable<boolean> {
    if (!organization) {
      return of(false);
    }
    return of(organization.allowRegistrations);
  }
}
