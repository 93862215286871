<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->


<div class="review-status-filter-container">
  <mat-card *ngIf="expanded" class="review-status-filter-settings">
    <div class="review-status-filter-settings__header">
      <span class="small">{{ 'translations.reviews.filter.status' | transloco }}</span>
      <button mat-icon-button [matMenuTriggerFor]="contextMenu" class="isBtn">
        <span>
          {{statusIs ? ('translations.reviews.filter.is' | transloco) : ('translations.reviews.filter.isNot' | transloco) }}
        </span>
        <mat-icon aria-hidden="true" class="icon">expand_more</mat-icon>
      </button>
      <span class="review-status-filter-delete" (click)="deleteFilter()" (keydown.enter)="deleteFilter()">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
        </svg>
      </span>
    </div>
    <div class="review-status-filter-settings__content">
      <div *ngFor="let status of statuses" class="review-status-filter-settings__item">
          <div class="review-status-filter-settings__checkmark checkmark" [class.is_checked]="status.value"
               (click)="changeStatus(status.type)"
               (keydown.enter)="deleteFilter()">
            <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.check' | transloco)">
              <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
            </svg>
          </div>
          <span
            (click)="changeStatus(status.type)"
            (keydown.enter)="deleteFilter()"
            class="review-status-filter-settings__label">{{ status.label }}</span>
      </div>
      <div class="review-status-filter-settings__footer">
        <button
          mat-stroked-button
          class="review-status-filter-settings__btn"
          (click)="applyFilter()">
          {{'translations.global.button.apply' | transloco}}
        </button>
      </div>
    </div>
  </mat-card>
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="setStatusIs()"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="'translations.global.ariaLabels.statusIs' | transloco">
      <span>
        {{'translations.reviews.filter.is' | transloco }}
      </span>
    </button>
    <button mat-menu-item (click)="setStatusIsNot()"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="'translations.global.ariaLabels.statusIsNot' | transloco">
      <span>
        {{'translations.reviews.filter.isNot' | transloco }}
      </span>
    </button>
  </ng-template>
</mat-menu>
