/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { EditorContentUpdateEvent, MediumEditorData } from '../../../../../../shared/models';
import { VersionHelper } from '../../../../../../shared/helpers/version.helper';


@Component({
  selector: 'ptl-group-question-type',
  templateUrl: './info-question-type.component.html',
  styleUrls: ['../add-question-section.component.scss', './info-question-type.component.scss'],
})
export class InfoQuestionTypeComponent implements OnInit {

  /** Receives the question to edit */
  @Input() question: FormGroup;

  /** Emits the medium-editor content change event. */
  @Output() mediumEditorChanged = new EventEmitter<string>();

  content: MediumEditorData = { type: 'PARAGRAPH', content: '' };

  newVersionEnabled = VersionHelper.newVersionEnabled();
  ptlFocused: boolean;

  onMediumEditorUpdate(event: EditorContentUpdateEvent) {
    this.content = event.update.newContent as MediumEditorData;
    this.mediumEditorChanged.emit(this.content.content);
  }

  ngOnInit() {
    // removing options since not present in INFO_QUESTION
    while ( (this.question.controls['options'] as FormArray).length !== 0 ) {
      (this.question.controls['options'] as FormArray).removeAt(0);
    }

    if ( this.question && this.question.value ) {
      this.content = {
        content: this.question.value.value.value,
        type: 'PARAGRAPH',
      };
    }
  }

}
