/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Input, OnInit } from '@angular/core';
import { ChartsHelper } from '../../../helpers/charts-helper';

@Component({
  selector: 'ptl-card-progress',
  templateUrl: './card-progress.component.html',
  styleUrls: ['./card-progress.component.scss'],
})
export class CardProgressComponent implements OnInit {

  @Input() totalCount: number;
  @Input() completedCount: number;

  @Input() completed: boolean;
  @Input() started: boolean;

  progress = 0;
  showProgress: boolean;

  ngOnInit() {
    if (this.totalCount === 0 || (this.completed && this.totalCount <= 0)) {
      this.showProgress = false;
    } else {
      this.showProgress = true;
    }
    this.calculateProgress();
  }

  private calculateProgress() {
    let progress = 0;
    if (this.completedCount === 0 && this.totalCount === 0) {
      progress = 0;
    } else {
      progress = ChartsHelper.calculateProgress(this.completedCount, this.totalCount);
    }
    this.progress = progress > 100 ? 100 : progress;
  }

}
