/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';

import { environment } from '../../environments/environment';
import { CORE_PLAYLIST_DATA_SERVICE } from '../page-modules/playlist/services/create/core/data.service';
import { RestClientService } from '../shared/services/rest-client.service';
import { playlistServiceFactory } from '../shared/shared-service-factory';
import { SharedModule } from '../shared/shared.module';
import { AccountVerificationComponent } from './components/account-verification/account-verification.component';
import { EmailRecoveryComponent } from './components/email-recovery/email-recovery.component';
import { FolioAccountVerificationComponent } from './components/folio-account-verification/folio-account-verification.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { IframeLogoutComponent } from './components/iframe-logout/iframe-logout.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LtiLaunchComponent } from './components/lti-launch/lti-launch.component';
import { LtiRegistrationErrorComponent } from './components/lti-registration-error/lti-registration-error.component';
// eslint-disable-next-line max-len
import { PasswordConfirmationAndRecoveryComponent } from './components/password-confirmation-and-recovery/password-confirmation-and-recovery.component';
import { RecoveryEmailVerificationComponent } from './components/recovery-email-verification/recovery-email-verification.component';
import { SignupDetailsFormComponent } from './components/signup-form/details-form/signup-details-form.component';
import { SignupFormComponent } from './components/signup-form/signup-form.component';
import { AccessTokenService } from './services/access-token.service';
import { ApiBasicAuthAuthService } from './services/api-basic-auth.service';
import { AUTH_SERVICE } from './services/basic-auth.service';
import { MockAuthService } from './services/mock-basic-auth.service';
import { UserAuthState } from './store/user-auth.state';
import { UnverifiedEmailComponent } from './components/unverified-email/unverified-email.component';
import { VerificationTemplateComponent } from './components/verification-template/verification-template.component';
import { ClassofAuthComponent } from './components/classof-auth/classof-auth.component';
import { ClassOfLoginComponent } from './components/classof-auth/classof-login/classof-login.component';
import { ClassofSignupComponent } from './components/classof-auth/classof-singup/classof-signup.component';
import { AlumniLoginComponent } from './components/alumni-login/alumni-login.component';
import { TranslationService } from '../shared/services/translation/translation.service';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { LtiPlaylistEditComponent } from './components/lti-playlist-edit/lti-playlist-edit.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { InvitationJoinComponent } from './components/invitation-join/invitation-join.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { SamlSPLaunchComponent } from './components/saml-sp/saml-sp.component';
import { SamlSpErrorComponent } from './components/saml-sp/saml-sp-error/saml-sp-error.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as SharedFactoryService from '../shared/shared-service-factory';
import { MatSelectModule } from '@angular/material/select';
import { PrearrivalsLoginFormComponent } from './components/prearrivals-login-form/prearrivals-login-form.component';
import { MatRadioModule } from '@angular/material/radio';
import { LtiDeeplinkingComponent } from './components/lti-deeplinking/lti-deeplinking.component';
import { LTI_AUTH_SERVICE, LtiAuthService } from './services/lti-auth.service';
import {
  FolioPublicMomentContentComponent
} from '../page-modules/folio/components/view/public-moment/public-moment-content.component';
import { PtlEditorModule } from '../editor/editor.module';

@NgModule({
  declarations: [
    LoginFormComponent,
    SignupDetailsFormComponent,
    ClassofAuthComponent,
    ClassOfLoginComponent,
    ClassofSignupComponent,
    AlumniLoginComponent,
    PrearrivalsLoginFormComponent,
    SignupFormComponent,
    AccountVerificationComponent,
    SamlSPLaunchComponent,
    SamlSpErrorComponent,
    LtiLaunchComponent,
    LtiDeeplinkingComponent,
    LtiPlaylistEditComponent,
    LtiRegistrationErrorComponent,
    IframeLogoutComponent,
    PasswordConfirmationAndRecoveryComponent,
    ForgotPasswordComponent,
    EmailRecoveryComponent,
    RecoveryEmailVerificationComponent,
    FolioAccountVerificationComponent,
    UnverifiedEmailComponent,
    VerifyEmailComponent,
    VerificationTemplateComponent,
    FolioPublicMomentContentComponent,
    InvitationJoinComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgxsModule.forFeature([
      UserAuthState,
    ]),
    TranslocoRootModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatCardModule,
    SharedModule,
    NgxCaptchaModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatRadioModule,
    PtlEditorModule,
  ],
  providers: [
    AccessTokenService,
    { provide: AUTH_SERVICE, useClass: environment.disableAuth ? MockAuthService : ApiBasicAuthAuthService },
    { provide: LTI_AUTH_SERVICE, useClass: LtiAuthService },
    {
      provide: CORE_PLAYLIST_DATA_SERVICE,
      useFactory: SharedFactoryService.playlistServiceFactory,
      deps: [RestClientService, TranslationService],
    },
  ],
  exports: [
    LoginFormComponent,
    SignupFormComponent,
  ],
})
export class UserAuthModule {
}
