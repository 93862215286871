/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../shared/store';
import { InjectionToken } from '@angular/core';
import { Resource } from '../../../shared/models';
import {
  EventCardTime,
  EventCardTypeRequest,
  EventReminderRequest,
  EventTickets,
  EventTicketsRequest
} from '../../resource/store/admin/resource-event-admin.state.model';
import { EventTicketAdminParticipateRequest, EventTicketAdminRegisterRequest } from 'src/app/shared/models/admin/members.model';
import { Observable } from 'rxjs';


/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const EVENT_CARDS_DATA_SERVICE = new InjectionToken<EventCardsDataService>('[Event Card] DataService');

/** The discovery data service. */
export interface EventCardsDataService {

  setEventCardTypes(eventUid: string, request: EventCardTypeRequest): ObservableResult<EventCardTypeRequest>;

  setEventCardDates(eventUid: string, request: EventCardTime): ObservableResult<Resource>;

  setEventTickets(eventUid: string, request: EventTicketsRequest): ObservableResult<EventTickets>;

  deleteEventTickets(eventUid: string): ObservableResult<EventTickets>;

  setEventReminders(eventUid: string, request: EventReminderRequest): ObservableResult<Resource>;

  registerToEvent(eventUid: string): ObservableResult<Resource>;

  unregisterFromEvent(eventUid: string): ObservableResult<Resource>;

  attendToEvent(eventUid: string): ObservableResult<Resource>;

  attendToEventFromQrCode(eventUid: string, participationCode: string): ObservableResult<Resource>;

  adminRegisterToEventBulk(eventUid: string, request: EventTicketAdminRegisterRequest): ObservableResult<EventTickets>;

  adminUnregisterFromEventBulk(eventUid: string, usersUids: string[]): ObservableResult<EventTickets>;

  adminAttendToEventBulk(eventUid: string, request: EventTicketAdminParticipateRequest): ObservableResult<EventTickets>;

  adminMoveToWaitingListEventBulk(eventUid: string, usersUids: string[]): ObservableResult<EventTickets>;

  exportEventCalendarEvent(eventUid: string, languageCode?: string): Observable<string>;
}

