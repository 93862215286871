import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsersUploadCsvError } from '../../../../../../shared/models/account/account.model';

@Component({
  templateUrl: './users-csv-upload-errors-dialog.component.html',
  styleUrls: ['./users-csv-upload-errors-dialog.component.scss'],
})
export class UsersCsvUploadErrorsDialogComponent {

  uploadErrors: UsersUploadCsvError[] = [];
  csvHeader: string[];
  csvRows: string[][] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { csvHeader: string[]; uploadErrors: UsersUploadCsvError[] },
    private dialogRef: MatDialogRef<UsersCsvUploadErrorsDialogComponent>,
  ) {
    this.uploadErrors = data.uploadErrors;
    this.csvHeader = data.csvHeader;

    if ( this.uploadErrors ) {
      // Map entries and return only the entry array
      this.csvRows = this.uploadErrors.map(error => {
        return this.csvHeader.map(header => error.entry[header]);
      });

    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  downloadErrorsCSV() {
    const csvRows = [];

    csvRows.push(this.csvHeader.join(','));

    this.csvRows.forEach(( error ) => {
      csvRows.push(error.join(','));
    });

    const csvContent = csvRows.join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'errors.csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
