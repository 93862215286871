/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../../shared/store';
import { InjectionToken } from '@angular/core';
import { Members } from '../../../../shared/models/admin/members.model';
import { Page } from '../../../../shared/models/page';
import {
  CreateOrganizationRoleRequest,
  OrganizationPrivilege,
  OrganizationRoles,
  UpdateOrganizationRoleRequest
} from '../../../../shared/models/organization';
import { UserRoleType } from '../../../../user-auth/models';


/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const ROLES_DATA_SERVICE = new InjectionToken<RolesDataService>('[Admin Roles] DataService');

/** The admin data service. */
export interface RolesDataService {

  /** Load organization roles. */
  loadOrganizationRoles(organizationUid: string): ObservableResult<OrganizationRoles[]>;

  /** Load organization role by id. */
  loadOrganizationRoleById(roleUid: string): ObservableResult<OrganizationRoles>;

  /** update organization role. */
  updateOrganizationRole(roleUid: string, request: UpdateOrganizationRoleRequest): ObservableResult<OrganizationRoles>;

  /** create organization role. */
  createOrganizationRole(request: CreateOrganizationRoleRequest): ObservableResult<OrganizationRoles>;

  /** Load organization role privileges. */
  loadOrganizationPrivileges(roleType: UserRoleType): ObservableResult<OrganizationPrivilege[]>;

  /** assign organization role to selected user. */
  assignRoleToUser(roleUid: string, userUid: string): ObservableResult<void>;

  /** remove organization role from assigned user. */
  removeRoleFromUser(roleUid: string, userUid: string): ObservableResult<void>;

  /** assign organization role to multiple users. */
  assignRoleToMultipleUsers(roleUid: string, request: string[]): ObservableResult<void>;

  /** assign multiple organization roles to multiple users. */
  assignMultipleRolesToMultipleUsers(roleUids: string[], userUids: string[]): ObservableResult<void>;

  /** remove organization role from multiple users. */
  removeRoleFromMultipleUsers(roleUid: string, request: string[]): ObservableResult<void>;

  /** remove multiple organization roles from multiple users. */
  removeMultipleRolesFromMultipleUsers(roleUids: string[], userUids: string[]): ObservableResult<void>;

  /** loading member by organization role. */
  loadMembersByRoleId(roleUid: string, page: number, size: number, term?: string): ObservableResult<Page<Members>>;

  /** loading roles with pagination. */
  loadRolesByPage(page: number, size: number): ObservableResult<Page<OrganizationRoles>>;

  /** loading roles with search and pagination. */
  loadRolesByPageWithSearch(searchText: string, page: number, size: number): ObservableResult<Page<OrganizationRoles>>;
}
