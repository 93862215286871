/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment.prod';
import { AppModule } from './app/app.module';
import { EnvironmentCode } from './environments/environment.model';
import { TRANSLATIONS_DATA } from './app/shared/constants/constants';
import { DEFAULT_LANGUAGE_CODE, LANGUAGE_KEY } from './app/shared/services/translation/translation.service';

if (environment.env === EnvironmentCode.prod) {
  enableProdMode();
}

let language = localStorage.getItem(LANGUAGE_KEY);
if (!language) {
  language = DEFAULT_LANGUAGE_CODE;
}
fetch(`/locale/messages.${language}.json?${(new Date()).getTime()}`)
  .then((response) => response.json())
  .then((data) => {
    TRANSLATIONS_DATA[language] = data;
    platformBrowserDynamic().bootstrapModule(AppModule);
  });

