<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="orientation !== 'HORIZONTAL_LARGE'">
  <button
    mat-icon-button
    class="favour-button"
    *ngIf="favorite !== undefined && favorite !== null"
    [attr.aria-label]="favorite ? ('translations.global.ariaLabels.remove' | transloco: {name: title}) + ' ' + ('translations.global.ariaLabels.fromFavorites' | transloco) : ('translations.global.ariaLabels.add' | transloco: {name: title}) + ' ' + ('translations.global.ariaLabels.toFavorites' | transloco)"
    [title]="!editMode ? (favorite ? ('translations.global.ariaLabels.remove' | transloco: {name: title}) + ' ' + ('translations.global.ariaLabels.fromFavorites' | transloco) : ('translations.global.ariaLabels.add' | transloco: {name: title}) + ' ' + ('translations.global.ariaLabels.toFavorites' | transloco)) : ''"
    [ngClass]="{
      'selected' : favorite && !editMode,
      'horizontal': orientation !== 'VERTICAL',
      'vertical': orientation === 'VERTICAL',
      'edit-mode' : editMode}"
    (click)="onFavoriteToggleClick(); $event.stopPropagation();"
    (keydown.enter)="$event.stopPropagation();">
    <i class="ptl-icon-bookmarkiOS favour-icon" aria-hidden="true" *ngIf="!(favorite && !editMode)"></i>
    <mat-icon aria-hidden="true" class="favour-icon" *ngIf="(favorite && !editMode)">
      bookmark
    </mat-icon>
  </button>
</ng-container>

<ng-container *ngIf="orientation === 'HORIZONTAL_LARGE'">
  <button
    class="favour-large-button"
    *ngIf="favorite !== undefined && favorite !== null"
    [attr.aria-label]="favorite ? ('translations.global.ariaLabels.remove' | transloco: {name: title}) + ' ' + ('translations.global.ariaLabels.fromFavorites' | transloco) : ('translations.global.ariaLabels.add' | transloco: {name: title}) + ' ' + ('translations.global.ariaLabels.toFavorites' | transloco)"
    [title]="!editMode ? (favorite ? ('translations.global.ariaLabels.remove' | transloco: {name: title}) + ' ' + ('translations.global.ariaLabels.fromFavorites' | transloco) : ('translations.global.ariaLabels.add' | transloco: {name: title}) + ' ' + ('translations.global.ariaLabels.toFavorites' | transloco)) : ''"
    (click)="onFavoriteToggleClick(); $event.stopPropagation();"
    (keydown.enter)="$event.stopPropagation();">
    <i class="ptl-icon-bookmarkiOS favour-icon" aria-hidden="true" *ngIf="!(favorite && !editMode)"></i>
    <mat-icon aria-hidden="true" class="favour-icon" *ngIf="(favorite && !editMode)">
      bookmark
    </mat-icon>
    <span class="uppercase" aria-hidden="true">{{ 'translations.bookmark' | transloco }}</span>
  </button>
</ng-container>


