<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="member-filters">
  <mat-divider class="member-filters-divider"></mat-divider>
  <div class="member-filters__summary" *ngIf="(!currentFilter && !expanded) || filters.length">
    <i class="ptl-icon-filter big-icon" aria-hidden="true"></i>
    <div *ngFor="let filter of filters; let i = index">
      <div *ngIf="!filter.request.type || !['FIRST_NAME', 'LAST_NAME', 'EXTERNAL_ID'].includes(filter.request.type)"
           class="member-filter__summary">
        <div>{{ filter.message }}</div>
        <button mat-icon-button class="member-filter__remove" aria-hidden="true" (click)="onRemoveFilter(i)">
          <mat-icon class="member-filter__remove__icon" aria-hidden="true">clear</mat-icon>
        </button>
      </div>
    </div>
    <button
      *ngIf="(!currentFilter && !expanded)"
      mat-button
      type="button"
      title="Add filter"
      [attr.aria-label]="('translations.members.filter.button.add' | transloco)"
      (click)="onExpand()"
      (keydown.enter)="onExpand()"
    >{{'translations.members.filter.button.plus' | transloco}}</button>
  </div>
  <ptl-member-filter
    class="member-filter"
    *ngIf="currentFilter; else filtersList"
    [type]="currentFilter"
    [isCardPage]="isCardPage"
    [isPlaylistPage]="isPlaylistPage"
    [cardUid]="cardUid"
    [playlistUid]="playlistUid"
    [cardHeaders]="cardHeaders"
    [excludedCards]="excludedCards"
    [excludedCardStatuses]="excludedCardStatuses"
    [formHeaders]="formHeaders"
    [excludedFormsTitle]="excludedFormsTitle"
    [excludedFormTitleStatuses]="excludedFormTitleStatuses"
    [excludedPlaylists]="excludedPlaylists"
    [excludedPlaylistStatuses]="excludedPlaylistStatuses"
    [excludedGroups]="excludedGroups"
    [excludedGroupStatuses]="excludedGroupStatuses"
    [excludedRoles]="excludedRoles"
    [excludedRoleStatuses]="excludedRoleStatuses"
    [excludedActivityStatuses]="excludedActivityStatuses"
    [excludedResourceStatuses]="excludedResourceStatuses"
    [excludedFormStatuses]="excludedFormStatuses"
    (save)="filterSaved($event)"
    (cancel)="filterCancelled()"
  >
  </ptl-member-filter>
  <mat-divider class="member-filters-divider"></mat-divider>
</div>

<ng-template #filtersList>
  <div class="member-filters__list" *ngIf="expanded">
    <div class="member-filters__header">
      <span>{{ 'translations.members.filter.label.filters' | transloco }}</span>
      <button mat-icon-button aria-hidden="true" (click)="onCollapse()">
        <mat-icon class="member-filters__collapse" aria-hidden="true">clear</mat-icon>
      </button>
    </div>
    <ptl-add-member-filter
      [name]="('translations.members.filter.name.role' | transloco)"
      [description]="('translations.members.filter.description.role' | transloco)"
      [type]="'ROLE'"
      [disabled]="excludedRoleStatuses.length > 1"
      (addFilter)="filterSelected($event)"
    ></ptl-add-member-filter>
    <ptl-add-member-filter
      [name]="('translations.members.filter.name.group' | transloco)"
      [description]="('translations.members.filter.description.group' | transloco)"
      [type]="'GROUP'"
      [disabled]="excludedGroupStatuses.length > 1"
      (addFilter)="filterSelected($event)"
    ></ptl-add-member-filter>
    <ptl-add-member-filter
      [name]="('translations.members.filter.name.activity' | transloco)"
      [description]="('translations.members.filter.description.activity' | transloco)"
      [type]="'ACTIVITY'"
      [disabled]="excludedActivityStatuses.length > 1"
      (addFilter)="filterSelected($event)"
    ></ptl-add-member-filter>
    <ptl-add-member-filter *ngIf="isPlaylistPage"
      [name]="('translations.members.filter.name.goal' | transloco)"
      [description]="('translations.members.filter.description.goal' | transloco)"
      [type]="'GOAL'"
      [disabled]="excludedResourceStatuses.length > 1"
      (addFilter)="filterSelected($event)"
    ></ptl-add-member-filter>
    <ptl-add-member-filter *ngIf="!isPlaylistPage && !isCardPage"
      [name]="('translations.members.filter.name.resources' | transloco)"
      [description]="('translations.members.filter.description.resources' | transloco)"
      [type]="'RESOURCES'"
      (addFilter)="filterSelected($event)"
    ></ptl-add-member-filter>
    <ptl-add-member-filter *ngIf="!isCardPage"
      [name]="!isPlaylistPage ? ('translations.members.filter.name.playlist' | transloco)
      : ('translations.members.filter.name.playlistStatus' | transloco)"
      [description]="!isPlaylistPage ? ('translations.members.filter.description.playlist' | transloco)
      : ('translations.members.filter.description.playlistStatus' | transloco)"
      [type]="'PLAYLIST_STATUS'"
      [disabled]="excludedPlaylistStatuses > 3"
      (addFilter)="filterSelected($event)"
    ></ptl-add-member-filter>
    <ptl-add-member-filter
      [name]="!isCardPage ? ('translations.members.filter.name.card' | transloco)
      : ('translations.members.filter.name.cardStatus' | transloco)"
      [description]="!isCardPage ? ('translations.members.filter.description.card' | transloco)
      : ('translations.members.filter.description.cardStatus' | transloco)"
      [type]="'CARD_STATUS'"
      [disabled]="excludedCardStatuses > 3"
      (addFilter)="filterSelected($event)"
    ></ptl-add-member-filter>
    <ptl-add-member-filter *ngIf="isCardPage"
      [name]="'translations.members.filter.name.form' | transloco"
      [description]="'translations.members.filter.description.form' | transloco"
      [type]="'FORM_STATUS'"
      [disabled]="excludedFormTitleStatuses > 3"
      (addFilter)="filterSelected($event)"
    ></ptl-add-member-filter>
    <ptl-add-member-filter *ngIf="isCardPage"
      [name]="('translations.members.filter.name.formStatus' | transloco)"
      [description]="('translations.members.filter.description.formStatuses' | transloco)"
      [type]="'FORMS'"
      [disabled]="excludedFormStatuses.length > 1"
      (addFilter)="filterSelected($event)"
    ></ptl-add-member-filter>
  </div>
</ng-template>
