<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="data-forms-container" [class.simple-form]="formsCount === 1"
     tabindex="0"
     [attr.aria-label]="('translations.global.ariaLabels.questionsForm' | transloco)" >

  <div class="dialog-header">
    <div class="dialog-header__top no-wrap">
        <span class="header-icon" *ngIf="false">
              <img src="{{(organizationData$| async)?.logo}}" class="dialog-header__logo" alt="" width="88" height="67"/>
        </span>
      <div class="dialog-header-title"
           tabindex="0"
           [attr.aria-label]="dataForm?.title">
        <h1>{{ dataForm?.title }}</h1>
      </div>
      <i *ngIf="canClose" class="material-icons dialog-header__close-icon"
         [attr.aria-label]="('translations.global.ariaLabels.closeQuestionsForm' | transloco)"
         (click)="onClose()"
         (keydown.enter)="onClose()"
         tabindex="0">close</i>
    </div>
  </div>
  <mat-stepper [linear]="false" #stepper>
    <mat-step *ngFor="let form of data; let index = index" class="hide-click">
      <ng-template matStepLabel>{{ form.dataForm?.title }}</ng-template>
      <div class="body">
        <div class="body-title label">
          <ptl-resource-preview
            [previewContent]="dataForm?.instructions">
          </ptl-resource-preview>
        </div>

        <form [formGroup]="formAnswers">
          <div class="body-form">
            <ng-container formArrayName="answers" *ngFor="let question of questions, let i = index">
              <ng-container [ngSwitch]="question.type">

                <div *ngSwitchCase="'TEXTBOX'" class="text-box" [hidden]="question.hidden">
                  <p class="text-box-title label"
                     tabindex="0"
                     [attr.aria-label]="question?.title">{{ question?.title }}</p>
                  <input
                    mat-input
                    class="text-box-answer"
                    type="text"
                    placeholder="Enter the answer"
                    [formControlName]="i"
                    tabindex="0"
                    [attr.aria-label]="question?.title?.endsWith(' *') ? ('translations.global.ariaLabels.textBox' | transloco: {isRequired: 'is required'}) : ('translations.global.ariaLabels.textBox' | transloco)"
                  >
                </div>

                <div *ngSwitchCase="'FIRST_NAME'" class="text-box" [hidden]="question.hidden">
                  <p class="text-box-title label"
                     tabindex="0"
                     [attr.aria-label]="question?.title?.endsWith(' *') ? ('translations.global.ariaLabels.firstName' | transloco: {text: 'is required'}) : ('translations.global.ariaLabels.firstName' | transloco: {text: question?.title})">{{ question?.title }}</p>
                  <input
                    mat-input
                    class="text-box-answer"
                    type="text"
                    placeholder="{{('translations.global.dataForms.dialog.enter' | transloco) + question?.title.toLowerCase() }}"
                    [formControlName]="i"
                  >
                </div>

                <div *ngSwitchCase="'LAST_NAME'" class="text-box" [hidden]="question.hidden">
                  <p class="text-box-title label"
                     tabindex="0"
                     [attr.aria-label]="question?.title?.endsWith(' *') ? ('translations.global.ariaLabels.lastName' | transloco: {text: 'is required'}) : ('translations.global.ariaLabels.lastName' | transloco: {text: question?.title})">{{ question?.title }}</p>
                  <input
                    mat-input
                    class="text-box-answer"
                    type="text"
                    placeholder="{{('translations.global.dataForms.dialog.enter' | transloco) + question?.title.toLowerCase() }}"
                    [formControlName]="i"
                  >
                </div>

                <div *ngSwitchCase="'CHECKBOX'" class="checkbox" [hidden]="question.hidden">
                  <p class="checkbox-title"
                     tabindex="0"
                     [attr.aria-label]="('translations.global.ariaLabels.checkbox' | transloco: {question: ': ' + question?.title})">{{ question?.title }}</p>
                  <div class="checkbox-box">
                    <mat-checkbox
                      #checkbox
                      class="checkbox-answer label"
                      [aria-label]="question?.title"
                      color="primary"
                      (change)="onChecked($event, question.uid)"
                      (keydown)="onChecked($event, question.uid)"
                      [formControlName]="i"
                    >
                    </mat-checkbox>
                  </div>
                </div>


                <div *ngSwitchCase="'GROUP_LIST'" class="select-box" [hidden]="question.hidden">
                  <p class="select-title label"
                     tabindex="0"
                     [attr.aria-label]="question?.title">{{ question?.title }}</p>
                  <div class="custom-mat-select f_custom-select" tabindex="0"
                       [class.has-error]="controls[i].hasError('required') && controls[i].touched && formAnswers.status === 'INVALID'"
                       (click)="openCustomSelect(i)"
                       (keydown.enter)="openCustomSelect(i)"
                       [attr.aria-label]="question?.title?.endsWith(' *') ? ('translations.global.ariaLabels.selectBox' | transloco: {isRequired: 'is required'}) : ('translations.global.ariaLabels.selectBox' | transloco)">
                    <mat-label class="select-label" *ngIf="activeCustomSelect === i">{{question.placeholder}}</mat-label>
                    <div class="input-text">
                      <div class="placeholder"
                           *ngIf="!customSelectSelectedText[question.uid]">{{question.placeholder}}</div>
                      <div class=" placeholder text"
                           #selectedAnswerBox
                           *ngIf="customSelectSelectedText[question.uid]"
                           [matTooltipDisabled]="selectedAnswerText.innerText.length * 4.25 < selectedAnswerBox.offsetWidth"
                           [matTooltip]="customSelectSelectedText[question.uid]"
                           [matTooltipPosition]="'below'">
                        <span #selectedAnswerText>{{customSelectSelectedText[question.uid]}}</span>
                      </div>
                      <span class="arrow-icon">
                          <mat-icon aria-hidden="true">expand_more</mat-icon>
                      </span>
                    </div>

                    <ng-container *ngIf="activeCustomSelect === i">
                      <ptl-app-group-list-drop-down class="custom-mat-optgroup"
                                                    [option]="question.options"
                                                    [i]="i"
                                                    [selectedElement]="selectedElement"
                                                    [uId]="question.uid"
                                                    (groupSelectedData)="onGroupSelect($event, i, question.uid)"></ptl-app-group-list-drop-down>
                    </ng-container>
                  </div>
                </div>

                <div *ngSwitchCase="'LIST'" class="checkbox checkbox-list" [hidden]="question.hidden">
                  <p class="checkbox-title"
                     tabindex="0"
                     [attr.aria-label]="('translations.global.ariaLabels.checkbox' | transloco: {question: ': ' + question?.title})">{{ question?.title }}</p>

                  <div class="checkbox-box-list-container">

                    <div *ngFor="let option of question.options" class="checkbox-list-item">
                      <p class="title" tabindex="0">{{ option.value }}</p>
                      <mat-checkbox
                        class="checkbox-answer label"
                        [aria-label]="option.value"
                        color="primary"
                        (change)="onCheckListItem($event, option.value)"
                        (keydown)="onCheckListItem($event, option.value)"
                        [formControlName]="i"
                      >
                      </mat-checkbox>
                    </div>
                  </div>
                </div>


                <!-- not supported
                <div *ngSwitchCase="'RATING'" class="radio-box">
                  <span class="radio-title">{{ question?.title }}</span>
                  <mat-radio-group  [(ngModel)]="formAnswers[i]">
                    <mat-radio-button
                      color="primary"
                      [value]="option"
                      [tabIndex]="index"
                      *ngFor="let option of question.options; let index = index">
                      <span>{{ option.name }}</span>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                -->

              </ng-container>
            </ng-container>
          </div>
        </form>
      </div>
      <div class="footer">
        <div class="footer-buttons">
          <ng-container *ngIf="canClose">
            <button *ngIf="canSkip; else cancelButton" class="cancel" mat-button (click)="onSkip()"
                    tabindex="0"
                    [attr.aria-label]="('translations.global.ariaLabels.skipForm' | transloco)">
              <span>{{ 'translations.global.dataForms.dialog.skip' | transloco }}</span>
            </button>
            <ng-template #cancelButton>
              <button class="cancel" mat-button (click)="onClose()"
                      tabindex="0"
                      [attr.aria-label]="('translations.global.ariaLabels.cancelForm' | transloco)">
                <span>{{ 'translations.global.dataForms.dialog.cancel' | transloco }}</span>
              </button>
            </ng-template>
          </ng-container>
        </div>
        <div class="footer-text label">
          <span
            tabindex="0"
            [attr.aria-label]="('translations.global.dataForms.dialog.shared' | transloco)">
            {{ 'translations.global.dataForms.dialog.shared' | transloco }}
          </span>
        </div>

        <div class="footer-buttons">
          <button class="submit" mat-button (click)="onSave()"
                  tabindex="0"
                  [attr.aria-label]="('translations.global.ariaLabels.submitForm' | transloco)">
            <span>{{ 'translations.global.dataForms.dialog.submit' | transloco }}</span>
          </button>
          <button matStepperNext [hidden]="true" #nextStep>Next</button>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
