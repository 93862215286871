<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<ng-container *ngIf="!!linkData">

  <mat-card
    [ngClass]="{
    'link-card': linkData.linkStyle === 'TEXT_COLOR_BACKGROUND',
    'card': linkData.linkStyle !== 'TEXT_COLOR_BACKGROUND',
    'edit-view': type === 'EDIT',
    'disabled': disabled
    }"
    [class.link-card--view]="type === 'VIEW'"
    [class.link-card--slim]="size === 'SLIM'"
    [style.background]="linkData.linkStyle === 'TEXT_COLOR_BACKGROUND' ?  linkData.backgroundColor : ''"
    (click)="type === 'EDIT' ? $event.preventDefault() : openLink(false)"
    (keydown.enter)="type === 'EDIT' ? $event.preventDefault() : openLink(false)"
    (contextmenu)="onContextMenu($event)"
    tabindex="0"
    role="listitem"
    [ngStyle]="{'cursor': inHomeLayoutEditor ? 'default' : ''}"
    [attr.aria-label]="('translations.global.ariaLabels.open' | transloco: {title: linkData.headline})">

    <ng-container *ngIf="newVersionEnabled">
      <button mat-icon-button class="remove-btn" *ngIf="(linkData.destination === 'WEBLINK' ||
                                                        linkData.destination === 'PAGE') && type === 'EDIT' &&
                                                        !disabled"
              [title]="'translations.global.button.remove' | transloco"
              (click)="onRemoveClick($event)"><mat-icon aria-hidden="true">clear</mat-icon></button>

      <button mat-icon-button class="edit-btn" *ngIf="linkData.destination === 'WEBLINK' && type === 'EDIT' &&
                                                        !disabled"
              [title]="'translations.global.button.edit' | transloco"
              (click)="onEditClick()"><mat-icon aria-hidden="true">edit</mat-icon></button>
    </ng-container>

    <ng-container *ngIf="!newVersionEnabled">
    <button mat-icon-button class="remove-btn" *ngIf="linkData.destination === 'PAGE' && type === 'EDIT' && !disabled"
            [title]="'translations.global.button.remove' | transloco"
            (click)="onRemoveClick($event)"><mat-icon aria-hidden="true">clear</mat-icon></button>
    </ng-container>


    <div class="context-menu"
         [ngStyle]="{ 'left' : contextMenuPosition.x, 'top' : contextMenuPosition.y }"
         [matMenuTriggerFor]="contextMenu">
    </div>
    <mat-menu #contextMenu="matMenu">
      <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="openLink(true)">
          <span>{{ 'translations.openLinkInANewTab' | transloco }}</span>
        </button>
      </ng-template>
    </mat-menu>


    <div class="link-card__center"
         *ngIf="linkData.linkStyle === 'TEXT_COLOR_BACKGROUND'"
         [style.color]="linkData.fontColor"
         [style.font-size]="(linkData.fontSize / 16) + 'rem'">
      {{ linkData.text }}
    </div>

    <div *ngIf="linkData.linkStyle !== 'TEXT_COLOR_BACKGROUND'"
         [class.text-on-image]="linkData.linkStyle === 'TEXT_IMAGE_BACKGROUND'">
      <div class="card__center">
        <img class="center__image" alt="" width="266" height="150" [src]="linkData.previewImage ?
                      linkData.previewImage : linkData.thumbnail ? linkData.thumbnail :
                       '../../../../assets/card-placeholders/card-placeholder-' + ((index % 4) + 1) + '.png'"/>
      </div>
      <div class="card-info-box" [class.has-bottom-border]="linkData.linkStyle === 'TEXT_IMAGE' && type !== 'EDIT'">
        <div class="card__header-box">
          <span class="card__header">
            {{ linkData.headline }}
          </span>
        </div>
        <p class="card__description">
          {{ linkData.subLine }}
        </p>
      </div>
    </div>

    <ng-container *ngIf="!newVersionEnabled">
      <div class="link-card__buttons-container"
           *ngIf="type === 'EDIT' && linkData.destination === 'WEBLINK'">
        <ng-container>
          <mat-menu #appMenu="matMenu">
            <button mat-menu-item (click)="onEditClick()">
              {{ 'translations.global.button.edit' | transloco }}
            </button>
            <button mat-menu-item (click)="onRemoveClick($event)">{{ 'translations.global.button.remove' | transloco }}</button>
          </mat-menu>
          <button class="settings-button" mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon aria-hidden="true">more_vert</mat-icon>
          </button>
        </ng-container>
      </div>
    </ng-container>


    <div class="disabled-overlay" *ngIf="disabled"></div>

  </mat-card>

</ng-container>
