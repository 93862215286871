import { Pipe, PipeTransform } from '@angular/core';
import { LanguageCodeHelper } from '../helpers/language-code-helper';
import { LanguageValue } from '../models';

@Pipe({
  name: 'getDataByLangCode'
})

export class GetDataByLangCodePipe implements PipeTransform {
  transform(value: LanguageValue[]): string | undefined {
    return value ? LanguageCodeHelper.getDataByLanguageCode(value)?.value : undefined;
  }
}
