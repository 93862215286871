<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="target-main-container-new">
  <mat-card class="version-new-card">
    <div class="top-section">
      <div class="header-new">
        <h1 class="large header-title"
            [attr.aria-label]="('translations.global.ariaLabels.thisIsTargetTitle' | transloco)">
          {{ 'translations.pages.target.title' | transloco }}</h1>
        <p class="info small">
          {{ 'translations.pages.target.whoCanView' | transloco }}
        </p>
      </div>

      <mat-radio-group>
        <mat-radio-button
          value="first"
          name="layoutType"
          tabindex="0"
          [checked]="selectedOrganizationOnly"
          [aria-label]="'translations.global.ariaLabels.members' | transloco"
          (click)="$event.preventDefault(); onToggleChangeOrganizationVisibility($event)"
          (change)="onToggleChangeOrganizationVisibility($event)"
          color="primary">
          <span>
          {{ 'translations.pages.target.label.onlyOrganizationMembers' | transloco: {orgName: (organizationData$ | async)?.name} }}
          </span>
        </mat-radio-button>

        <mat-radio-button
          value="second"
          name="layoutType"
          tabindex="0"
          [checked]="selectedGroupsOnly"
          [attr.aria-label]="'translations.global.ariaLabels.selectedGroups' | transloco"
          (click)="$event.preventDefault(); onToggleChangeGroupsVisibility($event)"
          (change)="onToggleChangeGroupsVisibility($event)"
          color="primary">
        <span>
          {{ 'translations.pages.target.label.onlySelectedGroup' | transloco }}
        </span>
        </mat-radio-button>

        <mat-radio-button
          value="third"
          name="layoutType"
          [checked]="selectedMembersOnly"
          (change)="onToggleChangeMembersVisibility($event)"
          (click)="$event.preventDefault(); onToggleChangeMembersVisibility($event)"
          tabindex="0"
          color="primary">
          <span>
          {{ 'translations.pages.target.label.onlySelectedMembers' | transloco }}
          </span>
        </mat-radio-button>

        <mat-radio-button value="forth"
                          name="layoutType"
                          [checked]="selectedOnlyMe"
                          (change)="onToggleChangeOnlyMeVisibility($event)"
                          (click)="$event.preventDefault(); onToggleChangeOnlyMeVisibility($event)"
                          tabindex="0"
                          color="primary">
          <span>
          {{ 'translations.pages.target.label.onlyMe' | transloco }}
          </span>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="search-items-container">
      <ng-container *ngIf="selectedMembersOnly">
        <div class="form-info small bold">
          {{ 'translations.pages.target.label.selectPeople' | transloco }}
        </div>

        <ptl-user-search-autocomplete-new
          #userSearchAutocomplete
          [loadingMembers]="loadingMembers"
          [placeholder]="('translations.global.label.search' | transloco)"
          [members]="searchedMembers"
          (searchLoadedMore)="onSearchLoadingMore('MEMBER')"
          (searchInputChanged)="onUserSearchInputChange($event)"
          (memberSelected)="onMemberSelected($event)">
        </ptl-user-search-autocomplete-new>
      </ng-container>

      <ng-container *ngIf="selectedGroupsOnly">
        <div class="form-info small bold">
          {{ 'translations.pages.target.label.selectGroup' | transloco }}
        </div>

        <ptl-group-search-autocomplete
          #groupSearchAutocomplete
          [loadingGroups]="loadingGroups"
          [placeholder]="('translations.global.label.search' | transloco)"
          [groups]="searchedGroups"
          (searchInputChanged)="onGroupSearchInputChange($event)"
          (groupSelected)="onGroupSelected($event)"
          (paste)="onTextPaste($event)"
        ></ptl-group-search-autocomplete>
      </ng-container>
    </div>
  </mat-card>

  <mat-card class="version-new-card target-audience-section">

    <ng-container *ngIf="selectedGroupsOnly">
      <div class="target-audience-members" *ngIf="groups?.length">
        <div class="target-audience-members-header">
          <div class="name-cell row-cell small bold">
            {{ 'translations.pages.target.label.groupName' | transloco }}
          </div>
          <div class="remove-cell row-cell small bold">
            <div class="remove-cell-inner">
              {{'translations.global.label.remove' | transloco }}
            </div>
          </div>
        </div>
        <div class="target-audience-members-row" *ngFor="let group of groups">
          <div class="name-cell row-cell small">
              <span class="name">
               {{ group.name }}
              </span>
          </div>
          <div class="remove-cell row-cell small">
            <div class="remove-cell-inner">
              <button
                mat-icon-button
                type="button"
                [attr.aria-label]="('translations.global.ariaLabels.remove' | transloco)"
                (click)="deleteGroup(group._id)">
                <svg class="svg svg-icon" aria-hidden="true">
                  <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>


    <ng-container *ngIf="selectedMembersOnly">
      <div class="target-audience-members" *ngIf="members?.length">
        <div class="target-audience-members-header">
          <div class="name-cell row-cell small bold">
            {{ 'translations.global.label.firstName' | transloco }}
          </div>
          <div class="remove-cell row-cell small bold">
            <div class="remove-cell-inner">
              {{'translations.global.label.remove' | transloco }}
            </div>
          </div>
        </div>
        <div class="target-audience-members-row" *ngFor="let member of members">
          <div class="name-cell row-cell small">
              <span class="name">
               {{ member.name }}
              </span>
          </div>
          <div class="remove-cell row-cell small">
            <div class="remove-cell-inner">
              <button
                mat-icon-button
                type="button"
                [attr.aria-label]="('translations.global.ariaLabels.remove' | transloco: {name: member.name})"
                (click)="deleteMember(member._id)">
                <svg class="svg svg-icon" aria-hidden="true">
                  <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-card>
</div>
