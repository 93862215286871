<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ptl-media-content-view
  [isUploadedImage]="contentItem?.type === 'MEDIA_URL' && contentItem?.mediaType === 'IMAGE'"
  [position]="contentItem?.position || 'CENTER'"
  (removeMediaContent)="removeMedia()"
  [canRemoveItem]="canRemoveItem"
  (mediaContentReplaced)="onReplaceMediaContent($event)"
  (regularLinkReplaced)="onReplaceRegularLink($event)"
  (updateMediaPosition)="onUpdateMediaPosition($event)">
  <div class="content-media__oembed content-media__oembed--disabled" #oembedEl></div>
</ptl-media-content-view>
