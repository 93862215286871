import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BooleanFilterType } from '../../../models/admin/members.model';

@Component({
  selector: 'ptl-filter-btn',
  templateUrl: './filter-btn.component.html',
  styleUrls: ['./filter-btn.component.scss']
})
export class FilterBtnComponent {

  @Input() labels: { type: BooleanFilterType; value: string }[];
  @Output() filterSelected = new EventEmitter<BooleanFilterType>();

  expanded = false;

  expand() {
    this.expanded = !this.expanded;
  }

  selectFilter(type: BooleanFilterType) {
    this.filterSelected.emit(type);
  }
}
