<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="dialog-header">
  <div class="dialog-header__top">
    <img src="{{(organizationData$| async)?.logo}}" class="dialog-header__logo" alt="" width="88" height="67"/>
    <h1 class="dialog-header-title"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.discoveryShareDialogTitle' | transloco">
      {{ 'translations.discoveryShareDialogTitle' | transloco }}</h1>
    <i class="material-icons dialog-header__close-icon"
       [attr.aria-label]="('translations.global.ariaLabels.closeInsertFeaturedSection' | transloco)"
       (click)="onCloseDialog()"
       (keydown.enter)="onCloseDialog()"
       tabindex="0">close</i>
  </div>

  <div class="dialog-header-active-tabs">
    <div class="dialog-header-tab-item"
         [class.is_active]="activePage === 0"
         tabindex="0"
         (click)="activePage > 0 ? setPage(0) : null"
         (keydown.enter)="activePage > 0 ? setPage(0) : null">
      {{ 'translations.discoveryShareSelectPlaylistPrepareSubmit' | transloco }}
    </div>
    <div class="dialog-header-tab-item">
      <mat-icon aria-hidden="true">east</mat-icon>
    </div>
    <div class="dialog-header-tab-item" [class.is_active]="activePage === 1"
         tabindex="0"
         (click)="activePage > 1 ? setPage(0) : null"
         (keydown.enter)="activePage > 1 ? setPage(0) : null">
      {{ 'translations.discoveryShareSelectPlaylistPrepareSubmit' | transloco }}
    </div>
    <div class="dialog-header-tab-item">
      <mat-icon aria-hidden="true">east</mat-icon>
    </div>
    <div class="dialog-header-tab-item" [class.is_active]="activePage === 2">
      {{ 'translations.global.label.review' | transloco }}
    </div>
  </div>

</div>
<div class="content">

  <ptl-discovery-share-playlists
    *ngIf="activePage === 0"
    (playlistSelected)="onPlaylistSelected($event)">
  </ptl-discovery-share-playlists>

  <ptl-discovery-share-prepare-submit
    *ngIf="activePage === 1"
    [playlist]="selectedPlaylist"
    (canceledSharePlaylist)="onCloseDialog()"
    (reviewPlaylist)="requestReviewPlaylist($event)">
  </ptl-discovery-share-prepare-submit>

  <ptl-discovery-share-review *ngIf="activePage === 2" (shareFinished)="onCloseDialog()"></ptl-discovery-share-review>
</div>
