import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import {
  GLOBAL_SEARCH_DATA_SERVICE,
  GlobalSearchDataService
} from 'src/app/page-modules/global-search/services/global-search-data.service';
import { canTriggerSearch } from 'src/app/shared/helpers/content-helper';
import { SearchPlaylist } from 'src/app/shared/models';
import { CardHeaders, FormHeaders } from 'src/app/shared/models/admin/members.model';
import {
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType,
  CardStatusRequest,
  CompletionBooleanFilter,
  CompletionFilterType,
  CompletionStatus,
  CompletionType,
  FormStatusRequest,
  PlaylistStatusRequest,
  ResourcesStatusRequest
} from '../../../models/admin/boolean-filters.model';
import { TranslationService } from '../../../services/translation/translation.service';


@Component({
  selector: 'ptl-completion-filter',
  templateUrl: './completion-filter.component.html',
  styleUrls: ['./completion-filter.component.scss']
})
export class CompletionFilterComponent implements OnInit, OnDestroy {

  @Input() selectedFilters: BooleanFilter[];
  @Input() isPlaylistPage: boolean;
  @Input() playlistUid: string;
  @Input() isCardPage: boolean;
  @Input() cardUid: string;
  @Input() cardHeaders: CardHeaders[];
  @Input() formHeaders: FormHeaders[];
  @Output() filterDeleted = new EventEmitter<void>();
  @Output() filterSaved = new EventEmitter<BooleanFilter>();

  expanded = true;
  completionType: CompletionType = 'COMPLETED';
  filterType: CompletionFilterType;
  maxResourceCount: number;
  resourceCount = 1;
  resourceCountError = false;
  certainResources: { uid: string; value: boolean; label: string }[] = [];
  shouldDisplayActivitiesFilters = false;
  isAdminPage = false;
  resourcesUidsFromStore: string[]

  // admin playlist search
  private page = 0;
  playlistSearchString = '';
  searchedPlaylists: SearchPlaylist[];
  loadingSearch: boolean;
  canLoadMore = false;
  selectedPlaylists: { uid: string; value: boolean; label: string }[] = [];
  private playlistSearchSubject = new Subject<void>();
  private playlistSearchSubscription: Subscription;

  constructor(
    private translationService: TranslationService,
    @Inject(GLOBAL_SEARCH_DATA_SERVICE) private globalSearchDataService: GlobalSearchDataService
  ) {
    this.playlistSearchSubscription = this.playlistSearchSubject
      .pipe(debounceTime(500)).subscribe(() => {this.page = 0; this.firePlaylistsSearch(true)});
  }

  ngOnInit() {
    this.isAdminPage = !this.isPlaylistPage && !this.isCardPage;
    if (this.isAdminPage) {
      this.initializeAdminPage();
    } else {
      this.initializeCardPlaylistPage();
    }
  }

  private initializeAdminPage() {
    this.filterType = 'CERTAIN_ACTIVITIES';
    this.getSettingsFromFilters();
    this.firePlaylistsSearch(true, true);
  }

  private initializeCardPlaylistPage() {
    this.filterType = 'EVERYTHING';
    this.initCertainActivities();
    this.getSettingsFromFilters();
    this.setResourcesSelected();
    this.maxResourceCount = this.getMaxRequiredResourceCount();
    this.shouldDisplayActivitiesFilters = this.maxResourceCount > 0;
  }

  ngOnDestroy() {
    this.playlistSearchSubscription?.unsubscribe();
  }

  deleteFilter() {
    this.filterDeleted.emit();
  }

  setCompletionType(completionType: CompletionType) {
    this.completionType = completionType;
  }

  applyFilter() {
    this.validateResourceCountField();
    if ((this.filterType === 'AT_LEAST' || this.filterType === 'AT_MOST') && this.resourceCountError) {
        return;
    }
    const request = this.getRequest();
    const message = this.getMessage();
    this.filterSaved.emit(
      {
        request: request,
        message: message,
        type: 'COMPLETION',
        resourceCount: this.resourceCount,
        completionType: this.completionType,
        completionFilterType: this.filterType,
        resourcesUids: this.getSelectedCertainActivities().map(it => it.uid)
      } as CompletionBooleanFilter
    );
  }

  onInputChange() {
    this.resourceCountError = false;
  }

  validateResourceCountField() {
    this.resourceCountError = this.resourceCount === null || this.resourceCount > this.maxResourceCount;
  }

  changeActivity(resource: { uid: string; value: boolean; label: string }) {
    this.certainResources = this.certainResources.map(r => {
      if (resource.uid === r.uid) {
        r.value = !r.value;
      }
      return r;
    });
    if (this.isAdminPage) {
      if (this.selectedPlaylists.find(playlist => playlist.uid === resource.uid)) {
        this.selectedPlaylists = this.selectedPlaylists.filter(playlist => playlist.uid !== resource.uid);
      } else {
        this.selectedPlaylists = this.selectedPlaylists.concat(resource);
      }
    }
  }

  initCertainActivities() {
    if (this.isPlaylistPage) {
      this.certainResources = this.cardHeaders.map(card => {
        return { uid: card.cardId, value: false, label: card.header }
      })
      this.certainResources?.sort((a, b) => a.label.localeCompare(b.label));
    } else if(this.isCardPage) {
      this.certainResources = this.formHeaders.map(form => {
        return { uid: form.formId, value: false, label: form.header }
      })
      this.certainResources?.sort((a, b) => a.label.localeCompare(b.label));
    }
  }

  onFocus(): void {
    if (this.playlistSearchString === undefined) {
      this.playlistSearchString = '';
      this.playlistSearchSubject.next();
    }
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        if (this.playlistSearchString  !== undefined) {
          this.loadingSearch = true;
          this.playlistSearchSubject.next();
        }
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      if (this.playlistSearchString !== undefined) {
        this.loadingSearch = true;
        this.playlistSearchSubject.next();
      }
    }
  }

  loadNextPageOfPlaylists() {
    this.page = this.page + 1;
    this.firePlaylistsSearch(false);
  }

  private getRequest(): BooleanQueryRequest {
    switch (this.filterType) {
      case 'EVERYTHING': return this.getEverythingFilterTypeRequest();
      case 'CERTAIN_ACTIVITIES': return this.getCertainActivitiesFilterTypeReques();
      case 'AT_LEAST': return this.getRequiredResourceRequest('AT_LEAST');
      case 'AT_MOST': return this.getRequiredResourceRequest('AT_MOST');
    }
  }

  private getEverythingFilterTypeRequest(): BooleanQueryRequest {
    if (this.isPlaylistPage) {
      const request: PlaylistStatusRequest = {
        type: BooleanQueryType.PLAYLIST_STATUS,
        uid: this.playlistUid,
        status: this.getCompletionStatusFromCompletionType()
      }
      return this.negateRequestIfNeeded(request);
    } else if (this.isCardPage) {
      const request: CardStatusRequest = {
        type: BooleanQueryType.CARD_STATUS,
        uid: this.cardUid,
        status: this.getCompletionStatusFromCompletionType()
      }
      return this.negateRequestIfNeeded(request);
    } else {
      return undefined;
    }
  }

  private getCertainActivitiesFilterTypeReques(): BooleanQueryRequest {
    if (this.isAdminPage) {
      const request: PlaylistStatusRequest[] = this.getSelectedCertainActivities().flatMap(playlist => {
        const r: PlaylistStatusRequest = {
          type: BooleanQueryType.PLAYLIST_STATUS,
          uid: playlist.uid,
          status: this.getCompletionStatusFromCompletionType(),
        }
        return r;
      })
      return this.negateRequestIfNeededArray(request);
    } else if (this.isPlaylistPage) {
      const request: CardStatusRequest[] = this.getSelectedCertainActivities().flatMap(card => {
        const r: CardStatusRequest = {
          type: BooleanQueryType.CARD_STATUS,
          uid: card.uid,
          status: this.getCompletionStatusFromCompletionType(),
        }
        return r;
      })
      return this.negateRequestIfNeededArray(request);
    } else if (this.isCardPage) {
      const request: FormStatusRequest[] = this.getSelectedCertainActivities().flatMap(form => {
        const r: FormStatusRequest = {
          type: BooleanQueryType.FORM_STATUS,
          uid: form.uid,
          status: this.getCompletionStatusFromCompletionType(),
        }
        return r;
      })
      return this.negateRequestIfNeededArray(request);
    } else {
      return undefined;
    }
  }

  private getRequiredResourceRequest(operator: 'AT_LEAST' | 'AT_MOST'): BooleanQueryRequest {
    const request: ResourcesStatusRequest = {
      type: BooleanQueryType.RESOURCES_STATUS,
      resourcesType: this.getAtLeastAtMostResourcesTypeFilterValue(),
      operator: operator,
      amount: this.resourceCount,
      status: this.getCompletionStatusFromCompletionType(),
      required: false
    }
    return this.negateRequestIfNeeded(request);
  }

  private getAtLeastAtMostResourcesTypeFilterValue(): 'FORMS' | 'CARDS' | 'PLAYLISTS' {
    if (this.isAdminPage) {
      return 'PLAYLISTS'
    } else if (this.isPlaylistPage) {
      return 'CARDS'
    } else {
      return 'FORMS'
    }
  }

  private negateRequestIfNeeded(request: BooleanQueryRequest): BooleanQueryRequest {
    if (this.isNegation()) {
      return { type: BooleanQueryType.NOT, arg: request } as BooleanQueryRequest;
    }
    return request;
  }

  private negateRequestIfNeededArray(requests: BooleanQueryRequest[]): BooleanQueryRequest {
    if (this.isNegation()) {
      const negatedRequests = requests.map(it => {
        return { type: BooleanQueryType.NOT, arg: it }
      });
      return { type: BooleanQueryType.AND, args: negatedRequests} as BooleanQueryRequest;
    } else {
      return { type: BooleanQueryType.AND, args: requests } as BooleanQueryRequest
    }
  }

  private getCompletionStatusFromCompletionType(): CompletionStatus {
    return this.completionType === 'COMPLETED' || this.completionType === 'NOT_COMPLETED' ? 'COMPLETED' : 'STARTED';
  }

  private getMessage(): string {
    return this.translationService.getTranslation('filter.has')
      + ' ' + this.getCompletionTypeTranslation()
      + ' ' + this.getFilterTypeTranslation();
  }

  private getCompletionTypeTranslation(): string {
    switch (this.completionType) {
      case 'COMPLETED': return this.translationService.getTranslation('filter.completion.type.completed')
      case 'NOT_COMPLETED': return this.translationService.getTranslation('filter.completion.type.notCompleted')
      case 'STARTED': return this.translationService.getTranslation('filter.completion.type.started')
      case 'NOT_STARTED': return this.translationService.getTranslation('filter.completion.type.notStarted')
    }
  }


  private getFilterTypeTranslation(): string {
    switch (this.filterType) {
      case 'EVERYTHING': return this.translationService.getTranslation('filter.completion.filterType.everything').toLowerCase();
      case 'CERTAIN_ACTIVITIES': {
        return this.getSelectedCertainActivities().map(resource => resource.label)
          .join(' ' + this.translationService.getTranslation('filter.and') + ' ');
      }
      case 'AT_LEAST': {
        return (this.translationService.getTranslation('filter.completion.atLeast')
        + ` ${this.resourceCount} `
        + this.getResourceMessageForm()).toLowerCase();
      }
      case 'AT_MOST': {
        return (this.translationService.getTranslation('filter.completion.atMost')
        + ` ${this.resourceCount} `
        + this.getResourceMessageForm()).toLowerCase();
      }
    }
  }

  private getResourceMessageForm(): string {
    const isPlural = this.resourceCount > 1;
    if (this.isAdminPage) {
      return isPlural ? this.translationService.getTranslation('filter.completion.playlistPlural')
                      : this.translationService.getTranslation('filter.completion.playlistSingular');
    } else if (this.isPlaylistPage) {
      return isPlural ? this.translationService.getTranslation('filter.completion.cardPlural')
                      : this.translationService.getTranslation('filter.completion.cardSingular');
    } else if (this.isCardPage) {
      return isPlural ? this.translationService.getTranslation('filter.completion.formPlural')
                      : this.translationService.getTranslation('filter.completion.formSingular');
    }
    return '';
  }

  private getSelectedCertainActivities():{ uid: string; value: boolean; label: string }[] {
    if (this.isAdminPage) {
      return this.selectedPlaylists;
    } else {
      return this.certainResources.filter(resource => resource.value === true);
    }
  }

  private isNegation() {
    return this.completionType === 'NOT_COMPLETED' || this.completionType === 'NOT_STARTED';
  }

  private getMaxRequiredResourceCount(): number {
    if (this.isPlaylistPage) {
      return this.cardHeaders.length;
    } else if (this.isCardPage) {
      return this.formHeaders.length;
    }
    return 0;
  }

  private firePlaylistsSearch(override: boolean, initialize: boolean = false) {
    this.page = override ? 0 : this.page + 1;
    this.loadingSearch = true;
    this.globalSearchDataService.searchPlaylists(this.page, this.playlistSearchString).subscribe(({ value }) => {
      if (value) {
        if (override) {
          this.certainResources = this.getListAndCheckIfChecked(value.content);
        } else {
          this.certainResources = this.certainResources.concat(this.getListAndCheckIfChecked(value.content));
        }
        this.canLoadMore = this.certainResources.length < value.totalNumberOfElement;
        if (initialize) {
          this.maxResourceCount = value.totalNumberOfElement;
          this.shouldDisplayActivitiesFilters = this.maxResourceCount > 0;
          this.setResourcesSelected();
        }
      }
      this.loadingSearch = false;
    });
  }

  private getListAndCheckIfChecked(list: SearchPlaylist[]): { uid: string; value: boolean; label: string }[]  {
    if (this.certainResources) {
      return list.map(item => {
        const checked = this.certainResources.filter(it => it.value).map(it => it.uid).includes(item.id);
        return { uid: item.id, label: item.title, value: checked }
      });
    } else {
      return list.map(item => {
        return { uid: item.id, label: item.title, value: false }
      });
    }
  }

  private getSettingsFromFilters() {
    if (this.selectedFilters) {
      const completionFilter = this.selectedFilters.find(f => f.type === 'COMPLETION') as CompletionBooleanFilter;
      if (completionFilter) {
        this.completionType = completionFilter.completionType;
        this.filterType = completionFilter.completionFilterType;
        this.resourceCount = completionFilter.resourceCount;
        this.resourcesUidsFromStore = completionFilter.resourcesUids;
      }
    }
  }

  private setResourcesSelected() {
    this.certainResources = this.certainResources?.map(r => {
      if (this.resourcesUidsFromStore?.includes(r.uid)) {
        r.value = true;
      }
      return r;
    });
    this.selectedPlaylists = this.certainResources.filter(resource => resource.value);
  }

  isResourceChecked(resource: { uid: string; value: boolean; label: string }): boolean {
    if (this.isAdminPage) {
      return this.selectedPlaylists.find(playlist => playlist.uid === resource.uid)?.value;
    } else {
      return this.certainResources.find(r => r.uid === resource.uid)?.value;
    }
  }
}
