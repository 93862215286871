<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="form-dialog-backdrop" tabindex="0"
     role="button"
     [attr.aria-label]="('translations.global.ariaLabels.enterToCloseModal' | transloco)"
     (keydown.enter)="closeFullscreenMode()"
     (click)="closeFullscreenMode()"
     *ngIf="fullscreenEnabled"></div>
<div class="form-wrapper"
     [class.fullscreen-enabled]="fullscreenEnabled"
     [class.preview-expanded]="showAiAssistant">
  <div
    class="form-preview"
    role="form"
    tabindex="0"
    [attr.aria-label]="('translations.global.ariaLabels.textBoxFormTitle' | transloco: {
      title: formData?.content?.title,
      state: (submitted ? 'translations.global.ariaLabels.update' : 'translations.global.ariaLabels.markAsDone') | transloco
    })"
    [class.form-preview--active]="inputFocused"
    [class.form-preview--saved]="submitted"
    [class.form-preview--requires-resubmission]="requiresResubmission">
    <div class="form-preview__top">
      <div class="form-preview__top-title">
        <div class="title medium bold">{{ formData?.content?.title }}</div>
        <div class="form-preview__top-limits extra-small">
          {{ inputTextWords }} / {{ formData?.content?.wordLimit }}
          {{ 'translations.textForm.preview.maxWordsLimit.label' | transloco }}
          <span class="has-error-message" *ngIf="inputTextWords < minWordsLimit && inputFocused">
            ({{ 'translations.textForm.preview.minWordsLimit.validationErrorPrefix' | transloco }} {{ minWordsLimit }}
            {{ 'translations.textForm.preview.minWordsLimit.validationErrorSuffix' | transloco }})
        </span>
        </div>
      </div>

      <div class="form-preview__top-icon-box">
        <button
          (click)="showAiAssistant = !showAiAssistant"
          (keydown.enter)="showAiAssistant = !showAiAssistant"
          *ngIf="aiAssistantFeatureEnabled && aiAssistantEnabled && canEdit && aiAssistantSettingsUid"
          class="ai-assistant-button">
          <svg class="svg" role="img"
               [attr.aria-label]="('translations.aiAssistant.tooltip' | transloco)">
            <use ogSvgUnify="assets/sprite/sprite.svg#magic"></use>
          </svg>
        </button>
        <button
          (click)="changeFullscreenMode()"
          class="fullscreen-btn-container"
          *ngIf="canEdit"
          tabindex="0">
          <svg class="svg" role="img" *ngIf="!fullscreenEnabled"
               [attr.aria-label]="('translations.global.ariaLabels.icons.check' | transloco)">
            <use ogSvgUnify="assets/sprite/sprite.svg#fullscreen-expand"></use>
          </svg>

          <svg class="svg" role="img" *ngIf="fullscreenEnabled"
               [attr.aria-label]="('translations.global.ariaLabels.icons.contract' | transloco)">
            <use ogSvgUnify="assets/sprite/sprite.svg#fullscreen-collapse"></use>
          </svg>
        </button>
        <div *ngIf="submitted" class="form-preview__top-icon non-editable" [matTooltip]="canNotEditReason">
          <mat-icon *ngIf="canEdit" aria-hidden="true">check</mat-icon>
          <svg *ngIf="!canEdit" class="svg" role="img"
               [attr.aria-label]="('translations.global.ariaLabels.icons.lock' | transloco)">
            <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
          </svg>
        </div>
        <div *ngIf="!submitted && !canEdit" class="form-preview__top-icon non-saved non-editable"
             [matTooltip]="canNotEditReason">
          <svg class="svg" role="img"
               [attr.aria-label]="('translations.global.ariaLabels.icons.lock' | transloco)">
            <use ogSvgUnify="assets/sprite/sprite.svg#lock"></use>
          </svg>
        </div>
        <button *ngIf="canEdit" tabindex="0" class="form-preview__top-icon editable"
                (click)="triggerEdit()"
                [ngClass]="canEdit ? 'pointer' : ''">
          <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.edit' | transloco)">
            <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
          </svg>
        </button>
      </div>
    </div>
    <div class="form-preview__input" #formInputPreview>
      <ptl-tiny-medium-editor
        [attr.aria-label]="('translations.global.ariaLabels.textBoxForm' | transloco)"
        [class.is_disabled]="!canEdit"
        [wordLimit]="formData?.content?.wordLimit"
        [minWordsLimit]="formData?.content?.minWordsLimit"
        [content]="existingUserAnswer"
        [inputType]="formData?.content?.answerType"
        [editClicked]="editClicked"
        (inputFocus)="onInputFocus()"
        (inputPast)="onPast($event)"
        (inputBlur)="deactivateForm($event)"
        (textLength)="onTextLengthUpdate($event)"
        (mediumEditorUpdate)="onMediumEditorUpdate($event)"></ptl-tiny-medium-editor>
    </div>
    <div class="form-preview-notes" *ngIf="formData?.content?.notes?.length"
         [class.form-preview--active]="inputFocused">
      <div class="note-item" *ngFor="let note of formData?.content?.notes" tabindex="0">
        <div class="note-item-inner">
          <div class="note-message small">
            <ptl-resource-preview class="thin-preview" [previewContent]="note.message">
            </ptl-resource-preview>
          </div>
        </div>
        <div class="note-added-by extra-small italic">
          {{ note.addedBy }}, {{ note.addedOn | dateFormat: 'DD MMM yyyy HH:mm' }}
        </div>
      </div>
    </div>

    <div class="form-preview__footer" *ngIf="canEdit">

      <div class="form-preview__footer-buttons">
        <span class="autosave-label extra-small saving"
              *ngIf="autoSaveInProgress">{{ 'translations.global.label.autoSaving' | transloco }}</span>
        <span class="autosave-label extra-small" *ngIf="!submitted && autoSaved">
          <mat-icon aria-hidden="true">check</mat-icon>
          {{ 'translations.global.label.saved' | transloco }}</span>
        <button
          mat-stroked-button
          color="accent"
          class="form-preview__footer-submit uppercase"
          type="button"
          (click)="submit()"
          [disabled]="isSubmissionDisabled || autoSaveInProgress || inputTextWords < minWordsLimit || inputTextWords > wordLimit">
          <span *ngIf="requiresResubmission">{{ 'translations.cards.form.button.resubmit' | transloco }}</span>
          <span
            *ngIf="!requiresResubmission && !submitted">{{
              'translations.cards.form.button.markAsDone' | transloco
            }}</span>
          <span
            *ngIf="!requiresResubmission && submitted">{{ 'translations.cards.form.button.update' | transloco }}</span>
        </button>
      </div>
    </div>

  </div>

  <ptl-form-ai-assistant
    *ngIf="showAiAssistant"
    class="form-ai-assistant-box"
    [aiAssistantSettingsUid]="aiAssistantSettingsUid"
    [style.max-width]="resizableContainerWidth"
    [formContent]="existingUserAnswer"
    [formContentWordLimit]="formData?.content?.wordLimit"
    (assistantAnswerSelected)="handleAiAssistantContent($event)"
    (closeAiAssistantBox)="closeAiAssistantBox($event)"
    mwlResizable
    [allowNegativeResizes]="false"
    [ghostElementPositioning]="'absolute'"
    [enableGhostResize]="true"
    (resizeEnd)="onResizeEnd($event)">
    <div class="resize-handler" mwlResizeHandle [resizeEdges]="{ left: true }">
      <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.resize' | transloco)">
        <use ogSvgUnify="assets/sprite/sprite.svg#unfold-more-horizontal"></use>
      </svg>
    </div>
  </ptl-form-ai-assistant>

</div>
<ptl-form-update-footer
  [submitted]="submitted"
  [canEdit]="canEdit"
  [learnerFormAnswer]="learnerFormAnswer"
  [formType]="formData?.content?.type"
  [playlistSubmissionDeadline]="userPlaylistSubmissionSummary?.submissionDeadline">
</ptl-form-update-footer>
