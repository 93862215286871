/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ReviewerDetails } from '../../../page-modules/playlist/models';
import { ProgressiveReviewStatus } from '../../../page-modules/reviews/models';
import { MemberGroup } from '../admin/members.model';

export type ReviewItemType = 'CARD' | 'PLAYLIST';
export type ReviewStatus = 'OPEN' | 'CLOSED';

export interface Review {
  _id?:string;
  reviewUid: string;
  submittingUserId: string;
  reviewItemType: ReviewItemType;
  learnerItemId: string;
  learnerUserUid: string;
  learnerFirstName: string;
  learnerLastName: string;
  learnerThumbnail: string;
  learnerEmail: string;
  learnerExternalId: string;
  itemId: string;
  status: ReviewStatusSummary;
  createdAt: Date;
  lastUpdatedOn: Date;
  title?: string;
  reviewers?: ReviewerSummary[];
  closeReason?: ReviewCloseReasonSummary;
}

export class LearnerReviewDetails {
  constructor(public uid: string, public firstName: string, public lastName: string, public imageUrl?: string) {
  }
}

export interface ReviewStatusSummary {
  open: boolean;
  currentReviewEvent: ReviewEvent;
  reviewerDecision?: ReviewEvent;
  approvalsCount: number;
}

export interface ReviewerSummary {
  uid: string;
  firstName: string;
  lastName: string;
  imageUrl?: string;
}

export interface UserReviewsCount {
  count: number;
  pendingCount: number;
}

export interface ReviewCloseReasonSummary {
  listItem?: string;
  freeText?: string;
}

export interface ReviewEvent { // todo that interface represents backend ReviewEventSummary but is used as ReviewEvent in frontend too
  type: ReviewEventType;
  itemId: string;
  createdAt: Date;
  reviewer: ReviewerSummary;
}

export interface MemberReview {
  userUid: string;
  reviewUid: string | undefined;
  reviewLearnerItemId: string;
  firstName: string;
  lastName: string;
  externalId: string | undefined;
  imageUrl: string | undefined;
  department: MemberGroup | undefined;
  reviewers: ReviewerDetails[] | undefined;
  status: ReviewStatusSummary;
  latestReviewEvent: ReviewEvent;
  formReviewStatusSummary?: FormReviewStatusSummary;
  // frontend specific
  action: { title: string; url: string };
  // frontend specific
  checked: boolean;
}

export interface FormReviewStatusSummary {
  unsubmittedCount: number;
  pendingReviewCount: number;
  requiresResubmissionCount: number;
  approvedCount: number;
  cardReviewEnabledCount: number;
}

export enum ReviewEventType {
  SUBMITTED_FOR_REVIEW = 'SUBMITTED_FOR_REVIEW',
  ACCEPTED = 'ACCEPTED',
  REQUIRES_RESUBMISSION = 'REQUIRES_RESUBMISSION',
  RESUBMITTED = 'RESUBMITTED',
  REJECTED_TO_REVIEW = 'REJECTED_TO_REVIEW',
  CLOSED = 'CLOSED',
  REOPENED = 'REOPENED',
  REMINDED_TO_SUBMIT = 'REMINDED_TO_SUBMIT',
  NOTE = 'NOTE'
}
