<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<mat-card class="skeleton__card-item">
  <div class="content-container">
    <div class="skeleton__avatar"></div>
    <div class="text-block">
      <div class="text-container">
        <div class="skeleton__text skeleton__text-title slide-row"></div>
      </div>
      <div class="text-container small">
        <div class="skeleton__text skeleton__text-title slide-row"></div>
      </div>
    </div>
  </div>
</mat-card>
