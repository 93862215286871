/** Contains the key details for an organization registered for the platform. */
import { LanguageValue } from '..';
import { TextOrientation } from '../languages/languages.model';

export interface Organization {
  /** A generated id. */
  _id: string;
  /** The official organization name. */
  name: string;
  /** Defines the organization logo. */
  logo: string;
  /** The organization public site. */
  site: string;
  /** The domain to use. (i.e. foo.potential.ly) where 'foo' is the domain. */
  domain: string;
  /** When ACTIVE organization users are allowed access to the platform. */
  status: OrganizationStatus;
  /** The organization landing page. */
  landingPage: {
    /** The organization landing page logo */
    landingLogo: string;
    /** The organization landing page welcome message. */
    welcomeMessage: string;
    /** The organization landing page background image. */
    backgroundImage: string;
    /** The organization landing page layout. */
    layout: 'SPLIT_SCREEN' | 'FULL_BACKGROUND';
    /** The organization landing page background color. */
    backgroundColour: string;
    /** The organization landing page background color apply flag. */
    useBackgroundColourFill: boolean;
  };
  /** The organization Discovery page. */
  discoveryPage: {
    status: OrganizationStatus;
    uri: string;
    id: string;
    title: string;
  };
  /** The organization languages. */
  languages: OrganizationLanguage[];
  /** The login type organization uses */
  type: OrganizationType;
  /** The login type organization uses */
  authType: OrganizationAuthType;
  /** This is user to support SAML_SP orgs that share one IDP, Value must be the domain of org */
  authorisedClient?: string;
  /** Idp used for SAML login type */
  idp?: string;
  /** Allow registration */
  allowRegistrations: boolean;
  /** Allow folio connection */
  allowFolioConnection: boolean;
  /** Allow prearrivals */
  preArrivals: boolean;
  whitelist: string[];
  blacklist: string[];
  helpCenterEnabled: boolean;
  defaultLanguage?: string;
  contentStoreEnabled?: boolean;
  emailsEnabled?: boolean;
  jiscDeepLink: string;
  ui: OrganizationUi;
  pointClick: OrganizationTheme;
}

export interface JiscOrganization {
  id: string;
  authType: OrganizationAuthType;
  authorisedClient?: string;
  domain: string;
  entityId: string;
  idp: string;
  logo: string;
  name: string;
  jiscDeepLink: string;
}

export enum OrganizationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum OrganizationAuthType {
  FORM = 'FORM',
  SAML = 'SAML',
  SAML_SP = 'SAML_SP',
  SOCIAL = 'SOCIAL',
  LDAP = 'LDAP',
}

export interface OrganizationRoles {
  _id: string;
  name: LanguageValue[];
  privileges: OrganizationPrivilege[];
  type: string;
  organizationUid?: string;
}

export interface OrganizationPrivilege {
  _id: string;
  assigned: boolean;
  label: LanguageValue[];
  name: string;
  privilegeGroup: OrganizationPrivilegeGroupType;
}

export interface UpdateOrganizationRoleRequest {
  name: LanguageValue;
  privileges: string[];
}

export interface CreateOrganizationRoleRequest extends UpdateOrganizationRoleRequest {
  type: OrganizationPrivilegeGroupType;
}

export interface OrganizationLanguage {
  code: string;
  name: string;
  default: boolean;
  active: boolean;
  orientation: TextOrientation;
}

export interface AvailableLanguage {
  code: string;
  enabled: boolean;
}

export interface OrganizationFeature {
  createdAt : Date;
  enabled:boolean;
  featureUid: string;
  title: string;
}

export interface OrganizationTheme {
  primaryThemeColor: string;
  primaryThemeLiteColor: string;
  primaryColour: string;
  primaryLiteColor: string;
  interactiveColor: string;
  interactiveLightColor: string;
  adminColor: string;
  adminLightColor: string;


}

export type OrganizationPrivilegeGroupType = 'ADMIN' | 'CREATOR' | 'PUBLISHING' | 'MEMBER';

export type OrganizationType = 'FOLIO' | 'ENTERPRISE' | 'EDUCATIONAL';

export type OrganizationUi = 'v1' | 'v2';
