/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, OnInit, SimpleChanges } from '@angular/core';
import { ImageUploadContent, MediaContent, MediaUploadData } from '../../../../models';

@Component({
	selector: 'ptl-media-uploads',
	templateUrl: './media-uploads.component.html',
	styleUrls: ['./media-uploads.component.scss']
})
export class MediaUploadsComponent implements OnChanges, OnInit {

	/** Receives the content */
	private _mediaUploadData: MediaUploadData;
	@Input()
	set mediaUploadData(value: MediaUploadData | undefined) {
		if (typeof value === 'string') {
			this._mediaUploadData = JSON.parse(decodeURIComponent(value));
		} else {
			this._mediaUploadData = value;
		}
	}

	get mediaUploadData() {
		return this._mediaUploadData;
	}

	@Input() canRemoveItem = true;

	/** Treat in-memory upload as not uploaded */
	@Input() markInMemoryUploadsAsNotUploaded = false;

	@Input() streamVideoBytePlaceholder;

	/** Outputs removing of the component */
	@Output() removeMediaContent = new EventEmitter<void>();
	/** Outputs the new position of the HTML element. */
	@Output() updateMediaPosition = new EventEmitter<string>();
	@Output() updateMediaCaption = new EventEmitter<string>();
	@Output() updateMediaHyperlink = new EventEmitter<{ url: string; openUrlInTab: boolean }>();

	@Output() mediaContentReplaced = new EventEmitter<MediaContent>();
	@Output() regularLinkReplaced = new EventEmitter<string>();
	@Output() emitToolBarState = new EventEmitter<boolean>();

	uploadedMediaDataUrl = '';
	uploadedMediaFileType = '';
	isProgressBarVisible = false;
	isNewEditorEnabled = false;
	imageSrc = '';

	constructor(private cdr: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.isNewEditorEnabled = JSON.parse(localStorage.getItem('enableNewEditor'));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.mediaUploadData?.file) {
			if (this.mediaUploadData.file.type !== 'application/pdf' || this.mediaUploadData.file.size <= 2000000) {
				this.uploadedMediaFileType = this.mediaUploadData.file.type;
			}

			const reader = new FileReader();
			reader.onload = (event) => {
				this.uploadedMediaDataUrl = (event.target as FileReader).result as string;
				this.cdr.detectChanges();
			};
			this.isProgressBarVisible = true;
			reader.readAsDataURL(this.mediaUploadData.file);
		}

		if (this.mediaUploadData?.['hasFile']) {
			this.isProgressBarVisible = true;
		}

		if (this.mediaUploadData?.content) {
			this.imageSrc = this.getImageSrc();
			this.cdr.detectChanges();
		} else {
			this.imageSrc = this.uploadedMediaDataUrl;
			this.cdr.detectChanges();
		}
	}

	contentUploadProgress(progress: number): void {
		if (progress === 100) {
			setTimeout(() => {
				this.isProgressBarVisible = false;
				this.cdr.detectChanges();
			}, 500);
		}
	}

	removeMedia(): void {
		this.removeMediaContent.emit();
	}

	onUpdateMediaPosition(position: string): void {
		this.updateMediaPosition.emit(position);
	}

	onReplaceMediaContent(media: MediaContent): void {
		this.mediaContentReplaced.emit(media);
	}

	onReplaceRegularLink(link: string): void {
		this.regularLinkReplaced.emit(link);
	}

	onUpdateMediaCaption(caption: string): void {
		this.updateMediaCaption.emit(caption);
	}

	toolBarState(event: boolean) {
		this.emitToolBarState.emit(event);
	}

	onUpdateMediaHyperlink(form: { url: string; openUrlInTab: boolean }): void {
		this.updateMediaHyperlink.emit(form);
	}

	documentCanBePreviewed(): boolean {
		return !!this.mediaUploadData?.content || (!!this.uploadedMediaDataUrl &&
			!this.markInMemoryUploadsAsNotUploaded && this.uploadedMediaFileType === 'application/pdf');
	}

	getImageSrc(): string {
		if (this.mediaUploadData) {
			const content = this.mediaUploadData.content as ImageUploadContent;
			switch (this.mediaUploadData.position) {
				case 'FULL_WIDTH':
					return content.extraLarge;
				case 'CENTER':
				case 'LEFT':
				case 'RIGHT':
				default:
					return content.original;
			}
		}
		return '';
	}
}
