<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="preview">

  <div class="preview__area" *ngIf="previewContent">
    <ng-container *ngFor="let contentItem of previewContent; trackBy: trackByFn; let i = index;">

      <ptl-resource-html-preview
        *ngIf="isContentHtmlPreview(contentItem)"
        [class.first-html-preview]="i === 0"
        [content]="contentItem">
      </ptl-resource-html-preview>

      <ptl-resource-form-preview
        *ngIf="contentItem.type === 'FORM'"
        [formContent]="contentItem"
        [userPlaylistSubmissionSummary]="userPlaylistSubmissionSummary"
        [hideTextFormButtons]="hideTextFormButtons"
      ></ptl-resource-form-preview>

      <ptl-resource-image-preview
        *ngIf="isContentImagePreview(contentItem)"
        [class.first-preview]="i === 0"
        [content]="contentItem">
      </ptl-resource-image-preview>

      <ptl-resource-stream-video-preview
        *ngIf="isContentStreamVideoPreview(contentItem)"
        [class.first-preview]="i === 0"
        [content]="contentItem">
      </ptl-resource-stream-video-preview>

      <ptl-resource-video-preview
        *ngIf="isContentVideoPreview(contentItem)"
        [class.first-preview]="i === 0"
        [content]="contentItem">
      </ptl-resource-video-preview>

      <ptl-resource-document-preview
        *ngIf="isContentDocumentPreview(contentItem)"
        [class.first-preview]="i === 0"
        [content]="contentItem">
      </ptl-resource-document-preview>

      <ptl-resource-audio-preview
        *ngIf="isContentAudioPreview(contentItem)"
        [class.first-preview]="i === 0"
        [content]="contentItem">
      </ptl-resource-audio-preview>

      <ptl-external-preview
        *ngIf="contentItem.type === 'EXTERNAL_CONTENT_SUMMARY'"
        [content]="contentItem">
      </ptl-external-preview>

      <ptl-chart-preview
        *ngIf="contentItem.type === 'CHART'"
        [chartContent]="contentItem">
      </ptl-chart-preview>

      <ptl-pi-trait-chart
        *ngIf="contentItem.type === 'TRAITS_CHART'"
        [content]="contentItem">
      </ptl-pi-trait-chart>

      <ptl-pi-team-role-chart
        *ngIf="contentItem.type === 'TEAM_ROLES_CHART'"
        [content]="contentItem">
      </ptl-pi-team-role-chart>

      <ptl-pi-big-six-chart
        *ngIf="contentItem.type === 'BIG_SIX_CHART'"
        [content]="contentItem">
      </ptl-pi-big-six-chart>

      <ptl-pi-jungian-chart
        *ngIf="contentItem.type === 'JUNGIAN_TYPES_CHART'"
        [content]="contentItem">
      </ptl-pi-jungian-chart>

      <ptl-pi-spectrum-chart
        *ngIf="contentItem.type === 'SPECTRUM_CHART'"
        [showStaticData]="true"
        [content]="contentItem">
      </ptl-pi-spectrum-chart>

      <div *ngIf="contentItem.type === 'EXTERNAL_CONTENT_SUMMARY' || isContentDocumentPreview(contentItem) ||
                  isContentAudioPreview(contentItem) || isContentVideoPreview(contentItem)" class="print-content">
        <a [href]="getUrl(contentItem)" target="_blank">{{ getText(contentItem) }}</a>
      </div>
    </ng-container>
  </div>
</div>
