/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../shared/store';
import {
  AccountInvitationJoinRequest,
  OnBoardingDataRequest,
  UserAccountCreationRequest,
  UserDetails
} from '../models';
import { InjectionToken } from '@angular/core';
import { LtiResponse, SamlSPResponse } from '../models/lti.model';
import { LoginFinalizationResponse, TokenValidationResponse } from '../models/login-response.model';

/** Injection token for the authentication service. */
export const AUTH_SERVICE = new InjectionToken<BasicAuthService>('[User-Auth] Basic AuthService');

/** Contains authentication-related methods. */
export interface BasicAuthService {

  /**
   * Attempts to sign a user up.
   *
   * @param signupRequest contains the user details to use for signup
   * @param orgId contains the organization id
   * @param organizationDomain contains the organization domain
   * @param orgName contains the organization name
   */
  createAccount(
    signupRequest: UserAccountCreationRequest,
    orgId: string,
    organizationDomain: string,
    orgName: string): ObservableResult<void>;


  /**
   * Attempts to sign a user up.
   *
   * @param signupRequest contains the user details to use for signup
   * @param invitationId contains the invitation id
   * @param invitationToken contains the invitation token
   * @param organizationDomain contains the organization domain
   */
  createAccountWithInvitationToken(
    signupRequest: UserAccountCreationRequest,
    invitationId: string,
    invitationToken: string,
    organizationDomain: string): ObservableResult<void>;

  joinOrganizationWithInvitationToken(
    request: AccountInvitationJoinRequest,
    invitationId: string,
    invitationToken: string): ObservableResult<void>;

  /**
   * Attempts to confirm user signup using a verification token.
   *
   * @param token the verification token issued to the user
   * @param verificationCode the verification code to confirm
   */
  verifyAccount(token: string): ObservableResult<void>;

  /**
   * Authenticates or attempts to renew authentication, retrieving the user details post-authentication.
   *
   * @param email the email to submit for authentication
   * @param password the password to submit for authentication
   * @param organizationDomain the organization domain to submit for authentication
   * @returns an observable completing with the user details or an error
   */
  authenticate(email: string, password: string, organizationDomain: string): ObservableResult<UserDetails>;

  /** Resend verification token */
  resendVerificationEmail(email: string, organizationUid: string, captchaResponseV3: string, captchaResponseV2: string)
    : ObservableResult<void>;

  /** Getting LTI token */
  getLtiToken(requestId: string, isPlaylist: boolean): ObservableResult<LtiResponse>;

  /** Getting SAML SP token */
  getSamlSPToken(requestId: string): ObservableResult<SamlSPResponse>;

  /** Fetches the details for the user currently logged in. */
  fetchUserDetails(): ObservableResult<UserDetails>;

  /** Unauthenticates the user. */
  unauthenticate(redirectUri?: string): ObservableResult<void>;

  /** Finalizes SAML authentication */
  finalize(email?: string): ObservableResult<LoginFinalizationResponse>;

  finalizeFolioLogin(destinationOrganization: string, overrideToken: boolean, email?: string): ObservableResult<LoginFinalizationResponse>;

  /**
   * Sends user on board data.
   *
   * @param data on board data
   */
  sendOnBoardData(data: OnBoardingDataRequest): ObservableResult<UserDetails>;

  /**
   * Validates invitation token and gets user email if it's valid.
   *
   * @param invitationId the invitation id
   * @param token token to be validated
   */
  validateInvitationToken(invitationId: string, token: string): ObservableResult<TokenValidationResponse>;
}
