<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="item" [class.item--selected]="value.criteria === 'ALL_CORRECT'"
  [attr.aria-label]="('translations.global.ariaLabels.all' | transloco)"
  (click)="clickAll()"
  (keydown.enter)="clickAll()"
  tabindex="0">{{ 'translations.quiz.settings.passCriteria.options.allCorrect' | transloco }}</div>
<div
  class="item"
  [class.item--selected]="value.criteria === 'PARTOF_CORRECT'"
  [attr.aria-label]="('translations.global.ariaLabels.minimum' | transloco)"
  (click)="clickMin()"
  (keydown.enter)="clickMin()"
  tabindex="0">
  <span>{{ 'translations.quiz.settings.passCriteria.options.partOfCorrect' | transloco }}</span>
  <input
    matInput
    type="number"
    class="input"
    pattern="^\d+$"
    min="0"
    max="{{questionsCount}}"
    [ngModel]="value.partOf"
    (ngModelChange)="onValueChange($event)">
</div>
