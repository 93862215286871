import {
  LeftNavItemSummary,
  LeftNavItemType,
  SidebarCard,
  SidebarSection
} from '../../models/sidebar-tree/sidebar-tree.model';
import { SidebarTreeDataService } from '../../services/sidebar-tree/sidebar-tree-data.service';
import { Organization, ResourcePermission, ResourceTag } from '../../models';
import { UserRole } from '../../../user-auth/models';
import { cloneDeep } from 'lodash-es';
import { developmentDomains } from '../development-domains.helper';
import { Store } from '@ngxs/store';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { AdminDataService } from '../../../page-modules/admin/services/data.service';
import { CATEGORY_PARAM_NAME } from '../../../page-modules/explore/models/explore.model';
import { ExploreHelper } from '@app/app/page-modules/explore/helpers/explore.helper';

export class SidebarTreeHelper {

  static loadChildData(
    sidebarTreeService: SidebarTreeDataService,
    card: SidebarCard,
    callback: () => void,
    adminDataService: AdminDataService = undefined,
    cardCategory?: SidebarCard,
    categoryUids?: string[],
  ) {
    let request = null;
    if (card.type === 'PAGE' || card.type === 'HOME_PAGE') {
      request = sidebarTreeService.loadPageChildren(card.requestUri);
    } else if (card.type === 'GROUP') {
      request = sidebarTreeService.loadGroupCardChildren(card.requestUri);
    } else if (card.type === 'PLAYLIST' || card.type === 'PERSONALITY_INDICATOR') {
      request = sidebarTreeService.loadPlaylistChildren(card.requestUri);
    } else if (card.type === 'CATEGORY') {
      SidebarTreeHelper.loadCategoryChildren(adminDataService, card, callback)
    } else if (card.type === 'PROJECT') {
      request = sidebarTreeService.loadProjectChildren(card.requestUri);
    }

    if (request) {
      request.subscribe(({ isSuccess, value }) => {

        if (isSuccess) {
          if (cardCategory) {
            const changedValue = [{ ...card }];
            const nonCategoryCardId = this.findNonCategoryCardId(cardCategory);
            if (nonCategoryCardId) {
              const formatedValue = value.map((sidebarCard) => this.changeTypeAndFormattedUri(sidebarCard, cardCategory, categoryUids))
              this.addItemsToObjectById(cardCategory.cards,card, formatedValue )
            } else {
              changedValue[0].cards = value.map((sidebarCard) => this.changeTypeAndFormattedUri(sidebarCard, cardCategory, categoryUids))
              cardCategory.cards = this.formatChildrenCards(changedValue, cardCategory, categoryUids);
              cardCategory.hasChild = !!cardCategory.cards.length;
            }
          } else {
            card.cards = this.formatChildrenCards(value, card);
            card.hasChild = !!card.cards?.length;
          }
        } else {
          card.cards = [];
          card.hasChild = false;
        }
        callback();
      });
    } else {
      callback();
    }
  }

   static addItemsToObjectById(cardsArray: SidebarCard[], parentCard: SidebarCard,
     itemsToAdd: SidebarCard[], categoryUids?: string[]): void {
    for (const card of cardsArray) {
      if (card._id === parentCard._id) {
        card.cards = this.formatChildrenCards(itemsToAdd, parentCard, categoryUids);
        return;
      }

      if (card.cards && card.cards.length > 0) {
        this.addItemsToObjectById(card.cards, parentCard, itemsToAdd);
      }
    }
  }

static findNonCategoryCardId(card: SidebarCard): string | null {
    if (card.type !== 'CATEGORY') {
      return card._id;
    }

    if (card.cards && card.cards.length > 0) {
      for (const subCard of card.cards) {
        const result = this.findNonCategoryCardId(subCard);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  static loadCategoryChildren(
    adminDataService: AdminDataService,
    card: SidebarCard,
    callback: () => void
  ) {
    adminDataService.loadCategoryChildren(card._id).subscribe(({ isSuccess, value }) => {
      if (isSuccess) {
        card.cards = value.map((it) =>
          this.convertFromResourceTagToNavItem(it, card)
        );

        card.hasChild = !!card.cards?.length;
      } else {

        card.cards = [];
        card.hasChild = false;
      }
      callback();
    })
  }

  static convertFromResourceTagToNavItem(resourceTag: ResourceTag, parentCard: SidebarCard): SidebarCard {
    const card = {
      _id: resourceTag._id,
      title: resourceTag.title,
      parentId: parentCard._id,
      hasChild: resourceTag.descendantsCount > 0,
      type: 'CATEGORY',
      published: true,
      formattedUri: `${parentCard.formattedUri}&${CATEGORY_PARAM_NAME}=${resourceTag._id}`,
      requestUri: resourceTag._id
    } as SidebarCard
    return card;
  }
  static changeTypeAndFormattedUri(resourceTag: SidebarCard, parentCard: SidebarCard, categoryUids: string[]): SidebarCard {
    const queryParams = categoryUids ? ExploreHelper.generateCategoryUidQueryString(categoryUids)
    : ExploreHelper.generateCategoryUidQueryString([parentCard._id]);
    const uri = resourceTag.type !== 'PLAYLIST' ? 'explore/category' + resourceTag.formattedUri +
    `/page/1${queryParams}` : 'explore/category' + resourceTag.formattedUri + `${queryParams}`;
    return {
      ...resourceTag,
      parentId: parentCard._id,
      requestUri: uri,
      type: resourceTag.type as LeftNavItemType,
      formattedUri: uri,
    };
  }

  static formatChildrenCards(
    cards: LeftNavItemSummary[],
    parentCard: SidebarCard,
    categoryUids?: string[]
  ): SidebarCard[] {
    const queryParams = categoryUids ? ExploreHelper.generateCategoryUidQueryString(categoryUids) : '';
    const sections: SidebarCard[] = [];

    for (const card of cards) {
      const uri = categoryUids && !card.formattedUri.includes('explore/category') && parentCard.formattedUri.includes('explore/category')
        ? 'explore/category' + card.formattedUri + `${queryParams}`
        : card.formattedUri;
      sections.push({
        ...card,
        parentId: parentCard._id,
        requestUri: uri,
        formattedUri: uri,
        hasChild: ['PLAYLIST', 'PERSONALITY_INDICATOR', 'PAGE', 'GROUP', 'PROJECT'].includes(card.type),
      });
    }
    return this.removeDuplicatedCards(sections);
  }

  static searchInTree(item: SidebarCard, matchingKey: string): SidebarCard {
    if (item._id === matchingKey || item.formattedUri === matchingKey) {
      return item;
    } else if (item.cards?.length) {
      let i;
      let result = null;
      for (i = 0; result === null && i < item.cards.length; i++) {
        result = this.searchInTree(item.cards[i], matchingKey);
      }
      return result;
    }
    return null;
  }

  static searchFirstLevel(item: SidebarCard, matchingKey: string): SidebarCard {
    if (item._id === matchingKey || item.formattedUri === matchingKey) {
      return item;
    }
    if (item.cards?.length) {
      let result = null;
      for (let i = 0; i < item.cards.length; i++) {
        if (item.cards[i]._id === matchingKey || item.formattedUri === matchingKey) {
          result = item.cards[i];
          break
        }
      }
      return result;
    }
    return null;
  }

  static searchAllInTree(item: SidebarCard, matchingKey: string): SidebarCard[] {
    let results: SidebarCard[] = [];

    if (item._id === matchingKey || item.formattedUri === matchingKey) {
      results.push(item);
    }
    if (item.cards && item.cards.length) {
      for (let i = 0; i < item.cards.length; i++) {
        const childResults = this.searchAllInTree(item.cards[i], matchingKey);
        if (childResults.length > 0) {
          results = results.concat(childResults);
        }
      }
    }
    return results;
  }


  static excludeStaticLinksByPermissions(sections: SidebarSection[], permissions: ResourcePermission): SidebarSection[] {
    const newSections = cloneDeep(sections);

    return newSections.map(section => ({
      ...section,
      staticLinks: section.staticLinks?.filter(item => {
        if (item.allowedPermissions) {
          const intersect = [];
          for (const allowedPermission of item.allowedPermissions) {
            if (permissions[allowedPermission]) {
              intersect.push(allowedPermission);
              break;
            }
          }
          return !!intersect.length;
        }
        return true;
      }),
    }));
  }

  static excludeSectionsByEmbeddedStatus(sections: SidebarSection[], embedded: boolean): SidebarSection[] {
    const newSections = cloneDeep(sections);

    return newSections.map(section => ({
      ...section,
      staticLinks: section.staticLinks?.filter(item => {
        if (embedded) {
          if (item.uri === 'target-audience' || item.uri === 'collaboration-settings') {
            return false;
          }
        }
        return true;
      }),
    }));
  }

  static excludeStaticLinksByRoles(sections: SidebarSection[], organization: Organization, roles: UserRole[]): SidebarSection[] {
    return sections.map(section => ({
      ...section,
      staticLinks: section.staticLinks?.filter(item => {
        if (item.allowedRoles) {
          if (item.allowedRoles.includes('BETA') && developmentDomains(organization?.domain)) {
            return true;
          }
          const intersect = [];
          for (const allowedRole of item.allowedRoles) {
            if (roles.filter(role => allowedRole === role.type).length) {
              intersect.push(allowedRole);
              break;
            }
          }
          return !!intersect.length;
        }
        return true;
      }),
    }));
  }

  static excludeStaticLinksByPrivileges(sections: SidebarSection[], roles: UserRole[]): SidebarSection[] {
    return sections.map(section => ({
      ...section,
      staticLinks: section.staticLinks?.filter(item => {
        if (item.allowedPrivileges) {
          let canManage = false;
          for (const allowedPrivilege of item.allowedPrivileges) {
            for ( const role of roles ) {
              for ( const privilege of role.privileges ) {
                if ( privilege.name === allowedPrivilege ) {
                  canManage = privilege.assigned;
                  if ( canManage ) {
                    break;
                  }
                }
              }
              if ( canManage ) {
                break;
              }
            }

            if ( canManage ) {
              break;
            }
          }
          return canManage;
        }
        return true;
      }),
    }));
  }

  static excludeCategories(sections: SidebarSection[], categoriesFeatureFlag: boolean): SidebarSection[] {
    if (categoriesFeatureFlag) {
      return sections
    } else {
      return sections.map(section => ({
        ...section,
        staticLinks: section.staticLinks?.filter(item => {
          return item.titleId !== 'categories';
        }),
      }));
    }
  }

  static excludeSectionByRoles(sections: SidebarSection[], organization: Organization, roles: UserRole[]): SidebarSection[] {

    return sections.filter(section => {
      if (section.allowedRoles) {
        if (section.allowedRoles.includes('BETA') && developmentDomains(organization?.domain)) {
          return true;
        }
        const intersect = [];
        for (const allowedRole of section.allowedRoles) {
          if (roles.filter(role => allowedRole === role.type).length) {
            intersect.push(allowedRole);
            break;
          }
        }
        return !!intersect.length;
      }
      return true;
    });
  }

  static excludeStaticLinkReviewerDashboardByPermissions(
    sections: SidebarSection[],
    permissions: ResourcePermission,
    roles: UserRole[]
  ): SidebarSection[] {
    return sections.map(section => ({
        ...section,
        staticLinks: section.staticLinks?.filter(item => {
          if (item.allowedPermissions && item.allowedPermissions.includes('canViewReviewerDashboard')
            && !roles.map(role => role.type).includes('SUPER_ADMIN')) {
            return !!permissions.reviewerDashboardPermission?.accessForGroups?.length;
          } else {
            return true;
          }
        })
      })
    );
  }

  static filterStaticUrls(sections: SidebarSection[], store: Store, excludedStaticUrls?: string[]): SidebarSection[] {
    const resultingSections = cloneDeep(sections);
    const organizationDomain = store.selectSnapshot(UserAuthState.organizationDomain);
    if (excludedStaticUrls) {
      return resultingSections.map(section => ({
        ...section,
        staticLinks: section.staticLinks?.filter(item => {
          if (item.allowedRoles && item.allowedRoles.includes('BETA') && developmentDomains(organizationDomain)) {
            return true;
          }
          return !excludedStaticUrls.find(uri => item.uri === uri);
        }),
      }));
    }

    return resultingSections;
  }

  private static removeDuplicatedCards(sections: SidebarCard[]): SidebarCard[] {
    return sections.filter((card, index) =>
      sections.findIndex((c) => card._id === c._id) === index
    );
  }
}
