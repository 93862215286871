import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { USER_GROUPS_DATA_SERVICE, UserGroupsDataService } from 'src/app/page-modules/admin/services/groups/groups-data.service';
import { GroupSearchAutocompleteComponent } from 'src/app/shared/components/group-search-autocomplete/group-search-autocomplete.component';
import { UserGroups } from 'src/app/shared/models/admin/group/user-groups.model';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from 'src/app/shared/services/toast-notifications/toast-service';
import { TranslationService } from '../../../../../../shared/services/translation/translation.service';

@Component({
  templateUrl: './edit-group-dialog.component.html',
  styleUrls: ['./edit-group-dialog.component.scss'],
})
export class EditGroupDialogComponent implements OnInit, OnDestroy {

  @Input() checkedMemberIds: string[];

  @Output() refreshList = new EventEmitter<number>();

  loadingGroups: boolean;
  searchString: string;
  selectedGroups: UserGroups[] = [];
  searchedGroups: UserGroups[] = [];

  private groupsSearchPage = 0;
  private groupsSearchPageSize = 10;
  private searchInputSubscription: Subscription;
  private searchInputSubject = new Subject<string>();

  @ViewChild('groupSearchAutocomplete') private groupSearchAutocomplete: GroupSearchAutocompleteComponent;

  constructor(
    private translationService: TranslationService,
    private dialogRef: MatDialogRef<EditGroupDialogComponent>,
    @Inject(USER_GROUPS_DATA_SERVICE) public userGroupsService: UserGroupsDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: { checkedMemberIds: string[] }
  ) {
    if (this.data) {
      this.checkedMemberIds = data.checkedMemberIds;
    }
  }

  ngOnInit() {
    this.searchInputSubscription = this.searchInputSubject
      .pipe(debounceTime(500)).subscribe(() => this.fireSearch(true));
  }

  ngOnDestroy() {
    this.searchInputSubscription?.unsubscribe();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSearchInputChange(searchValue: string) {
    this.searchString = searchValue;
    this.loadingGroups = true;
    this.searchInputSubject.next(searchValue);
  }

  onSearchLoadingMore(): void {
    this.fireSearch(false);
  }

  onGroupSelected(data: UserGroups) {
    this.selectedGroups =
      this.selectedGroups.findIndex((value) => value._id === data._id) === -1
      ? this.selectedGroups.concat(data)
      : this.selectedGroups;
  }

  removeSelectedGroup(group: UserGroups) {
    this.selectedGroups = this.selectedGroups.filter((g) => g !== group);
  }

  private fireSearch(override: boolean): void {
    this.groupsSearchPage = override ? 0 : this.groupsSearchPage + 1;
    if (!this.searchString) {
      this.searchString = '';
    }
    this.userGroupsService.searchUserGroups(this.groupsSearchPage, this.groupsSearchPageSize, this.searchString)
      .subscribe(({ isSuccess, value }) => {
        if (isSuccess) {
          if (override) {
            this.searchedGroups = value.content;
          } else {
            this.searchedGroups = this.searchedGroups.concat(value.content);
          }
        }
        this.groupSearchAutocomplete.canLoadMore = value.totalNumberOfElement > this.searchedGroups.length;
        this.groupSearchAutocomplete.isLoadingMore = false;
        this.loadingGroups = false;
      });
  }

  removeGroups() {
    this.userGroupsService.removeMultipleGroupsFromMultipleUsers(
      this.selectedGroups.map(it => it._id), this.checkedMemberIds
    ).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        this.toastService.showSuccess(this.translationService.getTranslation('successes.successRemoveGroupsFromUsers'));
      } else {
        this.toastService.showFail(error);
      }
      this.closeDialog();
      this.refreshList.emit(this.checkedMemberIds.length);
    });
  }

  addGroups() {
    this.userGroupsService.assignMultipleGroupsToMultipleUsers(
      this.selectedGroups.map(it => it._id), this.checkedMemberIds
    ).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        this.toastService.showSuccess(this.translationService.getTranslation('successes.successAssignGroupsToUsers'));
      } else {
        this.toastService.showFail(error);
      }
      this.closeDialog();
      this.refreshList.emit(this.checkedMemberIds.length);
    });
  }
}
