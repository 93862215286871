/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  EditorContent,
  ExternalContentSummary,
  FileEditorContent,
  MediaType,
  MediaUploadData,
  MediaUrlData, UserPlaylistSubmissionSummary
} from '../../models';

@Component({
  selector: 'ptl-resource-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcePreviewComponent implements OnChanges {

  /** The content to display. */
  @Input() previewContent: EditorContent[] = [];

  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;

  @Input() hideTextFormButtons = false;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.previewContent) {
      this.previewContent = changes.previewContent.currentValue;
    }
    if (changes.userPlaylistSubmissionSummary && changes.userPlaylistSubmissionSummary.currentValue) {
      this.cd.detectChanges();
    }
  }

  isContentHtmlPreview(content: EditorContent): boolean {
    return this.isNonImageMediaUrl(content) || content.type === 'PARAGRAPH' ||
      content.type === 'SEPARATOR' || content.type === 'EMBED_CODE' || content.type === 'TABLE';
  }

  private isNonImageMediaUrl(content: EditorContent): boolean {
    return content.type === 'MEDIA_URL' && this.isNotImage(content as MediaUrlData);
  }

  private isNotImage(content: MediaUrlData): boolean {
    const mediaType: MediaType = content.mediaType;
    return mediaType !== 'IMAGE';
  }

  isContentImagePreview(content: EditorContent): boolean {
    return (content.type === 'MEDIA_URL' || content.type === 'MEDIA_UPLOAD') && (content as MediaUrlData).mediaType === 'IMAGE';
  }

  isContentStreamVideoPreview(content: EditorContent): boolean {
    return (content.type === 'MEDIA_URL' || content.type === 'MEDIA_UPLOAD') && (content as MediaUrlData).mediaType === 'STREAM_VIDEO';
  }

  isContentVideoPreview(content: EditorContent): boolean {
    return (content.type === 'MEDIA_URL' || content.type === 'MEDIA_UPLOAD') && (content as MediaUrlData).mediaType === 'VIDEO';
  }

  isContentAudioPreview(content: EditorContent): boolean {
    return (content.type === 'MEDIA_URL' || content.type === 'MEDIA_UPLOAD') && (content as MediaUrlData).mediaType === 'AUDIO';
  }

  isContentDocumentPreview(content: EditorContent): boolean {
    return content.type === 'MEDIA_UPLOAD' && (content as MediaUploadData).mediaType === 'DOCUMENT';
  }

  trackByFn(index: number, { uid }: EditorContent) {
    return uid || index;
  }

  getUrl(content: EditorContent): string {
    return (content as MediaUrlData)?.url ?? content?.content?.url;
  }

  getText(content: EditorContent): string {
    return (content as FileEditorContent)?.caption ||
        (content as MediaUploadData)?.name ||
        (content as ExternalContentSummary)?.title ||
        this.getUrl(content);
  }
}
