<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<a class="back-to-menu" tabindex="0" role="button" (click)="onBackToMenu()" (keydown.enter)="onBackToMenu()">
  <i aria-hidden="true" class="material-icons icon">arrow_back</i>
  <span class="t3">{{(organizationData$ | async)?.name}}</span>
</a>


<div class="mobile-menu-sidebar" [class.hide-sidebar]="hideMenuSidebar"
     [class.f_hide-sidebar]="hideMenuSidebar">


  <div class="mobile-menu-inner">

    <a class="mobile-menu-item" role="button" [attr.tabindex]="hideMenuSidebar ? -1 : 0"
       (click)="onHomeClick()" (keydown.enter)="onHomeClick()">
      <svg aria-hidden="true"
           class="logo-icon icon"
           width="12"
           height="16"
           viewBox="0 0 12 16"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 13H9V11.5H3V13ZM3 10H9V8.5H3V10ZM1.5 16C1.08333 16 0.729334 15.854 0.438 15.562C0.146 15.2707 0 14.9167 0 14.5V1.5C0 1.08333 0.146 0.729333 0.438 0.438C0.729334 0.146 1.08333 0 1.5 0H8L12 4V14.5C12 14.9167 11.854 15.2707 11.562 15.562C11.2707 15.854 10.9167 16 10.5 16H1.5ZM7 5V1.5H1.5V14.5H10.5V5H7Z"
          fill="#1A1A1A"/>
      </svg>
      <span class="small">{{(organizationData$ | async)?.name}}</span>
      <i aria-hidden="true" class="material-icons arrow-icon">chevron_right</i>
    </a>

    <a class="mobile-menu-item show-in-mobile" [attr.tabindex]="hideMenuSidebar ? -1 : 0"
       (click)="navigateTo('/my-content')" (keydown.enter)="navigateTo('/my-content')">
      <svg class="svg icon" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#save"></use>
      </svg>
      <span class="small">{{ 'translations.header.title.myPages' | transloco }}</span>
      <i aria-hidden="true" class="material-icons arrow-icon">chevron_right</i>
    </a>

    <a class="mobile-menu-item show-in-mobile" [attr.tabindex]="hideMenuSidebar ? -1 : 0"
       (click)="navigateTo('/reviews')" (keydown.enter)="navigateTo('/reviews')">
      <svg class="svg icon" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#rate-review"></use>
      </svg>
      <span class="small">
        {{ 'translations.header.title.toReview' | transloco }}
        <span class="has-review" *ngIf="reviewsCount > 0">{{reviewsCount}}</span>
      </span>
      <i aria-hidden="true" class="material-icons arrow-icon">chevron_right</i>
    </a>

    <div class="mobile-menu-divider"></div>

    <a class="mobile-menu-item" *ngIf="hasUpcomingEvent"
       [attr.tabindex]="hideMenuSidebar ? -1 : 0"
       (click)="navigateTo('/upcoming')" (keydown.enter)="navigateTo('/upcoming')">
      <svg class="svg icon" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#calendar"></use>
      </svg>
      <span class="small">{{ 'translations.header.title.events' | transloco }}</span>
    </a>

    <a class="mobile-menu-item" role="button"
       [attr.tabindex]="hideMenuSidebar ? -1 : 0"
       (click)="onAddNewMoment()" (keydown.enter)="onAddNewMoment()">
      <svg class="svg icon" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#moment"></use>
      </svg>
      <span class="small">{{ 'translations.header.title.newPost' | transloco }}</span>
    </a>

    <a class="mobile-menu-item" [attr.tabindex]="hideMenuSidebar ? -1 : 0" tabindex="0"
       (click)="navigateTo('/account/messages')" (keydown.enter)="navigateTo('/account/messages')">
      <svg class="svg icon" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#mail"></use>
      </svg>
      <span class="small">{{ 'translations.header.title.messages' | transloco }}</span>
    </a>

    <a class="mobile-menu-item" [attr.tabindex]="hideMenuSidebar ? -1 : 0"
       *ngIf="(isUserAdmin$ | async) || (isUserSuperAdmin$ | async)"
       (click)="navigateTo('/admin/members')" (keydown.enter)="navigateTo('/account/messages')">
      <svg class="svg icon" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#tune"></use>
      </svg>
      <span class="small">{{ 'translations.header.title.admin' | transloco }}</span>
    </a>

    <a class="mobile-menu-item" [attr.tabindex]="hideMenuSidebar ? -1 : 0"
       *ngIf="isExploreButtonVisible"
       (click)="navigateTo('/explore')" (keydown.enter)="navigateTo('/explore')">
      <svg class="svg icon" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#explore"></use>
      </svg>
      <span class="small">{{ 'translations.header.title.explore' | transloco }}</span>
    </a>

    <div class="mobile-menu-divider"></div>

    <a class="mobile-menu-item" [attr.tabindex]="hideMenuSidebar ? -1 : 0"
       (click)="navigateTo('/account')" (keydown.enter)="navigateTo('/account')">
      <span class="small">{{ 'translations.header.title.myAccount' | transloco }}</span>
    </a>

    <a class="mobile-menu-item" *ngIf="showPublisherSettings"  [attr.tabindex]="hideMenuSidebar ? -1 : 0"
       (click)="navigateTo('/publisher')" (keydown.enter)="navigateTo('/publisher')">
      <span class="small">{{ 'translations.publisher.button.publisherSettings' | transloco }}</span>
    </a>

    <a class="mobile-menu-item" [attr.tabindex]="hideMenuSidebar ? -1 : 0"
       *ngIf="(isUserAdmin$ | async) || (isUserSuperAdmin$ | async)"
       (click)="navigateTo('/playlists-manager')" (keydown.enter)="navigateTo('/playlists-manager')">
      <span class="small">{{ 'translations.managePlaylist.button.manage' | transloco }}</span>
    </a>

    <button [attr.tabindex]="hideMenuSidebar ? -1 : 0" class="mobile-menu-item mobile-menu-button-item" (click)="logout()" (keydown.enter)="logout()">
      <span class="small">{{ 'translations.header.title.logout' | transloco }}</span>
    </button>


  </div>

</div>
