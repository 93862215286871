/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatTable} from '@angular/material/table';
import {
  CustomDiagnosticFormattedQuestion,
  DiagnosticFormattedQuestion,
  DiagnosticQuestionCopy, DiagnosticQuestionOptionRequest, DiagnosticQuestionOptionWithSelection,
  DiagnosticQuestionRequest
} from '../../../../../shared/models';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { VersionHelper } from '../../../../../shared/helpers/version.helper';

@Component({
  selector: 'ptl-questions-list',
  templateUrl: './questions-list.component.html',
  styleUrls: ['./questions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsListComponent implements OnInit {

  /** The data for the table */
  @Input() tableData: DiagnosticFormattedQuestion[] | undefined;

  @Input() isQuiz: boolean | undefined;

  @Input() isDefaultLanguage: boolean;

  /** Emits on drag and dropping of item */
  @Output() moveItems = new EventEmitter<CdkDragDrop<DiagnosticFormattedQuestion[]> | CustomDiagnosticFormattedQuestion>();
  /** Emits on item remove */
  @Output() removeItem = new EventEmitter<DiagnosticQuestionRequest>();
  /** Emits question to edit on specific index */
  @Output() editIndex = new EventEmitter<number>();
  /** Emits question to copy on specific index */
  @Output() copyQuestion = new EventEmitter<DiagnosticQuestionCopy>();

  @ViewChild('table', {static: true}) table: MatTable<HTMLElement> | undefined;

  prettyStatus = {
    INFO_QUESTION: 'Info',
    SLIDER_QUESTION: 'Slider',
    ORDERING_QUESTION: 'Ranking',
    DRAGDROP_QUESTION: 'Drag & Drop',
    GRID_QUESTION: 'Grid',
    CHOICE_QUESTION: 'Rating/Likert',
    RATING_QUESTION: 'Rating/Likert',
    MULTIPLE_CHOICE_QUESTION: 'Multiple Choice',
  };
  displayedColumns = [];

  newVersionEnabled = VersionHelper.newVersionEnabled();

  private draggedRow: DiagnosticFormattedQuestion;

  ngOnInit() {
    if (this.isQuiz) {
      this.prettyStatus['GRID_QUESTION'] = 'Multiple Choice';
      this.displayedColumns = ['sort', 'question', 'type', 'required', 'edit', 'copy', 'remove'];
    } else {
      this.displayedColumns =
        ['sort', 'question', 'attribute', 'type', 'range', 'required', 'edit', 'copy', 'remove'];
    }
  }

  dropTable(event: CdkDragDrop<DiagnosticFormattedQuestion[]>) {
    this.moveItems.emit(event);
  }

  editQuestion(idx: number) {
    this.editIndex.emit(idx);
  }

  onCopyQuestion(idx: number, questionUid: string) {
    this.copyQuestion.emit({index: idx, questionUid: questionUid});
  }

  renderRows() {
    if (this.table) {
      this.table.renderRows();
    }

  }

  removeQuestion(question: DiagnosticQuestionRequest) {
    this.removeItem.emit(question);
  }

  drag(row: DiagnosticFormattedQuestion) {
    this.draggedRow = row;
  }

  @HostListener('keydown.ArrowUp')
  moveToUp() {
    if (!this.draggedRow) {
      return;
    }

    const currentDraggedItemIndex = this.tableData.indexOf(this.draggedRow)
    if (currentDraggedItemIndex === 0) {
      return;
    }

    this.moveItemTo('top', currentDraggedItemIndex);
    this.draggedRow = undefined;
  }

  @HostListener('keydown.ArrowDown')
  moveToDown() {
    if (!this.draggedRow) {
      return;
    }

    const currentDraggedItemIndex = this.tableData.indexOf(this.draggedRow)
    if (currentDraggedItemIndex === this.tableData.length - 1) {
      return;
    }

    this.moveItemTo('down', currentDraggedItemIndex);
    this.draggedRow = undefined;
  }

  moveItemTo(topOrDown, currentDraggedItemIndex) {
    switch (topOrDown) {
      case 'top':
        this.moveItems.emit({
          data: this.draggedRow,
          currentIndex: currentDraggedItemIndex - 1
        } as CustomDiagnosticFormattedQuestion);
        return;
      case 'down':
        this.moveItems.emit({
          data: this.draggedRow,
          currentIndex: currentDraggedItemIndex + 1
        } as CustomDiagnosticFormattedQuestion);
        return;
    }
  }
}
