<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div
  class="workspaces-sidebar"
  *ngIf="showSideBar"
  [class.is_active]="expanded">
  <span class="cdk-visually-hidden"
        #toggleWorkspaceSidebarTitle>{{ 'translations.account.workspaces.label.toggleWorkspaceSidebar' | transloco }}</span>
  <button mat-icon-button
          type="button"
          class="workspaces-sidebar__container-expand-box"
          [style.top]="placeExpandButtonLower ? '2.938rem' : ''"
          (click)="expandWorkspaceSidebar()"
          [attr.aria-label]="('translations.global.ariaLabels.toggleWorkspaceSidebar' | transloco)"
          title="{{ toggleWorkspaceSidebarTitle.innerText }}">
    <mat-icon aria-hidden="true">{{ expanded ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}}</mat-icon>
  </button>
  <div class="workspaces-sidebar__container" *ngIf="expanded">
    <div class="workspaces-sidebar__list-container">
      <ul class="workspaces-sidebar__list">
        <li
          class="workspaces-sidebar__list-item"
          [class.is_active]="workspace.domain === domain"
          tabindex="0"
          [attr.aria-label]="('translations.global.ariaLabels.switchToWorkspace' | transloco: {workspace: workspace.name})"
          (click)="loadWorkspace(workspace.domain, workspace.organizationUid)"
          (keydown.enter)="loadWorkspace(workspace.domain, workspace.organizationUid)"
          *ngFor="let workspace of userData?.workspaces">
          <div class="workspaces-sidebar__list-item-link" [style.backgroundImage]="'url(' + workspace.logo + ')'"></div>
        </li>
      </ul>
    </div>
  </div>
</div>
