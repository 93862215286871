<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<button
  mat-raised-button
  class="uppercase add-question-button"
  color="primary"
  title="Add Question"
  *ngIf="currentLanguage?.isDefault && (!newVersionEnabled || newVersionEnabled && !isQuiz)"
  (click)="toggleQuestionForm()">
  <span>{{ 'translations.addQuestion' | transloco }}</span>
</button>

<ptl-edit-question
  *ngIf="questionFormVisible"
  [isEditingQuestion]="isEditingQuestion"
  [isQuiz]="isQuiz"
  [class.question-edit-container]="newVersionEnabled && isQuiz"
  [languageCode]="languageCode"
  [editableQuestion]="editableQuestion"
  [questionTypes]="questionTypes"
  [currentLanguage]="currentLanguage"
  (feedbackCleared)="onFeedBackClear($event);"
  (closeForm)="toggleQuestionForm()"
  (addedQuestion)="addQuestion($event)"
  (updatedQuestion)="updateQuestion($event)"
></ptl-edit-question>

<ptl-questions-list
  #tableList
  [class.questions-list-new]="newVersionEnabled && isQuiz"
  *ngIf="formattedQuestions"
  [isQuiz]="isQuiz"
  [isDefaultLanguage]="currentLanguage?.isDefault"
  [tableData]="formattedQuestions"
  (moveItems)="onItemsMove($event)"
  (removeItem)="onItemRemove($event)"
  (editIndex)="onIndexEdit($event)"
  (copyQuestion)="onQuestionCopy($event)"
></ptl-questions-list>

<button
  mat-button
  class="add-question-button-new"
  title="Add Question"
  *ngIf="currentLanguage?.isDefault && newVersionEnabled && isQuiz"
  (click)="toggleQuestionForm()">
  <svg class="svg svg-icon icon-left" aria-hidden="true">
    <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
  </svg>
  <span class="small">{{ 'translations.addQuestion' | transloco }}</span>
</button>
