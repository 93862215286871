/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReviewEvent } from 'src/app/shared/models';
import { UserDetailsSummary } from 'src/app/user-auth/models';
import { UserAuthState } from 'src/app/user-auth/store/user-auth.state';

@Component({
  selector: 'ptl-form-review-events-list',
  templateUrl: './form-review-events-list.component.html',
  styleUrls: ['./form-review-events-list.component.scss'],
})
export class FormReviewEventsListComponent implements OnInit {

  @Input() reviewEvents: ReviewEvent[];
  @Input() playlistSubmissionDeadline: Date;

  @Select(UserAuthState.userDetailsData)
  organizationData$: Observable<UserDetailsSummary>;

  userUid: string;

  ngOnInit(): void {
    this.organizationData$.subscribe(value => {
      this.userUid = value.uid;
    })
  }
}
