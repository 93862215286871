/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnInit } from '@angular/core';
import { ImageDimensions } from '@app/app/shared/models/images/unsplash-images.model';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'ptl-skeleton-image-item',
  templateUrl: './skeleton-image-item.component.html',
  styleUrls: ['./skeleton-image-item.component.scss'],
})
export class SkeletonImageItemComponent implements OnInit {
  @Input() imageDimensions: ImageDimensions;

  items: number[] = new Array(10);
  imageWidth = '500px';

  ngOnInit(): void {
    if (!isEmpty(this.imageDimensions)) {
      const skeletonItemHeight = 37;
      this.items = new Array(Math.floor(this.imageDimensions.imageHeight / skeletonItemHeight));
      this.imageWidth = `${this.imageDimensions.imageWidth}px`;
    }
  }
}
