import { InjectionToken } from '@angular/core';
import { ObservableResult } from '../../store';
import { QuestionFormList } from '../../models/lists/list.model';

export const SHARED_LISTS_DATA_SERVICE =
  new InjectionToken<SharedListsDataService>('[resource-editor] SharedListsDataService');

/** The service handling resource CRUD operations. */
export interface SharedListsDataService {

  createFormQuestionTextList(title: string): ObservableResult<QuestionFormList>;

  searchListsByTerm(title: string): ObservableResult<QuestionFormList>;

  getFormListByOptionUid(optionsListUid: string): ObservableResult<QuestionFormList>;

  addElementToFormQuestionList(listUid: string, data: {
    parentUid: string;
    value: string;
  }): ObservableResult<QuestionFormList>;

  updateFormQuestionListTitle(listUid: string, data: { title: string }): ObservableResult<QuestionFormList>;

  updateFormQuestionListElementByElementUid(listUid: string, elementUid: string, data: {
    value: string;
  }): ObservableResult<QuestionFormList>;

  deleteFormQuestionListElement(listUid: string, elementUid: string): ObservableResult<null>;
}
