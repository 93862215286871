/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { FormGroup } from '@angular/forms';

export class FormsHelper {
  static isEqual(controlName: string, matchingControlName: string): (formGroup: FormGroup) => void {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  static isValidUrlWithPattern(url: string): boolean {
    // eslint-disable-next-line no-useless-escape
    const urlPattern = /^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,6})(\/[\w\.-]*)*\/?(\?[^\s]*)?(#[^\s]*)?$/i;
    try {
      new URL(url);
    } catch (_) {
      return false;
    }
    return urlPattern.test(url);
  }
}
