<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="!maintenanceModeEnabled">
  <div [class.iframe-mode]="isIframeMode">
    <router-outlet></router-outlet>
  </div>

  <div class="pi-loader" *ngIf="loading">
    <div class="pi-loader__spinner"></div>
  </div>
</ng-container>

<ng-container *ngIf="maintenanceModeEnabled">
  <div class="error-content">
    <div class="error-content-inner">
      <div class="error-content-left">
        <h1 class="error-title">{{'translations.maintenance.title' | transloco}}</h1>
        <div class="error-description">{{'translations.maintenance.description' | transloco}}</div>
      </div>
      <div class="error-content-right">
        <img src="/assets/404.svg" alt="" width="88" height="67" class="error-content-image">
      </div>
    </div>
  </div>
</ng-container>
