/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EditorContent } from '../../shared/models';

export * from '../../shared/models/editor/editor-content.model';
export * from '../../shared/models/editor/media-content.model';
export * from '../../shared/models/editor/form-content.model';
export * from '../../shared/models/editor/events.model';

export function numericOnlyValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const value = control.value;

    // Check if the value is empty
    if (Validators.required(control) !== null) {
      return null; // Return null if the field is not required and empty
    }

    // Check if the value contains only numeric characters
    if (/^[0-9]+$/.test(value)) {
      return null; // Return null if the value is numeric
    }

    return { numericOnly: true }; // Return an error if the value is not numeric
  };
}

export function atLeastOneRequiredValidator(instructionControl: AbstractControl, descriptionControl: AbstractControl): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const instruction = instructionControl.value;
    const descriptionBlocks = descriptionControl.value?.blocks as EditorContent[];
    let descriptionHasContent = false;

    descriptionBlocks.forEach((item) => {
      if (item.type === 'PARAGRAPH' && item.content.length) {
        descriptionHasContent = true;
      }

      if (item.type === 'MEDIA_UPLOAD') {
        descriptionHasContent = true;
      }
    });

    if (instruction || descriptionHasContent) {
      return null;
    } else {
      return { atLeastOneRequired: true };
    }
  };
}
