/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { ObservableResult } from '../../../shared/store';
import { LearnerViewDataService } from './data.service';
import { Location } from '@angular/common';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RestClientService } from '../../../shared/services/rest-client.service';
import { CardWithProgress, LearnerFormAnswerRequest, LearnerFormUpdatePermission, LearnerResourceProgress } from '../models';
import { CardCompletionRequirements } from '../../../shared/models/completion-logic/completion-logic.model';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { Resource } from '../../../shared/models';
import { HttpErrorResponse } from '@angular/common/http';
import { PrerequisitesRequest, RequirementsMode } from '../../playlist/models';
import { SubmitPlaylistSummary } from 'src/app/shared/models/playlist/playlist-report.model';
import { ProjectHelper } from '../../project/helpers/project.helper';
import { throwError } from 'rxjs';

/* eslint-disable max-len */
@Injectable()
export class ApiLearnerViewDataService implements LearnerViewDataService {

  constructor( private client: RestClientService, private translationService: TranslationService ) {
  }

  getCardWithProgress(
    isProjectResource: boolean,
    playlistUri: string,
    resourceUri: string,
    groupUri: string,
    publisherUri: string,
    packageUri: string,
    pageUri: string,
    languageCode: string,
  ): ObservableResult<CardWithProgress> {
    const isProjectPublicView = ProjectHelper.isProjectPublicView();
    const projectUrlPrefix = isProjectPublicView ? `projects/public/${playlistUri}` : `projects/${playlistUri}`;
    const playlistUrlPrefix = `playlists/${playlistUri}`;
    const prefix = isProjectResource ? projectUrlPrefix : playlistUrlPrefix;
    let url = groupUri ?
      `${prefix}/cards/${groupUri}/${resourceUri}` :
      `${prefix}/cards/${resourceUri}`;
    // if (pageUri) {
    //   url = `pages/${pageUri}/${url}`
    // }
    //
    // if (publisherUri && packageUri) {
    //   url = `pub/${publisherUri}/${packageUri}/${url}`
    // }

    if(publisherUri && packageUri) {
      url = `pub/${publisherUri}/${packageUri}/pages/${pageUri}/${url}`
    }


    return this.client.get<CardWithProgress>(Location.joinWithSlash(environment.apiRootUrl || '', url),
      { includeProgress: 'true' },
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(( err ) =>
        this.handleErrorResponse(err, this.translationService.getTranslation('errors.errorGetResource'))
      )
    );
  }

  getGroupCards(
    playlistUid: string,
    groupUid: string,
    languageCode: string,
  ): ObservableResult<Resource> {
    return this.client.get<Resource>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/cards/${groupUid}`),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetGroupCards')))
    );
  }

  validateFormUpdate(playlistUid: string, resourceUid: string, formUid: string): ObservableResult<LearnerFormUpdatePermission> {
    return this.client.get<LearnerFormUpdatePermission>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/cards/${resourceUid}/progress/forms/${formUid}/update_validation`)
    ).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorValidatingFormUpdate')))
    );
  }

  saveFormAnswer( playlistUid: string, resourceUid: string, formAnswer: LearnerFormAnswerRequest ): ObservableResult<LearnerResourceProgress> {
    return this.client.post<LearnerResourceProgress>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/cards/${resourceUid}/progress`), formAnswer
    ).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  submitForm(playlistUid: string, resourceUid: string, formId: string ): ObservableResult<void> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `playlists/${playlistUid}/cards/${resourceUid}/progress/forms/${formId}/submissions`
    );
    return this.client.post<void>(url).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorSubmitFromAnswer')))
    );
  }

  submitFormAndPlaylist(playlistUid: string, resourceUid: string, formId: string, request: SubmitPlaylistSummary): ObservableResult<void> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `playlists/${playlistUid}/cards/${resourceUid}/progress/forms/${formId}/submissions`
    );
    return this.client.post<void>(url, request).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorSubmittingFormAnswerAndInitializingReview')))
    );
  }

  removeFileFromForm(playlistUid: string, resourceUid: string, formUid: string, filePath: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `playlists/${playlistUid}/cards/${resourceUid}/progress/forms/${formUid}/files`),
      {
        file: filePath
      }
    ).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveFileFromForm')))
    );
  }

  loadCompletionActivities( playlistUri: string, resourceUri: string ): ObservableResult<CardCompletionRequirements> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `playlists/${playlistUri}/cards/${resourceUri}/requirements`
    );

    return this.client.get<CardCompletionRequirements>(url).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetResourceActivity')))
    );
  }

  updateCompletionPrerequisites( playlistUri: string, resourceUri: string, requestBody: PrerequisitesRequest ): ObservableResult<void> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `playlists/${playlistUri}/cards/${resourceUri}/requirements/prerequisites`
    );

    return this.client.put<void>(url, requestBody).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(''))
    )
  }

  updateCompletionRequirementsMode( playlistId: string, mode: RequirementsMode, minRequiredCard: number ): ObservableResult<void> {

    const params = {
      m: mode,
    }
    if (minRequiredCard) {
      params['min'] = minRequiredCard;
    }

    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `playlists/${playlistId}/requirements/mode`
    );

    return this.client.patch<void>(url, {}, params).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(''))
    )
  }

  setCardCompletionRequired( playlistUid: string, resourceUid: string, required: boolean ): ObservableResult<void> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `playlists/${playlistUid}/cards/${resourceUid}/required`
    );

    return this.client.put<void>(
      url,
      required,
      {},
      { 'Content-type': 'application/json' }
    ).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorSetCardCompletionRequired')))
    );
  }

  setActivityRequired( playlistId: string, resourceUid: string, activityId: string, required: boolean ): ObservableResult<void> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `playlists/${playlistId}/cards/${resourceUid}/requirements/${activityId}/required`
    );

    return this.client.put<void>(
      url,
      JSON.stringify(required),
      {},
      { 'Content-type': 'application/json' }
    ).pipe(
      switchMap(( { body } ) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorSetCardCompletionActivityRequired')))
    );
  }

  private handleErrorResponse( err: HttpErrorResponse, errorMessage: string ) {
    if(err.error?.status === 'NOT_FOUND') {
      const message = err.error.message.toLowerCase();
      if(message.indexOf('is un published') !== -1) {
        return ObservableResult.ofError(
          this.translationService.getTranslation('errors.errorGetUnpublishedResource')
        );
      }
    }
    return ObservableResult.ofError(errorMessage, err.error?.dataForms);
  }
}
