/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { ContentPackageDataService } from './content-package-data.service';
import { ObservableResult } from '../../store';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { TranslationService } from '../translation/translation.service';
import {
  AddPlaylistFromStoreRequest,
  ContentPackageThumbnailUpdateRequest,
  ContentPackagePageOrPlaylistCreateRequest,
  CreatePublisherPackageRequest,
  CreatePublisherRequest,
  PackageSubscriber,
  PageContentStoreSummary,
  Publisher,
  PublisherPackage,
  SubmitContentStoreRequest,
  SyndicationResponse,
  UpdatePublisherPackageRequest,
  PublisherPackageLicense,
  StandardTailoredSubjectList,
  TailoredSubjectSearchResult,
  TailoredSubjectRequest, TailoredSubjectList
} from '../../models/content-store/content-store.model';
import { Page as Pagination } from '../../models/page';
import { Playlist, SearchPlaylist } from '../../models';
import { Page, SearchPage } from '../../models/page/page.model';
import { ItemWithUploadUrl } from '../../models/ItemWithUploadUrl';

@Injectable()
export class ApiContentPackageDataService implements ContentPackageDataService {

  constructor(private client: RestClientService, private translationService: TranslationService) {
  }


  submitPlaylist(request: SubmitContentStoreRequest): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', 'store/submit');
    return this.client.post<void>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorSubmittingPlaylist')))
      );
  }

  updateContentStoreSettings(request: SubmitContentStoreRequest): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', 'store/submit');
    return this.client.patch<void>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorSubmittingPlaylist')))
      );
  }

  createPublisher(request: CreatePublisherRequest): ObservableResult<Publisher> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', 'store/publishers/create');
    return this.client.post<Publisher>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorCreatingPublisher')))
      );
  }


  updatePublisher(publisherUid: string, request: CreatePublisherRequest): ObservableResult<Publisher> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `store/publishers/${publisherUid}`);
    return this.client.patch<Publisher>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorUpdatingPublisher')))
      );
  }

  getCurrentOrganizationPublisher(): ObservableResult<Publisher> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', 'store/publishers');
    return this.client.get<Publisher>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingPublisher')))
      );
  }

  getPublisherByUid(publisherUid: string): ObservableResult<Publisher> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `store/publishers/${publisherUid}`);
    return this.client.get<Publisher>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingPublisher')))
      );
  }

  getPublisherByUserUid(userUid: string): ObservableResult<Publisher> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `store/users/${userUid}`);
    return this.client.get<Publisher>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingPublisher')))
      );
  }

  getSubmittedPlaylists(page: number, size: number): ObservableResult<Pagination<Playlist>> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', 'playlists/store/submitted');
    const params = {
      page: page.toString(),
      size: size.toString(),
    };
    return this.client.get<Pagination<Playlist>>(url, params)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingSubmittedPlaylists')))
      );
  }

  getSubmittedPages(page: number, size: number): ObservableResult<Pagination<SearchPage>> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', 'pages/store/published');
    const params = {
      page: page.toString(),
      size: size.toString(),
    };
    return this.client.get<Pagination<SearchPage>>(url, params)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingSubmittedPages')))
      );
  }

  getPublisherPages(publisherUid: string, page: number, size: number): ObservableResult<Pagination<PageContentStoreSummary>> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `pages/store/publishers/${publisherUid}`);
    const params = {
      page: page.toString(),
      size: size.toString(),
    };
    return this.client.get<Pagination<PageContentStoreSummary>>(url, params)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingPublishersPages')))
      );
  }

  getPublisherPlaylists(publisherUid: string, page: number, size: number): ObservableResult<Pagination<Playlist>> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `playlists/store/publishers/${publisherUid}`);
    const params = {
      page: page.toString(),
      size: size.toString(),
    };
    return this.client.get<Pagination<Playlist>>(url, params)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingPublishersPlaylists')))
      );
  }

  getCategories(frameworkUid: string, tagUid: string, page: number, size: number): ObservableResult<Pagination<Playlist>> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `playlists/store/categories/${frameworkUid}/tags/${tagUid}`);
    const params = {
      page: page.toString(),
      size: size.toString(),
    };
    return this.client.get<Pagination<Playlist>>(url, params)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingCategories')))
      );
  }

  searchPlaylists(text: string, page = 0, size = 10): ObservableResult<Pagination<SearchPlaylist>> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', '/store/search');
    const params = {
      term: text,
      page: page.toString(),
      size: size.toString(),
    };
    return this.client.get<Pagination<SearchPlaylist>>(url, params)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGettingSubmittedPlaylists')))
      );
  }

  syndicatePlaylist(playlistUid: string, request: AddPlaylistFromStoreRequest): ObservableResult<SyndicationResponse> {
    return this.client.post(
      Location.joinWithSlash(environment.apiRootUrl || '', `/playlists/${playlistUid}/syndication/syndicate`),
      request,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorSyndicatingPlaylist')))
    );
  }

  syndicatePage(pageUid: string): ObservableResult<Page> {
    return this.client.post(
      Location.joinWithSlash(environment.apiRootUrl || '', `/pages/${pageUid}/syndication`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorSyndicatingPage')))
    );
  }

  createPublisherPackage(request: CreatePublisherPackageRequest): ObservableResult<ItemWithUploadUrl<PublisherPackage>> {
    return this.client.post<ItemWithUploadUrl<PublisherPackage>>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/packages'),
      request,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorCreatingPackage')))
    );
  }

  deleteContentPackageThumbnail(contentPackageUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/packages/${contentPackageUid}/thumbnails`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorDeletingPackage')))
    );
  }

  updatePublisherPackage(contentPackageUid: string, request: UpdatePublisherPackageRequest):
    ObservableResult<ItemWithUploadUrl<PublisherPackage>> {
    return this.client.patch<ItemWithUploadUrl<PublisherPackage>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/packages/${contentPackageUid}`),
      request,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorUpdatingPackage')))
    );
  }

  updateContentPackageThumbnail(contentPackageUid: string, request: ContentPackageThumbnailUpdateRequest)
    : ObservableResult<ItemWithUploadUrl<PublisherPackage>> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `packages/${contentPackageUid}/thumbnails`);
    return this.client.patch<ItemWithUploadUrl<PublisherPackage>>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(
          this.translationService?.getTranslation('errors.errorSubmittingContentPackageThumbnail'))
        )
      );
  }

  getPublisherPackages(publisherUid: string, page: number, size: number, term?: string): ObservableResult<Pagination<PublisherPackage>> {
    const params = {
      page: page.toString(),
      size: size.toString()
    };
    if (term) {
      params['term'] = term;
    }
    return this.client.get<Pagination<PublisherPackage>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/packages/pub/${publisherUid}`),
      params,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetPublisherPackages')))
    );
  }

  addContentPackageItem(contentPackageId: string, request: ContentPackagePageOrPlaylistCreateRequest)
    : ObservableResult<PublisherPackage> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `packages/${contentPackageId}/items`);
    return this.client.put<PublisherPackage>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(
          this.translationService?.getTranslation('errors.errorCreatingContentPackagePage'))
        )
      );
  }

  deleteContentPackageItem(contentPackageId: string, itemUid: string)
    : ObservableResult<PublisherPackage> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `packages/${contentPackageId}/items/${itemUid}`);
    return this.client.delete<PublisherPackage>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(
          this.translationService?.getTranslation('errors.errorDeletingContentPackagePage'))
        )
      );
  }


  createContentPackageLicense(contentPackageId: string, request: PublisherPackageLicense)
    : ObservableResult<PublisherPackage> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `/packages/${contentPackageId}/licenses`);
    return this.client.post<PublisherPackage>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(
          this.translationService?.getTranslation('errors.errorCreatingContentPackagePage'))
        )
      );
  }


  updateContentPackageLicense(contentPackageId: string, request: PublisherPackageLicense)
    : ObservableResult<PublisherPackage> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `/packages/${contentPackageId}/licenses/${request.uid}`);
    return this.client.patch<PublisherPackage>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(
          this.translationService?.getTranslation('errors.errorCreatingContentPackagePage'))
        )
      );
  }


  getPackageDetailById(contentPackageUid: string): ObservableResult<PublisherPackage> {
    return this.client.get<PublisherPackage>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/packages/${contentPackageUid}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetContentPackage')))
    );
  }

  getPackageSubscribers(contentPackageUid: string, page: number, size: number, term?: string)
    : ObservableResult<Pagination<PackageSubscriber>> {
    const params = {
      page: page.toString(),
      size: size.toString()
    };
    if (term) {
      params['term'] = term;
    }
    return this.client.get<Pagination<PackageSubscriber>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/packages/subscriptions/package/${contentPackageUid}`),
      params,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetPackageSubscribers')))
    );
  }

  unsubscribeFromPackage(contentPackageUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/packages/subscriptions/${contentPackageUid}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorUnsubscribeFromPackage')))
    );
  }

  subscribeToPackage(contentPackageUid: string): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/packages/subscriptions/${contentPackageUid}/reactivate`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorSubscribeToPackage')))
    );
  }

  packageAddInstitutionalAdmin(adminUid: string): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/packages/subscriptions/admin/${adminUid}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorAddInstitutionalAdmin')))
    );
  }

  packageRemoveInstitutionalAdmin(adminUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/packages/subscriptions/admin/${adminUid}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorDeleteInstitutionalAdmin')))
    );
  }

  packageAddAnalyticsAccess(adminUid: string): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/packages/subscriptions/analytics/${adminUid}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorAddAnalyticsAccess')))
    );
  }

  packageRemoveAnalyticsAccess(adminUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/packages/subscriptions/analytics/${adminUid}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorRemoveAnalyticsAccess')))
    );
  }

  addDataForm(contentPackageUid: string, dataFormUid: string): ObservableResult<void> {
    return this.client.put<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `packages/${contentPackageUid}/forms/${dataFormUid}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('global.dataForms.error.addingDataForm'))));
  }

  removeDataForm(contentPackageUid: string, dataFormUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${contentPackageUid}/forms/${dataFormUid}`),
      null,
      null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('global.dataForms.error.removingDataForm'))));
  }

  createTailoredSubjectsList(organizationId: string, packageId: string, request: TailoredSubjectRequest): ObservableResult<void> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `/group-lists/tailored/subjects?orgUid=${organizationId}&packageUid=${packageId}`
    );
    return this.client.post<void>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorCreateTailoredSubjectsList')))
      );
  }

  createTailoredDepartmentsList(organizationId: string, packageId: string, request: TailoredSubjectRequest): ObservableResult<void> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `/group-lists/tailored/departments?orgUid=${organizationId}&packageUid=${packageId}`
    );
    return this.client.post<void>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorCreateTailoredDepartmentsList')))
      );
  }

  updateTailoredSubjectsList(listUid: string, packageUid: string, request: TailoredSubjectRequest)
    : ObservableResult<void> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `/group-lists/tailored/subjects/${listUid}?packageUid=${packageUid}`
    );
    return this.client.patch<void>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorUpdateTailoredSubjectsList')))
      );
  }

  updateTailoredDepartmentsList(listUid: string, packageUid: string, request: TailoredSubjectRequest)
    : ObservableResult<void> {
    const url = Location.joinWithSlash(
      environment.apiRootUrl || '', `/group-lists/tailored/departments/${listUid}?packageUid=${packageUid}`
    );
    return this.client.patch<void>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorUpdateTailoredDepartmentsList')))
      );
  }

  searchTailoredSubjects(page: number, size: number, organizationUid: string, packageUid: string, term: string):
    ObservableResult<Pagination<TailoredSubjectSearchResult>> {
    return this.client.get<Pagination<TailoredSubjectSearchResult>>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/group-lists/tailored/subjects/search'),
      {
        orgUid: organizationUid,
        packageUid: packageUid,
        term: term,
        page: page.toString(),
        size: size.toString()
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetTailoredSubjects')))
    );
  }

  searchTailoredDepartments(page: number, size: number, organizationUid: string, packageUid: string, term: string):
    ObservableResult<Pagination<TailoredSubjectSearchResult>> {
    return this.client.get<Pagination<TailoredSubjectSearchResult>>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/group-lists/tailored/departments/search'),
      {
        orgUid: organizationUid,
        packageUid: packageUid,
        term: term,
        page: page.toString(),
        size: size.toString()
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetTailoredDepartments')))
    );
  }

  getJacs3List(): ObservableResult<StandardTailoredSubjectList> {
    return this.client.get<StandardTailoredSubjectList>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/group-lists/tailored/subjects/jacs3'),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetPackageSubscribers')))
    );
  }

  getOfqualList(): ObservableResult<StandardTailoredSubjectList> {
    return this.client.get<StandardTailoredSubjectList>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/group-lists/tailored/subjects/ofqual'),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetPackageSubscribers')))
    );
  }

  getTailoredSubjects(organizationId: string, packageId: string): ObservableResult<TailoredSubjectList> {
    return this.client.get<TailoredSubjectList>(Location.joinWithSlash(environment.apiRootUrl || '',
      `/group-lists/tailored/subjects?orgUid=${organizationId}&packageUid=${packageId}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetPackageSubscribers')))
    );
  }

  getTailoredDepartments(organizationId: string, packageId: string): ObservableResult<TailoredSubjectList> {
    return this.client.get<TailoredSubjectList>(Location.joinWithSlash(environment.apiRootUrl || '',
      `/group-lists/tailored/departments?orgUid=${organizationId}&packageUid=${packageId}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorGetPackageSubscribers')))
    );
  }
}
