/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../shared/store';
import { InjectionToken } from '@angular/core';
import { Page as Pagination } from '../../../shared/models/page';
import {
  Page, PageAuthorInfoUpdateRequest,
  PageHeadersRequest, PageList,
  PageRequest,
  PageThumbnailUpdateRequest, SearchPage, UpdatePageNavigationRequest, UpdateSyndicationRequest
} from '../../../shared/models/page/page.model';
import { LearnerPlaylistSummary } from '../../../shared/models/playlist/learner-playlist-summary.model';
import { ItemWithUploadUrl } from '../../../shared/models/ItemWithUploadUrl';
import { PageContentStoreSummary } from '../../../shared/models/content-store/content-store.model';
import { PlaylistCardShort } from '../../../shared/models';


/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const PAGES_DATA_SERVICE = new InjectionToken<PageDataService>('[Pages] DataService');

/** The admin data service. */
export interface PageDataService {

  /** Create page for the given organization. */
  createPage(request: PageRequest): ObservableResult<Page>;

  /** Gets pages of the organization. */
  getPages(page: number, size: number): ObservableResult<PageList[]>;

  /** Gets the default home page of the organization. */
  getHomePage(isAlumni?: boolean, languageCode?: string): ObservableResult<Page>;

  updatePage(pageUid: string, request: PageRequest, languageCode?: string): ObservableResult<void>;

  updateHeaders(pageUid: string, request: PageHeadersRequest, languageCode?: string): ObservableResult<void>;

  /** Gets the page by uri. */
  getPage(
    pageUri: string,
    publisherUri: string,
    packageUri: string,
    frameworkId?: string,
    tagId?: string,
    languageCode?: string,
    contentStore?: boolean,
  ): ObservableResult<Page>;

  searchPage(term: string, page: number, size: number): ObservableResult<Pagination<SearchPage>>;

  searchContentStorePage(page: number, size: number, term: string): ObservableResult<Pagination<SearchPage>>;

  getPageSummary(pageUid: string): ObservableResult<PageContentStoreSummary>;

  getStorePagesItems(page: number, size: number, languageCode?: string): ObservableResult<Pagination<PageContentStoreSummary>>;

  /** Returns latest playlists for given user from organization. */
  getLatestPlaylists(page: number, size: number, frameworkId?: string, tagId?: string, languageCode?: string, contentStore?: boolean):
    ObservableResult<Pagination<LearnerPlaylistSummary>>;

  /** Returns uncompleted learners playlists list */
  getUncompletedPlaylists(page: number, size: number, languageCode?: string): ObservableResult<Pagination<LearnerPlaylistSummary>>;

  getYourPlaylists(page: number, size: number, term?: string, languageCode?: string): ObservableResult<Pagination<LearnerPlaylistSummary>>;

  getSubscriptionPlaylists(page: number, size: number): ObservableResult<Pagination<LearnerPlaylistSummary>>;

  getInstitutionalLibraryPlaylist(): ObservableResult<PlaylistCardShort>;

  changePageThumbnail(pageUid: string, request: PageThumbnailUpdateRequest, languageCode?: string): ObservableResult<{ url: string }>;

  removePageThumbnail(pageUid: string, languageCode?: string): ObservableResult<void>;

  archivePage(pageUid: string): ObservableResult<void>;

  switchToHomePage(pageUid: string): ObservableResult<void>;

  clonePage(pageUid: string): ObservableResult<PageRequest>;

  getTableauToken(pageUid: string, languageCode?: string): ObservableResult<{ token: string }>;

  associateAdmin(pageUid: string, adminUid: string): ObservableResult<void>;

  dissociateAdmin(pageUid: string, adminUid: string): ObservableResult<void>;

  addTag(pageUid: string, tagUid: string): ObservableResult<void>;

  removeTag(pageUid: string, tagUid: string): ObservableResult<void>;

  targetPageToGroup(pageUid: string, groupUid: string): ObservableResult<void>;

  targetPageRemoveGroup(pageUid: string, groupUid: string): ObservableResult<void>;

  targetPageToUser(pageUid: string, userUid: string): ObservableResult<void>;

  targetPageRemoveUser(pageUid: string, userUid: string): ObservableResult<void>;

  targetPageRemoveAllUsers(pageUid: string): ObservableResult<void>;

  targetPageRemoveAllGroups(pageUid: string): ObservableResult<void>;

  addLanguage(pageUid: string, languageCode: string): ObservableResult<void>;

  removeLanguage(pageUid: string, languageCode: string): ObservableResult<void>;

  enableLanguage(pageUid: string, languageCode: string): ObservableResult<void>;

  disableLanguage(pageUid: string, languageCode: string): ObservableResult<void>;

  addDataForm(pageUid: string, dataFormUid: string): ObservableResult<void>;

  removeDataForm(pageUid: string, dataFormUid: string): ObservableResult<void>;

  updateAuthorInfo(pageUid: string, request: PageAuthorInfoUpdateRequest): ObservableResult<void>;

  updateSyndicateStatus(pageUid: string, request: UpdateSyndicationRequest): ObservableResult<void>;

  updateNavigation(pageUid: string, request: UpdatePageNavigationRequest): ObservableResult<void>;

}
