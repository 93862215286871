/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ptl-required-icon',
  templateUrl: './playlist-card-required-icon.component.html',
  styleUrls: ['./playlist-card-required-icon.component.scss'],
})
export class PlaylistCardRequiredIconComponent {
  @Input() required: boolean;
  @Input() offsetTop: number;
  @Input() offsetLeft: number;
}
