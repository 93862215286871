/*
 * Copyright (C) 2022 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Inject, Injectable } from '@angular/core';
import {
  AnswerRequest, DataForm,
  DataFormSummary, DataFormWithAnswers, UserDataForm
} from '../../models/data-form/data-form.model';
import {
  DataFormsDialogComponent
} from '../../components/data-forms/data-forms-dialog/data-forms-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DATA_FORM_DATA_SERVICE, DataFormDataService } from './data-form-data.service';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';

@Injectable({
  providedIn: 'root'
})
export class DataFormDialogService {

  public dataForms: DataFormSummary[] = [];
  private canClose: boolean;
  private retake: boolean;
  private onClose: (hasAnswers: boolean) => void;

  constructor(
    public dialog: MatDialog,
    @Inject(DATA_FORM_DATA_SERVICE) private dataFormDataService: DataFormDataService
  ) {
  }

  displayDialog(dataForms: DataFormSummary[], canClose: boolean, onClose: () => void) {
    this.canClose = canClose;
    this.retake = false;
    this.onClose = onClose;
    this.getFormData(dataForms);
  }

  displayDialogForRetake(dataForm: DataForm, userForm: UserDataForm, onClose: (hasAnswers: boolean) => void) {
    this.canClose = true;
    this.retake = true;
    this.onClose = onClose;
    this.openDialog([{dataForm: dataForm, userForm: userForm}]);
  }

  getFormData(summaryArr: DataFormSummary[]) {
    const responses: DataFormWithAnswers[] = [];
    const summaryCount = summaryArr.length;
    let currentCount = 0;

    for (const summary of summaryArr) {
      this.dataFormDataService.getDataForm(summary.dataFormUid).subscribe(response => {
          if (response.isSuccess) {
            if (summary.userData) {
              responses.push({dataForm: response.value, userForm: summary.userData})
              currentCount++
              this.checkResponsesAndOpenDialog(responses, currentCount, summaryCount)
            } else {
              this.dataFormDataService.createUserForm(response.value.uid).subscribe(userForm => {
                  if (userForm.isSuccess) {
                    responses.push({dataForm: response.value, userForm: userForm.value})
                    currentCount++
                    this.checkResponsesAndOpenDialog(responses, currentCount, summaryCount)
                  }
                }
              )
            }
          }
        }
      );
    }
  }

  sendFormData(form: { answers: AnswerRequest[]; pageLocation: Location; userFormUid: string }[]) {
    const formCount = form.length;
    let count = 0;

    for (const {userFormUid, answers, pageLocation} of form) {
      this.dataFormDataService.updateUserForm(userFormUid, {answers}).subscribe(
        response => {
          if (response.isSuccess) {
            count++;
            if (!this.retake && formCount === count) {
              pageLocation.reload();
            }
          }
        }
      );
    }
  }

  openDialog(response: DataFormWithAnswers[]) {
    if (this.dialog) {
      this.dialog.closeAll()
    }
    let canClose = false;

    for (const {dataForm} of response) {
      canClose = this.canClose || !dataForm.mandatory || canClose;
    }

    let dialogRef = this.dialog.open(DataFormsDialogComponent, {
      width: '90vw',
      maxWidth: '41.875rem',
      maxHeight: '80vh',
      position: {
        top: '10vh',
        bottom: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: ['page-publish-dialog', 'ptl-mat-dialog'],
      backdropClass: 'dialog-backdrop',
      disableClose: !canClose,
      autoFocus: false,
      data: {
        canClose: canClose,
        retake: this.retake,
        response
      }
    });

    dialogRef.afterClosed().subscribe((form: { answers: AnswerRequest[]; pageLocation: Location; userFormUid: string }[]) => {
      this.onClose(!!form?.length);
      dialogRef = null;
      if (form?.length) {
        this.sendFormData(form);
      }
    });
  }

  private checkResponsesAndOpenDialog(responses: DataFormWithAnswers[], currentCount: number, summaryCount: number) {
    if (responses.length && currentCount === summaryCount) {
      this.openDialog(responses);
    }
  }

}
