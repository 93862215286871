import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import packageJson from '../../../../package.json';

@Directive({
  selector: '[ogSvgUnify]'
})
export class SvgUnifyDirective implements OnInit {
  @Input() ogSvgUnify: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    const svgElement = this.elementRef.nativeElement;
    const xlinkHref = this.ogSvgUnify;
    const version = packageJson ? packageJson?.version : '0.0.1'

    if (!xlinkHref) {
      return;
    }

    const svgPath = xlinkHref.split('#')[0];
    const svgName = xlinkHref.split('#')[1];
    const svgUniqRef = svgPath + `?v=${version}#${svgName}`;

    svgElement.setAttributeNS('http://www.w3.org/1999/xlink', 'href', svgUniqRef);
  }
}
