<div class="unsplash-images-container">
  <div class="spinner-container" *ngIf="showSpinner">
    <mat-spinner [diameter]="40">
    </mat-spinner>
  </div>

  <span class="photos-search-label">{{ 'translations.global.search.label.forPhotos' | transloco }}</span><br/>

  <mat-form-field class="photos-search-input-field" appearance="outline">
    <svg matPrefix class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.search' | transloco)">
      <use ogSvgUnify="assets/sprite/sprite.svg#search"></use>
    </svg>
    <mat-label>{{ 'translations.global.ariaLabels.icons.search' | transloco }}</mat-label>
    <input
      tabindex="0"
      matInput
      (keyup)="onSearchInputChange($event)">
  </mat-form-field>
  <ptl-skeleton-view
    [state]="{ loading: isLoading }"
    [countInput]="3"
    [viewType]="'page'">
    <div class="image_search_box_masonry-container" *ngIf="photos && !errorOccurred">
      <div class="image_search_box_content-element"
           *ngFor="let photo of photos.results"
           tabindex="0"
           (click)="onImageClicked(photo)"
           (keydown.enter)="onImageClicked(photo)">
        <img src="{{photo.urls.small}}" alt="{{photo.description}}">
        <div class="images_search_box_caption"><a href="{{photo.user?.links.html}}?utm_source=potentially&utm_medium=referral"
                                                  target="_blank">{{photo.user?.name}}</a></div>
      </div>
    </div>
    <div class="images_search_box_error" *ngIf="photos?.results.length === 0">
      {{'translations.global.input.error.noResults' | transloco}} <span class="search-term">"{{searchString}}"</span>.
      <br/>
      {{'translations.global.input.error.tryAgainDifferentTerm' | transloco}}
    </div>
    <div class="images_search_box_error" *ngIf="errorOccurred">
      {{'translations.errors.unableToLoadImage' | transloco}}
    </div>
    <div class="images_search_box_footer_row">
      <mat-spinner class="image_search_box_spinner" *ngIf="isLoadingMore" [diameter]="20"></mat-spinner>
      <button
        mat-flat-button
        class="see-more-btn"
        *ngIf="photos?.results && pageNumber < photos.total_pages && !errorOccurred"
        (click)="onShowMore()">
        <span class="see-more-btn-title">{{ 'translations.global.button.seeMore' | transloco }}</span>
      </button>
    </div>
  </ptl-skeleton-view>
</div>
