/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { ContentPackageDataService } from './content-package-data.service';
import {
  AddPlaylistFromStoreRequest,
  ContentPackagePageOrPlaylistCreateRequest,
  ContentPackageThumbnailUpdateRequest,
  CreatePublisherPackageRequest,
  CreatePublisherRequest,
  PackageSubscriber,
  PageContentStoreSummary,
  Publisher,
  PublisherPackage, PublisherPackageLicense, StandardTailoredSubjectList,
  SubmitContentStoreRequest,
  SyndicationResponse, TailoredSubjectList, TailoredSubjectRequest, TailoredSubjectSearchResult,
  UpdatePublisherPackageRequest
} from '../../models/content-store/content-store.model';
import { Page as Pagination } from '../../models/page';
import { Playlist, SearchPlaylist } from '../../models';
import { createDummyPlaylist } from '../../../page-modules/mock-utils';
import { Page, SearchPage } from '../../models/page/page.model';
import { ItemWithUploadUrl } from '../../models/ItemWithUploadUrl';

@Injectable()
export class MockContentPackageDataService implements ContentPackageDataService {

  private readonly publisher: Publisher = {
    _id: 'someUid',
    organizationUid: 'someUid',
    image: '',
    name: 'Potential.ly',
    description: [{ uid: '', content: '<p>publisher bio information</p>', type: 'PARAGRAPH' }],
    links: { website: 'https://my.potential.ly', linkedIn: '', twitter: '' },
    createdOn: new Date(),
    updatedOn: new Date(),
    email: 'some@emial.com',
    type: 'ORGANIZATION',
  };

  private readonly playlistsContent: Pagination<Playlist> = {
    totalNumberOfElement: 10,
    pageSize: 2,
    currentPageNumber: 0,
    content: [createDummyPlaylist('1', 'playlist 1'), createDummyPlaylist('2', 'playlist 2')],
  };

  private readonly pagesContent: Pagination<SearchPage | PageContentStoreSummary> = {
    totalNumberOfElement: 0,
    pageSize: 0,
    currentPageNumber: 0,
    content: [],
  };

  private readonly searchPlaylist: Pagination<SearchPlaylist> = {
    totalNumberOfElement: 10,
    pageSize: 2,
    currentPageNumber: 0,
    content: [],
  };

  submitPlaylist(request: SubmitContentStoreRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateContentStoreSettings(request: SubmitContentStoreRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  createPublisher(request: CreatePublisherRequest): ObservableResult<Publisher> {
    return ObservableResult.ofSuccess(this.publisher);
  }

  updatePublisher(publisherUid: string, request: CreatePublisherRequest): ObservableResult<Publisher> {
    return ObservableResult.ofSuccess(this.publisher);
  }

  getCurrentOrganizationPublisher(): ObservableResult<Publisher> {
    return ObservableResult.ofSuccess(this.publisher);
  }

  getPublisherByUid(publisherUid: string): ObservableResult<Publisher> {
    return ObservableResult.ofSuccess(this.publisher);
  }

  getPublisherByUserUid(userUid: string): ObservableResult<Publisher> {
    return ObservableResult.ofSuccess(this.publisher);
  }

  getSubmittedPlaylists(page: number, size: number): ObservableResult<Pagination<Playlist>> {
    return ObservableResult.ofSuccess(this.playlistsContent);
  }

  getSubmittedPages(page: number, size: number): ObservableResult<Pagination<SearchPage>> {
    return ObservableResult.ofSuccess(this.pagesContent as Pagination<SearchPage>);
  }

  getPublisherPages(publisherUid: string, page: number, size: number): ObservableResult<Pagination<PageContentStoreSummary>> {
    return ObservableResult.ofSuccess(this.pagesContent as Pagination<PageContentStoreSummary>);
  }

  getPublisherPlaylists(publisherUid: string, page: number, size: number): ObservableResult<Pagination<Playlist>> {
    return ObservableResult.ofSuccess(this.playlistsContent);
  }

  searchPlaylists(text: string, page?: number, size?: number): ObservableResult<Pagination<SearchPlaylist>> {
    return ObservableResult.ofSuccess(this.searchPlaylist);
  }

  syndicatePlaylist(itemUid: string, request: AddPlaylistFromStoreRequest): ObservableResult<SyndicationResponse> {
    return ObservableResult.ofSuccess();
  }

  syndicatePage(pageUid: string): ObservableResult<Page> {
    return ObservableResult.ofSuccess();
  }

  createPublisherPackage(request: CreatePublisherPackageRequest): ObservableResult<ItemWithUploadUrl<PublisherPackage>> {
    return ObservableResult.ofSuccess();
  }

  deletePublisherPackage(contentPackageUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updatePublisherPackage(contentPackageUid: string, request: UpdatePublisherPackageRequest)
    : ObservableResult<ItemWithUploadUrl<PublisherPackage>> {
    return ObservableResult.ofSuccess();
  }

  updateContentPackageThumbnail(contentPackageUid: string, request: ContentPackageThumbnailUpdateRequest)
    : ObservableResult<ItemWithUploadUrl<PublisherPackage>> {
    return ObservableResult.ofSuccess();
  }

  deleteContentPackageThumbnail(contentPackageUid: string)
    : ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getPublisherPackages(publisherUid: string, page: number, size: number, term?: string)
    : ObservableResult<Pagination<PublisherPackage>> {
    return ObservableResult.ofSuccess();
  }

  addContentPackageItem(contentPackageId: string, request: ContentPackagePageOrPlaylistCreateRequest)
    : ObservableResult<PublisherPackage> {
    return ObservableResult.ofSuccess();
  }

  deleteContentPackageItem(contentPackageId: string)
    : ObservableResult<PublisherPackage> {
    return ObservableResult.ofSuccess();
  }

  createContentPackageLicense(contentPackageId: string, request: PublisherPackageLicense)
    : ObservableResult<PublisherPackage> {
    return ObservableResult.ofSuccess();
  }

  updateContentPackageLicense(contentPackageId: string, request: PublisherPackageLicense)
    : ObservableResult<PublisherPackage> {
    return ObservableResult.ofSuccess();
  }

  getPackageDetailById(contentPackageUid: string): ObservableResult<PublisherPackage> {
    return ObservableResult.ofSuccess();
  }

  getPackageSubscribers(contentPackageUid: string, page: number, size: number, term?: string)
    : ObservableResult<Pagination<PackageSubscriber>> {
    return ObservableResult.ofSuccess();
  }

  unsubscribeFromPackage(contentPackageUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  subscribeToPackage(contentPackageUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  packageAddInstitutionalAdmin(adminUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  packageRemoveInstitutionalAdmin(adminUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  packageAddAnalyticsAccess(adminUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  packageRemoveAnalyticsAccess(adminUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addDataForm(contentPackageUid: string, dataFormUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeDataForm(contentPackageUid: string, dataFormUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  createTailoredSubjectsList(organizationId: string, packageId: string, request: TailoredSubjectRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  createTailoredDepartmentsList(organizationId: string, packageId: string, request: TailoredSubjectRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }


  updateTailoredSubjectsList(listUid: string, packageUid: string, request: TailoredSubjectRequest):
    ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateTailoredDepartmentsList(listUid: string, packageUid: string, request: TailoredSubjectRequest):
    ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }


  searchTailoredDepartments(page: number, size: number, organizationUid: string, packageUid: string, term: string)
    : ObservableResult<Pagination<TailoredSubjectSearchResult>> {
    return ObservableResult.ofSuccess();
  }

  searchTailoredSubjects(page: number, size: number, organizationUid: string, packageUid: string, term: string)
    : ObservableResult<Pagination<TailoredSubjectSearchResult>> {
    return ObservableResult.ofSuccess();
  }

  getJacs3List(): ObservableResult<StandardTailoredSubjectList> {
    return ObservableResult.ofSuccess();
  }

  getOfqualList(): ObservableResult<StandardTailoredSubjectList> {
    return ObservableResult.ofSuccess();
  }

  getTailoredSubjects(organizationId: string, packageId: string): ObservableResult<TailoredSubjectList> {
    return ObservableResult.ofSuccess();
  }

  getTailoredDepartments(organizationId: string, packageId: string): ObservableResult<TailoredSubjectList> {
    return ObservableResult.ofSuccess();
  }
}
