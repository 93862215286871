<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="side-nav-add-button-box"
     *ngIf="isPage && ((isUserAdmin$ | async) || (isUserSuperAdmin$ | async))"
     [ogAppTooltip]="'Add new page'">
  <a class="side-nav-add-button-link" [attr.aria-label]="('translations.global.ariaLabels.addNewPage' | transloco)" [routerLink]="['/pages/create']">
    <i class="material-icons side-nav-add-button-icon" aria-hidden="true">add_circle_outline</i>
    <span>{{ 'translations.pages.button.newPage' | transloco }}</span>
  </a>
</div>


<div class="side-nav-add-button-box"
     *ngIf="isPublisher"
     [ogAppTooltip]="'Add new package'">
  <a class="side-nav-add-button-link" [attr.aria-label]="('translations.global.ariaLabels.addNewPackage' | transloco)" [routerLink]="['/publisher/manage-content/create']">
    <i class="material-icons side-nav-add-button-icon" aria-hidden="true">add_circle_outline</i>
    <span>{{ 'translations.publisher.button.addNewPackage' | transloco }}</span>
  </a>
</div>
