<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="quiz">
  <div class="header">
    <div class="header__left">
    </div>
    <div class="header__right">
    </div>
  </div>

  <div id="quizView" class="question-view" tabindex="0"
       [attr.aria-label]="('translations.global.ariaLabels.youAreOnQuizQuestionsView' | transloco)">

    <div class="question-view-top">
      <div class="question-view-top__back">
        <button mat-stroked-button (click)="back()"
                tabindex="0"
                [attr.aria-label]="'translations.global.button.exit' | transloco">{{ 'translations.global.button.exit' | transloco }}</button>
      </div>

      <div #questionProgress class="question-page"
           [attr.aria-label]="('translations.global.ariaLabels.question' | transloco: {text: currentPage}) + ' ' + ('translations.global.ariaLabels.of' | transloco: {text: questions?.length})">

        <svg width="120" height="120" viewBox="0 0 120 120" class="progress__svg" [attr.aria-label]="('translations.global.ariaLabels.icons.progressCircle' | transloco )">
          <circle [attr.r]="radius - 4"
                  cx="60"
                  cy="60"
                  stroke-width="4"
                  stroke-dasharray="0 6"
                  stroke-linecap="round"
                  class="progress__meter"/>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stop-color="#4DA7D8"/>
              <stop offset="100%" stop-color="#5FD5BB"/>
            </linearGradient>
          </defs>
          <circle [style.strokeDasharray]="circumference"
                  [style.strokeDashoffset]="dashoffset"
                  [attr.r]="radius"
                  cx="60"
                  cy="60"
                  stroke-width="12"
                  [style.stroke]="svgStroke"
                  class="progress__value"/>
        </svg>

        <div class="question-progress-number">
          <span> {{currentPage}} </span> <span>/</span> <span>
            {{ questions?.length }} </span>
        </div>
      </div>
    </div>

    <div class="instructions" *ngIf="currentPage === 0">
      <div [innerHTML]="quiz.instruction.value"></div>
    </div>

    <mat-card class="question-card" *ngIf="questions.length">
      <ng-container class="question-content" *ngFor="let question of questions; index as idx;">
        <ptl-diagnostics-view-choice-question-type
          *ngIf="question.type === 'CHOICE_QUESTION' && currentPage === idx"
          [question]="question"
          [answeredQuestions]="savedOptions[idx]?.selectedOptions ? savedOptions[idx] : {}"
          [disabled]="questionsCompleted"
          [multipleChoice]=true
          [currentLanguage]="currentLanguage"
          [organization]="organization"
          (selectedAnswer)="onSelectedAnswer($event)">
        </ptl-diagnostics-view-choice-question-type>

        <ptl-diagnostics-view-grid-question-type
          *ngIf="question.type === 'GRID_QUESTION' && currentPage === idx"
          [question]="question"
          [answeredQuestions]="savedOptions[idx]?.selectedOptions ? savedOptions[idx]: {}"
          [disabled]="questionsCompleted"
          (selectedAnswer)="onSelectedAnswer($event)"
          [organization]="organization"
          [currentLanguage]="currentLanguage">
        </ptl-diagnostics-view-grid-question-type>

        <ptl-diagnostics-view-drag-drop-question-type
          *ngIf="question.type === 'DRAGDROP_QUESTION' && currentPage === idx"
          [question]="question"
          [answeredQuestions]="savedOptions[idx]?.selectedOptions ? savedOptions[idx]: {}"
          [disabled]="questionsCompleted"
          [currentLanguage]="currentLanguage"
          [organization]="organization"
          (selectedAnswer)="onSelectedAnswer($event)">
        </ptl-diagnostics-view-drag-drop-question-type>
        <!-- We can't add 'SLIDER_QUESTION' question type, but I left this component because there are some cards with this type of question  -->
        <ptl-diagnostics-view-slider-question-type
          *ngIf="question.type === 'SLIDER_QUESTION' && currentPage === idx"
          [question]="question"
          [disabled]="questionsCompleted"
          [currentLanguage]="currentLanguage"
          [answeredQuestions]="savedOptions[idx]?.selectedOptions ? savedOptions[idx] : {}"
          [organization]="organization"
          (sliderChange)="onSliderChange($event, question)">
        </ptl-diagnostics-view-slider-question-type>

      </ng-container>

      <div class="question-view__choose-option-message" *ngIf="showChooseOptionMessage"
           tabindex="0"
           [attr.aria-label]="'translations.chooseOptionMessage' | transloco">
        {{ 'translations.chooseOptionMessage' | transloco }}
      </div>

      <div class="question-view__buttons-block">
        <button
          mat-stroked-button
          class="question-view__pagination-btn"
          color="primary"
          [disabled]="currentPage === 0"
          [attr.aria-label]="('translations.global.ariaLabels.enterToGoToThePreviousQuestion' | transloco)"
          (click)="previousPage()">{{ 'translations.global.button.previous' | transloco }}
        </button>
        <button
          mat-stroked-button
          class="question-view__pagination-btn"
          [attr.aria-label]="('translations.global.ariaLabels.enterToGoToTheNextQuestion' | transloco)"
          [disabled]="questions?.length === currentPage && questionsCompleted"
          (click)="nextPage()">{{ 'translations.global.button.next' | transloco }}
        </button>
      </div>
      <div
        class="question-view__buttons-block"
        *ngIf="questionsCompleted">
        <button
          mat-stroked-button
          class="question-view__report-btn"
          color="primary"
          id="viewReportButton"
          [attr.aria-label]="('translations.global.ariaLabels.enterToOpenYourAssessmentReport' | transloco)"
          [disabled]="!questionsCompleted"
          (click)="toReport()">{{ 'translations.viewReport' | transloco }}
        </button>
      </div>
    </mat-card>
  </div>
</ng-container>
