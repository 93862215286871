import { Injectable, InjectionToken } from '@angular/core';

export const LTI_AUTH_SERVICE = new InjectionToken<LtiAuthService>('[User-Auth] LtiAuthService');

const LTI_REQUEST_ID = 'lti_request_id';

@Injectable()
export class LtiAuthService {

  saveLtiRequestId(requestId: string): void {
    localStorage.setItem(LTI_REQUEST_ID, requestId);
  }

  getLtiRequestId(): string {
    return localStorage.getItem(LTI_REQUEST_ID);
  }

  removeLtiRequestId(): void {
    localStorage.removeItem(LTI_REQUEST_ID);
  }
}
