/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/** Defines the request sent in order to sign up. */
export interface UserAccountCreationRequest {
  /** The email to sign the user up with. */
  email: string;
  /** The password to use for user signup. */
  password: string;
  /** The user first name. */
  firstName: string;
  /** The user last name. */
  lastName: string;
  captchaResponseV2?: string;
  captchaResponseV3?: string;
}

export interface AccountInvitationJoinRequest {
  email: string;
  organizationDomain: string;
  captchaResponseV3?: string;
  captchaResponseV2?: string;
}

export enum TokenType {
  FOLIO_ACCOUNT_VERIFICATION = 'FOLIO_ACCOUNT_VERIFICATION',
  FOLIO_EMAIL_VERIFICATION = 'FOLIO_EMAIL_VERIFICATION',
  ALUMNI_ACCOUNT_VERIFICATION = 'ALUMNI_ACCOUNT_VERIFICATION',
  ALUMNI_EMAIL_VERIFICATION = 'ALUMNI_EMAIL_VERIFICATION',
  PRE_ARRIVAL_ACCOUNT_INVITATION = 'PRE_ARRIVAL_ACCOUNT_INVITATION',
  PRE_ARRIVAL_ACCOUNT_INVITATION_WITH_FOLIO = 'PRE_ARRIVAL_ACCOUNT_INVITATION_WITH_FOLIO'
}
