/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LEARNER_VIEW_DATA_SERVICE, LearnerViewDataService } from 'src/app/page-modules/resource/services/data.service';
import { LearnerFormAnswer } from '@app/app/page-modules/resource/models';
import {
  SaveFormAnswer
} from '@app/app/page-modules/resource/store/learner-view.actions';
import {
  FormSavedEvent
} from '@app/app/shared/components/resource-preview/form-preview/form-preview-event.model';
import { ContentHelper } from '@app/app/shared/helpers/content-helper';
import { DialogService } from '@app/app/shared/helpers/dialog/dialog.service';
import { Form, TextBoxFormContent, UserPlaylistSubmissionSummary } from '@app/app/shared/models';
import { TranslationService } from '@app/app/shared/services/translation/translation.service';


@Component({
  selector: 'ptl-form-preview-private-note',
  templateUrl: './form-private-note.component.html',
  styleUrls: [ './form-private-note.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPreviewPrivateNoteComponent implements OnInit, OnChanges {

  /** Receives the textbox type object */
  @Input() formData: Form<TextBoxFormContent>;
  @Input() learnerFormAnswer: LearnerFormAnswer;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() playlistUri: string;
  @Input() playlistUid: string;
  @Input() resourceUri: string;
  @Input() resourceUid: string;
  @Input() groupUid: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  existingUserAnswer: string;
  submitButtonEnabled: boolean;
  submitted = false;
  canEdit = true;
  canNotEditReason: string;

  inputFocused: boolean;
  inputTextWords = 0;

  autoSaveInProgress = false;
  autoSaved = false;
  editClicked = false;
  fullscreenEnabled = false;

  validationInProgress = false;
  validationProcessed = false;

  private userInput: string;
  private autoSaveSubscription: Subscription;
  private autoSaveSubject = new Subject<void>();

  constructor(
    private store: Store,
    private dialogService: DialogService,
    private translationService: TranslationService,
    private cd: ChangeDetectorRef,
    @Inject(LEARNER_VIEW_DATA_SERVICE) private dataService: LearnerViewDataService,
  ) {
    this.autoSaveSubscription = this.autoSaveSubject
      .pipe(debounceTime(500))
      .subscribe(() => this.saveUserInput());
  }

  ngOnInit() {
    if ( this.formData && (this.formData.content as TextBoxFormContent).userAnswer ) {
      const content = this.formData.content as TextBoxFormContent;
      if ( content.formIsActive ) {
        this.inputFocused = true;
      }
      if ( content.userAnswer.length > 0 ) {
        this.existingUserAnswer = JSON.parse(JSON.stringify(content.userAnswer));
        this.submitButtonEnabled = true;
        this.inputTextWords = this.countWords();
        this.submitted = this.userPlaylistSubmissionSummary?.submittedOn ? true : content.submitted;
      }
      this.checkSubmissionMode();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.playlistUid && this.resourceUid && this.formData?.uid) {
      this.checkSubmissionMode();
    } else {
      if (changes.learnerFormAnswer || changes.userPlaylistSubmissionSummary) {
        this.checkSubmissionMode();
      }
    }
  }

  onInputFocus() {
    this.inputFocused = true;
    this.editClicked = true;
  }

  deactivateForm(input: string) {
    this.userInput = input;
    this.existingUserAnswer = input;
    this.triggerDeactivation();
  }

  saveUserInput() {
    if ( this.userInput && !!this.userInput.length ) {
      const event: FormSavedEvent = {
        formUid: this.formData.uid,
        answer: this.userInput,
      };
      this.submitButtonEnabled = !!this.userInput;
      this.store.dispatch(new SaveFormAnswer(this.playlistUid, event, this.formData.uid)).toPromise().then(() => {
        this.autoSaveInProgress = false;
        this.autoSaved = true;
        this.cd.detectChanges();
      });
    }
  }

  onTextLengthUpdate(wordsNumber: number) {
    this.inputTextWords = wordsNumber;
  }

  onMediumEditorUpdate(userInput: string) {
    this.userInput = userInput;
    if ( this.inputFocused ) {
      if ( !this.canEdit ) {
        this.formData.content.userAnswer = userInput;
      } else {
        this.autoSaveInProgress = true;
        this.autoSaved = false;
        this.autoSaveSubject.next();
      }
    }
  }

  triggerEdit() {
    if ( this.editClicked ) {
      this.triggerDeactivation();
    } else if ( this.canEdit ) {
      this.onInputFocus();
    }
  }

  changeFullscreenMode(): void {
    this.fullscreenEnabled = !this.fullscreenEnabled;
    if ( this.fullscreenEnabled ) {
      document.body.classList.add('full-screen-enabled');
    } else {
      document.body.classList.remove('full-screen-enabled');
    }
  }

  closeFullscreenMode(): void {
    this.fullscreenEnabled = false;
    document.body.classList.remove('full-screen-enabled');
  }

  get isSubmissionDisabled(): boolean {
    return !this.submitButtonEnabled;
  }

  get submittedAnswerContent() {
    return { content: this.existingUserAnswer, type: 'PARAGRAPH' };
  }

  private checkSubmissionMode(): void {
    if (!this.learnerFormAnswer) {
      if (!this.playlistUid || !this.resourceUid || !this.formData?.uid) {
        return;
      }
      if (!this.validationInProgress && !this.validationProcessed) {
        this.validationInProgress = true;
        this.dataService.validateFormUpdate(this.playlistUid, this.resourceUid, this.formData?.uid)
          .subscribe(({ isSuccess, value }) => {
            if (isSuccess) {
              this.canEdit = value.canBeUpdated;
              this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translationService, value);
              this.validationProcessed = true;
            }
            this.checkSubmissionLocked();
            this.cd.detectChanges();
            this.validationInProgress = false;
          });
      }
    } else {
      this.canEdit = this.learnerFormAnswer?.updatePermission?.canBeUpdated;
      this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translationService, this.learnerFormAnswer?.updatePermission);
      this.checkSubmissionLocked();
      this.cd.detectChanges();
    }
  }

  private countWords(): number {
    if ( this.existingUserAnswer.trim() === '<p><br></p>' ) {
      return 0
    }
    return this.existingUserAnswer.trim().split(/\S+/g).length;
  }

  private triggerDeactivation() {
    this.inputFocused = false;
    this.editClicked = false;
  }

  private checkSubmissionLocked() {
    if (this.userPlaylistSubmissionSummary?.submissionsLocked) {
      this.canEdit = false;
      this.canNotEditReason = this.translationService.getTranslation('formUpdateDisabledReasons.submissionClosed')
    }
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    if (!this.fullscreenEnabled) {
      return;
    }

    this.closeFullscreenMode();
  }
}
