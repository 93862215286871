/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PassCriteriaPopupComponent } from './pass-criteria-popup/pass-criteria-popup.component';
import { QuizSettings } from '../../../../../shared/models/editor/quiz-content.model';
import { Subscription } from 'rxjs';
import { TranslationService } from '../../../../../shared/services/translation/translation.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'ptl-quiz-settings',
  templateUrl: './quiz-settings.component.html',
  styleUrls: ['./quiz-settings.component.scss', './quiz-settings-new.component.scss'],
})
export class QuizSettingsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() settings: QuizSettings;

  @Input() questionsCount: number;

  @Input() newVersionEnabled: boolean;

  @Output() settingsChanged = new EventEmitter<QuizSettings>();

  @ViewChild(PassCriteriaPopupComponent, { read: ElementRef }) private passCriteriaPopupElement: ElementRef;

  expanded = false;
  passCriteriaPopupOpen = false;
  showPassCriteriaOption = false;
  passCriteriaOption: 'ALL_CORRECT' | 'PARTOF_CORRECT';
  settingsForm: FormGroup;

  private settingsFormSubscription: Subscription;

  constructor(private formBuilder: FormBuilder, private translationService: TranslationService) {
  }

  ngOnInit() {
    this.createSubscription();
    if (this.newVersionEnabled) {
      this.checkPassCriteriaOption();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.settings && changes.settings.currentValue) {
      this.settingsForm = this.formBuilder.group({
        passCriteria: {
          criteria: this.settings.passCriteria.criteria,
          partOf: this.settings.passCriteria.partOf,
        },
        submissionCriteria: {
          mustPass: this.settings.submissionCriteria.mustPass,
        },
        retakeCriteria: this.settings.retakeCriteria,
        resultDisplayPolicy: this.settings.resultDisplayPolicy,
      });
      // destroy the previous created subscription and add a new one
      this.destroySubscription();
      this.createSubscription();
    }
  }

  ngOnDestroy() {
    this.destroySubscription();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (
      this.passCriteriaPopupOpen &&
      this.passCriteriaPopupElement &&
      !this.passCriteriaPopupElement.nativeElement.contains(event.target)
    ) {
      this.passCriteriaPopupOpen = false;
    }
  }

  onCriteriaSelected(criteria) {
    this.settingsForm.patchValue({
      passCriteria: criteria,
    });
  }

  get passCriteria() {
    return this.settingsForm.get('passCriteria');
  }

  get passCriteriaString() {
    const value = this.passCriteria.value;
    if (value.criteria === 'ALL_CORRECT') {
      return this.translationService.getTranslation('quiz.settings.passCriteria.options.allCorrect');
    } else {
      return `${this.translationService.getTranslation('quiz.settings.passCriteria.options.partOfCorrect')} ${value.partOf}`;
    }
  }

  createSubscription() {
    this.settingsFormSubscription = this.settingsForm.valueChanges.subscribe(
      changes => {
        this.settingsChanged.emit(changes);
      }
    );
  }

  destroySubscription() {
    this.settingsFormSubscription?.unsubscribe();
  }

  onTowardsChange(event: MatSlideToggleChange) {
    const value = event.checked;
    const submissionCriteria = {
      mustPass: value,
    };
    this.settingsForm.patchValue({
      submissionCriteria: submissionCriteria,
    });
  }

  onChangeCompletionMustPass(mustPass: boolean): void {
    this.settingsForm.patchValue({
      submissionCriteria: {
        mustPass: mustPass
      },
    });
  }

  onPassCountChange(type: 'ALL_CORRECT' | 'PARTOF_CORRECT'): void {
    this.passCriteriaOption = type
    if (type === 'PARTOF_CORRECT') {
      this.showPassCriteriaOption = true;
    } else {
      this.showPassCriteriaOption = false;
    }
    this.passCriteria.value.criteria = type
    this.onCriteriaSelected(this.passCriteria.value);
  }

  private checkPassCriteriaOption() {
    this.passCriteriaOption = this.settings?.passCriteria?.criteria;
    if (this.passCriteriaOption === 'PARTOF_CORRECT') {
      this.showPassCriteriaOption = true;
    } else {
      this.showPassCriteriaOption = false;
    }
  }

}
