<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="back-container" *ngIf="!invitationId">
  <i
    tabindex="0"
    class="material-icons back-container__icon"
    [attr.aria-label]="('translations.global.ariaLabels.backToSignIn' | transloco)"
    (click)="onBackClicked()"
    (keydown.enter)="onBackClicked()">
    keyboard_backspace
  </i>

  <h2 class="back-container__label"
      tabindex="0"
      [attr.aria-label]="'translations.public.title.registration' | transloco"
  >{{ 'translations.public.title.registration' | transloco }}</h2>
</div>

<form
  class="form"
  [formGroup]="signupDetailsFormGroup"
  (ngSubmit)="onSignUpClick()"
  [attr.aria-label]="('translations.global.ariaLabels.signUpForm' | transloco)"
  novalidate
>

  <div class="whitelist-domains-info"
       *ngIf="!invitationId && organizationData?.whitelist.length"
       tabindex="0"
       [attr.aria-label]="'translations.whitelist.description.registerPermittedEmailsTitle' | transloco"
       (click)="openWhitelistDomains($event)"
       (keydown.enter)="openWhitelistDomains($event)"
       [innerHTML]="'translations.whitelist.description.registerPermittedEmailsTitle' | transloco | safeHtml"></div>

  <mat-form-field class="form__field" appearance="outline">
    <mat-label [attr.aria-label]="('translations.global.ariaLabels.firstNameField' | transloco)">{{ 'translations.global.input.label.firstName' | transloco }}</mat-label>
    <input
      matInput
      required
      type="text"
      name="firstName"
      formControlName="firstName"/>
    <mat-error *ngIf="signupDetailsFormGroup.controls.firstName.hasError('required')">
      {{ 'translations.global.input.error.enterValidFirstName' | transloco }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="form__field" appearance="outline">
    <mat-label [attr.aria-label]="('translations.global.ariaLabels.lastNameField' | transloco)">{{ 'translations.global.input.label.lastName' | transloco }}</mat-label>
    <input
      matInput
      required
      type="text"
      name="lastName"
      formControlName="lastName"/>
    <mat-error *ngIf="signupDetailsFormGroup.controls.lastName.hasError('required')">
      {{ 'translations.global.input.error.enterValidLastName' | transloco }}
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="hasAffiliation" class="form__field" appearance="outline">
    <mat-label [attr.aria-label]="('translations.global.ariaLabels.affiliationField' | transloco)">{{ 'translations.global.input.label.affiliation' | transloco }}</mat-label>
    <mat-select formControlName="affiliation">
      <mat-option *ngFor="let option of affiliations" [value]="option.key">{{ option.title }}</mat-option>
    </mat-select>
    <mat-error *ngIf="signupDetailsFormGroup.controls.affiliation.hasError('required')">
      {{ 'translations.global.input.error.selectAffiliation' | transloco }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="form__field" appearance="outline">
    <mat-label [attr.aria-label]="('translations.global.ariaLabels.emailField' | transloco)">{{ 'translations.global.input.label.email' | transloco }}</mat-label>
    <input
      matInput
      required
      type="email"
      name="email"
      formControlName="email"
      [readonly]="invitationId">
    <mat-error *ngIf="signupDetailsFormGroup.controls.email.hasError('required')">
      {{ 'translations.global.input.error.enterEmail' | transloco }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="form__field password-field" appearance="outline">
    <mat-label [attr.aria-label]="('translations.global.ariaLabels.passwordField' | transloco)">{{ 'translations.global.input.label.password' | transloco }}</mat-label>
    <input
      matInput
      required
      name="password"
      formControlName="password"
      (focus)="passwordFocusState = true" (focusout)="passwordFocusState = false"
      [type]="showPasswordSelected ? 'text' : 'password'"
      autocomplete="off"/>
    <div class="password__visibility">
      <i
        tabindex="0"
        class="material-icons password__show-icon"
        [attr.aria-label]="('translations.global.ariaLabels.showOrHidePassword' | transloco)"
        (click)="showPasswordSelected = !showPasswordSelected"
        (keydown.enter)="showPasswordSelected = !showPasswordSelected">
        visibility
      </i>
    </div>
    <mat-error *ngIf="signupDetailsFormGroup.controls.password.hasError('required')">
      {{ 'translations.global.input.error.enterPassword' | transloco }}
    </mat-error>
    <mat-hint *ngIf="passwordFocusState">{{ 'translations.global.input.error.enterValidPassword' | transloco }}</mat-hint>
    <mat-error *ngIf="signupDetailsFormGroup.controls.password.hasError('pattern')">
      {{ 'translations.global.input.error.enterValidPassword' | transloco }}
    </mat-error>
  </mat-form-field>

  <mat-checkbox
    required="true"
    class="form__terms-and-conditions-checkbox"
    name="termsOfService"
    formControlName="termsOfService"
    [aria-label]="('translations.global.ariaLabels.termsOfServiceCheckbox' | transloco)"
    color="primary">
    <span class="inner-text" [innerHTML]="'translations.public.description.termsOfService' | transloco"></span>
  </mat-checkbox>
  <ngx-invisible-recaptcha #captchaElem
                           [siteKey]="reCaptchaSiteKeyV2"
                           (success)="proceedIfValid($event)"
                           (reset)="resetCaptcha()"
                           [ngModel]="reCaptchaElement"
                           [ngModelOptions]="{ standalone: true }"
                           [useGlobalDomain]="false">
  </ngx-invisible-recaptcha>
  <div class="form__error" *ngIf="accountCreationError$ | async">
    <span>{{ accountCreationError$ | async }}</span>
  </div>

  <button
    mat-flat-button
    [class.old-version]="!newVersionEnabled"
    type="submit"
    class="form__submit"
    color="primary"
    *ngIf="invitationId || organizationData?.allowRegistrations"
    [disabled]="accountCreationInProgress$ | async">
    <span class="uppercase"
          *ngIf="(accountCreationInProgress$ | async) !== true">{{ 'translations.public.button.signUp' | transloco }}</span>
    <span class="submit__loading" *ngIf="accountCreationInProgress$ | async">
      <span class="submit__logging-in">{{ 'translations.public.button.creatingAccount' | transloco }}</span>
      <mat-spinner [diameter]="20"></mat-spinner>
    </span>
  </button>

</form>
<ng-container *ngIf="organizationData?.authType === 'SOCIAL' && !invitationId">
  <span class="social-buttons-headline"
        [attr.aria-label]="('translations.global.ariaLabels.orSignUpWith' | transloco)">{{ 'translations.public.label.signUpWith' | transloco }}</span>
  <div class="social-buttons">
    <button class="linkedin-button" title="Sign up with LinkedIn" mat-raised-button (click)="signInUsingLinkedIn()">
      <img alt="" src="assets/social/linkedin-white.svg" width="291" height="79">
    </button>
    <button class="google-button" title="Sign up with Google" mat-raised-button (click)="signInUsingGoogle()">
      <img alt="" src="assets/social/google-colored.svg" width="265" height="87">
    </button>
  </div>
</ng-container>
