<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="header">
  <div class="header__top">
    <img src="{{(organizationData$| async)?.logo}}" class="header__logo" alt="" width="88" height="67"/>
    <h1 class="title">{{ 'translations.contentStore.title.addToContentStore' | transloco }}</h1>
    <i class="material-icons header__close-icon"
       [attr.aria-label]="('translations.global.ariaLabels.close' | transloco)"
       (click)="onCloseDialog()"
       (keydown.enter)="onCloseDialog()"
       tabindex="0">close</i>
  </div>

  <div class="header-active-tabs">
    <div class="tab-item"
         [class.is_active]="activePage === 0"
         tabindex="0"
         (click)="activePage > 0 ? setPage(0) : null"
         (keydown.enter)="activePage > 0 ? setPage(0) : null">
      {{ 'translations.contentStore.tabs.selectContent' | transloco }}
    </div>
    <div class="tab-item">
      <mat-icon aria-hidden="true">east</mat-icon>
    </div>
    <div class="tab-item" [class.is_active]="activePage === 1"
         tabindex="0"
         (click)="activePage > 1 ? setPage(0) : null"
         (keydown.enter)="activePage > 1 ? setPage(0) : null">
      {{ 'translations.contentStore.tabs.prepareSubmit' | transloco }}
    </div>
    <!-- <div class="tab-item">
      <mat-icon aria-hidden="true">east</mat-icon>
    </div>
    <div class="tab-item" [class.is_active]="activePage === 2">
      {{ 'translations.contentStore.tabs.review' | transloco }}
    </div> -->
  </div>

</div>
<div class="content">

  <ptl-content-store-add
    *ngIf="activePage === 0"
    (contentSelected)="onContentSelected($event)">
  </ptl-content-store-add>

  <ptl-content-store-prepare-submit
    *ngIf="activePage === 1"
    [playlist]="selectedPlaylist"
    [page]="selectedPage"
    [contentType]="contentType"
    [organization]="organization"
    (canceledSharePlaylist)="onCloseDialog()"
    (reviewContent)="requestReviewContent($event)">
  </ptl-content-store-prepare-submit>

  <ptl-content-store-finish *ngIf="activePage === 2" (shareFinished)="onCloseDialog(true)"></ptl-content-store-finish>
</div>
