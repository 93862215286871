/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'ptl-side-nav-btn',
  templateUrl: './side-nav-btn.component.html',
  styleUrls: ['./side-nav-btn.component.scss'],
})
export class SideNavBtnComponent {

  @Input() isPage: boolean;
  @Input() isPublisher: boolean;

  @Select(UserAuthState.userHasSuperAdminRole)
  isUserSuperAdmin$: Observable<boolean>;

  @Select(UserAuthState.userHasAdminRole)
  isUserAdmin$: Observable<boolean>;
}
