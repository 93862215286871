/*
 * Copyright (C) 2022 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { ObservableResult } from '../../store';
import { catchError, switchMap } from 'rxjs/operators';
import { RestClientService } from '../rest-client.service';
import { DataFormDataService } from './data-form-data.service';
import { TranslationService } from '../translation/translation.service';
import {
  AnswerRequest,
  CreateDataFormRequest,
  DataForm, DataFormSummary,
  FormElementRequest,
  UserDataForm
} from '../../models/data-form/data-form.model';
import { ItemWithUploadUrls } from '../../models/ItemWithUploadUrl';
import { Page } from '../../models/page';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';

@Injectable()
export class ApiDataFormDataService implements DataFormDataService {

  private dataFormsUrl = Location.joinWithSlash(environment.apiRootUrl || '', 'data-forms');

  private userFormsUrl = Location.joinWithSlash(environment.apiRootUrl || '', 'user-forms');

  constructor(private client: RestClientService, private translationService: TranslationService) {
  }

  getDataForm(dataFormUid: string, languageCode?: string): ObservableResult<DataForm> {
    return this.client.get<DataForm>(
      `${this.dataFormsUrl}/${dataFormUid}`,
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  getDataForms(page: number): ObservableResult<Page<DataForm>> {
    return this.client.get<Page<DataForm>>(`${this.dataFormsUrl}/user?page=${page}`)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  createDataForm(request: CreateDataFormRequest, languageCode?: string): ObservableResult<ItemWithUploadUrls<DataForm>> {
    return this.client.post<ItemWithUploadUrls<DataForm>>(
      this.dataFormsUrl,
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  updateDataForm(
    dataFormUid: string,
    request: CreateDataFormRequest,
    languageCode?: string
  ): ObservableResult<ItemWithUploadUrls<DataForm>> {
    return this.client.patch<ItemWithUploadUrls<DataForm>>(`${this.dataFormsUrl}/${dataFormUid}`, request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
      )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  deleteDataForm(dataFormUid: string): ObservableResult<void> {
    return this.client.delete<void>( `${this.dataFormsUrl}/${dataFormUid}`)
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  addFormElement(
    dataFormUid: string,
    request: FormElementRequest,
    languageCode?: string
  ): ObservableResult<DataForm> {
    return this.client.post<DataForm>(
      `${this.dataFormsUrl}/${dataFormUid}/elements`,
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
  )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  updateFormElement(
    dataFormUid: string,
    formElementUid: string,
    request: FormElementRequest,
    languageCode?: string
  ): ObservableResult<DataForm> {
    return this.client.patch<DataForm>(
      `${this.dataFormsUrl}/${dataFormUid}/elements/${formElementUid}`,
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  deleteFormElement(
    dataFormUid: string,
    formElementUid: string,
  ): ObservableResult<void> {
    return this.client.delete<void>(`${this.dataFormsUrl}/${dataFormUid}/elements/${formElementUid}`)
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  reorderFormElements(
    dataFormUid: string,
    request: { elements: string[] },
    languageCode?: string
  ): ObservableResult<DataForm> {
    return this.client.patch<DataForm>(`${this.dataFormsUrl}/${dataFormUid}/elements/reorder`, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  getUserForm(
    dataFormUid: string,
  ): ObservableResult<UserDataForm> {
    return this.client.get<UserDataForm>(`${this.userFormsUrl}/${dataFormUid}`)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  createUserForm(
    dataFormUid: string,
  ): ObservableResult<UserDataForm> {
    return this.client.post<UserDataForm>(`${this.userFormsUrl}/${dataFormUid}`)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  getUserForms(
    page: number,
    size: number,
    status?: string
  ): ObservableResult<Page<UserDataForm>> {
    const params = {
      page: page.toString(),
      size: size.toString()
    };
    if (status) {
      params['status'] = status;
    }
    return this.client.get<Page<UserDataForm>>(
      `${this.userFormsUrl}/user`,
      params
    ).pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  updateUserForm(
    userFormUid: string,
    request: { answers: AnswerRequest[] }
  ): ObservableResult<UserDataForm> {
    return this.client.patch<UserDataForm>(`${this.userFormsUrl}/${userFormUid}`, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  getSummaries(dataFormUids: string[]): ObservableResult<DataFormSummary[]> {
    return this.client.post<DataFormSummary[]>(`${this.dataFormsUrl}/summaries`, dataFormUids)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }

  getDataFormsDetail(dataFormUids: string[]): ObservableResult<DataForm[]> {
    return this.client.post<DataForm[]>(`${this.dataFormsUrl}/details`, dataFormUids)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(JSON.stringify(err)))
      );
  }
}
