/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs';
import { LanguageCodeHelper } from 'src/app/shared/helpers/language-code-helper';
import { Playlist, Resource } from 'src/app/shared/models';
import { RestClientService } from 'src/app/shared/services/rest-client.service';
import { DEFAULT_LANGUAGE_CODE, TranslationService } from 'src/app/shared/services/translation/translation.service';
import { ObservableResult } from 'src/app/shared/store';
import { environment } from 'src/environments/environment';
import { PlaylistWebLinkCreationRequest } from '../../playlist/models';
import { ProjectCreationRequest } from '../models/project.model';
import { ProjectDataService } from './data.service';
import { CreatePlaylistResponse } from '../../playlist/services/create/core/models/create-playlist-response';
import { ProjectHelper } from '../helpers/project.helper';

export class ApiProjectDataService implements ProjectDataService {

  constructor(
    private client: RestClientService,
    private translationService: TranslationService,
  ) {}

  createProject(request: ProjectCreationRequest): ObservableResult<CreatePlaylistResponse> {
    return this.client.post<CreatePlaylistResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'projects/create'),
      request,
      undefined,
      request.languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(request.languageCode) : null,
    ).pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreatingProject')))
    );
  }

  getProjectDetails(playlistUri: string, languageCode?: string): ObservableResult<Playlist> {
    const url = ProjectHelper.isProjectPublicView() ? `projects/public/${playlistUri}` : `projects/${playlistUri}`;
    return this.client.get<Playlist>(
      Location.joinWithSlash(environment.apiRootUrl || '', url),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) =>
        this.handleErrorResponseGetProject(err, this.translationService.getTranslation('errors.errorGetPlaylist'))
      ));
  }

  createEmptyCard(playlistUid: string, sectionUid: string): ObservableResult<Resource> {
    return this.client.post<Resource>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `projects/${playlistUid}/sections/${sectionUid}?cardType=RESOURCE`
      ),
      null,
      {},
      { 'Content-Language': DEFAULT_LANGUAGE_CODE })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateResource')))
      );
  }

  addWebLinkToPlaylistSection(
    playlistUid: string,
    sectionUid: string,
    requestBody: PlaylistWebLinkCreationRequest
  ): ObservableResult<Resource> {
    return this.client.put<Resource>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `projects/${playlistUid}/sections/${sectionUid}/weblinks`
      ),
      requestBody
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateWeblink')))
    );
  }

  publishAndShowProjectOnFolio(projectUid: string): ObservableResult<void> {
    return this.client.patch<void>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `projects/${projectUid}/folio/visibility`
      )).pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorMakingProjectVisible')))
      );
  }

  unPublishAndHideProjectVisibleOnFolio(projectUid: string): ObservableResult<void> {
    return this.client.delete<void>(
      Location.joinWithSlash(
        environment.apiRootUrl || '',
        `projects/${projectUid}/folio/visibility`
      )).pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorHidingProject')))
      );
  }

  private handleErrorResponseGetProject(err: HttpErrorResponse, message: string) {
    const errorMessage = err.status === 404 ? this.translationService.getTranslation('errors.errorProjectNotFound') :
      err.status === 403 ? this.translationService.getTranslation('errors.errorProjectNotAvailable') : message;
    return ObservableResult.ofError(errorMessage, err.error?.dataForms);
  }

}
