<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<div class="submission-date-filter-container">
  <mat-card *ngIf="expanded" class="submission-date-filter-settings">
    <div class="submission-date-filter-settings__header">
      <span class="small">{{ 'translations.reviews.filter.submission' | transloco }}</span>
      <button mat-icon-button [matMenuTriggerFor]="contextMenu" class="isBtn">
        <span *ngIf="submissionIs === 'BETWEEN'">{{ 'translations.reviews.filter.isBetween' | transloco}}</span>
        <span *ngIf="submissionIs === 'BEFORE'">{{ 'translations.reviews.filter.isBefore' | transloco}}</span>
        <span *ngIf="submissionIs === 'AFTER'">{{ 'translations.reviews.filter.isAfter' | transloco}}</span>
        <mat-icon aria-hidden="true" class="icon">expand_more</mat-icon>
      </button>
      <span class="submission-date-filter-delete" (click)="deleteFilter()" (keydown.enter)="deleteFilter()">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
        </svg>
      </span>
    </div>
    <div class="submission-date-filter-settings__content">
      <mat-calendar
        *ngIf="submissionIs !== 'BETWEEN'"
        [startAt]="date"
        [selected]="date"
        (selectedChange)="dateChanged($event)"
      ></mat-calendar>
      <mat-calendar
        *ngIf="submissionIs === 'BETWEEN'"
        [startAt]="betweenStart"
        [selected]="betweenRange"
        (selectedChange)="dateRangeChanged($event)">
      </mat-calendar>
      <div class="submission-date-filter-settings__footer">
        <button
          mat-stroked-button
          class="submission-date-filter-settings__btn"
          (click)="applyFilter()">
          {{'translations.global.button.apply' | transloco}}
        </button>
      </div>
    </div>
  </mat-card>
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="setSubmissionDate('BETWEEN')"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="'translations.global.ariaLabels.dateIsBetween' | transloco">
      <span>
        {{'translations.reviews.filter.isBetween' | transloco }}
      </span>
    </button>
    <button mat-menu-item (click)="setSubmissionDate('BEFORE')"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="'translations.global.ariaLabels.dateIsBefore' | transloco">
      <span>
        {{'translations.reviews.filter.isBefore' | transloco }}
      </span>
    </button>
    <button mat-menu-item (click)="setSubmissionDate('AFTER')"
            tabindex="0"
            class="is-item"
            [attr.aria-label]="'translations.global.ariaLabels.dateIsAfter' | transloco">
      <span>
        {{'translations.reviews.filter.isAfter' | transloco }}
      </span>
    </button>
  </ng-template>
</mat-menu>

