/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FolioShareSocialItem } from '../../../page-modules/folio/store/folio.state.model';
import { ShareService } from '@app/app/shared/services/share/share.service';
import { SocialShareType } from '@app/app/shared/models/public-share/public-share.model';


@Component({
  selector: 'ptl-social-share-url',
  templateUrl: './social-share-url.component.html',
  styleUrls: [ './social-share-url.component.scss' ],
})
export class SocialShareUrlComponent {
  shareUrl:string;
  socialShareType = SocialShareType;
  socialShareItems: FolioShareSocialItem[] = [
    {
      name: SocialShareType.FACEBOOK,
      title: 'Facebook',
    },
    {
      name: SocialShareType.LINKEDIN,
      title: 'LinkedIn',
    },
    {
      name: SocialShareType.TWITTER,
      title: 'Twitter',
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { shareUrl: string },
    private dialogRef: MatDialogRef<SocialShareUrlComponent>,
    private shareService: ShareService)
  {
      this.shareUrl = data.shareUrl;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  shareAction(name: SocialShareType): void {
    this.shareService.shareAction(name, this.shareUrl)
  }
}
