<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="member-filter__content">
  <div class="member-filter__header">
    <span class="member-filter__label">{{'translations.members.filter.button.set' | transloco}}</span>
  </div>
  <div [ngSwitch]="type" class="member-filter__selections">
    <ptl-role-member-filter
      *ngSwitchCase="'ROLE'"
      (setRoleFilter)="onSave($event)"
      (cancelRoleFilter)="onCancel()"
      [excludedRoles]="excludedRoles"
      [excludedRoleStatuses]="excludedRoleStatuses"
      class="member-filter__select">
    </ptl-role-member-filter>
    <ptl-group-member-filter
      *ngSwitchCase="'GROUP'"
      (setGroupFilter)="onSave($event)"
      (cancelGroupFilter)="onCancel()"
      [excludedGroups]="excludedGroups"
      [excludedGroupStatuses]="excludedGroupStatuses"
      class="member-filter__select">
    </ptl-group-member-filter>
    <ptl-activity-member-filter
      *ngSwitchCase="'ACTIVITY'"
      (setActivityFilter)="onSave($event)"
      (cancelActivityFilter)="onCancel()"
      [excludedActivityStatuses]="excludedActivityStatuses"
      class="member-filter__select">
    </ptl-activity-member-filter>
    <ptl-goal-member-filter
      *ngSwitchCase="'GOAL'"
      [isCardPage]="isCardPage"
      [isPlaylistPage]="isPlaylistPage"
      [cardUid]="cardUid"
      [playlistUid]="playlistUid"
      [excludedResourceStatuses]="excludedResourceStatuses"
      (setGoalFilter)="onSave($event)"
      (cancelGoalFilter)="onCancel()"
      class="member-filter__select">
    </ptl-goal-member-filter>
    <ptl-resources-member-filter
      *ngSwitchCase="'RESOURCES'"
      (setResourcesFilter)="onSave($event)"
      (cancelResourcesFilter)="onCancel()"
      class="member-filter__select">
    </ptl-resources-member-filter>
    <ptl-playlist-member-filter
      *ngSwitchCase="'PLAYLIST_STATUS'"
      [isPlaylistPage]="isPlaylistPage"
      [playlistUid]="playlistUid"
      [excludedPlaylists]="excludedPlaylists"
      [excludedPlaylistStatuses]="excludedPlaylistStatuses"
      (setPlaylistFilter)="onSave($event)"
      (cancelPlaylistFilter)="onCancel()"
      class="member-filter__select">
    </ptl-playlist-member-filter>
    <ptl-card-member-filter
      *ngSwitchCase="'CARD_STATUS'"
      [isCardPage]="isCardPage"
      [isPlaylistPage]="isPlaylistPage"
      [cardUid]="cardUid"
      [cardHeaders]="cardHeaders"
      [excludedCards]="excludedCards"
      [excludedCardStatuses]="excludedCardStatuses"
      (setCardFilter)="onSave($event)"
      (cancelCardFilter)="onCancel()"
      class="member-filter__select">
    </ptl-card-member-filter>
    <ptl-form-member-filter
      *ngSwitchCase="'FORM_STATUS'"
      [formHeaders]="formHeaders"
      [excludedFormsTitle]="excludedFormsTitle"
      [excludedFormTitleStatuses]="excludedFormTitleStatuses"
      (setFormFilter)="onSave($event)"
      (cancelFormFilter)="onCancel()"
      class="member-filter__select">
    </ptl-form-member-filter>
    <ptl-form-statuses-member-filter
      *ngSwitchCase="'FORMS'"
      [formHeaders]="formHeaders"
      [excludedFormStatuses]="excludedFormStatuses"
      (setFormFilter)="onSave($event)"
      (cancelFormFilter)="onCancel()"
      class="member-filter__select">
    </ptl-form-statuses-member-filter>
    <div *ngSwitchDefault></div>
  </div>
</div>
