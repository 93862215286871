/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Environment, EnvironmentCode } from './environment.model';

export const environment: Environment = {
  env: EnvironmentCode.prod,
  disableAuth: false,
  dataProvider: 'api',
  iframelyApiKey: '406be9cc0e924b4cdda562',
  iframelyEncodedApiKey: '5c83d7ddc6a9dfcf6adc1c0aa938063a',
  apiRootUrl: 'https://api.y.potential.ly',
  realmUrl: 'https://auth.potential.ly/auth/realms/yuna-prod/',
  samlSpHost: 'https://sso.potential.ly/',
  clientId: 'yuna-prod-web',
  nonce: '123456',
  googleAnalyticsPropertyId: 'UA-159589622-1',
  reCaptchaSiteKeyV2: '6LcT-LIaAAAAAJcYuo_Kffq60twc4-D76cAqZGxr',
  reCaptchaSiteKeyV3: '6Ld5v54aAAAAAD6K_j4yZ3lBuHbwQhyoyvfc8De9',
};
