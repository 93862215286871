<div class="users-csv-upload-dialog-content">
  <div class="dialog-top-info">
    <h1 class="dialog-title">{{ 'translations.membersNew.controlPanel.usersUpload.title' | transloco }}</h1>
    <p
      class="dialog-description medium">{{ 'translations.membersNew.controlPanel.usersUpload.description' | transloco }}</p>
    <button class="close-dialog-btn"
            mat-icon-button color="primary"
            (click)="closeDialog()"
            [attr.aria-label]="'translations.global.ariaLabels.closeDialog'">
      <svg class="svg" role="img" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div class="users-csv-upload-content">
    <div class="content-wrapper">
      <h2>{{ 'translations.membersNew.controlPanel.usersUpload.csvFile' | transloco }}</h2>
      <div
        tabindex="0"
        class="file-upload-content"
        ogFileDrop
        (click)="fileInput.click()"
        [attr.aria-label]="('translations.global.ariaLabels.dragFileOrBrowse' | transloco)"
        (keydown.enter)="fileInput.click()"
        (filesHovered)="filesHovered=$event"
        [class.file-upload-content--active]="filesHovered"
        [class.file-upload-content--error]="csvInvalid"
        (filesDropped)="onFilesDropped($event)">
        <div *ngIf="!fileUploaded" class="medium"
             [innerHTML]=" 'translations.membersNew.controlPanel.usersUpload.button.dragFileOrBrowse' | transloco"></div>
        <span *ngIf="fileUploaded" class="medium">{{ uploadedFileName }}</span>
        <input
          type="file"
          [accept]="'.csv'"
          (change)="onFileChanged($event)"
          hidden
          #fileInput>
      </div>

      <div class="download-csv-content"
           [innerHTML]="'translations.membersNew.controlPanel.usersUpload.csvDownload' | transloco | safeHtml"></div>

    </div>

  </div>

  <div class="dialog-footer-buttons">
    <button mat-flat-button type="button" class="cancel-button" color="accent" (click)="closeDialog()">
      {{ 'translations.global.button.cancel' | transloco }}
    </button>
    <button mat-flat-button type="submit" class="submit-button" color="primary" (click)="uploadUsers()">
      <span class="submit-loading" *ngIf="isLoading">
        <mat-spinner [diameter]="20"></mat-spinner>
      </span>
      {{'translations.global.button.upload' | transloco}}
    </button>
  </div>

</div>
