import * as moment from 'moment';
import { cloneDeep } from 'lodash-es';
import { EventCardTime, EventTickets } from '../../page-modules/resource/store/admin/resource-event-admin.state.model';
import { TranslationService } from '../services/translation/translation.service';
import { EventTicketsDetails } from '@app/app/shared/models';

type EventStatus = 'ENDED' | 'IN_PROGRESS' | 'TODAY' | 'TOMORROW' | 'FUTURE' | 'UNKNOWN'

export class EventCardsHelper {

  static getTextColorClassBasedOnRemainingTickets(tickets: EventTickets): string {
    if (tickets && tickets.availability === 'LIMITED' && tickets.quantity && (tickets.remainingTickets || tickets.remainingTickets === 0)) {
      const ratio = tickets.remainingTickets / tickets.quantity;
      if (ratio <= 0.15) { // 15% of all tickets
        return 'bad';
      } else if (ratio <= 0.30) { // 30% of all tickets
        return 'warn';
      } else {
        return 'positive';
      }
    }
    return ''
  }

  static getEventFormattedDate(eventTime: EventCardTime): string {
    if (!eventTime) {
      return '';
    }

    const startTime = eventTime.start ? this.formatDateIntoUTC0(eventTime.start, eventTime.timeZone) : null;
    const endTime = eventTime.end ? this.formatDateIntoUTC0(eventTime.end, eventTime.timeZone) : null;

    const timeFormat = 'DD MMM YYYY HH:mm';
    const startDate = moment.utc(startTime).add(this.getLocalTimeZone(), 'hours');
    const endDate = endTime ? moment.utc(endTime).add(this.getLocalTimeZone(), 'hours') : null;

    if (endDate) {
      if (startDate.year() === endDate.year() &&
        startDate.month() === endDate.month() &&
        startDate.date() === endDate.date()) {
        return `${startDate.format(timeFormat)} - ${endDate.format('HH:mm')}`;
      } else {
        return `${startDate.format(timeFormat)} - ${endDate.format(timeFormat)}`;
      }
    } else {
      return startDate.format(timeFormat);
    }
  }

  static getLocalTimeZone(): number {
    return new Date().getTimezoneOffset() / -60;
  }

  static formatDateIntoUTC0(time: string, timezone: string): string {
    if (timezone === 'GMT') {
      timezone = 'GMT+00:00';
    }

    const dateWithOffset = `${time}${timezone}`;
    return moment.parseZone(dateWithOffset, 'YYYY-MM-DD HH:mmZ').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }

  static validateIfStartDateIsPresentOrFuture(startedDate: Date, startedTime: string, timezone: string): boolean {
    const startDateString = `${moment(startedDate).format('YYYY-MM-DD')} ${startedTime}`
    const startDateTimeWithTimezone = this.getTimes({start: startDateString, end: undefined, timeZone: timezone}).start;

    return moment(startDateTimeWithTimezone).isAfter(moment(new Date()));
  }

  static convertParticipationStatusTimestampToLocale(dateInUTC: string) {
    const timeFormat = 'DD MMM YYYY HH:mm';
    const hoursToSubtract = (-1) * (moment().utcOffset()) / 60;
    const date = dateInUTC.substr(0, dateInUTC.indexOf('T'));
    const time = dateInUTC.substr(dateInUTC.indexOf('T')+1).substr(0, 5);
    const dateTime = `${date}T${time}`

    return moment(dateTime).subtract(hoursToSubtract, 'hours').format(timeFormat);
  }

  static getEventStartingText(eventTime: EventCardTime, translationService: TranslationService): string {
    if (!eventTime) {
      return '';
    }
    const times = this.getTimes(eventTime);
    const timezone = this.getTimezone();
    const status = this.getEventStatus(eventTime, times, timezone);
    return this.getStartEventMessage(status, times.start, timezone, translationService);
  }

  static getEventEndingText(eventTime: EventCardTime, translationService: TranslationService): string {
    if (!eventTime) {
      return '';
    }
    const times = this.getTimes(eventTime);
    const timezone = this.getTimezone();
    const status = this.getEventStatus(eventTime, times, timezone);
    return this.getEndEventMessage(status, times.end, timezone, translationService);
  }

  private static getTimes(eventTime: EventCardTime) {
    const hoursToSubtract = this.getHoursToSubtract(cloneDeep(eventTime.timeZone));
    return this.getStartAndEndTimes(cloneDeep(eventTime.start), cloneDeep(eventTime.end), hoursToSubtract);
  }

  private static getTimezone() {
    const date = new Date().toString();
    return date.substr(date.indexOf('GMT') + 3, 5);
  }

  private static getEventStatus(
    eventTime: EventCardTime,
    times: { start: string; end: string },
    timezone: string
  ): EventStatus {
    if (!eventTime) {
      return 'UNKNOWN';
    }

    if (moment(times.start).utcOffset(timezone).isAfter(moment().utcOffset(timezone, true))) {
      const startDate = moment(times.start).utcOffset(timezone, true).format('YYYY-MM-DD');
      const currentDate = moment(new Date()).utcOffset(timezone, true).format('YYYY-MM-DD');
      const daysDiff = moment(startDate).diff(moment(currentDate), 'days');
      if (daysDiff === 1) {
        return 'TOMORROW';
      } else if (daysDiff === 0) {
        return 'TODAY';
      } else {
        return 'FUTURE';
      }
    } else if (times.end && (moment(times.end).utcOffset(timezone, true).isBefore(moment().utcOffset(timezone, true)))) {
      return 'ENDED';
    } else {
      return 'IN_PROGRESS';
    }
  }

  private static getStartEventMessage(
    status: EventStatus,
    time: string,
    timezone: string,
    translationService: TranslationService
  ): string {
    if(!time) {
      return '';
    }
    switch (status) {
      case 'TODAY':
        return translationService.getTranslation('cards.event.time.startsToday')
          + ' ' + moment(time).utcOffset(timezone, true).format('LT');
      case 'TOMORROW':
        return translationService.getTranslation('cards.event.time.startsTomorrow')
          + ' ' + moment(time).utcOffset(timezone, true).format('LT');
      case 'FUTURE':
        return translationService.getTranslation('cards.event.time.starts')
          + ' ' + moment(time).utcOffset(timezone, true).format('DD MMM YYYY - LT')
            .replace('-', ' at ');
      case 'ENDED':
        return translationService.getTranslation('cards.event.time.ended');
      case 'IN_PROGRESS':
        return translationService.getTranslation('cards.event.time.inProgress');
      default:
        return '';
    }
  }

  private static getEndEventMessage(
    status: EventStatus,
    time: string,
    timezone: string,
    translationService: TranslationService
  ): string {
    if (!time) {
      return '';
    }
    switch (status) {
      case 'TODAY':
        return translationService.getTranslation('cards.event.time.endsToday')
          + ' ' + moment(time).utcOffset(timezone, true).format('LT');
      case 'TOMORROW':
        return translationService.getTranslation('cards.event.time.endsTomorrow')
          + ' ' + moment(time).utcOffset(timezone, true).format('LT');
      case 'FUTURE':
        return translationService.getTranslation('cards.event.time.ends')
          + ' ' + moment(time).utcOffset(timezone, true).format('DD MMM YYYY - LT')
            .replace('-', ' at ');
      default:
        return '';
    }
  }

  static getHoursToSubtract(timezone: string): number {
    let timezoneOffset = timezone.replace('GMT', '');
    if (timezoneOffset === '') {
      timezoneOffset = '+0000';
    }
    if (timezoneOffset.indexOf('-') !== -1) {
      return -1 * Number(timezoneOffset.slice(1, 3));
    } else {
      return Number(timezoneOffset.slice(1, 3));
    }
  }

  static getStartAndEndTimes(start: string, end: string, hoursToSubtract: number): { start: string; end: string } {
    const localTimezoneOffset = this.getLocalTimeZone();

    if (start) {
      start = start.replace(' ', 'T') + ':00Z';
      start = moment(start).subtract(hoursToSubtract, 'hours').utcOffset(localTimezoneOffset).format();
    }
    if (end) {
      end = end.replace(' ', 'T') + ':00Z';
      end = moment(end).subtract(hoursToSubtract, 'hours').utcOffset(localTimezoneOffset).format();
    }

    return { start: start, end: end };
  }

  static validateNewEventTime(
    eventTimeStartDate: Date,
    eventTimeStartTime: string,
    eventTimeTimezone: string,
    eventTickets: EventTickets | EventTicketsDetails
    ): boolean {
      if (eventTickets?.time?.start) {
        const eventTimeStartDateString = `${moment(eventTimeStartDate).format('YYYY-MM-DD')} ${eventTimeStartTime}`
        const eventTimeStartDateWithTimezone =
          this.getTimes({start: eventTimeStartDateString, end: undefined, timeZone: eventTimeTimezone}).start;

        const eventTicketsStartDate = eventTickets.time.start.split(' ')[0];
        const eventTicketsStartTime = eventTickets.time.start.split(' ')[1];

        const eventTicketsStartDateString = `${moment(eventTicketsStartDate).format('YYYY-MM-DD')} ${eventTicketsStartTime}`
        const eventTicketsStartDateWithTimezone =
          this.getTimes({start: eventTicketsStartDateString, end: undefined, timeZone: eventTickets.time.timeZone}).start;

        if (moment(eventTicketsStartDateWithTimezone).isSameOrAfter(moment(eventTimeStartDateWithTimezone))) {
          return false;
        }

        if (eventTickets.time.end) {
          const eventTicketsEndDate = eventTickets.time.end.split(' ')[0];
          const eventTicketsEndTime = eventTickets.time.end.split(' ')[1];

          const eventTicketsEndDateString = `${moment(eventTicketsEndDate).format('YYYY-MM-DD')} ${eventTicketsEndTime}`
          const eventTicketsEndDateWithTimezone =
            this.getTimes({start: eventTicketsEndDateString, end: undefined, timeZone: eventTickets.time.timeZone}).start;

          if (moment(eventTicketsEndDateWithTimezone).isSameOrAfter(moment(eventTimeStartDateWithTimezone))) {
            return false;
          }
        }
      }

      return true;
  }

  static validateNewEventTicketsTime(
    eventTicketsStartDate: Date,
    eventTicketsStartTime: string,
    eventTicketsEndDate: Date,
    eventTicketsEndTime: string,
    eventTicketsTimezone: string,
    eventTime: EventCardTime
    ): boolean {
      if (eventTime) {
        const eventTimeStartDate = eventTime.start.split(' ')[0];
        const eventTimeStartTime = eventTime.start.split(' ')[1];

        const eventTimeStartDateString = `${moment(eventTimeStartDate).format('YYYY-MM-DD')} ${eventTimeStartTime}`
        const eventTimeStartDateWithTimezone =
          this.getTimes({start: eventTimeStartDateString, end: undefined, timeZone: eventTime.timeZone}).start;

        const eventTicketsStartDateString = `${moment(eventTicketsStartDate).format('YYYY-MM-DD')} ${eventTicketsStartTime}`
        const eventTicketsStartDateWithTimezone =
          this.getTimes({start: eventTicketsStartDateString, end: undefined, timeZone: eventTicketsTimezone}).start;

        if (moment(eventTicketsStartDateWithTimezone).isSameOrAfter(moment(eventTimeStartDateWithTimezone))) {
          return false;
        }

        if (eventTicketsEndDate && eventTicketsEndTime) {
          const eventTicketsEndDateString = `${moment(eventTicketsEndDate).format('YYYY-MM-DD')} ${eventTicketsEndTime}`
          const eventTicketsEndDateWithTimezone =
            this.getTimes({start: eventTicketsEndDateString, end: undefined, timeZone: eventTicketsTimezone}).start;

          if (moment(eventTicketsEndDateWithTimezone).isSameOrAfter(moment(eventTimeStartDateWithTimezone))) {
            return false;
          }
        }
      }

      return true;
  }
}
