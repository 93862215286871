/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { EditorContent, EditorContentRemovalEvent, EditorContentUpdateEvent } from '../../../../../../shared/models';

@Component({
  selector: 'ptl-choice-question-type',
  templateUrl: './choice-question-type.component.html',
  styleUrls: ['../add-question-section.component.scss', './choice-question-type.component.scss'],
})
export class ChoiceQuestionTypeComponent implements OnChanges {

  /** Receives the question to edit */
  @Input() question: FormGroup;

  /** Receives Form Editing Status */
  @Input() isEditingForm: boolean;

  @Input() languageCode: string | undefined;
  @Input() isDefaultLanguage: boolean;

  @Input() newVersionEnabled: boolean;

  constructor( private fb: FormBuilder ) {
  }

  ngOnChanges() {
    if ( this.question ) {
      this.populateForm();
    }
  }

  addQuestionOption( data?: { value: string; score: number } ): FormGroup {
    const dynamicContentRequest = this.fb.array([
      this.fb.group({
        content: '',
        type: 'PARAGRAPH',
        uid: ''
      })
    ]);

    return this.fb.group({
      type: 'CHOICE_OPTION',
      value: this.fb.group({
        languageCode: this.languageCode,
        value: [data ? data.value : '', [Validators.required, Validators.maxLength(33)]],
      }),
      instructions: dynamicContentRequest,
      score: [data ? data.score : undefined, Validators.required],
    });
  }

  onContentUpdate( { index, update }: EditorContentUpdateEvent, level: EditorContent[], questionIndex: number ) {
    const content = level.map(( editorContent, i ) => i === index ? update.newContent : editorContent);
    const optionsArray = this.question.get('options') as FormArray;
    const optionControl = optionsArray.at(questionIndex) as FormGroup;
    optionControl.patchValue({ instructions: content });
  }

  onContentRemoval( { index }: EditorContentRemovalEvent, level: EditorContent[], questionIndex: number ) {
    const optionsArray = this.question.get('options') as FormArray;
    const optionControl = optionsArray.at(questionIndex) as FormGroup;
    optionControl.patchValue({ instructions: [{ type: 'PARAGRAPH', content: '' }] });
  }

  removeOption( index ) {
    (this.question.controls['options'] as FormArray).removeAt(index);
  }

  addNewOption() {
    (this.question.controls['options'] as FormArray).push(this.addQuestionOption());
  }

  dropOption( moveEvent: CdkDragDrop<FormArray[]> ) {
    moveItemInArray((this.question.controls['options'] as FormArray).controls,
      moveEvent.previousIndex, moveEvent.currentIndex);
    this.question.controls['options'].updateValueAndValidity();
  }

  private populateForm() {
    // if no uid, clear current arrays and add a blank one
    if ( !this.question.value.uid ) {
      while ( (this.question.controls['options'] as FormArray).length !== 0 ) {
        (this.question.controls['options'] as FormArray).removeAt(0);
      }
      this.addNewOption();
    }
  }

}
