<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<main
  class="main"
  [ngStyle]="{
    'background-image': (organizationData$ | async)?.landingPage.backgroundImage ? 'url(&quot;' +  (organizationData$ | async)?.landingPage.backgroundImage + '&quot;)' : '',
    'background-color': (organizationData$ | async)?.landingPage.useBackgroundColourFill ? (organizationData$ | async)?.landingPage.backgroundColour : '',
  }"
  [class.dynamic-background-color]="(organizationData$ | async)?.landingPage.useBackgroundColourFill"
  [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">

  <div class="maintenance-layer" *ngIf="showMaintenanceOverlay">
    <div class="maintenance-layer-inner" [innerHTML]="'translations.maintenance.overlayText' | transloco"></div>
  </div>

  <div class="password__form-container"
       [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
    <mat-card class="main__password"
              [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">

      <img
        class="logo__image" width="240" height="80"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
        src="{{ (organizationData$ | async)?.landingPage.landingLogo || (organizationData$ | async)?.logo }}"
        alt="{{(organizationData$ | async)?.name}} Logo"/>
      <div class="password__university-info">
        <h1 *ngIf="isEmailConfirmationPage && tokenType !== 'PRE_ARRIVAL_ACCOUNT_INVITATION'"
            class="title">{{ 'translations.public.title.confirmFolioEmail' | transloco }}</h1
        ><h1 *ngIf="isEmailConfirmationPage && tokenType === 'PRE_ARRIVAL_ACCOUNT_INVITATION'"
            class="title">{{ 'translations.public.title.confirmUniversityEmail' | transloco }}</h1>
        <h1 *ngIf="!isEmailConfirmationPage"
            class="title">{{ 'translations.public.title.resetPassword' | transloco }}</h1>
      </div>

      <div class="login__form-inner"
           [class.split-screen]="(organizationData$ | async)?.landingPage.layout === 'SPLIT_SCREEN'">
        <form
          class="password-form"
          [formGroup]="passwordForm"
          (ngSubmit)="onSubmitPassword()"
          [attr.aria-label]="('translations.global.ariaLabels.resetPasswordForm' | transloco)"
          novalidate>

          <mat-form-field *ngIf="isEmailConfirmationPage" class="form__field" appearance="outline">
            <mat-label
              [attr.aria-label]="('translations.global.ariaLabels.emailField' | transloco)">{{ 'translations.global.input.label.email' | transloco }}</mat-label>
            <input
              matInput
              required
              name="email"
              type="email"
              autocomplete="off"
              formControlName="email"
              readonly>
          </mat-form-field>

          <mat-form-field class="form__field password_field" appearance="outline">
            <mat-label
              [attr.aria-label]="('translations.global.ariaLabels.newPasswordField' | transloco)">{{ 'translations.global.input.label.newPassword' | transloco }}</mat-label>
            <input
              matInput
              required
              name="n-password"
              (focus)="passwordFocusState = true" (focusout)="passwordFocusState = false"
              type="{{ showPassword ? 'text' : 'password' }}"
              autocomplete="off"
              formControlName="password">
            <div class="password__visibility">
              <i
                tabindex="0"
                [attr.aria-label]="('translations.global.ariaLabels.showOrHidePassword' | transloco)"
                class="material-icons password__show-icon"
                (click)="showPassword = !showPassword"
                (keydown.enter)="showPassword = !showPassword">
                visibility
              </i>
            </div>
            <mat-error *ngIf="passwordForm?.controls.password.hasError('required')">
              {{ 'translations.global.input.error.passwordRequired' | transloco }}
            </mat-error>
            <mat-hint *ngIf="passwordFocusState">{{ 'translations.global.input.error.enterValidPassword' | transloco }}</mat-hint>
            <mat-error *ngIf="passwordForm?.controls.password.hasError('pattern')">
              {{ 'translations.global.input.error.enterValidNewPassword' | transloco }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form__field password_field" appearance="outline">
            <mat-label
              [attr.aria-label]="('translations.global.ariaLabels.confirmPasswordField' | transloco)">{{ 'translations.global.input.label.confirmPassword' | transloco }}</mat-label>
            <input
              matInput
              required
              name="n-password"
              (focus)="confirmPasswordFocusState = true" (focusout)="confirmPasswordFocusState = false"
              type="{{ showConfirmPassword ? 'text' : 'password' }}"
              autocomplete="off"
              formControlName="newPassword">
            <div class="password__visibility">
              <i
                tabindex="0"
                [attr.aria-label]="('translations.global.ariaLabels.showOrHidePassword' | transloco)"
                class="material-icons password__show-icon"
                (click)="showConfirmPassword = !showConfirmPassword"
                (keydown.enter)="showConfirmPassword = !showConfirmPassword">
                visibility
              </i>
            </div>
            <mat-error *ngIf="passwordForm?.controls.newPassword.hasError('required')">
              {{ 'translations.global.input.error.enterValidConfirmPassword' | transloco }}
            </mat-error>
            <mat-hint *ngIf="confirmPasswordFocusState">{{ 'translations.global.input.error.enterValidPassword' | transloco }}</mat-hint>
            <mat-error *ngIf="passwordForm?.controls.newPassword.hasError('mustMatch')">
              {{ 'translations.global.input.error.passwordsNotEqual' | transloco }}
            </mat-error>
          </mat-form-field>
          <ngx-invisible-recaptcha #captchaElem
                                   [siteKey]="reCaptchaSiteKeyV2"
                                   (success)="proceedIfValid($event)"
                                   (reset)="resetCaptcha()"
                                   [ngModel]="reCaptchaElement"
                                   [ngModelOptions]="{ standalone: true }"
                                   [useGlobalDomain]="false">
          </ngx-invisible-recaptcha>
          <div class="password-form__actions">
            <button
              mat-flat-button
              [class.old-version]="!newVersionEnabled"
              type="submit"
              class="password-form__submit uppercase"
              title="Update"
              [attr.aria-label]="('translations.global.ariaLabels.updatePassword' | transloco)"
              color="primary"
            >
              {{ (isEmailConfirmationPage ? 'translations.global.button.confirm' : 'translations.public.button.updateNewPassword') | transloco }}
            </button>
          </div>
        </form>
      </div>
      <div
        *ngIf="false"
        class="password__powered-content"
        [attr.aria-label]="('translations.global.ariaLabels.poweredBy' | transloco: {organizationName: (organizationData$ | async)?.name})"
        [class.full-background]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'">
        {{ 'translations.global.label.poweredBy' | transloco }}
        <a href="https://potential.ly"
           [class.is_light]="(organizationData$ | async)?.landingPage.layout === 'FULL_BACKGROUND'"
           class="login__powered-content-link" [attr.aria-label]="('translations.global.ariaLabels.open' | transloco: {title: (organizationData$ | async)?.name})">
          <img
            class="login__powered-image" width="40" height="40"
            src="https://static.yuna.potential.ly/public/images/icons/potentially_logo.png"
            alt="" />
          {{ 'translations.public.title.logoText' | transloco }}
        </a>
      </div>
    </mat-card>
  </div>
</main>

