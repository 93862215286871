/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Observable, Subject } from 'rxjs';
import { ObservableResult } from '../../store';
import { FileUploadService } from './file-upload.service';
import { FileNameWithUploadProgress } from './file-upload.model';

export class MockFileUploadService implements FileUploadService {

  uploadFile(fileUrl: string, file: File): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  uploadFileDirectly(presignedUrl: string, file: File): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  private uploadProgress = new Subject<FileNameWithUploadProgress[]>();

  castUploadProgress: Observable<FileNameWithUploadProgress[]>;

  constructor() {
    this.castUploadProgress = this.uploadProgress.asObservable();
  }
}
