<div class="assessment-export-dialog-content">
  <div class="dialog-top-info">
    <h1 class="dialog-title">{{ 'translations.membersNew.controlPanel.assessmentExport.title' | transloco }}</h1>
    <p
      class="dialog-description medium">{{ 'translations.membersNew.controlPanel.assessmentExport.description' | transloco }}</p>
    <button class="close-dialog-btn"
            mat-icon-button color="primary"
            (click)="closeDialog()"
            [attr.aria-label]="'translations.global.ariaLabels.closeDialog'">
      <svg class="svg" role="img" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div class="assessment-export-content">
    <div class="assessment-export__include-distance-travelled-toggle-box">
      <mat-slide-toggle
        class="ptl-slide-toggle small"
        [attr.aria-label]="('translations.global.ariaLabels.includeDistanceTravel' | transloco) + includeDistanceTravel ? ' ' + ('translations.global.ariaLabels.enabled' | transloco) : ' ' + ('translations.global.ariaLabels.disabled' | transloco)"
        [checked]="includeDistanceTravel"
        (change)="toggleIncludeDistanceTravel()"
        (keydown.enter)="toggleIncludeDistanceTravel()">
        <span
          class="medium">{{ 'translations.membersNew.controlPanel.assessmentExport.includeDistanceTravelled' | transloco }}</span>
      </mat-slide-toggle>
    </div>

    <div class="assessment-export__include-distance-travelled-content" [class.disabled]="!includeDistanceTravel">
      <div class="medium bold">
        {{ 'translations.membersNew.controlPanel.assessmentExport.compare' | transloco }}
      </div>
      <div class="compare-item">
        <mat-select
          class="select-field mode small"
          [attr.aria-label]="('translations.global.ariaLabels.selectFirstDistanceTravelledDateRange' | transloco)"
          [disabled]="!includeDistanceTravel"
          [value]="firstComparisonType"
          (selectionChange)="onFirstComparisonTypeChange($event)"
        >
          <mat-option [value]="'EARLIEST'">
            {{ 'translations.membersNew.controlPanel.assessmentExport.earliest' | transloco }}
          </mat-option>
          <mat-option [value]="'LATEST'">
            {{ 'translations.membersNew.controlPanel.assessmentExport.latest' | transloco }}
          </mat-option>
        </mat-select>
        <span class="small">
    {{ 'translations.membersNew.controlPanel.assessmentExport.completionWithin' | transloco }}
  </span>
        <button
          class="select-field"
          type="button"
          [disabled]="!includeDistanceTravel"
          [class.active-btn]="includeDistanceTravel"
          [class.error]="includeDistanceTravel && firstRangeError"
          (click)="firstDatePicker.open()"
        >
          <span class="small" *ngIf="!firstRangeInitialized">...</span>
          <span class="small" *ngIf="firstRangeInitialized">
            {{ formatRange(firstBetweenStart, firstBetweenEnd) }}
          </span>
        </button>
        <mat-date-range-input [rangePicker]="firstDatePicker">
          <input matStartDate (dateChange)="firstStartDateChanged($event)" hidden>
          <input matEndDate (dateChange)="firstEndDateChanged($event)" hidden>
          <mat-date-range-picker #firstDatePicker></mat-date-range-picker>
        </mat-date-range-input>
      </div>
      <div class="medium bold">
        {{ 'translations.membersNew.controlPanel.assessmentExport.with' | transloco }}
      </div>
      <div class="compare-item">
        <mat-select
          class="select-field mode small"
          [attr.aria-label]="('translations.global.ariaLabels.selectSecondDistanceTravelledDateRange' | transloco)"
          [disabled]="!includeDistanceTravel"
          [value]="secondComparisonType"
          (selectionChange)="onSecondComparisonTypeChange($event)">
          <mat-option [value]="'EARLIEST'">
            {{ 'translations.membersNew.controlPanel.assessmentExport.earliest' | transloco }}
          </mat-option>
          <mat-option [value]="'LATEST'">
            {{ 'translations.membersNew.controlPanel.assessmentExport.latest' | transloco }}
          </mat-option>
        </mat-select>
        <span
          class="small">{{ 'translations.membersNew.controlPanel.assessmentExport.completionWithin' | transloco }}</span>
        <button
          class="select-field"
          type="button"
          [disabled]="!includeDistanceTravel"
          [class.active-btn]="includeDistanceTravel"
          [class.error]="includeDistanceTravel && secondRangeError"
          (click)="secondDatePicker.open()"
        >
          <span class="small" *ngIf="!secondRangeInitialized">...</span>
          <span class="small" *ngIf="secondRangeInitialized">
            {{ formatRange(secondBetweenStart, secondBetweenEnd) }}
          </span>
        </button>
        <mat-date-range-input [rangePicker]="secondDatePicker">
          <input matStartDate (dateChange)="secondStartDateChanged($event)" hidden>
          <input matEndDate (dateChange)="secondEndDateChanged($event)" hidden>
          <mat-date-range-picker #secondDatePicker></mat-date-range-picker>
        </mat-date-range-input>
      </div>
    </div>
  </div>

  <div class="buttons-row">
    <button mat-button (click)="closeDialog()">
      <span class="small bold">{{ 'translations.global.button.cancel' | transloco }}</span>
    </button>
    <button mat-button (click)="export()" class="suggested">
      <span class="small bold">{{'translations.global.button.export' | transloco}}</span>
    </button>
  </div>

</div>
