<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="add-section-dialog">
  <div class="dialog-header">
    <div class="dialog-header__top">

      <h1 class="dialog-header-title"
          tabindex="0"
          [attr.aria-label]="('translations.pages.newSection.title.addSection' | transloco)">
        {{ 'translations.pages.newSection.title.addSection' | transloco }}</h1>

      <i class="material-icons dialog-header__close-icon"
         [attr.aria-label]="('translations.global.ariaLabels.closePage' | transloco)"
         (click)="closeDialog()"
         (keydown.enter)="closeDialog()"
         tabindex="0">close</i>
    </div>

    <div class="add-section-tabs">
      <div class="tab-item small is_active" *ngIf="data?.addType === 'template'"
           tabindex="0" [class.is_active]="selectedTab === 'PAGES'"
           [attr.aria-label]="'translations.pages.newSection.tabs.pages' | transloco"
           (click)="showTab('PAGES')" (keydown.enter)="showTab('PAGES')">
        <svg class="svg" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#multiple-page"></use>
        </svg>
        <span class="title">{{ 'translations.pages.newSection.tabs.pages' | transloco }}</span>
      </div>
      <div class="tab-item small" *ngIf="data?.addType === 'block'"
           tabindex="0" [class.is_active]="selectedTab === 'BASIC_BLOCK'"
           [attr.aria-label]="'translations.pages.newSection.tabs.basicBlocks' | transloco"
           (click)="showTab('BASIC_BLOCK')" (keydown.enter)="showTab('BASIC_BLOCK')">
        <svg class="svg" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#image"></use>
        </svg>
        <span class="title">{{ 'translations.pages.newSection.tabs.basicBlocks' | transloco }}</span>
      </div>
      <div class="tab-item small" *ngIf="data?.addType === 'block'"
           tabindex="0" [class.is_active]="selectedTab === 'AUTOMATIC'"
           [attr.aria-label]="'translations.pages.newSection.tabs.automation' | transloco"
           (click)="showTab('AUTOMATIC')" (keydown.enter)="showTab('AUTOMATIC')">
        <svg class="svg" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#advanced"></use>
        </svg>
        <span class="title">{{ 'translations.pages.newSection.tabs.automation' | transloco }}</span>
      </div>
    </div>
  </div>
  <div class="add-section-dialog-body">
    <div class="section-items-list" *ngIf="selectedTab === 'PAGES'">
      <div class="section-item" *ngFor="let item of pagesSectionItems"
           tabindex="0" [attr.aria-label]="item.title | transloco" (click)="onSectionClick(item.type)"
           (keydown.enter)="onSectionClick(item.type)">
        <div class="section-image">
          <img alt="{{item.title | transloco}}" src="{{item.image}}" width=""/>
        </div>
        <div class="section-title small">
          {{item.title | transloco}}
        </div>
      </div>
    </div>
    <div class="section-items-list" *ngIf="selectedTab === 'BASIC_BLOCK'">
      <div class="section-item" *ngFor="let item of basicBlockSectionItems"
           tabindex="0" [attr.aria-label]="item.title | transloco" (click)="onSectionClick(item.type)"
           (keydown.enter)="onSectionClick(item.type)">
        <div class="section-image">
          <img alt="{{item.title | transloco}}" src="{{item.image}}" width=""/>
        </div>
        <div class="section-title small">
          {{item.title | transloco}}
        </div>
      </div>
    </div>
    <div class="section-items-list" *ngIf="selectedTab === 'AUTOMATIC'">
      <div class="section-item" *ngFor="let item of automaticSectionItems"
           tabindex="0" [attr.aria-label]="item.title | transloco" (click)="onSectionClick(item.type)"
           (keydown.enter)="onSectionClick(item.type)">
        <div class="section-image">
          <img alt="{{item.title | transloco}}" src="{{item.image}}" width=""/>
        </div>
        <div class="section-title small">
          {{item.title | transloco}}
        </div>
      </div>
    </div>
  </div>
</div>
