import { Component, Input, OnDestroy, OnChanges } from '@angular/core';
import { interval, Subscription, of, Observable } from 'rxjs';
import { switchMap, tap, filter, takeWhile, catchError } from 'rxjs/operators';
import { RestClientService } from '../../services/rest-client.service';

@Component({
  selector: 'ptl-optimized-image-loader',
  templateUrl: './optimized-image-loader.component.html',
  styleUrls: ['./optimized-image-loader.component.scss'],
})
export class OptimizedImageLoaderComponent implements OnDestroy, OnChanges {

  /** The source of the image to display. */
  @Input() src: string;

  displayImage = false;
  unableToLoadImage = false;

  imagePollSubscription: Subscription;
  MAX_POLLS = 20;
  pollCounter = 0;

  constructor(private restClient: RestClientService) {
  }

  /**
   * Implements a poller which would poll the image source (up to 10 times) with a 600 ms interval,
   * and only display the image once the image is present in the source location.
   * This has been implemented to fix the race condition where the image takes a moment to be optimized when uploaded,
   * so if we try to render it straight away we get a 404 (image not yet in the s3 bucket, being optimized).
   */
  ngOnChanges() {
    if (this.src) {
      this.imagePollSubscription = this.restClient.get(this.src).pipe(
        catchError(e => of({ status: e.status })),
        tap(response => {
          if (response.status === 200) {
            this.displayImage = true;
          }
          this.pollCounter++;
        }),
        filter(response => response.status && response.status !== 200),
        switchMap(() => this.pollForImageEveryHalfSecond())
      ).subscribe();
    }
  }

  ngOnDestroy() {
    this.imagePollSubscription.unsubscribe();
  }

  pollForImageEveryHalfSecond() {
    return interval(600).pipe(
      takeWhile(() => !this.displayImage && this.pollCounter++ < this.MAX_POLLS),
      switchMap(() => this.restClient.get(this.src)),
      catchError(() => {
        if (this.pollCounter === this.MAX_POLLS) {
          this.unableToLoadImage = true;
        }
        return this.pollForImageEveryHalfSecond();
      }),
      // @ts-ignore
      filter(response => response.status === 200),
      tap(() => {
        this.displayImage = true;
      })
    );
  }
}
