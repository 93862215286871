/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
import { ObservableResult } from '../../../shared/store';
import {
  MembersMessageRequest,
  Message,
  MessageRequest,
  Recipient
} from '../../../shared/models/messages/messages.model';
import { Page } from '../../../shared/models/page';
import { InjectionToken } from '@angular/core';
import { ItemWithUploadUrls } from '../../../shared/models/ItemWithUploadUrl';

export const MESSAGES_DATA_SERVICE =
  new InjectionToken<MessagesDataService>('[Messages] MessagesDataService');

/** The service handling message CRUD operations. */
export interface MessagesDataService {

  createMessage(request: MessageRequest): ObservableResult<ItemWithUploadUrls<Message>>;

  loadMessage(messageUid: string): ObservableResult<Message>;

  loadReceivedMessages(
    page: number,
    size: number,
    read?: boolean,
    playlistUid?: string,
    cardUid?: string
  ): ObservableResult<Page<Message>>;

  loadSentMessages(
    page: number,
    size: number,
    playlistUid?: string,
    cardUid?: string
  ): ObservableResult<Page<Message>>;

  markMessage(messageUid: string, read: boolean): ObservableResult<void>;

  markManyMessages(messages: string[], read: boolean): ObservableResult<void>;

  loadRecipients(messageUid: string, page: number, size: number): ObservableResult<Page<Recipient>>;

  sendMessageToMembers(request: MembersMessageRequest): ObservableResult<ItemWithUploadUrls<Message>>;

  sendMessageToPlaylistMembers(request: MembersMessageRequest, playlistUid: string): ObservableResult<ItemWithUploadUrls<Message>>;

  sendMessageToCardMembers(
    request: MembersMessageRequest,
    playlistUid: string,
    cardUid: string
  ): ObservableResult<ItemWithUploadUrls<Message>>;
}
