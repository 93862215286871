import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '../../services/translation/translation.service';

@Component({
  selector: 'ptl-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QRCodeComponent implements OnInit {

  @Input() qrCodeData: string;
  @Input() qrCodeLabel: string;
  qrCode: string
  ariaLabel: string
  title: string

  constructor(private translationService: TranslationService) {
  }

  ngOnInit () {
    this.qrCode = this.qrCodeData;
    this.title = this.translationService.getTranslation('qrcode.title');
    this.ariaLabel = this.qrCodeLabel ? this.translationService.getTranslation(this.qrCodeLabel) : this.title;
  }
}
