/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import {
  DeepLinkingResponse, FileConversionPathToUpload,
  FileConversionRequest,
  FilePathResolutionRequest,
  LtiCourse,
  SkillsSuggestion
} from '../models/lti-course.model';
import { ResourceTag } from '../../../shared/models';
import { ObservableResult } from '../../../shared/store';
import { Page } from '../../../shared/models/page';
import { LearnerPlaylistSummary } from '../../../shared/models/playlist/learner-playlist-summary.model';
import { MembersBooleanSearchRequest } from '@app/app/shared/models/admin/boolean-filters.model';
import { MembersSearchResponse } from '@app/app/shared/models/admin/members.model';

export const LTI_COURSE_DATA_SERVICE =
  new InjectionToken<LtiCourseDataService>('[Lti] LtiCourseDataService');

export const LTI_PROVIDER = 'lti_provider'
export const LTI_COURSE_UID = 'lti_course_uid'


/** The service handling LTI course CRUD operations. */
export interface LtiCourseDataService {

  getLtiCourse(requestId: string): ObservableResult<LtiCourse>;

  getLtiCourseByPlaylistUid(coursePlaylistUid: string): ObservableResult<LtiCourse>;

  createLtiCourse(requestId: string): ObservableResult<LtiCourse>;

  getLtiPlaylist(requestId: string, playlistUri: string): ObservableResult<LearnerPlaylistSummary>;

  getLtiPlaylists(requestId: string, page: number, size: number, term?: string): ObservableResult<Page<LearnerPlaylistSummary>>;

  selectLtiPlaylist(requestId: string, playlistUid: string): ObservableResult<DeepLinkingResponse>;

  selectMultipleLtiPlaylists(requestId: string, playlists: string[]): ObservableResult<DeepLinkingResponse>;

  selectLtiSkills(requestId: string, skills: string[], frameworkUid?: string): ObservableResult<DeepLinkingResponse>;

  submitLtiSkills(organizationUid: string, coursePlaylistUid: string, userUid: string): ObservableResult<void>;

  generateSuggestions(text: string): ObservableResult<SkillsSuggestion[]>;

  transformSuggestionsToTags(suggestionsRequest: SkillsSuggestion[]): ObservableResult<ResourceTag[]>;

  resolveSuggestionsFileName(requestId: string, request: FilePathResolutionRequest): ObservableResult<FileConversionPathToUpload>;

  generateSuggestionsFromFile(requestId: string, request: FileConversionRequest): ObservableResult<SkillsSuggestion[]>;

  searchPlaylistMembers(
    request: MembersBooleanSearchRequest,
    playlistUid: string,
    page: number,
    size: number,
    courseUid: string
  ): ObservableResult<MembersSearchResponse>;

  exportPlaylistMembers(
    playlistUid: string,
    courseUid: string
  ): ObservableResult<string>;
}
