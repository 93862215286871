<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div id="addQuestionContainer" class="add-question"
     [ngClass]="{'questions__is_floating-content': isEditingQuestion, 'quiz-question-container': isQuiz}">
  <form class="add-question__form" [class.add-question-form-new]="newVersionEnabled" [formGroup]="questionForm"
        (ngSubmit)="saveQuestion()">

    <div class="question-editing-title" *ngIf="!currentLanguage?.isDefault"
         [innerHTML]="'translations.editingQuestionInLanguage' | transloco :{name: currentLanguage?.language?.name}"></div>

    <ng-container *ngIf="!newVersionEnabled">
      <div class="add-question__section add-question__section--padding" formGroupName="instruction">
        <h2
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.newQuestion' | transloco">
          {{ 'translations.newQuestion' | transloco }}</h2>
        <div class="add-question__section-field add-question__section-field--extended no-padding border"
             [class.has-error]="questionEmpty"
             [class.focused]="ptlFocused">
          <ptl-editor
            class="add-question__section__rich"
            [link]="false"
            [upload]="false"
            [separator]="false"
            [content]="[content]"
            (inputFocus)="ptlFocused = true"
            (inputBlur)="ptlFocused = false"
            (contentUpdate)="onMediumEditorUpdate($event)">
          </ptl-editor>
        </div>
        <div class="mat-error question-error" *ngIf="questionEmpty">
          {{ 'translations.questionRequiredError' | transloco }}
        </div>
      </div>
    </ng-container>

    <div class="add-question__section add-question__section--padding" *ngIf="newQuestion && !newVersionEnabled">
      <h2
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.questionType' | transloco">
        {{ 'translations.questionType' | transloco }}</h2>
      <div>
        <mat-form-field class="add-question__section-field no-padding color-gray add-question__section-field--min-width"
                        appearance="outline">
          <mat-select formControlName="type" [placeholder]="'Select Question Type'">
            <mat-select-trigger style="min-width: 6.25rem;">
              <img class="mat-select-icon"
                   [attr.src]="prettyStatus[questionForm.value.type].image"
                   width="50"
                   height="30"
                   alt="Option image">
              {{ prettyStatus[questionForm.value.type].name }}
            </mat-select-trigger>
            <mat-option value="CHOICE_QUESTION" *ngIf="activeTypes['CHOICE_QUESTION']">
              <img class="mat-select-icon"
                   src="../../../../../../../assets/icons/circles.svg"
                   width="50"
                   height="30"
                   alt="Option image">
              <span
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.ratingLikert' | transloco">
                {{ 'translations.ratingLikert' | transloco }}</span>
            </mat-option>
            <mat-option value="RATING_QUESTION" *ngIf="activeTypes['RATING_QUESTION']">
              <img class="mat-select-icon"
                   src="../../../../../../../assets/icons/circles.svg"
                   width="50"
                   height="30"
                   alt="Option image">
              <span
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.trueFalse' | transloco">
                {{ 'translations.trueFalse' | transloco }}</span>
            </mat-option>
            <mat-option value="MULTIPLE_CHOICE_QUESTION" *ngIf="activeTypes['MULTIPLE_CHOICE_QUESTION']">
              <img class="mat-select-icon"
                   src="../../../../../../../assets/icons/grid.svg"
                   width="50"
                   height="30"
                   alt="Option image">
              <span
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.multipleChoice' | transloco">
                {{ 'translations.multipleChoice' | transloco }}</span>
            </mat-option>
            <mat-option value="SLIDER_QUESTION" *ngIf="activeTypes['SLIDER_QUESTION']">
              <img class="mat-select-icon"
                   src="../../../../../../../assets/icons/line.svg"
                   width="50"
                   height="30"
                   alt="Option image">
              <span
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.slider' | transloco">
                {{ 'translations.slider' | transloco }}</span>
            </mat-option>
            <mat-option value="DRAGDROP_QUESTION" *ngIf="activeTypes['DRAGDROP_QUESTION']">
              <img class="mat-select-icon"
                   src="../../../../../../../assets/icons/hand.svg"
                   width="50"
                   height="30"
                   alt="Option image">
              <span
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.dragAndDrop' | transloco">
                {{ 'translations.dragAndDrop' | transloco }}</span>
            </mat-option>
            <mat-option value="GRID_QUESTION" *ngIf="activeTypes['GRID_QUESTION']">
              <img class="mat-select-icon"
                   src="../../../../../../../assets/icons/grid.svg"
                   width="50"
                   height="30"
                   alt="Option image">
              <span
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.grid' | transloco">
                {{ 'translations.grid' | transloco }}</span>
            </mat-option>
            <!-- info question -->
            <mat-option value="INFO_QUESTION" *ngIf="activeTypes['INFO_QUESTION']">
              <img class="mat-select-icon"
                   src="../../../../../../../assets/icons/info-circle.svg"
                   width="50"
                   height="30"
                   alt="Option image">
              <span
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.info' | transloco">
                {{ 'translations.info' | transloco }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="add-new-question-select-box" *ngIf="newQuestion && newVersionEnabled">
      <mat-form-field class="add-new-question-select" appearance="outline">
        <mat-select formControlName="type" [placeholder]="'Select Question Type'">
          <mat-select-trigger style="width: 100%">
            <img class="mat-select-icon-new"
                 [attr.src]="prettyStatus[questionForm.value.type].image"
                 width="20"
                 height="20"
                 alt="Option image">
            {{ prettyStatus[questionForm.value.type].name }}
          </mat-select-trigger>
          <mat-option value="CHOICE_QUESTION" *ngIf="activeTypes['CHOICE_QUESTION']">
            <img class="mat-select-icon-new"
                 src="../../../../../../../assets/icons/circles.svg"
                 width="20"
                 height="20"
                 alt="Option image">
            <span
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.ratingLikert' | transloco">
                {{ 'translations.ratingLikert' | transloco }}</span>
          </mat-option>
          <mat-option value="RATING_QUESTION" *ngIf="activeTypes['RATING_QUESTION']">
            <img class="mat-select-icon-new"
                 src="../../../../../../../assets/icons/circles.svg"
                 width="20"
                 height="20"
                 alt="Option image">
            <span
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.trueFalse' | transloco">
                {{ 'translations.trueFalse' | transloco }}</span>
          </mat-option>
          <mat-option value="MULTIPLE_CHOICE_QUESTION" *ngIf="activeTypes['MULTIPLE_CHOICE_QUESTION']">
            <img class="mat-select-icon-new"
                 [src]="newVersionEnabled ? '../../../../../../../assets/icons/new/list-bullet.svg' : '../../../../../../../assets/icons/grid.svg'"
                 width="20"
                 height="20"
                 alt="Option image">
            <span
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.multipleChoice' | transloco">
                {{ 'translations.multipleChoice' | transloco }}</span>
          </mat-option>
          <mat-option value="SLIDER_QUESTION" *ngIf="activeTypes['SLIDER_QUESTION']">
            <img class="mat-select-icon-new"
                 src="../../../../../../../assets/icons/line.svg"
                 width="20"
                 height="20"
                 alt="Option image">
            <span
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.slider' | transloco">
                {{ 'translations.slider' | transloco }}</span>
          </mat-option>
          <mat-option value="DRAGDROP_QUESTION" *ngIf="activeTypes['DRAGDROP_QUESTION']">
            <img class="mat-select-icon-new"
                 [src]="newVersionEnabled ? '../../../../../../../assets/icons/new/drag-drop.svg' : '../../../../../../../assets/icons/hand.svg'"
                 width="20"
                 height="20"
                 alt="Option image">
            <span
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.dragAndDrop' | transloco">
                {{ 'translations.dragAndDrop' | transloco }}</span>
          </mat-option>
          <mat-option value="GRID_QUESTION" *ngIf="activeTypes['GRID_QUESTION']">
            <img class="mat-select-icon-new"
                 src="../../../../../../../assets/icons/grid.svg"
                 width="20"
                 height="20"
                 alt="Option image">
            <span
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.grid' | transloco">
                {{ 'translations.grid' | transloco }}</span>
          </mat-option>
          <!-- info question -->
          <mat-option value="INFO_QUESTION" *ngIf="activeTypes['INFO_QUESTION']">
            <img class="mat-select-icon-new"
                 src="../../../../../../../assets/icons/info-circle.svg"
                 width="20"
                 height="20"
                 alt="Option image">
            <span
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.info' | transloco">
                {{ 'translations.info' | transloco }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngIf="newVersionEnabled">
      <div class="quiz-instruction-box" formGroupName="instruction">
        <h2 class="small bold title"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.newQuestion' | transloco">
          {{ 'translations.newQuestion' | transloco }}</h2>
        <ptl-editor
          class="quiz-instruction-textarea no-outline"
          [class.has-error]="questionEmpty"
          [class.focused]="ptlFocused"
          [link]="false"
          [upload]="false"
          [separator]="false"
          [content]="[content]"
          (inputFocus)="ptlFocused = true"
          (inputBlur)="ptlFocused = false"
          (contentUpdate)="onMediumEditorUpdate($event)">
        </ptl-editor>
        <div class="mat-error question-error" *ngIf="questionEmpty">
          {{ 'translations.questionRequiredError' | transloco }}
        </div>
      </div>
    </ng-container>

    <div class="add-question__section" [class.add-question__section--padding]="!newVersionEnabled">
      <ptl-group-question-type
        *ngIf="questionForm.value.type === 'INFO_QUESTION'"
        [question]="questionForm"
        (mediumEditorChanged)="onInfoQuestionValueChange($event)">
      </ptl-group-question-type>

      <ptl-grid-question-type-new
        *ngIf="newVersionEnabled && questionForm.value.type === 'GRID_QUESTION'"
        [languageCode]="languageCode"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [question]="questionForm">
      </ptl-grid-question-type-new>

      <ptl-grid-question-type
        *ngIf="!newVersionEnabled && questionForm.value.type === 'GRID_QUESTION'"
        [languageCode]="languageCode"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [question]="questionForm">
      </ptl-grid-question-type>

      <ptl-slider-question-type
        *ngIf="questionForm.value.type === 'SLIDER_QUESTION'"
        [isQuiz]="isQuiz"
        [newVersionEnabled]="newVersionEnabled"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [languageCode]="languageCode"
        [question]="questionForm">
      </ptl-slider-question-type>

      <ptl-drag-drop-question-type
        *ngIf="questionForm.value.type === 'DRAGDROP_QUESTION'"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [languageCode]="languageCode"
        [isQuiz]="isQuiz"
        [newVersionEnabled]="newVersionEnabled"
        [question]="questionForm">
      </ptl-drag-drop-question-type>

      <ptl-choice-question-type
        *ngIf="questionForm.value.type === 'CHOICE_QUESTION'"
        [languageCode]="languageCode"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [isEditingForm]=isEditingQuestion
        [newVersionEnabled]="newVersionEnabled"
        [question]="questionForm">
      </ptl-choice-question-type>

      <ptl-app-true-or-false-choice-question-type
        *ngIf="questionForm.value.type === 'RATING_QUESTION'"
        [languageCode]="languageCode"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [newVersionEnabled]="newVersionEnabled"
        [question]="questionForm">
      </ptl-app-true-or-false-choice-question-type>

      <ptl-multiple-choice-question-type
        *ngIf="questionForm.value.type === 'MULTIPLE_CHOICE_QUESTION'"
        [isDefaultLanguage]="currentLanguage?.isDefault"
        [languageCode]="languageCode"
        [newVersionEnabled]="newVersionEnabled"
        [question]="questionForm">
      </ptl-multiple-choice-question-type>
    </div>

    <div class="add-question__section add-question__section--padding" formGroupName="feedback"
         *ngIf="isQuiz && !newVersionEnabled">
      <div class="feedback-container-title">
        <h2
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.feedback' | transloco">
          {{ 'translations.feedback' | transloco }}</h2>
        <button mat-icon-button
                type="button"
                *ngIf="editableQuestion?.uid"
                class="clear-button"
                tabindex="0"
                [attr.aria-label]="('translations.global.ariaLabels.clear' | transloco: {title: ''})"
                (click)="clearFeedback()">
          <i class="ptl-icon-trash-can" aria-hidden="true"></i>
        </button>
      </div>
      <div class="add-question__section-field add-question__section-field--extended no-padding border"
           [class.focused]="ptlFeedbackFocused">
        <ptl-editor
          class="add-question__section__rich no-outline"
          [link]="false"
          [disableAutoFocus]="true"
          [upload]="false"
          [separator]="false"
          [placeholder]=""
          [content]="feedback.dynamicContentRequest"
          (inputFocus)="ptlFeedbackFocused = true"
          (inputBlur)="ptlFeedbackFocused = false"
          (contentUpdate)="onFeedbackUpdate($event, feedback)">
        </ptl-editor>
      </div>
    </div>

    <div class="feedback-container" formGroupName="feedback" *ngIf="isQuiz && newVersionEnabled">
      <div class="feedback-container-title">
        <h2 class="small bold title" tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.feedback' | transloco">
          {{ 'translations.feedback' | transloco }}
        </h2>
        <div class="extra-small">
          {{ 'translations.feedbackDescription' | transloco }}
        </div>
        <button mat-icon-button
                type="button"
                class="clear-button"
                *ngIf="editableQuestion?.uid"
                tabindex="0"
                [attr.aria-label]="('translations.global.ariaLabels.clear' | transloco: {title: ''})"
                (click)="clearFeedback()">
          <svg class="svg svg-icon" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
          </svg>
        </button>
      </div>
      <ptl-editor
        class="feedback-container-textarea no-outline"
        [class.focused]="ptlFeedbackFocused"
        [link]="false"
        [disableAutoFocus]="true"
        [upload]="false"
        [separator]="false"
        [placeholder]=""
        [content]="feedback.dynamicContentRequest"
        (inputFocus)="ptlFeedbackFocused = true"
        (inputBlur)="ptlFeedbackFocused = false"
        (contentUpdate)="onFeedbackUpdate($event, feedback)">
      </ptl-editor>
    </div>


    <ng-container *ngIf="!isQuiz">
      <div class="add-question__section add-question__section--padding bottom-section"
           [class.is_disabled]="!currentLanguage?.isDefault"
           *ngIf="questionForm.value.type !== 'INFO_QUESTION'">
        <h2
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.mapQuestionToLearningGoal' | transloco">
          {{ 'translations.mapQuestionToLearningGoal' | transloco }}</h2>

        <ng-container *ngIf="!newVersionEnabled">
          <div class="add-question__tags" *ngIf="!!questionTags.length">
            <div class="add-question__tags-item" *ngFor="let tag of questionTags; let i = index">
              <div class="bold"
                   tabindex="0"
                   [attr.aria-label]="tag?.title">
                {{ tag?.title }}</div>
              <span
                tabindex="0"
                class="add-question__tags-remove"
                matTooltip="Remove tag"
                [attr.aria-label]="('translations.global.ariaLabels.removeTag' | transloco)"
                (click)="deleteTag(i)"
                (keydown.enter)="deleteTag(i)">
              <mat-icon aria-hidden="true" [inline]="true">clear</mat-icon>
            </span>
            </div>
          </div>
          <div class="add-question-tags__framework-container" *ngIf="!frameworkTagsPanelOpen">
            <button
              mat-button
              class="add-question__section-add-new gray"
              type="button"
              [attr.aria-label]="('translations.global.ariaLabels.broseFrameworksTag' | transloco)"
              (click)="onToggleFrameworkTagsPanel(true)">
            <span
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.browse' | transloco">
              {{ 'translations.framework.button.browse' | transloco }} </span>
              <span class="add-question-tags__framework-btn-inner"
                    tabindex="0"
                    [attr.aria-label]="'translations.global.ariaLabels.frameworks' | transloco">
              {{ 'translations.framework.button.frameworks' | transloco }}
            </span>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="newVersionEnabled">

          <div class="chips-list">
            <div class="chip-item has-action-btn chip-interactive"
                 [matTooltip]="tag.title"
                 *ngFor="let tag of questionTags; let i = index">
              <span class="chip-text">{{ tag.title }}</span>

              <span
                tabindex="0"
                class="chip-item-icon"
                [attr.aria-label]="('translations.global.ariaLabels.removeTag' | transloco)"
                (click)="deleteTag(i)"
                (keydown.enter)="deleteTag(i)">
                <svg class="svg" role="img" aria-hidden="true">
                  <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
                </svg>
              </span>
            </div>
          </div>

          <button
            mat-stroked-button
            type="button"
            color="potentially-secondary"
            class="add-frameworks-button"
            tabindex="0"
            [attr.aria-label]="('translations.global.ariaLabels.addFromFramework' | transloco)"
            (click)="onToggleFrameworkTagsPanel(true)">
            <svg class="svg svg-icon icon-left" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#framework"></use>
            </svg>
            <span>{{ 'translations.framework.button.framework' | transloco }}</span>
          </button>
        </ng-container>

      </div>
    </ng-container>

    <ptl-add-framework-tags
      class="add-framework-tags-container"
      *ngIf="frameworkTagsPanelOpen"
      [momentTags]="questionTags"
      [isDialogPage]="true"
      (closeFrameworkTags)="onToggleFrameworkTagsPanel($event)"
      (addTagBulk)="onAddTagBulk($event)">
    </ptl-add-framework-tags>


    <div class="add-question__footer" *ngIf="!newVersionEnabled">
      <div class="add-question__footer-right">
        <button
          mat-button
          class=""
          type="button"
          title="Remove element"
          [attr.aria-label]="('translations.global.ariaLabels.closeQuestion' | transloco)"
          (click)="onCloseForm()">{{ 'translations.global.button.cancel' | transloco }}
        </button>
        <button
          mat-flat-button
          class="uppercase"
          color="primary"
          title="Save"
          type="submit"
          [attr.aria-label]="('translations.global.ariaLabels.appleQuestion' | transloco)">{{ 'translations.global.button.apply' | transloco }}
        </button>
      </div>
    </div>

    <div class="add-question-footer-box" *ngIf="newVersionEnabled">

      <button
        mat-stroked-button
        class="remove-button small"
        type="button"
        [attr.aria-label]="('translations.global.ariaLabels.closeQuestion' | transloco)"
        (click)="onCloseForm()">
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
        </svg>
        <span>{{ 'translations.global.button.cancel' | transloco }}</span>
      </button>

      <button
        mat-stroked-button
        class="save-button small"
        title="Save"
        type="submit"
        [attr.aria-label]="('translations.global.ariaLabels.appleQuestion' | transloco)">
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
        </svg>
        <span>{{ 'translations.global.button.apply' | transloco }}</span>
      </button>
    </div>
  </form>

  <div
    tabindex="0"
    [attr.aria-label]="'translations.global.ariaLabels.closeForm' | transloco"
    class="add-question__form-backdrop" (click)="onCloseForm()" (keydown.enter)="onCloseForm()"></div>
</div>
