/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  GeneratedReport,
  GeneratedReportConditionalSection,
  GeneratedReportSection,
  GeneratedReportStandardSection,
  Organization
} from '../../../../../../shared/models';
import { UserAuthState } from '../../../../../../user-auth/store/user-auth.state';
import {
  DEFAULT_LANGUAGE_CODE
} from '../../../../../../shared/services/translation/translation.service';
import { ChartContent } from '../../../../../../shared/models/editor/chart-content.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DIAGNOSTICS_DATA_SERVICE, DiagnosticsDataService } from '../../../../services/data.service';
import { MemberRecord } from '../../../../../../shared/models/admin/members.model';

@Component({
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss'],
})
export class DiagnosticsViewReportDialogComponent implements OnDestroy {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @ViewChild('reportPdf') reportPdf: ElementRef<HTMLElement>;

  completedData: Date;
  downloadInProgress = false;
  reportMode: string;
  report: GeneratedReport;
  reportSections: GeneratedReportSection[] = [];
  member: MemberRecord;

  private currentLanguage = DEFAULT_LANGUAGE_CODE;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { assessmentUid: string; responsesUid: string; memberData: MemberRecord },
    @Inject(DIAGNOSTICS_DATA_SERVICE) private diagnosticsDataService: DiagnosticsDataService,
    private dialogRef: MatDialogRef<DiagnosticsViewReportDialogComponent>,
  ) {
    if(data) {
      this.member = data.memberData;
      this.diagnosticsDataService.getReport(data.assessmentUid, data.responsesUid, this.currentLanguage)
        .subscribe(( { isSuccess, value } ) => {
          if(isSuccess && value) {
            this.report = value;
            this.reportMode = this.report.reportMode;
            this.reportSections = this.report.sections;
            this.setSectionChartColors(this.reportSections);
            this.completedData = new Date();
          }
        })
    }
  }

  sectionTitle( section: GeneratedReportSection ) {
    if(section.type === 'STANDARD_SECTION') {
      const tempElement = document.createElement('div');
      tempElement.innerText = '...';

      for ( const sectionContent of (section as GeneratedReportStandardSection).dynamicContent ) {
        if(typeof sectionContent.content === 'string') {
          tempElement.innerHTML = sectionContent.content;
          break;
        }
      }

      const content = tempElement.textContent || tempElement.innerText || '';

      if(content.length > 30) {
        return content.substring(0, 30) + '...';
      }

      return content;
    } else {
      return (section as GeneratedReportConditionalSection).learningGoalWrapper.tagTitle;
    }
  }

  scrollTo( section: GeneratedReportSection ) {
    const element = document.getElementById(section.uid);
    if(element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  trackByFn( index: number, { uid } ) {
    return uid || index;
  }

  back(): void {
    document.body.classList.remove('dialog-print');
    this.dialogRef.close();
  }

  downloadReport(): void {
    document.body.classList.add('dialog-print');
    window.print();
  }

  ngOnDestroy() {
    document.body.classList.remove('dialog-print');
  }

  private setSectionChartColors( sections: GeneratedReportSection[] ) {
    const standardSections = sections.filter(item => item.type === 'STANDARD_SECTION') as GeneratedReportStandardSection[];

    let chartContent = null;
    for ( const section of standardSections ) {
      for ( const content of section.dynamicContent ) {
        if(content.type === 'CHART') {
          chartContent = content;
          break;
        }
      }
      if(chartContent) {
        break;
      }
    }
    if(!chartContent) {
      return;
    }

    this.setConditionalSectionColors(chartContent, sections);
  }

  private setConditionalSectionColors( chartContent: ChartContent, sections: GeneratedReportSection[] ) {
    const colors = {};
    chartContent.items.map(item => {
      colors[item.itemUid] = item.color;
    });
    sections.map(section => {
      if(section.type === 'CONDITIONAL_SECTION') {
        (section as GeneratedReportConditionalSection).chartColor =
          colors[(section as GeneratedReportConditionalSection).learningGoalWrapper.tagUid];
      }
    });
  }

}
