<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<section class="report-section" *ngIf="cards.length" [class.first]="isFirst" [id]="section?.uid">

  <div class="resource-cards-container">
    <swiper #swiperComponent [config]="swiperConfig" (slideChange)="onSlideChange($event)">
      <ng-template swiperSlide *ngFor="let card of cards; index as i; trackBy: trackByFn;">
        <ptl-resource-section-card [card]="card" [cardIndex]="i"></ptl-resource-section-card>
      </ng-template>
    </swiper>
    <button class="slider-arrow arrow-prev arrow-{{ sectionIndex }}-prev"
            [attr.aria-label]="('translations.global.ariaLabels.icons.slidePrev' | transloco)">
      <mat-icon aria-hidden="true">navigate_before</mat-icon>
    </button>
    <button class="slider-arrow arrow-next arrow-{{ sectionIndex }}-next"
            [attr.aria-label]="('translations.global.ariaLabels.icons.slideNext' | transloco)">
      <mat-icon aria-hidden="true">navigate_next</mat-icon>
    </button>
    <mat-spinner class="loading-more" *ngIf="isLoadingMore" [diameter]="20"></mat-spinner>
  </div>

</section>
