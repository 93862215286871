/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AddMomentToFolio,
  RemoveMomentFromFolio, UpdateMoment
} from '../../../../../store/moment/moment-view-all/moment-view-all.actions';
import { Store } from '@ngxs/store';
import { MomentCard } from '../../../../../models';

@Component({
  selector: 'ptl-masonry-action-button',
  templateUrl: './masonry-action-button.component.html',
  styleUrls: ['./masonry-action-button.component.scss'],
})
export class MasonryActionButtonComponent {

  /** Moment card. */
  @Input() card: MomentCard;

  @Input() archiveMoment: boolean;

  /** Emits when a card is removed. */
  @Output() cardRemoved = new EventEmitter<string>();

  @Output() cardArchived = new EventEmitter<string>();

  constructor(private store: Store) {
  }

  removeCard() {
    this.cardRemoved.emit(this.card._id);
  }

  archiveCard() {
    this.cardArchived.emit(this.card._id);
  }

  onEditClick() {
    this.store.dispatch(new UpdateMoment(this.card._id));
  }

  onAddToFolioClick() {
    this.store.dispatch(new AddMomentToFolio(this.card._id));
  }

  onRemoveMomentFromFolioClick() {
    this.store.dispatch(new RemoveMomentFromFolio(this.card._id));
  }
}
