/*
 * Copyright (C) 2021 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import * as moment from 'moment';

export class LocalTimeHelper {
  static getLocalDateTime(date: Date) {
    return moment.utc(date).local();
  }
}
