import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription, switchMap } from 'rxjs';

@Injectable()
export class RequestQueueManagerService<T> implements OnDestroy {
    private requestQueue: (() => Observable<T>)[] = [];
    private isProcessing = false;
    private requestSubject = new Subject<void>();
    private subscription: Subscription;

    constructor() {
        this.requestSubject.pipe(
			switchMap(() => this.executeNextRequest())
		).subscribe();
    }

    public executeNextRequest(): Observable<T> {
        if (this.requestQueue.length === 0) {
            this.isProcessing = false;
            return new Observable<T>(observer => observer.complete());
        }

        this.isProcessing = true;
        const request = this.requestQueue.shift();
        return request();
    }

    private scheduleNextRequest(): void {
        if (!this.isProcessing) {
            this.requestSubject.next();
        }
    }

    public addRequest(request: () => Observable<T>): void {
        this.requestQueue.push(request);
        this.scheduleNextRequest();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
