<div class="toast-container">
  <ptl-toast
    *ngFor="let toast of toastService.getToastList()"
    [type]="toast.type"
    [message]="toast.message"
    [createdAt]="toast.createdAt"
    [header]="toast.header"
    [notification]="toast.notification"
    [delay]="toast.delay"
  ></ptl-toast>
</div>
