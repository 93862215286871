/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { SubheaderDataService } from './subheader-data.service';
import { ResourceSubheaderUpdateRequest } from '../../models';

@Injectable()
export class MockSubheaderDataService implements SubheaderDataService {
  updatePlaylistSubheader(playlistUid: string, subheader: ResourceSubheaderUpdateRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
  updateCardSubheader(cardUid: string, subheader: ResourceSubheaderUpdateRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
