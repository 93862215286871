<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="edit-role-dialog-content">

  <div class="dialog-top-info">
    <h1 class="dialog-title title">{{'translations.membersNew.actionBar.editRoleDialog.title' | transloco}}</h1>
    <p class="dialog-description medium">{{'translations.membersNew.actionBar.editRoleDialog.description' | transloco: {checkedMembersCount: checkedMemberIds.length} }}</p>
    <button class="close-dialog-btn"
            mat-icon-button color="primary"
            (click)="closeDialog()"
            [attr.aria-label]="('translations.global.ariaLabels.closeDialog' | transloco)">
      <svg class="svg" role="img" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div class="edit-role-content">
    <div class="edit-role__search-roles-box">
      <ptl-role-search-autocomplete
        #roleSearchAutocomplete
        class="search-field"
        [roles]="searchedRoles"
        [loadingRoles]="loadingRoles"
        (searchInputChanged)="onSearchInputChange($event)"
        (searchLoadedMore)="onSearchLoadingMore()"
        (roleSelected)="onRoleSelected($event)">
      </ptl-role-search-autocomplete>
    </div>

    <div class="edit-role__selected-roles-chips-box" *ngIf="selectedRoles?.length">
      <div class="chips-list">
        <div *ngFor="let role of selectedRoles"
          class="content-view-tag-item chip-item chip-interactive pointer"
          [matTooltip]="getNameOfRole(role.name)"
          (click)="removeSelectedRole(role)"
          (keydown.enter)="removeSelectedRole(role)">
          <span class="chip-text">{{ getNameOfRole(role.name) }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="edit-role-buttons-row">
    <button mat-button (click)="closeDialog()">
      <span class="small bold">{{ 'translations.global.button.cancel' | transloco }}</span>
    </button>
    <button mat-button (click)="removeRoles()" class="suggested">
      <span class="small bold">{{'translations.membersNew.actionBar.editRoleDialog.removeRoles' | transloco}}</span>
    </button>
    <button mat-button (click)="addRoles()" class="suggested">
      <span class="small bold">{{'translations.membersNew.actionBar.editRoleDialog.addRolesBtnText' | transloco}}</span>
    </button>
  </div>

</div>
