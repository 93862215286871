/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import {
  EditorContent, MediaType,
  MediaUploadData
} from '../../../../../../shared/models';
import {
  EMBEDS_CONTENT_GENERATOR_SERVICE,
  EmbedsContentGeneratorService
} from '../../../../../../editor/services/embeds-content-generator.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropDialogComponent } from '../../../../../../shared/components/image-crop-dialog/image-crop-dialog.component';
import { LanguageCodeHelper } from '../../../../../../shared/helpers/language-code-helper';

@Component({
  selector: 'ptl-moment-actions',
  templateUrl: './moment-actions.component.html',
  styleUrls: ['./moment-actions.component.scss'],
})
export class MomentActionsComponent {

  /** Emits the iframely content added(either uploaded or URL). */
  @Output() mediaContentAdded = new EventEmitter<EditorContent>();
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  inputActive = false;
  filesHovered = false;

  constructor(
    @Inject(EMBEDS_CONTENT_GENERATOR_SERVICE) public embedsService: EmbedsContentGeneratorService,
    private dialog: MatDialog
  ) { }

  showInput() {
    this.inputActive = true;
  }

  onFilesDropped(fileList: FileList) {
    this.filesHovered = false;
    this.emitAddedFiles(fileList);
  }

  onFileChanged(eventData: Event) {
    this.emitAddedFiles((eventData.target as HTMLInputElement).files);
  }

  private emitAddedFiles(fileList: FileList, i = 0) {
    if (i < fileList.length) {
      const mediaUploadData = {
        type: 'MEDIA_UPLOAD',
        name: fileList[i].name,
        file: fileList[i],
        position: 'CENTER',
        mediaType: this.getFileType(fileList[i]),
        main: true,
      } as MediaUploadData;

      if (mediaUploadData.mediaType === 'IMAGE') {
        const dialogRef = this.dialog.open(ImageCropDialogComponent, {
          width: '90vw',
          minWidth: '15.625rem',
          maxWidth: '37.5rem',
          maxHeight: '80vh',
          position: {
            top: '10vh',
          },
          direction: LanguageCodeHelper.getBodyLanguageDir(),
          panelClass: ['f_moment-dialog', 'ptl-mat-dialog'],
          backdropClass: 'dialog-backdrop',
          data: {
            imageFile: fileList[i],
            cropperShape: 'rectangle',
            aspectRatio: 1.57,
          },
        });

        const dialogSubscription = dialogRef.afterClosed().subscribe((data) => {
          if (data) {
            const croppedImageFile: File = data.croppedImageFile;
            mediaUploadData.file = croppedImageFile;
            mediaUploadData.name = croppedImageFile.name;
            this.mediaContentAdded.emit(mediaUploadData);
          }

          this.emitAddedFiles(fileList, i + 1);
          dialogSubscription.unsubscribe();
        });
      } else {
        this.mediaContentAdded.emit(mediaUploadData);
        this.emitAddedFiles(fileList, i + 1);
      }
    } else {
      this.inputActive = false;
      (this.fileInput.nativeElement as HTMLInputElement).value = '';
    }
  }

  private getFileType(file: File): MediaType {
    if (file.type.match('image.*')) {
      return 'IMAGE';
    }
    if (file.type.match('video.*')) {
      return 'STREAM_VIDEO';
    }
    if (file.type.match('audio.*')) {
      return 'AUDIO';
    }
    return 'DOCUMENT';
  }

}
