/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Component, Inject, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../services/account-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { AUTH_SERVICE, BasicAuthService } from '../../../../user-auth/services/basic-auth.service';
import { DialogService } from '../../../../shared/helpers/dialog/dialog.service';
import { SnackbarHelper } from '../../../../shared/helpers/snackbar-helper';
import { TranslationService } from '../../../../shared/services/translation/translation.service';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { Organization } from '../../../../shared/models';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  templateUrl: './add-workspaces.component.html',
  styleUrls: ['./add-workspaces.component.scss'],
})

export class AddWorkspacesComponent {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;


  addWorkspaceForm: FormGroup;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddWorkspacesComponent>,
    private ngZone: NgZone,
    private dialogService: DialogService,
    private translationService: TranslationService,
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    @Inject(AUTH_SERVICE) private authService: BasicAuthService
  ) {

    this.addWorkspaceForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }


  submitUserEmail() {
    if (this.addWorkspaceForm.valid) {
      const email = this.addWorkspaceForm.get('email')?.value;
      this.connectUserToWorkspace(email);
    }
  }

  private connectUserToWorkspace(email: string) {
    this.isLoading = true;
    this.accountService.connectUserToWorkspace(email).subscribe(({ isSuccess, error }) => {
      this.isLoading = false;
      if (isSuccess) {
        this.dialogService.showAlertDialog(
          this.translationService.getTranslation('verifyYourAccount'),
          this.translationService
        );
        this.dialogRef.close();
      } else {
        if ((error as unknown as HttpErrorResponse).status === 404) {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'account.workspaces.message.error.AccountNotFound'
          );
          return;
        }
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone, this.snackBar, this.translationService, 'account.workspaces.message.error.addingWorkspaceError'
        );
      }
    });
  }
}
