import { UrlMatchResult, UrlSegment } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EnvironmentCode } from 'src/environments/environment.model';

export const FOLIO_PATHS = {
  edit: 'edit',
  moments: 'moments',
  logs: 'logs',
}

export const FOLIO_PREFIX = getFolioPrefix();

export function getFolioPrefix(): string {
  return environment.env === EnvironmentCode.prod ? 'https://my.potential.ly/folio/'
    : 'https://my.yuna.dev.potential.ly/folio/';
}

export const FOLIO_BADGE_PREFIX = getFolioBadgePrefix();

export function getFolioBadgePrefix(): string {
  return environment.env === EnvironmentCode.prod ? 'https://my.potential.ly/folio/badge/'
    : 'https://my.yuna.dev.potential.ly/folio/badge/';
}

export function isPublicFolioUrl(pathname: string): boolean {
  return pathname.includes('/folio/') && (!Object.values(FOLIO_PATHS).some(
    folioPath => document.location.pathname.includes(folioPath))
  );
}

export function publicFolioMatcher(url: UrlSegment[]): UrlMatchResult {
  if (url.length === 2) {
    if (url[0].path === 'folio' && !Object.values(FOLIO_PATHS).includes(url[1].path)) {
      return {
        consumed: [url[0]],
        posParams: { publicId: url[1] }
      }
    }
  }
  return null;
}
