<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="like-info-container">
  <button
    mat-button
    tabindex="0"
    class="like-info-button"
    [title]="!editMode ? ((vote ? 'Unlike ' : 'Like ') + title) : 'Vote disabled'"
    [disabled]="disabled"
    [attr.aria-label]="!(vote && !editMode) ? ('translations.global.ariaLabels.icons.thumbUp' | transloco) : ('translations.global.ariaLabels.icons.thumbUpActive' | transloco)"
    (click)="onVote(); $event.stopPropagation();"
    (keydown.enter)="$event.stopPropagation();">

    <svg class="svg" role="img" aria-hidden="true"
         *ngIf="!(vote && !editMode)">
      <use ogSvgUnify="assets/sprite/sprite.svg#thumb-up"></use>
    </svg>

    <svg class="svg" role="img" aria-hidden="true"
         *ngIf="(vote && !editMode)">
      <use ogSvgUnify="assets/sprite/sprite.svg#thumb-up-active"></use>
    </svg>

    <span class="like-count extra-small" aria-hidden="true" *ngIf="totalLikes">
        {{ totalLikes }}
      </span>
  </button>
</div>
