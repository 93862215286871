/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, Inject } from '@angular/core';
import { FolioNewMomentSectionComponent } from '../new-moment-section.component';
import {
  EMBEDS_CONTENT_GENERATOR_SERVICE,
  EmbedsContentGeneratorService
} from '../../../../../../editor/services/embeds-content-generator.service';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../../../../../shared/services/tags/tag-data.service';

@Component({
  selector: 'ptl-new-moment-section-dialog',
  templateUrl: './new-moment-section-dialog.component.html',
  styleUrls: ['./new-moment-section-dialog.component.scss'],
})
export class NewMomentSectionDialogComponent extends FolioNewMomentSectionComponent {

  constructor(
    @Inject(EMBEDS_CONTENT_GENERATOR_SERVICE) protected override embedsService: EmbedsContentGeneratorService,
    @Inject(CORE_TAG_DATA_SERVICE) protected override tagService: TagDataService,
    private componentContainer: ElementRef
  ) {
    super(embedsService, tagService);
  }

  changeDialogSizeMode(mode: string) {
    if (mode === 'large') {
      this.expandDialog();
    } else {
      this.shrinkDialog();
    }
  }

  private expandDialog() {
    const dialogElement = (document.querySelector('.f_moment-dialog') as HTMLElement);
    dialogElement.style.width = '73.75rem';
    dialogElement.style.height = '80vh';
    this.focusMediumEditor();
  }

  private shrinkDialog() {
    const dialogElement = (document.querySelector('.f_moment-dialog') as HTMLElement);
    dialogElement.style.width = '37.5rem';
    dialogElement.style.height = 'auto';
    this.focusMediumEditor();
  }

  private focusMediumEditor() {
    let editorElement = this.componentContainer.nativeElement.querySelector('.medium-editor-element') as HTMLElement;
    if (!editorElement) {
      editorElement = this.componentContainer.nativeElement.querySelector('.mce-content-body') as HTMLElement;
    }
    editorElement?.focus();
  }
}
