<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="!newVersionEnabled">
  <div class="quiz-settings">
    <div
      tabindex="0"
      class="quiz-settings__header"
      [class.quiz-settings__header--expanded]="expanded"
      (click)="expanded = !expanded"
      [attr.aria-label]="('translations.global.ariaLabels.expandQuizSettings' | transloco)"
      (keydown.enter)="expanded = !expanded">
      <span class="bold-text">{{ 'translations.quiz.settings.title.settings' | transloco }}</span>
      <span *ngIf="!expanded"
            class="light-text">{{ 'translations.quiz.settings.passCriteria.description' | transloco: {passCriteriaString: passCriteriaString} }}</span>
      <mat-icon aria-hidden="true" *ngIf="!expanded" class="icon">chevron_right</mat-icon>
    </div>

    <div *ngIf="expanded" class="quiz-settings__body">

      <form [formGroup]="settingsForm">
        <div class="quiz-settings__pass-criteria">
          <div class="quiz-settings__body__row quiz-settings__pass-criteria-row">
            <div class="bold-text">{{ 'translations.quiz.settings.passCriteria.label' | transloco }}</div>
            <div>
              <span>{{ 'translations.quiz.settings.passCriteria.userMustAnswer' | transloco }}</span>
              &nbsp;<span
              class="link"
              tabindex="0"
              [attr.aria-label]="('translations.global.ariaLabels.openUserMustAnswerCriteria' | transloco)"
              (click)="passCriteriaPopupOpen = !passCriteriaPopupOpen; $event.stopPropagation()"
              (keydown.enter)="passCriteriaPopupOpen = !passCriteriaPopupOpen; $event.stopPropagation()">{{ passCriteriaString }}</span>&nbsp;
              <ptl-pass-criteria-popup
                *ngIf="passCriteriaPopupOpen"
                [value]="passCriteria.value"
                [questionsCount]="questionsCount"
                (selectedValue)="onCriteriaSelected($event)">
              </ptl-pass-criteria-popup>
              <span>{{ 'translations.quiz.settings.passCriteria.questionsCorrectly' | transloco }}</span>
            </div>
          </div>
          <div class="quiz-settings__body__row quiz-settings__pass-criteria-row">
            <div class="bold-text"></div>
            <div>
              <mat-slide-toggle color="primary"
                                class="count-towards-toggle"
                                [attr.aria-label]="'translations.global.ariaLabels.countTowards' | transloco"
                                [checked]="settings?.submissionCriteria?.mustPass"
                                (change)="onTowardsChange($event)">
                {{ 'translations.quiz.settings.passCriteria.countTowards' | transloco }}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="quiz-settings__body__row">
          <div class="bold-text">{{ 'translations.quiz.settings.retake.label' | transloco }}</div>
          <div>
            <mat-form-field class="quiz-settings__body__row__select no-padding" appearance="outline">
              <mat-select formControlName="retakeCriteria">
                <mat-option value="UNTIL_PASSED"
                            [innerHTML]="'translations.quiz.settings.retake.options.untilPassed' | transloco"></mat-option>
                <mat-option value="UNLIMITED"
                            [innerHTML]="'translations.quiz.settings.retake.options.unlimited' | transloco"></mat-option>
                <mat-option value="ONCE"
                            [innerHTML]="'translations.quiz.settings.retake.options.once' | transloco"></mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="quiz-settings__body__row">
          <div class="bold-text">{{ 'translations.quiz.settings.feedback.label' | transloco }}</div>
          <div>
            <mat-form-field class="quiz-settings__body__row__select no-padding" appearance="outline">
              <mat-select formControlName="resultDisplayPolicy">
                <mat-option value="SUMMARY"
                            [innerHTML]="'translations.quiz.settings.feedback.options.summary' | transloco"></mat-option>
                <mat-option value="DETAILED"
                            [innerHTML]="'translations.quiz.settings.feedback.options.detailed' | transloco"></mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
      <mat-icon tabindex="0"
                class="icon"
                [attr.aria-label]="('translations.global.ariaLabels.collapseQuizSettings' | transloco)"
                (click)="expanded=false"
                (keydown.enter)="expanded=false">keyboard_arrow_up
      </mat-icon>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="newVersionEnabled">
  <form [formGroup]="settingsForm">

    <div class="pass-criteria-box firts">
      <div class="title small bold">{{'translations.quiz.settings.title.whenQuizCompleted' | transloco}}</div>

      <mat-radio-group class="pass-criteria-options-list">
        <mat-radio-button
          class="pass-criteria-option"
          [checked]="settings?.submissionCriteria?.mustPass"
          (change)="onChangeCompletionMustPass(true)"
          value="true"
          color="primary"
          name="submissionCriteriaMustPass"
          tabIndex="0">
          <span class="small">{{'translations.quiz.settings.option.whenPassQuiz' | transloco}}</span>
        </mat-radio-button>

        <mat-radio-button
          class="pass-criteria-option"
          value="false"
          [checked]="!settings?.submissionCriteria?.mustPass"
          (change)="onChangeCompletionMustPass(false)"
          color="primary"
          name="submissionCriteriaMustPass"
          tabIndex="0">
          <span class="small">{{'translations.quiz.settings.option.whenCompleteQuizRegardlessResult' | transloco}}</span>
        </mat-radio-button>

      </mat-radio-group>
    </div>

    <div class="pass-criteria-box">
      <div class="title small bold">{{'translations.quiz.settings.title.whenSomeonePassQuiz' | transloco}}</div>

      <mat-radio-group class="pass-criteria-options-list">
        <mat-radio-button
          class="pass-criteria-option"
          [checked]="passCriteriaOption === 'ALL_CORRECT'"
          (change)="onPassCountChange('ALL_CORRECT')"
          value="true"
          color="primary"
          name="passCount"
          tabIndex="0">
          <span class="small">{{'translations.quiz.settings.option.whenAnswerAllCorrect' | transloco}}</span>
        </mat-radio-button>

        <mat-radio-button
          class="pass-criteria-option"
          value="false"
          [checked]="passCriteriaOption === 'PARTOF_CORRECT'"
          (change)="onPassCountChange('PARTOF_CORRECT')"
          color="primary"
          name="passCount"
          tabIndex="0">
          <span class="small">{{'translations.quiz.settings.option.whenAnswerPartCorrect' | transloco}}</span>
        </mat-radio-button>

        <ptl-pass-criteria-count
          class="pass-criteria-popup"
          *ngIf="showPassCriteriaOption"
          [value]="passCriteria.value"
          [questionsCount]="questionsCount"
          (selectedValue)="onCriteriaSelected($event)">
        </ptl-pass-criteria-count>

      </mat-radio-group>
    </div>

    <div class="pass-criteria-box">
      <div class="title small bold">{{ 'translations.quiz.settings.retake.label' | transloco }}</div>

      <mat-radio-group formControlName="retakeCriteria" class="pass-criteria-options-list">
        <mat-radio-button
          class="pass-criteria-option"
          value="UNTIL_PASSED"
          color="primary"
          name="passCriteriaRetake"
          tabIndex="0">
          <span class="small">{{ 'translations.quiz.settings.retake.options.untilPassed' | transloco }}</span>
        </mat-radio-button>

        <mat-radio-button
          class="pass-criteria-option"
          value="UNLIMITED"
          color="primary"
          name="passCriteriaRetake"
          tabIndex="0">
          <span class="small">{{ 'translations.quiz.settings.retake.options.unlimited' | transloco }}</span>
        </mat-radio-button>

        <mat-radio-button
          class="pass-criteria-option"
          value="ONCE"
          color="primary"
          name="passCriteriaRetake"
          tabIndex="0">
          <span class="small">{{ 'translations.quiz.settings.retake.options.once' | transloco }}</span>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="pass-criteria-box">
      <div class="title small bold">{{ 'translations.quiz.settings.feedback.label' | transloco }}</div>

      <mat-radio-group formControlName="resultDisplayPolicy" class="pass-criteria-options-list">
        <mat-radio-button
          class="pass-criteria-option"
          value="SUMMARY"
          color="primary"
          name="reportSummary"
          tabIndex="0">
          <span class="small">{{ 'translations.quiz.settings.feedback.options.summary' | transloco }}</span>
        </mat-radio-button>

        <mat-radio-button
          class="pass-criteria-option"
          value="DETAILED"
          color="primary"
          name="reportSummary"
          tabIndex="0">
          <span class="small">{{ 'translations.quiz.settings.feedback.options.detailed' | transloco }}</span>
        </mat-radio-button>

      </mat-radio-group>
    </div>
  </form>
</ng-container>
