/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { ObservableResult } from '../../store';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { SharedReviewDataService } from './review-data.service';
import { ReviewNoteUpdateRequest } from '../../models/admin/members.model';
import { Review } from '../../models/review/review.model';

@Injectable()
export class ApiSharedReviewDataService implements SharedReviewDataService {

  constructor(private client: RestClientService) {
  }

  reviewFormReSubmit(reviewId: string, cardUid: string, formUid: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewId}/cards/${cardUid}/submissions/${formUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error re submit review'))
    );
  }

  reviewApprove(reviewId: string, cardUid: string, itemId: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewId}/cards/${cardUid}/submissions/${itemId}/acceptance`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error approve review'))
    );
  }

  unlockForm(cardUid: string, formId: string, learnerCardUid: string): ObservableResult<void> {
    return this.client.delete<void>(Location.joinWithSlash(environment.apiRootUrl || '',
      `/cards/${cardUid}/progress/forms/${formId}/submissions?learnerCardId=${learnerCardUid}`
      )
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error unlock form'))
    );
  }

  reviewAddNote(reviewId: string, cardUid: string, itemUid: string, request: ReviewNoteUpdateRequest): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewId}/cards/${cardUid}/notes/${itemUid}`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error adding note to review'))
    );
  }

  remindToSubmitForm(reviewUid: string, cardUid: string, formUid: string, message: string): ObservableResult<void> {
    return this.client.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `reviews/${reviewUid}/cards/${cardUid}/remind_submission/${formUid}`),
      {
        message: message
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError('Error reminding to submit form'))
    );
  }
}
