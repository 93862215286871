/*
 * Copyright (C) 2024 - present by Potentially
 *
 * Please see distribution for license.
 */

import { RoleFilterTypes } from '@app/app/page-modules/explore/models';
import { SidebarSection } from '@app/app/shared/models/sidebar-tree/sidebar-tree.model';

export class ClearFilteringAndSetTermFilter {
  static readonly type = '[Explore] set explore term filter';

  constructor(public term: string) {
  }
}

export class SetExploreCategoryFilter {
  static readonly type = '[Explore] category filter';

  constructor(public categoryUid: string) {
  }
}

export class SetExploreRoleFilter {
  static readonly type = '[Explore] set explore role filter';

  constructor(public roleFilter: RoleFilterTypes) {
  }
}

export class ResetExploreState {
  static readonly type = '[Explore] reset state';
}

export class SetExploreSideBarSections  {
  static readonly type = '[Explore] set explore sidebar sections';

  constructor(public exploreSideBarSections: SidebarSection[]) {
  }
}

export class SetCategoryUidsQueryParam {
  static readonly type = '[Explore] set category uids query param';

  constructor(public categoryUidsQueryParam: string[]) {
  }
}

