/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { defaultLoadableState, LoadableState } from '../../../shared/store';
import { Resource, ResourceApprovalType } from '../../../shared/models';
import { CardCompletionRequirements } from '../../../shared/models/completion-logic/completion-logic.model';
import { LearnerCardStatus, LearnerFormAnswer } from '../models';

/** The learner view main state model. */
export interface LearnerViewStateModel {
  /** The content for the learner view. */
  resource: LoadableState<Resource>;
  completion: LoadableState<CardCompletionRequirements>;
  status: {
    learnerFormAnswers: LoadableState<LearnerFormAnswer[]>;
    cardStatuses: LoadableState<LearnerCardStatus[]>;
    startedOn: LoadableState<string>;
    updatedOn: LoadableState<string>;
    reviewStatus: LoadableState<ResourceApprovalType>;
  };
  currentLanguage: LoadableState<string>;
  cardOpened: LoadableState<boolean>;
}

/** Initial empty state of the lerner view. */
export const INITIAL_STATE: LearnerViewStateModel = {
  resource: defaultLoadableState(),
  completion: defaultLoadableState(),
  status: {
    learnerFormAnswers: defaultLoadableState(),
    cardStatuses: defaultLoadableState(),
    startedOn: defaultLoadableState(),
    updatedOn: defaultLoadableState(),
    reviewStatus: defaultLoadableState(),
  },
  currentLanguage: defaultLoadableState(),
  cardOpened: defaultLoadableState(),
};
