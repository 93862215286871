/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import {
  AssociateCategoryRequest,
  AssociateTagRequest,
  PlaylistCardLayoutUpdate,
  PlaylistCardOrderingUpdate,
  PlaylistCreationRequest,
  PlaylistPublicationStuffUpdateRequest,
  PlaylistThumbnailUpdateRequest,
  PlaylistUpdateRequest,
  PlaylistWebLinkCreationRequest
} from '../../../models';
import { PlaylistResourceModel } from '../../../models/playlist-resource.model';
import { ObservableResult } from '../../../../../shared/store';
import {
  Diagnostics, EnrollmentSettingsRequest, EventTicketsDetails,
  Playlist,
  PlaylistAssociateAdmin, PlaylistStandardSection,
  Resource,
  ResourceCardType,
  ResourcePermission
} from '../../../../../shared/models';
import { CreatePlaylistResponse } from './models/create-playlist-response';
import { UpdatePlaylistResponse } from './models/update-playlist-response';
import { ItemWithUploadUrl } from '../../../../../shared/models/ItemWithUploadUrl';
import { Page } from '../../../../../shared/models/page';
import { LearnerPlaylistSummary } from '../../../../../shared/models/playlist/learner-playlist-summary.model';
import { ContentStorePlaylistSummary } from '../../../../../shared/models/content-store/content-store.model';
import {
  PlaylistMemberMetrics,
  PlaylistsStatsSummary,
  PlaylistStatistics,
  StatsData
} from '../../../../../shared/models/analytics/analytics.model';
import { EventTicketsRequest } from '@app/app/page-modules/resource/store/admin/resource-event-admin.state.model';
import { MembersBooleanSearchRequest } from '@app/app/shared/models/admin/boolean-filters.model';


/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const CORE_PLAYLIST_DATA_SERVICE =
  new InjectionToken<PlaylistDataService>('[playlist-editor] CorePlaylistDataService');

/** The service handling playlist CRUD operations. */
export interface PlaylistDataService {

  createPlaylist(request: PlaylistCreationRequest, languageCode?: string): ObservableResult<CreatePlaylistResponse>;

  updatePlaylist(playlistUid: string, request: PlaylistUpdateRequest, languageCode?: string): ObservableResult<UpdatePlaylistResponse>;

  updatePlaylistUri(
    playlistUid: string,
    data: PlaylistPublicationStuffUpdateRequest,
  ): ObservableResult<void>;

  getPlaylistDetails(playlistUri: string, publisherUri: string, packageUri: string, pageUri: string, languageCode?: string):
    ObservableResult<Playlist>;

  deletePlaylist(playlistUid: string): ObservableResult<void>;

  clonePlaylist(playlistUid: string): ObservableResult<string>;

  getPlaylistSummaryByUid(playlistUid: string, languageCode?: string): ObservableResult<ContentStorePlaylistSummary>;

  associateTag(playlistUid: string, request: AssociateTagRequest): ObservableResult<PlaylistResourceModel>;

  dissociateTag(playlistUid: string, tagUid: string): ObservableResult<void>;

  associateFeedTag(playlistUid: string, request: AssociateTagRequest): ObservableResult<PlaylistResourceModel>;

  dissociateFeedTag(playlistUid: string, tagUid: string): ObservableResult<void>;

  dissociateAllFeedTags(playlistUid: string): ObservableResult<void>;

  associateCategory(playlistUid: string, request: AssociateCategoryRequest): ObservableResult<PlaylistResourceModel>;

  dissociateCategory(playlistUid: string, categoryTagUid: string): ObservableResult<void>;

  changeCardsLayout(playlistUid: string, sectionUid: string, requestBody: PlaylistCardLayoutUpdate): ObservableResult<void>;

  updatePlaylistSectionCards(playlistUid: string, sectionUid: string, requestBody: PlaylistCardOrderingUpdate);

  movePlaylistCardFromSections(playlistUid: string, fromSectionUid: string, toSectionUid, cardUid: string);

  addCardToPlaylist(playlistUid: string, sectionUid: string, cardUid: string, clone?: boolean): ObservableResult<string>;

  addWebLinkToPlaylistSection(playlistUid: string, sectionUid: string, requestBody: PlaylistWebLinkCreationRequest):
    ObservableResult<Resource>;

  removeCardFromSection(playlistUid: string, sectionUid: string, uid: string);

  associateAdmin(playlistUid: string, userUid: string): ObservableResult<PlaylistAssociateAdmin[]>;

  dissociateAdmin(playlistUid: string, userUid: string): ObservableResult<void>;

  changeAdminPermissions(playlistUid: string, userUid: string, permissions: ResourcePermission): ObservableResult<void>;

  getPlaylistStatistics(playlistUid: string, from: string, to: string, daysDiff: number): ObservableResult<PlaylistStatistics>;

  getPlaylistMetrics(playlistUid: string, request: MembersBooleanSearchRequest): ObservableResult<PlaylistMemberMetrics>;

  getPlaylistStartedStatistics(playlistUid: string, from: string, to: string): ObservableResult<StatsData[]>;

  getPlaylistCompletedStatistics(playlistUid: string, from: string, to: string): ObservableResult<StatsData[]>;

  getPlaylistStatisticsSummary(playlistUid: string): ObservableResult<PlaylistsStatsSummary>;

  enablePlaylistCollaboration(playlistUid: string): ObservableResult<void>;

  disablePlaylistCollaboration(playlistUid: string): ObservableResult<void>;

  enablePlaylistMemberCollaboration(playlistUid: string, userUid: string): ObservableResult<void>;

  disablePlaylistMemberCollaboration(playlistUid: string, userUid: string): ObservableResult<void>;

  /**
   * Creates a new card.
   */
  createEmptyCard(playlistUid: string, sectionUid: string): ObservableResult<Resource>;

  createEmptyAssessment(playlistUid: string, sectionUid: string): ObservableResult<Diagnostics>;

  createEmptyEventCard(playlistUid: string, sectionUid: string): ObservableResult<Resource>;

  createPlaylistSection(playlistUid: string): ObservableResult<PlaylistStandardSection>;

  createEmptyResourceInGroup(playlistUid: string, sectionUid: string, groupUid: string, type: ResourceCardType)
    : ObservableResult<Resource | Diagnostics>;

  createWeblinkInGroupCard(playlistUid: string, sectionUid: string, groupUid: string, requestBody: PlaylistWebLinkCreationRequest):
    ObservableResult<Resource>;

  deletePlaylistSection(playlistUid: string, sectionUid: string, cards: boolean): ObservableResult<void>;

  addPlaylistToPlaylist(playlistUid: string, sectionUid: string, cardUid: string, clone?: boolean): ObservableResult<void>;

  addPlaylistToPlaylistMainSection(playlistUid: string, cardUid: string): ObservableResult<void>;

  reorderPlaylistSections(playlistUid: string, request: { sections: string[] }): ObservableResult<void>;

  /**
   * Assign / Unassign user group to / from playlist target
   */

  addUserGroupToPlaylistTarget(playlistUid: string, userGroupUid: string): ObservableResult<void>;

  updateUserGroupToPlaylistTargetForceStatus(playlistUid: string, userGroupUid: string, force: boolean):
    ObservableResult<void>;

  removeUserGroupFromPlaylistTarget(playlistUid: string, userGroupUid: string): ObservableResult<void>;

  removeAllUserGroupsFromPlaylistTarget(playlistUid: string): ObservableResult<void>;

  addUserToPlaylistTarget(playlistUid: string, userUid: string): ObservableResult<void>;

  removeUserFromPlaylistTarget(playlistUid: string, userUid: string): ObservableResult<void>;

  removeAllUsersFromPlaylistTarget(playlistUid: string): ObservableResult<void>;

  addSectorToPlaylistTarget(playlistId: string, sectorCode: string): ObservableResult<void>;

  removeSectorFromPlaylistTarget(playlistId: string, sectorCode: string): ObservableResult<void>;

  removeAllSectorsFromPlaylistTarget(playlistId: string): ObservableResult<void>;

  changePlaylistThumbnail(
    playlistUid: string,
    request: PlaylistThumbnailUpdateRequest
  ): ObservableResult<ItemWithUploadUrl<PlaylistResourceModel>>;

  removePlaylistThumbnail(playlistUid: string): ObservableResult<void>;

  setPlaylistTimeRequired(playlistUid: string, timeRequired: number): ObservableResult<void>;

  getPlaylistTimeRequiredSuggestion(playlistUid: string, languageCode?: string): ObservableResult<number>;

  /**
   * Archive/Restore playlist
   */

  getArchivedPlaylists(page: number, size: number, term?: string): ObservableResult<Page<LearnerPlaylistSummary>>;

  archivePlaylist(playlistUid: string): ObservableResult<void>;

  addLanguage(playlistUid: string, languageCode: string): ObservableResult<void>;

  removeLanguage(playlistUid: string, languageCode: string): ObservableResult<void>;

  enableLanguage(playlistUid: string, languageCode: string): ObservableResult<void>;

  disableLanguage(playlistUid: string, languageCode: string): ObservableResult<void>;

  addDataForm(pageUid: string, dataFormUid: string, event: string): ObservableResult<void>;

  removeDataForm(pageUid: string, dataFormUid: string, event: string): ObservableResult<void>;

  /**
   * Enrollment - admin part
   */
  updateEnrollmentSettings(playlistUid: string, request: EnrollmentSettingsRequest): ObservableResult<void>;

  updateTickets(playlistUid: string, request: EventTicketsRequest): ObservableResult<EventTicketsDetails>;

  deleteEnrollmentSettings(playlistUid: string): ObservableResult<void>;

  /**
   * Enrollment - learner part
   */
  register(playlistUid: string): ObservableResult<void>;

  unregister(playlistUid: string): ObservableResult<void>;

  registerUserToPlaylist(playlistUid: string, userUid: string): ObservableResult<Resource>;

  unregisterUserToPlaylist(playlistUid: string, userUid: string): ObservableResult<Resource>;
}
