import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BooleanFilterType, CardHeaders, FormHeaders } from '../../../../../../shared/models/admin/members.model';
import {
  BooleanFilter,
  BooleanQueryRequest
} from '../../../../../../shared/models/admin/boolean-filters.model';

@Component({
  selector: 'ptl-member-filter',
  templateUrl: './member-filter.component.html',
  styleUrls: ['./member-filter.component.scss'],
})
export class MemberFilterComponent {

  @Input() isCardPage: boolean;
  @Input() isPlaylistPage: boolean;
  @Input() cardUid: string;
  @Input() playlistUid: string;
  @Input() cardHeaders: CardHeaders[];
  @Input() formHeaders: FormHeaders[];
  @Input() type: BooleanFilterType;
  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<BooleanFilter>();

  @Input() excludedCards: string[];
  @Input() excludedCardStatuses: string[];
  @Input() excludedFormsTitle: string[];
  @Input() excludedFormTitleStatuses: string[];
  @Input() excludedPlaylists: string[];
  @Input() excludedPlaylistStatuses: string[];
  @Input() excludedGroups: string[];
  @Input() excludedGroupStatuses: string[];
  @Input() excludedRoles: string[];
  @Input() excludedRoleStatuses: string[];
  @Input() excludedActivityStatuses: string[];
  @Input() excludedResourceStatuses: string[];
  @Input() excludedFormStatuses: string[];

  onSave(filter: BooleanFilter) {
    this.save.emit(filter);
  }

  onCancel() {
    this.cancel.emit();
  }
}
