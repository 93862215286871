<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<mat-card class="event-date__container">

  <div class="event-date__container-top">

    <div class="event-date__container-description">
      <h2 class="title large"
        [attr.aria-label]="('translations.global.ariaLabels.eventDateTitleNew' | transloco)">
        {{ 'translations.eventDateTitleNew' | transloco }}
      </h2>
      <p class="description small"
        [attr.aria-label]="('translations.global.ariaLabels.eventDateDescription' | transloco)">
        {{ 'translations.eventDateDescription' | transloco }}
      </p>
    </div>

    <div class="event-date-box">
      <div class="field-item">
        <div *ngIf="!endDateActive"
          class="field-title small bold"
          [attr.aria-label]="('translations.global.ariaLabels.eventDateDefault' | transloco)">
          {{ 'translations.eventDateDefault' | transloco }}
        </div>
        <div *ngIf="endDateActive"
          class="field-title small bold"
          [attr.aria-label]="('translations.global.ariaLabels.eventDateStart' | transloco)">
          {{ 'translations.eventDateStart' | transloco }}
        </div>
        <div class="datepicker-field" [class.is_disabled]="!editingEnabled" [class.error]="startedDateError || ticketsTimeError">
          <input
            [disabled]="!editingEnabled"
            matInput
            autocomplete="off"
            class="input-field"
            [attr.aria-label]="('translations.global.ariaLabels.enterStartDate' | transloco)"
            placeholder="10/10/2020"
            [(ngModel)]="startedDate"
            (ngModelChange)="onDateChanged(true)"
            (focus)="onDateInputFocus()"
            (blur)="onDateInputBlur()"
            [min]="minDate"
            [matDatepicker]="fromDatepicker">
          <mat-datepicker #fromDatepicker></mat-datepicker>
          <mat-datepicker-toggle
            class="datepicker-toggle"
            matSuffix [for]="fromDatepicker">
          </mat-datepicker-toggle>
        </div>
      </div>

      <div class="field-item">
        <div *ngIf="!endDateActive"
          class="field-title small bold"
          [attr.aria-label]="('translations.global.ariaLabels.eventTimeDefault' | transloco)">
          {{ 'translations.eventTimeDefault' | transloco }}
        </div>
        <div *ngIf="endDateActive"
          class="field-title small bold"
          [attr.aria-label]="('translations.global.ariaLabels.eventTimeStart' | transloco)">
          {{ 'translations.eventTimeStart' | transloco }}
        </div>
        <div class="select-field-box">
          <mat-select
            [disabled]="!editingEnabled"
            class="select-field small bold"
            [class.is_disabled]="!editingEnabled"
            [attr.aria-label]="('translations.global.ariaLabels.selectStartTime' | transloco)"
            [class.error]="timeError || startedTimeError || ticketsTimeError"
            [(ngModel)]="startedTime"
            (selectionChange)="onTimeUpdate(true)"
            placeholder="HH:MM">
            <mat-option [value]="time" *ngFor="let time of timeSelect">{{time}}</mat-option>
          </mat-select>
        </div>
      </div>
      <div>
        <button class="date-button" *ngIf="!endDateActive && editingEnabled"
          [attr.aria-label]="('translations.global.ariaLabels.eventDateAddEndDateButton' | transloco)"
          (click)="showEndDateForm()">
          <svg class="svg" role="img" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
          </svg>
          <span
            class="button_description">
            {{ 'translations.eventDateAddEndDateButton' | transloco }}
          </span>
        </button>
      </div>
    </div>

    <div class="event-date-box" *ngIf="endDateActive">
      <div class="field-item">
        <div
          class="field-title small bold"
          [attr.aria-label]="'translations.global.ariaLabels.eventDateEndDateLabel' | transloco">
          {{ 'translations.eventDateEndDateLabel' | transloco }}
        </div>
        <div class="datepicker-field" [class.is_disabled]="!editingEnabled" [class.error]="dateError">
          <input
            [disabled]="!editingEnabled"
            matInput
            autocomplete="off"
            class="input-field"
            [attr.aria-label]="('translations.global.ariaLabels.enterEndDate' | transloco)"
            placeholder="10/10/2020"
            [(ngModel)]="endedDate"
            (ngModelChange)="onDateChanged()"
            (focus)="onDateInputFocus()"
            (blur)="onDateInputBlur()"
            [min]="startedDate"
            [matDatepicker]="toDatepicker">
          <mat-datepicker #toDatepicker></mat-datepicker>
          <mat-datepicker-toggle
            class="datepicker-toggle"
            matPrefix
            [for]="toDatepicker">
          </mat-datepicker-toggle>
        </div>
      </div>

      <div class="field-item">
        <div
          class="field-title small bold"
          [attr.aria-label]="'translations.global.ariaLabels.eventDateEndTimeLabel' | transloco">
          {{ 'translations.eventDateEndTimeLabel' | transloco }}
        </div>
        <div class="select-field-box">
          <mat-select
            [disabled]="!editingEnabled"
            class="select-field small bold"
            [class.is_disabled]="!editingEnabled"
            [attr.aria-label]="('translations.global.ariaLabels.selectEndHour' | transloco)"
            [class.error]="timeError"
            [(ngModel)]="endedTime"
            (selectionChange)="onTimeUpdate()"
            placeholder="HH:MM">
            <mat-option [value]="time" *ngFor="let time of timeSelect">{{time}}</mat-option>
          </mat-select>
        </div>
      </div>
      <button *ngIf="endDateActive && editingEnabled"
        class="date-button-inline"
        [attr.aria-label]="'translations.global.ariaLabels.eventDateRemoveEndDateButton' | transloco"
        (click)="hideEndDateForm()">
        <mat-icon class="icon-display" aria-hidden="true">clear</mat-icon>
        <span class="button_description">
          {{ 'translations.eventDateRemoveEndDateButton' | transloco }}
        </span>
      </button>
    </div>

    <div class="event-date-box">
      <div class="field-item">
        <div
          class="field-title small bold"
          [attr.aria-label]="'translations.global.ariaLabels.eventDateTimezoneLabel' | transloco">
          {{ 'translations.eventDateTimezoneLabel' | transloco }}
        </div>
        <div class="select-field-box timezone">
          <mat-select
            [disabled]="!editingEnabled"
            class="select-field small bold"
            [class.is_disabled]="!editingEnabled"
            [attr.aria-label]="('translations.global.ariaLabels.selectTimezone' | transloco)"
            [matTooltip]="timeZonesMapList && timeZonesMapList[selectedTimezone]
                            ? timeZonesMapList[selectedTimezone] : null"
            [(ngModel)]="selectedTimezone"
            (selectionChange)="onTimeUpdate(true)">
            <mat-option [value]="timezone.value" *ngFor="let timezone of timeZones">{{timezone.title}}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>

    <div class="event-date_update-button-box" *ngIf="editingEnabled">
      <button
        [hidden]="enrollment"
        [disabled]="isButtonClicked || !dataHasUpdated"
        mat-flat-button
        class="event-date_cancel-button uppercase"
        [attr.aria-label]="('translations.global.ariaLabels.eventDateCancelButton' | transloco)"
        type="button"
        (click)="cancel()">
        <span>
          {{ 'translations.global.button.cancel' | transloco }}
        </span>
      </button>
      <button
        [disabled]="isButtonClicked || !dataHasUpdated"
        mat-flat-button
        class="event-date_update-button uppercase"
        [attr.aria-label]="('translations.global.ariaLabels.eventDateUpdateButton' | transloco)"
        type="button"
        (click)="update()">
        <span>
          {{ 'translations.global.button.update' | transloco }}
        </span>
      </button>
    </div>

  </div>
</mat-card>
