/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UserAuthState } from '../../../store/user-auth.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, takeWhile } from 'rxjs/operators';
import { environment } from '@app/environments/environment';
import { EnvironmentCode } from '@app/environments/environment.model';
import { Organization } from '@app/app/shared/models';
import { AUTH_SERVICE, BasicAuthService } from '../../../services/basic-auth.service';
import { InvisibleReCaptchaComponent, ReCaptchaV3Service, ScriptService } from 'ngx-captcha';
import { TriggerUserAccountCreation } from '../../../store/user-auth.actions';
import Swal from 'sweetalert2';
import { TranslationService } from '@app/app/shared/services/translation/translation.service';
import { getClientId } from '@app/app/shared/helpers/development-domains.helper';
import { DEFAULT_PASSWORD_REGEXP } from '@app/app/shared/constants/constants';
import { VersionHelper } from '@app/app/shared/helpers/version.helper';

@Component({
  selector: 'ptl-signup-details-form',
  templateUrl: './signup-details-form.component.html',
  styleUrls: ['./signup-details-form.component.scss'],
})
export class SignupDetailsFormComponent implements OnInit, OnDestroy {

  @Input() organizationData: Organization;
  @Input() invitationId: string;
  @Input() invitationToken: string;

  /** Emits on back icon click. */
  @Output() backClicked = new EventEmitter<void>();

  @Select(UserAuthState.isAccountCreationInProgress)
  accountCreationInProgress$: Observable<boolean>;

  @Select(UserAuthState.accountCreationError)
  accountCreationError$: Observable<string>;

  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;

  signupDetailsFormGroup: FormGroup;
  hasAffiliation = false;
  componentAlive = true;
  formSubmitted = false;
  showPasswordSelected = false;
  reCaptchaElement = null;
  passwordFocusState = false;
  reCaptchaSiteKeyV2 = environment.reCaptchaSiteKeyV2;

  affiliations = [
    { title: 'Student', key: 'STUDENTS' },
    { title: 'Staff', key: 'STAFF' },
  ];

  newVersionEnabled = VersionHelper.newVersionEnabled();

  constructor(
    private store: Store,
    private fb: FormBuilder,
    @Inject(AUTH_SERVICE) private authService: BasicAuthService,
    private captchaService: ReCaptchaV3Service,
    private scriptService: ScriptService,
    private cdr: ChangeDetectorRef,
    private translationService: TranslationService,
  ) {
    store.select(state => state.userAuth.accountCreationState.accountCreationSucceeded).pipe(
      filter(Boolean),
      takeWhile(() => this.componentAlive))
      .subscribe();
  }

  ngOnInit() {
    this.signupDetailsFormGroup = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.pattern(DEFAULT_PASSWORD_REGEXP)])],
      termsOfService: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    });

    if (this.organizationData?.type === 'EDUCATIONAL') {
      this.hasAffiliation = true;
      this.signupDetailsFormGroup.addControl('affiliation', new FormControl('', Validators.required));
    }

    if (this.invitationId) {
      this.authService.validateInvitationToken(this.invitationId, this.invitationToken)
        .subscribe(({ isSuccess, value }) => {
          if (isSuccess) {
            this.signupDetailsFormGroup.controls['email'].setValue(value.email);
          } else {
            this.onBackClicked();
          }
        });
    }
  }

  ngOnDestroy() {
    this.componentAlive = false;
  }

  openWhitelistDomains(event: MouseEvent) {
    event.preventDefault();
    const target = event.target as HTMLElement;
    if (target.closest('a') !== null) {
      this.openWhitelistDomainsPopup();
    }
  }

  onSignUpClick() {
    this.captchaElem.execute();
  }

  resetCaptcha(): void {
    this.cdr.detectChanges();
  }

  onBackClicked() {
    this.backClicked.next();
  }

  signInUsingLinkedIn() {
    this.redirectToIdpViaRealm('linkedin');
  }

  signInUsingGoogle() {
    this.redirectToIdpViaRealm('google');
  }

  async proceedIfValid(captchaResponseV2: string) {
    await this.submitForm(captchaResponseV2);
    setTimeout(() => {
      this.scriptService.cleanup();
      this.captchaElem.resetCaptcha();
    }, 1500);
  }

  private openWhitelistDomainsPopup() {
    let domainsList = '';
    for (const domain of this.organizationData.whitelist) {
      domainsList += `<li>${domain}</li>`;
    }
    const contentHtml = '<ul>' + domainsList + '</ul>';
    const title = this.translationService.getTranslation('whitelist.description.registerPermittedEmailsDescription');
    Swal.fire({
      title: title,
      position: this.organizationData.landingPage.layout === 'SPLIT_SCREEN' ? 'center-left' : 'center',
      html: contentHtml,
      customClass: {popup: 'whitelist-domains-popup'},
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: this.translationService.getTranslation('global.button.ok'),
    });
  }

  private redirectToIdpViaRealm(idp: string) {
    document.location.href =
      `${environment.realmUrl}protocol/openid-connect/auth?` +
      `client_id=${getClientId(this.organizationData?.domain)}&` +
      'response_type=token&' +
      `nonce=${environment.nonce}&` +
      `kc_idp_hint=${idp}&` +
      `redirect_uri=${this.getRedirectUri()}`;
  }

  private getRedirectUri() {
    if ([EnvironmentCode.dev, EnvironmentCode.prod].includes(environment.env) &&
      window.location.hostname.includes('localhost')) {
      return 'http://localhost:4200';
    }

    return `https://${this.organizationData?.domain}`;
  }

  private async submitForm(captchaResponseV2: string) {
    this.formSubmitted = true;
    this.scriptService.cleanup();
    if (this.signupDetailsFormGroup.valid) {
      const captchaActionName = this.invitationId && this.invitationToken ? 'invitationUserRegistration' : 'userRegistration';
      this.captchaService.execute(environment.reCaptchaSiteKeyV3, captchaActionName, (captchaResponseV3: string) => {
        const request = {
          email: this.signupDetailsFormGroup.get('email').value?.trim(),
          password: this.signupDetailsFormGroup.get('password').value?.trim(),
          firstName: this.signupDetailsFormGroup.get('firstName').value?.trim(),
          lastName: this.signupDetailsFormGroup.get('lastName').value?.trim(),
          captchaResponseV2: captchaResponseV2,
          captchaResponseV3: captchaResponseV3,
        }
        if (this.hasAffiliation) {
          const affiliation = this.signupDetailsFormGroup.get('affiliation').value;
          request['targetGroups'] = [affiliation];
        }
        this.store.dispatch(new TriggerUserAccountCreation(request, this.invitationId, this.invitationToken));
      });
    } else {
      this.signupDetailsFormGroup.get('termsOfService').markAsDirty();
    }
  }

}
