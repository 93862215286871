<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div class="editor_container f_editor-container">
  <ptl-editor-content-view
    #editorContentView
    [editorOptions]="editorOptions"
    [content]="content"
    [chartSectionUid]="chartSectionUid"
    [placeholder]="placeholder"
    [disableAutoFocus]="disableAutoFocus"
    [upload]="upload"
    [toolbarOpen]="toolbarOpen"
    [markInMemoryUploadsAsNotUploaded]="markInMemoryUploadsAsNotUploaded"
    [canRemoveItem]="canRemoveItem"
    [emptyContentDisable]="emptyContentDisable"
    [streamVideoBytePlaceholder]="streamVideoBytePlaceholder"
    (contentUpdate)="onContentUpdate($event)"
    (contentRemoval)="onContentRemoval($event)"
    (contentCreated)="onContentCreation($event)"
    (contentUploaded)="onContentUpload($event)"
    (regularLinkReplaced)="onRegularLinkInsertion($event)"
    (toolbarUpdate)="onToolbarUpdate($event)"
    (inputFocus)="onInputFocus()"
    (inputBlur)="onInputBlur()"
  ></ptl-editor-content-view>

  <ng-container *ngIf="!newVersionEnabled">
    <ptl-editor-toolbar
      #editorToolbar
      *ngIf="toolbarButtonVisible && toolbarEnabled"
      [link]="link"
      [upload]="upload"
      [form]="form"
      [embed]="embed"
      [textFormOnly]="textFormOnly"
      [quiz]="quiz"
      [chart]="chart"
      [separator]="separator"
      [collector]="collector"
      [ngStyle]="{top: toolbarButtonOffsetTop + 'px',  'left': languageDirection === 'ltr' ? toolbarButtonOffsetLeft + 'px' : '',
        'right': languageDirection === 'rtl' ? toolbarButtonOffsetLeft + 'px' : '' }"
      (toolbarOpened)="toolbarOpen = $event"
      (contentCreated)="onContentCreation($event)"
      (regularLinkCreated)="onRegularLinkInsertion($event)"
    ></ptl-editor-toolbar>
  </ng-container>

  <ng-container *ngIf="newVersionEnabled">
    <ptl-editor-toolbar-new
      #editorToolbarNew
      *ngIf="toolbarButtonVisible && toolbarEnabled"
      [link]="link"
      [upload]="upload"
      [form]="form"
      [embed]="embed"
      [table]="true"
      [textFormOnly]="textFormOnly"
      [quiz]="quiz"
      [chart]="chart"
      [separator]="separator"
      [collector]="collector"
      [ngStyle]="{top: toolbarButtonOffsetTop + 'px',  'left': languageDirection === 'ltr' ? toolbarButtonOffsetLeft + 'px' : '',
        'right': languageDirection === 'rtl' ? toolbarButtonOffsetLeft + 'px' : '' }"
      (toolbarOpened)="toolbarOpen = $event"
      (contentCreated)="onContentCreation($event)"
      (regularLinkCreated)="onRegularLinkInsertion($event)">
    </ptl-editor-toolbar-new>
  </ng-container>
</div>
