import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MediaContent, MediaType, MediaUploadData } from '@app/app/shared/models';
import { ImageCropDialogComponent } from '@app/app/shared/components/image-crop-dialog/image-crop-dialog.component';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { MatDialog } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'ptl-editor-file-cropper',
	templateUrl: './editor-file-cropper.component.html',
	styleUrls: ['./editor-file-cropper.component.scss']
})
export class EditorFileCropperComponent implements OnInit {
	private dialog = inject(MatDialog);

	private _fileData; // TODO #Hrant should specify type
	@Input()
	set fileData( value ) {
		this._fileData = value;
	}

	get fileData() {
		return this._fileData;
	}

	@Output() mediaContentAdded = new EventEmitter<MediaContent>();

	ngOnInit() {
		this.onFileChanged(this.fileData);
	}

	onFileChanged( eventData: Event ) {
		const selectedFile: File = (eventData?.target as HTMLInputElement)?.files[0];

		const fileUploadData: MediaUploadData = {
			type: 'MEDIA_UPLOAD',
			file: selectedFile,
			mediaType: this.getFileType(selectedFile),
			uuid4: uuidv4(),
			replace: false
		};

		if ( fileUploadData.mediaType === 'IMAGE' ) {
			const dialogRef = this.dialog.open(ImageCropDialogComponent, {
				width: '90vw',
				minWidth: '15.625rem',
				maxWidth: '37.5rem',
				maxHeight: '80vh',
				position: {
					top: '10vh'
				},
				direction: LanguageCodeHelper.getBodyLanguageDir(),
				panelClass: 'ptl-mat-dialog',
				backdropClass: 'dialog-backdrop',
				data: {
					imageFile: selectedFile,
					cropperShape: 'rectangle'
				}
			});

			const dialogSubscription = dialogRef.afterClosed().subscribe(( data ) => {
				if ( data ) {
					fileUploadData.file = data.croppedImageFile;
					this.mediaContentAdded.emit(fileUploadData);
				}

				(eventData.target as HTMLInputElement).value = '';
				dialogSubscription.unsubscribe();
			});
		} else {
			this.mediaContentAdded.emit(fileUploadData);
		}
	}

	private getFileType( file: File ): MediaType {
		if ( file?.type.match('image.*') ) {
			return 'IMAGE';
		}
		if ( file?.type.match('video.*') ) {
			return 'VIDEO';
		}
		if ( file?.type.match('audio.*') ) {
			return 'AUDIO';
		}
		return 'DOCUMENT';
	}
}
