/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
export class LoadRecentPlaylists {
  static readonly type = '[Summaries] Load recent playlists';

  constructor(public page: number = 0) { }
}

export class RefreshRecentPlaylists {
  static readonly type = '[Summaries] Refresh recent playlists';
}

export class LoadFavoritePlaylists {
  static readonly type = '[Summaries] Load favorite playlists';

  constructor(public page: number) { }
}

export class RefreshFavoritePlaylists {
  static readonly type = '[Summaries] Refresh favorite playlists';
}
