/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { CATEGORY_PARAM_NAME } from '../models/explore.model';

export class ExploreHelper {

  static getCategoriesFromUrl(
    activatedRouteSnapshot: ActivatedRouteSnapshot
  ): string[] | undefined {
    const categoryParamValue = activatedRouteSnapshot.queryParams[CATEGORY_PARAM_NAME];

    if (!categoryParamValue) {
      return undefined;
    }

    return Array.isArray(categoryParamValue)
      ? categoryParamValue
      : [categoryParamValue];
  }

  static getLastCategoryChildrenFromUrl(activatedRouteSnapshot: ActivatedRouteSnapshot): string | undefined {
    const categoryParamValue = activatedRouteSnapshot.queryParams[CATEGORY_PARAM_NAME];

    if (!categoryParamValue) {
      return undefined;
    }

    return Array.isArray(categoryParamValue)
      ? categoryParamValue[categoryParamValue.length - 1]
      : categoryParamValue;
  }

  static generateCategoryUidQueryString(uids: string[]): string {
    const categoryUidQuery = uids.map(uid => `categoryUid=${encodeURIComponent(uid)}`).join('&');
    return '?' + categoryUidQuery;
  }

  static checkIfUrlContainsExploreCategory(currentUrl: string): boolean {
    if (!currentUrl) {
      return false;
    }
    return currentUrl.includes('explore/category');
  }

  static checkQueryParamExistence(queryParams: Params): boolean {
    return 'categoryUid' in queryParams;
  }

  /**
   * I added `queryUriParams` via the browser API.
   * I don't use the Angular approach because it would trigger a redundant redirect
   * (since query parameters are added after the redirect).
   *
   * In this function, I use the browser API (e.g., `window.location.search`) to get the query parameters
   * instead of Angular's Router, to avoid this unnecessary redirection.
   */
  static getCategoryUidsFromUrl(): string[] {
    const searchParams = new URLSearchParams(window.location.search);
    const categoryUids: string[] = searchParams.getAll('categoryUid');
    return categoryUids;
  }
}
