import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {environment} from '../../../../environments/environment';
import {TranslationService} from '../translation/translation.service';
import {RestClientService} from '../rest-client.service';
import {SharedListsDataService} from './lists-data.service';
import {catchError, switchMap} from 'rxjs/operators';
import {ObservableResult} from '../../store';
import {QuestionFormList} from '../../models/lists/list.model';

@Injectable()
export class ApiSharedListsDataService implements SharedListsDataService {
  private listsUrl = Location.joinWithSlash(environment.apiRootUrl || '', 'lists');

  constructor(private client: RestClientService, private translationService: TranslationService) {
  }

  createFormQuestionTextList(title: string): ObservableResult<QuestionFormList> {
    return this.client.post<QuestionFormList>(this.listsUrl, {title}).pipe(
      switchMap(({body}) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateListItem')))
    )
  }

  searchListsByTerm(term: string, page: number = 0, size: number = 10): ObservableResult<QuestionFormList> {
    const params = {
      page: page.toString(),
      size: size.toString(),
    }

    return this.client.get<QuestionFormList>(`${this.listsUrl}/search?term=${term}`, params).pipe(
      switchMap(({body}) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorListItemNotFound')))
    )
  }

  getFormListByOptionUid(optionsListUid: string): ObservableResult<QuestionFormList> {
    return this.client.get<QuestionFormList>(`${this.listsUrl}/${optionsListUid}`).pipe(
      switchMap(({body}) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateListItem')))
    )
  }

  addElementToFormQuestionList(listUid: string, data: {
    parentUid: string;
    value: string;
  }): ObservableResult<QuestionFormList> {
    return this.client.post<QuestionFormList>(`${this.listsUrl}/${listUid}/elements`, data).pipe(
      switchMap(({body}) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateListItem')))
    )
  }

  updateFormQuestionListElementByElementUid(listUid: string, elementUid: string, data: {
    value: string;
  }): ObservableResult<QuestionFormList> {
    return this.client.patch<QuestionFormList>(`${this.listsUrl}/${listUid}/elements/${elementUid}`, data).pipe(
      switchMap(({body}) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateListItem')))
    )
  }

  updateFormQuestionListTitle(listUid: string, data: { title: string }): ObservableResult<QuestionFormList> {
    return this.client.patch<QuestionFormList>(`${this.listsUrl}/${listUid}`, data).pipe(
      switchMap(({body}) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateListItem')))
    )
  }

  deleteFormQuestionListElement(listUid: string, elementUid: string): ObservableResult<null> {
    return this.client.delete<null>(`${this.listsUrl}/${listUid}/elements/${elementUid}`).pipe(
      switchMap(({body}) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateListItem')))
    )
  }
}
