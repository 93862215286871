import { BooleanFilter } from '../../../../../shared/models/admin/boolean-filters.model';
import { MembersSearchOrder } from '@app/app/shared/models/admin/members.model';

export const INITIAL_MEMBERS_STATE: MembersStateModel = {
  selectedMembers: [],
  memberFilters: [],
  cardFilters: [],
  playlistFilters: [],
  playlistReviewFilters: [],
  cardReviewFilters: [],
  playlistPaginationFilters: [],
  cardPaginationFilters: [],
  memberPaginationFilter: null,
  highlightedMemberId: null,
};

export interface MembersStateModel {
  selectedMembers: string[];
  memberFilters: BooleanFilter[];
  cardFilters: CardFilter[];
  playlistFilters: PlaylistFilter[];
  playlistReviewFilters: PlaylistFilter[];
  cardReviewFilters: CardFilter[];
  playlistPaginationFilters: PlaylistPaginationFilter[];
  cardPaginationFilters: CardPaginationFilter[];
  memberPaginationFilter?: PaginationFilter;
  highlightedMemberId: string;
}

export interface CardFilter {
  cardUid: string;
  value: BooleanFilter[];
}

export interface PlaylistFilter {
  playlistUid: string;
  value: BooleanFilter[];
}

export interface PlaylistPaginationFilter {
  playlistUid: string;
  value: PaginationFilter;
}

export interface CardPaginationFilter {
  cardUid: string;
  value: PaginationFilter;
}

export interface PaginationFilter {
  page?: number;
  pageSize?: number;
  order?: MembersSearchOrder;
}
