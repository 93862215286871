<div class="word-limit-dialog" #wordLimitDialog tabindex="0">
  <header class="header separator">
    <h3>{{ 'translations.cards.messages.error.textExceedsLimit' | transloco }}</h3>
  </header>

  <p class="exceed-text">{{ data.aiData }}</p>

  <footer class="footer">
    <div class="controls">
      <button mat-flat-button
              type="button"
              color="accent"
              [attr.aria-label]="('translations.global.ariaLabels.cancel' | transloco)"
              [title]="'translations.global.button.cancel' | transloco"
              (click)="cancel()">
        {{ 'translations.global.button.cancel' | transloco }}
      </button>
      <button mat-flat-button
              type="button"
              color="primary"
              [attr.aria-label]="('translations.global.ariaLabels.replace' | transloco)"
              [title]="'translations.global.button.replace' | transloco"
              (click)="replace()">
        {{ 'translations.global.button.replace' | transloco }}
      </button>
    </div>
  </footer>
</div>


