/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ImageUploadContent, MediaUploadData } from '../../../../models';

@Component({
  selector: 'ptl-media-upload-image-preview',
  templateUrl: './media-upload-image-preview.component.html',
  styleUrls: ['./media-upload-image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaUploadImagePreviewComponent {

  /** Receives image upload data */
  @Input() uploadedImage: MediaUploadData | undefined;
  @Input() link: string | undefined;
  @Input() caption: string | undefined;

  @Output() openUrl = new EventEmitter();

  @ViewChild('image') image: ElementRef | undefined;

  originalUrl = '';
  imageErrorCount = 0;

  get url(): string {
    if (!this.uploadedImage) {
      return '';
    }

    const content = this.uploadedImage.content as ImageUploadContent;
    this.originalUrl = content.original;
    switch (this.uploadedImage.position) {
      case 'THUMBNAIL':
      case 'LEFT':
      case 'RIGHT': return content.original;
      case 'CENTER': return content.original;
      case 'FULL_WIDTH': return content.extraLarge;
      default: return content.original;
    }
  }

  error() {
    if (!this.image || this.imageErrorCount > 0) {
      return;
    }
    this.image.nativeElement.src = this.originalUrl;
    this.imageErrorCount += 1;
  }

  onImageClick() {
    if (this.link) {
      this.openUrl.emit();
    }
  }
}
