/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { dataLoadedState, defaultLoadableState, errorState, LoadableState, loadingState } from '../../shared/store';
import * as Actions from './summaries.actions';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  LEARNER_PLAYLIST_DATA_SERVICE,
  LearnerPlaylistDataService
} from '../../shared/services/learner-playlist/data.service';
import { LearnerPlaylistSummary } from '../../shared/models/playlist/learner-playlist-summary.model';
import { Page } from '../../shared/models/page';
import { FavoritePlaylistSummary } from '../../shared/models';

/** The discovery state definition. */
export interface SummariesStateModel {
  /** The playlist summaries. */
  playlistSummaries: LoadableState<Page<LearnerPlaylistSummary>>;
  favoritePlaylists: LoadableState<Page<FavoritePlaylistSummary>>;
}

@State<SummariesStateModel>({
  name: 'summaries',
  defaults: {
    playlistSummaries: defaultLoadableState(),
    favoritePlaylists: defaultLoadableState(),
  },
})
@Injectable()
export class SummariesState {

  @Selector()
  static playlistSummaries({ playlistSummaries }: SummariesStateModel): LoadableState<Page<LearnerPlaylistSummary>> {
    return playlistSummaries;
  }

  @Selector()
  static favoritePlaylists({ favoritePlaylists }: SummariesStateModel): LoadableState<Page<FavoritePlaylistSummary>> {
    return favoritePlaylists;
  }

  constructor(
    @Inject(LEARNER_PLAYLIST_DATA_SERVICE) private learnerPlaylistService: LearnerPlaylistDataService
  ) {
  }

  @Action(Actions.LoadRecentPlaylists)
  loadRecentPlaylists(
    { getState, patchState }: StateContext<SummariesStateModel>,
    { page }: Actions.LoadRecentPlaylists
  ) {
    const oldContent = page === 0 ? []
      : getState().playlistSummaries.data && getState().playlistSummaries.data.content || [];

    patchState({
      playlistSummaries: loadingState(),
    });

    return this.learnerPlaylistService.getRecentPlaylists(page, 10).pipe(
      tap((({ isSuccess, value, error }) => {
          if (isSuccess) {
            patchState({
              playlistSummaries: dataLoadedState({
                ...value,
                content: oldContent.concat(value.content),
              }),
            });
          } else {
            patchState({
              playlistSummaries: errorState(error),
            });
          }
        }
      )));
  }

  @Action(Actions.RefreshRecentPlaylists)
  refreshRecentPlaylists(
    { getState, patchState }: StateContext<SummariesStateModel>
  ) {
    let pageSize = getState().playlistSummaries.data?.content ? getState().playlistSummaries.data.content.length : 0;
    let currentPageNumber: number;

    patchState({
      playlistSummaries: loadingState(),
    });

    if (pageSize > 10) {
      pageSize = 20;
      currentPageNumber = 2;
    } else {
      pageSize = 10;
      currentPageNumber = 1;
    }


    return this.learnerPlaylistService.getRecentPlaylists(0, pageSize).pipe(
      tap(({ isSuccess, value, error }) =>
        isSuccess
          ? patchState({
            playlistSummaries: dataLoadedState({
              ...value,
              currentPageNumber: currentPageNumber,
            }),
          })
          : patchState(({
            playlistSummaries: errorState(error),
          }))
      ));
  }

  @Action(Actions.LoadFavoritePlaylists)
  loadFavoritePlaylists(
    { getState, patchState }: StateContext<SummariesStateModel>,
    { page }: Actions.LoadFavoritePlaylists
  ) {
    const oldContent = page === 0 ? [] : getState().favoritePlaylists.data && getState().favoritePlaylists.data.content || [];

    patchState({
      favoritePlaylists: loadingState(),
    });

    return this.learnerPlaylistService.getFavoritePlaylists(page, 10).pipe(
      tap(({ isSuccess, value, error }) =>
        isSuccess
          ? patchState({
            favoritePlaylists: dataLoadedState({
              ...value,
              content: oldContent.concat(value.content),
            }),
          })
          : patchState(({
            favoritePlaylists: errorState(error),
          }))
      ));
  }

  @Action(Actions.RefreshFavoritePlaylists)
  refreshFavoritePlaylists(
    { getState, patchState }: StateContext<SummariesStateModel>
  ) {
    let pageSize = getState().favoritePlaylists.data?.content ? getState().favoritePlaylists.data.content.length : 0;
    let currentPageNumber: number;

    patchState({
      favoritePlaylists: loadingState(),
    });

    if (pageSize > 10) {
      pageSize = 20;
      currentPageNumber = 1;
    } else {
      pageSize = 10;
      currentPageNumber = 0;
    }


    return this.learnerPlaylistService.getFavoritePlaylists(0, pageSize).pipe(
      tap(({ isSuccess, value, error }) =>
        isSuccess
          ? patchState({
            favoritePlaylists: dataLoadedState({
              ...value,
              currentPageNumber: currentPageNumber,
            }),
          })
          : patchState(({
            favoritePlaylists: errorState(error),
          }))
      ));
  }
}
