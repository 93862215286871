/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {
  EditorContent,
  EditorContentRemovalEvent,
  EditorContentUpdateEvent
} from '@app/app/shared/models';

@Component({
  selector: 'ptl-question-instruction',
  templateUrl: './question-instruction.component.html',
  styleUrls: ['../add-question-section.component.scss', './question-instruction.component.scss'],
})
export class QuestionInstructionComponent implements AfterViewInit, OnDestroy {

  @Input() content: EditorContent[];

  @Input() showMore = true;

  @Output() contentUpdate = new EventEmitter<EditorContentUpdateEvent>();

  @Output() contentRemoval = new EventEmitter<EditorContentRemovalEvent>();

  @ViewChild('instructionContainerInner', { static: false }) instructionContainerInner: ElementRef;

  isOpened: boolean;
  showMoreButtonsAreVisible: boolean;
  private resizeObserver: ResizeObserver;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    if (this.instructionContainerInner) {
      const childElement = this.instructionContainerInner.nativeElement;

      this.resizeObserver = new ResizeObserver(() => {
        this.checkEditorHeight();
      });

      this.resizeObserver.observe(childElement);
    }
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  onContentUpdate(contentUpdateEvent: EditorContentUpdateEvent) {
    this.contentUpdate.emit(contentUpdateEvent);
    this.checkEditorHeight();
  }

  onContentRemoval(contentRemovalEvent: EditorContentRemovalEvent) {
    this.contentRemoval.emit(contentRemovalEvent);
  }

  toggleMore() {
    this.isOpened = !this.isOpened;
  }

  checkEditorHeight() {
    if (this.instructionContainerInner) {
      const editorHeight = this.instructionContainerInner.nativeElement.offsetHeight;
      const thresholdHeight = 50;

      this.showMoreButtonsAreVisible = editorHeight > thresholdHeight;
      this.cdr.detectChanges();
    }
  }
}
