/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { Select } from '@ngxs/store';
import {
  PlaylistCreationState
} from '../../../page-modules/playlist/store/create/playlist-creation.state';
import { Observable } from 'rxjs';
import { SupportedLanguage } from '../../models/languages/languages.model';
import { ContentHelper } from '../../helpers/content-helper';
import {
  SaveStatusFailureReason
} from '../../../page-modules/playlist/store/create/playlist-creation.state.model';

@Component({
  selector: 'ptl-headers-editor',
  templateUrl: './headers-editor.component.html',
  styleUrls: ['./headers-editor.component.scss'],
})
export class HeadersEditorComponent implements OnInit, OnChanges {
  @Input() playlistUid: string;
  @Input() cardUid: string;
  @Input() pageUid: string;
  @Input() subheader: string;
  @Input() title: string;
  @Input() uri: string;
  @Input() isDisabled: boolean;
  @Input() canEditTime: boolean;
  @Input() currentLanguage: SupportedLanguage;
  @Input() timeRequired: number;
  @Input() suggestedTime: number;

  @Output() titleUpdated = new EventEmitter<string>();
  @Output() subheaderUpdated = new EventEmitter<string>();
  @Output() uriUpdated = new EventEmitter<string>();
  @Output() timeEstimateUpdated = new EventEmitter<number>();
  @Output() appliedSuggestedTime = new EventEmitter<void>();

  @Select(PlaylistCreationState.saveFailureReason)
  saveFailureReason$: Observable<SaveStatusFailureReason>;

  @ViewChild('uriInput', {static: false}) private uriInput: ElementRef;

  editUri: boolean;
  suggestedTimeFormat: string;
  hours: string[] = [];
  minutes: string[] = [];
  selectedHour: string;
  selectedMinutes: string;

  ngOnInit() {
    this.buildHoursAndMinutesSelect();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.suggestedTime && changes.suggestedTime?.currentValue) {
      this.suggestedTimeFormat = ContentHelper.convertTimeEstimate(this.suggestedTime);
    }
    if (changes.timeRequired?.currentValue) {
      this.setTimeSelectValues(changes.timeRequired?.currentValue);
    }
  }

  onTitleUpdate(): void {
    this.titleUpdated.emit(this.title);
  }

  updateUri(): void {
    this.uri = this.uri?.toLowerCase().trim()?.replace(/ /g, '');
    this.editUri = false;
    this.uriUpdated.emit(this.uri);
  }

  updateSubheader(): void {
    this.subheaderUpdated.emit(this.subheader);
  }

  onTimeSelectChange() {
    const timeInMinutes = this.getTimeInMinutes();
    this.timeEstimateUpdated.emit(timeInMinutes);
  }

  onResetTimeSuggestion() {
    this.suggestedTime = 0;
    this.selectedHour = '0';
    this.selectedMinutes = '0';
    this.timeEstimateUpdated.emit(0);
  }

  applySuggestedTime() {
    this.suggestedTime = 0;
    this.appliedSuggestedTime.emit();
  }

  onEditUri(): void {
    this.editUri = true;
    setTimeout(() => {
      (this.uriInput.nativeElement as HTMLElement).focus();
    }, 0);
  }

  private setTimeSelectValues(time: number) {
    let hours: string | number = Math.floor(time / 60);
    let minutes: string | number = time % 60;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    this.selectedHour = hours ? hours.toString() : '00';
    this.selectedMinutes = minutes ? minutes.toString() : '00';
  }

  private getTimeInMinutes() {
    return (this.selectedHour ? (+this.selectedHour * 60) : 0) + (this.selectedMinutes ? (+this.selectedMinutes) : 0);
  }

  private buildHoursAndMinutesSelect() {
    this.hours = [];
    this.minutes = [];
    for (let i = 0; i < 24; i++) {
      let hour = `${i}`;
      if (i < 10) {
        hour = `0${i}`;
      }
      this.hours.push(hour);
    }

    for (let i = 0; i < 60; i++) {
      let hour = `${i}`;
      if (i < 10) {
        hour = `0${i}`;
      }
      this.minutes.push(hour);
    }
  }
}
