/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  EditorContent,
  EditorContentRemovalEvent,
  EditorContentUpdateEvent,
  Organization
} from '../../../../../shared/models';
import { EditorHelper } from '../../../../../shared/helpers/editor.helper';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './collector-form-log.component.html',
  styleUrls: ['./collector-form-log.component.scss'],
})
export class CollectorFormLogComponent {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  formData: EditorContent[] | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: EditorContent[],
    public dialogRef: MatDialogRef<CollectorFormLogComponent>,
  ) {
    if (data) {
      this.formData = this.data;
    }
  }

  updateCollectorFormLog() {
    this.dialogRef.close(this.formData);
  }

  onContentUpdate({ index, update }: EditorContentUpdateEvent, level: EditorContent[]) {
    this.formData = level.map((editorContent, i) => i === index ? update.newContent : editorContent);
  }

  onContentRemoval({ index }: EditorContentRemovalEvent, level: EditorContent[]) {
    const filteredContent = level.filter((_, i) => i !== index);
    this.formData = EditorHelper.joinSuccessiveParagraphContent(filteredContent);
  }

  onContentCreation(content: EditorContent, level: EditorContent[]) {
    this.formData = EditorHelper.addDynamicContentWithBreakpointCheck(level, content);
  }

}
