/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Framework,
  FrameworkDetails,
  FrameworkTag, FrameworkTagRequest, FrameworkType, UpdateFrameworkRequest
} from '../../../models/admin/admin.model';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  MediumEditorToolbarEvent,
  Organization,
  ResourceTag
} from '../../../models';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { canTriggerSearch } from '../../../helpers/content-helper';
import { FrameWorkState } from '../../../../page-modules/admin/store/framework.state';
import { DialogService } from '../../../helpers/dialog/dialog.service';
import { TranslationService } from '../../../services/translation/translation.service';
import { cloneDeep } from 'lodash-es';
import * as FrameworkActions from '../../../../page-modules/admin/store/framework.actions';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LoadableState } from '../../../store';
import { LTI_AUTH_SERVICE, LtiAuthService } from '../../../../user-auth/services/lti-auth.service';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../../services/tags/tag-data.service';


@Component({
  selector: 'ptl-frameworks-create',
  templateUrl: './framework-create.component.html',
  styleUrls: [ './framework-create.component.scss' ],
})
export class FrameworkCreateComponent implements OnInit, OnDestroy, OnChanges {

  @Input() isLtiModule = false;
  @Input() editableFrameworkId: string;
  @Input() frameworkType: FrameworkType;

  @Output() frameworkCreated = new EventEmitter<void>();

  @Select(FrameWorkState.filteredMainTags)
  filteredMainTags$: Observable<ResourceTag[]>;

  @Select(FrameWorkState.organizationFramework)
  organizationFramework$: Observable<LoadableState<FrameworkDetails>>;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  frameworkFormGroup: FormGroup;
  filterMainTagsSubscription: Subscription;
  filteredMainTags: ResourceTag[] = [];
  tags: FrameworkTag[] = [];
  searchString: string;
  loadingSearch: boolean;

  private searchInputSubject$ = new Subject<void>();
  private searchInputSubscription: Subscription;
  private matchingTag: FrameworkTag;
  private modifiedTags: FrameworkTagRequest[] = [];
  private organizationFrameworkSubscription: Subscription;
  private subscriptionEnd$ = new EventEmitter<void>();
  private organizationUid: string;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private dialogService: DialogService,
    private translationService: TranslationService,
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    @Inject(LTI_AUTH_SERVICE) private ltiAuthService: LtiAuthService,
  ) {
    this.frameworkFormGroup = this.fb.group({
      title: [ '', [ Validators.required ] ],
      description: [ '' ]
    });

    this.searchInputSubscription = this.searchInputSubject$
      .pipe(debounceTime(500)).subscribe(() => this.fireSearch());
  }

  ngOnInit() {
    this.filterMainTagsSubscription = this.filteredMainTags$.subscribe((data) => {
      this.filteredMainTags = data;
      this.loadingSearch = false;
    })

    this.organizationData$.pipe(
      takeUntil(this.subscriptionEnd$)
    ).subscribe(org => this.organizationUid = org._id);

    if ( this.editableFrameworkId ) {
      this.store.dispatch(new FrameworkActions.LoadOrganizationFrameworkById(this.editableFrameworkId));

      this.organizationFrameworkSubscription = this.organizationFramework$.pipe(
        filter(({ loading, data }) => !loading && !!data),
        map(({ data }) => {
          return data;
        })
      ).subscribe(data => {
        this.setFrameworkEditableData(data)
      });
    }
  }

  ngOnChanges({ tags }: SimpleChanges) {
    if ( tags && tags.currentValue.length ) {
      this.modifyTags(this.tags, 0);
    }
  }

  ngOnDestroy() {
    this.subscriptionEnd$.emit();
    this.searchInputSubscription?.unsubscribe();
    this.filterMainTagsSubscription?.unsubscribe();
    this.organizationFrameworkSubscription?.unsubscribe();
  }

  setFrameworkEditableData(data: FrameworkDetails) {
    this.frameworkFormGroup.controls['title'].setValue(data.title);
    this.frameworkFormGroup.controls['description'].setValue(data.description);
    this.tags = data.tags as FrameworkTag[];
    this.modifyTags(this.tags);
  }


  onSearchInputEnter(event: KeyboardEvent) {
    const searchValue = (event.target as HTMLInputElement).value;
    this.loadingSearch = true;
    const api = this.frameworkType === 'STANDARD' ?
      this.tagService.createTag({ title: searchValue }) : this.tagService.createCategory({ title: searchValue });

    api.subscribe(({ isSuccess, value }) => {
      if ( isSuccess ) {
        this.loadingSearch = false;
        (event.target as HTMLInputElement).value = '';

        const frameworkTag: FrameworkTag = {
          tagId: value._id,
          title: value.title,
          description: undefined,
          competency: undefined,
          parentTagId: null
        }
        this.tags = this.tags.concat(frameworkTag);
      } else {
        this.loadingSearch = false;
      }
    });
  }

  onAddFramework() {
    this.frameworkFormGroup.get('title').markAsTouched();

    if (!this.frameworkFormGroup.valid) {
      return;
    }

    if (!this.isLtiModule ) {
      this.addFramework();
      return;
    }

    this.addLtiFramework();
  }

  onUpdateFramework() {
    if ( !this.frameworkFormGroup.valid || !this.editableFrameworkId ) {
      return;
    }

    if (!this.isLtiModule ) {
      this.updateFramework();
      return;
    }

    this.updateLtiFramework();
  }

  onTextPaste(event: ClipboardEvent) {
    if ( event.type === 'paste' ) {
      setTimeout(() => {
        if ( this.searchString ) {
          this.loadingSearch = true;
          this.searchInputSubject$.next();
        }
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if ( canTriggerSearch(event) ) {
      if ( this.searchString ) {
        this.loadingSearch = true;
        this.searchInputSubject$.next();
      }
    }
  }

  searchAutocompleteFormat(): string {
    return '';
  }


  onSearchResultSelected({ option }: MatAutocompleteSelectedEvent): void {
    const selectedTag = option.value;

    selectedTag.tagId = selectedTag._id ? selectedTag._id : selectedTag.tagId;
    delete selectedTag._id;
    selectedTag.parentTagId = null;
    selectedTag.indentValue = 0;

    this.addOrUpdateTag(selectedTag as FrameworkTag);

    this.searchString = '';
    this.filteredMainTags = [];
  }

  onTagDeleted(tag: FrameworkTag) {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.confirmRemoveTagFromFramework'),
      this.translationService
    ).then(confirmed => {
      if ( confirmed ) {
        this.removeTagsFromHierarchyByTagIndex(tag.tagIndex, tag.parentTagId);
      }
    });
  }

  onChildTagAdded(data: { childTag: FrameworkTag; parentTag: FrameworkTag }) {
    this.addOrUpdateTag(data.childTag, data.parentTag, true);
  }

  private addFramework() {
    const tags = this.getParsedTags();
    const title = this.frameworkFormGroup.get('title').value;
    const description = this.frameworkFormGroup.get('description').value;

    this.store.dispatch(new FrameworkActions.AddFramework({
      title: title,
      description: description,
      tags: tags,
    })).toPromise().then(() => {
      this.store.dispatch(new FrameworkActions.LoadFrameworks());

      this.frameworkCreated.emit();
    });
  }

  private addLtiFramework() {
    const requestId = this.ltiAuthService.getLtiRequestId();

    if (!this.organizationUid || !requestId) {
      return;
    }

    const tags = this.getParsedTags();
    const title = this.frameworkFormGroup.get('title').value;
    const description = this.frameworkFormGroup.get('description').value;

    this.store.dispatch(new FrameworkActions.AddLtiFramework(requestId, this.organizationUid, {
      title: title,
      description: description,
      tags: tags,
    })).toPromise().then(() => {
      this.frameworkCreated.emit();
    })
  }

  private updateFramework() {
    const tags = this.getParsedTags();
    const title = this.frameworkFormGroup.get('title').value;
    const description = this.frameworkFormGroup.get('description').value;

    const framework: UpdateFrameworkRequest = {
      title: title,
      description: description,
      tags: tags,
      type: this.frameworkType
    };
    this.store.dispatch(
      new FrameworkActions.UpdateFramework(this.editableFrameworkId, framework, undefined)
    ).toPromise().then((data) => {
      if (data) {
        if (this.frameworkType === 'STANDARD') {
          this.store.dispatch(new FrameworkActions.LoadFrameworks());
        } else if (this.frameworkType === 'CATEGORIES') {
          this.store.dispatch(new FrameworkActions.LoadFrameworkCategories());
        }
      }
    })
  }

  private updateLtiFramework() {
    const requestId = this.ltiAuthService.getLtiRequestId();

    if (!this.organizationUid || !requestId) {
      return;
    }

    const tags = this.getParsedTags();
    const title = this.frameworkFormGroup.get('title').value;
    const description = this.frameworkFormGroup.get('description').value;

    const framework: UpdateFrameworkRequest = {
      title: title,
      description: description,
      tags: tags,
      type: this.frameworkType
    };
    this.store.dispatch(
      new FrameworkActions.UpdateLtiFramework(this.editableFrameworkId, this.organizationUid, requestId, framework)
    );
  }

  private fireSearch() {
    if (!this.isLtiModule) {
      if (this.frameworkType === 'STANDARD') {
        this.store.dispatch(new FrameworkActions.FilterMainTags(this.searchString));
      }
      if (this.frameworkType === 'CATEGORIES') {
        this.store.dispatch(new FrameworkActions.FilterCategories(this.searchString));
      }
      return;
    }

    const requestId = this.ltiAuthService.getLtiRequestId();

    if (this.organizationUid && requestId) {
      this.store.dispatch(new FrameworkActions.FilterLtiTags(this.organizationUid, requestId, this.searchString));
    }
  }

  private addOrUpdateTag(selectedTag: FrameworkTag, parentTag?: FrameworkTag, addingAsChild?: boolean) {
    const newTag = JSON.parse(JSON.stringify(selectedTag));

    this.setMatchingTag(this.tags, newTag.tagId);

    if ( this.matchingTag && this.matchingTag.tagId === newTag.tagId ) {
      newTag.children = cloneDeep(this.matchingTag.children);
    }

    this.updateTagsById(this.tags, newTag.tagId, newTag);

    if ( addingAsChild ) {
      this.setMatchingTag(this.tags, parentTag.tagId);

      if ( !this.matchingTag.children ) {
        this.matchingTag.children = [];
      }

      if ( !this.matchingTag.children.find(tag => tag.tagId === newTag.tagId) ) {
        const updatedChildren = [ ...this.matchingTag.children, newTag ];
        this.updateTagsChildrenById(this.tags, parentTag.tagId, updatedChildren);
        if ( newTag.parentTagId !== parentTag.tagId && (!newTag.children || newTag.children.length === 0)) {
          this.removeTagsFromTagsListByIds(this.tags, newTag.tagId, newTag.parentTagId);
        }
      }

    } else {
      this.removeTagsFromTagsListByIds(this.tags, newTag.tagId, newTag.parentTagId);

      if ( !this.tags.find(tag => tag.tagId === newTag.tagId) ) {
        newTag.parentTagId = null;
        this.tags.push(newTag);
      }
    }

    this.modifyTags(this.tags, 0);
  }

  private removeTagsFromHierarchyByTagIndex(tagIndex: number, parentId: string) {
    this.removeTagsFromTagsListByTagIndex(this.tags, tagIndex, parentId);
    this.modifyTags(this.tags, 0);
    this.matchingTag = null;
  }

  removeTagsFromTagsListByTagIndex(tags: FrameworkTag[], tagIndex: number, parentId: string) {
    const index = tags.findIndex(tag => tag.tagIndex === tagIndex);
    if ( index !== -1 ) {
      const matchingTag = tags[index];
      tags.splice(index, 1);

      if ( matchingTag.children?.length ) {
        const tagsToInsert: FrameworkTag[] =
          matchingTag.children?.filter(tag => !tags.find(duplicate => duplicate.tagId === tag.tagId))
            .map(tag => {
              return {
                ...tag,
                parentTagId: parentId,
              };
            });

        tags.splice(index, 0, ...tagsToInsert);
      }
    }

    for ( const tag of tags ) {
      if ( tag.children?.length ) {
        this.removeTagsFromTagsListByTagIndex((tag.children), tagIndex, parentId);
      }
    }
  }


  private getParsedTags(): FrameworkTagRequest[] {
    if ( this.tags.length ) {
      this.modifiedTags = [];
      this.modifyTagsForAddAction(this.tags);
      return this.modifiedTags
        .filter((item, index) => index === this.modifiedTags
          .findIndex(duplicate => item.tagId === duplicate.tagId && item.parentTagId === duplicate.parentTagId));
    }

    return [];
  }


  private modifyTagsForAddAction(tags: FrameworkTag[], tagId?: string) {
    for ( const tag of tags ) {
      this.modifiedTags.push({
        tagId: tag.tagId,
        parentTagId: tagId ? tagId : null,
        description: '',
      });
      if ( tag.children && tag.children.length ) {
        this.modifyTagsForAddAction(tag.children, tag.tagId);
      }
    }
  }

  /**
   * This function modifies tags data for showing tags hierarchy in template
   */
  private modifyTags(tags: FrameworkTag[], index: number = 0) {
    for (const tag of tags) {
      index++;
      tag.tagIndex = index;

      if (tag.children && tag.children.length) {
        index = this.modifyTags(tag.children, index);
      }
    }

    return index;
  }

  private updateTagsById(tags: FrameworkTag[], tagId: string, newTag: FrameworkTag) {
    for ( const tag of tags ) {
      if ( tag.tagId === tagId ) {
        tag.title = newTag.title;
        tag.description = newTag.description;
        tag.competency = newTag.competency;
      }

      if ( tag.children?.length ) {
        this.updateTagsById(tag.children, tagId, newTag);
      }
    }
  }

  private updateTagsChildrenById(tags: FrameworkTag[], tagId: string, updatedChildren: FrameworkTag[]) {
    for ( const tag of tags ) {
      if ( tag.tagId === tagId ) {
        tag.children = updatedChildren.map(child => {
          return { ...child, parentTagId: tagId };
        });
      } else if ( tag.children?.length ) {
        this.updateTagsChildrenById(tag.children, tagId, updatedChildren);
      }
    }
  }

  private removeTagsFromTagsListByIds(tags: FrameworkTag[], tagId: string, parentId: string, keepChildren = false) {
    const index = tags.findIndex(tag => tag.tagId === tagId && tag.parentTagId === parentId);

    if ( index !== -1 ) {
      const matchingTag = tags[index];
      tags.splice(index, 1);

      if ( keepChildren && matchingTag.children?.length ) {
        const tagsToInsert: FrameworkTag[] =
          matchingTag.children?.filter(tag => !tags.find(duplicate => duplicate.tagId === tag.tagId))
            .map(tag => {
              return {
                ...tag,
                parentTagId: parentId,
              };
            });

        tags.splice(index, 0, ...tagsToInsert);
      }
    }

    for ( const tag of tags ) {
      if ( tag.children?.length ) {
        this.removeTagsFromTagsListByIds(tag.children, tagId, parentId, keepChildren);
      }
    }
  }

  private setMatchingTag(tags: FrameworkTag[], tagId: string, parentId?: string) {
    for ( const tag of tags ) {
      if ( tag.tagId === tagId && (parentId === undefined || parentId === tag.parentTagId) ) {
        this.matchingTag = tag;
        return;
      }

      if ( tag.children?.length ) {
        this.setMatchingTag(tag.children, tagId, parentId);
      }
    }
  }
}
