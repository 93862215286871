<div class="ai-assistant-container">
  <button class="close-icogit n" mat-icon-button (click)="closeAiBox()">
    <mat-icon aria-hidden="true">close</mat-icon>
  </button>
  <ng-content></ng-content>
  <div class="ai-assistant-header">
    <div class="title medium bold ai-assistant-title">
      {{ aiAssistantSettings?.title ? aiAssistantSettings.title : 'translations.aiAssistant.title' | transloco }}
    </div>
    <div class="ai-assistant-error" *ngIf="errorLoadingActions">
      <span>{{ 'translations.aiAssistant.actions.loadingError' | transloco }}</span>
    </div>

    <div class="ai-assistant-options-box">
       <span class="title extra-small bold">
                {{ aiAssistantSettings?.instructions ? aiAssistantSettings.instructions : 'translations.aiAssistant.actions.choose' | transloco }}
        </span>
      <div class="ai-assistant-select-box">
        <mat-select class="ai-assistant-select"
                    [disabled]="isLoadingResponse"
                    (selectionChange)="chooseAction($event.value)"
                    [(value)]="activatedAction">
          <mat-option *ngFor="let action of actions" [value]="action"
                      [ngClass]="{'btn-active': activatedAction === action}">
            {{ action.description }}
          </mat-option>
        </mat-select>
        <button
          mat-flat-button
          color="primary"
          class="custom-go-button"
          (click)="combineAndSend()"
          (keydown.enter)="combineAndSend()"
          [disabled]="isLoadingResponse || !activatedAction || !formContent"
          [matTooltip]="'translations.aiAssistant.actions.send' | transloco">
          {{ 'translations.aiAssistant.actions.go' | transloco }}
        </button>
      </div>
    </div>
  </div>

  <div class="ai-assistant-conversation" [class.has-result]="assistantResponse" #conversation>
    <p class="assistant-message-box medium" *ngIf="assistantResponse" [innerHTML]="assistantResponse.content"></p>
    <div class="assistant-loader" *ngIf="isLoadingResponse">
      <mat-spinner
        *ngIf="isLoadingResponse"
        [diameter]="30">
      </mat-spinner>
    </div>
  </div>

  <div ogCopyText copiedMessage="{{ 'translations.aiAssistant.actions.copiedToClipBoard' | transloco }}"
       class="assistant-message-footer" *ngIf="assistantResponse">
    <button mat-button (click)="handleMessageClick(assistantResponse)" class="icon-button">
      <mat-icon class="icon-left">insert_drive_file</mat-icon>
      <span class="extra-small">
        {{ 'translations.aiAssistant.actions.insert' | transloco }}
      </span>
    </button>

    <button mat-button (click)="copyMessage(assistantResponse)" class="icon-button">
      <mat-icon class="icon-left">file_copy</mat-icon>
      <span class="extra-small">
        {{ 'translations.aiAssistant.actions.copy' | transloco }}
      </span>
    </button>

  </div>
</div>
