<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="stream-video-preview-element stream-video-preview-element-{{ content?.type }}"
  [ngClass]="[
    'stream-video-preview-element--' + content?.type,
    !isEditMode ? 'stream-video-preview-element-position-' + (content?.position || 'CENTER') : ''
  ]">
  <div *ngIf="isProcessing" class="loading-container">
    <p>{{ 'translations.videoProcessingInfo' | transloco }}</p>
    <div class="spinner"></div>
  </div>
  <div *ngIf="isProgressBarVisible" class="loading-container">
    <p>{{ 'translations.videoUploadInfo' | transloco }}</p>
    <ptl-progress-bar-upload [content]="content"
    (contentUploadProgressEmitter)="contentUploadProgress($event)"></ptl-progress-bar-upload>
  </div>
  <video #video playsinline controls
    class="stream-video-preview-element--video"
    type="application/x-mpegURL"
    [poster]="posterUrl"
    [hidden]="!playerInitialized || isProcessing">
  </video>
  <div class="stream-video-preview-element--content" *ngIf="content?.caption">
    <p
      tabindex="0"
      class="stream-video-preview-element--caption"
      role="button"
      [class.link]="content?.url"
      [attr.aria-label]="content?.url ? ('translations.global.ariaLabels.openUrl' | transloco: {url: content?.url}) : content?.caption"
      (click)="openUrl()"
      (keydown.enter)="openUrl()">
      {{content?.caption}}</p>
  </div>
</div>
