<div class="alumni-csv-upload-dialog-content">
  <div class="dialog-top-info">
    <h1 class="dialog-title">{{ 'translations.membersNew.controlPanel.alumniUpload.title' | transloco }}</h1>
    <p class="dialog-description medium">{{ 'translations.membersNew.controlPanel.alumniUpload.description' | transloco }}</p>
    <button class="close-dialog-btn"
            mat-icon-button color="primary"
            (click)="closeDialog()"
            [attr.aria-label]="'translations.global.ariaLabels.closeDialog'">
      <svg class="svg" role="img" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div class="alumni-csv-upload-content">
    <div class="content-wrapper">
      <h2>{{ 'translations.membersNew.controlPanel.alumniUpload.csvFile' | transloco }}</h2>
      <div
        tabindex="0"
        class="file-upload-content"
        ogFileDrop
        (click)="fileInput.click()"
        [attr.aria-label]="('translations.global.ariaLabels.dragFileOrBrowse' | transloco)"
        (keydown.enter)="fileInput.click()"
        (filesHovered)="filesHovered=$event"
        [class.file-upload-content--active]="filesHovered"
        [class.file-upload-content--error]="csvInvalid"
        (filesDropped)="onFilesDropped($event)">
        <div *ngIf="!fileUploaded" class="medium" [innerHTML]=" 'translations.members.alumni.button.dragFileOrBrowse' | transloco"></div>
        <span *ngIf="fileUploaded" class="medium">{{ uploadedFileName }}</span>
        <input
          type="file"
          [accept]="'.csv'"
          (change)="onFileChanged($event)"
          hidden
          #fileInput>
      </div>

      <div class="download-csv-content"
           [innerHTML]="'translations.members.alumni.description.csvDownload' | transloco | safeHtml"></div>

      <div class="group-content">
        <h2>{{ 'translations.membersNew.controlPanel.alumniUpload.group' | transloco }}</h2>
        <div class="group-content__search-groups-box">
          <ptl-group-search-autocomplete
            #groupSearchAutocomplete
            class="search-field"
            [class.error]="groupInvalid"
            [groups]="searchedGroups"
            [loadingGroups]="loadingGroups"
            [placeholder]="('translations.reviews.label.searchForGroups' | transloco)"
            (searchInputChanged)="onSearchInputChange($event)"
            (searchLoadedMore)="onSearchLoadingMore()"
            (groupSelected)="onGroupSelected($event)">
          </ptl-group-search-autocomplete>
        </div>

        <div class="group-content__selected-group-chip-box" *ngIf="selectedGroup">
          <div class="chips-list centered">
            <span class="medium">
              {{ 'translations.membersNew.controlPanel.alumniUpload.selectedGroup' | transloco }} -
            </span>
            <div
              class="content-view-tag-item chip-item chip-interactive pointer chip-with-text-before"
              (click)="removeSelectedGroup()"
              (keydown.enter)="removeSelectedGroup()">
              {{ selectedGroup.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons-row">
    <button mat-button (click)="closeDialog()">
      <span class="small bold">{{ 'translations.global.button.cancel' | transloco }}</span>
    </button>
    <button mat-button (click)="uploadAlumniUsers()" class="suggested" [disabled]="isLoading">
      <span class="small bold">{{'translations.global.button.upload' | transloco}}</span>
    </button>
  </div>

</div>
