/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

export class AnimatedGifHelper {
  static imageBase64: string;

  static isGifAnimated(file: File): Promise<boolean> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const result = event.target?.result;
        if (result instanceof ArrayBuffer) {
          const gifData = new Uint8Array(result);
          let frames = 0;
          for (let i = 0; i < gifData.length; i++) {
            if (gifData[i] === 0x21 && gifData[i + 1] === 0xF9 && gifData[i + 2] === 0x04) {
              frames++;
              if (frames > 1) {
                resolve(true);
                return;
              }
            }
          }
          resolve(false);
        } else {
          resolve(false);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }

   static readFileAsDataURL(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      AnimatedGifHelper.imageBase64 = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  }
}
