<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<mat-card class="skeleton__card-item">
  <mat-card-title class="skeleton__card-top">
    <div class="skeleton__avatar"></div>
    <div class="skeleton__text skeleton__text-title slide-row"></div>
  </mat-card-title>
  <mat-card-content class="skeleton__card-content">
    <div class="skeleton__card-image"></div>
  </mat-card-content>
  <mat-card-footer class="skeleton__card-footer">
    <span class="skeleton__text skeleton__text-footer slide-row"></span>
  </mat-card-footer>
</mat-card>
