<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->



<div class="dialog-header">
  <div class="dialog-header__top">

    <i class="material-icons dialog-header__close-icon" [mat-dialog-close]="false"
       [attr.aria-label]="('translations.global.ariaLabels.closeDialog' | transloco)"
       tabindex="0">close</i>
  </div>
</div>

<div class="public-moment-section-inner" *ngIf="moment">
  <div class="public-moment-description">
    <ptl-resource-preview [previewContent]="moment.content"></ptl-resource-preview>
  </div>

  <div class="public-moment-tags-container" *ngIf="moment.tags && moment.tags.length">
    <ul class="public-moment-tags-list">
      <li class="public-moment-tags-list-item" *ngFor="let tag of moment.tags">
        {{ tag.title }}
      </li>
    </ul>
  </div>

</div>

