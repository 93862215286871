import { ProjectHelper } from '../../page-modules/project/helpers/project.helper';
import { NEW_VERSION_ENABLED } from '../constants/constants';

export class VersionHelper {
  static newVersionEnabled() {
    if (ProjectHelper.isProjectPublicView()) {
      return true;
    }
    return window.localStorage.getItem(NEW_VERSION_ENABLED) === 'true';
  }

  static setNewVersionEnabled(enabled: boolean) {
    return window.localStorage.setItem(NEW_VERSION_ENABLED, enabled.toString());
  }
}
