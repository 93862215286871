<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->



<div class="dialog-header">
  <div class="dialog-header__top">
    <img src="{{(organizationData$| async)?.logo}}" class="dialog-header__logo" alt="" width="88" height="67"/>


    <i class="material-icons dialog-header__close-icon" [mat-dialog-close]="false"
       [attr.aria-label]="('translations.global.ariaLabels.closePublishPage' | transloco)"
       tabindex="0">close</i>
  </div>
</div>


<div class="add-workspace__main">

  <div class="add-workspace__details-container">

    <div class="add-workspace__details-top">
      <img src="https://static.yuna.potential.ly/public/images/emails/unbox.jpg" width="267" height="217" alt=""/>
    </div>

    <form class="add-workspace__email-form" [formGroup]="addWorkspaceForm" (ngSubmit)="submitUserEmail()">
      <div class="add-workspace__email-form-title">
        {{ 'translations.account.workspaces.label.connectFolioAccount' | transloco }}
      </div>
      <div class="add-workspace__email-form-inner">
      <mat-form-field class="input-field-box-email input-field-box" appearance="outline">
        <mat-label #emailLabel>{{ 'translations.global.input.label.email' | transloco }}</mat-label>
        <input
          matInput
          required
          type="email"
          name="email"
          [placeholder]="emailLabel.innerHTML"
          formControlName="email">
        <mat-error *ngIf="addWorkspaceForm?.controls.email.hasError('required')">
          {{ 'translations.global.input.error.enterEmail' | transloco }}
        </mat-error>
      </mat-form-field>

      <button mat-flat-button color="primary" class="submit-add-workspace uppercase" title="Submit email">
        <span>{{ 'translations.global.button.submit' | transloco }}</span>
        <span class="submit--loading" *ngIf="isLoading">
          <mat-spinner [diameter]="20"></mat-spinner>
        </span>
      </button>
      </div>
    </form>
  </div>
</div>
