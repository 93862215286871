/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FrameworkTrackerSection } from '../../../../../shared/models/page/page.model';

@Component({
  selector: 'ptl-page-framework-tracker-section',
  templateUrl: './page-framework-tracker-section.component.html',
  styleUrls: ['./page-framework-tracker-section.component.scss'],
})
export class PageFrameworkTrackerSectionComponent implements AfterViewInit {

  /** Section title. */
  @Input() title: string;
  @Input() section: FrameworkTrackerSection;

  viewInitialized = false;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInitialized = true;
      this.cd.detectChanges();
    }, 500);
  }

  generateColorStyle(index): string {
    const colors = [
      'red',
      'green',
      'blue',
      'olive',
      'orange',
      'brown',
      'magenta',
      'purple',
      'teal',
      'indigo',
    ];

    return colors[index % colors.length];
  }
}
