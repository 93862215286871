/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import {
  AiAssistantService,
  AIAssistantSettings,
  AIAssistantSettingsRequest,
  AIAssistantSettingsTemplateDTO,
  ExchangeRequest,
  LearnerAIAssistantSettings,
  OpenAiMessage
} from './ai-assistant.service';
import { ObservableResult } from '../../../shared/store';
import { Page } from '../../../shared/models/page';

@Injectable({
  providedIn: 'root',
})
export class MockAiAssistantDataService implements AiAssistantService {
  exchange(request: ExchangeRequest): ObservableResult<OpenAiMessage> {
    return ObservableResult.ofSuccess();
  }

  getAIAssistantSettingsLearnerView(aiAssistantSettingsUid: string): ObservableResult<LearnerAIAssistantSettings> {
    return ObservableResult.ofSuccess();
  }

  createAssistantSettings(cardUid: string, request: AIAssistantSettingsRequest): ObservableResult<AIAssistantSettings> {
    return ObservableResult.ofSuccess();
  }

  getAssistantSettingsById(cardUid: string, assistantSettingsUid: string): ObservableResult<AIAssistantSettings> {
    return ObservableResult.ofSuccess();
  }

  updateAssistantSettings(cardUid: string, assistantSettingsUid: string, request: AIAssistantSettingsRequest):
    ObservableResult<AIAssistantSettings> {
    return ObservableResult.ofSuccess();
  }

  deleteAssistantSettings(cardUid: string, assistantSettingsUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  searchAIAssistantSettingsTemplates(cardUid: string, phrase: string): ObservableResult<Page<AIAssistantSettingsTemplateDTO>> {
    return ObservableResult.ofSuccess();
  }

  createAIAssistantSettingsFromTemplate(cardUid: string, aiAssistantSettingsTemplateUid: string)
    : ObservableResult<AIAssistantSettings> {
    return ObservableResult.ofSuccess();
  }
}
