/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { JiscOrganization, Organization, OrganizationAuthType } from '../../../shared/models';
import { TriggerUserLogin } from '../../store/user-auth.actions';
import { UserAuthState } from '../../store/user-auth.state';
import { filter } from 'rxjs/operators';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { getClientId } from '../../../shared/helpers/development-domains.helper';
import { VersionHelper } from '../../../shared/helpers/version.helper';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';

@Component({
  templateUrl: './prearrivals-login-form.component.html',
  styleUrls: ['./prearrivals-login-form.component.scss'],
})
export class PrearrivalsLoginFormComponent implements OnInit, OnDestroy {

  @Select(UserAuthState.isLoginInProgress)
  loginInProgress$: Observable<boolean>;

  @Select(UserAuthState.userPreviouslyLoggedIn)
  private userPreviouslyLoggedIn$: Observable<boolean>;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  /** Emits when users click 'Not a member?'. */
  @Output() showSignupForm = new EventEmitter<void>();

  userPreviouslyLoggedIn: boolean;

  /** The authentication form details. */
  authenticationDetails: FormGroup;

  // Defines if the password should be shown to the user
  showPasswordSelected = false;

  organization: Organization;
  organizations: JiscOrganization[] = [];

  isFormAuthType: boolean;
  isSocialAuthType: boolean;
  isLoadingMore: boolean;
  showMaintenanceOverlay: boolean;
  newVersionEnabled = VersionHelper.newVersionEnabled();

  private subscription: Subscription;
  private previousLoginSubscription: Subscription;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private maintenanceService: MaintenanceService,
  ) {
    this.showMaintenanceOverlay = this.maintenanceService.showMaintenanceMessage();
    this.authenticationDetails = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.subscription = combineLatest([this.route.queryParams, this.organizationData$])
      .pipe(filter((data) => !!data[0] && !!data[1])).subscribe(data => {
        const params = data[0];
        this.organization = data[1];

        this.isFormAuthType = true;
        this.isSocialAuthType = this.organization?.authType === OrganizationAuthType.SOCIAL;

        if (params['email']) {
          this.authenticationDetails.controls['email'].setValue(params['email']);
        }
      });

    this.previousLoginSubscription = this.userPreviouslyLoggedIn$.subscribe(data => {
      if (this.userPreviouslyLoggedIn !== false) {
        this.userPreviouslyLoggedIn = data;
      }
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.previousLoginSubscription?.unsubscribe();
  }

  signInUsingLinkedIn() {
    this.redirectToIdpViaRealm('linkedin', this.organization.domain);
  }

  signInUsingGoogle() {
    this.redirectToIdpViaRealm('google', this.organization.domain);
  }

  onNotMemberClick() {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, '/prearrivals/signup');
  }

  onForgotPassword() {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, '/prearrivals/forgot-password');
  }

  submitForm() {
    if (this.authenticationDetails.valid) {
      this.store.dispatch(new TriggerUserLogin(
        this.authenticationDetails.get('email').value?.trim(),
        this.authenticationDetails.get('password').value?.trim()
      ));
    }
  }

  private redirectToIdpViaRealm(idp: string, orgDomain: string) {
    document.location.href =
      `${environment.realmUrl}protocol/openid-connect/auth?` +
      `client_id=${getClientId(orgDomain)}&` +
      'response_type=token&' +
      `nonce=${environment.nonce}&` +
      `kc_idp_hint=${idp}&` +
      `redirect_uri=${this.getRedirectUri(orgDomain)}`;
  }

  private getRedirectUri(orgDomain: string) {
    if (window.location.hostname.includes('localhost')) {
      return 'http://localhost:4200';
    }
    return `https://${orgDomain}`;
  }

}
