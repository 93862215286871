<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="edit-group-dialog-content">

  <div class="dialog-top-info">
    <h1 class="dialog-title title">{{'translations.membersNew.actionBar.editGroupDialog.title' | transloco}}</h1>
    <p class="dialog-description medium">{{'translations.membersNew.actionBar.editGroupDialog.description' | transloco: {checkedMembersCount: checkedMemberIds.length} }}</p>
    <button class="close-dialog-btn"
            mat-icon-button color="primary"
            (click)="closeDialog()"
            [attr.aria-label]="('translations.global.ariaLabels.closeDialog' | transloco)">
      <svg class="svg" role="img" aria-hidden="true">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div class="edit-group-content">
    <div class="edit-group__search-groups-box">
      <ptl-group-search-autocomplete
        #groupSearchAutocomplete
        class="search-field"
        [groups]="searchedGroups"
        [loadingGroups]="loadingGroups"
        [placeholder]="('translations.reviews.label.searchForGroups' | transloco)"
        (searchInputChanged)="onSearchInputChange($event)"
        (searchLoadedMore)="onSearchLoadingMore()"
        (groupSelected)="onGroupSelected($event)">
      </ptl-group-search-autocomplete>
    </div>

    <div class="edit-group__selected-groups-chips-box" *ngIf="selectedGroups?.length">
      <div class="chips-list">
        <div *ngFor="let group of selectedGroups"
          [matTooltip]="group.title"
          class="content-view-tag-item chip-item chip-interactive pointer"
          (click)="removeSelectedGroup(group)"
          (keydown.enter)="removeSelectedGroup(group)">
           <span class="chip-text">
              {{ group.title }}
           </span>
        </div>
      </div>
    </div>
  </div>

  <div class="edit-group-buttons-row">
    <button mat-button (click)="closeDialog()">
      <span class="small bold">{{ 'translations.global.button.cancel' | transloco }}</span>
    </button>
    <button mat-button (click)="removeGroups()" class="suggested">
      <span class="small bold">{{'translations.membersNew.actionBar.editGroupDialog.removeGroups' | transloco}}</span>
    </button>
    <button mat-button (click)="addGroups()" class="suggested">
      <span class="small bold">{{'translations.membersNew.actionBar.editGroupDialog.addGroupsBtnText' | transloco}}</span>
    </button>
  </div>

</div>
