/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { Page } from '../../models/page';
import { LearnerPlaylistSummary } from '../../models/playlist/learner-playlist-summary.model';
import { ObservableResult } from '../../store';
import { LearnerPlaylistReportItem, SubmitPlaylistSummary } from '../../models/playlist/playlist-report.model';
import { FavoritePlaylistSummary, UserPlaylistSubmissionSummary } from '../../models';
import { ReviewerDetails } from '../../../page-modules/playlist/models';

export const LEARNER_PLAYLIST_DATA_SERVICE =
  new InjectionToken<LearnerPlaylistDataService>('[Learner-Playlist] Learner playlist data service');

export interface LearnerPlaylistDataService {

  addPlaylistToFavorites(playlistId: string): ObservableResult<void>;

  removePlaylistFromFavorites(playlistId: string): ObservableResult<void>;

  getRecentPlaylists(page: number, pageSize?: number): ObservableResult<Page<LearnerPlaylistSummary>>;

  getFavoritePlaylists(page: number, pageSize?: number): ObservableResult<Page<FavoritePlaylistSummary>>;

  getReport(playlistId: string): ObservableResult<LearnerPlaylistReportItem[]>;

  getPlaylistSubmissionSummary(playlistId: string): ObservableResult<UserPlaylistSubmissionSummary>;

  submitPlaylistSummary(playlistId: string, request?: SubmitPlaylistSummary):  ObservableResult<void>;

  resubmitPlaylist(playlistId: string): ObservableResult<void>;

  loadPlaylistReviewers(playlistId: string, page: number, size: number, term?: string):  ObservableResult<Page<ReviewerDetails>>;
}
