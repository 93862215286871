/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
// Core Angular Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Modules
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Third-Party Libraries
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgChartsModule } from 'ng2-charts';
import { NgxsModule } from '@ngxs/store';
import { QRCodeModule } from 'angularx-qrcode';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, { Navigation, Pagination } from 'swiper';

// Custom Components
import { LoadableContentComponent } from './components/loadable-content/loadable-content.component';
import { DataLoadErrorComponent } from './components/loadable-content/data-load-error-panel/data-load-error-panel.component';
import { SkeletonViewComponent } from './components/skeleton-view/skeleton-view.component';
import { HeadersEditorComponent } from './components/headers-editor/headers-editor.component';
import { SkeletonListItemComponent } from './components/skeleton-view/skeleton-list-item/skeleton-list-item.component';
import { SkeletonActionItemComponent } from './components/skeleton-view/skeleton-action-item/skeleton-action-item.component';
import { SkeletonTableItemComponent } from './components/skeleton-view/skeleton-table-item/skeleton-table-item.component';
import { ResourcePreviewComponent } from './components/resource-preview/preview.component';
import { ResourceHtmlPreviewComponent } from './components/resource-preview/html-preview/html-preview.component';
import { ResourceDocumentPreviewComponent } from './components/resource-preview/document-preview/document-preview.component';
import { ResourceFormPreviewComponent } from './components/resource-preview/form-preview/form-preview.component';
import { ProfileImageComponent } from './components/profile-image/profile-image.component';
import { ResourceImagePreviewComponent } from './components/resource-preview/image-preview/image-preview.component';
import { TinyMediumEditorComponent } from './components/medium-editor/medium-editor.component';
import { MediumEditorDirective } from './medium-editor/medium-editor.directive';
import { MediaUploadImagePreviewComponent } from './components/resource-preview/image-preview/media-upload-image-preview/media-upload-image-preview.component';
import { SideNavBtnComponent } from './components/side-nav-btn/side-nav-btn.component';
import { OptimizedImageLoaderComponent } from './components/optimized-image-loader/optimized-image-loader.component';
import { ExternalPreviewComponent } from './components/resource-preview/external-preview/external-preview.component';
import { ImageCropDialogComponent } from './components/image-crop-dialog/image-crop-dialog.component';
import { PaginationBlockComponent } from '@app/app/shared/components/pagination-block/pagination-block.component';
import { AddFrameworkTagsComponent } from './components/add-framework-tags/add-framework-tags.component';
import { FrameworkTagsListComponent } from './components/add-framework-tags/framework-tags-list/framework-tags-list.component';
import { FrameworksListComponent } from './components/add-framework-tags/frameworks-list/frameworks-list.component';
import { ChartPreviewComponent } from './components/resource-preview/chart-preview/chart-preview.component';
import { MasonryCardComponent } from '../page-modules/folio/components/view/shared/masonry-section/masonry-card/masonry-card.component';
import { MasonryTextCardComponent } from '../page-modules/folio/components/view/shared/masonry-section/masonry-text-card/masonry-text-card.component';
import { MasonryActionButtonComponent } from '../page-modules/folio/components/view/shared/masonry-section/masonry-action-button/masonry-action-button.component';
import { ContentSectionViewComponent } from '../page-modules/pages/components/page-view/content-section-view/content-section-view.component';
import { PageFrameworkTrackerSectionComponent } from '../page-modules/pages/components/page-view/page-framework-tracker-section/page-framework-tracker-section.component';
import { PlaylistFeaturedCardComponent } from './components/playlist-featured-card/playlist-featured-card.component';
import { AchievementsSectionComponent } from '../page-modules/folio/components/view/shared/achievements-section/achievements-section.component';
import { FeaturedCardBottomComponent } from './components/layout/featured-card-bottom/featured-card-bottom.component';
import { SkeletonPageItemComponent } from './components/skeleton-view/skeleton-page-item/skeleton-page-item.component';
import { DiagnosticsViewChoiceQuestionTypeComponent } from './components/choice-question-type/choice-question-type.component';
import { AddFromMomentsDialogComponent } from './components/resource-preview/form-preview/form-collector-preview/add-from-moments-dialog/add-from-moments-dialog.component';
import { CollectorReviewComponent } from './components/resource-preview/form-preview/collector-review/collector-review.component';
import { PlaylistCardRequiredIconComponent } from '../page-modules/playlist/shared/create/cards-section/required-icon/playlist-card-required-icon.component';
import { NextPreviousButtonsComponent } from './components/next-previous-buttons/next-previous-buttons.component';
import { LikeInfoComponent } from './components/layout/like-info/like-info.component';

import { DiagnosticsViewSliderQuestionTypeComponent } from '../page-modules/diagnostics/components/view/questions/slider-question-type/slider-question-type.component';
import { PersonalIndicatorTraitChartComponent } from '../page-modules/personality-indicator/pi-charts/pi-trait-chart/pi-trait-chart.component';
import { PersonalIndicatorTeamRoleChartComponent } from '../page-modules/personality-indicator/pi-charts/pi-team-role-chart/pi-team-role-chart.component';
import {
  PersonalIndicatorBigSixChartComponent
} from '../page-modules/personality-indicator/pi-charts/pi-big-six-chart/pi-big-six-chart.component';
import {
  PersonalIndicatorSpectrumChartComponent
} from '../page-modules/personality-indicator/pi-charts/pi-spectrum-chart/pi-spectrum-chart.component';
import {
  PersonalIndicatorJungianChartComponent
} from '../page-modules/personality-indicator/pi-charts/pi-jungian-chart/pi-jungian-chart.component';
import { ResourceVideoPreviewComponent } from './components/resource-preview/video-preview/video-preview.component';
import { ResourceAudioPreviewComponent } from './components/resource-preview/audio-preview/audio-preview.component';
import {
  DiagnosticsViewDragDropQuestionTypeComponent
} from './components/drag-drop-question-type/drag-drop-question-type.component';
import {
  FormCollectorTableComponent
} from './components/resource-preview/form-preview/form-collector-preview/form-collector-table/form-collector-table.component';
import {
  CollectorDetailDialogComponent
} from './components/resource-preview/form-preview/form-collector-preview/form-collector-table/collector-detail-dialog/collector-detail-dialog.component';
import {
  PageCategoriesSectionComponent
} from '../page-modules/pages/components/page-view/page-categories-section/page-categories-section.component';
import { WorkspacesSidebarComponent } from './components/workspaces-sidebar/workspaces-sidebar.component';
import { AddWorkspacesComponent } from '../page-modules/account/components/add-workspace/add-workspaces.component';
import {
  WebLinkCardEditorComponent
} from '../page-modules/playlist/shared/create/new-web-link-card-dialog/web-link-card-editor/web-link-card-editor.component';
import { SideNavState } from './side-nav-tree/store/side-nav.state';
import {
  DataCollectionDialogComponent
} from './components/data-collection/data-collection-dialog/data-collection-dialog.component';
import { ReviewState } from '../page-modules/playlist/store/create/review/review.state';
import { ApiMembersDataService } from '../page-modules/admin/services/members/api-members-data.service';
import { ApiSharedReviewDataService } from './services/review/api-review-data.service';
import { PrintPageFooterComponent } from './components/print-page/print-page-footer/print-page-footer.component';
import { PlaylistFeaturedLinkComponent } from './components/playlist-featured-link/playlist-featured-link.component';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { BreadcrumbsModule } from './breadcrumbs/breadcrumbs.module';
import { LanguageTabsComponent } from './components/language-tabs/language-tabs.component';
import {
  DiscoveryPageHeadComponent
} from '../page-modules/pages/components/discovery/discovery-page-head/discovery-page-head.component';
import { ShareDialogComponent } from '../page-modules/pages/components/view/share-dialog/share-dialog.component';
import {
  FeaturedSectionViewComponent
} from '../page-modules/pages/components/page-view/featured-section-view/featured-section-view.component';
import { CardProgressComponent } from './components/layout/card-progress/card-progress.component';
import { StorePagesCardComponent } from './components/store-pages-card/store-pages-card.component';
import {
  UserSearchAutocompleteComponent
} from './components/user-search-autocomplete/user-search-autocomplete.component';
import { CardFooterComponent } from './components/layout/card-footer/card-footer.component';
import { FavoriteButtonComponent } from './components/layout/favorite-button/favorite-button.component';
import { PagePublishComponent } from '../page-modules/pages/components/edit/publish/publish.component';
import { IconsetDialogComponent } from './components/iconset-dialog/iconset-dialog.component';
import { DataFormsDialogComponent } from './components/data-forms/data-forms-dialog/data-forms-dialog.component';
import {
  PageTableauSectionComponent
} from '../page-modules/pages/components/page-view/page-tableau-section/page-tableau-section.component';
import {
  GroupListComponent
} from './components/data-forms/data-forms-dialog/group-list-drop-down/group-list/group-list.component';
import {
  GroupListDropDownComponent
} from './components/data-forms/data-forms-dialog/group-list-drop-down/group-list-drop-down.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { SideNavTreeSectionComponent } from './side-nav-tree/side-nav-tree-section/side-nav-tree-section.component';
import { SideNavTreeItemComponent } from './side-nav-tree/side-nav-tree-item/side-nav-tree-item.component';
import { WorkspacesNavComponent } from './side-nav-tree/workspaces-nav/workspaces-nav.component';
import { ApiSidebarTreeDataService } from './services/sidebar-tree/api-sidebar-tree-data.service';
import { SideNavTreeMainComponent } from './side-nav-tree/main-side-nav-tree/main-side-nav-tree.component';
import { MobileMenuSideNavComponent } from './side-nav-tree/mobile-menu-side-nav/mobile-menu-side-nav.component';
import { LikeInfoNewComponent } from './components/layout/like-info-new/like-info-new.component';
import { FavoriteNewButtonComponent } from './components/layout/favorite-new-button/favorite-new-button.component';
import { CardProgressCircleComponent } from './components/layout/card-progress-circle/card-progress-circle.component';
import {
  BarChartPreviewComponent
} from './components/resource-preview/chart-preview/bar-chart/bar-chart-preview.component';
import {
  UserSearchAutocompleteNewComponent
} from './components/user-search-autocomplete-new/user-search-autocomplete-new.component';
import {
  PlaylistFeaturedCardNewComponent
} from '../app-new-version/shared/components/playlist-featured-card-new/playlist-featured-card-new.component';
import {
  PlaylistUpcomingCardNewComponent
} from '../app-new-version/shared/components/playlist-upcoming-card-new/playlist-upcoming-card-new.component';
import { ReviewStatusComponent } from './components/review-status/review-status.component';
import { PaginationComponent } from '@app/app/shared/components/pagination/pagination.component';
import { ToastContainerComponent } from './components/toast-notifications/toast-container.component';
import { ToastComponent } from './components/toast-notifications/toast/toast.component';
import { NameFilterComponent } from './components/filters/name-filter/name-filter.component';
import { FilterBtnComponent } from './components/filters/filter-btn/filter-btn.component';
import { ReviewStatusFilterComponent } from './components/filters/review-status-filter/review-status-filter.component';
import { GroupFilterComponent } from './components/filters/group-filter/group-filter.component';
import {
  GroupSearchAutocompleteComponent
} from './components/group-search-autocomplete/group-search-autocomplete.component';
import {
  SubmissionDateFilterComponent
} from './components/filters/submission-date-filter/submission-date-filter.component';
import {
  TinyMceEditorDirective
} from '../editor/components/editor-content-view/editor-content/tinymce-editor/tinymce-editor-dir/tinymce-editor.dir';
import { AdminsSideNavComponent } from './side-nav-tree/admins-side-nav/admins-side-nav.component';
import {
  UnsplashImagesSearchBoxComponent
} from './components/unsplash-images-search-box/unsplash-images-search-box.component';
import { ApiUnsplashImagesDataService } from '../page-modules/admin/services/images/api-unsplash-images-data.service';
import { QRCodeComponent } from './components/qr-code/qr-code.component';
import { AdminMembersComponent } from '../page-modules/admin/components/members/members.component';
import {
  AdminMemberListComponent
} from '../page-modules/admin/components/members/admin-member-list/admin-member-list.component';
import {
  AdminMembersControlPanelComponent
} from '../page-modules/admin/components/members/admin-member-control-panel/admin-member-control-panel.component';
import { RoleFilterComponent } from './components/filters/role-filter/role-filter.component';
import { AttendanceFilterComponent } from './components/filters/attendance-filter/attendance-filter.component';
import { DataFormsErrorComponent } from './components/data-forms/data-forms-error/data-forms-error.component';
import { SocialShareUrlComponent } from './components/social-share/social-share-url.component';
import {
  AdminMembersActionBarComponent
} from '../page-modules/admin/components/members/admin-member-action-bar/admin-member-action-bar.component';
import {
  AdminMemberTableComponent
} from '../page-modules/admin/components/members/admin-member-list/admin-member-table/admin-member-table.component';
import { HeadersEditorNewComponent } from './components/headers-editor-new/headers-editor-new.component';
import { PageSummaryComponent } from '../page-modules/pages/components/edit/summary/summary.component';
import { AddLanguageSettingsComponent } from './components/add-language-settings/add-language-settings.component';
import {
  EditRoleDialogComponent
} from '../page-modules/admin/components/members/admin-member-action-bar/edit-role-dialog/edit-role-dialog.component';
import {
  RoleSearchAutocompleteComponent
} from './components/role-search-autocomplete/role-search-autocomplete.component';
import {
  EditGroupDialogComponent
} from '../page-modules/admin/components/members/admin-member-action-bar/edit-group-dialog/edit-group-dialog.component';
import { ActivityDateFilterComponent } from './components/filters/activity-date-filter/activity-date-filter.component';
import { CompletionFilterComponent } from './components/filters/completion-filter/completion-filter.component';
import {
  AlumniCsvUploadDialogComponent
} from '../page-modules/admin/components/members/admin-member-control-panel/alumni-csv-upload-dialog/alumni-csv-upload-dialog.component';
import { HelpCenterComponent } from './components/help-center/help-center.component';
import {
  CoverImageUploadDialogComponent
} from './components/cover-image-upload-dialog/cover-image-upload-dialog.component';
import { AddPageTemplateComponent } from './components/add-page-template/add-page-template.component';
import {
  ImportUsersDialogComponent
} from '../page-modules/admin/components/members/admin-member-control-panel/import-users-dialog/import-users-dialog.component';
import { FrameworkCreateComponent } from './components/add-framework-tags/create-framework/framework-create.component';
import {
  FrameworkTagsListTreeComponent
} from './components/add-framework-tags/framework-tags-list-tree/framework-tags-list-tree.component';
import { LoaderComponent } from './components/loader/loader.component';
import {
  UsersCsvUploadDialogComponent
} from '../page-modules/admin/components/members/admin-member-control-panel/users-csv-upload-dialog/users-csv-upload-dialog.component';
import { AlertComponent } from './components/alert/alert.component';
import {
  UsersCsvUploadErrorsDialogComponent
} from '../page-modules/admin/components/members/admin-member-control-panel/users-csv-upload-errors-dialog/users-csv-upload-errors-dialog.component';
import { EditorComponent } from '@tinymce/tinymce-angular';
import {
  FormConnectedTextboxComponent
} from './components/resource-preview/form-preview/form-connected-textbox/form-connected-textbox.component';
import { DisableTagPipe } from './components/add-framework-tags/framework-pipes/DisableTagPipe';
import { ResizableModule } from 'angular-resizable-element';
import {
  FormQuestionPreviewComponent
} from './components/resource-preview/form-preview/form-question-preview/form-question-preview.component';
import { AddTagsComponent } from './components/add-tags/add-tags.component';
import {
  DiagnosticsViewChoiceQuestionTypeNewComponent
} from './components/choice-question-type-new/choice-question-type-new.component';
import {
  DiagnosticsViewGridQuestionTypeComponent
} from './components/diagnostics-view-grid-question-type/diagnostics-view-grid-question-type.component';
import { SegmentBarComponent } from '../page-modules/diagnostics/components/view/segment-bar/segment-bar.component';
import { ToolbarTooltipComponent } from './editor-js/components/toolbar-tooltip/toolbar-tooltip.component';
import { EditorFileCropperComponent } from './editor-js/components/editor-file-cropper/editor-file-cropper.component';
import {
  SkeletonAppointmentListItemComponent
} from './components/skeleton-view/skeleton-appointment-list-item/skeleton-appointment-list-item.component';
import {
  SkeletonReportListItemComponent
} from './components/skeleton-view/skeleton-report-list-item/skeleton-report-list-item.component';
import {
  UpcomingEventListComponent
} from '../page-modules/upcoming/components/content-new/upcoming-event-list/upcoming-event-list.component';
import {
  UpcomingEventComponent
} from '../page-modules/upcoming/components/content-new/upcoming-event-list/upcoming-event/upcoming-event.component';
import {
  SkeletonEventItemComponent
} from './components/skeleton-view/skeleton-event-item/skeleton-event-item.component';
import {
  ResourceStreamVideoPreviewComponent
} from './components/resource-preview/stream-video-preview/stream-video-preview.component';
import { VideoUploadDialogComponent } from './components/video-upload-dialog/video-upload-dialog.component';
import {
  EventCardsRemindersComponent
} from '@app/app/shared/components/event-reminders/event-reminders.component';
import {
  EventCardsTicketsComponent
} from '@app/app/shared/components/event-tickets/event-tickets.component';
import {
  SkeletonBadgeComponent
} from '@app/app/shared/components/skeleton-view/skeleton-badge/skeleton-badge.component';
import { FolioMasonryCardComponent } from '../page-modules/folio/components/view/shared/masonry-section/folio-masonry-card/folio-masonry-card.component';
import { ProgressBarUploadComponent } from './components/progress-bar-upload/progress-bar-upload.component';
import {
  AdvancedImageCropDialogComponent
} from '@app/app/shared/components/advanced-image-crop-dialog/advanced-image-crop-dialog.component';
import { ShareBadgeUrlComponent } from '@app/app/shared/components/share-badge-url/share-badge-url.component';
import {
  PublicBadgeContentComponent
} from '@app/app/page-modules/folio/components/view/public-badge-content/public-badge-content-new.component';
import { SkeletonImageItemComponent } from './components/skeleton-view/skeleton-image-item/skeleton-image-item.component';
import {
  AdminAddMemberComponent
} from '@app/app/page-modules/admin/components/members/admin-member-list/admin-add-member/admin-add-member.component';
import { EventCardsDateComponent } from './components/event-date/event-date.component';

// Directives and Pipes
import { MasonryDirective } from './directives/masonry.directive';
import { FileDropDirective } from './directives/file-drop.directive';
import { ImageSourceDirective } from './directives/image-source.directive';
import { BetaFeatureDirective, NotBetaFeatureDirective } from './directives/beta-feature.directive';
import { BetaAccessDirective } from './directives/beta-access.directive';
import { ConditionalBetaFeatureDirective } from './directives/conditional-beta-feature.directive';
import { OptionsScrollDirective } from './directives/autocomplete-scroll.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { SvgUnifyDirective } from './directives/svg-unify-directive';
import { LongPressDirective } from './directives/long-press.directive';
import { CopyTextDirective } from './directives/copy-text.directive';
import { ScrollNearEndDirective } from './directives/scroll-near-end.directive';
import { ImageSizePipe } from './pipes/image-size.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { UpperFirstPipe } from './pipes/upper-first.pipe';
import { SingularPluralNounPipe } from './pipes/singular-plural-noun.pipe';
import { ReviewStatusPipe } from './pipes/review-status.pipe';
import { ArraySortPipe } from './pipes/array-sort.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SmartDateFormatPipe } from './pipes/smart-date.pipe';
import { UrlifyTextPipe } from './pipes/urlify-text.pipe';
import { GetDataByLangCodePipe } from './pipes/data-by-language-code.pipe';
import { PictureNamePipe } from './pipes/file-name-from-url.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { StringToArrayPipe } from '@app/app/shared/pipes/string-to-array.pipe';
import { DateFormatPipe } from '@app/app/shared/pipes/date-format.pipe';
import { DiagnosticsViewReportDialogComponent } from '../page-modules/diagnostics/components/view/diagnostics-view-report/report-dialog/report-dialog.component';
import { DiagnosticsReportStandardSectionComponent } from '../page-modules/diagnostics/components/view/diagnostics-view-report/standard-section/standard-section.component';
import { DiagnosticsReportResourceSectionComponent } from '../page-modules/diagnostics/components/view/diagnostics-view-report/resource-section/resource-section.component';
import { DiagnosticsReportConditionalSectionComponent } from '../page-modules/diagnostics/components/view/diagnostics-view-report/conditional-section/conditional-section.component';
import { DiagnosticsReportResourceSectionCardComponent } from '../page-modules/diagnostics/components/view/diagnostics-view-report/resource-section/resource-section-card/resource-section-card.component';
import { DiagnosticsReportConditionalSectionViewComponent } from '../page-modules/diagnostics/components/view/diagnostics-view-report/diagnostics-report-conditional-section-view/diagnostics-report-conditional-section-view.component';
import { CamelToReadablePipe } from '@app/app/shared/pipes/camel-to-readable.pipe';
import { BooleanMemberFiltersComponent } from '../page-modules/admin/components/members/boolean-member-filters/boolean-member-filters.component';
import { MemberFilterComponent } from '../page-modules/admin/components/members/boolean-member-filters/member-filter/member-filter.component';
import { MemberFilterControlsComponent } from '../page-modules/admin/components/members/boolean-member-filters/member-filter/member-filter-controls/member-filter-controls.component';
import { AddMemberFilterComponent } from '../page-modules/admin/components/members/boolean-member-filters/add-member-filter/add-member-filter.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { FormAiAssistantComponent } from '@app/app/shared/components/resource-preview/form-preview/form-ai-assistant/form-ai-assistant.component';
import { FormPreviewPrivateNoteComponent } from '@app/app/shared/components/resource-preview/form-preview/form-private-note/form-private-note.component';
import { FormPreviewCheckboxComponent } from '@app/app/shared/components/resource-preview/form-preview/form-checkbox/form-checkbox.component';
import { FormPreviewFileUploadComponent } from '@app/app/shared/components/resource-preview/form-preview/form-file-upload/form-preview-file-upload.component';
import { FormPreviewBookingComponent } from '@app/app/shared/components/resource-preview/form-preview/form-booking/form-booking.component';
import { FormLinkInputComponent } from '@app/app/shared/components/resource-preview/form-preview/form-link-input/form-link-input.component';
import { FormUpdateFooterComponent } from './components/resource-preview/form-preview/form-update-footer/form-update-footer.component';
import { FormReviewEventsListComponent } from './components/resource-preview/form-preview/form-update-footer/form-review-events-list/form-review-events-list.component';
import { FormPreviewTextboxComponent } from './components/resource-preview/form-preview/form-textbox/form-textbox.component';
import { FormCollectorPreviewComponent } from './components/resource-preview/form-preview/form-collector-preview/form-collector-preview.component';
import { PagesTargetAudienceComponent } from '../page-modules/pages/components/edit/target-audience/target-audience.component';
import { QuizResultsDialogComponent } from '../page-modules/resource/components/view/quizzes/quiz-results-dialog/quiz-results-dialog.component';
import { QuizResultsReportComponent } from '../page-modules/resource/components/view/quizzes/quiz-results-report/quiz-results-report.component';
import { FormPreviewQuizComponent } from './components/resource-preview/form-preview/form-quiz/form-preview-quiz.component';
import { QuizDialogComponent } from '../page-modules/resource/components/view/quizzes/quiz-dialog/quiz-dialog.component';
import { ReviewEventComponent } from './components/review-event/review-event.component';

const COMPONENTS = [
  LoadableContentComponent,
  LoaderComponent,
  DataLoadErrorComponent,
  SideNavTreeMainComponent,
  SideNavTreeSectionComponent,
  SideNavTreeItemComponent,
  AdminsSideNavComponent,
  WorkspacesNavComponent,
  MobileMenuSideNavComponent,
  WorkspacesSidebarComponent,
  ResourcePreviewComponent,
  ResourceHtmlPreviewComponent,
  ResourceDocumentPreviewComponent,
  ResourceFormPreviewComponent,
  ProfileImageComponent,
  MediaUploadImagePreviewComponent,
  ResourceImagePreviewComponent,
  ResourceVideoPreviewComponent,
  ResourceStreamVideoPreviewComponent,
  ResourceAudioPreviewComponent,
  TinyMediumEditorComponent,
  MediumEditorDirective,
  TinyMceEditorDirective,
  CopyTextDirective,
  ClickOutsideDirective,
  FormUpdateFooterComponent,
  FormReviewEventsListComponent,
  FormPreviewTextboxComponent,
  FormPreviewPrivateNoteComponent,
  FormPreviewCheckboxComponent,
  FormPreviewFileUploadComponent,
  FormCollectorPreviewComponent,
  FormCollectorTableComponent,
  FormAiAssistantComponent,
  CollectorDetailDialogComponent,
  AddFromMomentsDialogComponent,
  CollectorReviewComponent,
  ChartPreviewComponent,
  BarChartPreviewComponent,
  SideNavBtnComponent,
  SkeletonViewComponent,
  SkeletonBadgeComponent,
  SkeletonListItemComponent,
  SkeletonReportListItemComponent,
  HeadersEditorComponent,
  HeadersEditorNewComponent,
  WebLinkCardEditorComponent,
  SkeletonActionItemComponent,
  SkeletonAppointmentListItemComponent,
  SkeletonEventItemComponent,
  SkeletonTableItemComponent,
  SkeletonImageItemComponent,
  OptimizedImageLoaderComponent,
  MasonryDirective,
  ExternalPreviewComponent,
  AddWorkspacesComponent,
  ImageCropDialogComponent,
  AdvancedImageCropDialogComponent,
  IconsetDialogComponent,
  PaginationBlockComponent,
  PaginationComponent,
  AddFrameworkTagsComponent,
  FrameworkTagsListComponent,
  FrameworksListComponent,
  FrameworkCreateComponent,
  FrameworkTagsListTreeComponent,
  FileDropDirective,
  ImageSourceDirective,
  FormPreviewQuizComponent,
  FormPreviewBookingComponent,
  DiagnosticsViewGridQuestionTypeComponent,
  DiagnosticsViewDragDropQuestionTypeComponent,
  DiagnosticsViewChoiceQuestionTypeComponent,
  DiagnosticsViewChoiceQuestionTypeNewComponent,

  MasonryCardComponent,
  MasonryTextCardComponent,
  MasonryActionButtonComponent,
  ContentSectionViewComponent,
  PageFrameworkTrackerSectionComponent,
  PageTableauSectionComponent,
  PageCategoriesSectionComponent,
  DiscoveryPageHeadComponent,
  FeaturedSectionViewComponent,
  PlaylistFeaturedCardComponent,
  PlaylistFeaturedCardNewComponent,
  PlaylistFeaturedLinkComponent,
  PlaylistUpcomingCardNewComponent,
  UpcomingEventListComponent,
  UpcomingEventComponent,
  StorePagesCardComponent,
  AchievementsSectionComponent,
  FeaturedCardBottomComponent,
  SkeletonPageItemComponent,
  MemberFilterComponent,
  BooleanMemberFiltersComponent,
  AddMemberFilterComponent,
  MemberFilterControlsComponent,
  AdminMembersComponent,
  AdminMemberTableComponent,
  AdminAddMemberComponent,
  AdminMemberListComponent,
  AdminMembersControlPanelComponent,
  AdminMembersActionBarComponent,
  EditRoleDialogComponent,
  EditGroupDialogComponent,
  AlumniCsvUploadDialogComponent,
  UsersCsvUploadDialogComponent,
  UsersCsvUploadErrorsDialogComponent,
  QuizDialogComponent,
  QuizResultsDialogComponent,
  QuizResultsReportComponent,
  DataCollectionDialogComponent,
  PlaylistCardRequiredIconComponent,
  NextPreviousButtonsComponent,
  LikeInfoComponent,
  LikeInfoNewComponent,
  CardFooterComponent,
  FavoriteButtonComponent,
  FavoriteNewButtonComponent,
  CardProgressComponent,
  CardProgressCircleComponent,
  PrintPageFooterComponent,
  PersonalIndicatorTraitChartComponent,
  PersonalIndicatorTeamRoleChartComponent,
  PersonalIndicatorBigSixChartComponent,
  PersonalIndicatorSpectrumChartComponent,
  PersonalIndicatorJungianChartComponent,
  LanguageTabsComponent,
  ShareDialogComponent,
  RoleSearchAutocompleteComponent,
  UserSearchAutocompleteComponent,
  UserSearchAutocompleteNewComponent,
  GroupSearchAutocompleteComponent,
  PagePublishComponent,
  PageSummaryComponent,
  PagesTargetAudienceComponent,
  DataFormsDialogComponent,
  GroupListComponent,
  GroupListDropDownComponent,
  TooltipComponent,
  HelpCenterComponent,
  ReviewStatusComponent,
  ReviewEventComponent,
  ToastComponent,
  ToastContainerComponent,
  FilterBtnComponent,
  NameFilterComponent,
  GroupFilterComponent,
  RoleFilterComponent,
  ActivityDateFilterComponent,
  AttendanceFilterComponent,
  SubmissionDateFilterComponent,
  ReviewStatusFilterComponent,
  CompletionFilterComponent,
  QRCodeComponent,
  SvgUnifyDirective,
  LongPressDirective,
  UnsplashImagesSearchBoxComponent,
  DataFormsErrorComponent,
  SocialShareUrlComponent,
  ShareBadgeUrlComponent,
  PublicBadgeContentComponent,
  AddLanguageSettingsComponent,
  DiagnosticsViewReportDialogComponent,
  DiagnosticsReportStandardSectionComponent,
  DiagnosticsReportResourceSectionComponent,
  DiagnosticsReportConditionalSectionComponent,
  DiagnosticsReportConditionalSectionViewComponent,
  SegmentBarComponent,
  DiagnosticsReportResourceSectionCardComponent,
  CoverImageUploadDialogComponent,
  VideoUploadDialogComponent,
  AddPageTemplateComponent,
  AddTagsComponent,
  ImportUsersDialogComponent,
  AlertComponent,
  FormConnectedTextboxComponent,
  FormLinkInputComponent,
  EditorFileCropperComponent,
  ToolbarTooltipComponent,
  FormQuestionPreviewComponent,
  EventCardsDateComponent,
  EventCardsRemindersComponent,
  FolioMasonryCardComponent,
  ProgressBarUploadComponent,
  EventCardsTicketsComponent,
  DiagnosticsViewSliderQuestionTypeComponent
];

const DIRECTIVES = [
  BetaFeatureDirective,
  NotBetaFeatureDirective,
  BetaAccessDirective,
  OptionsScrollDirective,
  TooltipDirective,
  ConditionalBetaFeatureDirective,
  ScrollNearEndDirective
];

const PIPES = [
  SingularPluralNounPipe,
  GetDataByLangCodePipe,
  SmartDateFormatPipe,
  ReviewStatusPipe,
  PictureNamePipe,
  UpperFirstPipe,
  UrlifyTextPipe,
  DisableTagPipe,
  ImageSizePipe,
  ArraySortPipe,
  SafeHtmlPipe,
  TruncatePipe,
  SafeUrlPipe,
  StringToArrayPipe,
  DateFormatPipe,
  CamelToReadablePipe
];

SwiperCore.use([Navigation, Pagination]);

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  imports: [
    NgxsModule.forFeature([SideNavState, ReviewState]),
    TranslocoRootModule,
    CommonModule,
    DragDropModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatNativeDateModule,
    RouterModule,
    ImageCropperModule,
    BreadcrumbsModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    NgChartsModule,
    MatTabsModule,
    TranslocoRootModule,
    MatDividerModule,
    MatExpansionModule,
    EditorComponent,
    QRCodeModule,
    SwiperModule,
    ResizableModule
  ],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    BreadcrumbsModule
  ],
  entryComponents: [
    ImageCropDialogComponent,
    AdvancedImageCropDialogComponent,
    IconsetDialogComponent,
    QuizDialogComponent,
    DataCollectionDialogComponent,
    AddFromMomentsDialogComponent,
    CollectorDetailDialogComponent,
    AddWorkspacesComponent,
    AlumniCsvUploadDialogComponent,
    UsersCsvUploadDialogComponent,
    ShareDialogComponent,
  ],
  providers: [
    ApiMembersDataService,
    ApiUnsplashImagesDataService,
    ApiSharedReviewDataService,
    ApiSidebarTreeDataService,
  ],
})
export class SharedModule {
}
