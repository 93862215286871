<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<section class="conditional-report-section" [class.first]="isFirst" [id]="section?.uid">

  <div class="conditional-report-section__title-box" *ngIf="section?.showTitle && !section?.trimmedFromStart">
    <div class="icon" *ngIf="section?.learningGoalWrapper?.icon && section?.learningGoalWrapper?.icon?.iconImageUrl">
      <img src="{{ section?.learningGoalWrapper.icon.iconImageUrl }}" alt="" width="50" height="50">
    </div>
    <h2 class="conditional-report-section__title" tabindex="0">{{ section?.learningGoalWrapper.tagTitle }}</h2>
  </div>

  <div class="conditional-report-section__intro">
    <div class="conditional-report-section__intro__chart"
         [style.visibility]="section?.trimmedFromStart ? 'hidden' : ''"
         *ngIf="section?.learningGoalWrapper?.competencyLevels[0]?.showGraph && section?.learningGoalWrapper?.competencyLevels[0]?.graphType === 'CIRCLE'">

      <div class="radial-chart">
        <svg width="120" height="120" viewBox="0 0 120 120" class="progress__svg" [attr.aria-label]="('translations.global.ariaLabels.icons.progressCircle' | transloco )">
          <circle [attr.r]="radius - 4"
                  cx="60"
                  cy="60"
                  stroke-width="4"
                  stroke-dasharray="0 6"
                  stroke-linecap="round"
                  class="progress__meter"/>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stop-color="#4DA7D8"/>
              <stop offset="100%" stop-color="#5FD5BB"/>
            </linearGradient>
          </defs>
          <circle [style.strokeDasharray]="circumference"
                  [style.strokeDashoffset]="dashoffset"
                  [attr.r]="radius"
                  cx="60"
                  cy="60"
                  stroke-width="12"
                  [style.stroke]="svgStroke"
                  class="progress__value"/>
        </svg>
      </div>

    </div>
    <div class="conditional-report-section__intro__text">
      <ptl-resource-preview
        [previewContent]="section?.learningGoalWrapper.dynamicContent">
      </ptl-resource-preview>
    </div>
  </div>

  <div class="conditional-report-section-bar-chart" *ngIf="section?.learningGoalWrapper?.competencyLevels[0]?.showGraph">
    <div class="bar-chart" *ngIf="section?.learningGoalWrapper?.competencyLevels[0]?.graphType === 'BAR'">
      <div class="bar-chart-icons" *ngIf="section?.learningGoalWrapper?.minIcon || section?.learningGoalWrapper?.maxIcon">
        <div class="min-icon">
          <img *ngIf="section?.learningGoalWrapper?.minIcon && section?.learningGoalWrapper?.minIcon?.iconImageUrl"
               src="{{ section?.learningGoalWrapper.minIcon.iconImageUrl }}" alt="" width="30" height="30">
        </div>
        <div class="max-icon">
          <img *ngIf="section?.learningGoalWrapper?.maxIcon && section?.learningGoalWrapper?.maxIcon?.iconImageUrl"
               src="{{ section?.learningGoalWrapper.maxIcon.iconImageUrl }}" alt="" width="30" height="30">
        </div>
      </div>
      <div class="progress">
        <div class="progress-inner"
             [style.background]="progressColor ? progressColor : ''"
             [style.width]="scoredPercentage + '%'">
        </div>
      </div>
    </div>
  </div>

  <div class="conditional-report-section__competency">
    <ng-container *ngFor="let level of section?.learningGoalWrapper.competencyLevels">

      <h3 class="conditional-report-section__competency__title" tabindex="0" *ngIf="section?.showLevels">
        <span>{{ 'translations.yourConfidenceLevel' | transloco }}</span> {{ level.levelTitle[0].value }}
      </h3>
      <div class="conditional-report-section__competency__content">
        <ptl-resource-preview
          [previewContent]="level.dynamicContent">
        </ptl-resource-preview>
      </div>
    </ng-container>
  </div>

</section>
