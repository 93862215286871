<div class="floating-link-box">
  <form class="link-input-form-box"
        [formGroup]="mediaUrlData">
    <mat-form-field class="link-input-form-field">
      <input
        matInput
        type="url"
        required
        formControlName="contentUrl"
        [placeholder]="'translations.mediaContent.label.uploadImagePlaceholder' | transloco"
        [readonly]="mediaEmbeddableContentLoading"
        (keydown.enter)="onLinkEnter()">
      <mat-error
        *ngIf="mediaUrlData.controls['contentUrl'].invalid">{{ 'translations.mediaContent.message.error.invalidUrl' | transloco }}
      </mat-error>
      <mat-spinner
        class="link-input-form-spinner"
        *ngIf="mediaEmbeddableContentLoading"
        [diameter]="25"
      ></mat-spinner>
    </mat-form-field>
  </form>
</div>
