/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { Page } from '../../../shared/models/page';
import { ReviewerSummary, UserReviewsCount } from '../../../shared/models/review/review.model';
import { ObservableResult } from '../../../shared/store';
import { ReviewSettingsDetails } from '../../playlist/models/reviews-settings.model';
import {
  CommentRequest,
  ReviewAdminRequest, ReviewerAdminRequest,
  ReviewItem,
  ReviewRequest,
  ReviewSummaryDetailed,
  SubmissionsReviewRequest
} from '../models';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const REVIEWS_DATA_SERVICE = new InjectionToken<ReviewsDataService>('[Reviews] DataService');

/** The reviews data service. */
export interface ReviewsDataService {

  getReviews(page: number, pageSize: number, term?: string): ObservableResult<Page<ReviewItem>>;

  getReviewItem(itemUri: string): ObservableResult<ReviewItem>;

  getReviewSummaryDetailed(reviewItemUri: string, learnerUserUid: string): ObservableResult<ReviewSummaryDetailed>;

  /**
   * Add Comment
   *
   * @param reviewUid the UID of review.
   * @param cardUid the UID of card
   * @param formUid the UID of form
   * @param request added comment detail.
   */
  addComment(reviewUid: string, cardUid: string, formUid: string, request: CommentRequest): ObservableResult<void>;

  /**
   * Edit Comment
   *
   * @param reviewUid the UID of review.
   * @param noteUid the UID of form
   * @param request edited comment detail.
   */
  editComment(reviewUid: string, noteUid: string, request: CommentRequest): ObservableResult<void>;

  /**
   * Delete Comment
   *
   * @param reviewUid the UID of review.
   * @param noteUid the UID of playlist.
   */
  deleteComment(reviewUid: string, noteUid: string): ObservableResult<void>;

  /**
   * Reject review
   *
   * @param reviewUid the UID of review.
   * @param request the review request
   */
  rejectReview(reviewUid: string, request: ReviewRequest): ObservableResult<void>;

  /**
   * Approve review
   *
   * @param reviewUid the UID of review.
   * @param request the UID of playlist.
   */
  approveReview(reviewUid: string, request: ReviewRequest): ObservableResult<void>;

  /**
   * Request change of review
   *
   * @param reviewUid the UID of review.
   * @param playlistUid the UID of playlist.
   * @param request a request object.
   */
  requestChangeOfReview(reviewUid: string, playlistUid: string, request: ReviewRequest): ObservableResult<void>;

  /**
   * Request resubmission
   *
   * @param request SubmissionsReviewRequest.
   */
  requestResubmission(request: SubmissionsReviewRequest): ObservableResult<void>;

  /**
   * Request resubmission all
   *
   * @param reviewUid the UID of review.
   * @param request SubmissionsReviewRequest.
   */
  requestAllResubmission(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void>;

  /**
   * Request accept reviews
   *
   * @param request SubmissionsReviewRequest.
   */
  acceptReviews(request: SubmissionsReviewRequest): ObservableResult<void>;

  /**
   * Request accept all reviews
   *
   * @param reviewUid the UID of review.
   * @param request SubmissionsReviewRequest.
   */
  acceptAllReviews(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void>;


  /**
   * Request reject reviews
   *
   * @param request SubmissionsReviewRequest.
   */
  rejectReviewRequest(request: SubmissionsReviewRequest): ObservableResult<void>;

  /**
   * Request reject all reviews
   *
   * @param reviewUid the UID of review.
   * @param request SubmissionsReviewRequest.
   */
  rejectAllReviewRequest(reviewUid: string, request: SubmissionsReviewRequest): ObservableResult<void>;

  /** Admin action: close review on demand
   * Requires `can_manage_reviews` privilege
   *
   * @param request
   * @param isAccepted: boolean
   */
  closeReview(request: ReviewAdminRequest, isAccepted: boolean): ObservableResult<void>;

  /** Admin action: re-open review on demand and request re-submission from the user
   * Requires `can_manage_reviews` privilege
   *
   * @param request
   */
  reOpenReview(request: ReviewAdminRequest): ObservableResult<void>;

  /** Admin action: assign a reviewer to the review
   * Requires `can_manage_reviews` privilege
   *
   * @param reviewUid
   * @param request
   */
  updateReviewers(reviewUid: string, request: ReviewerAdminRequest): ObservableResult<void>;

  /** Admin action: retrieve reviewers list
   * Requires `can_manage_reviews` privilege
   *
   * @param reviewUid
   */
  retrieveReviewers(reviewUid: string): ObservableResult<ReviewerSummary[]>;


  /**
   * Get all pending reviews count.
   */
  getUserReviewsCount(): ObservableResult<UserReviewsCount>;

  /**
   * Retrieves review settings details for a specific playlist.
   *
   * @param playlistUid - Unique identifier of the playlist.
   * @returns ObservableResult containing review settings details.
   */
  getReviewSettingsDetail(playlistUid: string): ObservableResult<ReviewSettingsDetails>;

  /** Undo review
   *
   * @param reviewUid
   */
  undoReview(reviewUid: string): ObservableResult<void>;
}
