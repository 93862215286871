/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

export * from './user-details.model';
export * from './account-creation-request.model';

export const FINALIZATION_URL = 'users/auth/logins';
