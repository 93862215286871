import { InjectionToken } from '@angular/core';
import { ToastInfo } from '../../models/toast-notifications/toast-info';
import { PushNotificationMessage } from '../../models/websocket/websocket.model';

export const TOAST_NOTIFICATION_SERVICE = new InjectionToken<ToastService>(
  '[resource] ToastService'
);

/** The service handling toast notifications */
export interface ToastService {
  showPushNotification(pushNotification: PushNotificationMessage);

  showSuccess(message: string);

  showFail(message: string);

  remove(createdAt: Date);

  getToastList(): ToastInfo[];
}
