/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Moment } from '../../../models';
import { LoadableState, defaultLoadableState } from '../../../../../shared/store';
import { EditorContent, ResourceTag } from '../../../../../shared/models';
import { Page } from '../../../../../shared/models/page';

export interface MomentEditorState {
  /** The title. */
  title: string;
  /** The tags selected for the moment. */
  tags: ResourceTag[];
  /** The content added for the moment. */
  content: EditorContent[];
  /** Defines if the moment should be added to user's folio. */
  addedToFolio: boolean;
}

/** Defines the state of the Moment screen */
export interface MomentViewStateModel {
  /** The existing moments for the user. */
  moments: LoadableState<Page<Moment>>;
  /** Contains the state of the moment editor. */
  momentEditorState: MomentEditorState;
  /** Captures the moment creation progress/outcome */
  momentCreationState: {
    /** True when moment creation is in progress. */
    inProgress: boolean;
    /** The creation error. */
    creationError?: string;
  };
}

export const INITIAL_MOMENT_STATE: MomentViewStateModel = {
  moments: defaultLoadableState(),
  momentEditorState: {
    title: '',
    tags: [],
    content: [],
    addedToFolio: false,
  },
  momentCreationState: {
    inProgress: false,
  },
};
