/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { CardClickData, LinkToFeatureData, SectionItem } from '../../../../../shared/models/page/page.model';
import {
  CORE_PLAYLIST_DATA_SERVICE,
  PlaylistDataService
} from '../../../../playlist/services/create/core/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ptl-featured-section-view',
  templateUrl: './featured-section-view.component.html',
  styleUrls: ['./featured-section-view.component.scss'],
})
export class FeaturedSectionViewComponent implements OnChanges, OnDestroy {

  @Input() sectionCards: SectionItem[];
  @Input() type: 'FEATURED' | 'FEATURED_SLIM' | 'NEW_PLAYLISTS';
  @Input() inHomeLayoutEditor: boolean;
  @Input() isAdmin: boolean;
  @Input() languageCode: string;

  /** Emits on headline or image click. */
  @Output() cardClick = new EventEmitter<CardClickData>();
  /** Emits when cards get loaded. */
  @Output() loading = new EventEmitter<boolean>();

  @Input() size: CardSize;

  cards = [];
  isEditPage = false;

  private playlistsSubscription: Subscription;

  constructor(
    private router: Router,
    @Inject(CORE_PLAYLIST_DATA_SERVICE) private playlistDataService: PlaylistDataService
  ) {

    if (this.router.url.split('/').pop().indexOf('edit') === 0) {
      this.isEditPage = true;
    }
  }

  ngOnChanges() {
    this.cards = [];
    if (this.needToFetchCards()) {
      this.playlistsSubscription?.unsubscribe();

      // @ts-ignore
      const playlistObservables = this.sectionCards.map(card => {
        if (card.type === 'LINK') {
          const linkData = { ...card as unknown as LinkToFeatureCardData };
          return of({ isSuccess: true, value: linkData });
        }
      });
      this.loading.emit();
    } else if (this.sectionCards?.length) {
      for (let i = 0; i < this.sectionCards.length; ++i) {
        if (this.sectionCards[i].favorite === true || this.sectionCards[i].favorite === false) {
          this.cards[i].favorite = this.sectionCards[i].favorite;
        }
      }
    }
  }

  ngOnDestroy() {
    this.playlistsSubscription?.unsubscribe();
  }

  onCardClick(cardDetails: CardClickData) {
    this.cardClick.emit(cardDetails);
  }

  private needToFetchCards(): boolean {
    if (!this.sectionCards?.length) {
      return false;
    } else if (this.sectionCards?.length === this.cards.length) {
      for (let i = 0; i < this.sectionCards.length; ++i) {
        if (this.sectionCards[i].playlistUri !== this.cards[i].uri) {
          return true;
        }
      }

      return false;
    } else {
      return true;
    }
  }
}

type CardSize = 'STANDARD' | 'SLIM';

interface LinkToFeatureCardData extends LinkToFeatureData {
  type: 'LINK';
}
