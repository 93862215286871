<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div class="tableau__main-container">

  <div class="tableau__iframe-container" *ngIf="iframeSrc">
    <iframe [src]="iframeSrc" allowfullscreen></iframe>
  </div>

  <div class="no-data" *ngIf="!iframeSrc && dataIsLoaded">
    {{ 'translations.tableauNoData' | transloco }}
  </div>

</div>
