<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<section class="conditional-report-section" [class.first]="isFirst" [id]="section?.uid">

  <div class="conditional-report-section-title-box" *ngIf="section?.showTitle && !section?.trimmedFromStart">
    <div class="icon" *ngIf="section?.learningGoalWrapper?.icon && section?.learningGoalWrapper?.icon?.iconImageUrl">
      <img src="{{ section?.learningGoalWrapper.icon.iconImageUrl }}" alt="" width="50" height="50">
    </div>
    <h2 class="conditional-report-section__title extra-large" tabindex="0">{{ section?.learningGoalWrapper.tagTitle }}</h2>
  </div>

  <ng-container *ngIf="section?.learningGoalWrapper.competencyLevels[0]?.graphType === 'SEGMENTED_BAR' &&
                       section?.learningGoalWrapper.competencyLevels[0]?.showGraph">
    <ptl-segment-bar
      [levels]="levels"
      [score]="scored"
      [maxScore]="section.learningGoalWrapper.maxScore"
      [isPercentageEnabled]="isPercentageEnabled"
      [sectionIcons]="section?.learningGoalWrapper">
    </ptl-segment-bar>
  </ng-container>

  <div class="bar-chart-graph"
       *ngIf="section?.learningGoalWrapper.competencyLevels[0]?.graphType === 'BAR' && section?.learningGoalWrapper.competencyLevels[0]?.showGraph">
    <div class="bar-chart">
      <div class="bar-chart-container">
        <div class="min-icon icon-item"
             *ngIf="section?.learningGoalWrapper?.minIcon && section?.learningGoalWrapper?.minIcon?.iconImageUrl">
          <img src="{{ section?.learningGoalWrapper.minIcon.iconImageUrl }}" alt="" width="30" height="30">
        </div>
        <div class="bar-chart-progress">
          <div class="bar-chart-progress-bar"
               [style.background-color]="progressColor"
               [style.width]="scoredPercentage + '%'"></div>
        </div>
        <div class="max-icon icon-item"
             *ngIf="section?.learningGoalWrapper?.maxIcon && section?.learningGoalWrapper?.maxIcon?.iconImageUrl">
          <img src="{{ section?.learningGoalWrapper.maxIcon.iconImageUrl }}" alt="" width="30" height="30">
        </div>
      </div>
      <div class="bar-chart-title medium">
        <ng-container *ngIf="isPercentageEnabled">
          {{ 'translations.diagnostics.labels.youScoredPercentage' | transloco : {
            score: this.percentageValue,
          } }}
        </ng-container>
        <ng-container *ngIf="!isPercentageEnabled">
          {{ 'translations.diagnostics.labels.youScored' | transloco : {
          score: scored,
          from: this.section.learningGoalWrapper.maxScore
        } }}
        </ng-container>
      </div>
    </div>
  </div>

  <div class="conditional-report-section-dynamic-content"
       *ngIf="section?.learningGoalWrapper.dynamicContent?.length">

    <div class="conditional-report-section__intro__chart"
         [style.visibility]="section?.trimmedFromStart ? 'hidden' : ''"
         *ngIf="section?.learningGoalWrapper?.competencyLevels[0]?.showGraph && section?.learningGoalWrapper?.competencyLevels[0]?.graphType === 'CIRCLE'">

      <div class="radial-chart">
        <svg width="120" height="120" viewBox="0 0 120 120" class="progress__svg" [attr.aria-label]="('translations.global.ariaLabels.icons.progressCircle' | transloco )">
          <circle [attr.r]="radius - 4"
                  cx="60"
                  cy="60"
                  stroke-width="4"
                  stroke-dasharray="0 6"
                  stroke-linecap="round"
                  class="progress__meter"/>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stop-color="#4DA7D8"/>
              <stop offset="100%" stop-color="#5FD5BB"/>
            </linearGradient>
          </defs>
          <circle [style.strokeDasharray]="circumference"
                  [style.strokeDashoffset]="dashoffset"
                  [attr.r]="radius"
                  cx="60"
                  cy="60"
                  stroke-width="12"
                  [style.stroke]="svgStroke"
                  class="progress__value"/>
        </svg>
      </div>

    </div>

    <ptl-resource-preview
      [previewContent]="section?.learningGoalWrapper.dynamicContent">
    </ptl-resource-preview>
  </div>


  <div class="conditional-section-levels-content" *ngIf="section?.uid">
    <div class="footer-level-container"
         *ngIf="section.learningGoalWrapper.competencyLevels && section.learningGoalWrapper.competencyLevels[0]">
      <div class="footer-level-tabs">
        <div class="level-title-container" *ngIf="section?.showLevels">
          <span class="large text">{{ 'translations.confidenceLevel' | transloco }}</span>
          <span class="large bold title">{{section.learningGoalWrapper.competencyLevels[0].levelTitle[0].value}} </span>
        </div>

        <div class="conditional-section-levels-editor">
          <ptl-resource-preview
            [previewContent]="section.learningGoalWrapper.competencyLevels[0].dynamicContent">
          </ptl-resource-preview>
        </div>
      </div>
    </div>
  </div>
</section>
