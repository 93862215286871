/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { AdminDataService } from './data.service';
import { ObservableResult } from '../../../shared/store';
import { RestClientService } from '../../../shared/services/rest-client.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import {
  CreateFrameworkRequest,
  Framework, FrameworkDetails,
  UpdateFrameworkRequest,
  OrganizationFramework,
  FrameworkCategoriesTree
} from '../../../shared/models/admin/admin.model';
import { Page as Pagination } from '../../../shared/models/page';
import {
  Page,
  PageRequest,
  SectionRequest,
  Section,
  SectionReorder,
  CategoryItemCreationRequest, CategoryItemCreationResponse
} from '../../../shared/models/page/page.model';
import { Playlist, ResourceTag } from '../../../shared/models';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { LanguageCodeHelper } from '../../../shared/helpers/language-code-helper';
import { HttpErrorResponse } from '@angular/common/http';


/* eslint-disable max-len */
@Injectable()
export class ApiFrameWorkDataService implements AdminDataService {

  constructor(private restClient: RestClientService, private translationService: TranslationService) {
  }

  /** Loads the all frameworks. */
  loadFrameworks(): ObservableResult<Framework[]> {
    return this.restClient.get<Framework[]>(Location.joinWithSlash(
      environment.apiRootUrl || '', 'frameworks'), undefined, undefined).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFrameworks'))));
  }

  loadFrameworkCategories(): ObservableResult<Framework> {
    return this.restClient.get<Framework>(Location.joinWithSlash(
        environment.apiRootUrl || '', 'frameworks/categories'), undefined, undefined).pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFrameworks'))));
  }

  loadFrameworkCategoriesTree(): ObservableResult<FrameworkCategoriesTree[]> {
    return this.restClient.get<FrameworkCategoriesTree[]>(Location.joinWithSlash(
        environment.apiRootUrl || '', 'frameworks/categories/tree')).pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFrameworks'))));
  }

  loadCategoriesDescendantsTags(): ObservableResult<ResourceTag[]> {
    return this.restClient.get<ResourceTag[]>(Location.joinWithSlash(
      environment.apiRootUrl || '', 'frameworks/categories/descendants?depth=1'), undefined, undefined).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFrameworks'))));
  }

  loadCategoryChildren(categoryTagUid: string): ObservableResult<ResourceTag[]> {
    const categoryDetails = categoryTagUid ? `&categoryTagId=${categoryTagUid}` : '';
    return this.restClient.get<ResourceTag[]>(Location.joinWithSlash(
      environment.apiRootUrl || '', `frameworks/categories/descendants?depth=1${categoryDetails}`), undefined, undefined).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFrameworks'))));
  }

  loadContentStoreFrameworks(): ObservableResult<Framework> {
    return this.restClient.get<Framework>(Location.joinWithSlash(
        environment.apiRootUrl || '', 'frameworks/store/categories'), undefined, undefined).pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFrameworks'))));
  }

  createFramework(frameworkParams: CreateFrameworkRequest): ObservableResult<void> {
    return this.restClient.post<void>(Location.joinWithSlash(
      environment.apiRootUrl || '', 'frameworks'), frameworkParams).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateFramework'))));
  }

  updateFramework(frameworkId: string, frameworkParams: UpdateFrameworkRequest): ObservableResult<Framework> {
    return this.restClient.patch<Framework>(Location.joinWithSlash(
      environment.apiRootUrl || '', `frameworks/${frameworkId}`), frameworkParams).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => this.handleErrorResponse(err, this.translationService.getTranslation('errors.errorUpdateFrameworkFailed'))));
  }

  setOrganizationFrameworkStatus(frameworkId: string, status: boolean): ObservableResult<OrganizationFramework> {
    return this.restClient.patch<OrganizationFramework>(
      Location.joinWithSlash(environment.apiRootUrl || '', `frameworks/organizations/${frameworkId}/status`),
      { value: status }).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorSetOrgFrameworkStatus')))
    );
  }

  loadFrameworkById(frameworkId: string): ObservableResult<FrameworkDetails> {
    return this.restClient.get<FrameworkDetails>(Location.joinWithSlash(
      environment.apiRootUrl || '', `frameworks/${frameworkId}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFrameworks'))));
  }

  loadFrameworkDescendants(frameworkId: string): ObservableResult<ResourceTag[]> {
    return this.restClient.get<ResourceTag[]>(Location.joinWithSlash(
      environment.apiRootUrl || '', `frameworks/${frameworkId}/descendants?depth=1`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFrameworks'))));
  }

  loadFrameworkTagDescendants(frameworkId: string, tagId: string): ObservableResult<ResourceTag[]> {
    return this.restClient.get<ResourceTag[]>(Location.joinWithSlash(
      environment.apiRootUrl || '', `frameworks/${frameworkId}/descendants?tagId=${tagId}&depth=1`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFrameworkTags'))));
  }

  publishFramework(frameworkId: string, publish: boolean): ObservableResult<Framework> {
    return this.restClient.patch<Framework>(Location.joinWithSlash(
      environment.apiRootUrl || '', `frameworks/${frameworkId}/published`),
      { value: publish }).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorPublishFramework'))));
  }

  loadOrganizationFrameworks(): ObservableResult<OrganizationFramework[]> {
    return this.restClient.get<OrganizationFramework[]>(Location.joinWithSlash(
      environment.apiRootUrl || '', 'frameworks/organizations')).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFrameworks'))));
  }

  addFrameworkToOrganization(frameworkId: string): ObservableResult<OrganizationFramework> {
    return this.restClient.post<OrganizationFramework>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'frameworks/organizations'),
      { frameworkId: frameworkId }).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(JSON.stringify(err))));
  }

  removeFrameworkFromOrganization(frameworkId: string): ObservableResult<void> {
    return this.restClient.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `frameworks/organizations/${frameworkId}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemovingFramework'))));
  }

  updatePage(pageUid: string, pageBody: PageRequest): ObservableResult<Page> {
    return this.restClient.patch<Page>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}`), pageBody).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingPage'))));
  }

  addSection(pageUid: string, sectionBody: SectionRequest, languageCode?: string): ObservableResult<Section> {
    return this.restClient.post<Section>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/sections`),
      sectionBody,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetPage'))));
  }

  removeSection(pageUid: string, sectionUid: string): ObservableResult<void> {
    return this.restClient.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/sections/${sectionUid}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemovingSection'))));
  }

  updateSection(pageUid: string, sectionUid: string, section: SectionRequest, languageCode?: string): ObservableResult<Section> {
    return this.restClient.patch<Section>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/sections/${sectionUid}`),
      section,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingSection'))));
  }

  reorderPageSections(pageUid: string, section: SectionReorder): ObservableResult<Section[]> {
    return this.restClient.post<Section[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/sections/reorder`), section).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorReorderingPageSection'))));
  }

  getLatestPlaylists(page: number, size: number, frameworkId?: string, tagId?: string, languageCode?: string):
    ObservableResult<Pagination<Playlist>> {
    const uri = frameworkId ?
      `/playlists/latest/categories/${frameworkId}?tagId=${tagId}&page=${page}&size=${size}` :
      `/playlists/latest?page=${page}&size=${size}`;
    return this.restClient.get<Pagination<Playlist>>(
      Location.joinWithSlash(environment.apiRootUrl || '', uri),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetLatestPlaylists')))
    );
  }

  getPlaylistsByTags(page: number, size: number, tagIds?: string, languageCode?: string): ObservableResult<Pagination<Playlist>> {
    const uri = `/playlists/latest?tags=${tagIds}&page=${page}&size=${size}`;
    return this.restClient.get<Pagination<Playlist>>(
      Location.joinWithSlash(environment.apiRootUrl || '', uri),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetLatestPlaylists')))
    );
  }

  getUncompletedPlaylists(page: number, size: number, languageCode?: string): ObservableResult<Pagination<Playlist>> {
    return this.restClient.get<Pagination<Playlist>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/playlists/users/uncompleted?page=${page}&size=${size}`),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetUncompletedPlaylists')))
    );
  }

  getYourPlaylists(page: number, size: number, term?: string, languageCode?: string): ObservableResult<Pagination<Playlist>> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    if (term) {
      params['term'] = term;
    }
    return this.restClient.get<Pagination<Playlist>>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/playlists/owning'),
      params,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetYourPlaylists')))
    );
  }

  addCategory(pageUid: string, sectionUid: string, request: CategoryItemCreationRequest):
    ObservableResult<CategoryItemCreationResponse> {
    return this.restClient.post<CategoryItemCreationResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/sections/${sectionUid}/categories`),
      request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddCategory'))));
  }

  updateCategory(pageUid: string, sectionUid: string, categoryId: string, request: CategoryItemCreationRequest):
    ObservableResult<CategoryItemCreationResponse> {
    return this.restClient.patch<CategoryItemCreationResponse>(
      Location.joinWithSlash(environment.apiRootUrl || '',
        `pages/${pageUid}/sections/${sectionUid}/categories/${categoryId}`),
      request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateCategory'))));
  }

  removeCategory(pageUid: string, sectionUid: string, categoryId: string):
    ObservableResult<void> {
    return this.restClient.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/sections/${sectionUid}/categories/${categoryId}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveCategory'))));
  }

  cloneSection(pageUid: string, sectionUid: string): ObservableResult<Section> {
    return this.restClient.post<Section>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/sections/${sectionUid}/clone`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddCategory'))));
  }

  createLtiFramework(requestId: string, organizationUid: string, frameworkParams: CreateFrameworkRequest): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `/lti/skills/${organizationUid}/frameworks?requestId=${requestId}`);
    return this.restClient.post<void>(url, frameworkParams).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateFramework'))));
  }

  loadLtiFrameworks(requestId: string, organizationUid: string): ObservableResult<Framework[]> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '',
      `/lti/skills/${organizationUid}/frameworks?requestId=${requestId}`);
    return this.restClient.get<Framework[]>(url, undefined, undefined).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetFrameworks'))));
  }

  private handleErrorResponse(err: HttpErrorResponse, errorMessage: string) {
    if (err.error?.status === 'CONFLICT') {
      return ObservableResult.ofError(err.error?.message);
    }
    return ObservableResult.ofError(errorMessage);
  }

}
