/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Inject, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
/* eslint-disable max-len */
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { filter, switchMap } from 'rxjs/operators';
import {
  CORE_MOMENT_DATA_SERVICE,
  MomentDataService
} from '../../page-modules/folio/shared-moment-module/services/data.service';
import {
  MomentFileUploadHelpers
} from '../../page-modules/folio/shared-moment-module/services/moment-view-all-file-upload.helpers';
import { OpenMomentForUpdate } from '../../page-modules/folio/store/moment/moment-update/moment-update.actions';
import { LoadMomentsOnFolio } from '../../page-modules/folio/store/moment/moment-view-all/moment-view-all.actions';
import { TagRequest } from '../../page-modules/playlist/models';
import { EditorTransformer } from '../../shared/helpers/editor-transformer';
import { SHARED_CARD_DATA_SERVICE, SharedCardDataService } from '../../shared/services/card/card-data.service';
import {
  FileUploadService,
  RESOURCES_FILE_UPLOAD_DATA_SERVICE
} from '../../shared/services/file-upload/file-upload.service';
import {
  CORE_TAG_DATA_SERVICE,
  MultiTagCreationRequest,
  TagDataService
} from '../../shared/services/tags/tag-data.service';
import { UserAuthState } from '../../user-auth/store/user-auth.state';
import * as AppFrameActions from './app-frame.actions';
import { UpdateFrameworkTracker } from '../../page-modules/pages/store/pages.actions';
import { TranslationService } from '../../shared/services/translation/translation.service';
import { UpdateLearnerViewOpenCard } from '../../page-modules/resource/store/learner-view.actions';
import { RedirectHelper } from '../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { PlaylistCardWasOpened } from '../../page-modules/playlist/store/view/playlist-view.state.actions';
import * as FolioActions from '../../page-modules/folio/store/folio.action';
/* eslint-enable max-len */

export type ApplicationMode = 'OFFLINE' | 'ONLINE';

/** The app frame state definition. */
export interface AppFrameStateModel {
  /** Defines if the navigation menu is expanded (used on mobile devices). */
  mobileMenuExpanded: boolean;
  /** Defines if the viewport of the device is mobile. */
  isMobile: boolean;
  /** Defines if the app is operating in Offline or Online mode */
  mode: ApplicationMode;
  /** Defines if the header is displayed or not */
  displayHeader: boolean;
  /** Defines if the sidenav is displayed or not */
  displaySidenav: boolean;
}

@State<AppFrameStateModel>({
  name: 'appFrame',
  defaults: {
    mobileMenuExpanded: false,
    isMobile: false,
    mode: undefined,
    displayHeader: true,
    displaySidenav: true,
  },
})
@Injectable()
export class AppFrameState {

  @Selector()
  static isMobileMenuExpanded({ mobileMenuExpanded }: AppFrameStateModel) {
    return mobileMenuExpanded;
  }

  @Selector()
  static isMobile({ isMobile }: AppFrameStateModel) {
    return isMobile;
  }

  @Selector()
  static displayHeader({ displayHeader }: AppFrameStateModel) {
    return displayHeader;
  }

  // -------------------------------------------------------------------------
  constructor(
    private store: Store,
    @Inject(CORE_MOMENT_DATA_SERVICE) private momentDataService: MomentDataService,
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    @Inject(SHARED_CARD_DATA_SERVICE) private cardService: SharedCardDataService,
    private translationService: TranslationService,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {

    this.addBrowserModeChangeListeners();
  }


  // -------------------------------------------------------------------------
  @Action(AppFrameActions.OpenMobileNavMenu)
  openMobileNavMenu({ patchState }: StateContext<AppFrameStateModel>) {
    patchState({
      mobileMenuExpanded: true,
    });
  }

  // -------------------------------------------------------------------------
  @Action(AppFrameActions.CloseMobileNavMenu)
  closeMobileNavMenu({ patchState }: StateContext<AppFrameStateModel>) {
    patchState({
      mobileMenuExpanded: false,
    });
  }

  @Action(AppFrameActions.SetIsMobileState)
  toggleIsMobileState(
    { patchState }: StateContext<AppFrameStateModel>,
    { isMobile }: AppFrameActions.SetIsMobileState
  ) {
    patchState({
      isMobile: isMobile,
    });
  }

  @Action(AppFrameActions.SwitchApplicationMode)
  switchApplicationMode(
    { patchState }: StateContext<AppFrameStateModel>,
    { mode }: AppFrameActions.SwitchApplicationMode
  ) {
    patchState({
      mode,
    });
  }

  @Action(AppFrameActions.CreateMoment)
  createMoment(
    _: StateContext<AppFrameStateModel>,
    { moment }: AppFrameActions.CreateMoment
  ) {
    const userDetails = this.store.selectSnapshot(UserAuthState.userDetailsData);

    const request = {
      type: 'STANDARD',
      tags: moment.tags.map(tag => ({ title: tag.title } as TagRequest)),
    } as MultiTagCreationRequest;
    return this.tagService.createTags(request)
      .pipe(
        switchMap((tagsResult) => { // todo: basically duplicate of logic in MomentViewAllState
            if (tagsResult.isSuccess) {
              return this.momentDataService.createMoment({
                title: moment.title,
                tags: tagsResult.value,
                content: moment.content.map(content => EditorTransformer.transformEditorContentToMatchApiExpectations(content)),
                addedToFolio: moment.addedToFolio,
              }).pipe(
                filter(({ isSuccess }) => isSuccess),
                switchMap(({ value }) => MomentFileUploadHelpers.uploadMomentFiles(
                    userDetails.uid,
                    userDetails.organization._id,
                    moment.content,
                    this.fileUploadService,
                    value.content,
                    () => this.ngZone.run(() => {
                      this.snackBar.open(
                        this.translationService.getTranslation('folio.message.success.created'),
                        '',
                        { duration: 2000, horizontalPosition: 'center', verticalPosition: 'top' });
                      this.store.dispatch(new LoadMomentsOnFolio());
                      this.store.dispatch(new FolioActions.FolioMomentsUpdate());
                      if (location.pathname === '/' && request.tags.length > 0) { // detect if home page
                        this.store.dispatch(new UpdateFrameworkTracker());
                      }
                    }),
                  )
                )
              );
            } else {
              return undefined;
            }
          }
        ));
  }

  @Action(AppFrameActions.SetDisplayHeader)
  setHideHeader(
    { patchState }: StateContext<AppFrameStateModel>,
    { displayHeader }: AppFrameActions.SetDisplayHeader
  ) {
    patchState({
      displayHeader: displayHeader,
    });
  }

  @Action(AppFrameActions.SetDisplaySidenav)
  setDisplaySidenav(
    { patchState }: StateContext<AppFrameStateModel>,
    { displaySidenav }: AppFrameActions.SetDisplaySidenav
  ) {
    patchState({
      displaySidenav: displaySidenav,
    });
  }

  @Action(AppFrameActions.RegisterCardOpening)
  registerCardOpening(
    _: StateContext<AppFrameStateModel>,
    { cardId }: AppFrameActions.RegisterCardOpening
  ) {
    return this.cardService.registerCardOpen(cardId)
      .subscribe(() => {
        this.store.dispatch(new PlaylistCardWasOpened(true));
        this.store.dispatch(new UpdateLearnerViewOpenCard(true))
      });
  }

  @Action(AppFrameActions.ViewMoment)
  openMomentForView(
    _: StateContext<AppFrameStateModel>,
    { id }: AppFrameActions.ViewMoment
  ) {
    this.store.dispatch(new OpenMomentForUpdate(id));
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, `/folio/moments/${id}`);
  }

  private addBrowserModeChangeListeners() {
    const switchApplicationModeAction = navigator.onLine
      ? new AppFrameActions.SwitchApplicationMode('ONLINE')
      : new AppFrameActions.SwitchApplicationMode('OFFLINE');

    this.store.dispatch(switchApplicationModeAction);

    window.addEventListener(
      'offline',
      () => this.store.dispatch(new AppFrameActions.SwitchApplicationMode('OFFLINE')));

    window.addEventListener(
      'online',
      () => this.store.dispatch(new AppFrameActions.SwitchApplicationMode('ONLINE')));
  }
}
