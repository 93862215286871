/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

import { Inject, Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PublishPlaylist, SetPublicationSettings, UnpublishPlaylist } from './playlist-publiction.actions';

import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';
import { SnackbarHelper } from '../../../../../shared/helpers/snackbar-helper';
import { PlaylistPublicationSettings, PlaylistType } from '../../../../../shared/models';
import { TranslationService } from '../../../../../shared/services/translation/translation.service';
import {
  PLAYLIST_PUBLICATION_DATA_SERVICE,
  PublicationDataService
} from '../../../services/create/publication/publication-data.service';
import { PROJECT_DATA_SERVICE, ProjectDataService } from '../../../../project/services/data.service';

interface PlaylistResourceEditorPublicationStateModel {
  playlistId?: string;
  playlistType?: PlaylistType;
  publicationSettings?: PlaylistPublicationSettings;
  updateInProgress: boolean;
}

@State<PlaylistResourceEditorPublicationStateModel>({
  name: 'playlistPublicationState',
  defaults: {
    updateInProgress: false,
  },
})
@Injectable()
export class PlaylistResourceEditorPublicationState {

  @Selector()
  static publicationSettings(
    { publicationSettings }: PlaylistResourceEditorPublicationStateModel
  ): PlaylistPublicationSettings {

    return publicationSettings;
  }

  @Selector()
  static updateInProgress(
    { updateInProgress }: PlaylistResourceEditorPublicationStateModel
  ): boolean {

    return updateInProgress;
  }

  constructor(
    @Inject(PLAYLIST_PUBLICATION_DATA_SERVICE) private publicationDataService: PublicationDataService,
    @Inject(PROJECT_DATA_SERVICE) private projectsDataService: ProjectDataService,
    private translationService: TranslationService,
    private snackBar: MatSnackBar,
    private ngZone: NgZone
  ) {
  }

  @Action(SetPublicationSettings)
  setPublicationSettings(
    { patchState }: StateContext<PlaylistResourceEditorPublicationStateModel>,
    { settings, playlistId, playlistType }: SetPublicationSettings
  ) {
    if (!settings) {
      settings = { published: false };
    }

    patchState({
      playlistId,
      playlistType,
      publicationSettings: {
        ...settings,
        publishedDate: settings.publishedDate && new Date(settings.publishedDate),
      },
    });
  }

  @Action(PublishPlaylist)
  publishPlaylist(
    { patchState, getState }: StateContext<PlaylistResourceEditorPublicationStateModel>
  ) {
    patchState({
      updateInProgress: true,
    });

    const isProject = getState().playlistType === 'PROJECT';
    const apiCall = isProject ?
      this.projectsDataService.publishAndShowProjectOnFolio(getState().playlistId) :
      this.publicationDataService.publishPlaylist(getState().playlistId);

    return apiCall.pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translationService,
            isProject ? 'projectPublished' : 'playlistPublished'
          );
          patchState({
            updateInProgress: false,
            publicationSettings: {
              ...getState().publicationSettings,
              published: true,
            },
          });
        }
      })
    );
  }

  @Action(UnpublishPlaylist)
  unpublishPlaylist(
    { patchState, getState }: StateContext<PlaylistResourceEditorPublicationStateModel>
  ) {
    patchState({
      updateInProgress: true,
    });

    const isProject = getState().playlistType === 'PROJECT';
    const apiCall = isProject ?
      this.projectsDataService.unPublishAndHideProjectVisibleOnFolio(getState().playlistId) :
      this.publicationDataService.unpublishPlaylist(getState().playlistId);

    return apiCall.pipe(
      tap(({ isSuccess }) => {
        if (isSuccess) {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translationService,
            isProject ? 'projectUnPublished' : 'playlistUnPublished'
          );
          patchState({
            updateInProgress: false,
            publicationSettings: {
              ...getState().publicationSettings,
              published: false,
            },
          });
        }
      })
    );
  }
}
