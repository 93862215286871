/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Input } from '@angular/core';
import { SectionContent } from '../../../../../shared/models/page/page.model';

@Component({
  selector: 'ptl-content-section-view',
  templateUrl: './content-section-view.component.html',
  styleUrls: ['./content-section-view.component.scss'],
})
export class ContentSectionViewComponent  {

  @Input() content: SectionContent[];

}
