import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewSectionsType, SectionItem, SelectedTabs } from '../../models/pages/page-section.model';


@Component({
  templateUrl: './add-page-template.component.html',
  styleUrls: [ './add-page-template.component.scss' ],
})
export class AddPageTemplateComponent {
  selectedTab: SelectedTabs = 'PAGES';
  pagesSectionItems: SectionItem[] = [
    {
      title: 'translations.pages.newSection.label.imageBlockPages',
      image: 'assets/page-sections/image-block-page.png',
      type: 'ICBP'
    },
    {
      title: 'translations.pages.newSection.label.headlinePages',
      image: 'assets/page-sections/headline-page.png',
      type: 'HP'
    },
    {
      title: 'translations.pages.newSection.label.imagePages',
      image: 'assets/page-sections/image-page.png',
      type: 'IP'
    },
    {
      title: 'translations.pages.newSection.label.blockPages',
      image: 'assets/page-sections/block-page.png',
      type: 'CBP'
    },
    {
      title: 'translations.pages.newSection.label.calloutPages',
      image: 'assets/page-sections/callout-page.png',
      type: 'CP'
    }
  ];
  basicBlockSectionItems: SectionItem[] = [
    {
      title: 'translations.pages.newSection.label.imageBlock',
      image: 'assets/page-sections/image-block.png',
      type: 'ICB'
    },
    {
      title: 'translations.pages.newSection.label.image',
      image: 'assets/page-sections/image.png',
      type: 'I'
    },
    {
      title: 'translations.pages.newSection.label.block',
      image: 'assets/page-sections/block.png',
      type: 'CB'
    },
    {
      title: 'translations.pages.newSection.label.headline',
      image: 'assets/page-sections/headline.png',
      type: 'H'
    },
    {
      title: 'translations.pages.newSection.label.featured',
      image: 'assets/page-sections/featured-section.png',
      type: 'F'
    },
    {
      title: 'translations.pages.newSection.label.calloutPages',
      image: 'assets/page-sections/callout-page.png',
      type: 'CP'
    }
  ];
  automaticSectionItems: SectionItem[] = [
    {
      title: 'translations.pages.newSection.label.continueSection',
      image: 'assets/page-sections/continue-section.png',
      type: 'CS'
    },
    {
      title: 'translations.pages.newSection.label.whatsNewSection',
      image: 'assets/page-sections/whats-new-section.png',
      type: 'WNS'
    },
    {
      title: 'translations.pages.newSection.label.upcomingSection',
      image: 'assets/page-sections/upcoming-section.png',
      type: 'US'
    },
    {
      title: 'translations.pages.newSection.label.yourPlaylists',
      image: 'assets/page-sections/your-playlists-section.png',
      type: 'YPS'
    }
  ];

  constructor(
    private dialogRef: MatDialogRef<AddPageTemplateComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { addType?: 'template' | 'block' }
  ) {
    if (data.addType === 'block') {
      this.showTab('BASIC_BLOCK');
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  showTab(selectedTab: SelectedTabs): void {
    this.selectedTab = selectedTab;
  }

  onSectionClick(sectionType: NewSectionsType): void {
    this.dialogRef.close(sectionType);
  }
}
