<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div class="question-results" tabindex="0" role="group"
     [attr.aria-label]="('translations.global.ariaLabels.thisIsQuestionResult' | transloco)">

    <div class="report-results-your-answers">
      {{ 'translations.quizReport.yourAnswers' | transloco }}
    </div>

    <div class="report-results-item-container" *ngFor="let question of filteredResponses; let index = index">
      <div class="medium bold">
        {{ 'translations.quiz.label.question' | transloco :
        { number: index+1 }
        }}
      </div>

      <div class="report-results-item" [class.shadows-disabled]="playlistDownloadReportView">
        <div class="question-instructions" [innerHTML]="getTranslatedValue(question?.instructions)"></div>
        <div class="report-item-status"
             [attr.aria-label]="('translations.global.ariaLabels.question' | transloco:
             {text: ': ' + getTranslatedValue(question?.instructions)}) + ', ' +
             question?.answeredCorrectly ? ' ' + ('translations.global.ariaLabels.answeredCorrect' | transloco) :
              ' ' + ('translations.global.ariaLabels.answeredIncorrect' | transloco)">
          <div class="report-status-box" [ngClass]="question?.answeredCorrectly ? 'correct-answer' : 'wrong-answer'">
            <div class="report-status" *ngIf="question?.answeredCorrectly">
              <svg *ngIf="!playlistDownloadReportView" class="svg svg-icon icon-left">
                <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
              </svg>

              <span class="answer-result-text">{{ 'translations.quizReport.correct' | transloco }}</span>
            </div>

            <div class="report-status" *ngIf="!question?.answeredCorrectly">
              <svg *ngIf="!playlistDownloadReportView" class="svg svg-icon icon-left">
                <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
              </svg>

              <span class="answer-result-text">{{ 'translations.quizReport.wrong' | transloco }}</span>
            </div>
          </div>
        </div>
        <div class="answers" *ngIf="question?.options">
          <div class="answers-head">
            {{ 'translations.quizReport.youSelected' | transloco }}
          </div>
          <div class="answers-body">
            <div class="result-body-answer-wrapper"
                 *ngFor="let answer of question?.options; let index = index"
                 tabindex="0"
                 [attr.aria-label]="('translations.global.ariaLabels.option' | transloco: {text: getTranslatedValue(answer.value)}) + ', ' +  answer.correct && answer.selected? ('translations.global.ariaLabels.correct' | transloco) : answer.correct && !answer.selected? ('translations.global.ariaLabels.not-selected' | transloco) : !answer.correct && !answer.selected? ('translations.global.ariaLabels.no-border' | transloco) : ('translations.global.ariaLabels.incorrect' | transloco)">
              <div class="result-body-answer-inner">
                <div class="answer-option left-side">
                  <mat-icon aria-hidden="true">play_arrow</mat-icon>
                  <span class="option-text">
                    {{ getTranslatedValue(answer.value) }}
                  </span>
                </div>
                <div class="right-side">
                  <div class="option-status">
                    <svg class="svg" role="img" *ngIf="answer.selected && !playlistDownloadReportView">
                      <use ogSvgUnify="assets/sprite/sprite.svg#check-box"></use>
                    </svg>
                    <span *ngIf="answer.selected && playlistDownloadReportView" class="medium bold">X</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="result-body__feedback-container" *ngIf="question.feedback.length && !playlistDownloadReportView">
          <ptl-resource-preview class="result-body__feedback thin" [previewContent]="question.feedback"></ptl-resource-preview>
        </div>
      </div>

    </div>
</div>
