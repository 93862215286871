<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="form" [ngStyle]="{'overflow' : (form.content?.type === 'COLLECTOR') ? 'visible' : ''}">
  <ptl-form-textbox
    [id]="form.uid"
    *ngIf="form.content?.type === 'TEXTBOX'"
    [canRemoveItem]="canRemoveItem"
    [form]="form"
    (formElementAdded)="onFormElementSaved($event)"
    (formElementRemoved)="onFormElementRemoved()">
  </ptl-form-textbox>

  <ptl-form-connected-textbox
    [id]="form.uid"
    [canRemoveItem]="canRemoveItem"
    *ngIf="form.content?.type === 'CONNECTED_TEXTBOX'"
    [form]="form"
    (formElementAdded)="onFormElementSaved($event)"
    (formElementRemoved)="onFormElementRemoved()">
  </ptl-form-connected-textbox>

  <ptl-form-question
    [id]="form.uid"
    [canRemoveItem]="canRemoveItem"
    *ngIf="form?.content?.type === 'QUESTION_FORM'"
    [form]="form"
    (formElementAdded)="onFormElementSaved($event)"
    (formElementRemoved)="onFormElementRemoved()">
  </ptl-form-question>

  <ptl-form-private-note
    [id]="form.uid"
    *ngIf="form.content?.type === 'PRIVATE_NOTE'"
    [canRemoveItem]="canRemoveItem"
    [form]="form"
    (formElementAdded)="onFormElementSaved($event)"
    (formElementRemoved)="onFormElementRemoved()">
  </ptl-form-private-note>

  <ptl-form-file-upload
    [id]="form.uid"
    *ngIf="form.content?.type === 'USER_FILE_UPLOAD'"
    [canRemoveItem]="canRemoveItem"
    [form]="form"
    (formElementAdded)="onFormElementSaved($event)"
    (formElementRemoved)="onFormElementRemoved()">
  </ptl-form-file-upload>

  <ptl-form-checkbox
    [id]="form.uid"
    *ngIf="form.content?.type === 'CHECKBOX'"
    [canRemoveItem]="canRemoveItem"
    [form]="form"
    (formElementAdded)="onFormElementSaved($event)"
    (formElementRemoved)="onFormElementRemoved()">
  </ptl-form-checkbox>

  <ptl-quiz
    [id]="form.uid"
    *ngIf="form?.content?.type === 'QUIZ'"
    [canRemoveItem]="canRemoveItem"
    [quizContent]="form"
    (updateQuizContent)="onFormElementSaved($event)"
    (removeQuizContent)="onFormElementRemoved()">
  </ptl-quiz>

  <ptl-collector
    [id]="form.uid"
    *ngIf="form.content?.type === 'COLLECTOR'"
    [canRemoveItem]="canRemoveItem"
    [form]="form"
    (updateCollectorContent)="onFormElementSaved($event)"
    (removeCollectorContent)="onFormElementRemoved()">
  </ptl-collector>
</div>
