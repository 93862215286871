/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Directive, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserAuthState } from '../../user-auth/store/user-auth.state';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Organization } from '../models';
import { filter } from 'rxjs/operators';
import { developmentDomains } from '../helpers/development-domains.helper';
import { BETA_ACCESS_KEY } from '../guards/beta-access.guard';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Directive({
  selector: '[ogBetaAccess]',
})
export class BetaAccessDirective implements OnDestroy {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  private organizationDataSubscription: Subscription;

  constructor(
    private tmpRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    const isBetaAccess = window.sessionStorage.getItem(BETA_ACCESS_KEY) === 'true';
    this.organizationDataSubscription = this.organizationData$
      .pipe(filter(data => !!data))
      .subscribe(data => {
        if (developmentDomains(data.domain) || isBetaAccess) {
          this.viewContainer.clear();
          this.viewContainer.createEmbeddedView(this.tmpRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
  }
}
