/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, withLatestFrom } from 'rxjs/operators';
import {
  SubmitPlaylistReviewDialogComponent
} from 'src/app/page-modules/playlist/shared/view/playlist-action-bar/submit-dialog/submit-dialog.component';
import {
  RefreshUserPlaylistSubmissionSummary
} from 'src/app/page-modules/playlist/store/view/playlist-view.state.actions';
import { LEARNER_VIEW_DATA_SERVICE, LearnerViewDataService } from 'src/app/page-modules/resource/services/data.service';
import { ContentHelper } from 'src/app/shared/helpers/content-helper';
import { LearnerFormAnswer } from '@app/app/page-modules/resource/models';
import {
  RefreshStateAfterFormSubmission,
  SaveFormAnswer
} from '@app/app/page-modules/resource/store/learner-view.actions';
import {
  FormSavedEvent
} from '@app/app/shared/components/resource-preview/form-preview/form-preview-event.model';
import { DialogService } from '@app/app/shared/helpers/dialog/dialog.service';
import { Form, Organization, TextBoxFormContent, UserPlaylistSubmissionSummary } from '@app/app/shared/models';
import { TranslationService } from '@app/app/shared/services/translation/translation.service';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { ResizeEvent } from 'angular-resizable-element';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { WordLimitDialogComponent } from './word-limit-dialog/word-limit-dialog.component';
import { TEXT_FORM_MAX_WORDS_LIMIT, TEXT_FORM_MIN_WORDS_LIMIT } from '@app/app/shared/constants/constants';
import { HtmlSanitizer } from '@app/app/shared/helpers/sanitizer/html-sanitizer';


@Component({
  selector: 'ptl-form-preview-textbox',
  templateUrl: './form-textbox.component.html',
  styleUrls: ['./form-textbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPreviewTextboxComponent implements OnInit, OnChanges, OnDestroy {

  /** Receives the textbox type object */
  @Input() formData: Form<TextBoxFormContent>;
  @Input() learnerFormAnswer: LearnerFormAnswer;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() isProjectResource: boolean;
  @Input() playlistUri: string;
  @Input() playlistUid: string;
  @Input() playlistTitle: string;
  @Input() resourceUri: string;
  @Input() resourceUid: string;
  @Input() groupUid: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;

  existingUserAnswer: string;
  submitButtonEnabled: boolean;
  submitted = false;
  canEdit = false;
  canNotEditReason: string;
  requiresResubmission = false;
  showAiAssistant = false;

  inputFocused: boolean;
  inputTextWords = 0;

  autoSaveInProgress = false;
  autoSaved = false;
  editClicked = false;
  fullscreenEnabled = false;

  submissionInProgress = false;
  validationInProgress = false;
  validationProcessed = false;
  aiAssistantEnabled = false;
  aiAssistantSettingsUid: string = null;
  wordLimit: number;
  minWordsLimit: number;

  resizableContainerWidth: string = this.getContainerWidth(300);
  aiAssistantFeatureEnabled = false;
  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;
  @Select(UserAuthState.aiAssistantFeatureFlag)
  private aiAssistantFeatureFlag$: Observable<boolean>;

  private userInput: string;
  private autoSaveSubscription: Subscription;
  private autoSaveSubject = new Subject<void>();
  private aiFeatureFlagSubscription: Subscription;
  private dialogConfig = {
    width: '90vw',
    minWidth: '15.625rem',
    maxWidth: '46.875rem',
    maxHeight: '33.75rem',
    restoreFocus: true,
    position: {
      top: '10vh',
    },
    direction: LanguageCodeHelper.getBodyLanguageDir(),
    panelClass: 'ptl-mat-dialog',
    backdropClass: 'dialog-backdrop',
    data: {}
  }

  private aiCopiedText: string;
  private isWordLimitDialogOpened: boolean;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private translationService: TranslationService,
    private cd: ChangeDetectorRef,
    @Inject(LEARNER_VIEW_DATA_SERVICE) private dataService: LearnerViewDataService,
  ) {
    this.autoSaveSubscription = this.autoSaveSubject
      .pipe(debounceTime(500))
      .subscribe(() => this.saveUserInput());
    this.aiFeatureFlagSubscription = this.aiAssistantFeatureFlag$.pipe(
      withLatestFrom(this.organizationData$)
    ).subscribe(([flagEnabled]) => {
      this.aiAssistantFeatureEnabled = flagEnabled;
    });
  }

  ngOnInit() {
    this.wordLimit = this.formData?.content?.wordLimit || TEXT_FORM_MAX_WORDS_LIMIT;
    this.minWordsLimit = this.formData?.content?.minWordsLimit || TEXT_FORM_MIN_WORDS_LIMIT;
    this.aiAssistantEnabled = this.formData?.content?.aiAssistantEnabled
      ? this.formData.content.aiAssistantEnabled : false;
    this.aiAssistantSettingsUid = this.formData?.content?.aiAssistantSettingsUid ? this.formData?.content?.aiAssistantSettingsUid : null;
    if (this.formData && (this.formData.content as TextBoxFormContent).userAnswer) {
      const content = this.formData.content as TextBoxFormContent;
      if (content.formIsActive) {
        this.inputFocused = true;
      }
      if (content.userAnswer.length >= 0) {
        this.existingUserAnswer = JSON.parse(JSON.stringify(content.userAnswer));
        this.submitButtonEnabled = true;
        this.inputTextWords = this.countWords();
        this.submitted =
          (
            this.userPlaylistSubmissionSummary?.submissionMode === 'SINGLE' &&
            this.userPlaylistSubmissionSummary?.submittedOn
          ) ? true : content.submitted;
      }
      this.checkSubmissionMode();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.playlistUid && this.resourceUid && this.formData?.uid) {
      this.checkSubmissionMode();
    } else {
      if (changes.learnerFormAnswer || changes.userPlaylistSubmissionSummary) {
        this.checkSubmissionMode();
      }
    }
  }

  ngOnDestroy() {
    if (this.aiFeatureFlagSubscription) {
      this.aiFeatureFlagSubscription.unsubscribe();
    }
  }

  onInputFocus() {
    this.inputFocused = true;
    this.editClicked = true;
  }

  deactivateForm(input: string) {
    this.userInput = input;
    this.existingUserAnswer = input;
    this.triggerDeactivation();
  }

  saveUserInput(): void {
    if (this.userInput && !!this.userInput.length) {
      const event: FormSavedEvent = {
        formUid: this.formData.uid,
        answer: this.userInput,
      };
      this.submitButtonEnabled = !!this.userInput;
      this.store.dispatch(new SaveFormAnswer(this.playlistUid, event, this.formData.uid))
        .toPromise()
        .then(() => {
          this.autoSaveInProgress = false;
          this.autoSaved = true;
          this.cd.detectChanges();
        })
        .catch(() => {
          this.autoSaveInProgress = false;
          this.autoSaved = false;
          this.cd.detectChanges();
        });
    }
  }

  submit(): void {
    if (ContentHelper.isProgressiveSubmissionMode(this.userPlaylistSubmissionSummary)) {
      const dialogMessage =
        this.userPlaylistSubmissionSummary?.userReviewSummary?.reviewType &&
        this.userPlaylistSubmissionSummary.userReviewSummary.reviewType !== 'AUTOMATIC' ?
          this.translationService.getTranslation('dialog.title.formSubmissionReviewEnabled') :
          this.translationService.getTranslation('dialog.title.formSubmission')
      this.dialogService.showConfirmDialog(
        dialogMessage,
        this.translationService
      ).then(confirmed => {
          if (confirmed) {
            this.processSubmit();
          }
        }
      );
    } else {
      this.processSubmit();
    }
  }

  private processSubmit(): void {
    if (ContentHelper.shouldDisplayReviewersDialogOnFormSubmission(this.userPlaylistSubmissionSummary)) {
      this.openReviewersDialog();
    } else {
      this.submitForm();
    }
  }

  private submitForm(): void {
    this.submissionInProgress = true;
    this.existingUserAnswer = this.userInput;
    this.dataService.submitForm(this.playlistUid, this.resourceUid, this.formData.uid).subscribe(({isSuccess}) => {
      if (isSuccess) {
        this.refreshStateAndFinishSubmission();
      }
    });
  }

  private refreshStateAndFinishSubmission(): void {
    this.store.dispatch(new RefreshUserPlaylistSubmissionSummary(this.playlistUid));
    this.dataService.getCardWithProgress(
      this.isProjectResource,
      this.playlistUri,
      this.resourceUri,
      this.groupUid,
      this.publisherUri,
      this.packageUri,
      this.pageUri,
      this.languageCode
    ).subscribe(
      ({isSuccess, value}) => {
        if (isSuccess) {
          this.store.dispatch(new RefreshStateAfterFormSubmission(value));
          this.finishSubmission();
        }
      });
  }

  private openReviewersDialog(): void {
    const dialogRef = this.dialog.open(SubmitPlaylistReviewDialogComponent, {
      ...this.dialogConfig,
      data: {
        isProjectResource: this.isProjectResource,
        playlistUid: this.playlistUid,
        playlistTitle: this.playlistTitle,
        userPlaylistSubmissionSummary: this.userPlaylistSubmissionSummary,
        mode: 'FORM',
        resourceUid: this.resourceUid,
        formUid: this.formData.uid,
        playlistUri: this.playlistUri,
        resourceUri: this.resourceUri,
        groupUid: this.groupUid,
        publisherUri: this.publisherUri,
        packageUri: this.packageUri,
        pageUri: this.pageUri,
        languageCode: this.languageCode
      }
    });
    dialogRef.afterClosed().subscribe((data => {
      if (data) {
        this.finishSubmission();
      }
    }));
  }

  private finishSubmission(): void {
    this.closeFullscreenMode();
    this.submissionInProgress = false;
    this.autoSaveInProgress = false;
    this.submitted = true;
    this.triggerDeactivation();
    this.checkSubmissionMode();
  }

  onTextLengthUpdate(wordsNumber: number): void {
    this.inputTextWords = wordsNumber;
  }

  onMediumEditorUpdate(userInput: string): void {
    this.userInput = userInput;
    this.existingUserAnswer = userInput;
    this.inputTextWords = this.countWords();
    if (this.inputFocused) {
      if (!this.canEdit) {
        this.formData.content.userAnswer = userInput;
      } else {
        this.autoSaveInProgress = true;
        this.autoSaved = false;
        this.autoSaveSubject.next();
      }
    }
  }

  triggerEdit(): void {
    if (this.editClicked) {
      this.triggerDeactivation();
    } else if (this.canEdit) {
      this.onInputFocus();
    }
  }

  changeFullscreenMode(): void {
    this.fullscreenEnabled = !this.fullscreenEnabled;
    if (this.fullscreenEnabled) {
      document.body.classList.add('full-screen-enabled');
    } else {
      document.body.classList.remove('full-screen-enabled');
    }
  }

  closeFullscreenMode(): void {
    this.fullscreenEnabled = false;
    document.body.classList.remove('full-screen-enabled');
  }

  onPast(content: string): void {
    const formattedContent = this.formatAiStringForEditorContent(content);
    this.userInput = formattedContent;
    this.existingUserAnswer = formattedContent;
    this.inputTextWords = this.countWords();
    if (this.inputTextWords > this.wordLimit) {
      this.openWordLimitDialog(content, this.aiCopiedText);
    } else {
      this.saveUserInput();
    }
  }

  handleAiAssistantContent(content: { text: string; eventType: string }): void {
    this.showAiAssistant = false;
    if (content.eventType === 'insert') {
      const formattedContent = this.formatAiStringForEditorContent(content.text);
      this.userInput += formattedContent;
      this.existingUserAnswer += formattedContent;

      const countWords = this.countWords();

      if (countWords > this.wordLimit) {
        this.openWordLimitDialog(content);
      } else {
        this.saveUserInput();
      }
    }

    if (content.eventType === 'copy') {
      this.aiCopiedText = content.text;
    }
  }

  private openWordLimitDialog(content: { text: string; eventType: string } | string, copiedText: string = ''): void {
    if (this.isWordLimitDialogOpened) {
      return;
    }

    let aiData: string;

    if (typeof content === 'string') {
      aiData = copiedText || content;
    } else {
      aiData = copiedText || content.text;
    }

    const dialogRef = this.dialog.open(WordLimitDialogComponent, {
      ...this.dialogConfig,
      data: {
        aiData: aiData,
      }
    });

    this.isWordLimitDialogOpened = true;

    dialogRef.afterClosed().subscribe((data => {
      if (data) {
        this.userInput = this.removeAllContentInParagraphs(this.userInput);
        this.existingUserAnswer = this.removeAllContentInParagraphs(this.existingUserAnswer);

        this.userInput += this.formatAiStringForEditorContent(data);
        this.existingUserAnswer += this.formatAiStringForEditorContent(data);
        this.saveUserInput();
      }

      this.isWordLimitDialogOpened = false;
    }));
  }

  private removeAllContentInParagraphs(originalContent: string): string {
    const regex = /<p class="ai-generated">(.*?)<\/p>/gs;
    return originalContent.replace(regex, '');
  }

  private formatAiStringForEditorContent(aiData: string): string {
    aiData = ContentHelper.escapeHtml(aiData);
    const paragraphs = aiData.split('\n\n');
    let formattedParagraphs = paragraphs.map(paragraph => `<p class="ai-generated">${paragraph}</p>`).join('');
    formattedParagraphs = formattedParagraphs.replace(/\n/g, '<br>');
    return formattedParagraphs;
  }

  onResizeEnd(event: ResizeEvent): void {
    const width = event.rectangle.width > 900 ? 900 : event.rectangle.width < 300 ? 300 : event.rectangle.width;
    this.resizableContainerWidth = this.getContainerWidth(width);
  }

  closeAiAssistantBox(isClosed: boolean) {
    this.showAiAssistant = !isClosed;
  }

  get isSubmissionDisabled(): boolean {
    return this.submissionInProgress || !this.submitButtonEnabled;
  }

  get submittedAnswerContent() {
    return {content: this.existingUserAnswer, type: 'PARAGRAPH'};
  }

  private checkSubmissionMode(): void {
    if (!this.learnerFormAnswer) {
      if (!this.playlistUid || !this.resourceUid || !this.formData?.uid) {
        return;
      }
      if (!this.validationInProgress && !this.validationProcessed) {
        this.validationInProgress = true;
        this.dataService.validateFormUpdate(this.playlistUid, this.resourceUid, this.formData?.uid)
          .subscribe(({isSuccess, value}) => {
            if (isSuccess) {
              this.canEdit = value.canBeUpdated;
              this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translationService, value);
              this.requiresResubmission = false;
              this.validationProcessed = true;
            }
            this.checkSubmissionLocked();
            this.cd.detectChanges();
            this.validationInProgress = false;
          });
      }
    } else {
      this.canEdit = this.learnerFormAnswer?.updatePermission?.canBeUpdated;
      this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translationService, this.learnerFormAnswer?.updatePermission);
      this.requiresResubmission = this.learnerFormAnswer?.requiresResubmission;
      this.checkSubmissionLocked();
      this.cd.detectChanges();
    }
  }

  private countWords(): number {
    let cleanedAnswer = HtmlSanitizer.stripAllTags(this.existingUserAnswer.trim());
    cleanedAnswer = cleanedAnswer.replace(/&nbsp;/g, ' '); // This replaces &nbsp; that copy & paste adds
    cleanedAnswer = cleanedAnswer.trim();
    if (cleanedAnswer === '') {
      return 0;
    }
    return cleanedAnswer.trim().split(/\s+/).filter(word => word.length > 0).length;
  }

  private triggerDeactivation(): void {
    this.inputFocused = false;
    this.editClicked = false;
  }

  private getContainerWidth(width: number): string {
    return `${(width / 16)}rem`;
  }

  private checkSubmissionLocked(): void {
    if (this.userPlaylistSubmissionSummary?.submissionsLocked) {
      this.canEdit = false;
      this.canNotEditReason = this.translationService.getTranslation('formUpdateDisabledReasons.submissionClosed')
      this.requiresResubmission = false;
      this.submitButtonEnabled = false;
    }
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    if (!this.fullscreenEnabled) {
      return;
    }

    this.closeFullscreenMode();
  }
}
