/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UserAuthState } from '../../user-auth/store/user-auth.state';
import { Organization } from '../models/organization';
import { developmentDomains } from '../helpers/development-domains.helper';
import { map } from 'rxjs/operators';
import { RedirectHelper } from '../../page-modules/resource/store/editor/content/helpers/redirect.helper';

export const BETA_ACCESS_KEY = 'betaAccess';

@Injectable({
  providedIn: 'root',
})
export class BetaAccessGuard implements CanActivate {

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone
  ) {
  }

  /**
   * Checks if it is development environment (domain).
   * If not, the user will not be allowed to access the guarded page.
   *
   */
  canActivate(): Observable<boolean> {

    return this.organizationData$.pipe(map(organization => {
      const isDevelopmentDomain = (organization ? developmentDomains(organization.domain) : false);
      const isBetaAccess = window.sessionStorage.getItem(BETA_ACCESS_KEY) === 'true';
      if (!isDevelopmentDomain && !isBetaAccess) {
        const homePageUri = this.store.selectSnapshot(UserAuthState.homePageUri);
        RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, homePageUri);
      }
      return isDevelopmentDomain || isBetaAccess;
    }));
  }

}
