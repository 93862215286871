/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LEARNER_PLAYLIST_DATA_SERVICE,
  LearnerPlaylistDataService
} from '../../services/learner-playlist/data.service';
import { LearnerPlaylistSummary } from '../../models/playlist/learner-playlist-summary.model';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { LoadableState } from '../../store';
import { PlaylistCardShort, Resource } from '../../models';
import { filter, map } from 'rxjs/operators';
import { PlaylistViewState } from '../../../page-modules/playlist/store/view/playlist-view.state';
import {
  ClearAllBreadcrumbs,
  PopBreadcrumb,
  PopGroupBreadcrumbIfPresent
} from '../../breadcrumbs/store/breadcrumbs.actions';
import { SummariesState } from '../../../app-frame/store/summaries.state';
import { Page } from '../../models/page';
import {
  RedirectCardType,
  RedirectHelper
} from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { ResourceAdminState } from '../../../page-modules/resource/store/admin/resource-admin.state';

@Component({
  selector: 'ptl-next-previous-buttons',
  templateUrl: './next-previous-buttons.component.html',
  styleUrls: ['./next-previous-buttons.component.scss'],
})
export class NextPreviousButtonsComponent implements OnInit, OnDestroy {

  @Input() isPlaylist: boolean;

  @Select(ResourceAdminState.resource)
  private resource$: Observable<Resource>;

  @Select(PlaylistViewState.resourceAllCards)
  private allCards$: Observable<PlaylistCardShort[]>;

  @Select(SummariesState.playlistSummaries)
  private playlistSummaries$: Observable<LoadableState<Page<LearnerPlaylistSummary>>>;

  @Select(PlaylistViewState.playlistFormattedUri)
  private playlistFormattedUri$: Observable<string>;

  showNextButton: boolean;
  showPreviousButton: boolean;
  nextButtonTitle: string;
  previousButtonTitle: string;
  isNavigationInGroup: boolean;

  private playlistFormattedUri: string;
  private currentItemIndex: number;
  private learnerPlaylist: LearnerPlaylistSummary[] = [];
  private groupCards: PlaylistCardShort[];
  private playlistCards: PlaylistCardShort[];
  private allCardsSubscription: Subscription;
  private resourceSubscription: Subscription;
  private playlistFormattedUriSubscription: Subscription;
  private playlistSummariesSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    @Inject(LEARNER_PLAYLIST_DATA_SERVICE) private learnerPlaylistService: LearnerPlaylistDataService,
  ) {
  }

  ngOnInit() {
    this.isNavigationInGroup = !!this.route.snapshot.paramMap.get('groupUri');

    this.playlistFormattedUriSubscription = this.playlistFormattedUri$.pipe()
      .subscribe(formattedUri => {
        this.playlistFormattedUri = formattedUri;
      })

    if (this.isPlaylist) {
      this.loadLearnerPlaylists();
    } else {
      if (this.isNavigationInGroup) {
        this.loadGroupCardsList();
      } else {
        this.loadPlaylistCardsList();
      }
    }
  }

  ngOnDestroy() {
    this.resourceSubscription?.unsubscribe();
    this.allCardsSubscription?.unsubscribe();
    this.playlistFormattedUriSubscription?.unsubscribe();
  }

  openNextPreviousButtonClick(openNext: boolean) {
    if (this.isPlaylist) {
      this.openNextPreviousPlaylist(openNext);
    } else {
      if (this.isNavigationInGroup) {
        this.openNextPreviousGroupCard(openNext);
      } else {
        this.openNextPreviousCard(openNext);
      }
    }
  }

  returnToPlaylistOrGroup() {
    const currentPlaylistUri = this.route.snapshot.paramMap.get('playlistUri');

    if (this.isNavigationInGroup) {
      const currentGroupUri = this.route.snapshot.paramMap.get('groupUri');
      this.store.dispatch(new PopGroupBreadcrumbIfPresent());
      RedirectHelper.redirectByParams(this.ngZone, this.router, this.route, {
        playlistUri: currentPlaylistUri,
        resourceUri: currentGroupUri,
      }, 'GROUP');
    } else {
      this.store.dispatch(new PopBreadcrumb());
      RedirectHelper.redirectByParams(this.ngZone, this.router, this.route, {
        playlistUri: currentPlaylistUri,
        formattedUri: this.playlistFormattedUri,
      }, 'PLAYLIST');
    }
  }

  private loadLearnerPlaylists() {
    this.playlistSummariesSubscription = this.playlistSummaries$.pipe(
      filter(({ loading, data }) => !loading && !!data),
      map(value => value.data)
    ).subscribe(playlistSummaries => {
      this.learnerPlaylist = playlistSummaries.content;
      const currentPlaylistUri = this.route.snapshot.paramMap.get('playlistUri');
      this.currentItemIndex = this.learnerPlaylist.findIndex(playlist => playlist.uri === currentPlaylistUri);
      this.checkButtonsVisibility(this.learnerPlaylist);
    });
  }

  private openNextPreviousPlaylist(openNext: boolean) {
    const nextPrevPlaylist = openNext ? this.learnerPlaylist[this.currentItemIndex + 1] : this.learnerPlaylist[this.currentItemIndex - 1];
    if (nextPrevPlaylist) {
      this.store.dispatch(new ClearAllBreadcrumbs());
      RedirectHelper.redirectByParams(this.ngZone, this.router, this.route, {
        playlistUri: nextPrevPlaylist.uri,
        formattedUri: nextPrevPlaylist.formattedUri,
      }, 'PLAYLIST');
    }
  }

  private openNextPreviousGroupCard(openNext: boolean) {
    const nextPrevGroupCard = openNext ? this.groupCards[this.currentItemIndex + 1] : this.groupCards[this.currentItemIndex - 1];
    if (nextPrevGroupCard) {
      const playlistUri = this.route.snapshot.paramMap.get('playlistUri');
      this.redirectToPage(playlistUri, nextPrevGroupCard);
      this.currentItemIndex = this.groupCards.findIndex(card => card.uri === nextPrevGroupCard.uri);
      this.checkButtonsVisibility(this.groupCards);
    }
  }

  private loadGroupCardsList() {
    this.resourceSubscription = combineLatest([this.resource$, this.route.paramMap]).subscribe(data => {
      const resource = data[0];
      const paramMap = data[1];
      if (resource?.cards && paramMap) {
        this.groupCards = resource.cards;
        let currentCardUri = paramMap.get('resourceUri');

        if (!currentCardUri) {
          currentCardUri = paramMap.get('diagnosticUri');
        }

        this.currentItemIndex = this.groupCards.findIndex(card => card.uri === currentCardUri);
        this.checkButtonsVisibility(this.groupCards);
      }
    });
  }

  private loadPlaylistCardsList() {
    this.allCardsSubscription = combineLatest([this.allCards$, this.route.paramMap]).subscribe(data => {
      const cards = data[0];
      const paramMap = data[1];

      if (cards && paramMap) {
        this.playlistCards = cards;
        let currentCardUri = paramMap.get('resourceUri');

        if (!currentCardUri) {
          currentCardUri = paramMap.get('diagnosticUri');
        }

        this.currentItemIndex = this.playlistCards.findIndex(card => card.uri === currentCardUri);
        this.checkButtonsVisibility(this.playlistCards);
      }
    });
  }

  private openNextPreviousCard(openNext: boolean) {
    const nextPrevCard = openNext ? this.playlistCards[this.currentItemIndex + 1] : this.playlistCards[this.currentItemIndex - 1];
    if (nextPrevCard) {
      const playlistUri = this.route.snapshot.paramMap.get('playlistUri');
      this.redirectToPage(playlistUri, nextPrevCard);
      this.currentItemIndex = this.playlistCards.findIndex(card => card.uri === nextPrevCard.uri);
      this.checkButtonsVisibility(this.playlistCards);
    }
  }

  private redirectToPage(playlistUri: string, card?: PlaylistCardShort) {
    if (!card) {
      return false;
    }
    if (card.type === 'PLAYLIST') {
      RedirectHelper.redirectByParams(this.ngZone, this.router, this.route, {
        playlistUri: card.uri,
        formattedUri: card.formattedUri,
      }, 'PLAYLIST');
      return false;
    }
    if (card.type === 'GROUP') {
      RedirectHelper.redirectByParams(this.ngZone, this.router, this.route, {
        playlistUri: playlistUri,
        formattedUri: card.formattedUri,
        resourceUri: card.uri,
      }, 'GROUP');
      return false;
    }

    RedirectHelper.redirectByParams(this.ngZone, this.router, this.route, {
      playlistUri: playlistUri,
      groupUri: this.route.snapshot.paramMap.get('groupUri'),
      resourceUri: card.uri,
      formattedUri: card.formattedUri,
      pageNumberUri: 'page/1',
    }, card.type as RedirectCardType);
    return false;
  }

  private checkButtonsVisibility(cards) {
    if (this.currentItemIndex > -1) {
      this.showPreviousButton = this.currentItemIndex > 0;
      this.showNextButton = this.currentItemIndex < cards.length - 1;
    }
    const titleLength = 45;
    if (cards[this.currentItemIndex + 1]) {
      this.nextButtonTitle = this.isPlaylist ? cards[this.currentItemIndex + 1].title : cards[this.currentItemIndex + 1].header;
      if (this.nextButtonTitle.length > titleLength) {
        this.nextButtonTitle = this.nextButtonTitle.substring(0, titleLength) + '...';
      }
    } else {
      this.nextButtonTitle = '';
    }
    if (cards[this.currentItemIndex - 1]) {
      this.previousButtonTitle = this.isPlaylist ? cards[this.currentItemIndex - 1].title : cards[this.currentItemIndex - 1].header;
      if (this.previousButtonTitle.length > titleLength) {
        this.previousButtonTitle = this.previousButtonTitle.substring(0, titleLength) + '...';
      }
    } else {
      this.previousButtonTitle = '';
    }
  }

}
