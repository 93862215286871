import { getTooltipImageByType } from '@app/app/shared/editor-js/helper/ToolTipMethods';

const TooltipOptions = [
	{
		buttonType: 'paragraph',
		image: getTooltipImageByType('FORM', 'TEXTBOX'),
		title: 'translations.editor.toolbarNew.option.textForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.textForm.tooltipDescription',
		subType: 'paragraph'
	},
	{
		buttonType: 'LINK',
		image: getTooltipImageByType('LINK', null),
		title: 'translations.editor.toolbarNew.option.link.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.link.tooltipDescription',
		subType: 'LINK'
	},
	{
		buttonType: 'UPLOAD',
		image: getTooltipImageByType('UPLOAD', 'FILE'),
		title: 'translations.editor.toolbarNew.option.file.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.file.tooltipDescription',
		subType: 'FilePlugin'
	},
	{
		buttonType: 'UPLOAD',
		image: getTooltipImageByType('UPLOAD', 'VIDEO'),
		title: 'translations.editor.toolbarNew.option.video.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.video.tooltipDescription',
		subType: 'VideoPlugin'
	},
	{
		buttonType: 'UPLOAD',
		image: getTooltipImageByType('UPLOAD', 'AUDIO'),
		title: 'translations.editor.toolbarNew.option.audio.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.audio.tooltipDescription',
		subType: 'AudioPlugin'
	},
	{
		buttonType: 'IMAGE',
		image: getTooltipImageByType('UPLOAD', 'IMAGE'),
		title: 'translations.editor.toolbarNew.option.image.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.image.tooltipDescription',
		subType: 'IMAGE'
	},
	{
		buttonType: 'EMBED',
		image: getTooltipImageByType('EMBED', null),
		title: 'translations.editor.toolbarNew.option.embed.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.embed.tooltipDescription',
		subType: 'EMBED_CODE'
	},
	{
		buttonType: 'CHART',
		image: getTooltipImageByType('CHART', null),
		title: 'translations.editor.toolbarNew.option.chart.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.chart.tooltipDescription',
		subType: null
	},
	{
		buttonType: 'SEPARATOR',
		image: getTooltipImageByType('SEPARATOR', null),
		title: 'translations.editor.toolbarNew.option.separator.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.separator.tooltipDescription',
		subType: 'SEPARATOR'
	},
	{
		buttonType: 'FORM',
		image: getTooltipImageByType('FORM', 'TEXTBOX'),
		title: 'translations.editor.toolbarNew.option.textForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.textForm.tooltipDescription',
		subType: 'TEXTBOX'
	},
	{
		buttonType: 'FORM',
		image: getTooltipImageByType('FORM', 'CONNECTED_TEXTBOX'),
		title: 'translations.editor.toolbarNew.option.connectedTextForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.connectedTextForm.tooltipDescription',
		subType: 'CONNECTED_TEXTBOX'
	},
	{
		buttonType: 'FORM',
		image: getTooltipImageByType('FORM', 'QUESTION_FORM'),
		title: 'translations.editor.toolbarNew.option.questionForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.questionForm.tooltipDescription',
		subType: 'QUESTION_FORM'
	},
	{
		buttonType: 'FORM',
		image: getTooltipImageByType('FORM', 'PRIVATE_NOTE'),
		title: 'translations.editor.toolbarNew.option.privateNote.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.privateNote.tooltipDescription',
		subType: 'PRIVATE_NOTE'
	},
	{
		buttonType: 'FORM',
		image: getTooltipImageByType('FORM', 'CHECKBOX'),
		title: 'translations.editor.toolbarNew.option.checkboxForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.checkboxForm.tooltipDescription',
		subType: 'CHECKBOX'
	},
	{
		buttonType: 'FORM',
		image: getTooltipImageByType('FORM', 'USER_FILE_UPLOAD'),
		title: 'translations.editor.toolbarNew.option.fileForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.fileForm.tooltipDescription',
		subType: 'USER_FILE_UPLOAD'
	},
	{
		buttonType: 'FORM',
		image: getTooltipImageByType('FORM', 'BOOKING'),
		title: 'translations.editor.toolbarNew.option.bookingForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.bookingForm.tooltipDescription',
		subType: 'BOOKING'
	},
	{
		buttonType: 'FORM',
		image: getTooltipImageByType('FORM', 'QUIZ'),
		title: 'translations.editor.toolbarNew.option.quizForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.quizForm.tooltipDescription',
		subType: 'QUIZ'
	},
	{
		buttonType: 'FORM',
		image: getTooltipImageByType('FORM', 'COLLECTOR'),
		title: 'translations.editor.toolbarNew.option.collectorForm.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.collectorForm.tooltipDescription',
		subType: 'COLLECTOR'
	},
	{
		buttonType: 'table',
		image: getTooltipImageByType('TABLE', null),
		title: 'translations.editor.toolbarNew.option.table.tooltipTitle',
		description: 'translations.editor.toolbarNew.option.table.tooltipDescription',
		subType: 'table'
	}
];


export default TooltipOptions;