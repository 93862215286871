export const TABLE_EDITOR_OPTIONS = {
  inline: false,
  menubar: false,
  placeholder: '',
  plugins: [
    'lists', 'link', 'quickbars', 'table', 'autoresize',
  ],
  color_map: [
    'e6194B', 'Crimson',
    '3cb44b', 'Chateau Green',
    'ffe119', 'Broom',
    '4363d8', 'Royal Blue',
    'f58231', 'Jaffa',
    '911eb4', 'Seance',
    '42d4f4', 'Picton Blue',
    'f032e6', 'Razzle Dazzle Rose',
    'bfef45', 'Starship',
    'fa9b9d', 'Sweet Pink',
    '469990', 'Wedgewood',
    'e6beff', 'Mauve',
    '9A6324', 'Kumera',
    '800000', 'Maroon',
    '808000', 'Olive',
    '000075', 'Navy Blue',
    '595959', 'Scorpion',
    'a9a9a9', 'Silver Chalice',
    '000000', 'Black',
  ],
  block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6',
  contextmenu: false,
  relative_urls: false,
  convert_urls: false,
  toolbar: false,
  content_style: 'body { ' +
    'font-family: "charter-regular", sans-serif;' +
    'font-size: 16px;' +
    ' padding-bottom: 30px; margin-bottom: -10px; } ' +
    'table { table-layout: fixed;}',
  paste_data_images: false,
  object_resizing: true,
  quickbars_selection_toolbar: 'blocks bold italic link blockquote forecolor alignleft ' +
    'aligncenter alignright bullist numlist removeformat',
  powerpaste_word_import: 'clean',
  powerpaste_html_import: 'clean',
};
