<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<form class="add-question__section" [formGroup]="question">
  <div class="add-question__section__grid">
    <div class="add-question__section__grid--spacing" [class.is_disabled]="!isDefaultLanguage">
      <div>
        <span
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.userSelectMaximum' | transloco">
          {{ 'translations.userSelectMaximum' | transloco }}</span>
        <mat-form-field class="add-question__section-field add-question__section-field--small no-padding color-gray"
                        appearance="outline">
          <input
            matInput
            type="number"
            formControlName="selectionLimit"
            min="0">
        </mat-form-field>
        <span
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.optionsLower' | transloco">
          {{ 'translations.optionsLower' | transloco }}</span>
      </div>
      <div>
        <span
          tabindex="0"
          [attr.aria-label]="'translations.global.ariaLabels.optionsPerRowText' | transloco">
          {{ 'translations.optionsPerRowText' | transloco }}</span>
        <mat-form-field class="add-question__section-field add-question__section-field--small no-padding color-gray"
                        appearance="outline">
          <mat-select formControlName="optionsPerRow">
            <mat-option *ngFor="let option of gridOptionsPerRow" [value]="option"
                        tabindex="0"
                        [attr.aria-label]="option">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-checkbox
        [(ngModel)]="isNoneOfTheseActive"
        (change)="onNoneOfTheseSelected()"
        [ngModelOptions]="{standalone: true}"
        tabindex="0"
        [aria-label]="'translations.global.ariaLabels.addNoneOfTheseOption' | transloco"
        class="checkbox-item"
        color="primary">
        <span>{{ 'translations.addNoneOfTheseOption' | transloco }}</span>
      </mat-checkbox>
    </div>
    <div formArrayName="options">
      <div class="add-question__section-table">
        <div class="add-question__section-column add-question__section-column--grid">
          <h2
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.gridOptionsTitle' | transloco">
            {{ 'translations.gridOptionsTitle' | transloco }}</h2>
        </div>
        <div class="add-question__section-column add-question__section-column--narrow">
          <h2
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.score' | transloco">
            {{ 'translations.score' | transloco }}</h2>
        </div>
      </div>
      <div
        cdkDropList
        [cdkDropListData]="question.controls['options']['controls']"
        (cdkDropListDropped)="dropOption($event)">
        <div
          cdkDrag
          cdkDragLockAxis="y"
          class="add-question__section-table"
          *ngFor="let item of question.controls['options']['controls']; let i = index"
          [formGroupName]="i"
          [cdkDragData]="item">
          <div
            cdkDragHandle
            [class.is_disabled]="!isDefaultLanguage"
            class="add-question__section-column add-question__section-column--icons add-question__section-column-icon-grid  add-question__section-column__hoverable">
            <i class="material-icons" aria-hidden="true">unfold_more</i>
          </div>
          <div
            class="add-question__section-column add-question__section-column--width"
            formGroupName="value">
            <mat-form-field class="add-question__section-field no-padding color-gray" appearance="outline">
              <input
                matInput
                type="text"
                [attr.aria-label]="('translations.global.ariaLabels.enterOptionTitle' | transloco)"
                formControlName="value">
            </mat-form-field>
          </div>
          <div class="add-question__section-column" [class.is_disabled]="!isDefaultLanguage">
            <mat-form-field class="add-question__section-field no-padding color-gray" appearance="outline">
              <input
                matInput
                type="number"
                min="0"
                [attr.aria-label]="('translations.global.ariaLabels.enterOptionScore' | transloco)"
                pattern="^\d+$"
                formControlName="score">
            </mat-form-field>
          </div>
          <span #removeCategory class="cdk-visually-hidden"
                tabindex="0"
                [attr.aria-label]="'translations.global.ariaLabels.removeOption' | transloco">
            {{ 'translations.removeOption' | transloco }}</span>
          <button
            type="button"
            [class.is_disabled]="!isDefaultLanguage"
            class="add-question__section-column add-question__section-column-remove add-question__section-column-remove-grid"
            *ngIf="i > 0"
            [title]="removeCategory.innerHTML"
            [attr.aria-label]="('translations.global.ariaLabels.removeOption' | transloco)"
            (click)="removeOption(i)">
            <mat-icon aria-hidden="true" [inline]="true">highlight_off</mat-icon>
          </button>
        </div>
      </div>

      <button
        mat-button
        class="add-question__section-add-new"
        [class.is_disabled]="!isDefaultLanguage"
        type="button"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.clickToAddOption' | transloco"
        (click)="addNewOption()">
        <i class="material-icons" aria-hidden="true">add_circle_outline</i>
        <span>{{ 'translations.clickToAddOption' | transloco }}</span>
      </button>
    </div>
  </div>
</form>
