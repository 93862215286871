/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Inject, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ConnectedTextBoxFormContent, EditorContent } from '../../../editor/models';
import { ContentHelper } from '../../../shared/helpers/content-helper';
import { notDevelopmentDomains } from '../../../shared/helpers/development-domains.helper';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import {
  AvailableLanguage,
  DiagnosticQuestionResponse,
  Diagnostics,
  Form,
  Resource,
  ResourceApprovalType,
  ResourceCardType,
  ResourceSection,
  ResourceTag
} from '../../../shared/models';
import { CardCompletionRequirements } from '../../../shared/models/completion-logic/completion-logic.model';
import { LikeInfoDetails } from '../../../shared/models/like-info/like-info.model';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { dataLoadedState, errorState, LoadableState, loadingState } from '../../../shared/store';
import { UserAuthState, UserAuthStateModel } from '../../../user-auth/store/user-auth.state';
import { CardWithProgress, LearnerCardStatus, LearnerFormAnswer, LearnerFormAnswerNote } from '../models';
import { LEARNER_VIEW_DATA_SERVICE, LearnerViewDataService } from '../services/data.service';
import { EventCardTime } from './admin/resource-event-admin.state.model';
import { RedirectCardType, RedirectHelper } from './editor/content/helpers/redirect.helper';
import * as LearnerViewActions from './learner-view.actions';
import { INITIAL_STATE, LearnerViewStateModel } from './learner-view.state.model';

@State<LearnerViewStateModel>({
  name: 'learnerView',
  defaults: INITIAL_STATE,
})
@Injectable()
export class LearnerViewState {

  @Selector()
  static loadingResourceDetails({ resource }: LearnerViewStateModel): boolean {
    return resource.loading;
  }

  @Selector()
  static resourceState({ resource }: LearnerViewStateModel): LoadableState<Resource> {
    return resource;
  }

  @Selector()
  static resourceData({ resource }: LearnerViewStateModel): Resource {
    return resource.data;
  }

  @Selector()
  static resourceId({ resource }: LearnerViewStateModel): string {
    return resource.data._id;
  }

  @Selector()
  static formattedUri({ resource }: LearnerViewStateModel): string {
    return resource.data.formattedUri;
  }

  @Selector()
  static mainSection({ resource }: LearnerViewStateModel): EditorContent[] {
    const mainSection = resource.data.content.sections[0];
    return mainSection ? mainSection.dynamicContent : [];
  }

  @Selector()
  static existingSections({ resource }: LearnerViewStateModel): ResourceSection[] {
    return resource.data.content.sections.length <= 1 ? [] : resource.data.content.sections;
  }

  @Selector()
  static headline({ resource }: LearnerViewStateModel): string {
    return resource.data.content.headline;
  }

  @Selector()
  static tags({ resource }: LearnerViewStateModel): ResourceTag[] {
    return resource.data.tags;
  }

  @Selector()
  static isEventCard({ resource }: LearnerViewStateModel): boolean {
    return resource.data.cardType === 'EVENT';
  }

  @Selector()
  static cardType({ resource }: LearnerViewStateModel): ResourceCardType {
    return resource.data.cardType;
  }

  @Selector()
  static questions({ resource }: LearnerViewStateModel): DiagnosticQuestionResponse[] {
    return (resource.data as Diagnostics).questions;
  }

  @Selector()
  static timeRequired({ resource }: LearnerViewStateModel): number {
    return resource.data.timeRequired;
  }

  @Selector([UserAuthState])
  static canViewMembers({ resource }: LearnerViewStateModel, userAuthState: UserAuthStateModel): boolean {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }

    if (userAuthState.userDetails.data && !resource.data.syndicated) {
      if (resource.data.settings.publication.author.uid === userAuthState.userDetails.data.uid) {
        return true;
      } else {
        return resource.data.permission.canViewAnalytics;
      }
    }
    return false;
  }

  @Selector([UserAuthState])
  static canViewAnalytics({ resource }: LearnerViewStateModel, userAuthState: UserAuthStateModel): boolean {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }

    if (userAuthState.userDetails.data && !resource.data.syndicated) {
      if (resource.data.settings.publication.author.uid === userAuthState.userDetails.data.uid) {
        return true;
      } else {
        return resource.data.permission.canViewAnalytics;
      }
    }
    return false;
  }

  @Selector([UserAuthState])
  static canPublish({ resource }: LearnerViewStateModel, userAuthState: UserAuthStateModel): boolean {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }

    if (userAuthState.userDetails.data && !resource.data.syndicated) {
      if (resource.data.settings.publication.author.uid === userAuthState.userDetails.data.uid) {
        return true;
      } else {
        return resource.data.permission.canPublish;
      }
    }
    return false;
  }

  @Selector([UserAuthState])
  static canClone({ resource }: LearnerViewStateModel, userAuthState: UserAuthStateModel): boolean {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }

    if (userAuthState.userDetails.data && !resource.data.syndicated) {
      if (resource.data.settings.publication.author.uid === userAuthState.userDetails.data.uid) {
        return true;
      } else {
        return resource.data.permission.canClone;
      }
    }
    return false;
  }

  @Selector([UserAuthState])
  static canEdit({ resource }: LearnerViewStateModel, userAuthState: UserAuthStateModel): boolean {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }

    if (userAuthState.userDetails.data) {
      if (resource.data.settings.publication.author.uid === userAuthState.userDetails.data.uid) {
        return true;
      } else {
        return resource.data.permission.canEdit;
      }
    }
    return false;
  }

  @Selector([UserAuthState])
  static canChangeCompletion({ resource }: LearnerViewStateModel, userAuthState: UserAuthStateModel): boolean {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }

    if (userAuthState.userDetails.data) {
      if (resource.data.settings.publication.author.uid === userAuthState.userDetails.data.uid) {
        return true;
      } else {
        return (resource.data.permission.canEditGoal || resource.data.permission.canEditBadge);
      }
    }
    return false;
  }

  @Selector([UserAuthState])
  static canReview({ resource }: LearnerViewStateModel, userAuthState: UserAuthStateModel) {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }

    const isUnderReview = resource.data.approvalMethod.type === 'REQUIRES_REVIEW';
    if (userAuthState.userDetails.data) {
      if (resource.data.settings.publication.author.uid === userAuthState.userDetails.data.uid) {
        return isUnderReview;
      } else {
        for (const reviewer of resource.data.approvalMethod.reviewers) {
          if (reviewer.uid === userAuthState.userDetails.data.uid) {
            return isUnderReview;
          }
        }
      }
    }
    return false;
  }


  @Selector([UserAuthState])
  static canOpenEditPages({ resource }: LearnerViewStateModel, userAuthState: UserAuthStateModel): boolean {
    if (ContentHelper.isFrameMode() && notDevelopmentDomains(userAuthState?.orgDetails?.data?.domain)) {
      return false;
    }

    if (resource?.data?.syndicated) {
      return false;
    }

    if (userAuthState.userDetails.data) {
      if (resource.data.settings.publication.author.uid === userAuthState.userDetails.data.uid) {
        return true;
      }
    }
    if (!resource.data?.permission) {
      return false;
    } else {
      const permission = resource.data.permission;
      return permission.canEdit ||
        permission.canEditBadge ||
        permission.canEditGoal ||
        permission.canPublish ||
        permission.canViewAnalytics ||
        permission.canManageCollaboration ||
        permission.canManageTargeting;
    }
  }

  @Selector()
  static completion({ completion }: LearnerViewStateModel): LoadableState<CardCompletionRequirements> {
    return completion;
  }

  @Selector()
  static isSyndicated({ resource }: LearnerViewStateModel): boolean {
    return !!resource.data.syndicated;
  }

  @Selector()
  static learnerFormAnswers({ status }: LearnerViewStateModel): LearnerFormAnswer[] {
    return status.learnerFormAnswers.data;
  }

  @Selector()
  static hasPendingForms({ status }: LearnerViewStateModel): boolean {
    const learnerFormAnswers = status.learnerFormAnswers.data;

    if (learnerFormAnswers) {
      for (const learnerFormAnswer of learnerFormAnswers) {
        if (!learnerFormAnswer.submitted) {
          return true;
        }
      }
    }

    return false;
  }


  @Selector()
  static lastUpdatedOn({ resource }: LearnerViewStateModel): string {
    return resource.data.lastUpdatedOn;
  }

  @Selector()
  static cardStatuses({ status }: LearnerViewStateModel): LearnerCardStatus[] {
    return status.cardStatuses.data;
  }

  @Selector()
  static startDate({ status }: LearnerViewStateModel): string {
    return status.startedOn.data;
  }

  @Selector()
  static updateDate({ status }: LearnerViewStateModel): string {
    return status.updatedOn.data;
  }

  @Selector()
  static reviewStatus({ status }: LearnerViewStateModel): ResourceApprovalType {
    return status.reviewStatus.data;
  }

  @Selector()
  static likeInfoDetails({ resource }: LearnerViewStateModel): LikeInfoDetails {
    return resource.data.likes;
  }

  @Selector()
  static availableLanguages({ resource }: LearnerViewStateModel): AvailableLanguage[] {
    return resource.data.availableLanguages;
  }

  @Selector()
  static currentLanguage({ currentLanguage }: LearnerViewStateModel): string {
    return currentLanguage.data;
  }

  @Selector()
  static cardOpened({ cardOpened }: LearnerViewStateModel): boolean {
    return cardOpened.data;
  }

  @Selector()
  static eventTime({ resource }: LearnerViewStateModel): EventCardTime {
    return resource.data.time;
  }

  @Selector()
  static participatedInEvent({ resource }: LearnerViewStateModel): boolean {
    return resource.data.participationStatus === 'PARTICIPATED';
  }

  constructor(
    @Inject(LEARNER_VIEW_DATA_SERVICE) private dataService: LearnerViewDataService,
    private translationService: TranslationService,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
  ) {
  }

  @Action(LearnerViewActions.RefreshLearnerViewCardStatus)
  refreshCardCompletionStatus(
    { patchState }: StateContext<LearnerViewStateModel>,
    {
      isProjectResource,
      playlistUri,
      resourceUri,
      groupUri,
      publisherUri,
      packageUri,
      pageUri,
      languageCode,
    }: LearnerViewActions.LoadLearnerViewDetails
  ) {
    return this.dataService.getCardWithProgress(
      isProjectResource,
      playlistUri,
      resourceUri,
      groupUri,
      publisherUri,
      packageUri,
      pageUri,
      languageCode
    ).pipe(
      tap(({ isSuccess, value }) => {
        if (isSuccess) {
          patchState({
            status: {
              cardStatuses: dataLoadedState(value.progress?.cardStatuses),
              learnerFormAnswers: dataLoadedState(value.progress?.learnerFormAnswers),
              startedOn: dataLoadedState(value.progress?.startedOn),
              updatedOn: dataLoadedState(value.progress?.lastUpdatedOn),
              reviewStatus: dataLoadedState(value.card?.approvalMethod?.type),
            },
          });
        }
      }));
  }

  @Action(LearnerViewActions.LoadLearnerViewDetails)
  loadResourceDetails(
    { getState, patchState }: StateContext<LearnerViewStateModel>,
    {
      isProjectResource,
      playlistUri,
      resourceUri,
      groupUri,
      publisherUri,
      packageUri,
      pageUri,
      languageCode
    }: LearnerViewActions.LoadLearnerViewDetails
  ) {
    patchState({
      resource: loadingState(),
    });
    return this.dataService.getCardWithProgress(
      isProjectResource,
      playlistUri,
      resourceUri,
      groupUri,
      publisherUri,
      packageUri,
      pageUri,
      languageCode
    ).pipe(
      tap(({ isSuccess, value, error, dataForms }) => {
        if (!isSuccess) {
          patchState({
            resource: errorState(error, dataForms),
          });
          return;
        }

        this.loadResource(value, patchState);
      })
    );
  }


  @Action(LearnerViewActions.GetGroupCards)
  getGroupCards(
    { getState, patchState }: StateContext<LearnerViewStateModel>,
    { playlistUri, resourceUri, languageCode }: LearnerViewActions.GetGroupCards
  ) {
    patchState({
      resource: loadingState(),
    });
    return this.dataService.getGroupCards(playlistUri, resourceUri, languageCode).pipe(
      tap(({ isSuccess, value, error }) => {
        if (isSuccess) {
          patchState({
            resource: dataLoadedState(value),
            cardOpened: dataLoadedState(false),
          });
        } else {
          patchState({
            resource: errorState(error),
          });
        }
      })
    );
  }

  @Action(LearnerViewActions.UpdateLearnerViewDetailsWithProgress)
  updateLearnerViewDetailsWithProgress(
    { getState, patchState }: StateContext<LearnerViewStateModel>,
    { cardWithProgress }: LearnerViewActions.UpdateLearnerViewDetailsWithProgress
  ) {
    const { card } = cardWithProgress;
    const resourceWithFormAnswers = cardWithProgress.progress
      ? this.enrichResourceFormContentWithAnswers(
        card,
        cardWithProgress.progress.learnerFormAnswers)
      : card;

    patchState({
      resource: dataLoadedState(resourceWithFormAnswers),
      status: {
        learnerFormAnswers: dataLoadedState(cardWithProgress.progress?.learnerFormAnswers),
        cardStatuses: dataLoadedState(cardWithProgress.progress?.cardStatuses),
        startedOn: dataLoadedState(cardWithProgress.progress?.startedOn),
        updatedOn: dataLoadedState(cardWithProgress.progress?.lastUpdatedOn),
        reviewStatus: dataLoadedState(cardWithProgress.card?.approvalMethod?.type),
      },
    });
  }

  @Action(LearnerViewActions.SaveFormAnswer)
  saveFormAnswer(
    { patchState, getState }: StateContext<LearnerViewStateModel>,
    { playlistUid, formAnswer, formUid }: LearnerViewActions.SaveFormAnswer
  ) {
    return this.dataService.saveFormAnswer(playlistUid, getState().resource.data._id, formAnswer).pipe(
      tap(({ isSuccess, value }) => {
        if (isSuccess) {
          patchState({
            resource: dataLoadedState(
              this.enrichResourceFormContentWithAnswers(getState().resource.data, value.learnerFormAnswers, formUid)
            ),
            status: {
              learnerFormAnswers: dataLoadedState(value.learnerFormAnswers),
              cardStatuses: dataLoadedState(value.cardStatuses),
              startedOn: dataLoadedState(value.startedOn),
              updatedOn: dataLoadedState(value.lastUpdatedOn),
              reviewStatus: dataLoadedState(getState().status?.reviewStatus?.data),
            },
          });
        }
      }));
  }

  @Action(LearnerViewActions.SubmitForm)
  submitForm(
    { getState, patchState }: StateContext<LearnerViewStateModel>,
    { playlistUid, formId }: LearnerViewActions.SubmitForm
  ) {
    return this.dataService.submitForm(playlistUid, getState().resource.data._id, formId).subscribe(({ isSuccess }) => {
      if (isSuccess) {
        const learnerFormAnswers = getState().status.learnerFormAnswers.data;

        if (learnerFormAnswers) {
          patchState({
            status: {
              learnerFormAnswers: dataLoadedState(learnerFormAnswers.map(formAnswer => {
                return formId === formAnswer.formUid ? {
                  ...formAnswer,
                  submitted: true,
                } : formAnswer;
              })),
              cardStatuses: dataLoadedState(getState().status.cardStatuses.data),
              startedOn: dataLoadedState(getState().status.startedOn.data),
              updatedOn: dataLoadedState(getState().status.updatedOn.data),
              reviewStatus: dataLoadedState(getState().status.reviewStatus.data),
            },
          });
        }
      }
    });
  }

  @Action(LearnerViewActions.RefreshStateAfterFormSubmission)
  submitFormNewVersion(
    { getState, patchState }: StateContext<LearnerViewStateModel>,
    { cardWithProgress }: LearnerViewActions.RefreshStateAfterFormSubmission
  ) {
    patchState({
      status: {
        cardStatuses: dataLoadedState(cardWithProgress.progress?.cardStatuses),
        learnerFormAnswers: dataLoadedState(cardWithProgress.progress?.learnerFormAnswers),
        startedOn: dataLoadedState(cardWithProgress.progress?.startedOn),
        updatedOn: dataLoadedState(cardWithProgress.progress?.lastUpdatedOn),
        reviewStatus: dataLoadedState(cardWithProgress.card?.approvalMethod?.type),
      },
    });
  }

  @Action(LearnerViewActions.UpdateLearnerViewDetails)
  updateLearnerViewDetails(
    { patchState }: StateContext<LearnerViewStateModel>,
    { data }: LearnerViewActions.UpdateLearnerViewDetails
  ) {
    patchState({
      resource: dataLoadedState(data),
    });
  }

  @Action(LearnerViewActions.NavigateToRootPage)
  navigateToRootPage() {
    const homePageUri = this.store.selectSnapshot(UserAuthState.homePageUri);
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, homePageUri);
  }

  @Action(LearnerViewActions.EditCurrentResource)
  editCurrentResource(
    { getState }: StateContext<LearnerViewStateModel>,
    { isProjectResource, playlistUri, folioPublicId, groupUri, editSection, page }: LearnerViewActions.EditCurrentResource
  ) {
    const resource = getState().resource.data;
    RedirectHelper.redirectByParams(this.ngZone, this.router, this.activatedRoute, {
      folioPublicId: folioPublicId,
      playlistUri: playlistUri,
      groupUri: groupUri,
      resourceUri: resource.settings.publication.uri,
      formattedUri: resource.formattedUri,
      pageNumberUri: `page/${page}`,
      extraUriParam: editSection ? editSection : 'edit',
    }, isProjectResource ? 'PROJECT_CARD' : this.store.selectSnapshot(LearnerViewState.cardType) as RedirectCardType);
  }

  @Action(LearnerViewActions.LoadCompletionActivities)
  loadCompletionActivities(
    { patchState }: StateContext<LearnerViewStateModel>,
    { playlistUri, resourceUri }: LearnerViewActions.LoadCompletionActivities
  ) {

    patchState({
      completion: loadingState(),
    });
    return this.dataService.loadCompletionActivities(playlistUri, resourceUri).pipe(
      tap(({ isSuccess, value, error }) => {
        if (isSuccess) {
          patchState({
            completion: dataLoadedState(value),
          });
        } else {
          patchState({
            completion: errorState(error),
          });
        }
      }));
  }

  @Action(LearnerViewActions.SetCardCompletionRequired)
  setCardCompletionRequired(
    { getState, patchState }: StateContext<LearnerViewStateModel>,
    { playlistUid, resourceUid, required }: LearnerViewActions.SetCardCompletionRequired
  ) {

    return this.dataService.setCardCompletionRequired(playlistUid, resourceUid, required).pipe(
      tap(({ isSuccess, error }) => {
        if (isSuccess) {
          const completionStateData = getState().completion.data;
          patchState({
            completion: {
              ...getState().completion,
              data: {
                activities: completionStateData.activities,
                cardRequired: required,
                prerequisites: completionStateData.prerequisites
              },
            },
          });
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, required ? 'setCardCompletionRequired' : 'setCardCompletionOptional'
          );
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      }));
  }

  @Action(LearnerViewActions.SetActivityRequired)
  setActivityRequired(
    { getState, patchState }: StateContext<LearnerViewStateModel>,
    { playlistId, resourceUid, activityId, required }: LearnerViewActions.SetActivityRequired
  ) {


    return this.dataService.setActivityRequired(playlistId, resourceUid, activityId, required).pipe(
      tap(({ isSuccess, error }) => {
        if (isSuccess) {
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone,
            this.snackBar,
            this.translationService,
            required ? 'setResourceCompletionRequired' : 'setResourceCompletionOptional'
          );
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      }));
  }

  @Action(LearnerViewActions.UpdateLearnerParticipationStatus)
  updateLearnerParticipationStatus(
    { getState, patchState }: StateContext<LearnerViewStateModel>,
    { event }: LearnerViewActions.UpdateLearnerParticipationStatus
  ) {
    const resource = getState().resource;

    return patchState({
      resource: {
        ...resource,
        data: {
          ...resource.data,
          isRegistered: event.isRegistered,
          registeredOn: event.registeredOn,
          participationStatus: event.participationStatus,
          participationStatusOn: event.participationStatusOn,
          tickets: event.tickets,
          waitingListSpot: event.waitingListSpot
        },
      },
    });
  }

  @Action(LearnerViewActions.ParticipateToEvent)
  participateToEvent(
    { getState, patchState }: StateContext<LearnerViewStateModel>,
    { participated }: LearnerViewActions.ParticipateToEvent
  ) {
    const resource = getState().resource;

    return patchState({
      resource: {
        ...resource,
        data: {
          ...resource.data,
          hasParticipated: participated,
        },
      },
    });
  }

  @Action(LearnerViewActions.SetCurrentViewLanguage) setCurrentViewLanguage(
    { getState, patchState }: StateContext<LearnerViewStateModel>,
    { languageCode }: LearnerViewActions.SetCurrentViewLanguage
  ) {
    return patchState({
      currentLanguage: { ...getState().currentLanguage, data: languageCode },
    });
  }

  @Action(LearnerViewActions.UpdateLearnerViewOpenCard)
  updateLearnerViewOpenCard(
    { getState, patchState }: StateContext<LearnerViewStateModel>,
    { cardOpened }: LearnerViewActions.UpdateLearnerViewOpenCard
  ) {
    return patchState({
      cardOpened: { ...getState().cardOpened, data: cardOpened },
    });
  }

  private loadResource(value: CardWithProgress, patchState: (val: Partial<LearnerViewStateModel>) => LearnerViewStateModel) {
    const { card } = value;
    const resourceWithFormAnswers = value.progress
      ? this.enrichResourceFormContentWithAnswers(
        card,
        value.progress.learnerFormAnswers)
      : card;

    const filteredResourceFormAnswers = this.filterValidSections(resourceWithFormAnswers);

    patchState({
      resource: dataLoadedState(filteredResourceFormAnswers),
      status: {
        learnerFormAnswers: dataLoadedState(value.progress?.learnerFormAnswers),
        cardStatuses: dataLoadedState(value.progress?.cardStatuses),
        startedOn: dataLoadedState(value.progress?.startedOn),
        updatedOn: dataLoadedState(value.progress?.lastUpdatedOn),
        reviewStatus: dataLoadedState(value.card?.approvalMethod?.type),
      },
      cardOpened: dataLoadedState(false),
    });
  }

  private enrichResourceFormContentWithAnswers(resource: Resource, formAnswers: LearnerFormAnswer[], formUid?: string): Resource {
    return {
      ...resource,
      content: {
        ...resource.content,
        sections: resource.content.sections.map(section => ({
          ...section,
          dynamicContent: section.dynamicContent.map(dynamicContent => dynamicContent.type === 'FORM' ? ({
              ...dynamicContent,
              content: {
                ...(dynamicContent as Form).content,
                formIsActive: (dynamicContent.uid === formUid),
                userAnswer: this.findAnswerForForm(dynamicContent.uid, formAnswers),
                submitted: this.findSubmittedForForm(dynamicContent.uid, formAnswers),
                notes: this.findNotesForForm(dynamicContent.uid, dynamicContent.content.notes, formAnswers),
              },
          }) : dynamicContent),
        })),
      },
    };
  }

  private filterValidSections(resource: Resource): Resource {
    return {
      ...resource,
      content: {
        ...resource.content,
        sections: resource.content.sections
          .map(section => ({
            ...section,
            dynamicContent: section.dynamicContent.filter(dynamicContent =>
               this.isValidDynamicContent(dynamicContent)
            )
          }))
      },
    };
  }

  private isValidDynamicContent(dynamicContent: EditorContent): boolean {
    if (dynamicContent.type === 'FORM' && dynamicContent?.content?.type === 'CONNECTED_TEXTBOX') {
      const formData = dynamicContent.content as ConnectedTextBoxFormContent;

      if (!formData.connection?.cardUid ||
        !formData.connection?.playlistUid ||
        !formData.connection?.formUid) {
        return false;
      }
    }

    return true;
  }

  private findAnswerForForm(formUid: string, answers: LearnerFormAnswer[]): string {
    const targetAnswer = this.targetAnswer(answers, formUid);
    return targetAnswer && targetAnswer.answer;
  }

  private findNotesForForm(formUid: string, oldNotes: LearnerFormAnswerNote[], answers: LearnerFormAnswer[]): LearnerFormAnswerNote[] {
    const targetAnswer = this.targetAnswer(answers, formUid);
    if (targetAnswer && targetAnswer.notes) {
      return targetAnswer.notes;
    }
    return oldNotes ? oldNotes : [];
  }

  private findSubmittedForForm(formUid: string, answers: LearnerFormAnswer[]): boolean {
    const targetAnswer = this.targetAnswer(answers, formUid);
    return targetAnswer && targetAnswer.submitted;
  }

  private targetAnswer(answers: LearnerFormAnswer[], formUid: string): LearnerFormAnswer {
    return answers.find(answer => answer.formUid === formUid);
  }

}
