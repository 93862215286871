/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import * as MediumEditor from 'medium-editor';

/**
 * Font size extension for medium-editor
 * Source: https://github.com/yabwe/medium-editor/blob/master/src/js/extensions/fontsize.js
 */
export const FONT_SIZE_FORM = MediumEditor.extensions.form.extend({

  name: 'fontsize',
  action: 'fontSize',
  aria: 'increase/decrease font size',
  contentDefault: '<i class="material-icons" aria-hidden="true">format_size</i>',

  init: function() {
    MediumEditor.extensions.form.prototype.init.apply(this, arguments);
  },

  // Called when the button the toolbar is clicked
  // Overrides ButtonExtension.handleClick
  handleClick: function(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.isDisplayed()) {
      let fontSize = '3';
      const selectedEl = document.getSelection().anchorNode?.parentElement;
      const selectedFont = selectedEl?.closest('font');

      if (selectedFont && selectedFont.hasAttribute('size')) {
        fontSize = selectedFont.getAttribute('size');
      } else {
        if (selectedEl.style.fontSize) {
          fontSize = this.getFormattedFontSize(selectedEl.style.fontSize)
        }
      }

      this.showForm(fontSize);
    }

    return false;
  },

  getFormattedFontSize(size: string) {
    let fontSize = '3';
    switch (size) {
      case 'xx-small':
      case 'x-small':
        fontSize = '1';
        break;
      case 'small':
      case 'smaller':
        fontSize = '2';
        break;
      case 'medium':
        fontSize = '3';
        break;
      case 'large':
        fontSize = '4';
        break;
      case 'x-large':
      case 'larger':
        fontSize = '5';
        break;
      case 'xx-large':
        fontSize = '6';
        break;
      default:
        fontSize = '3';
    }

    return fontSize;
  },

  // Called by medium-editor to append form to the toolbar
  getForm: function() {
    if (!this.form) {
      this.form = this.createForm();
    }
    return this.form;
  },

  // Used by medium-editor when the default toolbar is to be displayed
  isDisplayed: function() {
    return this.getForm().style.display === 'block';
  },

  hideForm: function() {
    this.getForm().style.display = 'none';
    this.getInput().value = '';
  },

  showForm: function(fontSize) {
    const input = this.getInput();

    this.base.saveSelection();
    this.hideToolbarDefaultActions();
    this.getForm().style.display = 'block';
    this.setToolbarPosition();

    input.value = fontSize || '';
  },

  // Called by core when tearing down medium-editor (destroy)
  destroy: function(): boolean | undefined {
    if (!this.form) {
      return false;
    }

    if (this.form.parentNode) {
      this.form.parentNode.removeChild(this.form);
    }

    delete this.form;
    return undefined;
  },

  // core methods

  doFormSave: function() {
    this.base.restoreSelection();
    this.base.checkSelection();
  },

  doFormCancel: function() {
    this.base.restoreSelection();
    this.clearFontSize();
    this.base.checkSelection();
  },

  // form creation and event handling
  createForm: function() {
    const doc = this.document;
    const form = doc.createElement('div');
    const input = doc.createElement('select');
    const close = doc.createElement('a');
    const save = doc.createElement('a');

    // Font Size Form (div)
    form.className = 'medium-editor-toolbar-form';
    form.id = 'medium-editor-toolbar-form-fontsize-' + this.getEditorId();

    // Handle clicks on the form itself
    this.on(form, 'click', this.handleFormClick.bind(this));

    // Add font size dropdown
    for (let i = 1; i <= 6; ++i) {
      const option = doc.createElement('option');
      option.value = i;
      option.textContent = 'Size ' + i;
      input.appendChild(option);
    }

    input.className = 'medium-editor-toolbar-input';
    form.appendChild(input);

    // Handle typing in the textbox
    this.on(input, 'change', this.handleDropdownChange.bind(this));

    // Add save buton
    save.setAttribute('href', '#');
    save.className = 'medium-editor-toobar-save';
    save.innerHTML = '<i class="material-icons" aria-hidden="true">done</i>';
    form.appendChild(save);

    // Handle save button clicks (capture)
    this.on(save, 'click', this.handleSaveClick.bind(this), true);

    // Add close button
    close.setAttribute('href', '#');
    close.className = 'medium-editor-toobar-close';
    close.innerHTML = '<i class="material-icons" aria-hidden="true">clear</i>';
    form.appendChild(close);

    // Handle close button clicks
    this.on(close, 'click', this.handleCloseClick.bind(this));

    return form;
  },

  getInput: function() {
    return this.getForm().querySelector('select.medium-editor-toolbar-input');
  },

  clearFontSize: function() {
    MediumEditor.selection.getSelectedElements(this.document).forEach((el) => {
      if (el.nodeName.toLowerCase() === 'font' && el.hasAttribute('size')) {
        el.removeAttribute('size');
      }
    });
  },

  handleDropdownChange: function() {
    const size = this.getInput().value;
    this.execAction('fontSize', { value: size });
  },

  handleFormClick: (event) => {
    // make sure not to hide form when clicking inside the form
    event.stopPropagation();
  },

  handleSaveClick: function(event) {
    // Clicking Save -> create the font size
    event.preventDefault();
    this.doFormSave();
  },

  handleCloseClick: function(event) {
    // Click Close -> close the form
    event.preventDefault();
    this.doFormCancel();
  },

});
