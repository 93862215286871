/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';

@Component({
  selector: 'ptl-skeleton-item',
  templateUrl: './skeleton-list-item.component.html',
  styleUrls: ['./skeleton-list-item.component.scss'],
})
export class SkeletonListItemComponent {
}
