/*
 * Copyright (C) 2021 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ShareDiscoveryComponent
} from '../share-discovery/share-discovery.component';
import {
  ContentStoreAddDialogComponent
} from '../../content-store-add-dialog/content-store-add-dialog.component';
import { Organization, SearchPlaylist } from '@app/app/shared/models';
import {
  CONTENT_PACKAGE_DATA_SERVICE,
  ContentPackageDataService
} from '@app/app/shared/services/content-package/content-package-data.service';
import { Select } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { CardClickData } from '@app/app/shared/models/page/page.model';
import { debounceTime } from 'rxjs/operators';
import { canTriggerSearch } from '@app/app/shared/helpers/content-helper';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';

@Component({
  selector: 'ptl-discovery-page-head',
  templateUrl: './discovery-page-head.component.html',
  styleUrls: ['./discovery-page-head.component.scss'],
})
export class DiscoveryPageHeadComponent implements OnDestroy {

  @Input() isContentStore: boolean;
  @Output() cardClick = new EventEmitter<CardClickData>();
  @Output() refreshPage = new EventEmitter<void>();

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  searchString: string;
  searchResults: SearchPlaylist[] = [];
  private searchInProgress = false;

  private searchInputSubject$ = new Subject<void>();
  private searchInputSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    @Inject(CONTENT_PACKAGE_DATA_SERVICE) private contentPackageService: ContentPackageDataService
  ) {
    this.searchInputSubscription = this.searchInputSubject$
      .pipe(debounceTime(500)).subscribe(() => this.fireSearch());
  }

  ngOnDestroy(): void {
    this.searchInputSubscription?.unsubscribe();
  }

  shareDiscovery(): void {
    this.dialog.open(ShareDiscoveryComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '75rem',
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
    });
  }

  addToContentStore(): void {
    const dialogRef = this.dialog.open(ContentStoreAddDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '75rem',
      maxHeight: '80vh',
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe(refresh => {
      if ( refresh ) {
        this.refreshPage.emit();
      }

      dialogSubscription.unsubscribe();
    });
  }

  onTextPaste( event: ClipboardEvent ): void {
    if ( event.type === 'paste' ) {
      setTimeout(() => {
        this.searchInputSubject$.next();
      }, 0);
    }
  }

  onSearchInputChange( event: KeyboardEvent ): void {
    if ( canTriggerSearch(event) ) {
      this.searchInputSubject$.next();
    }
  }

  openPlaylistDetails( id: string ): void {
    this.cardClick.emit({ playlistUid: id, _id: id, uri: null, playlistUri: null, formattedUri: '' });
  }

  private fireSearch(): void {
    if ( !this.searchInProgress && this.searchString ) {
      this.searchInProgress = true;

      this.contentPackageService.searchPlaylists(this.searchString).subscribe(( { isSuccess, value } ) => {
        if ( isSuccess ) {
          this.searchResults = value.content;
        }
        this.cd.detectChanges();
        this.searchInProgress = false;
      });
    }
  }

}
