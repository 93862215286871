/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { InjectionToken } from '@angular/core';
import { Page } from '../../../../shared/models/page';
import { ObservableResult } from '../../../../shared/store';
import { Moment, MomentRequest } from '../../models';
import {
  FolioBadges, UploadCertificateRequest
} from '../../store/folio.state.model';
import { EditorContent } from '../../../../shared/models';
import { ItemWithUploadUrl } from '../../../../shared/models/ItemWithUploadUrl';


export const CORE_MOMENT_DATA_SERVICE =
  new InjectionToken<MomentDataService>('[moment] CoreMomentDataService');

/** The service handling moment CRUD operations. */
export interface MomentDataService {

  /**
   * Creates a moment.
   *
   * @param request the details of the moment to create
   */
  createMoment(request: MomentRequest): ObservableResult<Moment>;

  /**
   * Updates a moment
   *
   * @param momentUid the UID of the moment to update
   * @param request the moment details to use when updating
   */
  updateMoment(momentUid: string, request: MomentRequest): ObservableResult<Moment>;

  /**
   * Gets the details for a moment with a given UID.
   *
   * @param momentUid the UID of the moment to retrieve
   */
  getMomentDetails(momentUid: string): ObservableResult<Moment>;

  /**
   * Deletes a moment with a given UID.
   *
   * @param momentUid the UID of the moment to delete
   */
  deleteMoment(momentUid: string): ObservableResult<void>;

  /**
   * Restores a moment with a given UID.
   *
   * @param momentUid the UID of the moment to restore
   */
  restoreMoment(momentUid: string): ObservableResult<void>;

  /**
   * Fetches all moments for the user.
   */
  getMoments(page: number, size: number, languageCode?: string): ObservableResult<Page<Moment>>;

  /**
   * Fetches all badges for the user.
   */
  getBadges(languageCode?: string): ObservableResult<FolioBadges[]>;

  /**
   * Searches all moments for the user.
   */
  searchMoments(momentType: string, orderBy: string, searchKey: string, page: number, size: number): ObservableResult<Page<Moment>>;

  getArchivedMoments(orderBy: string, searchKey: string, page: number, size: number): ObservableResult<Page<Moment>>;

  uploadExternalCertificateFile(request: EditorContent, languageCode?: string):
    ObservableResult<ItemWithUploadUrl<UploadCertificateRequest>>;

  removeCertificate(uid: string): ObservableResult<void>;
}
