/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DialogService } from '../../../../../shared/helpers/dialog/dialog.service';
import { TranslationService } from '../../../../../shared/services/translation/translation.service';
import { Organization, PlaylistTargetUserOrGroupType } from '../../../../../shared/models';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserGroups } from '../../../../../shared/models/admin/group/user-groups.model';
import { UserSearch } from '../../../../../shared/models/admin/members.model';
import { USER_GROUPS_DATA_SERVICE, UserGroupsDataService } from '../../../../admin/services/groups/groups-data.service';
import { MEMBERS_DATA_SERVICE, MembersDataService } from '../../../../admin/services/members/members-data.service';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { PageState } from '../../../store/pages.state';
import { LoadableState } from '../../../../../shared/store';
import { Page, PageTargetItem } from '../../../../../shared/models/page/page.model';
import {
  PageTargetRemoveAllGroups, PageTargetRemoveAllMembers,
  PageTargetRemoveGroup,
  PageTargetRemoveMember, PageTargetSetPrivate,
  PageTargetToGroup,
  PageTargetToMember
} from '../../../store/pages.actions';
import {
  UserSearchAutocompleteComponent
} from '../../../../../shared/components/user-search-autocomplete/user-search-autocomplete.component';
import { MatRadioChange } from '@angular/material/radio';


@Component({
  selector: 'ptl-pages-audience',
  templateUrl: './target-audience.component.html',
  styleUrls: [ './target-audience.component.scss' ],
})
export class PagesTargetAudienceComponent implements OnInit, OnDestroy {

  @Input() isDialog: boolean;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @Select(PageState.page)
  private page$: Observable<LoadableState<Page>>;

  @ViewChild('userSearchAutocomplete') private userSearchAutocomplete: UserSearchAutocompleteComponent

  loadingGroups = false;
  loadingMembers = false;
  selectedOrganizationOnly = false;
  selectedGroupsOnly = false;
  selectedMembersOnly = false;
  selectedOnlyMe = false;
  searchedGroups: UserGroups[];
  searchedMembers: UserSearch[];
  haveUserGroups: boolean;
  groups: PageTargetItem[] = [];
  members: PageTargetItem[] = [];

  private userSearchPage = 0;
  private userSearchPageSize = 20;
  private searchString: string;
  private searchStringSubject$ = new Subject<SearchType>();
  private subscriptionEnd$ = new EventEmitter<void>();
  private searchFilterSubscription: Subscription;

  constructor(
    private store: Store,
    @Inject(USER_GROUPS_DATA_SERVICE) private userGroupsService: UserGroupsDataService,
    @Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService,
    private dialogService: DialogService,
    private translationService: TranslationService
  ) {
  }

  ngOnInit() {

    this.page$.pipe(
      map(value => value.data),
      filter(data => !!data),
      takeUntil(this.subscriptionEnd$)
    ).subscribe(page => {
      this.groups = page.target.groups;
      this.members = page.target.users;


      if (!this.groups.length && !this.members.length) {
        this.selectedOrganizationOnly = true;
        return;
      } else {
        this.selectedOrganizationOnly = false;
      }

      if (this.groups.length) {
        this.selectedGroupsOnly = true;
        return;
      } else {
        this.selectedGroupsOnly = false;
      }

      if (this.members.length === 1) {
        const userId = this.members[0]._id;
        if (userId === page.creator.adminId) {
          this.selectedMembersOnly = false;
          this.selectedOnlyMe = true;
        } else {
          this.selectedMembersOnly = true;
          this.selectedOnlyMe = false;
        }
        if (this.groups.length) {
          this.selectedOnlyMe = false;
        }
      } else {
        if (this.members.length) {
          this.selectedMembersOnly = true;
        } else {
          this.selectedMembersOnly = false;
        }
      }
    });

    this.userGroupsService.searchUserGroups(0, 1).subscribe(({ isSuccess, value }) => {
      if ( isSuccess ) {
        this.haveUserGroups = !!value.content.length;
      }
    });

    this.searchFilterSubscription = this.searchStringSubject$
      .pipe(debounceTime(500))
      .subscribe((type: SearchType) => this.fireSearch(type, true));

  }

  ngOnDestroy() {
    this.searchFilterSubscription?.unsubscribe();
    this.subscriptionEnd$.emit();
  }

  onToggleChangeOrganizationVisibility(event: MatRadioChange) {
    if ( !this.selectedOrganizationOnly ) {
      this.dialogService.showConfirmDialog(
        this.translationService.getTranslation('dialog.title.targetPageToAllMembers'),
        this.translationService
      ).then(confirmed => {
        if (confirmed) {
          this.store.dispatch(new PageTargetRemoveAllMembers()).toPromise().then(() => {
            this.store.dispatch(new PageTargetRemoveAllGroups());
          });
          this.enableOrganizationVisibility(event)
        }
      });
    }
  }

  onToggleChangeOnlyMeVisibility(event: MatRadioChange) {
    if (!this.selectedOnlyMe) {
      this.dialogService.showConfirmDialog(
        this.translationService.getTranslation('dialog.title.targetPageToOnlyMe'),
        this.translationService
      ).then(confirmed => {
        if (confirmed) {
          this.store.dispatch(new PageTargetSetPrivate());
          this.enableOnlyMeVisibility(event)
        }
      });
    }
  }

  onToggleChangeMembersVisibility(event: MatRadioChange) {
    event.value = true;

    this.enableMembersVisibility(event);
  }

  onToggleChangeGroupsVisibility(event: MatRadioChange) {
    event.value = true;

    this.enableGroupsVisibility(event);
  }

  private enableOrganizationVisibility(event: MatRadioChange) {
    this.selectedOrganizationOnly = true;
    this.selectedGroupsOnly = false;
    this.selectedMembersOnly = false;
    this.selectedOnlyMe = false;
    event.value = true;
  }

  private enableGroupsVisibility(event: MatRadioChange) {
    this.selectedGroupsOnly = true;
    this.selectedOrganizationOnly = false;
    this.selectedMembersOnly = false;
    this.selectedOnlyMe = false;
    event.value = true;
  }

  private enableMembersVisibility(event: MatRadioChange) {
    this.selectedMembersOnly = true;
    this.selectedOrganizationOnly = false;
    this.selectedGroupsOnly = false;
    this.selectedOnlyMe = false;
    event.value = true;
  }

  private enableOnlyMeVisibility(event: MatRadioChange) {
    this.selectedOnlyMe = true;
    this.selectedOrganizationOnly = false;
    this.selectedGroupsOnly = false;
    this.selectedMembersOnly = false;
    event.value = true;
  }

  onGroupSelected(data: UserGroups) {
    this.store.dispatch(new PageTargetToGroup({ _id: data._id, name: data.title }));
  }

  onMemberSelected(data: UserSearch) {
    this.store.dispatch(new PageTargetToMember({ _id: data.uid, name: data.firstName + ' ' + data.lastName }));
  }

  autocompleteDisplayFn() {
    return '';
  }

  onTextPaste(event: ClipboardEvent) {
    if ( event.type === 'paste' ) {
      setTimeout(() => {
        this.searchString = (event.target as HTMLInputElement).value;
        this.loadingGroups = true;

        this.searchStringSubject$.next('GROUP');
      }, 0);
    }
  }

  onGroupSearchInputChange(text: string) {
    this.searchString = text;
    this.loadingGroups = true;

    this.searchStringSubject$.next('GROUP');
  }

  onUserSearchInputChange(searchString: string) {
    this.searchString = searchString;
    this.loadingMembers = true;
    this.searchStringSubject$.next('MEMBER');
  }

  deleteGroup(groupUid: string) {
    this.store.dispatch(new PageTargetRemoveGroup(groupUid));
  }

  deleteMember(userUid: string) {
    this.store.dispatch(new PageTargetRemoveMember(userUid));
  }

  onSearchLoadingMore(type: SearchType) {
    this.fireSearch(type, false);
  }

  private fireSearch(type: SearchType, override: boolean) {
    if ( type === 'GROUP' ) {
      this.loadingGroups = true;
      this.loadGroupsListBySearch();
    } else if ( type === 'MEMBER' ) {
      this.loadingMembers = true;
      this.loadMembersListBySearch(override);
    }
  }

  private loadGroupsListBySearch() {
    if ( !this.searchString ) {
      this.loadingGroups = false;
      return;
    }
    this.userGroupsService.searchUserGroups(0, 10, this.searchString)
      .subscribe(({ isSuccess, value }) => {
        if ( isSuccess ) {
          this.searchedGroups = value.content;
        }
        this.loadingGroups = false;
      });
  }

  private loadMembersListBySearch(override: boolean) {
    this.userSearchPage = override ? 0 : this.userSearchPage + 1;
    if ( !this.searchString ) {
      this.loadingMembers = false;
      return;
    }
    this.membersDataService.searchUsers(this.userSearchPage, this.userSearchPageSize, this.searchString)
      .subscribe(({ isSuccess, value }) => {
        if ( isSuccess ) {
          if ( override ) {
            this.searchedMembers = value.content;
          } else {
            this.searchedMembers = this.searchedMembers.concat(value.content);
          }
        }
        this.userSearchAutocomplete.canLoadMore = value.totalNumberOfElement > this.members.length;
        this.userSearchAutocomplete.isLoadingMore = false;
        this.loadingMembers = false;
      });
  }
}


type SearchType = 'GROUP' | 'MEMBER';
