/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Form, TextBoxFormContent } from '../../../../models';
import { VersionHelper } from '../../../../../shared/helpers/version.helper';
import { TEXT_FORM_MAX_WORDS_LIMIT, TEXT_FORM_MIN_WORDS_LIMIT } from '../../../../../shared/constants/constants';
import { Select } from '@ngxs/store';
import { ResourceAdminState } from '../../../../../page-modules/resource/store/admin/resource-admin.state';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { withLatestFrom } from 'rxjs/operators';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { Organization } from '../../../../../shared/models';
import { AI_ASSISTANT_SERVICE, AIAssistantSettings } from '../../../../services/assistant/ai-assistant.service';
import { ApiAiAssistantDataService } from '../../../../services/assistant/ai-assistant-api-data.service';
import { FormAIAssistantSettingsComponent } from './form-ai-assistant-settings/form-ai-assistant-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguageCodeHelper } from '../../../../../shared/helpers/language-code-helper';
import { FormAiSearchDialogComponent } from './form-ai-search-dialog/form-ai-search-dialog.component';

@Component({
  selector: 'ptl-form-textbox',
  templateUrl: './form-textbox.component.html',
  styleUrls: ['../form-new.component.scss', '../form.component.scss', './form-textbox.component.scss']
})
export class FormTextboxComponent implements OnInit, OnChanges, OnDestroy {

  private _form: Form;
  private _canRemoveItem: boolean | undefined;
  private _position: number | string | undefined;

  @Input()
  set form(value: Form) {
    if (typeof value === 'string') {
      this._form = JSON.parse(decodeURIComponent(value));
    } else {
      this._form = value;
    }
  }

  get form() {
    return this._form;
  }

  @Input()
  set position(value: number | string | undefined) {
    if (typeof value === 'string') {
      this._position = JSON.parse(decodeURIComponent(value));
    } else {
      this._position = value;
    }
  }

  get position(): string | number {
    return this._position;
  }

  @Input()
  set canRemoveItem(value) {
    if (typeof value === 'string') {
      this._canRemoveItem = JSON.parse(decodeURIComponent(value));
    } else {
      this._canRemoveItem = value;
    }
  }

  get canRemoveItem(): boolean {
    return this._canRemoveItem;
  }

  @Output() formElementAdded = new EventEmitter<Form>();

  @Output() formElementRemoved = new EventEmitter<void>();

  @ViewChild('textBoxFormElement', { static: false }) private textBoxFormElement: ElementRef;
  @ViewChild('formAiAssistantSettings', { static: false }) private formAiAssistantSettings: FormAIAssistantSettingsComponent;

  @Select(ResourceAdminState.contentStateChanged)
  contentStateChanged$: Observable<boolean>;

  @Select(ResourceAdminState.resourceUid)
  resourceUid$: Observable<string>;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(UserAuthState.aiAssistantFeatureFlag)
  private aiAssistantFeatureFlag$: Observable<boolean>;

  textboxForm: FormGroup;
  expanded = true;
  focused = false;
  hasError = false;
  saveInProgress = false;
  newVersionEnabled = VersionHelper.newVersionEnabled();
  aiAssistantEnabled = false;
  isTemplateSettingsEnabled = false;
  aiAssistantFeatureEnabled = false;
  aiAssistantSettingsUid: string;
  aiAssistantData: AIAssistantSettings;
  isTemplate: boolean;
  saveButtonPressed: boolean;
  updatedAssistantSettings: AIAssistantSettings;
  isDialogOpen = false;
  cardUid: string;

  private subscriptionEnd$ = new EventEmitter<void>();
  private resourceSubscriptionEnd$ = new EventEmitter<void>();
  private aiFeatureFlagSubscription: Subscription;
  private assistantUpdateSubscription: Subscription;
  private assistantCreateSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    @Inject(AI_ASSISTANT_SERVICE) private aiAssistantService: ApiAiAssistantDataService) {
    this.textboxForm = this.fb.group({
      title: ['', [Validators.required]],
      wordLimit: [TEXT_FORM_MAX_WORDS_LIMIT, [Validators.required, Validators.min(1)]],
      minWordsLimit: [TEXT_FORM_MIN_WORDS_LIMIT, [Validators.required, Validators.min(1)]],
      answerType: ['RICH', [Validators.required]],
      aiAssistantEnabled: [false],
      aiAssistantSettingsUid: [null]
    }, { validator: this.wordLimitRangeValidator() });
    this.aiFeatureFlagSubscription = this.aiAssistantFeatureFlag$.pipe(
      withLatestFrom(this.organizationData$)
    ).subscribe(([flagEnabled]) => {
      this.aiAssistantFeatureEnabled = flagEnabled;
    });
  }

  ngOnChanges(): void {
    if (this.form && !!this.form.uid) {
      this.setFormContent(this.form.content as TextBoxFormContent, this.form.newAddedForm);
    }
  }

  ngOnInit(): void {
    const content = this.form ? this.form.content as TextBoxFormContent : null;
    this.isTemplateSettingsEnabled = !!content?.aiAssistantSettingsUid;

    if (this.form && (!!this.form.uid || !!content?.title)) {
      this.setFormContent(content, this.form.newAddedForm);
    }

    this.contentStateChanged$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(() => {
      this.saveInProgress = false;
    });

    this.resourceUid$.pipe(
      takeUntil(this.resourceSubscriptionEnd$)
    ).subscribe((resourceUid) => {
      if (resourceUid) {
        this.cardUid = resourceUid;
        if (content?.aiAssistantSettingsUid && this.cardUid) {
          this.getAiAssistantData(this.cardUid, content.aiAssistantSettingsUid);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionEnd$?.emit();
    this.resourceSubscriptionEnd$?.emit();
    this.aiFeatureFlagSubscription?.unsubscribe();
    this.assistantCreateSubscription?.unsubscribe();
    this.assistantUpdateSubscription?.unsubscribe();
  }

  saveForm(): void {
    this.saveButtonPressed = true;
    this.hasError = false;

    if (!this.cardUid || !this.aiAssistantEnabled) {
      this.proceedFormSave();
      return;
    }

    const aiAssistantModel = this.formAiAssistantSettings?.getValidAssistantSettings() || this.updatedAssistantSettings;

    if (!aiAssistantModel) {
      return;
    }

    if (this.aiAssistantSettingsUid) {
      this.assistantUpdateSubscription = this.aiAssistantService
        .updateAssistantSettings(this.cardUid, this.aiAssistantSettingsUid, aiAssistantModel)
        .subscribe(({ isSuccess, value }) => {
          if (isSuccess && value) {
            this.updatedAssistantSettings = value;
          }
          this.proceedFormSave();
        });
      return;
    }

    this.assistantCreateSubscription = this.aiAssistantService
      .createAssistantSettings(this.cardUid,
        aiAssistantModel)
      .subscribe(({ isSuccess, value }) => {
        if (value) {
          const uid = value._id;
          this.updatedAssistantSettings = value;
          this.onAssistantSettingsCreated(uid);
          this.aiAssistantSettingsUid = uid;
        }
        this.proceedFormSave();
      });
  }

  expandForm(): void {
    this.expanded = true;
  }

  collapseForm(event: PointerEvent): void {
    event.stopPropagation();
    this.expanded = false;
  }

  removeForm(): void {
    this.formElementRemoved.emit();

    if (!this.isTemplate && this.aiAssistantSettingsUid) {
      this.aiAssistantService.deleteAssistantSettings(this.cardUid, this.aiAssistantSettingsUid).subscribe();
    }
  }

  openAiSettings(): void {
    this.isTemplateSettingsEnabled = true;
    this.aiAssistantData = null;
  }

  onAiAssistantToggle(event: MatSlideToggleChange): void {
    this.aiAssistantEnabled = event.checked;
    this.textboxForm.patchValue({
      aiAssistantEnabled: this.aiAssistantEnabled
    });
  }

  onAssistantSettingsCreated(assistantId: string): void {
    this.textboxForm.patchValue({
      aiAssistantSettingsUid: assistantId
    });
  }

  formChanged(event: null | string): void {
    this.aiAssistantSettingsUid = event;
  }

  saveAsTemplate(event: boolean): void {
    this.isTemplate = event;
  }

  openAiTemplatesDialog(): void {
    this.isDialogOpen = true;
    this.isTemplateSettingsEnabled = false;
    if (this.dialog) {
      this.dialog.closeAll();
    }

    let dialogRef = this.dialog.open(FormAiSearchDialogComponent, {
      width: '90vw',
      maxWidth: '60rem',
      maxHeight: '80vh',
      position: {
        top: '10vh',
        bottom: '10vh'
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: ['page-publish-dialog', 'ptl-mat-dialog', 'ptl-mat-dialog-without-padding'],
      backdropClass: 'dialog-backdrop',
      autoFocus: false,
      data: {
        cardUid: this.cardUid
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe((dialogData) => {
        this.updatedAssistantSettings = null;
        if (dialogData?.assistantSettingsUid) {
          this.aiAssistantSettingsUid = dialogData.assistantSettingsUid;
          this.getAiAssistantData(this.cardUid, this.aiAssistantSettingsUid);
        }
        this.isDialogOpen = false;
        this.isTemplateSettingsEnabled = true;
        dialogRef = null;
      });
  }

  wordLimitRangeValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const minWordsLimit = formGroup.get('minWordsLimit')?.value;
      const maxWordsLimit = formGroup.get('wordLimit')?.value;
      if (minWordsLimit < 1 || maxWordsLimit < 1) {
        return { minLimitError: true };
      }
      if (maxWordsLimit < minWordsLimit) {
        return { rangeError: true };
      }
      return null;
    };
  }

  private getAiAssistantData(cardUid: string, aiAssistantSettingsUid: string): void {
    this.aiAssistantService.getAssistantSettingsById(cardUid, aiAssistantSettingsUid)
      .subscribe(({ isSuccess, value }) => {
        if (!value) {
          return;
        }
        this.aiAssistantData = value;
        this.aiAssistantSettingsUid = value?._id;
        this.isTemplate = value?.isTemplate || false;
        this.textboxForm.patchValue({
          aiAssistantSettingsUid: value._id
        });
      });
  }

  private setFormContent(content: TextBoxFormContent, newAddedForm: boolean): void {
    this.expanded = newAddedForm ?? false;
    this.aiAssistantEnabled = content.aiAssistantEnabled ? content.aiAssistantEnabled : false;
    this.aiAssistantSettingsUid = content.aiAssistantSettingsUid ? content.aiAssistantSettingsUid : null;
    this.textboxForm.setValue({
      title: content.title,
      wordLimit: content.wordLimit,
      minWordsLimit: content.minWordsLimit ?? TEXT_FORM_MIN_WORDS_LIMIT,
      aiAssistantEnabled: this.aiAssistantEnabled,
      aiAssistantSettingsUid: content.aiAssistantSettingsUid ? content.aiAssistantSettingsUid : null,
      answerType: 'RICH'
    });
  }

  private proceedFormSave(): void {
    if (this.textboxForm.valid) {
      this.expanded = false;
      this.outputData();
      this.saveInProgress = true;
    } else {
      this.hasError = true;
    }
  }

  private outputData(): void {
    const outputData: Form = {
      ...this.form,
      newAddedForm: false,
      content: {
        ...this.textboxForm.value,
        type: 'TEXTBOX'
      } as TextBoxFormContent
    };

    this.formElementAdded.emit(outputData);
  }
}

