/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { OnBoardingDataRequest, UserAccountCreationRequest, UserDetailsSummary } from '../models';
import { Publisher } from '../../shared/models/content-store/content-store.model';
import { OrganizationTheme } from '../../shared/models';

// -------------------------------------------------------------------------
export class TriggerUserLogin {
  static readonly type = '[User Auth] User Basic Login';

  constructor(public email: string, public password: string) {
  }
}

export class TriggerFolioUserLogin {
  static readonly type = '[User Auth] User Folio User Login';

  constructor(public email: string, public password: string, public targetOrganization: string) {
  }
}

export class UserLoginFailed {
  static readonly type = '[User Auth] User Basic Login Error';

  constructor(public error: string) {
  }
}

// -------------------------------------------------------------------------
export class UpdateUserPersonalDetails {
  static readonly type = '[User Auth] Update user details';

  constructor(public userPersonalData: UserDetailsSummary) {
  }
}

export class FetchUserDetails {
  static readonly type = '[User Auth] Fetch user details';
}

// -------------------------------------------------------------------------
export class UpdateUserJiscUsage {
  static readonly type = '[User Auth] Update user jisc usage';

  constructor(public hasUsedCpd: boolean) {
  }
}


// -------------------------------------------------------------------------
export class TriggerUserAccountCreation {
  static readonly type = '[User Auth] User Account Creation';

  constructor(public accountCreationRequest: UserAccountCreationRequest, public invitationId?: string, public token?: string) {
  }
}

export class UserAccountCreationSuccess {
  static readonly type = '[User Auth] User account creation success';

  constructor(public emailVerificationIsNeeded: boolean) {
  }
}

export class UserAccountCreationError {
  static readonly type = '[User Auth] User account creation error';

  constructor(public error: string) {
  }
}

// -------------------------------------------------------------------------
export class TriggerUserAccountVerification {
  static readonly type = '[User Auth] Trigger account verification';

  constructor(public token: string) {
  }
}

export class UserAccountVerificationSuccess {
  static readonly type = '[User Auth] User account verification success';
}

export class TriggerUserAccountVerificationError {
  static readonly type = '[User Auth] User account verification error';

  constructor(public error: string) {
  }
}

// -------------------------------------------------------------------------
export class RedirectToAuthenticatedApp {
  static readonly type = '[User Auth] Redirect to authenticated app';
}

export class RedirectToAuthenticatedAppSuccess {
  static readonly type = '[User Auth] Redirect to authenticated app succeeded';
}

export class RedirectToAuthenticatedAppFailure {
  static readonly type = '[User Auth] Redirect to authenticated app failure';
}

// -------------------------------------------------------------------------
export class UnauthenticateUser {
  static readonly type = '[User Auth] Unauthenticate';

  constructor(public redirectUri?: string) {
  }
}

// -------------------------------------------------------------------------
export class LoadOrganizationPublisher {
  static readonly type = '[User Auth] Load current org publisher';
}


// -------------------------------------------------------------------------
export class UpdateOrganizationPublisher {
  static readonly type = '[User Auth] Update current org publisher';

  constructor(public publisher: Publisher) {
  }
}


// -------------------------------------------------------------------------
export class LoadOrganizationDetails {
  static readonly type = '[User Auth] Load org details';
}

// -------------------------------------------------------------------------
export class CheckOrganizationFeature {
  static readonly type = '[User Auth] Check organization feature';

  constructor(public feature: string) {
  }
}

// -------------------------------------------------------------------------
export class LoadOrganizationFeatures {
  static readonly type = '[User Auth] Load organization features';

  constructor(public orgUid: string) {
  }
}


// -------------------------------------------------------------------------
export class AddDomainToOrganization {
  static readonly type = '[Organization] Add domain to whitelist/blacklist';

  constructor(public domain: string, public direction: string) {
  }
}

// -------------------------------------------------------------------------
export class RemoveDomainFromOrganization {
  static readonly type = '[Organization] Remove domain from whitelist/blacklist';

  constructor(public domain: string, public direction: string) {
  }
}

// -------------------------------------------------------------------------
export class RemoveAllDomainsFromOrganization {
  static readonly type = '[Organization] Remove all domains from whitelist/blacklist';

  constructor(public direction: string) {
  }
}

export class UpdateOrganizationStyles {
  static readonly type = '[Organization] Update styles';

  constructor(public pointClicks: OrganizationTheme) {
  }
}

// -------------------------------------------------------------------------
export class SendOnBoardingData {
  static readonly type = '[User Auth] Send on board data';

  constructor(public data: OnBoardingDataRequest) {
  }
}

export class GetHomePageTitleAndUri {
  static readonly type = '[User Auth] Get home page title and uri';
}

export class UpdateHomePageTitle {
  static readonly type = '[User Auth] Update home page title';

  constructor(public title: string) {
  }
}

export class FetchProfileImageDataUrl {
  static readonly type = '[User Auth] Fetch profile image data URL';
}

export class InitHelpHero {
  static readonly type = '[User Auth] Initiate HelpHero';
}

export class EditorJsFeatureFlag {
  static readonly type = '[User Auth] Check Editor-Js feature flag';
}

// -------------------------------------------------------------------------
export class LmsSkillsFeatureFlag {
  static readonly type = '[User Auth] Check Lms skills feature flag';
}

export class EmailNotificationsFeatureFlag {
  static readonly type = '[User Auth] Check email notifications feature flag';
}
