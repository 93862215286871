<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-sidenav-container class="main main-view-sidenav-container"
                       [class.is_edit-mode]="(showSidebarTree$ | async) !== true"
                       [class.workspace-hidden]="(showSidebarWorkspaceNav$ | async) !== true"
                       [hasBackdrop]="(isMobile$ | async)">

  <mat-sidenav class="main__side-nav"
               (closedStart)="closeSidebarMenu()"
               [mode]="(isMobile$ | async) ? 'over' : 'side'"
               [disableClose]="true"
               [fixedInViewport]="true"
               [style.top.rem]="(isMobile$ | async) ? 0 : (48 / 16)"
               [opened]="(mobileMenuExpanded$ | async)">

    <ptl-workspaces-nav *ngIf="(showSidebarWorkspaceNav$ | async) === true"></ptl-workspaces-nav>

    <div class="main__side-nav-list" *ngIf="(showSidebarTree$ | async) === true">
      <ptl-side-nav-tree [stackView]="stackView" [sections]="sections" (sidebarItemClicked)="onSideBarItemClicked($event)"></ptl-side-nav-tree>
    </div>

  </mat-sidenav>

  <mat-sidenav-content class="main__content" id="mainContent" tabindex="-1">
    <ng-content></ng-content>
  </mat-sidenav-content>

</mat-sidenav-container>

