import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '../../../../../../shared/services/translation/translation.service';
import { EVENT_CARDS_DATA_SERVICE, EventCardsDataService } from 'src/app/page-modules/event-cards/services/data.service';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from 'src/app/shared/services/toast-notifications/toast-service';
import { MEMBERS_DATA_SERVICE, MembersDataService } from 'src/app/page-modules/admin/services/members/members-data.service';
import { UserSearch } from 'src/app/shared/models/admin/members.model';
import { Store } from '@ngxs/store';
import * as ResourceAdminActions from '../../../../../resource/store/admin/resource-admin.actions';
import { DialogService } from 'src/app/shared/helpers/dialog/dialog.service';
import { IMPORT_USERS_SERVICE, ImportUsersService } from 'src/app/page-modules/admin/services/import-members/import-users.service';

@Component({
  templateUrl: './import-users-dialog.component.html',
  styleUrls: ['./import-users-dialog.component.scss'],
})
export class ImportUsersDialogComponent {

  @Input() cardUid: string;
  @Input() playlistUid: string;
  @Input() eventFinished: boolean;
  @Input() isEvent: boolean;
  @Input() isCardPage: boolean;
  @Input() isPlaylistPage: boolean;

  importInProgress = false;
  confirmationPageShown = false;
  matchedUsers: string[] = [];
  invalidEmails: string[] = [];
  validUsers: UserSearch[] = [];

  // pagination
  pageSize = 10;

  validUsersVisible = [];
  validUsersPageNumber = 1;
  validUsersPaginationLength = 0;

  invalidEmailsVisible = [];
  invalidEmailsPageNumber = 1;
  invalidEmailsPaginationLength = 0;

  @Output() csvFileUploadFinished = new EventEmitter<number>();

  constructor(
    private store: Store,
    private translationService: TranslationService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<ImportUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      cardUid: string;
      playlistUid: string;
      eventFinished: boolean;
      isEvent: boolean;
      isCardPage: boolean;
      isPlaylistPage: boolean;
    },
    @Inject(MEMBERS_DATA_SERVICE) private membersDataService: MembersDataService,
    @Inject(IMPORT_USERS_SERVICE) private importUsersService: ImportUsersService,
    @Inject(EVENT_CARDS_DATA_SERVICE) private eventCardsDataService: EventCardsDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService
  ) {
    if (this.data) {
      this.cardUid = this.data.cardUid;
      this.playlistUid = this.data.playlistUid;
      this.eventFinished = this.data.eventFinished;
      this.isEvent = this.data.isEvent;
      this.isCardPage = this.data.isCardPage;
      this.isPlaylistPage = this.data.isPlaylistPage;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onFileChanged(eventData: Event): void {
    if (eventData.target as HTMLInputElement) {
      const element = eventData.target as HTMLInputElement;

      if ((element.files) && (typeof element.files[0] !== 'undefined')) {
        const file = element.files[0];
        this.onCsvUpload(file);
      }
    }
  }

  onCsvUpload(file: File ): void {
    this.importInProgress = true;

    this.membersDataService.validateUsersFromCsvFile(file, this.playlistUid).subscribe(({ isSuccess, value, error }) => {
      if (isSuccess) {
        this.validUsers = value.validUsers;
        this.invalidEmails = value.invalidEmails;
        this.refreshPagination();
        this.confirmationPageShown = true;
      } else {
        this.toastService.showFail(error);
      }
      this.confirmationPageShown = true;
      this.importInProgress = false;
    });
  }

  participateValidUsersFromCsvFile() {
    if (!this.eventFinished) {
      this.dialogService.showConfirmDialog(
        this.translationService.getTranslation('dialog.title.eventNotFinished'),
        this.translationService
      ).then(confirmed => {
        if (confirmed) {
          this.participateInEvent();
        }
      });
    } else {
      this.participateInEvent();
    }
  }

  importValidUsersFromCsvFile() {
    if (this.isPlaylistPage) {
      this.importUsersService.initializeLearnerPlaylistForUsers(
        this.playlistUid,
        this.validUsers.map(it => it.uid)
      ).subscribe(({ isSuccess, error }) => {
        if (isSuccess) {
          this.csvFileUploadFinished.emit(this.validUsers.length);
          this.closeDialog();
          this.toastService.showSuccess(this.translationService.getTranslation('successes.successImportUsers'));
        } else {
          this.closeDialog();
          this.toastService.showFail(error);
        }
        this.confirmationPageShown = false;
        this.importInProgress = false;
      });
    } else {
      this.importUsersService.initializeLearnerCardForUsers(
        this.playlistUid,
        this.cardUid,
        this.validUsers.map(it => it.uid)
      ).subscribe(({ isSuccess, error }) => {
        if (isSuccess) {
          this.csvFileUploadFinished.emit(this.validUsers.length);
          this.closeDialog();
          this.toastService.showSuccess(this.translationService.getTranslation('successes.successImportUsers'));
        } else {
          this.closeDialog();
          this.toastService.showFail(error);
        }
        this.confirmationPageShown = false;
        this.importInProgress = false;
      });
    }
  }

  private participateInEvent() {
    this.eventCardsDataService.adminAttendToEventBulk(
      this.cardUid,
      {
        usersUid: this.validUsers.map(it => it.uid),
        playlistUid: this.playlistUid,
        checkInMethod: 'IMPORT'
      }
    ).subscribe(({ isSuccess, value, error }) => {
      if (isSuccess) {
        this.csvFileUploadFinished.emit(this.validUsers.length);
        this.store.dispatch(new ResourceAdminActions.SetEventTickets(value));
        this.closeDialog();
        this.toastService.showSuccess(this.translationService.getTranslation('successes.successImportUsersFromCsvFile'));
      } else {
        this.closeDialog();
        this.toastService.showFail(error);
      }
      this.confirmationPageShown = false;
      this.importInProgress = false;
    });
  }

  downloadUnmatchedEmails() {
    if (this.invalidEmails?.length > 0) {
      const exportName = 'unmatched_emails';
      const dateFormat = this.getExportedDateFormat();
      const exportedFileName = exportName + '_' + dateFormat + '.csv';
      const csvContent = 'data:application/octet-stream;base64,' +
        btoa(unescape(encodeURIComponent(this.getCsvFileUnmatchedEmailsContent())));
      const link = document.createElement('a');
      link.id = 'csvButton';
      link.setAttribute('href', csvContent);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', exportedFileName);
      document.body.appendChild(link); // Required for FF
      link.click();
      link.parentNode.removeChild(link);
    }
  }

  onValidUsersPageChange(page: number) {
    this.validUsersPageNumber = page;
    this.refreshPagination();
  }

  onInvalidEmailsPageChange(page: number) {
    this.invalidEmailsPageNumber = page;
    this.refreshPagination();
  }

  private refreshPagination() {
    this.validUsersPaginationLength = this.validUsers?.length / this.pageSize;
    this.validUsersVisible = this.validUsers.slice(
      (this.validUsersPageNumber - 1) * this.pageSize,
      this.validUsersPageNumber * this.pageSize
    );

    this.invalidEmailsPaginationLength = this.invalidEmails?.length / this.pageSize;
    this.invalidEmailsVisible = this.invalidEmails.slice(
      (this.invalidEmailsPageNumber - 1) * this.pageSize,
      this.invalidEmailsPageNumber * this.pageSize
    );
  }

  private getExportedDateFormat(): string {
    const date = new Date();
    return date.getFullYear() + '_' + (date.getMonth() + 1) + '_' + date.getDate() + '_' + date.getHours() + '_' +
      date.getMinutes();
  }

  private getCsvFileUnmatchedEmailsContent(): string {
    return this.invalidEmails.join('\r\n');
  }
}
