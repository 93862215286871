/*
 * Copyright (C) 2019 - present by Potentially
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../../../shared/store';
import { PublicationDataService } from './publication-data.service';

export class MockPublicationDataService implements PublicationDataService {

  publishPlaylist(playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  unpublishPlaylist(playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

}
