/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '@app/app/shared/store';
import { InjectionToken } from '@angular/core';
import { UnsplashPhotoList, UsableUnsplashPhoto } from '@app/app/shared/models/images/unsplash-images.model';


/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const UNSPLASH_IMAGES_DATA_SERVICE = new InjectionToken<UnsplashImagesDataService>('[Unsplash Images] DataService');

/** The admin data service. */
export interface UnsplashImagesDataService {

  search(phrase: string, page: number): ObservableResult<UnsplashPhotoList>;
  download(url: string): ObservableResult<UsableUnsplashPhoto>;
}
