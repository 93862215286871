<div class="table skeleton-table-container">

  <div class="table-cell skeleton-table-cell--first">
    <span class="skeleton-table-text slide-row"></span>
  </div>

  <div class="table-cell skeleton-table-cell--second">
    <span class="skeleton-table-text slide-row"></span>
  </div>

  <div class="table-cell skeleton-table-cell--third">
    <span class="skeleton-table-text slide-row"></span>
  </div>

  <div class="table-cell skeleton-table-cell--forth">
    <span class="skeleton-table-text slide-row"></span>
  </div>

</div>
