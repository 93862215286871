/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AUTH_SERVICE, BasicAuthService } from '../user-auth/services/basic-auth.service';
import { Observable, of } from 'rxjs';
import { withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../user-auth/store/user-auth.state';
import { UserDetails } from '../user-auth/models';

@Injectable()
export class AuthGuard implements CanActivate {

  @Select(UserAuthState.userDetailsData) userDetailsData$: Observable<UserDetails>;
  @Select(UserAuthState.userDetailsError) userDetailsError$: Observable<string>;

  constructor(
    @Inject(AUTH_SERVICE) private authService: BasicAuthService
  ) { }

  /**
   * Checks if the current user is authenticated.
   * If not, the user will not be allowed to access the guarded resource.
   *
   * @param route the route navigated to
   * @param state the current route state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userDetailsData$.pipe(
      filter(userDetails => !!userDetails),
      withLatestFrom(this.userDetailsError$),
      filter(([userData, userError]) => !!userData || !!userError),
      switchMap(([userData, userError]) => {
        // If there was an error with user authentication, log user out
        if (!!userError || !userData) {
          this.authService.unauthenticate();
          return of(false);
        }

        return of(true);
      }));
  }

}
