<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="!newVersionEnabled">
<div class="quiz-instructions">
  <div
    tabindex="0"
    class="quiz-instructions__header"
    [class.quiz-instructions__header--expanded]="expanded"
    (click)="expanded = !expanded"
    [attr.aria-label]="('translations.global.ariaLabels.expandQuizInstructions' | transloco)"
    (keydown.enter)="expanded = !expanded">
    <span class="bold-text">{{ 'translations.quiz.instructions.title' | transloco }}</span>
    <span *ngIf="!expanded && !content.content" class="light-text">{{ 'translations.quiz.instructions.placeholder.summary' | transloco }}</span>
    <span *ngIf="!expanded && content.content" class="light-text" [innerHTML]="content.content"></span>
    <mat-icon aria-hidden="true" class="icon" *ngIf="!expanded">chevron_right</mat-icon>
  </div>

  <div class="quiz-instructions__body" *ngIf="expanded">
    <span class="cdk-visually-hidden" #enterInstructionText>{{ 'translations.quiz.instructions.placeholder.full' | transloco }}</span>
    <ptl-editor
      class="add-question__section__rich"
      [link]="false"
      [upload]="false"
      [separator]="false"
      [placeholder]="enterInstructionText.innerHTML"
      [content]="[content]"
      (contentUpdate)="onMediumEditorUpdate($event)">
    </ptl-editor>

    <mat-icon tabindex="0"
              class="icon"
              [attr.aria-label]="('translations.global.ariaLabels.collapseQuizInstructions' | transloco)"
              (keydown.enter)="expanded=false"
              (click)="expanded=false">keyboard_arrow_up
    </mat-icon>
  </div>
</div>
</ng-container>

<ng-container *ngIf="newVersionEnabled">
  <div class="quiz-instruction-box">
    <div class="title small bold">{{ 'translations.quiz.instructions.title' | transloco }}</div>
    <div class="description extra-small">{{ 'translations.quiz.instructions.title' | transloco }}</div>
    <ptl-editor
      class="quiz-instruction-textarea"
      [link]="false"
      [upload]="false"
      [separator]="false"
      [placeholder]=""
      [content]="[content]"
      (contentUpdate)="onMediumEditorUpdate($event)">
    </ptl-editor>
  </div>
</ng-container>
