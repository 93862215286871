/*
 * Copyright (C) 2021 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Input } from '@angular/core';
import { CategoriesSection } from '../../../../../shared/models/page/page.model';
import { ContentHelper } from '../../../../../shared/helpers/content-helper';

@Component({
  selector: 'ptl-page-categories-section',
  templateUrl: './page-categories-section.component.html',
  styleUrls: ['./page-categories-section.component.scss'],
})
export class PageCategoriesSectionComponent {

  /** Section title. */
  @Input() title: string;
  @Input() section: CategoriesSection;
  @Input() isV2: boolean;
  @Input() isContentStore: boolean;

  discoveryPageUrl: string;
  constructor() {
    this.discoveryPageUrl = ContentHelper.getOrgDiscoveryUrl();

  }

}
