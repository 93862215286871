/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { PageDataService } from './data.service';
import { ObservableResult } from '../../../shared/store';
import { RestClientService } from '../../../shared/services/rest-client.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { Page as Pagination } from '../../../shared/models/page';
import {
  Page, PageAuthorInfoUpdateRequest,
  PageHeadersRequest, PageList,
  PageRequest,
  PageThumbnailUpdateRequest, SearchPage, UpdatePageNavigationRequest, UpdateSyndicationRequest
} from '../../../shared/models/page/page.model';
import { LearnerPlaylistSummary } from '../../../shared/models/playlist/learner-playlist-summary.model';
import { DEFAULT_LANGUAGE_CODE, TranslationService } from '../../../shared/services/translation/translation.service';
import { PlaylistCardShort, SearchPlaylist } from '../../../shared/models';
import { PageContentStoreSummary } from '../../../shared/models/content-store/content-store.model';
import { LanguageCodeHelper } from '../../../shared/helpers/language-code-helper';

@Injectable()
export class ApiPageDataService implements PageDataService {

  constructor(private restClient: RestClientService, private translationService: TranslationService) {
  }

  createPage(request: PageRequest): ObservableResult<Page> {
    return this.restClient.post<Page>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'pages'), request).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreatePage'))));
  }

  getPages(page: number, size: number): ObservableResult<PageList[]> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    return this.restClient.get<PageList[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'pages'),
      params
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetPage'))));
  }


  getHomePage(isAlumni?: boolean, languageCode?: string): ObservableResult<Page> {
    return this.restClient.get<Page>(
      Location.joinWithSlash(environment.apiRootUrl || '', isAlumni ? 'pages/alumni' : 'pages/home'),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetHomePage'))));
  }

  updatePage(pageUid: string, request: PageRequest, languageCode?: string): ObservableResult<void> {
    return this.restClient.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatePage'))));
  }

  updateHeaders(pageUid: string, request: PageHeadersRequest, languageCode?: string): ObservableResult<void> {
    return this.restClient.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/headers`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatePageHeaders'))));
  }

  getPage(
    pageUri: string,
    publisherUri: string,
    packageUri: string,
    frameworkId?: string,
    tagId?: string,
    languageCode?: string,
    contentStore?: boolean,
  ): ObservableResult<Page> {
    let uri = frameworkId ? `pages/${contentStore ? 'store/' : ''}categories/${frameworkId}/${pageUri}?tagId=${tagId}` :
      `pages/${pageUri}`;
    if (publisherUri && packageUri) {
      uri = `pub/${publisherUri}/${packageUri}/${uri}`
    }

    return this.restClient.get<Page>(
      Location.joinWithSlash(environment.apiRootUrl || '', uri),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(
        this.translationService.getTranslation('errors.errorGetPage'),
        err.error?.dataForms
      )));
  }

  searchPage(term: string, page: number, size: number): ObservableResult<Pagination<SearchPage>> {
    return this.restClient.get<Pagination<SearchPage>>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'pages/search'),
      {
        page: page.toString(),
        size: size ? size.toString() : '10',
        term: term,
      }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetPage'))));
  }


  searchContentStorePage(page: number, size: number, term: string): ObservableResult<Pagination<SearchPage>> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    if (term) {
      params['term'] = term;
    }
    return this.restClient.get<Pagination<SearchPage>>(
      Location.joinWithSlash(environment.apiRootUrl || '', 'pages/store/search'),
      params,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetPage'))));
  }


  getPageSummary(pageUid: string): ObservableResult<PageContentStoreSummary> {
    const uri = `pages/${pageUid}/store/summary`;
    return this.restClient.get<PageContentStoreSummary>(
      Location.joinWithSlash(environment.apiRootUrl || '', uri)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetPageSummary'))));
  }

  getStorePagesItems(page: number, size: number, languageCode?: string): ObservableResult<Pagination<PageContentStoreSummary>> {
    const uri = `/pages/store/published?page=${page}&size=${size}`;
    return this.restClient.get<Pagination<PageContentStoreSummary>>(
      Location.joinWithSlash(environment.apiRootUrl || '', uri),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetPage')))
    );
  }

  getLatestPlaylists(page: number, size: number, frameworkId?: string, tagId?: string, languageCode?: string, contentStore?: boolean):
    ObservableResult<Pagination<LearnerPlaylistSummary>> {
    const uri = frameworkId ?
      `/playlists/${contentStore ? 'store/' : ''}latest/categories/${frameworkId}?tagId=${tagId}&page=${page}&size=${size}` :
      `/playlists/${contentStore ? 'store/' : ''}latest?page=${page}&size=${size}`;
    return this.restClient.get<Pagination<LearnerPlaylistSummary>>(
      Location.joinWithSlash(environment.apiRootUrl || '', uri),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetLatestPlaylists')))
    );
  }

  getUncompletedPlaylists(page: number, size: number, languageCode?: string): ObservableResult<Pagination<LearnerPlaylistSummary>> {
    return this.restClient.get<Pagination<LearnerPlaylistSummary>>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/playlists/users/uncompleted?page=${page}&size=${size}`),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetUncompletedPlaylists')))
    );
  }

  getYourPlaylists(page: number, size: number, term?: string, languageCode?: string): ObservableResult<Pagination<LearnerPlaylistSummary>> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    if (term) {
      params['term'] = term;
    }
    return this.restClient.get<Pagination<LearnerPlaylistSummary>>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/playlists/owning'),
      params,
      languageCode ? { 'Accept-Language': languageCode } : null,
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetYourPlaylists')))
    );
  }

  getSubscriptionPlaylists(page: number, size: number): ObservableResult<Pagination<LearnerPlaylistSummary>> {
    return this.restClient.get<Pagination<LearnerPlaylistSummary>>(
      Location.joinWithSlash(
        environment.apiRootUrl || '', `/playlists/users/subscriptions?page=${page}&size=${size}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetSubscriptionPlaylists')))
    );
  }

  getInstitutionalLibraryPlaylist(): ObservableResult<PlaylistCardShort> {
    return this.restClient.get<PlaylistCardShort>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/playlists/inst-lib')).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(err => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetPlaylist')))
    );
  }

  changePageThumbnail(
    pageUid: string,
    request: PageThumbnailUpdateRequest,
    languageCode?: string
  ): ObservableResult<{ url: string }> {
    return this.restClient.post<{ url: string }>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/thumbnails`),
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatePageThumbnail')))
    );
  }

  removePageThumbnail(pageUid: string, languageCode?: string): ObservableResult<void> {
    return this.restClient.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/thumbnails`),
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    ).pipe(
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemovePageThumbnail')))
    );
  }

  archivePage(pageUid: string): ObservableResult<void> {
    return this.restClient.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemovePage')))
    );
  }

  switchToHomePage(pageUid: string): ObservableResult<void> {
    return this.restClient.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/switch`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorSwitchHomepage')))
    );
  }

  clonePage(pageUid: string): ObservableResult<PageRequest> {
    return this.restClient.post<PageRequest>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/clone`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorClonePage')))
    );
  }

  getTableauToken(pageUid: string, languageCode?: string): ObservableResult<{ token: string }> {
    return this.restClient.get<{ token: string }>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/dashboard/token`),
      {},
    { 'Content-Language': languageCode ? languageCode : DEFAULT_LANGUAGE_CODE }
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError((err) => ObservableResult.ofSuccess(err)
      ));
  }

  associateAdmin(pageUid: string, adminUid: string): ObservableResult<void> {
    const request = { adminUid: adminUid };
    return this.restClient.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/admins/${adminUid}`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddAdmin')))
    );
  }

  dissociateAdmin(pageUid: string, adminUid: string): ObservableResult<void> {
    return this.restClient.delete(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/admins/${adminUid}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveAdmin')))
    );
  }

  addTag(pageUid: string, tagUid: string): ObservableResult<void> {
    return this.restClient.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/tags/${tagUid}`),
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddTag')))
    );
  }

  removeTag(pageUid: string, tagUid: string): ObservableResult<void> {
    return this.restClient.delete(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/tags/${tagUid}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveTag')))
    );
  }

  targetPageToGroup(pageUid: string, groupUid: string): ObservableResult<void> {
    return this.restClient.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/target/groups/${groupUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddUserGroupToPage')))
    );
  }

  targetPageRemoveGroup(pageUid: string, groupUid: string): ObservableResult<void> {
    return this.restClient.delete(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/target/groups/${groupUid}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveUserGroupFromPage')))
    );
  }


  targetPageToUser(pageUid: string, userUid: string): ObservableResult<void> {
    return this.restClient.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/target/users/${userUid}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddUserToPage')))
    );
  }

  targetPageRemoveUser(pageUid: string, userUid: string): ObservableResult<void> {
    return this.restClient.delete(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/target/users/${userUid}`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveUserFromPage')))
    );
  }

  targetPageRemoveAllUsers(pageUid: string): ObservableResult<void> {
    return this.restClient.delete(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/target/users`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveAllUsersFromPage')))
    );
  }

  targetPageRemoveAllGroups(pageUid: string): ObservableResult<void> {
    return this.restClient.delete(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/target/groups`)
    ).pipe(
      switchMap(() => ObservableResult.ofSuccess()),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveAllUsersFromPage')))
    );
  }

  addLanguage(pageUid: string, languageCode: string): ObservableResult<void> {
    return this.restClient.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/languages/${languageCode}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingLanguage'))));
  }

  removeLanguage(pageUid: string, languageCode: string): ObservableResult<void> {
    return this.restClient.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/languages/${languageCode}`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingLanguage'))));
  }

  enableLanguage(pageUid: string, languageCode: string): ObservableResult<void> {
    return this.restClient.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/languages/${languageCode}/enable`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingLanguage'))));
  }

  disableLanguage(pageUid: string, languageCode: string): ObservableResult<void> {
    return this.restClient.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/languages/${languageCode}/disable`)
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingLanguage'))));
  }

  addDataForm(pageUid: string, dataFormUid: string): ObservableResult<void> {
    const request = {
      dataFormUid: dataFormUid,
      event: 'PAGE_LOAD'
    }
    return this.restClient.post<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/data-forms`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('global.dataForms.error.addingDataForm'))));
  }

  removeDataForm(pageUid: string, dataFormUid: string): ObservableResult<void> {
    const request = {
      dataFormUid: dataFormUid,
      event: 'PAGE_LOAD'
    }
    return this.restClient.delete<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/data-forms`),
      null,
      null,
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('global.dataForms.error.removingDataForm'))));
  }

  updateAuthorInfo(pageUid: string, request: PageAuthorInfoUpdateRequest): ObservableResult<void> {
    return this.restClient.patch<void>(
      Location.joinWithSlash(environment.apiRootUrl || '', `pages/${pageUid}/author`),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingPageAuthor'))));
  }

  updateSyndicateStatus(pageUid: string, request: UpdateSyndicationRequest): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `/pages/${pageUid}/syndication/items`);
    return this.restClient.patch<void>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingSyndicationStatus')))
      );
  }

  updateNavigation(pageUid: string, request: UpdatePageNavigationRequest): ObservableResult<void> {
    const url = Location.joinWithSlash(environment.apiRootUrl || '', `/pages/${pageUid}/navigations`);
    return this.restClient.patch<void>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingPageNavigation')))
      );
  }

}
