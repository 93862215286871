<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ptl-classof-auth>
  <div class="class-of__login-form-container">
    <div class="class-of__login-form-container-inner">

      <form
        class="class-of__login-form"
        [formGroup]="registerFrom"
        (ngSubmit)="onSignUpClick()"
        novalidate
        [attr.aria-label]="('translations.global.ariaLabels.classOf2020SignUpForm' | transloco)">

        <div class="back-container">

          <h2 class="back-container-label">{{ 'translations.public.title.registration' | transloco }}</h2>
        </div>

        <mat-form-field class="form-field" appearance="outline">
          <mat-label [attr.aria-label]="('translations.global.ariaLabels.emailField' | transloco)">{{ 'translations.global.input.label.email' | transloco }}</mat-label>
          <input
            matInput
            required
            type="email"
            name="email"
            formControlName="email"/>
          <mat-error *ngIf="registerFrom?.controls.email.hasError('required')">
            {{ 'translations.global.input.error.enterEmail' | transloco }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field" appearance="outline">
          <mat-label [attr.aria-label]="('translations.global.ariaLabels.firstNameField' | transloco)">{{ 'translations.global.input.label.firstName' | transloco }}</mat-label>
          <input
            matInput
            required
            type="text"
            name="firstName"
            formControlName="firstName"/>
          <mat-error *ngIf="registerFrom?.controls.firstName.hasError('required')">
            {{ 'translations.global.input.error.enterValidFirstName' | transloco }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field" appearance="outline">
          <mat-label [attr.aria-label]="('translations.global.ariaLabels.lastNameField' | transloco)">{{ 'translations.global.input.label.lastName' | transloco }}</mat-label>
          <input
            matInput
            required
            type="text"
            name="lastName"
            formControlName="lastName"/>
          <mat-error *ngIf="registerFrom?.controls.lastName.hasError('required')">
            {{ 'translations.global.input.error.enterValidLastName' | transloco }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field password-field" appearance="outline">
          <mat-label [attr.aria-label]="('translations.global.ariaLabels.passwordField' | transloco)">{{ 'translations.global.input.label.password' | transloco }}</mat-label>
          <input
            matInput
            required
            name="password"
            formControlName="password"
            (focus)="passwordFocusState = true" (focusout)="passwordFocusState = false"
            [type]="showRegisterPasswordSelected ? 'text' : 'password'"
            autocomplete="new-password"/>
          <div class="password-visibility">
            <i
              tabindex="0"
              [attr.aria-label]="('translations.global.ariaLabels.showOrHidePassword' | transloco)"
              class="material-icons password-show-icon"
              (click)="showRegisterPasswordSelected = !showRegisterPasswordSelected"
              (keydown.enter)="showRegisterPasswordSelected = !showRegisterPasswordSelected">
              visibility
            </i>
          </div>
          <mat-error *ngIf="registerFrom?.controls.password.hasError('required')">
            {{ 'translations.global.input.error.enterPassword' | transloco }}
          </mat-error>
          <mat-hint *ngIf="passwordFocusState">{{ 'translations.global.input.error.enterValidPassword' | transloco }}</mat-hint>
          <mat-error *ngIf="registerFrom?.controls.password.hasError('pattern')">
            {{ 'translations.global.input.error.enterValidPassword' | transloco }}
          </mat-error>
        </mat-form-field>

        <mat-checkbox
          required="true"
          class="form-terms-and-conditions-checkbox"
          name="termsOfService"
          formControlName="termsOfService"
          [aria-label]="('translations.global.ariaLabels.termsOfServiceCheckbox' | transloco)"
          color="primary">
          <span class="inner-text" [innerHTML]="'translations.public.description.termsOfService' | transloco"></span>
        </mat-checkbox>

        <div class="form-error" *ngIf="accountCreationError$ | async">
          <span>{{ accountCreationError$ | async }}</span>
        </div>

        <button
          mat-button
          [class.mat-flat-button]="newVersionEnabled"
          [class.mat-raised-button]="!newVersionEnabled"
          [class.old-version]="!newVersionEnabled"
          type="submit"
          class="form-submit"
          color="{{newVersionEnabled ? 'warn' : 'accent'}}"
          *ngIf="organization?.allowRegistrations"
          [disabled]="(accountCreationInProgress$ | async)">
          <span class="uppercase" *ngIf="(accountCreationInProgress$ | async) !== true">{{ 'translations.public.button.signUp' | transloco }}</span>
          <span class="submit-loading" *ngIf="accountCreationInProgress$ | async">
              <span class="submit-logging-in">{{ 'translations.public.button.creatingAccount' | transloco }}</span>
              <mat-spinner [diameter]="20"></mat-spinner>
            </span>
        </button>

      </form>

      <div class="social-buttons-headline">
        <span [attr.aria-label]="('translations.global.ariaLabels.orSingUpWith' | transloco)">{{ 'translations.public.label.signUpWith' | transloco }}</span>
      </div>
      <div class="social-buttons">
        <button class="linkedin-button" title="Sing up with LinkedIn" mat-raised-button (click)="signInUsingLinkedIn()">
          <img alt="" src="assets/social/linkedin-white.svg" width="291" height="79">
        </button>
        <button class="google-button" title="Sing up with Google" mat-raised-button (click)="signInUsingGoogle()">
          <img alt="" src="assets/social/google-colored.svg" width="265" height="87">
        </button>
      </div>

      <div class="class-of__login-footer">
        {{ 'translations.public.label.alreadyHaveAccount' | transloco }}
        <span tabindex="0"
              class="class-of__login-footer-link"
              [attr.aria-label]="('translations.global.ariaLabels.alreadyHaveAnAccount_SignInHere' | transloco)"
              (click)="switchLoginForm()"
              (keydown.enter)="switchLoginForm()">
          {{ 'translations.public.button.signInHere' | transloco }}
        </span>
      </div>

    </div>


  </div>
</ptl-classof-auth>
