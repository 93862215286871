import { Injectable, NgZone } from '@angular/core';
import { ToastInfo, ToastType } from '../../models/toast-notifications/toast-info';
import { PushNotificationMessage } from '../../models/websocket/websocket.model';
import * as moment from 'moment';

@Injectable()
export class ToastServiceImpl {
  private maxSize = 5;
  toastListBuffer: ToastInfo[] = [];
  visibleToastsList: ToastInfo[] = [];

  constructor(private ngZone: NgZone) {
  }

  showPushNotification(pushNotification: PushNotificationMessage) {
    if (pushNotification.notification?.additionalDateTime) {
      pushNotification.message += ' ' + moment.utc(pushNotification.notification.additionalDateTime)
        .local()
        .format('D MMMM YYYY HH:mm');
    }
    this.show({
      type: ToastType.PUSH_NOTIFICATION,
      createdAt: new Date(),
      header: pushNotification.header,
      message: pushNotification.message,
      notification: pushNotification.notification,
      delay: 10_000
    })
  }

  showSuccess(message: string) {
    this.show({
      type: ToastType.SUCCESS,
      createdAt: new Date(),
      message: message,
      delay: 3_500
    })
  }

  showFail(message: string) {
    this.show({
      type: ToastType.FAIL,
      createdAt: new Date(),
      message: message,
      delay: 5_000
    })
  }

  private show(toast: ToastInfo) {
    if (this.visibleToastsList.length >= this.maxSize) {
      this.toastListBuffer.push(toast);
    } else {
      this.ngZone.run(() => {
        this.visibleToastsList.push(toast);
      })
    }
  }

  remove(createdAt: Date) {
    this.visibleToastsList = this.visibleToastsList.filter(
      (toast) => toast.createdAt.getTime() !== createdAt.getTime()
    );
    if (this.toastListBuffer.length > 0) {
      const bufferedToastInfo = this.toastListBuffer.at(0);
      this.toastListBuffer = this.toastListBuffer.filter(
        (toast) =>
          toast.createdAt.getTime() !== bufferedToastInfo.createdAt.getTime()
      );
    }
  }

  getToastList(): ToastInfo[] {
    return this.visibleToastsList;
  }
}
