<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="headers-editor-container">

  <div class="editor-item" *ngIf="!shouldLimitSettings && currentLanguage?.isDefault">
    <h2 class="editor-item-title small bold">{{ 'translations.summary.title.url' | transloco }}</h2>
    <p class="editor-item-description extra-small">{{ 'translations.summary.description.url' | transloco }}</p>

    <input
      class="editor-item-input"
      matInput
      tabindex="0"
      #uriInput
      type="text"
      [attr.aria-label]="('translations.global.ariaLabels.update' | transloco) + ' ' + (cardUid ? ('translations.global.ariaLabels.card' | transloco) : pageUid ? ('translations.global.ariaLabels.page' | transloco) : ('translations.global.ariaLabels.playlist' | transloco)) + ' ' + ('translations.global.ariaLabels.uri' | transloco)"
      autocomplete="off"
      [placeholder]="'Update ' + (cardUid ? 'card' : pageUid ? 'page' : 'playlist') + ' uri'"
      [(ngModel)]="uri"
      (focusout)="updateUri()"/>
  </div>

  <div class="editor-item">
    <h2 class="editor-item-title small bold">{{ 'translations.summary.title.subheadline' | transloco }}</h2>
    <p class="editor-item-description extra-small">{{ 'translations.summary.description.subheadline' | transloco }}</p>
    <input
      class="editor-item-input"
      matInput
      tabindex="0"
      type="text"
      [attr.aria-label]="('translations.global.ariaLabels.addASubheaderToThis' | transloco) + ' ' + (cardUid ? ('translations.global.ariaLabels.card' | transloco) : pageUid ? ('translations.global.ariaLabels.page' | transloco) : ('translations.global.ariaLabels.playlist' | transloco))"
      autocomplete="off"
      [disabled]="isDisabled"
      [placeholder]="'Add a subheader to this ' + (cardUid ? 'card' : pageUid ? 'page' : 'playlist')"
      [(ngModel)]="subheader"
      (focusout)="updateSubheader()"/>
  </div>


  <div class="editor-item" *ngIf="!shouldLimitSettings && authorDetails && visibleAuthor">
    <h2 class="editor-item-title small bold">{{ 'translations.summary.title.author' | transloco }}</h2>
    <p class="editor-item-description extra-small">{{ 'translations.summary.description.author' | transloco }}</p>
      <mat-select
        class="editor-item-select"
        tabindex="0"
        [attr.aria-label]="('translations.global.ariaLabels.authorVisibility' | transloco)"
        [value]="visibleAuthor"
        (selectionChange)="changeAuthorVisibility($event)">
        <mat-select-trigger class="author-select-option">
          <img class="author-image"
               *ngIf="selectedAuthor.image"
               [attr.src]="selectedAuthor.image"
               width="24"
               height="24"
               alt="">
          <div class="author-initials small bold" *ngIf="!selectedAuthor.image">
            <span>{{ selectedAuthor.name?.charAt(0) }}</span>
          </div>
          <span class="author-name small bold">{{selectedAuthor.name}}</span>
        </mat-select-trigger>
        <mat-option [value]="1" class="author-select-option">
          <img
            class="author-image"
            alt="" width="24" height="24"
            [src]="getOrganizationImage()">
          <span class="author-name small bold">{{getOrganizationName()}}</span>
        </mat-option>
        <mat-option [value]="2" class="author-select-option">
          <img
            *ngIf="authorDetails?.userImageUrl"
            class="author-image"
            alt="" width="24" height="24"
            [src]="authorDetails?.userImageUrl">
          <div class="author-initials small bold" *ngIf="!authorDetails?.userImageUrl">
            <span>{{ authorDetails?.name.charAt(0) }}</span>
          </div>
          <span class="author-name small bold">{{authorDetails?.name}}</span>
        </mat-option>
      </mat-select>
  </div>


  <div class="editor-item time-estimate-section" *ngIf="!pageUid && canEditTime">

    <h2 class="editor-item-title small bold">{{ 'translations.summary.title.timeEstimate' | transloco }}</h2>
    <p class="editor-item-description extra-small"
       [class.no-margin]="suggestedTime">{{ 'translations.summary.description.timeEstimate' | transloco }}</p>

    <p *ngIf="suggestedTime"
       class="editor-item-description extra-small pointer"
       role="button"
       tabindex="0"
       (click)="applySuggestedTime()"
       (keydown.enter)="applySuggestedTime()"
       [innerHTML]="'translations.cards.publish.label.timeEstimateSuggestion' | transloco: {time: suggestedTimeFormat}">
    </p>

    <div class="time-estimate-select-box">
      <div class="select-field-box small">
        <mat-select class="select-field"
                    tabindex="0"
                    [attr.aria-label]="('translations.global.ariaLabels.selectHour' | transloco)"
                    placeholder="HH"
                    [(ngModel)]="selectedHour"
                    (selectionChange)="onTimeSelectChange()">
          <mat-option [value]="hour" *ngFor="let hour of hours">{{hour}}</mat-option>
        </mat-select>
        <span class="small">{{ 'translations.summary.label.hours' | transloco }}</span>
      </div>
      <div class="select-field-box small">
        <mat-select class="select-field"
                    [attr.aria-label]="('translations.global.ariaLabels.selectMinute' | transloco)"
                    placeholder="MM"
                    tabindex="0"
                    [(ngModel)]="selectedMinutes"
                    (selectionChange)="onTimeSelectChange()">
          <mat-option [value]="minute" *ngFor="let minute of minutes">{{minute}}</mat-option>
        </mat-select>
        <span class="small">{{ 'translations.summary.label.minutes' | transloco }}</span>
      </div>


      <div class="reset-button-box">
        <button
          tabindex="0"
          mat-stroked-button
          *ngIf="timeRequired"
          class="reset-btn"
          [attr.aria-label]="('translations.global.ariaLabels.resetTimeSuggestion' | transloco)"
          (click)="onResetTimeSuggestion()">{{ 'translations.cards.publish.button.resetTimeSuggestion' | transloco }}
        </button>

      </div>
    </div>
  </div>
</div>
