<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="audio-preview-element
  audio-preview-element-{{ content?.type }}
  audio-preview-element-position-{{ content?.position || 'CENTER' }}"
  *ngIf="content?.type === 'MEDIA_UPLOAD'"
  [ngClass]="'audio-preview-element--' + content?.type">
  <audio
    tabindex="0"
    class="audio-preview-element--audio"
    [src]="content?.content.url"
    controls>
  </audio>
  <div class="audio-preview-element--content" *ngIf="content?.caption">
    <p
      tabindex="0"
      class="audio-preview-element--caption"
      [class.link]="content?.url"
      [attr.aria-label]="content?.url ? ('translations.global.ariaLabels.openUrl' | transloco: {url: content?.url}) : content?.caption"
      (click)="openUrl()"
      (keydown.enter)="openUrl()">
      {{content?.caption}}</p>
  </div>
</div>
