/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, Input, NgZone, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Organization, PlaylistCardShort, ResourceAuthorDetails } from '../../../../../../../shared/models';
import { UserAuthState } from '../../../../../../../user-auth/store/user-auth.state';
import { LanguageCodeHelper } from '../../../../../../../shared/helpers/language-code-helper';
import { EventCardTime } from '../../../../../../resource/store/admin/resource-event-admin.state.model';
import { EventCardsHelper } from '../../../../../../../shared/helpers/event-cards-helper';
import {
  RedirectCardType,
  RedirectHelper
} from '../../../../../../resource/store/editor/content/helpers/redirect.helper';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '../../../../../../../shared/services/translation/translation.service';

@Component({
  selector: 'ptl-resource-section-card',
  templateUrl: './resource-section-card.component.html',
  styleUrls: ['./resource-section-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosticsReportResourceSectionCardComponent implements OnInit {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @Input() card: PlaylistCardShort;
  @Input() cardIndex: number;

  eventText: string;
  subheaderText: string;
  authorDetails: ResourceAuthorDetails;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService,
  ) {

  }


  ngOnInit() {
    if (this.card) {
      this.subheaderText = this.getCardSubheaderText();
      this.eventText = this.getCardEventText(this.card.eventTime);
      this.authorDetails = this.card.authorDetails;
    }
  }

  onCardClick(card: PlaylistCardShort) {
    const navigationUrl = this.getCardUrl(card, true);
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, navigationUrl, null, '_blank');
  }

  getCardUrl(card: PlaylistCardShort, isClick: boolean) {
    let navigationUrl: string;
    const playlistUri = null;
    if (card.type === 'GROUP') {
      navigationUrl = RedirectHelper.getRedirectUrl(this.activatedRoute, {
        playlistUri: playlistUri,
        resourceUri: card.uri,
        formattedUri: card.formattedUri,
      }, 'GROUP');
    } else if (card.type === 'PLAYLIST') {
      navigationUrl = RedirectHelper.getRedirectUrl(this.activatedRoute, {
        playlistUri: card.uri,
        formattedUri: card.formattedUri,
      }, 'PLAYLIST');
    } else if (card.type === 'WEBLINK') {
      const openTarget = (card.webLinkUrl.indexOf(window.location.hostname) !== -1) ? '_self' : '_blank';
      if (isClick) {
        window.open(card.webLinkUrl, openTarget);
      }
    } else {
      navigationUrl = RedirectHelper.getRedirectUrl(this.activatedRoute, {
        playlistUri: playlistUri,
        resourceUri: card.uri,
        formattedUri: card.formattedUri,
        pageNumberUri: 'page/1'
      }, card.type as RedirectCardType);
    }
    return navigationUrl;
  }


  getGroupCardImages(images): string[] {
    return images ? images.split(',') : [];
  }

  getCardSubheaderText() {
    const subheader = this.card?.subHeader;
    let text = subheader ? LanguageCodeHelper.getDataByLanguageCode(subheader).value.trim() : '';

    text = text.replace(/<[^>]*>/g, ' ').replace(/&nbsp;/g, ' ');
    return text ? text : '';
  }

  private getCardEventText(eventTime: EventCardTime): string {
    const text = EventCardsHelper.getEventStartingText(eventTime, this.translationService);
    return text ? text : '';
  }
}
