/*
 * Copyright (C) 2022 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
import { InjectionToken } from '@angular/core';
import { ObservableResult } from '../../store';
import {
  AnswerRequest,
  CreateDataFormRequest,
  DataForm, DataFormSummary,
  FormElementRequest, UserDataForm
} from '../../models/data-form/data-form.model';
import { ItemWithUploadUrls } from '../../models/ItemWithUploadUrl';
import { Page } from '../../models/page';

export const DATA_FORM_DATA_SERVICE =
    new InjectionToken<DataFormDataService>('[data-form] dataFormDataService');

/** The service handling data form CRUD operations. */
export interface DataFormDataService {

    getDataForm(dataFormUid: string, languageCode?: string): ObservableResult<DataForm>;

    getDataForms(page: number): ObservableResult<Page<DataForm>>;

    createDataForm(
      request: CreateDataFormRequest,
      languageCode?: string
    ) : ObservableResult<ItemWithUploadUrls<DataForm>>;

    updateDataForm(
      dataFormUid: string,
      request: CreateDataFormRequest,
      languageCode?: string
    ): ObservableResult<ItemWithUploadUrls<DataForm>>;

    deleteDataForm(
      dataFormUid: string,
    ): ObservableResult<void>;

    addFormElement(
      dataFormUid: string,
      request: FormElementRequest,
      languageCode?: string
    ): ObservableResult<DataForm>;

    updateFormElement(
      dataFormUid: string,
      formElementUid: string,
      request: FormElementRequest,
      languageCode?: string
    ): ObservableResult<DataForm>;

    deleteFormElement(
      dataFormUid: string,
      formElementUid: string,
    ): ObservableResult<void>;

    reorderFormElements(
      dataFormUid: string,
      request: { elements: string[] },
      languageCode?: string
    ): ObservableResult<DataForm>;

    getUserForm(
      dataFormUid: string,
    ): ObservableResult<UserDataForm>;

    getUserForms(
      page: number,
      size: number,
      status?: string
    ): ObservableResult<Page<UserDataForm>>;

    createUserForm(
      dataFormUid: string,
    ): ObservableResult<UserDataForm>;

    updateUserForm(
      userFormUid: string,
      request: { answers: AnswerRequest[] }
    ): ObservableResult<UserDataForm>;

    getSummaries(
      dataFormUids: string[]
    ): ObservableResult<DataFormSummary[]>;

    getDataFormsDetail(
      dataFormUids: string[]
    ): ObservableResult<DataForm[]>;
}
