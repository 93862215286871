/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../shared/store';
import { Injectable } from '@angular/core';
import { PageDataService } from './data.service';
import { Page as Pagination } from '../../../shared/models/page';
import {
  Page, PageAuthorInfoUpdateRequest,
  PageHeadersRequest, PageList,
  PageRequest,
  PageThumbnailUpdateRequest, SearchPage, UpdatePageNavigationRequest, UpdateSyndicationRequest
} from '../../../shared/models/page/page.model';
import { LearnerPlaylistSummary } from '../../../shared/models/playlist/learner-playlist-summary.model';
import { createDummyLearnerPlaylist } from '../../mock-utils';
import { PageContentStoreSummary } from '../../../shared/models/content-store/content-store.model';
import { PlaylistCardShort } from '../../../shared/models';

/* eslint-disable max-len */
@Injectable()
export class MockPageDataService implements PageDataService {

  private yourPlaylistData = {
    content: [createDummyLearnerPlaylist('1')],
    totalNumberOfElement: 10,
    pageSize: 10,
    currentPageNumber: 1,
    numberOfPages: 1,
  };

  private pagesSummaryData = {
    content: [],
    totalNumberOfElement: 0,
    pageSize: 0,
    currentPageNumber: 0,
    numberOfPages: 0,
  };


  /** Create page for the given organization. */
  createPage(request: PageRequest): ObservableResult<Page> {
    return ObservableResult.ofSuccess();
  }


  /** Gets pages of the organization */
  getPages(page: number, size: number): ObservableResult<PageList[]> {
    return ObservableResult.ofSuccess();
  }

  /** Gets the default home page of the organization */
  getHomePage(isAlumni?: boolean, languageCode?: string): ObservableResult<Page> {
    return ObservableResult.ofSuccess();
  }

  updatePage(pageUid: string, request: PageRequest, languageCode?: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateHeaders(pageUid: string, request: PageHeadersRequest, languageCode?: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  /** Gets the page by uri */
  getPage(
    pageUri: string,
    publisherUri: string,
    packageUri: string,
    frameworkId?: string,
    tagId?: string,
    languageCode?: string,
    contentStore?: boolean,
  ): ObservableResult<Page> {
    return ObservableResult.ofSuccess();
  }

  searchPage(term: string, page: number, size: number): ObservableResult<Pagination<SearchPage>> {
    return ObservableResult.ofSuccess();
  }

  searchContentStorePage(page: number, size: number, term: string): ObservableResult<Pagination<SearchPage>> {
    return ObservableResult.ofSuccess();
  }

  getPageSummary(pageUid: string): ObservableResult<PageContentStoreSummary> {
    return ObservableResult.ofSuccess();
  }

  getStorePagesItems(page: number, size: number, languageCode?: string): ObservableResult<Pagination<PageContentStoreSummary>> {
    return ObservableResult.ofSuccess(this.pagesSummaryData);
  }

  /** Returns latest playlists for given user from organization. */
  getLatestPlaylists(page: number, size: number, frameworkId?: string, tagId?: string, languageCode?: string,
                     contentStore?: boolean
  ):
    ObservableResult<Pagination<LearnerPlaylistSummary>> {
    return ObservableResult.ofSuccess();
  }

  /** Returns uncompleted learners playlists list */
  getUncompletedPlaylists(page: number, size: number, languageCode?: string): ObservableResult<Pagination<LearnerPlaylistSummary>> {
    return ObservableResult.ofSuccess();
  }

  getYourPlaylists(page: number, size: number, term?: string, languageCode?: string): ObservableResult<Pagination<LearnerPlaylistSummary>> {
    return ObservableResult.ofSuccess(this.yourPlaylistData);
  }

  getSubscriptionPlaylists(page: number, size: number): ObservableResult<Pagination<LearnerPlaylistSummary>> {
    return ObservableResult.ofSuccess(this.yourPlaylistData);
  }

  getInstitutionalLibraryPlaylist(): ObservableResult<PlaylistCardShort> {
    return ObservableResult.ofSuccess();
  }

  changePageThumbnail(pageUid: string, request: PageThumbnailUpdateRequest, languageCode?: string): ObservableResult<{ url: string }> {
    return ObservableResult.ofSuccess();
  }

  removePageThumbnail(pageUid: string, languageCode?: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  archivePage(pageUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  switchToHomePage(pageUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  clonePage(pageUid: string): ObservableResult<PageRequest> {
    return ObservableResult.ofSuccess();
  }

  getTableauToken(pageUid: string, languageCode?: string): ObservableResult<{ token: string }> {
    return ObservableResult.ofSuccess({ token: '' });
  }

  associateAdmin(pageUid: string, adminUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  dissociateAdmin(pageUid: string, adminUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addTag(pageUid: string, tagUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeTag(pageUid: string, tagUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  targetPageToGroup(pageUid: string, groupUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  targetPageRemoveGroup(pageUid: string, groupUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  targetPageToUser(pageUid: string, userUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  targetPageRemoveUser(pageUid: string, userUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  targetPageRemoveAllUsers(pageUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  targetPageRemoveAllGroups(pageUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  enableLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  disableLanguage(playlistUid: string, languageCode: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addDataForm(pageUid: string, dataFormUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeDataForm(pageUid: string, dataFormUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateAuthorInfo(pageUid: string, request: PageAuthorInfoUpdateRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateSyndicateStatus(pageUid: string, request: UpdateSyndicationRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateNavigation(pageUid: string, request: UpdatePageNavigationRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
