<!--
  ~ Copyright (C) 2022 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="add-member-filter">
  <mat-divider></mat-divider>
  <div class="add-member-filter__content">
      <span class="add-member-filter__name">
        {{ name }}
      </span>
      <span class="add-member-filter__description">
        {{ description }}
      </span>
      <button
        mat-stroked-button
        class="add-member-filter__button uppercase"
        type="button"
        title="Add filter"
        [disabled]="disabled"
        [attr.aria-label]="('translations.members.filter.button.add' | transloco)"
        (click)="onAddFilter()"
        (keydown.enter)="onAddFilter()"
      >
        {{ 'translations.members.filter.button.add' | transloco }}
      </button>
  </div>
</div>
