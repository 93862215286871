/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'ptl-grid-question-type',
  templateUrl: './grid-question-type.component.html',
  styleUrls: ['../add-question-section.component.scss', './grid-question-type.component.scss'],
})
export class GridQuestionTypeComponent implements OnChanges {

  /** Receives the question to edit */
  @Input() question: FormGroup;

  @Input() languageCode: string | undefined;

  @Input() isDefaultLanguage: boolean;

  gridOptionsPerRow: number[] = [1, 2, 3, 4, 5];
  isNoneOfTheseActive: boolean;

  constructor(private fb: FormBuilder) {
  }


  removeOption(index: number) {
    if (this.question.value.options[index].deselectOthers) {
      this.isNoneOfTheseActive = false;
    }
    this.questionOptions.removeAt(index);
  }

  addNewOption() {
    this.questionOptions.push(this.addGridOption());
    this.sortQuestionOptions();
  }

  dropOption(moveEvent: CdkDragDrop<FormArray>) {
    moveItemInArray(this.questionOptions.controls,
      moveEvent.previousIndex, moveEvent.currentIndex);
    this.sortQuestionOptions();
    this.questionOptions.updateValueAndValidity();
  }

  onNoneOfTheseSelected() {
    if (this.isNoneOfTheseActive) {
      this.questionOptions.push(this.addNoneOfTheseOption());
    } else {
      this.removeNoneOfTheseOption();
    }
  }


  ngOnChanges() {
    if (this.question) {
      this.populateForm();
      this.checkIfNoneOfTheseExists(this.question);
    }
  }

  private populateForm() {
    // if no uid, clear current arrays and add a blank one
    if (!this.question.value.uid) {
      while (this.questionOptions.length !== 0) {
        this.questionOptions.removeAt(0);
      }
      this.addNewOption();
    }
  }

  private addGridOption(): FormGroup {
    return this.fb.group({
      type: 'CHOICE_OPTION',
      value: this.fb.group({
        languageCode: this.languageCode,
        value: [undefined, Validators.required],
      }),
      deselectOthers: false,
      score: [undefined, Validators.required],
    });
  }

  private get questionOptions() {
    return this.question.get('options') as FormArray;
  }

  private addNoneOfTheseOption() {
    return this.fb.group({
      type: 'CHOICE_OPTION',
      value: this.fb.group({
        languageCode: this.languageCode,
        value: ['None of these', Validators.required],
      }),
      deselectOthers: true,
      score: [0, Validators.required],
    });
  }


  private sortQuestionOptions() {
    this.questionOptions.controls
      .sort((a, b) => a.value.deselectOthers - b.value.deselectOthers);
  }

  private removeNoneOfTheseOption() {
    const options = this.question.value.options;
    for (let i = 0; i < options.length; i++) {
      if (options[i].deselectOthers) {
        this.removeOption(i);
        break;
      }
    }
  }

  private checkIfNoneOfTheseExists(question: FormGroup) {
    const options = question.value.options;
    for (const option of options) {
      if (option.deselectOthers) {
        this.isNoneOfTheseActive = true;
        break;
      }
    }

  }

}
