/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { DiagnosticLogic, Diagnostics } from '../../../../shared/models';
import { filter } from 'rxjs/operators';
import { ChartContent, ChartItem, ChartType } from '../../../../shared/models/editor/chart-content.model';
import { TranslationService } from '../../../../shared/services/translation/translation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ResourceAdminState } from '../../../../page-modules/resource/store/admin/resource-admin.state';
import { VersionHelper } from '../../../../shared/helpers/version.helper';
import { DialogService } from '../../../../shared/helpers/dialog/dialog.service';

type formattedLearningGoals = {
  id: string;
  checked: boolean;
  title: string;
  color: string;
  maxIcon?: string;
  minIcon?: string;
  tagUid?: string;
}

@Component({
  selector: 'ptl-chart-new',
  templateUrl: './chart-new.component.html',
  styleUrls: ['./chart-new.component.scss'],
})
export class ChartNewComponent implements OnInit, OnDestroy, OnChanges {

  @Select(ResourceAdminState.resource)
  private resource$: Observable<Diagnostics>;

  @Select(ResourceAdminState.resourceUid)
  private resourceUid$: Observable<string>;

  @Input() charContent: ChartContent;

  @Input() canRemoveItem: boolean;

  @Input() chartSectionUid: string;

  /** Emits removing of the formContent event */
  @Output() removeChartContent = new EventEmitter<void>();

  /** Emits the update of the formContent event */
  @Output() updateChartContent = new EventEmitter<ChartContent>();

  private subscriptionEnd$ = new EventEmitter<void>();


  isEditing = true;
  selectedChartTitle = 'Radial';
  selectedChartType: ChartType = 'RADIAL';
  percentageShown = false;
  checkedItemIds = {};
  chartContentRequest: ChartContent;
  savedData: ChartItem[] = [];
  newVersionEnabled = VersionHelper.newVersionEnabled();

  charts = [{
    type: 'RADIAL',
    title: 'Radial',
  }, {
    type: 'BAR',
    title: 'Bar',
  }];

  dynamicContentUid: string;

  private resourceSubscription: Subscription;
  public resourceUid: string;
  public learnerGoals: formattedLearningGoals[];

  constructor(private translationService: TranslationService,
              private ngZone: NgZone,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private store: Store,
              private dialogService: DialogService
  ) {
    this.resourceUid$.pipe(
      takeUntil(this.subscriptionEnd$)
    ).subscribe((resourceUid) => {
      if (resourceUid) {
        this.resourceUid = resourceUid;
      }
    });

    if (this.newVersionEnabled) {
      this.isEditing = false;
    }
  }

  ngOnChanges() {
    this.dynamicContentUid = this.charContent.uid;
  }

  ngOnInit() {
    this.resourceSubscription = this.resource$.pipe(
      filter(data => !!data)
    ).subscribe(card => {
      this.learnerGoals = card.logic ? this.getLearningGoals(card.logic.learningGoals) : [];

      if (this.newVersionEnabled && !this.charContent.uid) {
        this.saveChart();
      }
    });
  }

  ngOnDestroy() {
    this.resourceSubscription?.unsubscribe();
    this.subscriptionEnd$?.emit();
  }

  onChartChange(type: ChartType) {
    for (const cart of this.charts) {
      if (cart.type === type) {
        this.selectedChartTitle = cart.title;
        this.selectedChartType = type;
        break;
      }
    }
  }


  saveChart() {
    this.isEditing = false;
    this.savedData = [];
    for (const learningGoal of this.learnerGoals) {
      if (this.checkedItemIds[learningGoal.id]) {
        this.savedData.push({
          itemUid: learningGoal.id,
          color: learningGoal.color
        });
      }
    }
    this.chartContentRequest = {
      ...this.charContent,
      showPercentage: this.percentageShown,
      chartType: this.selectedChartType,
      items: this.savedData,
    };
    this.updateChartContent.emit(this.chartContentRequest);
  }

  deleteChart() {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.removeSection'),
      this.translationService
    ).then(confirmed => {
      if(confirmed) {
        this.removeChartContent.emit();
      }
    });
  }

  editChart() {
    this.isEditing = true;
  }

  onCheckboxChange(item) {
    item.checked = !item.checked;

    if (item.checked) {
      this.checkedItemIds[item.id] = true;
    } else {
      this.checkedItemIds[item.id] = false;
    }
  }

  dropOption(moveEvent: CdkDragDrop<DiagnosticLogic[]>) {
    moveItemInArray(this.learnerGoals,
      moveEvent.previousIndex, moveEvent.currentIndex);
  }

  private getLearningGoals(learningGoals: DiagnosticLogic[]) {
    if (this.charContent && this.charContent.items) {
      this.setSelectedChartType(this.charContent);
      this.percentageShown = !!this.charContent.showPercentage;
      return this.getSelectedLearningGoals(learningGoals);
    } else {
      const formattedLearningGoals = [];
      for (const learningGoal of learningGoals) {
        formattedLearningGoals.push(
          {
            id: learningGoal.tagUid,
            checked: true,
            title: learningGoal.tagTitle,
            color: this.generateRandomHexColor(),
          }
        );
        this.checkedItemIds[learningGoal.tagUid] = true;
      }
      return formattedLearningGoals;
    }
  }

  private getSelectedLearningGoals(learningGoals: DiagnosticLogic[]) {
    const selectedArray = [];
    const unselectedArray = [];
    const learningGoalsArr = [...learningGoals];
    for (const cherItem of this.charContent.items) {
      for (let i = 0; i < learningGoalsArr.length; i++) {
        if (cherItem.itemUid === learningGoalsArr[i].tagUid) {
          selectedArray.push(
            {
              id: learningGoalsArr[i].tagUid,
              checked: true,
              title: learningGoalsArr[i].tagTitle,
              color: cherItem.color,
            }
          );
          this.checkedItemIds[learningGoalsArr[i].tagUid] = true;
          learningGoalsArr.splice(i, 1);
          i = 0;
          break;
        }
      }
    }
    for (const learningGoal of learningGoalsArr) {
      unselectedArray.push(
        {
          id: learningGoal.tagUid,
          checked: false,
          title: learningGoal.tagTitle,
          color: this.generateRandomHexColor(),
        }
      );
    }
    return selectedArray.concat(unselectedArray);
  }

  private setSelectedChartType(content: ChartContent) {
    this.selectedChartTitle = 'Radial';
    this.selectedChartType = content.chartType;
    if (this.selectedChartType === 'RADIAL') {
      this.selectedChartTitle = 'Radial';
    } else if (this.selectedChartType === 'BAR') {
      this.selectedChartTitle = 'Bar';
    }
  }

  private generateRandomHexColor(): string {
    let length = 6;
    const chars = '0123456789ABCDEF';
    let hex = '#';
    while (length--) {
      /* eslint-disable no-bitwise */
      hex += chars[(Math.random() * 16) | 0];
    }
    return hex;
  }
}
