import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { LocalTimeHelper } from '../../../helpers/local-time-helper';
import {
  ActivityRequest,
  BooleanFilter,
  BooleanQueryRequest,
  BooleanQueryType
} from '../../../models/admin/boolean-filters.model';
import { TranslationService } from '../../../services/translation/translation.service';

@Component({
  selector: 'ptl-activity-date-filter',
  templateUrl: './activity-date-filter.component.html',
  styleUrls: ['./activity-date-filter.component.scss']
})
export class ActivityDateFilterComponent implements OnInit {

  @Input() selectedFilters: BooleanFilter[];
  @Output() filterDeleted = new EventEmitter<void>();
  @Output() filterSaved = new EventEmitter<BooleanFilter>();

  expanded = true;
  activityIs = true;
  filterType: 'SINCE' | 'FOR_THE_LAST' = 'SINCE';

  date: Date;
  periodType: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR' = 'DAY';
  quantity = 10;

  constructor(private translationService: TranslationService) {
  }

  ngOnInit() {
    this.date = new Date(new Date().setUTCHours(0, 0, 0, 0));
    this.getSettingsFromFilters();
  }

  dateChanged(date) {
    this.date = date;
  }

  deleteFilter() {
    this.filterDeleted.emit();
  }

  setActivityIs() {
    this.activityIs = true;
  }

  setActivityIsNot() {
    this.activityIs = false;
  }

  setFilterType(fileterType: 'SINCE' | 'FOR_THE_LAST') {
    this.filterType = fileterType;
  }

  applyFilter() {
    const request = this.getRequest();
    const message = this.getMessage();
    this.filterSaved.emit({ request: request, message: message, type: 'ACTIVITY' });
  }

  private getRequest(): BooleanQueryRequest {
    const request: ActivityRequest = {
      type: BooleanQueryType.ACTIVITY,
      operator: this.activityIs ? 'AFTER' : 'BEFORE',
      time: this.getRequestTime().format(),
      frontendType: this.filterType,
      periodType: this.periodType,
      quantity: this.quantity
    }
    return request as BooleanQueryRequest;
  }

  private getMessage(): string {
    const hasHasNot = this.activityIs
    ? this.translationService.getTranslation('filter.has')
    : this.translationService.getTranslation('filter.hasNot');
    const pre = hasHasNot + ' ' + this.translationService.getTranslation('filter.activity.beenActive');
    if (this.filterType === 'SINCE') {
      return pre + ' ' + this.translationService.getTranslation('filter.activity.since') + ' '
        + LocalTimeHelper.getLocalDateTime(this.date).format('D MMM YYYY');
    } else if (this.filterType === 'FOR_THE_LAST') {
      return pre + ' ' + this.translationService.getTranslation('filter.activity.forTheLast') + ' ' + this.quantity
        + ' ' + this.getForTheLastMessageEnding();
    }
    return '';
  }

  private getForTheLastMessageEnding(): string {
    const singularPluralPostfix = this.quantity === 1 ? '' : 's';
    switch(this.periodType) {
      case 'DAY':
        return this.translationService.getTranslation('filter.activity.day' + singularPluralPostfix);
      case 'WEEK':
        return this.translationService.getTranslation('filter.activity.week' + singularPluralPostfix);
      case 'MONTH':
        return this.translationService.getTranslation('filter.activity.month' + singularPluralPostfix);
      case 'YEAR':
        return this.translationService.getTranslation('filter.activity.year' + singularPluralPostfix);
    }
  }

  private getRequestTime() {
    switch(this.filterType) {
      case 'SINCE':
        return moment(this.date);
      case 'FOR_THE_LAST':
        return moment().startOf('day').subtract(this.quantity, this.getPeriodDuration());
    }
  }

  private getPeriodDuration(): 'days' | 'weeks' | 'months' | 'years' {
    return this.periodType.toLowerCase() + 's' as 'days' | 'weeks' | 'months' | 'years';
  }

  private getSettingsFromFilters() {
    if (this.selectedFilters) {
      const activityFilter = this.selectedFilters.find(f => f.type === 'ACTIVITY');
      if (activityFilter) {
        const booleanRequest = (activityFilter.request as ActivityRequest);
        this.activityIs = booleanRequest.operator === 'AFTER';
        if (booleanRequest.frontendType === 'FOR_THE_LAST') {
          this.filterType = 'FOR_THE_LAST';
          this.periodType = booleanRequest.periodType;
          this.quantity = booleanRequest.quantity;
        } else {
          this.filterType = 'SINCE';
          this.date = new Date(booleanRequest.time);
        }
      }
    }
  }
}
