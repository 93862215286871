<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="create-moment__expand-btn-container">

  <button
    class="create-moment__expand-btn create-moment__expand-btn-open"
    *ngIf="!dialogLargeModeOn"
    [attr.aria-label]="('translations.global.ariaLabels.expandAddNewMoment' | transloco)"
    (click)="changeDialogMode(true)"
    (keydown.enter)="changeDialogMode(true)"
    tabindex="0">
    <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.expand' | transloco)">
      <use ogSvgUnify="assets/sprite/sprite.svg#fullscreen-expand"></use>
    </svg>
  </button>

  <button
    class="create-moment__expand-btn create-moment__expand-btn-close"
    *ngIf="dialogLargeModeOn"
    [attr.aria-label]="('translations.global.ariaLabels.collapseAddNewMoment' | transloco)"
    (click)="changeDialogMode(false)"
    (keydown.enter)="changeDialogMode(false)"
    tabindex="0">
    <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.contract' | transloco)">
      <use ogSvgUnify="assets/sprite/sprite.svg#fullscreen-collapse"></use>
    </svg>
    </button>

</div>
