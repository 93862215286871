/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import { Page as Pagination } from '../../models/page';
import {
  UpcomingEventCardV1,
  UpcomingEventCardV2,
  UpcomingEventFilter,
  UpcomingEventsTag
} from '../../models/event-card/event-card.model';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const SHARED_UPCOMING_DATA_SERVICE =
  new InjectionToken<SharedUpcomingDataService>('[Upcoming] SharedUpcomingDataService');

/** The service handling tag CRUD operations. */
export interface SharedUpcomingDataService {

  getUpcomingEvents(page: number, size: number, frameworkId?: string, tagId?: string, languageCode?: string):
    ObservableResult<Pagination<UpcomingEventCardV1>>;

  getUpcomingEventsByDate(startDate: string, endDate: string): ObservableResult<UpcomingEventCardV1[]>;

  getUpcomingEventsBySkills(tags: string[], startDate?: string, endDate?: string): ObservableResult<Pagination<UpcomingEventCardV1>>;

  getUpcomingEventsTags(page: number, size: number, term?: string): ObservableResult<Pagination<UpcomingEventsTag>>;

  hasUpcomingEvents(): ObservableResult<boolean>;

  getUpcomingEventsByFilter(page: number, size: number, filter: UpcomingEventFilter)
    : ObservableResult<Pagination<UpcomingEventCardV2>>;

  getEventsEngagements(page: number, size: number, filter: UpcomingEventFilter)
    : ObservableResult<Pagination<UpcomingEventCardV2>>;
}
