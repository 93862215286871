<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<ng-container *ngIf="content.mediaType === 'IMAGE'; else progressBar">
  <ptl-skeleton-view 
    [state]="{ loading: true }" 
    [viewType]="'image'" 
    [imageDimensions]="content?.imageDimensions">
  </ptl-skeleton-view>
</ng-container>

<ng-template #progressBar>
  <div class="upload-progress-bar-wrapper">
    <div class="upload-progress-bar">
      <div class="upload-progress" [style.width.%]="uploadProgress"></div>
    </div>
    <div class="upload-progress-percent">
      {{ uploadProgress }} %
    </div>
  </div>
</ng-template>