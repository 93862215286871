<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<form class="add-question__section" [formGroup]="question">
  <div class="add-question__section__grid">
    <div formArrayName="options">
      <div class="add-question__section-table  add-question__section-table--align">
        <div class="add-question__section-column add-question__section-column--grid">
          <h2
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.options' | transloco">
            {{ 'translations.options' | transloco }}</h2>
          <p
            class="add-question__section-col add-question__section-col--gray"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.pleaseAddOptionsBelow' | transloco">
            {{ 'translations.pleaseAddOptionsBelow' | transloco }}</p>
        </div>
        <div class="add-question__section-column">
          <h2 class="add-question__section-col"
              tabindex="0"
              [attr.aria-label]="'translations.global.ariaLabels.ranksTitle' | transloco">
            {{ 'translations.ranksTitle' | transloco }}
          </h2>
        </div>
      </div>
      <div
        cdkDropList
        [cdkDropListData]="question.controls['options']['controls']"
        (cdkDropListDropped)="dropOption($event)">
        <div
          cdkDrag
          cdkDragLockAxis="y"
          *ngFor="let item of question.controls['options']['controls']; let i = index"
          [formGroupName]="i"
          [cdkDragData]="item">
          <div class="add-question__section-table add-question__section-table--align"
          [class.add-question-section-table-new]="newVersionEnabled">

            <div class="icons" [class.is_disabled]="!isDefaultLanguage">
              <button mat-icon-button cdkDragHandle
                      class="add-question__section-column add-question__section-column--icons add-question__section-column-icon-grid  add-question__section-column__hoverable">
                <i class="material-icons" aria-hidden="true" *ngIf="!newVersionEnabled">unfold_more</i>

                <svg class="svg svg-icon" *ngIf="newVersionEnabled" [attr.aria-label]="('translations.global.ariaLabels.icons.draggable' | transloco)">
                  <use ogSvgUnify="assets/sprite/sprite.svg#drag-indicator"></use>
                </svg>
              </button>
            </div>
            <div class="add-question__section-column add-question__section-column--width" formGroupName="value">
              <mat-form-field class="add-question__section-field no-padding color-gray" appearance="outline"
                              [class.small-field]="newVersionEnabled">
                <input
                  matInput
                  type="text"
                  [attr.aria-label]="('translations.global.ariaLabels.enterOptionTitle' | transloco)"
                  id="input{{ i }}"
                  formControlName="value">
                <mat-error *ngIf="item.controls['value'].controls['value'].hasError('required')">
                  {{ 'translations.optionRequiredError' | transloco }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="icons">
              <div class="add-question__section-column" [class.is_disabled]="!isDefaultLanguage">
                <mat-form-field class="add-question__section-field no-padding color-gray" appearance="outline" [class.small-field]="newVersionEnabled">
                  <input
                    matInput
                    type="number"
                    [min]="1"
                    [attr.aria-label]="('translations.global.ariaLabels.enterOptionScore' | transloco)"
                    [max]="question.controls['options']['controls']?.length ?? 1"
                    (ngModelChange)="onRankChange($event, item.controls['score'])"
                    formControlName="score">
                  <mat-error *ngIf="item.controls['score'].hasError('required')">
                    {{ 'translations.optionScoreRequiredError' | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>
              <button mat-icon-button
                      type="button"
                      [class.is_disabled]="!isDefaultLanguage"
                      class="add-question__section-column add-question__section-column-remove"
                      *ngIf="i > 0"
                      [title]="('translations.removeOption' | transloco)"
                      [attr.aria-label]="('translations.global.ariaLabels.removeOption' | transloco)"
                      (click)="removeOption(i)">
                <mat-icon aria-hidden="true" [inline]="true" *ngIf="!newVersionEnabled">highlight_off</mat-icon>
                <svg class="svg svg-icon" *ngIf="newVersionEnabled" aria-hidden="true">
                  <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
                </svg>
              </button>
            </div>
          </div>

          <ng-container *ngIf="item.controls['instructions'] && newVersionEnabled && !isQuiz">
            <ptl-question-instruction
              [content]="item.controls['instructions'].value"
              (contentRemoval)="onContentRemoval($event, item.controls['instructions'].value, i)"
              (contentUpdate)="onContentUpdate($event, item.controls['instructions'].value, i)">
            </ptl-question-instruction>
          </ng-container>
        </div>
      </div>
      <button
        mat-button
        *ngIf="!newVersionEnabled"
        class="add-question__section-add-new"
        type="button"
        [class.is_disabled]="!isDefaultLanguage"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.clickToAddOption' | transloco"
        (click)="addNewOption()">
        <i class="material-icons" aria-hidden="true">add_circle_outline</i>
        <span>{{ 'translations.clickToAddOption' | transloco }}</span>
      </button>

      <button
        mat-button
        *ngIf="newVersionEnabled"
        class="add-new-question-btn"
        type="button"
        [class.is_disabled]="!isDefaultLanguage"
        tabindex="0"
        [attr.aria-label]="'translations.global.ariaLabels.clickToAddOption' | transloco"
        (click)="addNewOption()">
        <svg class="svg svg-icon icon-left" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#plus"></use>
        </svg>
        <span>{{ 'translations.clickToAddOption' | transloco }}</span>
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</form>
