/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { Organization } from '../../../models/organization';

@Component({
  selector: 'ptl-print-page-footer',
  templateUrl: './print-page-footer.component.html',
  styleUrls: ['./print-page-footer.component.scss'],
})
export class PrintPageFooterComponent {
  @Input() noteType: 'REPORT' | 'NONE' = 'NONE';
  @Input() pageNumber = 1;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  currentYear = new Date().getFullYear();
}
