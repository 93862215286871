/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {Injectable} from '@angular/core';
import {
  AssessmentSummary,
  UpdateReportSectionResponse,
  DiagnosticLogic,
  DiagnosticQuestionResponse,
  Diagnostics,
  DiagnosticsRetake,
  GeneratedReport,
  QuestionResponses,
  Report,
  UpdateReportTitleRequest,
  ShowReportLogoRequest,
  LearningGoalIconRequest,
  ReportMode,
  CreateReportResourceSectionRequest,
  UpdateReportResourceSectionRequest,
  MyPagesAssessmentsResponse,
  DiagnosticDescriptionQuestionResponse
} from '../../../shared/models';
import {ObservableResult} from '../../../shared/store';
import {DiagnosticsDataService} from './data.service';

/* eslint-disable max-len */
@Injectable()
export class MockDiagnosticsDataService implements DiagnosticsDataService {

  private dummyQuestionResponse: QuestionResponses = {
    uid: 'string',
    responses: [],
    version: 1,
    status: 'IN_PROGRESS',
  };

  private dummyAssessmentSummary: AssessmentSummary = {
    retake: true,
    lastVersion: 10,
    completed: true,
    inProgress: false,
    questionResponseId: '123Aas7',
  };

  createQuestion(): ObservableResult<DiagnosticQuestionResponse> {
    return ObservableResult.ofSuccess();
  }

  updateQuestion(): ObservableResult<DiagnosticQuestionResponse> {
    return ObservableResult.ofSuccess();
  }

  updateQuestionDescription(): ObservableResult<DiagnosticDescriptionQuestionResponse> {
    return ObservableResult.ofSuccess();
  }

  copyQuestion(assessmentUid: string, questionUid: string): ObservableResult<DiagnosticQuestionResponse> {
    return ObservableResult.ofSuccess();
  }

  deleteQuestion(): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  reorderQuestions(): ObservableResult<DiagnosticQuestionResponse[]> {
    return ObservableResult.ofSuccess();
  }

  getDiagnosticsLogicGoals(): ObservableResult<DiagnosticLogic[]> {
    return ObservableResult.ofSuccess();
  }

  updateLogicGoal(): ObservableResult<Diagnostics> {
    return ObservableResult.ofSuccess();
  }

  updateLogicGoalLevel(): ObservableResult<Diagnostics> {
    return ObservableResult.ofSuccess();
  }

  updateLearningGoalIcon(
    assessmentUid: string, goalUid: string, type: 'MAIN' | 'MAX' | 'MIN', iconData: LearningGoalIconRequest, file: File
  ): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateAssessmentChartIcon(
    assessmentUid: string, sectionUid: string, dynamicContentUid: string, type: 'MAX' | 'MIN', iconData: LearningGoalIconRequest, file: File
  ): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeLearningGoalIcon(assessmentUid: string, goalUid: string, type: 'MAIN' | 'MAX' | 'MIN'): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeAssessmentChartIcon( assessmentUid: string, sectionUid: string, dynamicContentUid, type: 'MAIN' | 'MAX' | 'MIN'): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addQuestionResponse(): ObservableResult<QuestionResponses> {
    return ObservableResult.ofSuccess();
  }

  getQuestionResponses(): ObservableResult<QuestionResponses> {
    return ObservableResult.ofSuccess(this.dummyQuestionResponse);
  }

  updateDiagnosticsRetake(assessmentUid: string, request: DiagnosticsRetake): ObservableResult<QuestionResponses> {
    return ObservableResult.ofSuccess();
  }

  randomizeQuestions(assessmentUid: string): ObservableResult<QuestionResponses> {
    return ObservableResult.ofSuccess();
  }

  deleteRandomizeQuestions(assessmentUid: string): ObservableResult<QuestionResponses> {
    return ObservableResult.ofSuccess();
  }

  getAssessmentSummary(assessmentUid: string): ObservableResult<AssessmentSummary> {
    return ObservableResult.ofSuccess(this.dummyAssessmentSummary);
  }

  getAssessmentSummaryAdmins(assessmentUid: string, userUid: string, languageCode?: string): ObservableResult<AssessmentSummary> {
    return ObservableResult.ofSuccess(this.dummyAssessmentSummary);
  }

  getDiagnosticsQuestions(assessmentUid: string): ObservableResult<DiagnosticQuestionResponse[]> {
    return ObservableResult.ofSuccess();
  }

  /** Reports. */

  createReportStandardSection(): ObservableResult<UpdateReportSectionResponse> {
    return ObservableResult.ofSuccess();
  }

  createReportStandardChartSection(): ObservableResult<UpdateReportSectionResponse> {
    return ObservableResult.ofSuccess();
  }

  createReportConditionalSection(): ObservableResult<UpdateReportSectionResponse> {
    return ObservableResult.ofSuccess();
  }

  createReportResourceSection(
    assessmentUid: string, request: CreateReportResourceSectionRequest, languageCode?: string
  ): ObservableResult<UpdateReportSectionResponse> {
    return ObservableResult.ofSuccess();
  }

  updateReportTitle(assessmentUid: string, request: UpdateReportTitleRequest, languageCode?: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateReportStandardSection(): ObservableResult<UpdateReportSectionResponse> {
    return ObservableResult.ofSuccess();
  }

  updateReportConditionalSection(): ObservableResult<UpdateReportSectionResponse> {
    return ObservableResult.ofSuccess();
  }

  updateReportResourceSection(
    assessmentUid: string, sectionUid: string, request: UpdateReportResourceSectionRequest, languageCode?: string
  ): ObservableResult<UpdateReportSectionResponse> {
    return ObservableResult.ofSuccess();
  }

  deleteReportSection(): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  reorderReportSections(): ObservableResult<Report> {
    return ObservableResult.ofSuccess();
  }

  getReport(): ObservableResult<GeneratedReport> {
    return ObservableResult.ofSuccess();
  }

  showReportTitle(assessmentUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  hideReportTitle(assessmentUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  showHideReportOrgLogo(assessmentUid: string, request: ShowReportLogoRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateReportMode(assessmentUid: string, mode: ReportMode): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  addReportResourceTag(assessmentUid: string, sectionUid: string, tagUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getAssessmentsResponses(): ObservableResult<MyPagesAssessmentsResponse[]> {
    return ObservableResult.ofSuccess();
  }

  deleteReportResourceTag(assessmentUid: string, sectionUid: string, tagUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

}
