/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ObservableResult } from '../../store';
import { RestClientService } from '../rest-client.service';
import { FileUploadService } from './file-upload.service';
import { TranslationService } from '../translation/translation.service';
import { HttpEvent, HttpEventType, HttpResponse, HttpUploadProgressEvent } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { FileNameWithUploadProgress, PresignResponseBody } from './file-upload.model';
import { getFileName } from '@app/app/shared/helpers/editor-transformer';

export class AwsFileUploadService implements FileUploadService {
  private uploadProgress = new Subject<FileNameWithUploadProgress[]>();
  private uploadedFiles: FileNameWithUploadProgress[] = [];
  castUploadProgress: Observable<FileNameWithUploadProgress[]>;

  constructor( private client: RestClientService, private translationService: TranslationService ) {
    this.castUploadProgress = this.uploadProgress.asObservable();
  }

  /**
   * @deprecated It uses endpoint which allows to upload anything to any directory – this endpoint should be removed
   */
  uploadFile( fileUrl: string, file: File, uuid4: string = '', withRaport: boolean = true): ObservableResult<void> {
    const presignUrl = Location.joinWithSlash(environment.apiRootUrl || '', 'cards/presign');
    const presignRequestBody = {
      filename: fileUrl,
      'content-length': file.size,
      method: 'PUT',
    };
    return this.client.post<PresignResponseBody>(presignUrl, presignRequestBody)
      .pipe(
        switchMap(( { body } ) =>
          withRaport ? this.uploadFileDirectly(body.URL, file as File, uuid4, true)
          : this.uploadFileDirectlyWithoutRaport(body.URL, file as File, true)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorSavingImage')))
      );
  }

  uploadFileDirectly( presignedUrl: string, file: File, uuid4: string = '', waitForOptimizer: boolean ): ObservableResult<void> {
    const uniqueFileName = getFileName(presignedUrl)
    this.uploadedFiles.push({ uniqueFileName: uniqueFileName, uploadedProgress: 0, uuid4: uuid4 })

    return this.client.putWithReportProgress<void>(presignedUrl, file, {}, { 'cache-control': 'public, max-age=31536000' }, null, true)
      .pipe(
        map(( event: HttpEvent<HttpUploadProgressEvent> ) => {
          if ( (event.type === HttpEventType.UploadProgress) && event.total ) {
            const uploadedProgress = Math.round(100 * event.loaded / event.total);
            const currentFile = this.uploadedFiles.find(( uploadedFile ) => uploadedFile.uniqueFileName === uniqueFileName);
            currentFile.uploadedProgress = uploadedProgress;
            this.uploadProgress.next(this.uploadedFiles);
          } else if ( event instanceof HttpResponse ) {
            this.uploadedFiles = this.uploadedFiles.filter(( uploadedFile ) => uploadedFile.uploadedProgress !== 100);
          }
        }),
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorSavingImage'))),
        delay(waitForOptimizer ? 1500 : 0) // optimizer is uploading other image sizes
      );
  }

  uploadFileDirectlyWithoutRaport(presignedUrl: string, file: File, waitForOptimizer: boolean): ObservableResult<void> {
    return this.client.put<void>(presignedUrl, file, {}, {'cache-control': 'public, max-age=31536000'})
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorSavingImage'))),
        delay(waitForOptimizer ? 1500 : 0) // optimizer is uploading other image sizes
      );
  }
}
