/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { ExternalContentSummary, MediaContent } from '../../../../models';
import { iframely } from '@iframely/embed.js';

@Component({
	selector: 'ptl-external-content-summary',
	templateUrl: './external-content-summary.component.html',
	styleUrls: ['./external-content-summary.component.scss']
})
export class ExternalContentSummaryComponent implements OnChanges, AfterViewInit {

	private _content: ExternalContentSummary | undefined;
	@Input()
	set content( value: ExternalContentSummary | undefined ) {
		if ( typeof value === 'string' ) {
			this._content = JSON.parse(decodeURIComponent(value));
		} else {
			this._content = value;
		}
	}

	get content() {
		return this._content;
	}

	@Input() canRemoveItem = true;

	/** Outputs removing of the component */
	@Output() removeContent = new EventEmitter<void>();

	/** Outputs the new position of the HTML element. */
	@Output() updateMediaPosition = new EventEmitter<string>();

	@Output() mediaContentReplaced = new EventEmitter<MediaContent>();

	@Output() regularLinkReplaced = new EventEmitter<string>();

	@ViewChild('oembedEl', { static: true }) public oembedEl: ElementRef | undefined;

	constructor( private renderer2: Renderer2 ) {
		setTimeout(() => {
			iframely.load();
		}, 500);
	}

	onCloseClick() {
		this.removeContent.next();
	}

	ngAfterViewInit() {
		if ( this.content && !!(this.content as ExternalContentSummary).html ) {
			this.parseAndAppendHtml((this.content as ExternalContentSummary).html);
		}
	}

	ngOnChanges( changes: SimpleChanges ) {
		setTimeout(() => {
			iframely.load();
		}, 500);
		if ( this.content && !!(this.content as ExternalContentSummary).html ) {
			// if contentHtml from previousValue is different from currentValue, remove el and append again
			if ( changes && changes.content && !!changes.content.previousValue &&
				((changes.content.previousValue as ExternalContentSummary).html
					!== (changes.content.currentValue as ExternalContentSummary).html) ) {
				const childElements = this.oembedEl?.nativeElement.children;
				for ( const child of childElements ) {
					this.renderer2.removeChild(this.oembedEl?.nativeElement, child);
				}
				this.parseAndAppendHtml((this.content as ExternalContentSummary).html);
			}
		}
	}

	parseAndAppendHtml( html: string ) {
		// createContextualFragment enables the execution of <script> if present in our html
		const fragment = document.createRange().createContextualFragment(html);
		this.oembedEl?.nativeElement.appendChild(fragment);
	}

	onUpdateMediaPosition( position: string ): void {
		this.updateMediaPosition.emit(position);
	}

	onReplaceMediaContent( media: MediaContent ): void {
		this.mediaContentReplaced.emit(media);
	}

	onReplaceRegularLink( link: string ): void {
		this.regularLinkReplaced.emit(link);
	}

	openUrl() {
		if ( this.content?.url ) {
			window.open(this.content.url, '_self');
		}
	}
}
