<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="qrcode-img">
  <qrcode
    *ngIf="qrCode"
    [qrdata]="qrCode"
    [alt]="qrCode"
    [ariaLabel]="ariaLabel"
    [elementType]="'img'"
    [errorCorrectionLevel]="'M'"
    [margin]="4"
    [scale]="1"
    [title]="title"
    [width]="300"
  ></qrcode>
</div>
