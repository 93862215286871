/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { TranslationService } from '../../services/translation/translation.service';
import { VersionHelper } from '../version.helper';

@Injectable()
export class DialogService {

  private newVersionEnabled = VersionHelper.newVersionEnabled()

  showConfirmDialog(content: string | HTMLElement, translationService: TranslationService, config?: ConfirmDialogConfig) {
    if (!config) {
      config = {};
    }
    return Swal.mixin({
      didOpen: (modal) => {
        const confirmBtn = modal.querySelector('.swal2-confirm') as HTMLInputElement | HTMLSelectElement;
        if (confirmBtn) {
          setTimeout(() => {
            confirmBtn.focus();
          }, 0)
        }
      }
    }).fire({
      text: content instanceof HTMLElement ? undefined : content,
      html: content instanceof HTMLElement ? content : undefined,
      icon: this.newVersionEnabled ? undefined : config.hideIcon ? undefined : 'warning',
      showCancelButton: true,
      reverseButtons: true,
      allowOutsideClick: false,
      closeButtonHtml: '<svg class="svg" role="img" aria-hidden="true"><use xlink:href="assets/sprite/sprite.svg#close"></use></svg>',
      showCloseButton: (config.showCloseButton !== undefined && config.showCloseButton !== null)
                        ? config.showCloseButton : this.newVersionEnabled,
      customClass: this.newVersionEnabled ? {popup: 'new-version f_inside-dialog'} : '',
      cancelButtonText: config.cancelBtnText ? config.cancelBtnText : translationService.getTranslation('global.button.cancel'),
      confirmButtonText: config.confirmBtnText ? config.confirmBtnText : translationService.getTranslation('global.button.confirm'),
    }).then((result) => {
      return result.value;
    });
  }

  showAlertDialog(text: string, translationService: TranslationService, config?: ConfirmDialogConfig) {
    if (!config) {
      config = {};
    }
    return Swal.fire({
      text: text,
      icon: this.newVersionEnabled ? undefined : config.hideIcon ? undefined : 'info',
      showCancelButton: false,
      reverseButtons: false,
      allowOutsideClick: false,
      closeButtonHtml: '<svg class="svg" role="img" aria-hidden="true"><use xlink:href="assets/sprite/sprite.svg#close"></use></svg>',
      showCloseButton: (config.showCloseButton !== undefined && config.showCloseButton !== null)
                        ? config.showCloseButton : this.newVersionEnabled,
      customClass: this.newVersionEnabled ? {popup: 'new-version'} : '',
      confirmButtonText: config.confirmBtnText ? config.confirmBtnText : translationService.getTranslation('global.button.ok'),
    }).then((result) => {
      return result.value;
    });
  }

}

export interface ConfirmDialogConfig {
  hideIcon?: boolean;
  showCloseButton?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
}
