<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ptl-verification-template
  [title]="'translations.public.title.verifyRecoveryEmail' | transloco"
  [buttonName]="'translations.public.button.verifyRecoveryEmail' | transloco"
  [showButton]="true"
  (buttonClick)="verifyRecoveryEmail()">
</ptl-verification-template>

