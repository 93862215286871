<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<ng-container *ngIf="!!card">
  <mat-card
    class="card"
    (click)="onCardClick()"
    (keydown.enter)="onCardClick()"
    (contextmenu)="onContextMenu($event)"
    tabindex="0"
    [attr.aria-label]="('translations.global.ariaLabels.open' | transloco: {title: cardTitle})"
    [ngStyle]="{'cursor': inHomeLayoutEditor ? 'default' : ''}">
    <div class="context-menu"
         [ngStyle]="{ 'left' : contextMenuPosition.x, 'top' : contextMenuPosition.y }"
         [matMenuTriggerFor]="contextMenu"></div>
    <mat-menu #contextMenu="matMenu">
      <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="onOpenInNewTab()">
          <span>{{ 'translations.openPlaylistInANewTab' | transloco }}</span>
        </button>
      </ng-template>
    </mat-menu>
    <div
      class="card__center"
      [class.card__center--slim]="size === 'SLIM'"
    >
      <ng-container *ngIf="card.imageUrl || card.thumbnail || card.image; else placeholderImage">
        <img
          class="center__image"
          [class.center__image--slim]="size === 'SLIM'"
          alt="" width="266" height="150"
          [src]="card.thumbnail ? card.thumbnail : (card.image ? card.image : card.imageUrl.medium)"/>
      </ng-container>
      <ng-template #placeholderImage>
        <img
          class="center__empty-image-placeholder"
          alt="" width="266" height="150"
          [src]="'/assets/card-placeholders/card-placeholder-' + ((index % 4) + 1) + '.png'"
        >
      </ng-template>
    </div>
    <ptl-featured-card-bottom
      [authorDetails]="card.authorDetails"
      [likeInfoDetails]="card.likes"
      [playlistUid]="card.playlistId ? card.playlistId : card._id"
      [inHomeLayoutEditor]="inHomeLayoutEditor"
      [treatAsPlaylistSectionCard]="treatAsPlaylistSectionCard"
      [totalItemsCount]="card.totalCardsCount"
      [completedItemsCount]="card.completedCardsCount"
      [started]="card.started || !!card.startedOn"
      [title]="cardTitle"
      [status]="cardStatus"
      [syndicated]="card.syndicated"
      [description]="cardSubheader"
      [favorite]="card.favorite"
      [completed]="card.completed"
      [resourceType]="'PLAYLIST'"
      [type]="type"
      [size]="size"
      [isAdmin]="isAdmin"
      [permission]="card.permission"
      [isPublic]="disabled"
      (membersClicked)="onMembersPage()"
      (editClicked)="onEditPage()"
      (duplicate)="onDuplicateClick()"
      (remove)="onRemoveClick()">
    </ptl-featured-card-bottom>
  </mat-card>
</ng-container>
