/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ptl-skeleton-appointment-list-item',
  templateUrl: './skeleton-appointment-list-item.component.html',
  styleUrls: ['./skeleton-appointment-list-item.component.scss'],
})
export class SkeletonAppointmentListItemComponent {
  @Input() items: number[] = [];
}
