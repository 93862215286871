/*
 * Copyright (C) 2024 - present by Potentially
 *
 * Please see distribution for license.
 */

import { RoleFilterTypes } from '../models/explore-filter.model';
import { SidebarSection } from '@app/app/shared/models/sidebar-tree/sidebar-tree.model';

export const INITIAL_EXPLORE_STATE: ExploreStateModel = {
  termFilter: '',
  roleFilters: [RoleFilterTypes.ORGANIZATION, RoleFilterTypes.MEMBERS],
  categoryFilter: undefined,
  categoryUidsQueryParam: undefined,
  exploreSideBarSections: undefined,
};

export interface ExploreStateModel {
  termFilter?: string;
  roleFilters: RoleFilterTypes[];
  categoryFilter?: string;
  categoryUidsQueryParam?: string[];
  exploreSideBarSections?: SidebarSection[];
}
