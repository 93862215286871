/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { DialogService } from '@app/app/shared/helpers/dialog/dialog.service';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '@app/app/shared/services/toast-notifications/toast-service';
import { SnackbarHelper } from '../../helpers/snackbar-helper';
import { TranslationService } from '../../services/translation/translation.service';
import {
  EventReminder,
  EventReminderRequest
} from '../../../page-modules/resource/store/admin/resource-event-admin.state.model';
import {
  EVENT_CARDS_DATA_SERVICE,
  EventCardsDataService
} from '../../../page-modules/event-cards/services/data.service';
import { ResourceAdminState } from '../../../page-modules/resource/store/admin/resource-admin.state';
import * as ResourceAdminActions from '../../../page-modules/resource/store/admin/resource-admin.actions';
import { Resource } from '../../models';

@Component({
  selector: 'ptl-event-reminders',
  templateUrl: './event-reminders.component.html',
  styleUrls: ['./event-reminders.component.scss'],
})
export class EventCardsRemindersComponent implements OnInit, OnDestroy {

  @Select(ResourceAdminState.resource)
  private resource$: Observable<Resource>;

  @Input() editingEnabled: boolean;
  @Input() enrollment = false;
  @Output() enrollmentRemindersUpdated = new EventEmitter<EventReminder[]>();
  /** Emits reminders tickets was modified or saved. */
  @Output() remindersWasUpdatedOrSaved = new EventEmitter<boolean>();

  remindersEnabled = false;
  timeList: EventReminder[] = [];
  remindersList: number[] = [];
  isButtonClicked = false;
  dataHasUpdated: boolean;

  private resource: Resource;
  private contentSubscription: Subscription;
  private eventRemindersFromState: EventReminder[];
  private contentId: string;

  constructor(
    private store: Store,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
    private dialogService: DialogService,
    @Inject(EVENT_CARDS_DATA_SERVICE) private eventCardsDataService: EventCardsDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService
  ) {
    this.createHoursList();
  }

  ngOnInit() {
    this.onDataSave();
    this.contentSubscription = this.resource$.subscribe((resource) => {
      this.resource = resource;
      this.eventRemindersFromState = this.resource?.reminders;
      this.contentId = this.resource?._id;
      this.setStateFromContentSubscription();
    });
  }

  ngOnDestroy() {
    this.contentSubscription?.unsubscribe();
  }

  setStateFromContentSubscription() {
    if (this.eventRemindersFromState) {
      this.remindersList = [];
      this.getSelectedRemindersList(this.eventRemindersFromState);
      this.remindersEnabled = this.remindersList.length > 0;
    }
  }

  switchRemindersToggle(e) {
    if (this.remindersEnabled) {
      this.resetReminders(e);
    } else {
      this.addNewReminder();
      this.remindersEnabled = true;
    }
  }

  addNewReminder() {
    this.onDataUpdate();
    this.remindersList.push(undefined);
  }

  removeReminder(index: number) {
    this.onDataUpdate();
    this.remindersList.splice(index, 1);
  }

  onReminderSelect(event: MatSelectChange, index: number) {
    this.onDataUpdate();
    this.remindersList[index] = event.value;
  }

  resetReminders(e) {
    if (this.anyValueInReminderArray()) {
      e.source.checked = true;
      this.dialogService.showConfirmDialog(
        this.translationService.getTranslation('dialog.title.eventRemindersDisable'),
        this.translationService
      ).then(confirmed => {
        if (confirmed) {
          this.remindersList = [];
          this.update(false);
        }
      });
    } else {
      this.remindersList = [];
      this.remindersEnabled = false;
      this.onDataSave();
    }
  }

  update(remindersEnabled: boolean = true) {
    const isValidData = this.validateReminders();
    if (!isValidData) {
      SnackbarHelper.showTranslatableSnackBar(
        this.ngZone,
        this.snackBar,
        this.translationService,
        'selectEventReminder'
      );
      return;
    }

    this.isButtonClicked = true;
    const request: EventReminderRequest = {
      reminders: [],
    };
    for (const item of this.remindersList) {
      request.reminders.push({
        amount: this.timeList[item].amount,
        timeUnit: this.timeList[item].timeUnit,
      });
    }
    if(this.enrollment === false) {
      this.eventCardsDataService
        .setEventReminders(this.contentId, request)
        .subscribe(({ isSuccess, value, error }) => {
          this.isButtonClicked = false;
          if (!isSuccess) {
            this.toastService.showFail(error);
            return;
          }
          this.remindersEnabled = remindersEnabled;
          this.onDataSave();
          this.store.dispatch(new ResourceAdminActions.SetEventReminders(value.reminders));
          this.toastService.showSuccess(this.translationService.getTranslation('successes.successUpdateEventReminders'));
        });
    } else {
      this.enrollmentRemindersUpdated.emit(request.reminders);
    }
  }

  cancel() {
    this.isButtonClicked = true;
    this.remindersList = [];
    this.remindersEnabled = false;
    this.setStateFromContentSubscription();
    this.onDataSave();
    this.isButtonClicked = false;
  }

  onDataSave() {
    this.dataHasUpdated = false;
    this.remindersWasUpdatedOrSaved.emit(this.dataHasUpdated);
  }

  onDataUpdate() {
    this.dataHasUpdated = true;
    this.remindersWasUpdatedOrSaved.emit(this.dataHasUpdated);
  }

  shouldDisplayData() {
    return this.remindersEnabled && this.editingEnabled;
  }

  private validateReminders() {
    if (this.remindersList.length === 0) {
      return true;
    }
    for (const item of this.remindersList) {
      if (item === undefined) {
        return false;
      }
    }
    return true;
  }

  private createHoursList() {
    this.timeList.push(
      {
        amount: 15,
        name: 'minutes',
        timeUnit: 'MINUTES',
      },
      {
        amount: 30,
        name: 'minutes',
        timeUnit: 'MINUTES',
      },
      {
        amount: 1,
        name: 'hour',
        timeUnit: 'HOURS',
      },
      {
        amount: 6,
        name: 'hours',
        timeUnit: 'HOURS',
      },
      {
        amount: 12,
        name: 'hours',
        timeUnit: 'HOURS',
      },
      {
        amount: 1,
        name: 'day',
        timeUnit: 'DAYS',
      },
      {
        amount: 2,
        name: 'days',
        timeUnit: 'DAYS',
      },
      {
        amount: 3,
        name: 'days',
        timeUnit: 'DAYS',
      },
      {
        amount: 4,
        name: 'days',
        timeUnit: 'DAYS',
      },
      {
        amount: 5,
        name: 'days',
        timeUnit: 'DAYS',
      },
      {
        amount: 6,
        name: 'days',
        timeUnit: 'DAYS',
      },
      {
        amount: 1,
        name: 'week',
        timeUnit: 'WEEKS',
      },
      {
        amount: 2,
        name: 'weeks',
        timeUnit: 'WEEKS',
      }
    );
  }

  private anyValueInReminderArray(): boolean {
    return this.remindersList.findIndex((value) => value !== undefined) !== -1;
  }

  private getSelectedRemindersList(reminders: EventReminder[]) {
    for (const reminder of reminders) {
      const selectedReminder = this.timeList.findIndex(
        (value) =>
          value.timeUnit === reminder.timeUnit &&
          value.amount === reminder.amount
      );
      if (selectedReminder !== undefined) {
        this.remindersList.push(selectedReminder);
      }
    }
  }

  trackByFn(index: number) {
    return index;
  }
}
