<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="spectrum-chart__content">

  <div class="spectrum-chart__content-inner" *ngIf="!showStaticData && piReport">
    <div class="chart-item" *ngFor="let trait of chartData; let i = index;">
      <ng-container *ngIf="traitMapping[trait.name]">
        <div class="chart-left-info" tabindex="0" >
          <div class="chart-title">{{ traitMapping[trait.name].left.title }}</div>
          <div class="chart-description">{{ traitMapping[trait.name].left.description }}</div>
        </div>
        <div class="chart-progress">
          <div class="progress-line">
            <img
              alt="Progress"
              class="progress"
              [src]="traitMapping[trait.name].imageUrl"
              [style.left]="'calc(' + trait.value * 100 + '% - 0.938rem)'">
          </div>
        </div>
        <div class="chart-right-info" tabindex="0" >
          <div class="chart-title">{{ traitMapping[trait.name].right.title }}</div>
          <div class="chart-description">{{ traitMapping[trait.name].right.description }}</div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="spectrum-chart__static-content" *ngIf="showStaticData">
    <img src="../assets/charts/spectrum.png" alt="spectrum chart" />
  </div>

</div>
