<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<div
  class="workspaces-nav">
  <div class="workspaces-nav-container" role="menubar" [attr.aria-label]="('translations.global.ariaLabels.workspaceMenu' | transloco )">
    <ul class="workspaces-nav-container-list" role="menu">
      <ng-container *ngFor="let workspace of userData?.workspaces">
        <ng-container *ngIf="workspace.type !== 'FOLIO' || organization?.type === 'FOLIO'">
          <li
            class="workspaces-nav-container-list-item"
            [class.is_active]="workspace.domain === domain"
            tabindex="0"
            role="menuitem"
            [attr.aria-label]="('translations.global.ariaLabels.switchToWorkspace' | transloco: {workspace: workspace.name})"
            (click)="loadWorkspace(workspace.domain, workspace.organizationUid)"
            (keydown.enter)="loadWorkspace(workspace.domain, workspace.organizationUid)"
          >
            <div class="workspaces-nav-container-list-item-link"
                 [style.backgroundImage]="'url(' + workspace.logo + ')'"></div>
          </li>
        </ng-container>
      </ng-container>
      <li class="workspaces-nav-container-list-divider" aria-hidden="true"></li>

      <li
        *ngIf="organization?.contentStoreEnabled && ((isUserAdmin$ | async) || (isUserSuperAdmin$ | async))"
        (click)="navigateTo('/content-store')" (keydown.enter)="navigateTo('/content-store')"
        class="workspaces-nav-container-list-item static-list-item"
        role="menuitem"
        [routerLinkActive]="'is_active'"
        tabindex="0"
        [routerLink]="'/content-store'"
        [attr.aria-label]="('translations.global.ariaLabels.openContentStore' | transloco )">
        <div class="workspaces-nav-container-list-item-link"
             [style.backgroundImage]="'url(https://static.yuna.potential.ly/public/images/icons/potentially_logo.png)'"></div>
      </li>
      <li
        class="workspaces-nav-container-list-item static-list-item"
        (click)="navigateTo('/folio')" (keydown.enter)="navigateTo('/folio')"
        [routerLinkActive]="'is_active'"
        role="menuitem"
        tabindex="0"
        [routerLink]="'/folio'"
        [attr.aria-label]="('translations.global.ariaLabels.openFolio' | transloco )">
        <div class="workspaces-nav-container-list-item-title" *ngIf="isFolioMainPage">
          {{'translations.sideNav.title.me' | transloco }}
        </div>
        <div class="workspaces-nav-container-list-item-link folio-link" *ngIf="!isFolioMainPage"
             [style.backgroundImage]="'url(' + userData?.imageUrl + ')'">
        </div>
      </li>
    </ul>
  </div>
</div>
