import { BooleanFilter } from '../../../../../shared/models/admin/boolean-filters.model';
import {
  PaginationFilter
} from '@app/app/page-modules/admin/components/members/store/members.state.model';

export class UpdateSelectedMembers {
  static readonly type = '[Members] Update selected members';

  constructor(public selectedMembers: string[]) {
  }
}

export class ClearSelectedMembers {
  static readonly type = '[Members] Clear selected members';
}

export class UpdateMemberFilters {
  static readonly type = '[Members] Update member filters';

  constructor(public filters: BooleanFilter[]) {
  }
}

export class UpdateMemberPaginationFilters {
  static readonly type = '[Members] Update member pagination filters';

  constructor(public filter?: PaginationFilter) {
  }
}


export class UpdateCardFilters {
  static readonly type = '[Members] Update card filters';

  constructor(public cardUid: string, public filters: BooleanFilter[]) {
  }
}

export class UpdateCardPaginationFilters {
  static readonly type = '[Members] Update card pagination filters';

  constructor(public cardUid: string, public filter?: PaginationFilter) {
  }
}

export class UpdatePlaylistFilters {
  static readonly type = '[Members] Update playlist filters';

  constructor(public playlistUid: string, public filters: BooleanFilter[]) {
  }
}

export class UpdatePlaylistPaginationFilters {
  static readonly type = '[Members] Update playlist pagination filters';

  constructor(public playlistUid: string, public filter?: PaginationFilter) {
  }
}

export class UpdatePlaylistReviewFilters {
  static readonly type = '[Members] Update playlist review filters';

  constructor(public playlistUid: string, public filters: BooleanFilter[]) {
  }
}

export class UpdateCardReviewFilters {
  static readonly type = '[Members] Update card review filters';

  constructor(public cardUid: string, public filters: BooleanFilter[]) {
  }
}

export class UpdateHighlightedMemberId {
  static readonly type = '[Members] Update highlighted member Id';

  constructor(public highlightedMemberId: string) {
  }
}
