import { Component, Inject, Input, OnInit } from '@angular/core';
import { DATA_FORM_DATA_SERVICE, DataFormDataService } from '../../../services/data-form/data-form-data.service';
import { DataFormSummary } from '../../../models/data-form/data-form.model';
import { DataFormDialogService } from '../../../services/data-form/data-form-dialog.service';
import { TranslationService } from '../../../services/translation/translation.service';

@Component({
  selector: 'ptl-data-forms-error',
  templateUrl: './data-forms-error.component.html',
  styleUrls: [],
})
export class DataFormsErrorComponent implements OnInit {

  @Input() dataFroms: string[];
  summaries: DataFormSummary[] = [];
  displayErrorPanel = false;

  constructor(
    private translationService: TranslationService,
    private dataFormDialogService: DataFormDialogService,
    @Inject(DATA_FORM_DATA_SERVICE) private dataFormDataService: DataFormDataService,
  ) {
  }

  ngOnInit() {
    this.dataFormDataService.getSummaries(this.dataFroms).subscribe(
        ({ isSuccess, value }) => {
          if (isSuccess) {
            this.summaries = value;
            if (this.summaries.length > 0) {
              this.dataFormDialogService.displayDialog(this.summaries, false, () => undefined);
            } else {
              this.displayErrorPanel = true;
            }
          } else {
            this.displayErrorPanel = true;
          }
        }
    );
  }
}
