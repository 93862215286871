/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TRANSLATIONS_DATA } from '../../constants/constants';

export const LANGUAGE_KEY = 'language_key';
export const ORGANIZATION_LANGUAGE_KEY = 'organization_language_key';
export const CONTENT_LANGUAGE_CODE = 'content_language_code';
export let DEFAULT_LANGUAGE_CODE = 'en';
export const AVAILABLE_LANGUAGES = ['en', 'cy', 'de', 'ar'];

@Injectable()
export class TranslationService {
  private language: string | null | undefined;

  constructor(
    private translocoService: TranslocoService
  ) {
  }

  setLanguage(lang: string) {
    this.translocoService.setDefaultLang(lang);
    this.translocoService.setActiveLang(lang);
    localStorage.setItem(LANGUAGE_KEY, lang);
  }

  getLanguage() {
    this.language = localStorage.getItem(LANGUAGE_KEY);
    if (!this.language) {
      this.language = DEFAULT_LANGUAGE_CODE;
      this.setLanguage(this.language);
    }
    return this.language;
  }

  setOrganizationLanguage(lang: string) {
    localStorage.setItem(ORGANIZATION_LANGUAGE_KEY, lang);
  }

  setOrganizationDefaultLanguage(lang: string) {
    DEFAULT_LANGUAGE_CODE = lang;
  }

  getOrganizationLanguage() {
    const lang = localStorage.getItem(ORGANIZATION_LANGUAGE_KEY);
    return lang ? lang : DEFAULT_LANGUAGE_CODE;
  }

  getTranslation(key: string): string {
    const translationKey = 'translations.' + key;
    let message = this.translocoService.translate(translationKey);
    if (translationKey === message) {
      message = this.manualTranslate(translationKey);
    }
    return message;
  }


  manualTranslate(key: string) {
    let language = localStorage.getItem(LANGUAGE_KEY);
    if (!language) {
      language = DEFAULT_LANGUAGE_CODE;
    }
    let translationJson = TRANSLATIONS_DATA[language];
    const keys = key.split('.');
    let index = 1;
    while (index < keys.length - 1) {
      if (translationJson == null) {
        break;
      }
      translationJson = translationJson[keys[index]];
      index++;
    }
    if (translationJson != null) {
      return translationJson[keys[index]] ? translationJson[keys[index]] : key;
    } else {
      return key;
    }
  }

  getSideNavSectionTranslation(slug: string, title: string) {
    const key = 'sideNav.title.' + slug;
    const translation = this.getTranslation(key);
    if (translation && translation.indexOf('translations.sideNav.title.') === -1) {
      return translation;
    }
    return title;
  }
}
