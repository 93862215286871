import { Injectable } from '@angular/core';
import { IMessage } from '@stomp/stompjs';
import { Observable } from 'rxjs';
import { WebSocketTopic } from '../../models/websocket/websocket.model';
import { WebsocketService } from './websocket.service';

@Injectable()
export class MockWebsocketService implements WebsocketService {
  openConnection(): void {
    return;
  }

  subscribeToTopic(topic: WebSocketTopic): Observable<IMessage> {
    return Observable.create();
  }

  closeConnection(): void {
    return;
  }
}
