import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BooleanFilterType } from '../../../../../../shared/models/admin/members.model';

@Component({
  selector: 'ptl-add-member-filter',
  templateUrl: './add-member-filter.component.html',
  styleUrls: ['./add-member-filter.component.scss'],
})
export class AddMemberFilterComponent {

  @Input() name: string;
  @Input() description: string;
  @Input() type: BooleanFilterType;
  @Input() disabled: boolean;
  @Output() addFilter = new EventEmitter<BooleanFilterType>();

  onAddFilter() {
    this.addFilter.emit(this.type);
  }
}
