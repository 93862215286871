/*
 * Copyright (C) 2018 - present by Potentially
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { TranslationService } from '@app/app/shared/services/translation/translation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PAGES_DATA_SERVICE, PageDataService } from '../../../services/data.service';
import { Section } from '../../../../../shared/models/page/page.model';
import { UserAuthState } from '../../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { Organization } from '../../../../../shared/models';
import { takeUntil } from 'rxjs/operators';
import { developmentDomains } from '../../../../../shared/helpers/development-domains.helper';


@Component({
  selector: 'ptl-page-tableau-section',
  templateUrl: './page-tableau-section.component.html',
  styleUrls: ['./page-tableau-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTableauSectionComponent implements OnInit, OnDestroy {

  @Input() pageUid: string;

  @Input() section: Section;

  @Input() languageCode: string;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  iframeSrc: string | null;
  dataIsLoaded: boolean;

  private isDevelopmentDomains: boolean;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private store: Store,
    private translationService: TranslationService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    @Inject(PAGES_DATA_SERVICE) private pageDataService: PageDataService,
  ) {
  }

  ngOnInit() {
    this.dataIsLoaded = false;

    this.organizationData$.pipe(
      takeUntil(this.subscriptionEnd$)
    ).subscribe((organization) => {
      this.isDevelopmentDomains = developmentDomains(organization?.domain);
      this.getTableauUrl();
    });

  }

  ngOnDestroy() {
    this.subscriptionEnd$?.emit();
  }

  private getTableauUrl() {
    const params = ':embed=yes&:toolbar=yes&:toolbar=top&:refresh=yes&PasswordParameter=Enter%20password&:customViews=no';
    let server = 'charts.jisc.ac.uk';
    let viewUrl = 't/EDW/views/DigitalCapabilitiesDashboard/Welcomepage';

    if (this.isDevelopmentDomains) {
      server = 'uat.charts.jisc.ac.uk';
      viewUrl = 't/EDWUAT/workbooks/721/views';
    }

    if (this.pageUid) {
      this.getTableauToken(server, params, viewUrl);
    }
  }

  private getTableauToken(server: string, params: string, viewUrl: string) {
    this.pageDataService.getTableauToken(this.pageUid, this.languageCode).subscribe(({isSuccess, value}) => {
      if (isSuccess) {
        const token = value.token;
        this.iframeSrc = this.getPreviewUrl(`https://${server}/trusted/${token}/${viewUrl}?${params}`) as string;
      } else {
        this.iframeSrc = null;
      }
      this.cdr.detectChanges();
      this.dataIsLoaded = true;
    });
  }

  private getPreviewUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
