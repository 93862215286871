/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Organization, ResourceAuthorDetails, ResourcePermission, ResourcePublishedStatus, ResourceType } from '../../../models';
import { LikeInfoDetails } from '../../../models/like-info/like-info.model';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'ptl-featured-card-bottom',
  templateUrl: './featured-card-bottom.component.html',
  styleUrls: ['./featured-card-bottom.component.scss'],
})
export class FeaturedCardBottomComponent {

  /** The card header to display. */
  @Input() title: string;
  /** The card description to display. */
  @Input() description: string;
  /** Defines if the card is favorite. */
  @Input() favorite: boolean;
  /** Defines the user's progress with the card resource. */
  @Input() totalItemsCount: number;
  @Input() completedItemsCount: number;
  @Input() completed: boolean;
  @Input() started: boolean;
  @Input() resourceType: ResourceType;
  @Input() type: string;
  @Input() size: CardSize;
  @Input() permission: ResourcePermission;
  @Input() inHomeLayoutEditor: boolean;
  @Input() treatAsPlaylistSectionCard: boolean;
  @Input() authorDetails: ResourceAuthorDetails;
  @Input() likeInfoDetails: LikeInfoDetails;
  @Input() playlistUid: string;
  @Input() status: ResourcePublishedStatus;
  @Input() syndicated: boolean;
  @Input() isAdmin: boolean;
  @Input() isPublic = false;

  /** Emits on duplicate click. */
  @Output() duplicate = new EventEmitter<void>();
  /** Emits on members click. */
  @Output() membersClicked = new EventEmitter<void>();
  /** Emits on edit click. */
  @Output() editClicked = new EventEmitter<void>();
  /** Emits on menu item remove click. */
  @Output() remove = new EventEmitter<void>();


  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  onDuplicateClick() {
    this.duplicate.next();
  }

  onMembersClick() {
    this.membersClicked.emit();
  }

  onEditClick() {
    this.editClicked.emit();
  }

  onRemoveClick(event: MouseEvent) {
    event.stopPropagation();
    this.remove.next();
  }

  getOrgName(): string {
    const orgName = this.authorDetails.organization;
    const changedOrgName = this.authorDetails.displayConfig.externalAuthor?.name;

    return changedOrgName ? changedOrgName : orgName;
  }

  getOrgImageUrl(): string {
    const orgImageUrl = this.authorDetails.orgLogoUrl;
    const uploadedOrgImageUrl = this.authorDetails.displayConfig.externalAuthor?.image;

    return uploadedOrgImageUrl ? uploadedOrgImageUrl : orgImageUrl;
  }
}

type CardSize = 'STANDARD' | 'SLIM';
