/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  EVENT_CARDS_DATA_SERVICE,
  EventCardsDataService
} from '../../../page-modules/event-cards/services/data.service';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import {
  EventCardTime,
  EventTickets
} from '../../../page-modules/resource/store/admin/resource-event-admin.state.model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { cloneDeep } from 'lodash-es';
import { EventCardsHelper } from '@app/app/shared/helpers/event-cards-helper';
import { TOAST_NOTIFICATION_SERVICE, ToastService } from '@app/app/shared/services/toast-notifications/toast-service';
import { SnackbarHelper } from '../../helpers/snackbar-helper';
import { TranslationService } from '../../services/translation/translation.service';
import { ResourceAdminState } from '../../../page-modules/resource/store/admin/resource-admin.state';
import * as ResourceAdminActions from '../../../page-modules/resource/store/admin/resource-admin.actions';
import { EventTicketsDetails, EventTime, Resource } from '../../models';
import { TimeZoneDisplay, TimezoneHelper, TimeZoneMap } from '@app/app/shared/helpers/timezone/timezone-helper';

const DATE_PICKER_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

class DateRangeAdapter extends NativeDateAdapter {

  public override format(date: Date, displayFormat: string): string {
    return moment(date).format(displayFormat);
  }
}

@Component({
  selector: 'ptl-event-date',
  templateUrl: './event-date.component.html',
  styleUrls: ['./event-date.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: DateRangeAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_FORMATS,
    },
  ],
})
export class EventCardsDateComponent implements OnInit, OnDestroy {

  @Select(ResourceAdminState.resource)
  private resource$: Observable<Resource>;

  @Input() enrollmentTime: EventCardTime;
  @Input() enrollmentTickets: EventTicketsDetails;
  @Input() editingEnabled: boolean;
  @Input() enrollment = false;
  @Output() enrollmentDateTimeUpdated = new EventEmitter<EventTime>();
  /** Emits when date was modified or saved. */
  @Output() dateWasUpdatedOrSaved = new EventEmitter<boolean>();

  dateError = false;
  timeError = false;
  startedTimeError = false;
  startedDateError = false;
  minDate = new Date();
  startedDate: Date;
  endedDate: Date;
  startedTime: string;
  endedTime: string;
  timeSelect: string[] = [];
  timeZones: TimeZoneDisplay[] = TimezoneHelper.getTimeZoneDisplayList();
  timeZonesMapList: TimeZoneMap = TimezoneHelper.getMapFormattedDisplayTimezoneList();
  selectedTimezone: string;
  isButtonClicked = false;
  endDateActive = false;
  dataHasUpdated: boolean;
  ticketsTimeError = false;


  private resource: Resource;
  private preventDateCorrection = false;

  private eventTimeFromState: EventCardTime;
  private contentId: string;
  private eventTicketsFromState: EventTickets | EventTicketsDetails;
  private contentSubscription: Subscription;

  constructor(
    private store: Store,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
    @Inject(EVENT_CARDS_DATA_SERVICE) private eventCardsDataService: EventCardsDataService,
    @Inject(TOAST_NOTIFICATION_SERVICE) private toastService: ToastService
  ) {
  }

  ngOnInit() {
    if (!this.enrollment) {
      this.onDataSave();
      this.buildTimeSelect();
      this.setDefaultState();

      this.contentSubscription = this.resource$.subscribe(resource => {
        this.resource = resource;
        this.eventTimeFromState = this.resource?.time;
        this.eventTicketsFromState = this.resource?.tickets;
        this.contentId = this.resource?._id;
        this.validateTicketsTime();
        this.setStateFromContentSubscription();
      });
    } else {
      this.eventTimeFromState = this.enrollmentTime;
      this.eventTicketsFromState = this.enrollmentTickets;
      this.buildTimeSelect();
      this.setDefaultState();
      this.setStateFromContentSubscription();
      this.validateTicketsTime();
    }
  }

  ngOnDestroy() {
    this.contentSubscription?.unsubscribe();
  }

  setDefaultState() {
    const date = new Date().toString();
    this.selectedTimezone = date.substring(date.indexOf('GMT'), date.indexOf('GMT') + 8);
    this.setDefaultDateAndTime();
  }

  setStateFromContentSubscription() {
    if (this.eventTimeFromState) {
      this.setSelectedDates(this.eventTimeFromState);
      if (this.eventTimeFromState.end) {
        this.endDateActive = true;
      }
    } else if (this.contentId) {
      this.update(false);
    }
  }

  onDateInputFocus() {
    this.preventDateCorrection = true;
  }

  onDateInputBlur() {
    this.preventDateCorrection = false;
    this.onDateChanged();
  }

  onDateChanged(startDateChanged: boolean = false) {
    this.onDataUpdate();
    if (this.preventDateCorrection) {
      return;
    }
    this.validateDateInputs(startDateChanged);
  }

  onTimeUpdate(startTimeChanged: boolean = false) {
    this.onDataUpdate();
    if (this.startedTime && this.endedTime) {
      this.validateTimeInputs(startTimeChanged, true);
    }
    if (!this.dateError && !this.timeError) {
      this.validateTicketsTime();
    }
  }

  update(showToast: boolean = true) {
    const isValidData = this.validateSendingData();
    if (!isValidData) {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'selectEventStartDate');
      return;
    }
    if (this.dateError || this.startedDateError) {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'eventStartDateError');
      return;
    }
    if (this.timeError || this.startedTimeError) {
      SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'eventStartTimeError');
      return;
    }
    if (this.ticketsTimeError) {
      SnackbarHelper.showTranslatableSnackBar(
        this.ngZone,
        this.snackBar,
        this.translationService,
        'eventStartTimeOverlapsOnEventTicketsError'
      );
      return;
    }
    const request = {
      ...this.getRequestDate(),
      timeZone: this.selectedTimezone,
    };

    this.isButtonClicked = true;
    if (this.enrollment === false) {
      this.eventCardsDataService.setEventCardDates(this.contentId, request)
        .subscribe(({ isSuccess, value, error }) => {
          this.isButtonClicked = false;
          if (!isSuccess) {
            this.toastService.showFail(error);
            return;
          }
          this.onDataSave();
          this.store.dispatch(new ResourceAdminActions.SetEventTime(value.time));
          if (showToast) {
            this.toastService.showSuccess(this.translationService.getTranslation('successes.successUpdateEventTimes'));
          }
        });
    } else {
      this.enrollmentDateTimeUpdated.emit(request);
      this.isButtonClicked = false;
    }
  }

  cancel() {
    this.isButtonClicked = true;
    this.dateError = false;
    this.timeError = false;
    this.startedTimeError = false;
    this.startedDateError = false;
    this.ticketsTimeError = false;
    this.endDateActive = false;
    this.startedDate = undefined;
    this.endedDate = undefined;
    this.startedTime = undefined;
    this.endedTime = undefined;
    this.selectedTimezone = undefined;
    this.setDefaultState();
    this.setStateFromContentSubscription();
    this.onDataSave();
    this.isButtonClicked = false;
  }

  onDataSave() {
    this.dataHasUpdated = false;
    this.dateWasUpdatedOrSaved.emit(this.dataHasUpdated);
  }

  onDataUpdate() {
    this.dataHasUpdated = true;
    this.dateWasUpdatedOrSaved.emit(this.dataHasUpdated);
  }

  showEndDateForm() {
    this.onDataUpdate();
    this.endDateActive = true;
    this.onDateChanged();
    this.onTimeUpdate();
  }

  hideEndDateForm() {
    this.onDataUpdate();
    this.endDateActive = false;
    this.onDateChanged();
    this.onTimeUpdate();
  }

  private setDefaultDateAndTime() {
    const current = moment();
    const roundUpNextHour = current.minute() || current.second() || current.millisecond() ?
      current.add(1, 'hour').startOf('hour') : current.startOf('hour');
    const startDate = cloneDeep(roundUpNextHour);
    const endDate = cloneDeep(roundUpNextHour).add(1, 'hour');
    this.startedDate = new Date(startDate.format('YYYY-MM-DD'));
    this.startedDate.setHours(0, 0, 0, 0);
    this.endedDate = new Date(endDate.format('YYYY-MM-DD'));
    this.endedDate.setHours(0, 0, 0, 0);
    this.startedTime = startDate.format('HH:mm');
    this.endedTime = endDate.format('HH:mm');
  }

  private setSelectedDates(time: EventCardTime) {
    if (time.timeZone === 'GMT') {
      this.selectedTimezone = 'GMT+0000';
    } else {
      this.selectedTimezone = time.timeZone.replace(':', '');
    }
    this.setSelectedDateAndTime(time.start, 'start');
    this.setSelectedDateAndTime(time.end, 'end');
  }

  private setSelectedDateAndTime(selectedTime: string, type: string) {
    if (!selectedTime) {
      return;
    }
    const dateArray = selectedTime.split(' ');
    if (type === 'start') {
      this.startedDate = new Date(dateArray[0]);
      this.startedDate.setHours(0, 0, 0, 0);
      this.startedTime = dateArray[1];
    } else {
      this.endedDate = new Date(dateArray[0]);
      this.endedDate.setHours(0, 0, 0, 0);
      this.endedTime = dateArray[1];
    }
  }

  private buildTimeSelect() {
    const minutes = ['00', '15', '30', '45'];
    for (let i = 0; i < 24; i++) {
      let hour = `${i}`;
      if (i < 10) {
        hour = `0${i}`;
      }
      for (const minute of minutes) {
        this.timeSelect.push(`${hour}:${minute}`);
      }
    }
  }

  private validateTimeInputs(startTimeChanged: boolean, shouldValidateEventTickets: boolean) {
    if (startTimeChanged) {
      this.validateStartedDate();
    }
    let startedDate;
    let endedDate;
    if (!this.startedDate && !this.endedDate) {
      startedDate = new Date('01/01/2020 ' + this.startedTime).getTime();
      endedDate = new Date('01/01/2020 ' + this.endedTime).getTime();
      if (startedDate >= endedDate) {
        this.timeError = true;
      }
    } else {
      startedDate = new Date(this.startedDate).getTime();
      endedDate = new Date(this.endedDate).getTime();
      if (startedDate !== endedDate) {
        this.timeError = false;
      } else {
        startedDate = new Date('01/01/2020 ' + this.startedTime).getTime();
        endedDate = new Date('01/01/2020 ' + this.endedTime).getTime();
        this.timeError = (startedDate >= endedDate);
      }
    }
    if (shouldValidateEventTickets) {
      this.validateTicketsTime();
    }
  }

  private startedDateSameAsToday() {
    const format = 'YYYY-MM-DD'
    return moment(this.startedDate).format(format) === moment(new Date()).format(format);
  }

  private validateDateInputs(startDateChanged: boolean) {
    if (startDateChanged) {
      this.validateStartedDate();
    }
    this.dateError = false;
    if (this.endDateActive) {
      if (this.startedDate && this.endedDate) {
        const startedDate = new Date(new Date(this.startedDate).toDateString()).getTime();
        const endedDate = new Date(new Date(this.endedDate).toDateString()).getTime();
        this.dateError = (startedDate > endedDate);
        if (this.startedTime && this.endedTime) {
          this.validateTimeInputs(startDateChanged, false);
        }
      } else {
        this.dateError = true;
      }
    } else {
      if (this.startedDate) {
        if (!this.startedTime) {
          this.timeError = true;
        }
      } else {
        this.dateError = true;
      }
    }
    this.validateTicketsTime();
  }

  private validateStartedDate() {
    this.startedTimeError = false;
    this.startedDateError = false;
    if (!EventCardsHelper.validateIfStartDateIsPresentOrFuture(this.startedDate, this.startedTime, this.selectedTimezone)) {
      this.startedTimeError = true;
      if (!this.startedDateSameAsToday()) {
        this.startedDateError = true;
      }
    }
  }

  private validateTicketsTime() {
    this.ticketsTimeError = !EventCardsHelper
      .validateNewEventTime(this.startedDate, this.startedTime, this.selectedTimezone, this.eventTicketsFromState);
  }

  private validateSendingData() {
    if (this.endDateActive) {
      return !(!this.startedDate || !this.endedDate || !this.startedTime || !this.endedTime || !this.selectedTimezone);
    } else {
      return !(!this.startedDate || !this.startedTime || !this.selectedTimezone);
    }
  }

  private getRequestDate() {
    let dateObject = new Date(this.startedDate);
    let dateString = `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()} ${this.startedTime}`;
    const startDate = moment(new Date(dateString)).format('YYYY-MM-DD HH:mm');
    if (this.endDateActive) {
      dateObject = new Date(this.endedDate);
      dateString = `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()} ${this.endedTime}`;
      const endDate = moment(new Date(dateString)).format('YYYY-MM-DD HH:mm');
      return {
        start: startDate,
        end: endDate,
      };
    } else {
      return {
        start: startDate,
        end: undefined,
      };
    }
  }
}
