import { Notification } from '../notifications/notifications.model';

export enum ToastType {
  PUSH_NOTIFICATION,
  SUCCESS,
  FAIL
}

export interface ToastInfo {
  type: ToastType;
  message: string;
  createdAt: Date;
  header?: string;
  notification?: Notification;
  delay?: number;
}
