/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Select } from '@ngxs/store';
import Hls, { HlsConfig } from 'hls.js';
import * as Plyr from 'plyr';
import { Observable, catchError, takeUntil } from 'rxjs';
import { AppFrameState } from 'src/app/app-frame/store/app-frame.state';
import { FileEditorContent } from '../../../models';
import { ObservableResult, Result } from '../../../store';

@Component({
  selector: 'ptl-resource-stream-video-preview',
  templateUrl: './stream-video-preview.component.html',
  styleUrls: ['./stream-video-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceStreamVideoPreviewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() content: FileEditorContent;

  @ViewChild('video', {static: false}) video: ElementRef<HTMLVideoElement>;

  @Select(AppFrameState.isMobile)
  private isMobile$: Observable<boolean>;

  player: Plyr;
  hls: Hls;
  sourcePath: string;
  playerInitialized = false;
  isProcessing = false;
  isProgressBarVisible = false;
  isEditMode = false;
  posterUrl: string;

  private isNewEditorEnabled: boolean;
  private readonly CHECK_FILE_INTERVAL_MS = 5000;
  private readonly DEFAULT_BUFFER_CONFIG = {maxBufferLength: 30, maxMaxBufferLength: 600};
  private videoIntervals: Map<string, NodeJS.Timeout> = new Map();
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
  ) {}

  ngOnInit(): void {
    const parentElement = this.el.nativeElement.parentElement;
    this.isEditMode = !parentElement.classList.contains('preview__area');
		this.isNewEditorEnabled = JSON.parse(localStorage.getItem('enableNewEditor'));
  }

  ngAfterViewInit(): void {
    if (this.content?.content?.url) {
      this.sourcePath = this.content.content.url;
      this.posterUrl = this.content.content.posterUrl;

      this.initStreamVideoPlayerIfSourceIsAvailable();
      const intervalId = setInterval(() => this.checkStreamVideoFile(), this.CHECK_FILE_INTERVAL_MS);
      this.videoIntervals.set(this.sourcePath, intervalId);
      this.isMobile$.pipe(takeUntil(this.subscriptionEnd$)).subscribe(isMobile => this.updateControlsVisibility(isMobile));
    }
  }

  ngOnDestroy(): void {
    this.subscriptionEnd$.emit();
    this.clearIntervalForCurrentVideo();
  }

  openUrl() {
    if (this.content.url) {
      window.open(this.content.url, this.content.openUrlInTab ? '_blank' : '_self');
    }
  }

  contentUploadProgress(progress: number): void {
    if (progress === 100) {
			setTimeout(() => {
				this.isProgressBarVisible = false;
        this.isProcessing = true;
				this.cdr.detectChanges();
			}, 500);
		}
  }

  private clearIntervalForCurrentVideo(): void {
    const intervalId = this.videoIntervals.get(this.sourcePath);
    if (intervalId) {
      clearInterval(intervalId);
      this.videoIntervals.delete(this.sourcePath);
    }
  }

  private checkStreamVideoFile(): void {
    if (this.isProcessing) {
      this.initStreamVideoPlayerIfSourceIsAvailable();
    }
  }

  private initStreamVideoPlayerIfSourceIsAvailable() {
    this.http
    .head(this.sourcePath, { observe: 'response', headers: { 'Cache-Control': 'no-cache' } })
    .pipe(catchError(() => ObservableResult.ofError('error')))
      .subscribe(response => {
        if (this.isValidStreamVideoHttpResponse(response)) {
          this.clearIntervalForCurrentVideo();
          this.initializePlayer();
          this.isProcessing = false;
          this.playerInitialized = true;
          this.isProgressBarVisible = false;
        } else {
          if (this.isNewEditorEnabled && !this.isProcessing) {
            this.isProgressBarVisible = true;
          } else {
            this.isProcessing = true;
          }
        }
        this.cdr.detectChanges();
      });
  }

  private isValidStreamVideoHttpResponse(response: HttpResponse<object> | Result<null>): response is HttpResponse<object> {
    return response instanceof HttpResponse &&
      (response.status === 200 || response.status === 304) &&
      response.headers.get('Content-Type') === 'binary/octet-stream';
  }

  private updateQuality(newQuality: string | number): void {
    this.hls.levels.forEach((level, levelIndex) => {
      if (level.height === newQuality) {
        this.hls.currentLevel = levelIndex;
      }
    });
  }

  private initializePlayer(): void {
    const storageKey = `videoTimestamp_${this.content.uid}_${this.content.fileName}`;
    const videoTimestamp = parseFloat(localStorage.getItem(storageKey) || '0');

    const videoElement = this.video.nativeElement;
    videoElement.muted = false;
    videoElement.volume = 1.0;

    videoElement.addEventListener('timeupdate', () => {
      localStorage.setItem(storageKey, videoElement.currentTime.toString());
    });

    videoElement.addEventListener('play', () => {
      videoElement.currentTime = videoTimestamp;
      if (this.hls) {
        this.hls.startLoad();
      }
    }, {once: true});

    const plyrOptions: Plyr.Options = {
      controls: ['play-large', 'play', 'progress', 'current-time', 'duration', 'volume', 'fullscreen', 'settings'],
      settings: ['quality', 'speed'],
      speed: {
        selected: 1,
        options: [0.5, 0.75, 1, 1.25, 1.5, 2],
      },
      autoplay: false,
      clickToPlay: true
    };

    if (this.isEditMode) {
      plyrOptions.controls = [];
      plyrOptions.clickToPlay = false;
      plyrOptions.fullscreen = {enabled: false}
      videoElement.controls = false;
      this.player = new Plyr(videoElement, plyrOptions);
      return;
    }

    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.config.autoStartLoad = false;
      this.hls.config.maxBufferLength = this.DEFAULT_BUFFER_CONFIG.maxBufferLength;
      this.hls.config.maxMaxBufferLength = this.DEFAULT_BUFFER_CONFIG.maxMaxBufferLength;
      this.hls.attachMedia(videoElement);
      this.hls.loadSource(this.sourcePath);
      this.hls.on(Hls.Events.MANIFEST_LOADED, () => {
        const availableQualities = this.hls.levels.map((level) => level.height);
        plyrOptions.quality = {
          default: availableQualities[0],
          options: availableQualities,
          forced: true,
          onChange: this.updateQuality.bind(this),
        };
        this.player = new Plyr(videoElement, plyrOptions);
      });
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = this.sourcePath;
      this.player = new Plyr(videoElement, plyrOptions);
    }
  }

  private updateControlsVisibility(isMobile: boolean): void {
    const playerElement = this.video.nativeElement.closest('.plyr') as HTMLElement;
    if (!playerElement) {
      return;
    }
    const volumeControl = playerElement.querySelector('.plyr__controls__item.plyr__volume') as HTMLElement;
    if (volumeControl) {
      volumeControl.style.display = isMobile ? 'none' : '';
    }
  }
}
