<h2 class="cdk-visually-hidden"
    tabindex="0"
    [attr.aria-label]="('translations.global.ariaLabels.manageReviewersModal' | transloco)">
  {{'translations.cards.review.button.manageReviewers' | transloco }}
</h2>
<button mat-button [mat-dialog-close]="false" class="dialog__close"
        [attr.aria-label]="('translations.global.ariaLabels.closeLogItem' | transloco)">
  <i class="material-icons" aria-hidden="true">close</i>
</button>
<h2>{{'translations.cards.review.button.manageReviewers' | transloco }}</h2>
<ptl-user-search-autocomplete-new *ngIf="data"
                                  class="reviewers-searchbox"
                                  #userSearchAutocomplete
                                  [loadingMembers]="loadingMembers"
                                  [members]="members"
                                  [placeholder]="'translations.global.search.label.forReviewers' | transloco"
                                  (searchLoadedMore)="onSearchLoadingMore($event)"
                                  (searchInputChanged)="onSearchInputChange($event)"
                                  (memberSelected)="onMemberSelected($event)"
>
</ptl-user-search-autocomplete-new>

<div class="chips-list reviewers-list" *ngIf="reviewers?.length">
  <div
    tabindex="0"
    *ngFor="let reviewer of reviewers; let i = index"
    [matTooltip]="reviewer.firstName + ' ' + reviewer.lastName"
    class="chip-item has-action-btn chip-interactive">
    <span class="preview-icon">
      <img alt=""
           src="{{ reviewer.imageUrl ?
           reviewer.imageUrl : '../../../../../../../assets/profile_image.png' }}"/>
        </span>
    {{reviewer.firstName}} {{reviewer.lastName}}
    <span
      tabindex="0"
      class="chip-item-icon"
      (click)="onRemove(reviewer)"
      (keydown.enter)="onRemove(reviewer)">
            <svg class="svg" role="img" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
            </svg>
          </span>
  </div>
</div>

<div class="reviewers-list-btn-container">
  <button
    mat-flat-button
    type="button"
    color="primary"
    (click)="onUpdateButton()"
    (keydown.enter)="onUpdateButton()"
    class="mat-button-base">
    {{ 'translations.reviews.adminActions.reviewersManagement.button' | transloco }}
  </button>
</div>
