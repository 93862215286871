<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<div class="skeleton__card-container">
  <div class="title-placeholder large">
    <div class="title-placeholder-inner">
      <div class="skeleton__text skeleton__text-title slide-row"></div>
    </div>
  </div>
  <div class="skeleton__card-item" *ngFor="let item of items">
    <mat-card-title class="skeleton__card-top">
      <div class="left-text-placeholder">
        <div class="skeleton__text skeleton__text-title slide-row"></div>
      </div>
      <div class="skeleton__avatar"></div>
      <div class="skeleton__text skeleton__text-title slide-row"></div>
    </mat-card-title>
  </div>
</div>
