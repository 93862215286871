<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<section class="report-section" [class.first]="isFirst" [id]="section?.uid">
  <ptl-resource-preview
    [previewContent]="section?.dynamicContent">
  </ptl-resource-preview>
</section>
