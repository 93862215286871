<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="form-section" (click)="expandForm()" (keydown.enter)="$event.stopPropagation()">
  <form
    class="form-section__form-expanded"
    tabindex="0"
    [attr.aria-label]="('translations.global.ariaLabels.thisIsBookingForm' | transloco)"
    [formGroup]="bookingForm"
    (ngSubmit)="saveForm()">
    <div class="booking-form-section__box">
      <span class="booking-edit__top-title">{{ 'translations.bookings.label.title' | transloco }}</span>

      <div class="booking-edit__top-icon-box" *ngIf="expanded">
        <div class="booking-edit__top-icon">
          <mat-spinner
            *ngIf="isLoading"
            [diameter]="18">
          </mat-spinner>
          <svg class="svg" *ngIf="!isLoading" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#calendar"></use>
          </svg>
        </div>
      </div>

      <span class="setting-text extra-small" *ngIf="!expanded">{{ 'translations.global.button.settings' | transloco }}</span>
    </div>

    <div class="form-block-inner">
      <div class="form-block-title-box">
        <input
          type="text"
          class="form-block-input-field no-border full-width no-padding medium bold"
          required
          [class.has-error]="nameFormControl.touched && nameFormControl.invalid"
          [formControl]="nameFormControl"
          [placeholder]="'translations.appointmentName' | transloco">

        <div class="error-border" *ngIf="nameFormControl.touched && nameFormControl.invalid"></div>
      </div>
    </div>

    <ng-container *ngIf="expanded">
      <div class="settings-padding hidden">
        <form
          tabindex="0"
          [attr.aria-label]="('translations.global.ariaLabels.thisIsBookingForm' | transloco)"
          [formGroup]="appointmentForm"
          (ngSubmit)="saveAppointmentForm()">
          <div class="row">
            <h3>
              <svg class="svg" role="img" aria-hidden="true">
                <use xlink:href="assets/sprite/sprite.svg#settings"></use>
              </svg>
              {{ 'translations.bookings.label.appointmentSettings' | transloco }}
            </h3>
          </div>
          <div class="row">
            <mat-form-field class="form__field col">
              <input
                matInput
                type="number"
                required
                formControlName="duration"
                [placeholder]="'translations.bookings.label.duration' | transloco">
            </mat-form-field>
            <mat-form-field class="form__field col">
              <input
                matInput
                type="number"
                required
                formControlName="bookableInterval"
                [placeholder]="'translations.bookings.label.bookableInterval' | transloco">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="form__field col">
              <input
                matInput
                type="number"
                required
                formControlName="bufferTimeBefore"
                [placeholder]="'translations.bookings.label.bufferTimeBefore' | transloco">
            </mat-form-field>
            <mat-form-field class="form__field col">
              <input
                matInput
                type="number"
                required
                formControlName="bufferTimeAfter"
                [placeholder]="'translations.bookings.label.bufferTimeAfter' | transloco">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="form__field col">
              <input
                matInput
                type="number"
                required
                formControlName="bookingWindowStart"
                [placeholder]="'translations.bookings.label.bookingWindowStart' | transloco">
            </mat-form-field>
            <mat-form-field class="form__field col">
              <input
                matInput
                type="number"
                required
                formControlName="bookingWindowEnd"
                [placeholder]="'translations.bookings.label.bookingWindowEnd' | transloco">
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="form__field col">
              <input
                matInput
                type="number"
                required
                formControlName="cancellationThreshold"
                [placeholder]="'translations.bookings.label.cancellationThreshold' | transloco">
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col">
              <button mat-stroked-button type="submit">
                <svg class="svg" role="img" aria-hidden="true">
                  <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
                </svg>
                {{ 'translations.global.button.edit' | transloco }}
              </button>
            </div>
          </div>
        </form>
      </div>

      <ng-container *ngIf="showUserResourceStep">
        <ng-container *ngIf="isUserTypeSelected">
          <ptl-form-booking-resource
            [form]="form"
            [bookableResources]="bookableResources"
            [appointment]="appointment"
            [timeIntervals]="timeIntervals"
            [cardUid]="cardUid"
            [currentLanguage]="currentLanguage"
            [saveInProgress]="isSaved"
            (isLoading)="setIsLoading($event)"
            (scheduleUpdated)="updateResourcesRequests($event)"
            (resourceUpdated)="onResourceRefresh()">
          </ptl-form-booking-resource>
        </ng-container>
      </ng-container>
      <div class="row booking-form__buttons">
        <button
          mat-flat-button
          class="booking-form__footer_btn"
          color="warn"
          type="button"
          [title]="'translations.global.button.remove' | transloco"
          *ngIf="canRemoveItem"
          [attr.aria-label]="('translations.global.ariaLabels.deleteCheckboxForm' | transloco)"
          (click)="removeForm()">
          <svg class="svg svg-icon icon-left" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
          </svg>
          <span>{{ 'translations.global.button.delete' | transloco }}</span>
        </button>
        <button
          mat-flat-button
          class="form-button-save"
          *ngIf="showUserResourceStep"
          [title]="'translations.bookings.label.doneEditing' | transloco"
          color="primary"
          [attr.aria-label]="('translations.bookings.label.doneEditing' | transloco)"
          (click)="submitChanges()">
          <svg class="svg svg-icon icon-left" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
          </svg>
          <span>{{ 'translations.bookings.label.doneEditing' | transloco }}</span>
        </button>
        <button
          mat-flat-button
          class="form-button-save small"
          title="Save"
          color="accent"
          type="button"
          (click)="collapseForm($event)"
          [attr.aria-label]="('translations.global.ariaLabels.collapseForm' | transloco)">
          <span>{{ 'translations.global.button.cancel' | transloco }}</span>
        </button>
      </div>
    </ng-container>
  </form>

  <div class="row chips-list" *ngIf="!expanded && this.bookableResources?.length > 0">
    <span class="chip-item chip-interactive"
      *ngFor="let resource of bookableResources">
      <span class="resource-preview-icon">
        <img alt=""
          src="{{ resource.targetResourceSummary.imageUrl ?
           resource.targetResourceSummary.imageUrl : '../../../../../../../assets/profile_image.png' }}" />
      </span>
      {{ resource.targetResourceSummary.name }}
    </span>
  </div>
</div>
