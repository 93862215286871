<mat-tab-group [(selectedIndex)]="currentIndex" *ngIf="supportedLanguages && notSingleDefaultLanguage">
  <mat-tab *ngFor="let supportedLanguage of supportedLanguages;"
           [attr.tabindex]="0"  >
    <ng-template mat-tab-label [attr.aria-disabled]="false" [attr.tabindex]="0">
      <div class="tab-click f_language-tab" [attr.tabindex]="0" role="button"
           [attr.aria-label]="supportedLanguage.language.name + ' ' + (supportedLanguage.isDefault ? ('translations.language.tabs.defaultSuffix' | transloco) : '')"
           [attr.language-code]="supportedLanguage.language.code"
           (click)="onTabChangeButton(supportedLanguage.language.code)"
           (keydown.enter)="onTabChangeButton(supportedLanguage.language.code)"></div>
      {{supportedLanguage.language.name}}&nbsp;
      <strong *ngIf="supportedLanguage.isDefault">
        ({{'translations.language.tabs.defaultSuffix' | transloco}})
      </strong>
    </ng-template>
  </mat-tab>
</mat-tab-group>
