import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation/translation.service';


@Pipe({
  name: 'smartDateFormat'
})
export class SmartDateFormatPipe implements PipeTransform {

  constructor(
    private translationService: TranslationService) {
  }

  transform(value: Date, format: string): string {
    const date = new Date(value)
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    if ( date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate() ) {
      return  this.translationService.getTranslation('global.calendar.today');
    } else if ( date.getFullYear() === yesterday.getFullYear() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getDate() === yesterday.getDate() ) {
      return this.translationService.getTranslation('global.calendar.yesterday');
    } else {
      return (new DatePipe('en-US')).transform(value, format);
    }
  }
}
