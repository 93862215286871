import { Component, EventEmitter, Output } from '@angular/core';
import { canTriggerSearch } from 'src/app/shared/helpers/content-helper';
import { BooleanQueryType, OrRequest } from 'src/app/shared/models/admin/boolean-filters.model';

@Component({
  selector: 'ptl-name-filter',
  templateUrl: './name-filter.component.html',
  styleUrls: ['./name-filter.component.scss']
})
export class NameFilterComponent {

  @Output() nameChanged = new EventEmitter<OrRequest>();
  private name = '';

  private applyName() {
    const firstNameRequest = {
        type: BooleanQueryType.FIRST_NAME,
        firstName: this.name
    }
    const lastNameRequest = {
        type: BooleanQueryType.LAST_NAME,
        lastName: this.name
    }
    const externalIdRequest = {
      type: BooleanQueryType.EXTERNAL_ID,
      externalId: this.name
    }
    const emailRequest = {
      type: BooleanQueryType.EMAIL,
      email: this.name
    }
    const request = {
      type: BooleanQueryType.OR,
      args: [firstNameRequest, lastNameRequest, externalIdRequest, emailRequest]
    } as OrRequest;
    this.nameChanged.emit(request);
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        this.name = (event.target as HTMLInputElement).value;
        this.applyName()
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      const previousValue = this.name;
      const newValue = (event.target as HTMLInputElement).value?.trim();
      if (previousValue !== newValue) {
        this.name = newValue;
        this.applyName();
      }
    }
  }

}
