import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ExternalContentSummary } from '../../../models';
import { iframely } from '@iframely/embed.js';
import { UserAuthState } from '@app/app/user-auth/store/user-auth.state';
import { Select } from '@ngxs/store';
import { Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'ptl-external-preview',
  templateUrl: './external-preview.component.html',
  styleUrls: ['./external-preview.component.scss', './external-preview-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(UserAuthState.editorJsFeatureFlag)
	private editorJsFeatureFlag$: Observable<boolean>;

  @Input() content: ExternalContentSummary;

  @ViewChild('htmlContent', { static: true }) public htmlContent: ElementRef;

  isNewEditorEnabled = false;
	private subscriptionEnd$ = new EventEmitter<void>();

  constructor() {
    setTimeout(() => {
      iframely.load();
    }, 500);
  }

  ngOnInit(): void {
    this.isNewEditorEnabled = JSON.parse(localStorage.getItem('enableNewEditor'));
		this.editorJsFeatureFlag$.pipe(takeUntil(this.subscriptionEnd$))
		.subscribe(() => {
			this.isNewEditorEnabled = JSON.parse(localStorage.getItem('enableNewEditor'));
		});
  }

  ngAfterViewInit() {
    if (this.content && this.content.html) {
      const fragment = document.createRange().createContextualFragment(
        this.wrapHtmlInStyleContainer(this.content.html)
      );

      this.htmlContent.nativeElement.prepend(fragment);
      setTimeout(() => {
        const iframe = (this.htmlContent.nativeElement as HTMLElement).querySelector('iframe');
        if (iframe) {
          iframe.setAttribute('title', this.content.title ? this.content.title : 'no title');
        }
      }, 500);
    }
  }

  ngOnDestroy(): void {
		this.subscriptionEnd$.emit();
  }

  private wrapHtmlInStyleContainer(html: string): string {
    const htmlWrapperOpening = '<div class="content-media__html">';
    const htmlWrapperClosing = '</div>';
    if (html.indexOf('iframely-embed') === -1) {
      return htmlWrapperOpening + html + htmlWrapperClosing;
    }

    return html;
  }
}

