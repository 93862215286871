/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Quiz, QuizSummary, UpdateQuizRequest } from '../../shared/models/editor/quiz-content.model';
import { ObservableResult } from '../../shared/store';
import { InjectionToken } from '@angular/core';
import { DiagnosticQuestionRequest, DiagnosticQuestionResponse } from '../../shared/models';
import { QuizReport, QuizResponse } from '../../page-modules/resource/models';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const DATA_SERVICE = new InjectionToken<QuizDataService>('[Quiz] DataService');

export interface QuizDataService {

  getQuiz(cardUid: string, quizUid: string, languageCode?: string): ObservableResult<Quiz>;

  getQuizSummary(cardUid: string, quizUid: string, languageCode?: string): ObservableResult<QuizSummary>;

  getQuizUserSummary(cardUid: string, quizUid: string, userUid: string, languageCode?: string): ObservableResult<QuizSummary>;

  getQuizSummaryAsReviewer(cardUid: string, quizUid: string, reviewUid: string): ObservableResult<QuizSummary>;

  deleteQuiz(cardUid: string, quizUid: string): ObservableResult<void>;

  updateQuiz(cardUid: string, quizUid: string, request: UpdateQuizRequest, languageCode?: string): ObservableResult<Quiz>;

  /**
   * Post Quiz Response
   */
  postQuizResponse(
    playlistUid: string,
    quizUid: string,
    version: number,
    questionResponseRequest,
    languageCode?: string
  ): ObservableResult<QuizResponse>;

  getQuizResponse(playlistUid: string, quizUid: string, quizResponseUid: string): ObservableResult<QuizResponse>;

  /**
   * Loads report by playlistUid, quizUid and quizResponsesUid and userUid if needed
   */
  getQuizReport(playlistUid: string, quizUid: string, quizResponsesUid: string, languageCode?: string): ObservableResult<QuizReport>;

  retakeQuiz(playlistUid: string, quizUid: string, quizResponsesUid: string, nextVersion: number): ObservableResult<void>;

  addQuestion(
    cardUid: string,
    quizUid: string,
    question: DiagnosticQuestionRequest,
    languageCode?: string
  ): ObservableResult<DiagnosticQuestionResponse>;

  updateQuestion(
    cardUid: string,
    quizUid: string,
    questionUid: string,
    question: DiagnosticQuestionRequest,
    languageCode?: string
  ): ObservableResult<DiagnosticQuestionResponse>;

  clearQuestionFeedback(cardUid: string, quizUid: string, questionUid: string, languageCode?: string): ObservableResult<void>;

  deleteQuestion(cardUid: string, quizUid: string, questionUid: string): ObservableResult<void>;

  copyQuestion(cardUid: string, quizUid: string, questionUid: string, languageCode?: string):
    ObservableResult<DiagnosticQuestionResponse>;

}
