import { AvailableLanguage, LanguageValue, Organization } from '../models';
import {
	CONTENT_LANGUAGE_CODE,
	DEFAULT_LANGUAGE_CODE,
	LANGUAGE_KEY,
	ORGANIZATION_LANGUAGE_KEY,
	TranslationService
} from '../services/translation/translation.service';
import { SupportedLanguage, TextOrientation } from '../models/languages/languages.model';
import { Direction } from '@angular/cdk/bidi';

export class LanguageCodeHelper {

	static getDataByLanguageCode( items: LanguageValue[] ): LanguageValue {
		const userLanguageCode = localStorage.getItem(LANGUAGE_KEY);
		if ( typeof items === 'string' ) {
			return { languageCode: userLanguageCode, value: items };
		}
		let valueToDisplay = items.find(item => item.languageCode === userLanguageCode);

		// fallback to organization language code if string with language code is not present
		const organizationLanguageCode = localStorage.getItem(ORGANIZATION_LANGUAGE_KEY);
		if ( !valueToDisplay ) {
			valueToDisplay = items.find(item => (item.languageCode === organizationLanguageCode));
		}

		// fallback to default language code if string with organization language code is not present
		if ( !valueToDisplay ) {
			valueToDisplay = items.find(item => (item.languageCode === DEFAULT_LANGUAGE_CODE));
		}

		// fallback to EN if string with default language code is not present
		if ( !valueToDisplay ) {
			valueToDisplay = items.find(item => (item.languageCode === 'en'));
		}

		if ( !valueToDisplay ) {
			valueToDisplay = items[0];
		}

		return valueToDisplay ? valueToDisplay : { languageCode: userLanguageCode, value: '' };
	}

	static getDataByUserLanguageCode( items: LanguageValue[], organization: Organization, userLanguageCode: string ): LanguageValue {
		if ( typeof items === 'string' ) {
			return { languageCode: userLanguageCode, value: items };
		}
		const valueToDisplay = items.find(item => item.languageCode === userLanguageCode);
		const organizationLanguageCode = LanguageCodeHelper.getOrganizationDefaultLanguage(organization);
		const fallbackValue = items.find(item => item.languageCode === organizationLanguageCode);
		return valueToDisplay ? valueToDisplay : {
			languageCode: userLanguageCode,
			value: fallbackValue ? fallbackValue.value : ''
		};
	}

	static getOrganizationDefaultLanguage( organization: Organization ): string {
		if ( organization ) {
			let result = organization.languages?.find(lang => lang.default === true);

			if ( !result ) {
				result = organization.languages?.find(lang => lang.code === DEFAULT_LANGUAGE_CODE);
			}
			return result.code;
		} else {
			return DEFAULT_LANGUAGE_CODE;
		}
	}

	// Uses for getting active language tab
	// Based on user account settings language code and content supported language code
	static getActiveLanguageTabIndex(
		supportedLanguages: SupportedLanguage[], availableLanguage: AvailableLanguage[], translationService: TranslationService
	): number {

		const userSelectedLanguage = translationService.getLanguage();
		let languageIndex = supportedLanguages.findIndex(it => it.language.code === userSelectedLanguage);
		if ( languageIndex === -1 ) {
			languageIndex = 0;
		}
		const languageCode = supportedLanguages[languageIndex]?.language?.code;
		const availableLanguageIndex = availableLanguage?.findIndex(lang => lang.code === languageCode);
		return availableLanguageIndex !== -1 ? availableLanguageIndex : 0;
	}

	// Orders an array of available languages based on the order in an array of supported languages.
	static orderAvailableLanguages(
		supportedLanguages: SupportedLanguage[],
		availableLanguages: AvailableLanguage[]
	): AvailableLanguage[] {
		if ( !supportedLanguages ) {
			// If supportedLanguages is undefined, return availableLanguages as is
			return availableLanguages || [];
		}

		// If availableLanguages is undefined, set it to an empty array
		availableLanguages = availableLanguages || [];

		const orderedLanguages: AvailableLanguage[] = [];

		// Iterate through supportedLanguages to determine the order
		supportedLanguages.forEach(supportedLang => {
			const matchingAvailableLang = availableLanguages.find(
				availableLang => availableLang.code === supportedLang.language.code
			);

			if ( matchingAvailableLang ) {
				orderedLanguages.push(matchingAvailableLang);
			}
		});

		return orderedLanguages;
	}

	static checkAndGetContentLanguageCode( languageCode: string, isGroupUpdate?: boolean ) {
		const activeLanguageTab = document.querySelector('.mat-tab-label-active');
		if ( activeLanguageTab ) {
			const activeLanguageCode = activeLanguageTab.querySelector('.f_language-tab')?.getAttribute('language-code');
			return { 'Content-Language': activeLanguageCode === languageCode ? activeLanguageCode : languageCode };
		}
		if ( isGroupUpdate ) {
			return { 'Content-Language': languageCode ? languageCode : DEFAULT_LANGUAGE_CODE };
		}
		return { 'Content-Language': DEFAULT_LANGUAGE_CODE };
	}

	// Uses for creating playlist with default organization language code
	static getLanguageCode( currentLanguage: SupportedLanguage ): string {
		if ( currentLanguage && currentLanguage.language ) {
			return currentLanguage.language.code;
		}
		return localStorage.getItem(ORGANIZATION_LANGUAGE_KEY);
	}

	// Uses for loading playlist/card by user preferred language
	static getContentLanguageCode(): string {
		const languageCode = localStorage.getItem(CONTENT_LANGUAGE_CODE);
		const userLanguageCode = localStorage.getItem(LANGUAGE_KEY);
		return languageCode ? languageCode : userLanguageCode ? userLanguageCode : DEFAULT_LANGUAGE_CODE;
	}


	// Uses for loading playlist/card by selected language code for learner ignoring language settings
	static getOnlyContentLanguageCode(): string {
		const languageCode = localStorage.getItem(CONTENT_LANGUAGE_CODE);
		return languageCode ? languageCode : DEFAULT_LANGUAGE_CODE;
	}

	// Uses for setting language code for playlist/card based on learner selected language
	static setContentLanguageCode( code: string ): void {
		localStorage.setItem(CONTENT_LANGUAGE_CODE, code);
	}

	// Uses for removing learner selected language
	static removeContentLanguageCode(): void {
		localStorage.removeItem(CONTENT_LANGUAGE_CODE);
	}

	static getDefaultLanguageCode(): string {
		return DEFAULT_LANGUAGE_CODE;
	}

	static setBodyLanguageDir( orientation: TextOrientation ) {
		const dir = orientation === 'LEFT_TO_RIGHT' ? 'ltr' : 'rtl';
		window.localStorage.setItem('languageDirection', dir);
	}

	static getBodyLanguageDir(): Direction {
		let dir = window.localStorage.getItem('languageDirection');
		if ( !dir ) {
			dir = document.body.getAttribute('dir');
		}
		return (dir ?? 'ltr') as Direction;
	}
}
