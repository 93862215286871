import { Injectable } from '@angular/core';
import { OptionListDataService } from './option-list-data.service';
import { ObservableResult } from '../../store';
import { HierarchicalList } from '../../models';
import { DEFAULT_LANGUAGE_CODE } from '../translation/translation.service';

@Injectable()
export class MockOptionListDataService implements OptionListDataService {

  optionList: HierarchicalList = {
    _id: 'someUid1',
    title: [{
      languageCode: DEFAULT_LANGUAGE_CODE,
      value: 'test title'
    }],
    creatorUid: 'someUid2',
    createdAt: new Date('2022-01-11T11:00:00.001'),
    lastUpdatedAt: new Date('2022-01-11T11:00:00.001'),
    root: {
      uid: 'someUid3',
      value: 'testRootValue',
      sublist: []
    }
  };

  createOptionList(title: string): ObservableResult<HierarchicalList> {
    return ObservableResult.ofSuccess(this.optionList);
  }

  addOptionListElement(listUid: string, parentUid: string, optionName: string): ObservableResult<HierarchicalList> {
    return ObservableResult.ofSuccess(this.optionList);
  }

  updateOptionListElement(listUid: string, parentUid: string, optionName: string): ObservableResult<HierarchicalList> {
    return  ObservableResult.ofSuccess(this.optionList);
  }

  removeOptionListElement(listUid: string, parentUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

}
