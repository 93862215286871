/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { RestClientService } from '../rest-client.service';
import { ObservableResult } from '../../store';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { Page as Pagination } from '../../models/page';
import { UpcomingEventCardV1, UpcomingEventCardV2, UpcomingEventFilter, UpcomingEventsTag } from '../../models/event-card/event-card.model';
import { SharedUpcomingDataService } from './upcoming-data.service';
import { TranslationService } from '../translation/translation.service';
import { VersionHelper } from '../../helpers/version.helper';

@Injectable()
export class ApiSharedUpcomingDataService implements SharedUpcomingDataService {

  constructor(private client: RestClientService, private translationService: TranslationService) {
  }

  getUpcomingEvents(page: number, size: number, frameworkId?: string, tagId?: string, languageCode?: string):
    ObservableResult<Pagination<UpcomingEventCardV1>> {
    const uri = frameworkId ?
      `/events/upcomings/categories/${frameworkId}?page=${page}&size=${size}&tagId=${tagId}` :
      `/events/upcomings?page=${page}&size=${size}`;
    return this.client.get<Pagination<UpcomingEventCardV1>>(
      Location.joinWithSlash(environment.apiRootUrl || '', uri),
      null,
      languageCode ? { 'Accept-Language': languageCode } : null
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorLoadingUpcomingEvents')))
    );
  }

  getUpcomingEventsByDate(startDate: string, endDate: string): ObservableResult<UpcomingEventCardV1[]> {
    return this.client.get<UpcomingEventCardV1[]>(
      Location.joinWithSlash(environment.apiRootUrl || '', `/events/upcomings/dates?from=${startDate}&to=${endDate}`)).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorLoadingUpcomingEvents')))
    );
  }

  getUpcomingEventsBySkills(tags: string[], startDate?: string, endDate?: string): ObservableResult<Pagination<UpcomingEventCardV1>> {
    const reqBody = {
      tags: tags,
    }
    if (startDate && endDate) {
      reqBody['date'] = {
        from: startDate,
        to: endDate,
      }
    }
    return this.client.post<Pagination<UpcomingEventCardV1>>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/events/upcomings/search'),
      reqBody
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorLoadingUpcomingEvents')))
    );
  }

  getUpcomingEventsTags(page: number, size: number, term?: string): ObservableResult<Pagination<UpcomingEventsTag>> {
    const params = {
      page: page.toString(),
      size: size ? size.toString() : '10',
    };
    if (term) {
      params['term'] = term;
    }
    return this.client.get<Pagination<UpcomingEventsTag>>(
      Location.joinWithSlash(environment.apiRootUrl || '', '/events/upcomings/tags'),
      params
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorLoadingUpcomingEvents')))
    );
  }

  hasUpcomingEvents(): ObservableResult<boolean> {
    const newVersionEnabled = VersionHelper.newVersionEnabled();
    if (newVersionEnabled) {
      const uri = '/events/upcomings/filtered?page=0&size=1';
      const request = {
        name: undefined,
        eventTypes: undefined,
        skills: undefined,
        dateBefore: undefined,
        dateAfter: undefined
      }
      return this.client.post<Pagination<UpcomingEventCardV2>>(
        Location.joinWithSlash(environment.apiRootUrl || '', uri),
        request
      ).pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body.hasContent)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorLoadingUpcomingEvents')))
      );
    } else {
      return this.client.get<{ content: [] }>(
        Location.joinWithSlash(environment.apiRootUrl || '', 'events/upcomings?page=0&size=1')).pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(!!body.content.length)),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorLoadingUpcomingEvents')))
      );
    }
  }

  getUpcomingEventsByFilter(page: number, size: number, filter: UpcomingEventFilter): ObservableResult<Pagination<UpcomingEventCardV2>>  {
    const uri = `/events/upcomings/filtered?page=${page}&size=${size}`;
    const request = {
      name: filter.nameFilter,
      eventTypes: filter.typeFilter ? {first: filter.typeFilter.types, second: filter.typeFilter.filterType} : undefined,
      skills: filter.skillFilter ? {first: filter.skillFilter.skills, second: filter.skillFilter.filterType}: undefined,
      dateBefore: filter.dateFilter?.beforeDate,
      dateAfter: filter.dateFilter?.afterDate
    }

    return this.client.post<Pagination<UpcomingEventCardV2>>(
      Location.joinWithSlash(environment.apiRootUrl || '', uri),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorLoadingUpcomingEvents')))
    );
  }

  getEventsEngagements(page: number, size: number, filter: UpcomingEventFilter): ObservableResult<Pagination<UpcomingEventCardV2>>  {
    const uri = `/events/engagements?page=${page}&size=${size}`;
    const request = {
      name: filter.nameFilter,
      eventTypes: filter.typeFilter ? {first: filter.typeFilter.types, second: filter.typeFilter.filterType} : undefined,
      skills: filter.skillFilter ? {first: filter.skillFilter.skills, second: filter.skillFilter.filterType}: undefined,
      dateBefore: filter.dateFilter?.beforeDate,
      dateAfter: filter.dateFilter?.afterDate
    }

    return this.client.post<Pagination<UpcomingEventCardV2>>(
      Location.joinWithSlash(environment.apiRootUrl || '', uri),
      request
    ).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorLoadingUpcomingEvents')))
    );
  }
}
