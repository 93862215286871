/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  PageAdminRights,
  PageAuthorInfoUpdateRequest,
  PageRequest,
  PageTargetItem
} from '../../../shared/models/page/page.model';
import { SupportedLanguage } from '../../../shared/models/languages/languages.model';
import { ResourceTag } from '../../../shared/models';

export class CreatePage {
  static readonly type = '[Page] Create Page';

  constructor(public request: PageRequest) {

  }

}

export class LoadHome {
  static readonly type = '[Page] Load home page';

  constructor(public languageCode?: string) {

  }
}

export class SetSaveInProgress {
  static readonly type = '[Page] Set save in progress';

  constructor(public saveInProgress: boolean) {

  }
}

export class ResetPageState {
  static readonly type = '[Page] reset state';
}

export class ResetCurrentPageState {
  static readonly type = '[Page] reset current state';
}

export class LoadPageByUriAndLanguageCode {
  static readonly type = '[Page] Load page by uri and language code';

  constructor(
    public pageUri: string,
    public publisherUri?: string,
    public packageUri?: string,
    public frameworkId?: string,
    public tagId?: string,
    public languageCode?: string,
    public contentStore?: boolean,
  ) {
  }
}

export class SetPageCurrentLanguage {
  static readonly type = '[Page] Set current language';

  constructor(public supportedLanguage: SupportedLanguage, public index: number) {
  }
}


export class ResetPageCurrentLanguage {
  static readonly type = '[Page] Reset current language';
}

export class GetContinuePlaylists {
  static readonly type = '[Page] Load continue playlists';

  constructor(public sectionUid: string, public page: number, public size: number, public languageCode?: string) {
  }
}

export class GetLatestPlaylists {
  static readonly type = '[Page] Load latest playlists';

  constructor(public sectionUid: string, public page: number, public size: number, public languageCode?: string,
              public contentStore?: boolean
  ) {
  }
}

export class GetYourPlaylists {
  static readonly type = '[Page] Load your playlists';

  constructor(public sectionUid: string, public page: number, public size: number, public term?: string, public languageCode?: string) {
  }
}

export class GetUpcomingEvents {
  static readonly type = '[Page] Load upcoming events';

  constructor(public sectionUid: string, public page: number, public size: number, public languageCode?: string) {
  }
}

export class GetPagesItems {
  static readonly type = '[Page] Load pages items';

  constructor(public sectionUid: string, public page: number, public size: number, public languageCode?: string) {
  }
}


export class ShowLessHomePageItems {
  static readonly type = '[Page] show less page items';

  constructor(public sectionUid: string, public size: number, public isDefaultView: boolean, public isMoments: boolean) {
  }
}

export class GetUserMoments {
  static readonly type = '[Page] Load user moments';

  constructor(public sectionUid: string, public page: number, public size: number, public languageCode?: string) {
  }
}

export class GetAchievements {
  static readonly type = '[Page] Load achievements';

  constructor(public sectionUid: string, public languageCode?: string) {
  }
}

export class UpdateFrameworkTracker {
  static readonly type = '[Page] Update framework tracker';
}

export class OpenResourceDetails {
  static readonly type = '[Page] Open resource details';

  constructor(public resourceUri: string, public formattedUri: string) {
  }
}

export class UpdateMainPageFavoritePlaylists {
  static readonly type = '[Page] Update main page favorite playlists';

  constructor(public playlistUri: string, public favorite: boolean) {
  }
}

export class LoadYourSectionPlaylists {
  static readonly type = '[Page] Load your section playlists';

  constructor(public page: number) {
  }
}

export class LoadMorePagesSection {
  static readonly type = '[Page] Load more pages section';

  constructor(public page: number) {
  }
}

export class RefreshYourSectionPlaylists {
  static readonly type = '[Page] Refresh your section playlists';
}

export class LoadSubscriptionSectionPlaylists {
  static readonly type = '[Page] Load subscription section playlists';

  constructor(public page: number) {
  }
}

export class RefreshSubscriptionSectionPlaylists {
  static readonly type = '[Page] Refresh subscription section playlists';
}


export class UpdatePageHeadline {
  static readonly type = '[Page] Update headline';

  constructor(public headline: string, public languageCode: string) {
  }
}

export class UpdatePageSubheader {
  static readonly type = '[Page] Update subheader';

  constructor(public subheader: string, public languageCode?: string) {
  }

}

export class UpdatePageUri {
  static readonly type = '[Page] Update URI';

  constructor(public value: string) {
  }
}

export class UpdatePageSidenavVisibility {
  static readonly type = '[Page] Update sidenav visibility';

  constructor(public enable: boolean) {
  }
}
export class SwitchToHomePage {
  static readonly type = '[Page] Switch to home page';

  constructor(public pageUid: string) {
  }
}

export class RedirectToPageLearnerView {
  static readonly type = '[Page] Navigate to page learner view';

  constructor(public uri?: string) {
  }
}

export class ClonePage {
  static readonly type = '[Page] Clone page';
}

export class PublishPage {
  static readonly type = '[Page] Publish page';
}

export class UnpublishPage {
  static readonly type = '[Page] Unpublish page';
}

export class ArchivePage {
  static readonly type = '[Page] Archive page';
}

export class PageAssociateAdmin {
  static readonly type = '[Page] Associate admin';

  constructor(public request: PageAdminRights) {
  }
}

export class PageDissociateAdmin {
  static readonly type = '[Page] Dissociate admin';

  constructor(public adminUid: string) {
  }
}

export class PageAddTag {
  static readonly type = '[Page] Add tag';

  constructor(public tag: ResourceTag) {
  }
}

export class PageRemoveTag {
  static readonly type = '[Page] remove tag';

  constructor(public tagUid: string) {
  }
}

export class PageTargetToGroup {
  static readonly type = '[Page] Target page to group';

  constructor(public request: PageTargetItem) {
  }
}

export class PageTargetRemoveGroup {
  static readonly type = '[Page] Remove page targeting from group';

  constructor(public groupUid: string) {
  }
}

export class PageTargetToMember {
  static readonly type = '[Page] Target page to member';

  constructor(public request: PageTargetItem) {
  }
}

export class PageTargetRemoveMember {
  static readonly type = '[Page] Remove page targeting from member';

  constructor(public userUid: string) {
  }
}

export class PageTargetRemoveAllMembers {
  static readonly type = '[Page] Remove page targeting from all members';
}

export class PageTargetRemoveAllGroups {
  static readonly type = '[Page] Remove page targeting from all group';
}

export class PageTargetSetPrivate {
  static readonly type = '[Page] Target page as private';
}


export class UpdatePageAuthorVisibility {
  static readonly type = '[Page] Update author visibility';

  constructor(public request: PageAuthorInfoUpdateRequest) {
  }
}

export class UpdatePageAuthorInfo {
  static readonly type = '[Page] Update author info';

  constructor(public request: PageAuthorInfoUpdateRequest) {
  }
}
