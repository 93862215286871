/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import {
  AddPlaylistFromStoreRequest, ContentPackagePageOrPlaylistCreateRequest,
  ContentPackageThumbnailUpdateRequest,
  CreatePublisherPackageRequest,
  CreatePublisherRequest,
  PackageSubscriber,
  PageContentStoreSummary,
  Publisher,
  PublisherPackage, PublisherPackageLicense, StandardTailoredSubjectList,
  SubmitContentStoreRequest,
  SyndicationResponse, TailoredSubjectList, TailoredSubjectRequest, TailoredSubjectSearchResult,
  UpdatePublisherPackageRequest
} from '../../models/content-store/content-store.model';
import { Page as Pagination } from '../../models/page';
import { Playlist, SearchPlaylist } from '../../models';
import { Page, SearchPage } from '../../models/page/page.model';
import { ItemWithUploadUrl } from '../../models/ItemWithUploadUrl';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const CONTENT_PACKAGE_DATA_SERVICE =
  new InjectionToken<ContentPackageDataService>('[Content Package] ContentPackageDataService');

/** The service handling tag CRUD operations. */
export interface ContentPackageDataService {

  submitPlaylist(request: SubmitContentStoreRequest): ObservableResult<void>;

  updateContentStoreSettings(request: SubmitContentStoreRequest): ObservableResult<void>;

  createPublisher(request: CreatePublisherRequest): ObservableResult<Publisher>;

  updatePublisher(publisherUid: string, request: CreatePublisherRequest): ObservableResult<Publisher>;

  getCurrentOrganizationPublisher(): ObservableResult<Publisher>;

  getPublisherByUid(publisherUid: string): ObservableResult<Publisher>;

  getPublisherByUserUid(userUid: string): ObservableResult<Publisher>;

  getSubmittedPlaylists(page: number, size: number): ObservableResult<Pagination<Playlist>>;

  getSubmittedPages(page: number, size: number): ObservableResult<Pagination<SearchPage>>;

  getPublisherPages(publisherUid: string, page: number, size: number): ObservableResult<Pagination<PageContentStoreSummary>>;

  getPublisherPlaylists(publisherUid: string, page: number, size: number): ObservableResult<Pagination<Playlist>>;

  searchPlaylists(text: string, page?: number, size?: number): ObservableResult<Pagination<SearchPlaylist>>;

  syndicatePlaylist(itemUid: string, request: AddPlaylistFromStoreRequest): ObservableResult<SyndicationResponse>;

  syndicatePage(pageUid: string): ObservableResult<Page>;

  createPublisherPackage(request: CreatePublisherPackageRequest): ObservableResult<ItemWithUploadUrl<PublisherPackage>>;

  deleteContentPackageThumbnail(contentPackageUid: string): ObservableResult<void>;

  updatePublisherPackage(contentPackageUid: string, request: UpdatePublisherPackageRequest)
    : ObservableResult<ItemWithUploadUrl<PublisherPackage>>;

  updateContentPackageThumbnail(contentPackageUid: string, request: ContentPackageThumbnailUpdateRequest)
    : ObservableResult<ItemWithUploadUrl<PublisherPackage>>;

  getPublisherPackages(publisherUid: string, page: number, size: number, term?: string)
    : ObservableResult<Pagination<PublisherPackage>>;

  addContentPackageItem(contentPackageId: string, request: ContentPackagePageOrPlaylistCreateRequest)
    : ObservableResult<PublisherPackage>;

  deleteContentPackageItem(contentPackageId: string, itemUid: string)
    : ObservableResult<PublisherPackage>;

  createContentPackageLicense(contentPackageId: string, request: PublisherPackageLicense)
    : ObservableResult<PublisherPackage>;

  updateContentPackageLicense(contentPackageId: string, request: PublisherPackageLicense)
    : ObservableResult<PublisherPackage>;

  getPackageDetailById(contentPackageUid: string): ObservableResult<PublisherPackage>;

  getPackageSubscribers(contentPackageUid: string, page: number, size: number, term?: string)
    : ObservableResult<Pagination<PackageSubscriber>>;

  unsubscribeFromPackage(contentPackageUid: string): ObservableResult<void>;

  subscribeToPackage(contentPackageUid: string): ObservableResult<void>;

  packageAddInstitutionalAdmin(adminUid: string): ObservableResult<void>;

  packageRemoveInstitutionalAdmin(adminUid: string): ObservableResult<void>;

  packageAddAnalyticsAccess(adminUid: string): ObservableResult<void>;

  packageRemoveAnalyticsAccess(adminUid: string): ObservableResult<void>;

  addDataForm(contentPackageUid: string, dataFormUid: string): ObservableResult<void>;

  removeDataForm(contentPackageUid: string, dataFormUid: string): ObservableResult<void>;

  createTailoredSubjectsList(organizationId: string, packageId: string, request: TailoredSubjectRequest): ObservableResult<void>;

  createTailoredDepartmentsList(organizationId: string, packageId: string, request: TailoredSubjectRequest): ObservableResult<void>;

  updateTailoredSubjectsList(listUid: string, packageUid: string, request: TailoredSubjectRequest):
    ObservableResult<void>;

  updateTailoredDepartmentsList(listUid: string, packageUid: string, request: TailoredSubjectRequest):
    ObservableResult<void>;

  searchTailoredSubjects(page: number, size: number, organizationUid: string, packageUid: string, term: string):
    ObservableResult<Pagination<TailoredSubjectSearchResult>>;

  searchTailoredDepartments(page: number, size: number, organizationUid: string, packageUid: string, term: string):
    ObservableResult<Pagination<TailoredSubjectSearchResult>>;

  getJacs3List(): ObservableResult<StandardTailoredSubjectList>;

  getOfqualList(): ObservableResult<StandardTailoredSubjectList>;

  getTailoredSubjects(organizationId: string, packageId: string): ObservableResult<TailoredSubjectList>;

  getTailoredDepartments(organizationId: string, packageId: string): ObservableResult<TailoredSubjectList>;

}
