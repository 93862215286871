/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectHelper } from '../../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ptl-data-load-error-panel',
  templateUrl: './data-load-error-panel.component.html',
  styleUrls: ['./data-load-error-panel.component.scss'],
})
export class DataLoadErrorComponent implements OnInit {

  @Select(UserAuthState.homePageUri)
  homePageUri$: Observable<string>;

  /** The error message to display */
  @Input() errorMessage: string;

  @Input() dataForms: string[];

  @Input() hideBackButton: boolean;

  private homePageUri: string;

  constructor(private ngZone: NgZone, private activatedRoute: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.homePageUri$.pipe(filter(data => !!data)).subscribe(data => this.homePageUri = data);
  }

  backToHome() {
    RedirectHelper.redirectToHomePage(this.ngZone, this.router, this.activatedRoute, this.homePageUri);
  }
}
