/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import {
  ChartEditorContent,
  PIReport,
  PIScore
} from '../../../../shared/models/pi/pi.model';
import { PI_DATA_SERVICE, PIDataService } from '../../../../shared/services/pi/pi-data.service';
import { spectrumTraits } from '../../pi-questions/pi-report/report-constants';

@Component({
  selector: 'ptl-pi-spectrum-chart',
  templateUrl: './pi-spectrum-chart.component.html',
  styleUrls: ['./pi-spectrum-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalIndicatorSpectrumChartComponent implements OnInit {

  @Input() content: ChartEditorContent;

  @Input() showStaticData: boolean;

  @Input() reports: PIReport[];

  @Input() isPIReport: boolean;

  piReport: PIReport[];
  chartData: PIScore[];

  readonly traitMapping = spectrumTraits;


  constructor(
    @Inject(PI_DATA_SERVICE) private piDataService: PIDataService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (!this.showStaticData && this.content) {
      if (this.isPIReport && this.reports) {
        this.setReportData(this.reports);
        return;
      }
      this.piDataService.getPIReports(this.content.piCardId).subscribe(({ isSuccess, value }) => {
        if (isSuccess && value.reports.length) {
          this.setReportData(value.reports);
        }
      });
    }
  }


  private setReportData(reports: PIReport[]) {
    this.piReport = reports;
    this.chartData = [ ...this.piReport[0].traitScoreReport.traitScores ];
    this.chartData.sort(this.compareFunction);
    this.cd.detectChanges();
  }

  private compareFunction = (a: PIScore, b: PIScore): number => {
    if (!this.traitMapping[a.name] || this.traitMapping[a.name]?.imageUrl < this.traitMapping[b.name]?.imageUrl) {
      return -1;
    } else if (!this.traitMapping[b.name] || this.traitMapping[a.name]?.imageUrl > this.traitMapping[b.name]?.imageUrl) {
      return 1;
    } else {
      return 0;
    }
  }
}
