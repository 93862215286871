import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Form, FormQuestionContent, PresentationMode, UserPlaylistSubmissionSummary } from '../../../../models';
import { LearnerFormAnswer } from '../../../../../page-modules/resource/models';
import { Store } from '@ngxs/store';
import {
  RefreshStateAfterFormSubmission,
  SaveFormAnswer
} from '../../../../../page-modules/resource/store/learner-view.actions';
import { takeUntil } from 'rxjs';
import { SHARED_LISTS_DATA_SERVICE, SharedListsDataService } from '../../../../services/lists/lists-data.service';
import { Result } from '../../../../store';
import { QuestionFormList, SubListItem } from '../../../../models/lists/list.model';
import { ContentHelper } from '../../../../helpers/content-helper';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../../../helpers/dialog/dialog.service';
import { TranslationService } from '../../../../services/translation/translation.service';
import {
  SubmitPlaylistReviewDialogComponent
} from '../../../../../page-modules/playlist/shared/view/playlist-action-bar/submit-dialog/submit-dialog.component';
import {
  RefreshUserPlaylistSubmissionSummary
} from '../../../../../page-modules/playlist/store/view/playlist-view.state.actions';
import {
  LEARNER_VIEW_DATA_SERVICE,
  LearnerViewDataService
} from '../../../../../page-modules/resource/services/data.service';
import { FormSavedEvent } from '../form-preview-event.model';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';
import { LanguageCodeHelper } from '../../../../helpers/language-code-helper';
import { LocalTimeHelper } from '../../../../../shared/helpers/local-time-helper';

@Component({
  selector: 'ptl-form-question-preview',
  templateUrl: './form-question-preview.component.html',
  styleUrls: ['./form-question-preview.component.scss'],
})
export class FormQuestionPreviewComponent implements OnInit, OnChanges, OnDestroy {
  /** Receives the textbox type object */
  @Input() formData: Form<FormQuestionContent>;
  @Input() learnerFormAnswer: LearnerFormAnswer;
  @Input() userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  @Input() isProjectResource: boolean;
  @Input() playlistUri: string;
  @Input() playlistUid: string;
  @Input() resourceUri: string;
  @Input() resourceUid: string;
  @Input() groupUid: string;
  @Input() publisherUri: string;
  @Input() packageUri: string;
  @Input() pageUri: string;
  @Input() languageCode: string;
  @Input() playlistTitle: string;

  canEdit = false;
  submissionInProgress = false;
  validationInProgress = false;
  validationProcessed = false;
  canNotEditReason: string;
  requiresResubmission = false;
  listTitle: string;

  existingUserAnswer: boolean;
  submitted = false;
  userAnswer = '';
  multiSelectedItems: Set<string> = new Set();
  defaultSelectedValues = [];
  formSubList = [];
  maxSelection: number;
  currentSelectedOptionsCount = 0;
  presentationMode: PresentationMode;
  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private translationService: TranslationService,
    @Inject(LEARNER_VIEW_DATA_SERVICE) private dataService: LearnerViewDataService,
    @Inject(SHARED_LISTS_DATA_SERVICE) private sharedListsDataService: SharedListsDataService
  ) {
  }

  ngOnInit() {
    if (this.formData && this.formData.content) {
      const optionsListUid = this.formData.content['optionsListUid'];
      this.presentationMode = this.formData.content['presentationMode'];
      this.submitted = this.formData.content.submitted;
      this.maxSelection = this.formData.content.maxSelection;
      this.listTitle = this.formData.content['title'];

      if (optionsListUid) {
        this.getQuestionFormListByUid(optionsListUid);
      }
      if (this.formData.content && this.formData.content.userAnswer?.length > 0) {
        this.existingUserAnswer = true;
        if (this.presentationMode === 'RADIO') {
          this.multiSelectedItems = new Set([this.formData.content['userAnswer'].split(',')[0]]);
        } else {
          this.multiSelectedItems = new Set([...this.formData.content['userAnswer'].split(',')]);
        }
      }
    }

    this.checkSubmissionMode();
  }

  submit() {
    if (ContentHelper.isProgressiveSubmissionMode(this.userPlaylistSubmissionSummary)) {
      const dialogMessage =
        this.userPlaylistSubmissionSummary?.userReviewSummary?.reviewType &&
          this.userPlaylistSubmissionSummary.userReviewSummary.reviewType !== 'AUTOMATIC' ?
          this.translationService.getTranslation('dialog.title.formSubmissionReviewEnabled') :
          this.translationService.getTranslation('dialog.title.formSubmission');
      this.dialogService.showConfirmDialog(
        dialogMessage,
        this.translationService
      ).then(confirmed => {
        if (confirmed) {
          this.processSubmit();
        }
      }
      );
    } else {
      this.processSubmit();
    }
  }

  processSubmit() {
    this.existingUserAnswer = true;
    if (ContentHelper.shouldDisplayReviewersDialogOnFormSubmission(this.userPlaylistSubmissionSummary)) {
      this.openReviewersDialog();
    } else {
      this.submitCheckbox();
    }
  }

  submitCheckbox(): void {
    this.submissionInProgress = true;
    this.dataService.submitForm(this.playlistUid, this.resourceUid, this.formData.uid)
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe(({ isSuccess }) => {
        if (isSuccess) {
          this.refreshStateAndFinishSubmission();
        }
      });
  }

  getQuestionFormListByUid(optionsListUid: string) {
    this.sharedListsDataService.getFormListByOptionUid(optionsListUid)
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe({
        next: (res: Result<QuestionFormList>) => {
          if (res.value.root.sublist) {
            this.formSubList = res.value.root.sublist.map((subListItem: SubListItem) => ({
              ...subListItem,
              isChecked: this.multiSelectedItems.has(subListItem.uid)
            }));

            this.defaultSelectedValues = this.formSubList.filter(item => item.isChecked).map(item => item.uid);
            this.setCheckBoxesDisabled(this.multiSelectedItems.size, [...this.multiSelectedItems]);
          }
        },
        error: (error) => console.log(error)
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.playlistUid && this.resourceUid && this.formData?.uid) {
      this.checkSubmissionMode();
    } else {
      if (changes.learnerFormAnswer || changes.userPlaylistSubmissionSummary) {
        this.checkSubmissionMode();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptionEnd$?.emit();
  }

  onOptionChange(uid: string, el: MatSelect | MatCheckbox, isDisabled: boolean) {
    if (isDisabled) {
      return;
    }

    if (this.isMatCheckbox(el) && el.checked !== undefined) {
      this.handleCheckboxChange(uid, el.checked);
    } else if (this.isMatSelect(el)) {
      this.handleMatSelectChange(el.value);
    }

    const skippedUids = [...this.multiSelectedItems].flatMap((item) =>
      this.isMatSelect(el) ? item.split(',') : [item]
    );

    this.setCheckBoxesDisabled(skippedUids.length, skippedUids);
    this.saveAnswer(skippedUids.join(','));
  }

  onMultipleOptionChange(uid: string, el: MatSelect | MatCheckbox, isDisabled: boolean): void {
    if (isDisabled) {
      return;
    }

    const selectedItemsUids = el.value;

    this.formSubList?.forEach((item) => {
      item.isChecked = !!selectedItemsUids.includes(item.uid);
    })

    this.onOptionChange(uid, el, isDisabled);
  }

  removeTag(subListItem: SubListItem): void {
    const index = this.formSubList.findIndex(item => item.uid === subListItem.uid);

    if (index !== -1) {
      this.formSubList[index].isChecked = false;

      this.defaultSelectedValues = this.defaultSelectedValues.filter(uid => uid !== subListItem.uid);

      this.onOptionChange(subListItem.uid, { value: this.defaultSelectedValues } as MatSelect, false);
    }
  }

  onRadioOptionChange(uid: string) {
    this.multiSelectedItems.clear();
    this.multiSelectedItems.add(uid);
    this.saveAnswer([...this.multiSelectedItems].join(','));
  }

  private refreshStateAndFinishSubmission() {
    this.dataService.getCardWithProgress(
      this.isProjectResource,
      this.playlistUri,
      this.resourceUri,
      this.groupUid,
      this.publisherUri,
      this.packageUri,
      this.pageUri,
      this.languageCode
    )
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe(
        ({ isSuccess, value }) => {
          if (isSuccess) {
            this.store.dispatch(new RefreshStateAfterFormSubmission(value));
            this.store.dispatch(new RefreshUserPlaylistSubmissionSummary(this.playlistUid));
            this.finishSubmission();
          }
        });
  }

  get isSubmissionDisabled(): boolean {
    return !this.existingUserAnswer || this.submissionInProgress;
  }

  private openReviewersDialog() {
    const dialogRef = this.dialog.open(SubmitPlaylistReviewDialogComponent, {
      width: '90vw',
      minWidth: '15.625rem',
      maxWidth: '46.875rem',
      maxHeight: '33.75rem',
      restoreFocus: true,
      position: {
        top: '10vh',
      },
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      panelClass: 'ptl-mat-dialog',
      backdropClass: 'dialog-backdrop',
      data: {
        isProjectResource: this.isProjectResource,
        playlistUid: this.playlistUid,
        playlistTitle: this.playlistTitle,
        userPlaylistSubmissionSummary: this.userPlaylistSubmissionSummary,
        mode: 'FORM',
        resourceUid: this.resourceUid,
        formUid: this.formData.uid,
        playlistUri: this.playlistUri,
        resourceUri: this.resourceUri,
        groupUid: this.groupUid,
        publisherUri: this.publisherUri,
        packageUri: this.packageUri,
        pageUri: this.pageUri,
        languageCode: this.languageCode
      }
    });
    dialogRef.afterClosed()
      .pipe(takeUntil(this.subscriptionEnd$))
      .subscribe((data => {
        if (data) {
          this.finishSubmission();
        }
      }));
  }

  private finishSubmission() {
    this.submitted = true;
    this.submissionInProgress = false;
    this.checkSubmissionMode();
  }

  private checkSubmissionMode(): void {
    if (!this.learnerFormAnswer) {
      if (!this.playlistUid || !this.resourceUid || !this.formData?.uid) {
        return;
      }
      if (!this.validationInProgress && !this.validationProcessed) {
        this.validationInProgress = true;
        this.dataService.validateFormUpdate(this.playlistUid, this.resourceUid, this.formData?.uid)
          .pipe(takeUntil(this.subscriptionEnd$))
          .subscribe(({ isSuccess, value }) => {
            if (isSuccess) {
              this.canEdit = value.canBeUpdated;
              this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translationService, value);
              this.requiresResubmission = false;
              this.validationProcessed = true;
            }
            this.checkSubmissionLocked();
            this.cdr.detectChanges();
            this.validationInProgress = false;
          });
      }
    } else {
      this.canEdit = this.learnerFormAnswer?.updatePermission?.canBeUpdated;
      this.canNotEditReason = ContentHelper.formCanNotBeEditedReason(this.translationService, this.learnerFormAnswer?.updatePermission);
      this.requiresResubmission = this.learnerFormAnswer?.requiresResubmission;
      this.checkSubmissionLocked();
      this.cdr.detectChanges();
    }
  }

  private checkSubmissionLocked() {
    if (this.userPlaylistSubmissionSummary?.submissionsLocked) {
      this.canEdit = false;
      this.canNotEditReason = this.translationService.getTranslation('formUpdateDisabledReasons.submissionClosed')
      this.requiresResubmission = false;
    }
  }

  private handleCheckboxChange(uid: string, isChecked: boolean) {
    if (isChecked) {
      this.multiSelectedItems.delete(uid);
    } else {
      this.multiSelectedItems.add(uid);
    }
  }

  private handleMatSelectChange(selectedValues: string[]) {
    this.multiSelectedItems.clear();
    if (selectedValues.length) {
      this.multiSelectedItems.add(selectedValues.join());
    }
  }

  private setCheckBoxesDisabled(selectedOptionCount: number, skippedUids: string[]) {
    this.formSubList.forEach(el => {
      el.isDisabled = !skippedUids.includes(el.uid) && selectedOptionCount >= this.maxSelection;
    });
  }

  private isMatCheckbox(element: MatSelect | MatCheckbox): element is MatCheckbox {
    return (element as MatCheckbox).checked !== undefined;
  }

  private isMatSelect(element: MatSelect | MatCheckbox): element is MatSelect {
    return (element as MatSelect).value !== undefined;
  }

  private saveAnswer(uid: string) {
    const event: FormSavedEvent = {
      formUid: this.formData.uid,
      answer: uid
    };

    this.existingUserAnswer = true;
    this.store.dispatch(new SaveFormAnswer(this.playlistUid, event));
  }
}
