/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MediaContent, MediaType, MediaUploadData } from '../../../../shared/models';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropDialogComponent } from '../../../../shared/components/image-crop-dialog/image-crop-dialog.component';
import { LanguageCodeHelper } from '../../../../shared/helpers/language-code-helper';

export const ALLOWED_DOCUMENT_EXTENSIONS = '.rtf,.odt,.doc,.docx,.ods,.xls,.xlsx,.odp,.ppt,.pptx,.pdf';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ptl-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, AfterViewInit {
  /** Allowed upload types */
  @Input() uploadType: 'IMAGE' | 'FILE' | 'VIDEO' | 'STREAM_VIDEO' | 'AUDIO';

  @Input() doNotShowButton: boolean;

  @Input() replace: boolean;

  /** Emits data onEnter key or file upload */
  @Output() mediaContentAdded = new EventEmitter<MediaContent>();

  @ViewChild('uploadInput') private uploadInput: ElementRef;

  private readonly fileUploadFileTypes = 'image/*,audio/*,video/*,' + ALLOWED_DOCUMENT_EXTENSIONS;
  private readonly imageUploadFileTypes = 'image/*';
  private readonly audioUploadFileTypes = 'audio/*';
  private readonly videoUploadFileTypes = 'video/*';
  fileUploadTypes = '';

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.uploadType === 'IMAGE') {
      this.fileUploadTypes = this.imageUploadFileTypes;
    } else if (this.uploadType === 'AUDIO') {
      this.fileUploadTypes = this.audioUploadFileTypes;
    } else if (this.uploadType === 'VIDEO' || this.uploadType === 'STREAM_VIDEO') {
      this.fileUploadTypes = this.videoUploadFileTypes;
    } else {
      this.fileUploadTypes = this.fileUploadFileTypes;
    }
  }

  ngAfterViewInit() {
    if (this.doNotShowButton) {
      this.uploadInput.nativeElement.click();
    }
  }

  onFileChanged(eventData: Event) {
    const selectedFile: File = (eventData.target as HTMLInputElement).files[0];
    const fileUploadData: MediaUploadData = {
      type: 'MEDIA_UPLOAD',
      file: selectedFile,
      replace: this.replace,
      uuid4: uuidv4(),
      mediaType: this.getFileType(selectedFile),
    };

    if (fileUploadData.mediaType === 'IMAGE') {
      const dialogRef = this.dialog.open(ImageCropDialogComponent, {
        width: '90vw',
        minWidth: '15.625rem',
        maxWidth: '37.5rem',
        maxHeight: '80vh',
        position: {
          top: '10vh',
        },
        direction: LanguageCodeHelper.getBodyLanguageDir(),
        panelClass: 'ptl-mat-dialog',
        backdropClass: 'dialog-backdrop',
        data: {
          imageFile: selectedFile,
          cropperShape: 'rectangle',
        },
      });

      const dialogSubscription = dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          fileUploadData.file = data.croppedImageFile;
          this.mediaContentAdded.emit(fileUploadData);
        }

        (eventData.target as HTMLInputElement).value = '';
        dialogSubscription.unsubscribe();
      });
    } else {
      this.mediaContentAdded.emit(fileUploadData);
    }
  }

  private getFileType(file: File): MediaType {
    if (file.type.match('image.*')) {
      return 'IMAGE';
    }
    if (file.type.match('video.*')) {
      if (this.uploadType === 'STREAM_VIDEO') {
        return 'STREAM_VIDEO';
      } else {
        return 'VIDEO';
      }
    }
    if (file.type.match('audio.*')) {
      return 'AUDIO';
    }
    return 'DOCUMENT';
  }

}
