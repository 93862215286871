/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserAuthState } from '../../store/user-auth.state';
import { Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Organization } from '../../../shared/models/organization';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../../page-modules/account/services/account-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { filter } from 'rxjs/operators';

@Component({
  templateUrl: './recovery-email-verification.component.html',
  styleUrls: ['./recovery-email-verification.component.scss'],
})
export class RecoveryEmailVerificationComponent implements OnInit {

  @Select(UserAuthState.homePageUri)
  private homePageUri$: Observable<string>;
  private homePageUri: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    private router: Router,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private translationService: TranslationService,
  ) {
  }

  ngOnInit() {
    this.homePageUri$.pipe(filter(data => !!data)).subscribe(data => this.homePageUri = data);
  }

  verifyRecoveryEmail() {
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');
    this.accountService.updateUserRecoveryEmail(token).subscribe(({ isSuccess, error }) => {
      if (isSuccess) {
        RedirectHelper.redirectToHomePage(this.ngZone, this.router, this.activatedRoute, this.homePageUri);
        SnackbarHelper.showTranslatableSnackBar(
          this.ngZone, this.snackBar, this.translationService, 'public.message.success.emailVerified'
        );
      } else {
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
      }
    });
  }

}
