<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

  <ng-container *ngIf="source">
    <div
      class="preview-element-document-container"
      [style.marginBottom]="isEditingContent ? '' : (((content?.caption ? 40 : 20) / 16) + 'rem')"
      [ngClass]='!isEditingContent ? "preview-element-document-container-position-" +
                                   (content?.position ? content?.position : "CENTER") : ""'>
      <div #previewElement class="preview-element-document" [style.borderWidth]="isEditingContent ? '0' : ''">
        <button class="exit-button" *ngIf="!showFullScreenButton && allowFullScreen" title="Exit from full screen" mat-icon-button (click)="exitFromFullScreen()">
          <mat-icon aria-hidden="true" inline="true">fullscreen_exit</mat-icon>
        </button>
        <iframe
          tabindex="0"
          title=""
          (load)="iframeLoad()"
          [class.preview-element-document--disabled]="isEditingContent"
          [class.allow-full-screen]="allowFullScreen"
          [src]="source"
        ></iframe>
        <div class="preview-element-footer" *ngIf="allowFullScreen">
          <button *ngIf="showFullScreenButton" title="Show in full screen" mat-icon-button (click)="showFullScreen(previewElement)">
            <mat-icon aria-hidden="true" inline="true">crop_free</mat-icon>
          </button>
          <button *ngIf="!showFullScreenButton" title="Exit from full screen" mat-icon-button (click)="exitFromFullScreen()">
            <mat-icon aria-hidden="true" inline="true">fullscreen_exit</mat-icon>
          </button>
        </div>
      </div>
      <div class="preview-element--content" *ngIf="content?.caption && !isEditingContent">
        <p
          tabindex="0"
          class="preview-element--caption"
          [class.link]="content?.url"
          [attr.aria-label]="content?.url ? ('translations.global.ariaLabels.openUrl' | transloco: {url: content?.url}) : content?.caption"
          (click)="openUrl()"
          (keydown.enter)="openUrl()">
          {{content?.caption}}</p>
      </div>
  </div>
  </ng-container>
  