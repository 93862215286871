/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { ReviewEventType } from '../models';

@Pipe({
  name: 'learnerReviewStatus'
})
export class ReviewStatusPipe implements PipeTransform {

  transform(value: ReviewEventType, type: 'dateLabel' | 'badge' | 'dashboard' | 'text', reviewEventStyle: boolean = false): string {
    if (type === 'dateLabel') {
      switch (value) {
        case ReviewEventType.RESUBMITTED:
          if (reviewEventStyle) {
            return 'translations.reviews.status.dateLabel.resubmittedOn';
          } else {
            return 'translations.reviews.status.dateLabel.2ndVersionSentOn';
          }
        case ReviewEventType.REJECTED_TO_REVIEW:
          return 'translations.reviews.status.dateLabel.reviewRequestRejectedOn';
        case ReviewEventType.ACCEPTED:
          return 'translations.reviews.status.dateLabel.approvedOn';
        case ReviewEventType.CLOSED:
          return 'translations.reviews.status.dateLabel.closedOn';
        case ReviewEventType.REOPENED:
          return 'translations.reviews.status.dateLabel.reopenedOn';
        case ReviewEventType.SUBMITTED_FOR_REVIEW:
          return 'translations.reviews.status.dateLabel.submittedOn';
        case ReviewEventType.REQUIRES_RESUBMISSION:
          return 'translations.reviews.status.dateLabel.changesRequested';
      }
    }

    if (type === 'text') {
      return 'translations.reviews.status.text.' + value.toLowerCase();
    }

    if (type === 'badge') {
      if (reviewEventStyle) {
        return ''
      } else {
        switch (value) {
          case ReviewEventType.SUBMITTED_FOR_REVIEW:
          case ReviewEventType.RESUBMITTED:
            return 'translations.reviews.status.badge.needsReview';
          case ReviewEventType.REQUIRES_RESUBMISSION:
            return 'translations.reviews.status.badge.changesRequested';
          default:
            return '';
        }
      }
    }

    if (type === 'dashboard') {
      switch (value) {
        case ReviewEventType.SUBMITTED_FOR_REVIEW:
          return 'translations.reviews.status.dashboard.needsReview';
        case ReviewEventType.RESUBMITTED:
          return 'translations.reviews.status.dashboard.needsAnotherReview';
        case ReviewEventType.REQUIRES_RESUBMISSION:
          return 'translations.reviews.status.dashboard.changesRequested';
        case ReviewEventType.ACCEPTED:
          return 'translations.reviews.status.dashboard.approved';
        case ReviewEventType.REJECTED_TO_REVIEW:
          return 'translations.reviews.status.dashboard.reviewRequestRejected';
        case ReviewEventType.CLOSED:
          return 'translations.reviews.status.dashboard.closed';
        case ReviewEventType.REOPENED:
          return 'translations.reviews.status.dashboard.reOpened';
        default:
          return '';
      }
    }

    return '';
  }

}
