<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="!newVersionEnabled">
  <ul class="framework-tags__frameworks-list" role="list">
    <li
      class="framework-tags__frameworks-list-item"
      [class.selected]="framework.active"
      *ngFor="let framework of frameworks"
      tabindex="0"
      role="listitem"
      [attr.aria-label]="('translations.global.ariaLabels.loadFrameworkTags' | transloco: {frameworkName: framework.title})"
      (click)="onLoadFrameworkTags($event, framework)"
      (keydown.enter)="onLoadFrameworkTags($event, framework); $event.stopPropagation()">
      <span class="framework-tags__frameworks-list-item-span">{{ framework.title }}</span>
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="newVersionEnabled">
  <ul class="frameworks-list" role="list">
    <li
      class="frameworks-list-item small"
      [class.selected]="framework.active"
      [ngClass]="'f_framework-list-item f_framework-item-' + (framework._id ?? framework.uid)"
      *ngFor="let framework of frameworks"
      tabindex="0"
      role="listitem"
      [attr.aria-label]="('translations.global.ariaLabels.loadFrameworkTags' | transloco: {frameworkName: framework.title})"
      (click)="onLoadFrameworkTags($event, framework)"
      (keydown.enter)="onLoadFrameworkTags($event, framework); $event.stopPropagation()">
      {{ framework.title }}
    </li>
    <ng-container *ngIf="isLtiModule">
      <li
        [class.selected]="createNewFrameworkVisible"
        class="frameworks-list-item small"
        tabindex="0"
        role="listitem"
        (click)="onOpenCreateFramework()"
        (keydown.enter)="onOpenCreateFramework(); $event.stopPropagation()">
        {{ 'translations.framework.button.plusCreateNewFramework' | transloco }}
      </li>
    </ng-container>
  </ul>

</ng-container>
