/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { MembersDataService } from '../page-modules/admin/services/members/members-data.service';
import { RestClientService } from './services/rest-client.service';
import { environment } from '../../environments/environment';
import { MockMembersDataService } from '../page-modules/admin/services/members/mock-members-data.service';
import { ApiMembersDataService } from '../page-modules/admin/services/members/api-members-data.service';
import { PlaylistDataService } from '../page-modules/playlist/services/create/core/data.service';
import { MockPlaylistDataService } from '../page-modules/playlist/services/create/core/mock-data.service';
import { ApiPlaylistDataService } from '../page-modules/playlist/services/create/core/api-data.service';
import { ResourceDataService } from '../page-modules/resource/services/editor/core/data.service';
import { MockResourceDataService } from '../page-modules/resource/services/editor/core/mock-data.service';
import { ApiResourceDataService } from '../page-modules/resource/services/editor/core/api-data.service';
import { RolesDataService } from '../page-modules/admin/services/roles/roles-data.service';
import { MockRolesDataService } from '../page-modules/admin/services/roles/mock-roles-data.service';
import { ApiRolesDataService } from '../page-modules/admin/services/roles/api-roles-data.service';
import { MockPlaylistResourceCardsDataService } from '../page-modules/playlist/services/create/cards/mock-data.service';
import { ApiCardsDataService } from '../page-modules/playlist/services/create/cards/api-data.service';
import { UserGroupsDataService } from '../page-modules/admin/services/groups/groups-data.service';
import { MockUserGroupsDataService } from '../page-modules/admin/services/groups/groups-mock-data.service';
import { ApiUserGroupsDataService } from '../page-modules/admin/services/groups/groups-api-data.service';
import { OrganizationsDataService } from '../page-modules/admin/super-admin/organizations/services/data.service';
import { MockOrganizationsDataService } from '../page-modules/admin/super-admin/organizations/services/mock-data.service';
import { ApiOrganizationDataService } from '../page-modules/admin/super-admin/organizations/services/api-data.service';
import { PublicationDataService } from '../page-modules/playlist/services/create/publication/publication-data.service';
import { MockPublicationDataService } from '../page-modules/playlist/services/create/publication/mock-publication-data.service';
import { ApiPublicationDataService } from '../page-modules/playlist/services/create/publication/api-publication-data.service';
import { GlobalSearchDataService } from '../page-modules/global-search/services/global-search-data.service';
import { MockGlobalSearchDataService } from '../page-modules/global-search/services/global-search-mock-data.service';
import { ApiGlobalSearchDataService } from '../page-modules/global-search/services/global-search-api-data.service';
import { CompetenceDataService } from './services/competence/competence-data.service';
import { MockCompetenceDataService } from './services/competence/mock-competence-data.service';
import { ApiCompetenceDataService } from './services/competence/api-competence-data.service';
import { PageDataService } from '../page-modules/pages/services/data.service';
import { MockPageDataService } from '../page-modules/pages/services/mock-data.service';
import { ApiPageDataService } from '../page-modules/pages/services/api-data.service';
import { SharedUpcomingDataService } from './services/upcoming/upcoming-data.service';
import { MockSharedUpcomingDataService } from './services/upcoming/mock-upcoming-data.service';
import { ApiSharedUpcomingDataService } from './services/upcoming/api-upcoming-data.service';
import { TranslationService } from './services/translation/translation.service';
import { LearnerViewDataService } from '../page-modules/resource/services/data.service';
import { MockLearnerViewDataService } from '../page-modules/resource/services/mock-data.service';
import { ApiLearnerViewDataService } from '../page-modules/resource/services/api-data.service';
import { ContentPackageDataService } from './services/content-package/content-package-data.service';
import { MockContentPackageDataService } from './services/content-package/mock-content-package-data.service';
import { ApiContentPackageDataService } from './services/content-package/api-content-package-data.service';
import { BookingDataService } from './services/booking/booking-data.service';
import { MockBookingDataService } from './services/booking/mock-booking-data.service';
import { ApiBookingDataService } from './services/booking/api-booking-data.service';
import { MockUnsplashImagesDataService } from '../page-modules/admin/services/images/mock-unsplash-images-data.service';
import { ApiUnsplashImagesDataService } from '../page-modules/admin/services/images/api-unsplash-images-data.service';
import { UnsplashImagesDataService } from '../page-modules/admin/services/images/unsplash-images-data.service';
import { DiagnosticsDataService } from '../page-modules/diagnostics/services/data.service';
import { MockDiagnosticsDataService } from '../page-modules/diagnostics/services/mock-data.service';
import { ApiDiagnosticsDataService } from '../page-modules/diagnostics/services/api-data.service';
import { EventCardsDataService } from '../page-modules/event-cards/services/data.service';
import { MockEventCardsDataService } from '../page-modules/event-cards/services/mock-data.service';
import { ApiEventCardsDataService } from '../page-modules/event-cards/services/api-data.service';
import { MockImportUsersService } from '../page-modules/admin/services/import-members/mock-import-users.service';
import { ApiImportUsersService } from '../page-modules/admin/services/import-members/api-import-users.service';
import { ImportUsersService } from '../page-modules/admin/services/import-members/import-users.service';
import { HelpCenterDataService } from './services/help-center/help-center-data.service';
import { MockHelpCenterDataService } from './services/help-center/mock-help-center-data.service';
import { ApiHelpCenterDataService } from './services/help-center/api-help-center-data.service';
import { AllContentDataService } from '../page-modules/admin/services/all-content/all-content-data.service';
import { MockAllContentDataService } from '../page-modules/admin/services/all-content/mock-all-content-data.service';
import { ApiAllContentDataService } from '../page-modules/admin/services/all-content/api-all-content-data.service';
import { ApiFolioDataService } from '../page-modules/folio/services/api-data.service';
import { FolioDataService } from '../page-modules/folio/services/data.service';
import { MockFolioDataService } from '../page-modules/folio/services/mock-data.service';
import { ProjectDataService } from '../page-modules/project/services/data.service';
import { MockProjectDataService } from '../page-modules/project/services/mock-data.service';
import { ApiProjectDataService } from '../page-modules/project/services/api-data.service';
import { Router } from '@angular/router';
import { RequestQueueManagerService } from './services/request-queue-manager/request-queue-manager';

export function membersServiceFactory(client: RestClientService, translationService: TranslationService): MembersDataService {
  if (environment.dataProvider === 'mock') {
    return new MockMembersDataService();
  }

  return new ApiMembersDataService(client, translationService);
}

export function playlistServiceFactory(client: RestClientService, translationService: TranslationService): PlaylistDataService {
  if (environment.dataProvider === 'mock') {
    return new MockPlaylistDataService();
  }

  return new ApiPlaylistDataService(client, translationService);
}

/** Provides the core resource data service. */
export function resourceServiceFactory(client: RestClientService, translationService: TranslationService,
  requestQueueManager: RequestQueueManagerService<unknown>): ResourceDataService {
  if (environment.dataProvider === 'mock') {
    return new MockResourceDataService();
  }

  return new ApiResourceDataService(client, translationService, requestQueueManager);
}

export function rolesServiceFactory(client: RestClientService, translationService: TranslationService): RolesDataService {
  if (environment.dataProvider === 'mock') {
    return new MockRolesDataService();
  }

  return new ApiRolesDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides the playlist cards data service. */
export function playlistCardsServiceFactory(client: RestClientService, translationService: TranslationService) {
  if (environment.dataProvider === 'mock') {
    return new MockPlaylistResourceCardsDataService();
  }

  return new ApiCardsDataService(client, translationService);
}

export function userGroupsServiceFactory(client: RestClientService, translationService: TranslationService): UserGroupsDataService {
  if (environment.dataProvider === 'mock') {
    return new MockUserGroupsDataService();
  }

  return new ApiUserGroupsDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides the core organization data service. */
export function organizationsServiceFactory(client: RestClientService, translationService: TranslationService): OrganizationsDataService {
  if (environment.dataProvider === 'mock') {
    return new MockOrganizationsDataService();
  }

  return new ApiOrganizationDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides the playlist publication data service. */
export function playlistPublicationServiceFactory(
  client: RestClientService, translationService: TranslationService
): PublicationDataService {
  if (environment.dataProvider === 'mock') {
    return new MockPublicationDataService();
  }

  return new ApiPublicationDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides the playlist goal data service. */
export function globalSearchServiceFactory(client: RestClientService, translationService: TranslationService): GlobalSearchDataService {
  if (environment.dataProvider === 'mock') {
    return new MockGlobalSearchDataService();
  }

  return new ApiGlobalSearchDataService(client, translationService);
}

export function competenceServiceFactory(client: RestClientService, translationService: TranslationService): CompetenceDataService {
  if (environment.dataProvider === 'mock') {
    return new MockCompetenceDataService();
  }

  return new ApiCompetenceDataService(client, translationService);
}

export function pageServiceFactory(client: RestClientService, translationService: TranslationService): PageDataService {
  if (environment.dataProvider === 'mock') {
    return new MockPageDataService();
  }

  return new ApiPageDataService(client, translationService);
}


export function upcomingServiceFactory(client: RestClientService, translationService: TranslationService): SharedUpcomingDataService {
  if (environment.dataProvider === 'mock') {
    return new MockSharedUpcomingDataService();
  }

  return new ApiSharedUpcomingDataService(client, translationService);
}

export function helpCenterServiceFactory(client: RestClientService, translationService: TranslationService): HelpCenterDataService {
  if (environment.dataProvider === 'mock') {
    return new MockHelpCenterDataService();
  }

  return new ApiHelpCenterDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides the data service. */
export function resourceLearnerViewServiceFactory(client: RestClientService, translationService: TranslationService)
  : LearnerViewDataService {
  if (environment.dataProvider === 'mock') {
    return new MockLearnerViewDataService();
  }

  return new ApiLearnerViewDataService(client, translationService);
}

// -------------------------------------------------------------------------
/** Provides the data service. */
export function diagnosticsServiceFactory(client: RestClientService, translationService: TranslationService): DiagnosticsDataService {
  if (environment.dataProvider === 'mock') {
    return new MockDiagnosticsDataService();
  }

  return new ApiDiagnosticsDataService(client, translationService);
}


export function contentStoreFactory(client: RestClientService, translationService: TranslationService): ContentPackageDataService {
  if (environment.dataProvider === 'mock') {
    return new MockContentPackageDataService();
  }

  return new ApiContentPackageDataService(client, translationService);
}

export function bookingServiceFactory(client: RestClientService, translationService: TranslationService): BookingDataService {
  if (environment.dataProvider === 'mock') {
    return new MockBookingDataService();
  }

  return new ApiBookingDataService(client, translationService);
}

export function unsplashServiceFactory(client: RestClientService, translationService: TranslationService): UnsplashImagesDataService {
  if (environment.dataProvider === 'mock') {
    return new MockUnsplashImagesDataService();
  }

  return new ApiUnsplashImagesDataService(client, translationService);
}

export function eventCardsServiceFactory(client: RestClientService, translationService: TranslationService): EventCardsDataService {
  if (environment.dataProvider === 'mock') {
    return new MockEventCardsDataService();
  }
  return new ApiEventCardsDataService(client, translationService);
}

export function importUsersServiceFactory(client: RestClientService, translationService: TranslationService): ImportUsersService {
  if (environment.dataProvider === 'mock') {
    return new MockImportUsersService();
  }

  return new ApiImportUsersService(client, translationService);
}

export function allContentServiceFactory(client: RestClientService, translationService: TranslationService): AllContentDataService {
  if (environment.dataProvider === 'mock') {
    return new MockAllContentDataService();
  }
  return new ApiAllContentDataService(client, translationService);
}

export function folioServiceFactory(client: RestClientService, translationService: TranslationService): FolioDataService {
  if (environment.dataProvider === 'mock') {
    return new MockFolioDataService();
  }

  return new ApiFolioDataService(client, translationService);
}

export function projectServiceFactory(
  client: RestClientService,
  translationService: TranslationService
): ProjectDataService {
  if (environment.dataProvider === 'mock') {
    return new MockProjectDataService();
  }

  return new ApiProjectDataService(client, translationService);
}