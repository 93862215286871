<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<mat-card class="moment-card"
          [attr.aria-label]="('translations.global.ariaLabels.open' | transloco: {title: card?.title})"
          (keydown.enter)="openMomentForView()"
          (click)="openMomentForView()"
          tabindex="0"
          [style.cursor]="(card.viewType === 'COLLECTOR') ? 'initial' : ''">

  <div class="moment-card__top">
    <div class="moment-card__top__icon-container-wrapper">
      <div class="moment-card__top__icon-container">
        <i class="material-icons icon-container__icon" aria-hidden="true">
          insert_drive_file
        </i>
      </div>
    </div>


    <button
      *ngIf="card.viewType === 'REMOVE'"
      mat-icon-button
      class="moment-card__remove-button"
      [title]="'Remove'"
      (click)="onRemoveMoment(card._id); $event.stopPropagation();">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>

    <ptl-masonry-action-button *ngIf="card.viewType === 'EDIT'"
                               [card]="card"
                               [archiveMoment]="archiveMoment"
                               (cardArchived)="onArchiveMoment($event)"
                               (cardRemoved)="onRemoveMoment($event)"></ptl-masonry-action-button>
    <button
      *ngIf="card.viewType === 'COLLECTOR'"
      mat-icon-button
      class="moment-card__button"
      [title]="selected ? 'Remove' : 'Add'"
      [class.is_selected]="selected"
      (click)="onButtonClick(); $event.stopPropagation();">
      <mat-icon aria-hidden="true" [inline]="true">add</mat-icon>
    </button>
  </div>

  <div class="moment-card__center">
    <ng-container *ngIf="card?.image; else placeholderImage">
      <ptl-optimized-image-loader
        class="moment-card__loader"
        *ngIf="!card.imageExternal"
        [src]="card?.image"
      ></ptl-optimized-image-loader>
      <img
        class="moment-card__loader"
        *ngIf="card.imageExternal"
        [src]="card?.image"
        width="266" height="150"
        alt=""
      />
    </ng-container>
    <ng-template #placeholderImage>
      <div class="moment-card__empty-image-placeholder">
        <i class="material-icons" aria-hidden="true">insert_drive_file</i>
      </div>
    </ng-template>
  </div>

  <div class="moment-card__footer">
    <div class="moment-card__description">
      {{ card?.title }}
    </div>
  </div>
</mat-card>
