/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { EditorContentViewComponent } from './components/editor-content-view/editor-content-view.component';
import { EditorToolbarComponent } from './components/editor-toolbar/toolbar.component';
import {
  MediaEmbedsComponent
} from './components/editor-content-view/editor-content/media-embeds/media-embeds.component';
import {
  ExternalContentSummaryComponent
} from './components/editor-content-view/editor-content/external-content-summary/external-content-summary.component';

import { HttpClient } from '@angular/common/http';
import {
  EMBEDS_CONTENT_GENERATOR_SERVICE,
  EmbedsContentGeneratorService
} from './services/embeds-content-generator.service';
import { environment } from '../../environments/environment';
import { IframelyEmbedsContentGeneratorService } from './services/iframely-embeds-content-generator.service';

import { FormComponent } from './components/shared/form/form.component';
import { FormTextboxComponent } from './components/shared/form/form-textbox/form-textbox.component';
import { FormCheckboxComponent } from './components/shared/form/form-checkbox/form-checkbox.component';
import { FormFileUploadComponent } from './components/shared/form/form-file-upload/form-file-upload.component';
import { EditorContentComponent } from './components/editor-content-view/editor-content/editor-content.component';
import {
  MediumEditorComponent
} from './components/editor-content-view/editor-content/medium-editor/medium-editor.component';
import { EditorMainComponent } from './components/editor-main/editor-main.component';
import {
  MediaUploadsComponent
} from './components/editor-content-view/editor-content/media-uploads/media-uploads.component';
import {
  UploadsPlaceholderComponent
} from './components/editor-content-view/editor-content/media-uploads/uploads-placeholder/uploads-placeholder.component';
import {
  MediaContentViewComponent
} from './components/editor-content-view/editor-content/media-content/media-content.component';
import { SharedModule } from '../shared/shared.module';
import { SeparatorComponent } from './components/shared/separator/separator.component';
import { LinkInputComponent } from './components/editor-toolbar/link-input/link-input.component';
import { FileUploadComponent } from './components/editor-toolbar/file-upload/file-upload.component';
import { QuizComponent } from './components/shared/quiz/quiz.component';
import { QuizInstructionsComponent } from './components/shared/quiz/quiz-instructions/quiz-instructions.component';
import { QuizQuestionsComponent } from './components/shared/quiz/quiz-questions/quiz-questions.component';
import { QuizSettingsComponent } from './components/shared/quiz/quiz-settings/quiz-settings.component';
import { QuestionsComponent } from './components/shared/questions/questions.component';
import { EditQuestionComponent } from './components/shared/questions/edit-question/edit-question.component';
import { QuestionsListComponent } from './components/shared/questions/questions-list/questions-list.component';
import {
  SliderQuestionTypeComponent
} from './components/shared/questions/edit-question/slider-question-type/slider-question-type.component';
import {
  GridQuestionTypeComponent
} from './components/shared/questions/edit-question/grid-question-type/grid-question-type.component';
import {
  ChoiceQuestionTypeComponent
} from './components/shared/questions/edit-question/choice-question-type/choice-question-type.component';
import {
  InfoQuestionTypeComponent
} from './components/shared/questions/edit-question/info-question-type/info-question-type.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  PassCriteriaPopupComponent
} from './components/shared/quiz/quiz-settings/pass-criteria-popup/pass-criteria-popup.component';
import { RestClientService } from '../shared/services/rest-client.service';
import { DATA_SERVICE, QuizDataService } from './services/data.service';
import { ApiQuizDataService } from './services/api-data.service';
import { MockQuizDataService } from './services/mock-data.service';
import {
  MultipleChoiceQuestionTypeComponent
} from './components/shared/questions/edit-question/multiple-choice-question-type/multiple-choice-question-type.component';
import {
  DragDropQuestionTypeComponent
} from './components/shared/questions/edit-question/drag-drop-question-type/drag-drop-question-type.component';
import {
  TrueOrFalseChoiceQuestionTypeComponent
} from './components/shared/questions/edit-question/true-or-false-choice-question-type/true-or-false-choice-question-type.component';
import { ChartComponent } from './components/shared/chart/chart.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CollectorComponent } from './components/shared/collector/collector.component';
import {
  CollectorFormContentComponent
} from './components/shared/collector/collector-form-content/collector-form-content.component';
import {
  CollectorFormLogComponent
} from './components/shared/collector/collector-form-log/collector-form-log.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslationService } from '../shared/services/translation/translation.service';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { EmbedCodeComponent } from './components/editor-content-view/editor-content/embed-code/embed-code.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DataFormsMainComponent } from '../shared/components/data-forms/data-forms-main/data-forms-main.component';
import { EditFieldsComponent } from '../shared/components/data-forms/edit-data-form/edit-fields/edit-fields.component';
import {
  EditGroupListComponent
} from '../shared/components/data-forms/edit-data-form/edit-group-list/edit-group-list.component';
import {
  GroupListCsvUploadDialogComponent
} from '../shared/components/data-forms/edit-data-form/group-list-csv-upload-dialog/group-list-csv-upload-dialog.component';
import {
  EditDataFormGroupsComponent
} from '../shared/components/data-forms/edit-data-form/edit-data-form-groups/edit-data-form-groups.component';
import {
  EditDataFormMainComponent
} from '../shared/components/data-forms/edit-data-form/edit-data-form-main/edit-data-form-main.component';
import {
  EditDependenciesComponent
} from '../shared/components/data-forms/edit-data-form/edit-dependencies/edit-dependencies.component';
import {
  TinymceEditorComponent
} from './components/editor-content-view/editor-content/tinymce-editor/tinymce-editor.component';
import { EditorToolbarNewComponent } from './components/editor-toolbar-new/editor-toolbar-new.component';
import {
  EditorToolbarOptionComponent
} from './components/editor-toolbar-new/editor-toolbar-option/editor-toolbar-option.component';
import {
  PassCriteriaCountComponent
} from './components/shared/quiz/quiz-settings/pass-criteria-count/pass-criteria-count.component';
import { FormBookingComponent } from './components/shared/form/form-booking/form-booking.component';
import { BOOKING_DATA_SERVICE } from '../shared/services/booking/booking-data.service';
import { MEMBERS_DATA_SERVICE } from '../page-modules/admin/services/members/members-data.service';
import * as SharedFactoryService from '../shared/shared-service-factory';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UNSPLASH_IMAGES_DATA_SERVICE } from '../page-modules/admin/services/images/unsplash-images-data.service';
import {
  FormBookingResourceComponent
} from './components/shared/form/form-booking/form-booking-resource/form-booking-resource.component';
import {
  DataFormsMainNewComponent
} from '../shared/components/data-forms/data-forms-main-new/data-forms-main-new.component';
import {
  EditFieldsNewComponent
} from '../shared/components/data-forms/edit-data-form/edit-fields-new/edit-fields-new.component';
import {
  EditGroupListNewComponent
} from '../shared/components/data-forms/edit-data-form/edit-group-list-new/edit-group-list-new.component';
import {
  EditDataFormMainNewComponent
} from '../shared/components/data-forms/edit-data-form/edit-data-form-main-new/edit-data-form-main-new.component';
import {
  EditCheckboxListComponent
} from '../shared/components/data-forms/edit-data-form/edit-checkbox-list/edit-checkbox-list.component';
import {
  FormBookingResourceItemComponent
} from './components/shared/form/form-booking/form-booking-resource/form-booking-resource-item/form-booking-resource-item.component';
import { FormPrivateNoteComponent } from './components/shared/form/form-private-note/form-private-note.component';
import {
  SendMessageDialogComponent
} from '../page-modules/admin/components/members/admin-member-action-bar/send-message-dialog/send-message-dialog.component';
import { TableComponent } from './components/editor-content-view/editor-content/table/table.component';
import { QuestionsNewComponent } from './components/shared/questions-new/questions-new.component';
import {
  QuestionsListNewComponent
} from './components/shared/questions/questions-list-new/questions-list-new.component';
import { EditQuestionNewComponent } from './components/shared/questions/edit-question-new/edit-question-new.component';
import {
  ChoiceQuestionTypeNewComponent
} from './components/shared/questions/edit-question/choice-question-type-new/choice-question-type-new.component';
import {
  SliderQuestionTypeNewComponent
} from './components/shared/questions/edit-question/slider-question-type-new/slider-question-type-new.component';
import {
  DragDropQuestionTypeNewComponent
} from './components/shared/questions/edit-question/drag-drop-quesition-type-new/drag-drop-question-type-new.component';
import {
  FormConnectedTextboxComponent
} from './components/shared/form/form-connected-textbox/form-connected-textbox.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormQuestionComponent } from './components/shared/form/form-question/form-question.component';
import {
  GridQuestionTypeNewComponent
} from './components/shared/questions/edit-question/grid-question-type-new/grid-question-type-new.component';
import { AI_ASSISTANT_SERVICE, AiAssistantService } from './services/assistant/ai-assistant.service';
import { MockAiAssistantDataService } from './services/assistant/ai-assistant-mock-data.service';
import { ApiAiAssistantDataService } from './services/assistant/ai-assistant-api-data.service';
import {
  QuestionInstructionComponent
} from './components/shared/questions/edit-question/question-instruction/question-instruction.component';
import {
  FormAIAssistantSettingsComponent
} from './components/shared/form/form-textbox/form-ai-assistant-settings/form-ai-assistant-settings.component';
import {
  FormAiSearchDialogComponent
} from './components/shared/form/form-textbox/form-ai-search-dialog/form-ai-search-dialog.component';
import { SliderBarComponent } from './components/shared/questions/slider-bar/slider-bar.component';
import { ChartNewComponent } from './components/shared/chart-new/chart-new.component';
import {
  ExternalEditorContentSummaryComponent
} from '@app/app/shared/editor-js/components/external-content-summary/external-editor-content-summary.component';
import { WordLimitDialogComponent } from '../shared/components/resource-preview/form-preview/form-textbox/word-limit-dialog/word-limit-dialog.component';
/* eslint-enable max-len */
@NgModule({
  imports: [
    EditorModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatOptionModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    MatTooltipModule,
    SharedModule,
    MatCardModule,
    MatTableModule,
    DragDropModule,
    MatMenuModule,
    ColorPickerModule,
    TranslocoRootModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatAutocompleteModule
  ],
  declarations: [
    EditorContentComponent,
    EditorToolbarComponent,
    EditorToolbarNewComponent,
    EditorToolbarOptionComponent,
    MediaEmbedsComponent,
    EmbedCodeComponent,
    FormComponent,
    FormTextboxComponent,
    FormPrivateNoteComponent,
    FormCheckboxComponent,
    FormFileUploadComponent,
    FormBookingComponent,
    FormBookingResourceComponent,
    FormBookingResourceItemComponent,
    EditorContentComponent,
    MediumEditorComponent,
    TinymceEditorComponent,
    EditorContentViewComponent,
    EditorMainComponent,
    MediaUploadsComponent,
    UploadsPlaceholderComponent,
    MediaContentViewComponent,
    SeparatorComponent,
    ExternalContentSummaryComponent,
    LinkInputComponent,
    FileUploadComponent,
    ChartComponent,
    ChartNewComponent,
    QuizComponent,
    CollectorComponent,
    CollectorFormContentComponent,
    CollectorFormLogComponent,
    QuizInstructionsComponent,
    QuizQuestionsComponent,
    QuizSettingsComponent,
    QuestionsComponent,
    QuestionsNewComponent,
    EditQuestionComponent,
    EditQuestionNewComponent,
    SliderBarComponent,
    QuestionsListComponent,
    QuestionsListNewComponent,
    SliderQuestionTypeComponent,
    SliderQuestionTypeNewComponent,
    GridQuestionTypeComponent,
    GridQuestionTypeNewComponent,
    QuestionInstructionComponent,
    DragDropQuestionTypeComponent,
    DragDropQuestionTypeNewComponent,
    ChoiceQuestionTypeComponent,
    ChoiceQuestionTypeNewComponent,
    InfoQuestionTypeComponent,
    PassCriteriaPopupComponent,
    PassCriteriaCountComponent,
    MultipleChoiceQuestionTypeComponent,
    TrueOrFalseChoiceQuestionTypeComponent,
    DataFormsMainComponent,
    DataFormsMainNewComponent,
    EditDataFormMainComponent,
    EditDataFormMainNewComponent,
    EditDataFormGroupsComponent,
    EditFieldsComponent,
    EditFieldsNewComponent,
    EditDependenciesComponent,
    EditGroupListComponent,
    EditGroupListNewComponent,
    GroupListCsvUploadDialogComponent,
    EditCheckboxListComponent,
    SendMessageDialogComponent,
    EditCheckboxListComponent,
    TableComponent,
    FormConnectedTextboxComponent,
    FormQuestionComponent,
    FormAIAssistantSettingsComponent,
    FormAiSearchDialogComponent,
    WordLimitDialogComponent,
    ExternalEditorContentSummaryComponent
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    { provide: EMBEDS_CONTENT_GENERATOR_SERVICE, useFactory: embedsContentGeneratorServiceFactory, deps: [HttpClient] },
    { provide: DATA_SERVICE, useFactory: serviceFactory, deps: [RestClientService, TranslationService] },
    {
      provide: AI_ASSISTANT_SERVICE,
      useFactory: aiAssistantServiceFactory,
      deps: [RestClientService, TranslationService]
    },
    {
      provide: BOOKING_DATA_SERVICE,
      useFactory: SharedFactoryService.bookingServiceFactory,
      deps: [RestClientService, TranslationService]
    },
    {
      provide: MEMBERS_DATA_SERVICE,
      useFactory: SharedFactoryService.membersServiceFactory,
      deps: [RestClientService, TranslationService]
    },
    {
      provide: UNSPLASH_IMAGES_DATA_SERVICE,
      useFactory: SharedFactoryService.unsplashServiceFactory,
      deps: [RestClientService, TranslationService]
    }
  ],
  entryComponents: [
    CollectorFormLogComponent,
  ],
  exports: [
    EditorMainComponent,
    QuestionsComponent,
    QuestionsNewComponent,
    EditQuestionComponent,
    QuestionsListComponent,
    SliderQuestionTypeComponent,
    GridQuestionTypeComponent,
    GridQuestionTypeNewComponent,
    QuestionInstructionComponent,
    ChoiceQuestionTypeComponent,
    InfoQuestionTypeComponent,
    DataFormsMainComponent,
    DataFormsMainNewComponent
  ],
})
export class PtlEditorModule {
}

// -------------------------------------------------------------------------
/** Provides the embeds content generator data service. */
export function embedsContentGeneratorServiceFactory(client: HttpClient): EmbedsContentGeneratorService {
  if (environment.dataProvider === 'mock') {
    return new IframelyEmbedsContentGeneratorService(client);
  }

  return new IframelyEmbedsContentGeneratorService(client);
}


// -------------------------------------------------------------------------
/** Provides the data service. */
export function serviceFactory(client: RestClientService, translationService: TranslationService): QuizDataService {
  if (environment.dataProvider === 'mock') {
    return new MockQuizDataService();
  }

  return new ApiQuizDataService(client, translationService);
}

export function aiAssistantServiceFactory(client: RestClientService, translationService: TranslationService): AiAssistantService {
  if (environment.dataProvider === 'mock') {
    return new MockAiAssistantDataService();
  }

  return new ApiAiAssistantDataService(client, translationService);
}
