/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FileUploadHelper } from '../../helpers/file-upload-helper';
import { ImageCropperData } from '../../models/images/image-crop.model';
import { AnimatedGifHelper } from '../../helpers/animated-gif-helper';

@Component({
  selector: 'ptl-image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.scss'],
})

export class ImageCropDialogComponent implements OnInit {
  imageFile?: File;
  imageBase64?: string;

  maintainAspectRatio = false;
  aspectRatio = 1;
  roundCropper = false;
  resizeToWidth = 0;
  resizeToHeight = 0;
  imageFormat: 'jpeg' | 'png' | 'gif' = 'jpeg';
  backgroundColor: string | undefined = 'white';
  description: string;
  isAnimatedGif = false;

  private croppedImageFile?: File;
  private croppedImageDataUrl?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImageCropperData,
    private dialogRef: MatDialogRef<ImageCropDialogComponent>,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.croppedImageFile = this.data.imageFile;
    this.setInputImage(this.data.imageFile);
    this.maintainAspectRatio =
      this.data.cropperShape === 'square' ||
      this.data.cropperShape === 'circle' ||
      !!this.data.aspectRatio;
    this.roundCropper = this.data.cropperShape === 'circle';
    this.resizeToWidth = this.data.resizeToWidth ? this.data.resizeToWidth : 0;
    this.resizeToHeight = this.data.resizeToHeight ? this.data.resizeToHeight : 0;

    if (this.data.imageFormat === 'png') {
      this.imageFormat = 'png';
      this.backgroundColor = undefined;
    }

    if (this.data.imageFile?.type.includes('gif')) {
      this.imageFormat = 'gif';
    }

    if (this.data.aspectRatio && this.data.cropperShape === 'rectangle') {
      this.aspectRatio = this.data.aspectRatio;
    }
    this.description = this.data.description;
  }

  updateCroppedImage(event: ImageCroppedEvent): void {
    const base64 = event.base64;

    if (this.imageFormat.includes('gif')) {
      const file = this.data.imageFile;
      AnimatedGifHelper.isGifAnimated(file).then((isAnimated) => {
        this.isAnimatedGif = isAnimated;
        AnimatedGifHelper.readFileAsDataURL(file);
        setTimeout(() => {
          this.imageBase64 = AnimatedGifHelper.imageBase64;
          this.croppedImageDataUrl = this.imageBase64;
          this.cdr.detectChanges();
        }, 100);
      });
    } else {
      if (base64) {
        this.croppedImageDataUrl = base64;
      } else if (event.blob) {
        const reader = new FileReader();
        reader.readAsDataURL(event.blob);
        reader.onloadend = () => {
          this.croppedImageDataUrl = reader.result as string;
        };
      }
    }
  }

  saveCrop(): void {
    if (this.croppedImageDataUrl) {
      const cropedImage = FileUploadHelper.convertDataUrlToFile(
        this.croppedImageDataUrl,
        this.croppedImageFile.name.toLowerCase(),
        'image/' + this.imageFormat);
      if (cropedImage) {
        this.croppedImageFile = cropedImage;
      }
    }

    this.dialogRef.close({
      croppedImageFile: this.croppedImageFile,
      croppedImageDataUrl: this.croppedImageDataUrl,
    });
  }

  cancelCrop(): void {
    this.dialogRef.close();
  }

  private setInputImage(imageFile: File): void {
    if (imageFile?.type.includes('svg')) {
      const canvasEl = document.createElement('canvas') as HTMLCanvasElement;
      const context = canvasEl.getContext('2d');
      const image = new Image();
      const reader = new FileReader();

      reader.onload = (event) => {
        image.onload = () => {
          canvasEl.width = image.naturalWidth;
          canvasEl.height = image.naturalHeight;
          if (context) {
            context?.drawImage(image, 0, 0);
          }
          this.croppedImageDataUrl = this.imageBase64 = canvasEl.toDataURL('image/png');
        };

        if (event.target) {
          image.src = event.target.result as string;
        }
      };

      reader.readAsDataURL(imageFile);
    } else {
      this.imageFile = imageFile;
    }
  }
}
