/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { Observable, Subscription } from 'rxjs';
import { Organization } from '../../models';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../../page-modules/account/services/account-data.service';
import { UserDetails } from '../../../user-auth/models';
import { filter } from 'rxjs/operators';
import { AUTH_SERVICE, BasicAuthService } from '../../../user-auth/services/basic-auth.service';
import { CookieHelper } from '../../helpers/cookie-helper';
import { SnackbarHelper } from '../../helpers/snackbar-helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { CloseMobileNavMenu } from '../../../app-frame/store/app-frame.actions';

@Component({
  selector: 'ptl-workspaces-nav',
  templateUrl: './workspaces-nav.component.html',
  styleUrls: ['./workspaces-nav.component.scss'],
})
export class WorkspacesNavComponent implements OnInit, OnDestroy {

  @Select(UserAuthState.userHasSuperAdminRole)
  isUserSuperAdmin$: Observable<boolean>;

  @Select(UserAuthState.userHasAdminRole)
  isUserAdmin$: Observable<boolean>;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;

  @Select(UserAuthState.userDetailsData)
  private userDetailsData$: Observable<UserDetails>;

  @Select(UserAuthState.homePageUri)
  private homePageUri$: Observable<string>;

  domain: string;
  organization: Organization;
  userData: UserDetails;
  isFolioMainPage = false;

  private organizationDataSubscription: Subscription;
  private userDataSubscription: Subscription;
  private routerChangeSubscription: Subscription;
  private homePageUri: string;

  constructor(
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    @Inject(AUTH_SERVICE) private authService: BasicAuthService,
    private snackBar: MatSnackBar,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
  ) {
    this.organizationDataSubscription = this.organizationData$
      .pipe(filter(data => !!data))
      .subscribe(data => {
        this.organization = data;
        this.domain = data.domain;

        this.updateOrganizationLogo();
      });

    this.userDataSubscription = this.userDetailsData$.pipe(
    ).subscribe(data => {
      this.userData = data;
    });
    this.homePageUri$.pipe(filter(data => !!data)).subscribe(data => this.homePageUri = data);
  }

  ngOnInit(): void {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    this.routerChangeSubscription = this.router.events.subscribe((event: any) => {
      if (event.routerEvent instanceof NavigationEnd) {
        const routeUrl = event.routerEvent.urlAfterRedirects;

        this.isFolioMainPage = routeUrl === '/folio';
      }
    });
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
    this.userDataSubscription?.unsubscribe();
    this.routerChangeSubscription?.unsubscribe();
  }

  loadWorkspace(domain: string, organizationUid: string) {
    this.onItemClicked();
    if (domain === this.domain) {
      RedirectHelper.redirectToHomePage(this.ngZone, this.router, this.activatedRoute, this.homePageUri);
      return;
    }
    this.authService.finalizeFolioLogin(organizationUid, false, '').subscribe(({ isSuccess, value, error }) => {
      if (isSuccess) {
        CookieHelper.SetCookie('FolioAccessToken', value.accessToken, 2);
        location.href = 'https://' + domain;
      } else if (error) {
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
      }
    });
  }

  navigateTo(route) {
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, route);
    this.onItemClicked();
  }

  onItemClicked() {
    if (window.innerWidth <= 900) {
      this.store.dispatch(new CloseMobileNavMenu());
    }
  }

  private updateOrganizationLogo(): void {
    if (this.userData && this.userData.workspaces) {
      const currentWorkspace = this.userData.workspaces.find(workspace => workspace.domain === this.domain);
      if (currentWorkspace) {
        currentWorkspace.logo = this.organization.logo
      }
    }
  }

}
