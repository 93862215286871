<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="headers-editor__container">
  <ng-container *ngIf="playlistUid || pageUid">
    <h2>{{ 'translations.completion.title.title' | transloco }}</h2>
    <input
      class="headers-editor__input"
      [attr.aria-label]="('translations.global.ariaLabels.addTitleToThisPlaylist' | transloco)"
      matInput
      type="text"
      autocomplete="off"
      placeholder="Add title to this playlist"
      [disabled]="isDisabled"
      [(ngModel)]="title"
      (focusout)="onTitleUpdate()"/>
  </ng-container>
  <h2>{{ 'translations.completion.title.subLine' | transloco }}</h2>
  <input
    class="headers-editor__input"
    matInput
    type="text"
    [attr.aria-label]="('translations.global.ariaLabels.addASubheaderToThis' | transloco) + ' ' + (cardUid ? ('translations.global.ariaLabels.card' | transloco) : pageUid ? ('translations.global.ariaLabels.page' | transloco) : ('translations.global.ariaLabels.playlist' | transloco))"
    autocomplete="off"
    [disabled]="isDisabled"
    [placeholder]="'Add a subheader to this ' + (cardUid ? 'card' : pageUid ? 'page' : 'playlist')"
    [(ngModel)]="subheader"
    (focusout)="updateSubheader()"/>

  <div class="section-uri" *ngIf="currentLanguage?.isDefault">
    <h2
      [attr.aria-label]="(cardUid ? 'translations.cards.publish.title.cardUrl' : pageUid ? 'translations.pages.publish.title.pagesUrl' : 'translations.playlist.publish.title.playlistUrl') | transloco">
      {{ (cardUid ? 'translations.cards.publish.title.cardUrl' : pageUid ? 'translations.pages.publish.title.pagesUrl' : 'translations.playlist.publish.title.playlistUrl') | transloco }}</h2>
    <ng-container *ngIf="!editUri">
      <span class="static-uri">{{ uri }}</span>
      <span class="edit-uri-button"
            role="button"
            tabindex="0"
            [attr.aria-label]="('translations.global.button.edit' | transloco) + ' ' + uri"
            (click)="onEditUri()"
            (keydown.enter)="onEditUri()">
        {{ 'translations.global.button.edit' | transloco }}
      </span>
    </ng-container>

    <input
      class="headers-editor__input"
      matInput
      #uriInput
      type="text"
      *ngIf="editUri"
      [attr.aria-label]="('translations.global.ariaLabels.update' | transloco) + ' ' + (cardUid ? ('translations.global.ariaLabels.card' | transloco) : pageUid ? ('translations.global.ariaLabels.page' | transloco) : ('translations.global.ariaLabels.playlist' | transloco)) + ' ' + ('translations.global.ariaLabels.uri' | transloco)"
      autocomplete="off"
      [placeholder]="'Update ' + (cardUid ? 'card' : pageUid ? 'page' : 'playlist') + ' uri'"
      [(ngModel)]="uri"
      (focusout)="updateUri()"/>
  </div>

  <div class="time-estimate-section" *ngIf="!pageUid && canEditTime">
    <h2
    [attr.aria-label]="'translations.completion.title.timeEstimate' | transloco">{{ 'translations.completion.title.timeEstimate' | transloco }}</h2>
    <div *ngIf="suggestedTime"
         class="time-estimate-suggestion"
         (click)="applySuggestedTime()"
         (keydown.enter)="applySuggestedTime()"
         [innerHTML]="'translations.cards.publish.label.timeEstimateSuggestion' | transloco: {time: suggestedTimeFormat}">
    </div>
    <div class="time-estimate-select-box">
      <div class="select-field-with-icon select-field-box">
        <i class="material-icons" aria-hidden="true">access_time</i>
        <mat-select class="select-field"
                    tabindex="0"
                    [attr.aria-label]="('translations.global.ariaLabels.selectHour' | transloco)"
                    [(ngModel)]="selectedHour"
                    (selectionChange)="onTimeSelectChange()"
                    placeholder="HH">
          <mat-option [value]="hour" *ngFor="let hour of hours">{{hour}}</mat-option>
        </mat-select>
      </div>
      <div class="select-field-box">
        <mat-select class="select-field"
                    [attr.aria-label]="('translations.global.ariaLabels.selectMinute' | transloco)"
                    [(ngModel)]="selectedMinutes"
                    (selectionChange)="onTimeSelectChange()"
                    placeholder="MM">
          <mat-option [value]="minute" *ngFor="let minute of minutes">{{minute}}</mat-option>
        </mat-select>
      </div>


      <div class="reset-button-box">
        <button
          mat-button
          *ngIf="timeRequired"
          color="primary"
          class="reset-btn"
          [attr.aria-label]="('translations.global.ariaLabels.resetTimeSuggestion' | transloco)"
          (click)="onResetTimeSuggestion()">{{ 'translations.cards.publish.button.resetTimeSuggestion' | transloco }}
        </button>

      </div>
    </div>
  </div>
</div>
