/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { EditorHelper } from '../../../../../../shared/helpers/editor.helper';
import { Store } from '@ngxs/store';
import { ResourceAdminStateModel } from '../../../admin/resource-admin.state.model';
import * as ResourceAdminActions from '../../../admin/resource-admin.actions';
import { Resource } from '../../../../../../shared/models';

export const ResourceAdminMainSectionActionHelpers = {

	removeDynamicContentFromMainSection: (
		state: ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		{ contentIndex, languageCode }: ResourceAdminActions.RemoveDynamicContentFromMainSection,
		store: Store
	) => {

		const sectionUid = state.resource.content.sections[0].uid;

		const resourceState = {
			...state.resource,
			content: {
				...state.resource.content,
				sections: [{
					...state.resource.content.sections[0],
					dynamicContent: EditorHelper.removeDynamicContentByIndexAndJoinParagraphContent(
						contentIndex,
						state.resource.content.sections[0].dynamicContent)
				}, ...state.resource.content.sections.slice(1)]
			}
		};

		ResourceAdminMainSectionActionHelpers.updateState(patchState, resourceState);

		store.dispatch(new ResourceAdminActions.SaveSection(sectionUid, resourceState, languageCode));
	},

	updateDynamicContentOfMainSection: (
		state: ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		{
			contentIndex,
			updatedContent,
			oldContent,
			languageCode
		}: ResourceAdminActions.UpdateDynamicContentOfMainSection,
		store: Store
	) => {

		const sectionUid = state.resource.content.sections[0].uid;

		const resourceState = {
			...state.resource,
			content: {
				...state.resource.content,
				sections: [{
					...state.resource.content.sections[0],
					dynamicContent: state.resource.content.sections[0].dynamicContent.map(( content, idx ) => idx === contentIndex
						? updatedContent : content)
				}, ...state.resource.content.sections.slice(1)]
			}
		};

		ResourceAdminMainSectionActionHelpers.updateState(patchState, resourceState);

		if ( updatedContent.type === 'PARAGRAPH' || updatedContent.type === 'TABLE' ) {
			store.dispatch(new ResourceAdminActions.InitAutoSaveSections(sectionUid, resourceState, languageCode));
		} else {
			store.dispatch(new ResourceAdminActions.SaveSection(sectionUid, resourceState, languageCode));
		}
	},

	addDynamicContentToMainSection: (
		state: ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		{ content, languageCode }: ResourceAdminActions.AddDynamicContentToMainSection,
		store: Store
	) => {

		const sectionUid = state.resource.content.sections[0].uid;
		const resourceState = {
			...state.resource,
			content: {
				...state.resource.content,
				sections: [
					{
						...state.resource.content.sections[0],
						dynamicContent: EditorHelper.addDynamicContentWithBreakpointCheck(
							state.resource.content.sections[0] ? state.resource.content.sections[0].dynamicContent : [],
							content
						)
					},
					...state.resource.content.sections.slice(1)
				]
			}
		};

		ResourceAdminMainSectionActionHelpers.updateState(patchState, resourceState);

		store.dispatch(new ResourceAdminActions.SaveSection(sectionUid, resourceState, languageCode));
	},

	removeAllContentFromMainSection: (
		state: ResourceAdminStateModel,
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		{ languageCode }: ResourceAdminActions.RemoveAllContentFromMainSection,
		store: Store
	) => {
		const sectionUid = state.resource.content.sections[0].uid;

		const resourceState = {
			...state.resource,

			content: {
				...state.resource.content,
				sections: [{
					...state.resource.content.sections[0],
					dynamicContent: []
				}, ...state.resource.content.sections.slice(1)]
			}
		};

		ResourceAdminMainSectionActionHelpers.updateState(patchState, resourceState);

		store.dispatch(new ResourceAdminActions.SaveSection(
			sectionUid,
			resourceState,
			languageCode
		));
	},

	updateState: (
		patchState: ( p: Partial<ResourceAdminStateModel> ) => Partial<ResourceAdminStateModel>,
		resourceState: Resource
	) => {

		patchState({
			resource: resourceState,
			contentChanged: true
		});

	}
};
