import { Injectable } from '@angular/core';
import { ToastInfo } from '../../models/toast-notifications/toast-info';
import { PushNotificationMessage } from '../../models/websocket/websocket.model';

@Injectable()
export class MockToastService {

  showPushNotification(pushNotification: PushNotificationMessage) {
    return;
  }

  showSuccess(message: string) {
    return;
  }

  showFail(message: string) {
    return;
  }

  remove(createdAt: Date) {
    return;
  }

  getToastList(): ToastInfo[] {
    return [];
  }
}
