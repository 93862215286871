<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="collector-form">
  <div class="collector-form-inner">

    <div class="collector-form-row">
      <div class="collector-form-left">
        {{ 'translations.collector.form.label.typeOfLog' | transloco }}
      </div>
      <div class="collector-form-right">
        <div class="popup-box-container">
          <span
            tabindex="0"
            [attr.aria-label]="('translations.global.ariaLabels.openTypeOfLog' | transloco)"
            (click)="showModal(0)"
            (keydown.enter)="showModal(0)"
            class="popup-box-title f_popup-title">
            <span [innerText]="collectorConfig.logType === 'TIME_LOG' ? ('translations.collector.form.option.timeLog' | transloco) :
            collectorConfig.logType === 'MOMENTS' ? ('translations.collector.form.option.moments' | transloco) : ('translations.collector.form.option.forms' | transloco)"></span>
            <mat-icon class="icon" aria-hidden="true">expand_more</mat-icon> </span>
          <div class="popup f_popup" *ngIf="modalIndex === 0">
            <div
              tabindex="0"
              class="popup-item"
              [attr.aria-label]="('translations.global.ariaLabels.chooseTimeLog' | transloco)"
              [class.active]="collectorConfig.logType === 'TIME_LOG'"
              (click)="onLogTypeChange('TIME_LOG')"
              (keydown.enter)="onLogTypeChange('TIME_LOG')"
              [innerText]="'translations.collector.form.option.timeLog' | transloco">
            </div>
            <div
              tabindex="0"
              class="popup-item"
              [class.active]="collectorConfig.logType === 'MOMENTS'"
              [attr.aria-label]="('translations.global.ariaLabels.chooseMoments' | transloco)"
              (click)="onLogTypeChange('MOMENTS')"
              (keydown.enter)="onLogTypeChange('MOMENTS')"
              [innerText]="'translations.collector.form.option.moments' | transloco">
            </div>
            <div
              tabindex="0"
              class="popup-item"
              [class.active]="collectorConfig.logType === 'FORMS'"
              (click)="onLogTypeChange('FORMS')"
              [attr.aria-label]="('translations.global.ariaLabels.chooseForms' | transloco)"
              (keydown.enter)="onLogTypeChange('FORMS')"
              [innerText]="'translations.collector.form.option.forms' | transloco">
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="collector-form-row">
      <div class="collector-form-left">
        {{ 'translations.collector.form.label.required' | transloco }}
      </div>
      <div class="collector-form-right">
        <span class="cdk-visually-hidden" #requiredNumberOfLogsHours>
          {{ 'translations.collector.form.option.requireLogHours' | transloco }}
        </span>
        <span class="cdk-visually-hidden" #requiredNumberOfLogsDays>
          {{ 'translations.collector.form.option.requireLogDays' | transloco }}
        </span>
        <div class="popup-box-container">
          <input
            matInput
            type="number"
            class="collector-form-input"
            pattern="^\d+$"
            min="1"
            (ngModelChange)="onInputChange()"
            [(ngModel)]="collectorConfig.requiredNumberOfLogs"/>

          <span *ngIf="collectorConfig.logType !== 'TIME_LOG'" class="box-title">
            <span [innerText]="collectorConfig.logType === 'MOMENTS' ? ('translations.collector.form.option.moments' | transloco) : ('translations.collector.form.option.forms' | transloco)"></span>
          </span>

          <span
            tabindex="0"
            *ngIf="collectorConfig.logType === 'TIME_LOG'"
            (click)="showModal(1)"
            (keydown.enter)="showModal(1)"
            [attr.aria-label]="('translations.global.ariaLabels.openRequiredTypeDays_Hours' | transloco)"
            class="popup-box-title f_popup-title">
            <span [innerText]="collectorConfig.timeLogConfig.requiredTimeUnit === 'HOURS' ?
            requiredNumberOfLogsHours.innerText : requiredNumberOfLogsDays.innerText"></span>
            <mat-icon class="icon" aria-hidden="true">expand_more</mat-icon> </span>
          <div class="popup f_popup" *ngIf="modalIndex === 1">
            <div
              tabindex="0"
              class="popup-item"
              [class.active]="collectorConfig.timeLogConfig.requiredTimeUnit === 'HOURS'"
              [attr.aria-label]="('translations.global.ariaLabels.chooseHours' | transloco)"
              (click)="onNumberOfLogsChange('HOURS')"
              (keydown.enter)="onNumberOfLogsChange('HOURS')"
              [innerText]="requiredNumberOfLogsHours.innerText">
            </div>
            <div
              tabindex="0"
              class="popup-item"
              [class.active]="collectorConfig.timeLogConfig.requiredTimeUnit === 'DAYS'"
              [attr.aria-label]="('translations.global.ariaLabels.chooseDays' | transloco)"
              (click)="onNumberOfLogsChange('DAYS')"
              (keydown.enter)="onNumberOfLogsChange('DAYS')"
              [innerText]="requiredNumberOfLogsDays.innerText">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="collector-form-row" *ngIf="collectorConfig.logType !== 'MOMENTS'">
      <div class="collector-form-left">
        {{ 'translations.collector.form.label.evidenceType' | transloco }}
      </div>
      <div class="collector-form-right">
        <span class="cdk-visually-hidden" #evidenceTypeForms>{{ 'translations.collector.form.option.forms' | transloco }}</span>
        <span class="cdk-visually-hidden" #evidenceTypeMoments>{{ 'translations.collector.form.option.moments' | transloco }}</span>
        <div class="popup-box-container"
             [class.bottom-margin]="collectorConfig.evidencesType === 'FORMS'"
             *ngIf="collectorConfig.logType !== 'FORMS'">
          <span
            tabindex="0"
            (click)="showModal(2)"
            (keydown.enter)="showModal(2)"
            [attr.aria-label]="('translations.global.ariaLabels.openEvidenceType' | transloco)"
            class="popup-box-title f_popup-title">
            <span [innerText]="collectorConfig.evidencesType === 'FORMS' ? evidenceTypeForms.innerText : evidenceTypeMoments.innerText"></span>
            <mat-icon class="icon" aria-hidden="true">expand_more</mat-icon> </span>
          <div class="popup f_popup" *ngIf="modalIndex === 2">
            <div
              tabindex="0"
              class="popup-item"
              [class.active]="collectorConfig.evidencesType === 'FORMS'"
              [attr.aria-label]="('translations.global.ariaLabels.chooseForms' | transloco)"
              (click)="onEvidencesTypeChange('FORMS')"
              (keydown.enter)="onEvidencesTypeChange('FORMS')"
              [innerText]="evidenceTypeForms.innerText">
            </div>
            <div
              tabindex="0"
              class="popup-item"
              [class.active]="collectorConfig.evidencesType === 'MOMENTS'"
              [attr.aria-label]="('translations.global.ariaLabels.chooseMoments' | transloco)"
              (click)="onEvidencesTypeChange('MOMENTS')"
              (keydown.enter)="onEvidencesTypeChange('MOMENTS')"
              [innerText]="evidenceTypeMoments.innerText">
            </div>
          </div>
        </div>
        <div class="button-box" *ngIf="collectorConfig.evidencesType === 'FORMS'">
          <button
            mat-stroked-button
            class="uppercase"
            color="primary"
            title="Edit Forms Template"
            type="button"
            (click)="openFormTemplates()">{{ 'translations.collector.form.button.editTemplate' | transloco }}
          </button>
        </div>
      </div>
    </div>

    <div class="collector-form-row" *ngIf="collectorConfig.logType === 'TIME_LOG'">
      <div class="collector-form-left" *ngIf="collectorConfig.timeLogConfig.requiredTimeUnit === 'HOURS'">
        {{ 'translations.collector.form.label.allowedHoursPerSubmission' | transloco }}
      </div>
      <div class="collector-form-left" *ngIf="collectorConfig.timeLogConfig.requiredTimeUnit === 'DAYS'">
        {{ 'translations.collector.form.label.allowedDaysPerSubmission' | transloco }}
      </div>
      <div class="collector-form-right">
        <div class="hours-box">
          <span>{{ 'translations.collector.form.label.min' | transloco }}</span>
          <input
            matInput
            type="number"
            class="collector-form-input"
            pattern="^\d+$"
            min="1"
            (ngModelChange)="onInputChange()"
            [(ngModel)]="collectorConfig.timeLogConfig.minTime"/>
        </div>
        <div class="hours-box">
          <span>{{ 'translations.collector.form.label.max' | transloco }}</span>
          <input
            matInput
            type="number"
            class="collector-form-input"
            pattern="^\d+$"
            [min]="collectorConfig.timeLogConfig.minTime"
            (ngModelChange)="onInputChange()"
            [(ngModel)]="collectorConfig.timeLogConfig.maxTime"/>
        </div>
      </div>
    </div>

    <div class="collector-form-row">
      <div class="collector-form-left">{{ 'translations.collector.form.label.mapEvidenceAgainst' | transloco }}</div>
      <div class="collector-form-right">
        <div class="popup-box-container">
          <span class="cdk-visually-hidden"
                #tagsTypeFramework >{{ 'translations.collector.form.option.mapEvidenceAgainstFrameworks' | transloco }}</span>
          <span class="cdk-visually-hidden"
                #tagsTypeTags >{{ 'translations.collector.form.option.mapEvidenceAgainstTags' | transloco }}</span>
          <span
            tabindex="0"
            [attr.aria-label]="('translations.global.ariaLabels.openMapEvidenceAgainst' | transloco)"
            (click)="showModal(3)"
            (keydown.enter)="showModal(3)"
            class="popup-box-title f_popup-title">
            <span [innerText]="tagsType === 'SELECT_FRAMEWORK' ? tagsTypeFramework.innerText : tagsTypeTags.innerText"></span>
            <mat-icon class="icon" aria-hidden="true">expand_more</mat-icon> </span>
          <div class="popup f_popup" *ngIf="modalIndex === 3">
            <div
              tabindex="0"
              class="popup-item"
              [class.active]="tagsType === 'SELECT_FRAMEWORK'"
              [attr.aria-label]="('translations.global.ariaLabels.chooseMappingFromSelectedFramework' | transloco)"
              (click)="onFrameworkTypeChange('SELECT_FRAMEWORK')"
              (keydown.enter)="onFrameworkTypeChange('SELECT_FRAMEWORK')"
              [innerText]="tagsTypeFramework.innerText">
            </div>
            <div
              tabindex="0"
              class="popup-item"
              [class.active]="tagsType === 'SET_TAGS'"
              [attr.aria-label]="('translations.global.ariaLabels.chooseMappingFromTags' | transloco)"
              (click)="onFrameworkTypeChange('SET_TAGS')"
              (keydown.enter)="onFrameworkTypeChange('SET_TAGS')"
              [innerText]="tagsTypeTags.innerText">
            </div>
          </div>
        </div>

        <div class="tags-box">

          <div class="tags-box-buttons">
            <span>{{ 'translations.collector.form.label.select' | transloco }}</span>
            <span
              class="tags-box__framework-btn"
              tabindex="0"
              [attr.aria-label]="('translations.global.ariaLabels.selectFramework' | transloco)"
              (click)="onFrameworkBtnClick()"
              (keydown.enter)="onFrameworkBtnClick()">
              <span class="tags-box__framework-icon"></span>
              <span>{{ 'translations.framework.button.framework' | transloco }}</span>
          </span>
            <span *ngIf="tagsType === 'SET_TAGS'"> {{ 'translations.collector.form.label.or' | transloco }} </span>
            <input
              matInput
              type="text"
              autocomplete="off"
              tabindex="0"
              name="tagName"
              *ngIf="tagsType === 'SET_TAGS'"
              class="tags-box-add-new-tag"
              [(ngModel)]="newTagName"
              [ngModelOptions]="{standalone: true}"
              (keydown.enter)="addNewTag(); $event.stopPropagation(); $event.preventDefault();"
              placeholder="Add tags">
          </div>

          <div class="selected-tags-list">

            <div class="tags-box-selected-item" *ngFor="let framework of selectedFrameworks">
              <span>{{ framework.title }}</span>
              <span
                class="tags-box-selected-item-remove"
                title="Remove"
                tabindex="0"
                [attr.aria-label]="('translations.global.ariaLabels.removeFramework' | transloco)"
                (click)="deleteFramework(framework)"
                (keydown.enter)="deleteFramework(framework)">x</span>
            </div>

            <div class="tags-box-selected-item" *ngFor="let tag of selectedTags">
              <span>{{ tag.title }}</span>
              <span
                class="tags-box-selected-item-remove"
                title="Remove"
                tabindex="0"
                [attr.aria-label]="('translations.global.ariaLabels.removeTag' | transloco)"
                (click)="deleteTag(tag)"
                (keydown.enter)="deleteTag(tag)">x</span>
            </div>
          </div>

          <ptl-add-framework-tags
            class="add-framework-tags"
            *ngIf="frameworkTagsPanelOpen"
            [isDialogPage]="false"
            [selectFrameworks]="tagsType === 'SELECT_FRAMEWORK'"
            (closeFrameworkTags)="frameworkTagsPanelOpen = false"
            (addTagBulk)="onAddTagBulk($event)"
            (addFrameworkBulk)="onAddFramework($event)">
          </ptl-add-framework-tags>
        </div>

        <div class="popup-box-container">
          <span class="cdk-visually-hidden" #collectorTagsCountAny>{{ 'translations.collector.form.option.mapEvidenceAgainstAnyTagsCount' | transloco }}</span>
          <span class="cdk-visually-hidden" #collectorTagsCountMinStart>{{ 'translations.collector.form.option.mapEvidenceAgainstNumberTagsCount.start' | transloco }}</span>
          <span class="cdk-visually-hidden" #collectorTagsCountMinEnd>{{ 'translations.collector.form.option.mapEvidenceAgainstNumberTagsCount.end' | transloco }}</span>
          <span
            tabindex="0"
            [attr.aria-label]="('translations.global.ariaLabels.chooseNumberOfTags' | transloco)"
            (click)="showModal(4)"
            (keydown.enter)="showModal(4)"
            [class.has-error]="tagsCountError"
            class="popup-box-title f_popup-title">
            <span [innerText]="tagsCount === 0 ? collectorTagsCountAny.innerText :
             collectorTagsCountMinStart.innerText + ' ' + tagsCount + ' ' + collectorTagsCountMinEnd.innerText">
            </span>
            <mat-icon class="icon" aria-hidden="true">expand_more</mat-icon> </span>
          <div class="popup f_popup" *ngIf="modalIndex === 4">
            <div
              tabindex="0"
              class="popup-item"
              [attr.aria-label]="('translations.global.ariaLabels.chooseMinimumNumbersOfTag' | transloco)">
              <span [innerText]="collectorTagsCountMinStart.innerText"></span>
              <input
                matInput
                type="number"
                class="collector-form-input"
                pattern="^\d+$"
                min="0"
                (ngModelChange)="onInputChange()"
                [(ngModel)]="tagsCount"/>
              <span [innerText]="collectorTagsCountMinEnd.innerText"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="collector-form-row">
      <div class="collector-form-left">
        {{ 'translations.collector.form.label.requireTimeStamp' | transloco }}
      </div>
      <div class="collector-form-right">
        <span class="cdk-visually-hidden" #requireTimeStampYes>{{ 'translations.collector.form.option.requireTimeStampYes' | transloco }}</span>
        <span class="cdk-visually-hidden" #requireTimeStampNo>{{ 'translations.collector.form.option.requireTimeStampNo' | transloco }}</span>
        <div class="popup-box-container">
          <span
            tabindex="0"
            (click)="showModal(5)"
            (keydown.enter)="showModal(5)"
            [attr.aria-label]="('translations.global.ariaLabels.openRequireTimeStamp' | transloco)"
            class="popup-box-title f_popup-title"
            [innerText]="collectorConfig.timeLogConfig.requireTimestamp ? requireTimeStampYes.innerText : requireTimeStampNo.innerText">
          </span>
          <div class="popup f_popup" *ngIf="modalIndex === 5">
            <div
              tabindex="0"
              class="popup-item"
              [attr.aria-label]="('translations.global.ariaLabels.chooseEnableTimeStamp' | transloco)"
              [class.active]="collectorConfig.timeLogConfig.requireTimestamp"
              (click)="onTimeStampChange(true)"
              (keydown.enter)="onTimeStampChange(true)"
              [innerText]="requireTimeStampYes.innerText">
            </div>
            <div
              tabindex="0"
              class="popup-item"
              [attr.aria-label]="('translations.global.ariaLabels.chooseDisableTimeStamp' | transloco)"
              [class.active]="!collectorConfig.timeLogConfig.requireTimestamp"
              (click)="onTimeStampChange(false)"
              (keydown.enter)="onTimeStampChange(false)"
              [innerText]="requireTimeStampNo.innerText">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
