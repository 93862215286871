/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges } from '@angular/core';
import { UserSummary } from '../../models';
import { UserDetails } from '../../../user-auth/models';

@Component({
  selector: 'ptl-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
})
export class ProfileImageComponent implements OnChanges {

  /** Defines the image(or initials in the case of missing image) to be displayed.  */
  @Input() userSummary: UserDetails;

  userInitial: string;

  ngOnChanges() {
    if (this.userSummary) {
      this.userInitial = this.userSummary.firstName.charAt(0);
    }
  }
}
