<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<img
  class="loaded-image"
  *ngIf="displayImage; else loader"
  [src]="src"
  alt="" width="80" height="80"
/>

<ng-template #loader>
  <div *ngIf="!displayImage && !unableToLoadImage">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="loaded-image__unable" *ngIf="unableToLoadImage">
    <i class="material-icons" aria-hidden="true">
      sentiment_very_dissatisfied
    </i>
    <h3>{{ 'translations.errors.unableToLoadImage' | transloco }}</h3>
  </div>
</ng-template>
