/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[ogClickOutside]'
})
export class ClickOutsideDirective {
  @Input() isPanelOpen = false;
  @Input() clickableContainers: string[] = [];
  @Output() clickOutside: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    const isInside = this.clickableContainers.some(className => {
      const selector = className.startsWith('.') ? className : `.${className}`;
      return targetElement.closest(selector);
    });

    if (!isInside && this.isPanelOpen) {
      this.clickOutside.emit();
    }
  }
}
