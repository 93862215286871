import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ptl-new-moment-expand-btn',
  templateUrl: './new-moment-expand-btn.component.html',
  styleUrls: ['./new-moment-expand-btn.component.scss'],
})
export class NewMomentExpandBtnComponent {

  /** Emits framework tags form top position */
  @Output() changeDialogSizeMode = new EventEmitter<string>();

  dialogLargeModeOn: boolean;

  constructor() {
    this.setDialogLargeMode(false);
  }

  setDialogLargeMode(value: boolean): void {
    this.dialogLargeModeOn = value;
  }

  changeDialogMode(isExpanded: boolean) {
    this.changeDialogSizeMode.emit(isExpanded ? 'large' : 'small');
    this.setDialogLargeMode(isExpanded);
  }
}
