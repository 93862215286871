export const EDITOR_OPTIONS = {
  placeholder: false,
  toolbar: {
    buttons: [
      {
        name: 'bold',
        contentDefault: '<i class="material-icons" aria-hidden="true">format_bold</i>',
      },
      {
        name: 'italic',
        contentDefault: '<i class="material-icons" aria-hidden="true">format_italic</i>',
      },
      {
        name: 'anchor',
        contentDefault: '<i class="material-icons" aria-hidden="true">insert_link</i>',
      },
      {
        name: 'h1',
        contentDefault: '<b>H1</b>',
      },
      {
        name: 'h2',
        contentDefault: '<b>H2</b>',
      },
      {
        name: 'h3',
        contentDefault: '<b>H3</b>',
      },
      {
        name: 'quote',
        contentDefault: '<i class="material-icons" aria-hidden="true">format_quote</i>',
      },
      {
        name: 'fontsize',
      },
      {
        name: 'colorPicker',
      },
      {
        name: 'orderedlist',
        contentDefault: '<i class="material-icons" aria-hidden="true">format_list_numbered</i>',
      },
      {
        name: 'unorderedlist',
        contentDefault: '<i class="material-icons" aria-hidden="true">format_list_bulleted</i>',
      },
      {
        name: 'justifyLeft',
        contentDefault: '<i class="material-icons" aria-hidden="true">format_align_left</i>',
      },
      {
        name: 'justifyCenter',
        contentDefault: '<i class="material-icons" aria-hidden="true">format_align_center</i>',
      },
      {
        name: 'justifyRight',
        contentDefault: '<i class="material-icons" aria-hidden="true">format_align_right</i>',
      },
      {
        name: 'removeFormat',
        contentDefault: '<i class="material-icons" aria-hidden="true">format_clear</i>',
      }
    ],
  },
  anchor: {
    targetCheckbox: true,
    targetCheckboxText: 'Open in new tab',
  },
};
