/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

interface DotsVisibility {
  beginning: boolean;
  end: boolean;
}

interface Pager {
  /** Pager item total count. */
  totalItems: number;
  /** Pagination current page. */
  currentPage: number;
  /** Items count in one page. */
  pageSize: number;
  /** Total pages count. */
  totalPages: number;
  /** The Page from which Pager starts. */
  startPage: number;
  /** The Page from which Pager ends. */
  endPage: number;
  /** Pager's first page. */
  firstPage: number;
  /** Pager's last page. */
  lastPage: number;
  /** Pages array //[1, 2, 3, 4]. */
  pages: number[];
  /** Dots visibility */
  dots: DotsVisibility;
}

@Component({
  selector: 'ptl-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  /** Emits page */
  @Output() pageChange = new EventEmitter<number>();

  @Input() itemsLength: number;

  /** total pages count */
  @Input() totalPages: number;

  /** current Page */
  @Input() currentPage: number;

  /** total visible items per page */
  @Input() itemsPerPage: number;

  /** first page starts from 0 */
  @Input() startedFromZero: boolean;

  pager: Pager = {
    totalItems: undefined,
    currentPage: undefined,
    pageSize: undefined,
    totalPages: undefined,
    startPage: undefined,
    endPage: undefined,
    firstPage: undefined,
    lastPage: undefined,
    pages: undefined,
    dots: undefined,
  };

  private firstPage: number;
  private pageSize: number;

  ngOnInit() {
    this.firstPage = this.startedFromZero ? 0 : 1;
    this.pageSize = 10;

    if (this.currentPage) {
      this.initPaginationData();
    } else {
      this.setPage(this.firstPage, false);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initPaginationData();
  }

  initPaginationData() {
    if (this.startedFromZero && this.currentPage === 0) {
      this.setPage(this.currentPage, false);
    }
    if (this.currentPage) {
      this.setPage(this.currentPage, false);
    } else {
      this.setPage(this.pager.currentPage, false);
    }
  }

  setPage(page: number, shouldEmitChange = true) {
    if (page < this.firstPage || page > this.pager.totalPages) {
      return;
    }
    this.pager = this.getPager(this.itemsLength, page, this.itemsPerPage);
    if (shouldEmitChange) {
      this.pageChange.emit(this.pager.currentPage);
    }
  }

  setFirstLastPage(page: number) {
    this.pager.currentPage = page;
    this.pager = this.getPager(this.itemsLength, page, this.itemsPerPage);
    this.pageChange.emit(this.pager.currentPage);
  }

  private getPager(totalItems: number, currentPage: number = this.firstPage, pageSize: number = this.pageSize) {
    let totalPages = this.totalPages;

    if (totalPages === undefined && !!totalItems && !!pageSize) {
      totalPages = Math.ceil(totalItems / pageSize);
    }

    if (this.startedFromZero) {
      totalPages--;
    }
    const dots = this.getDots(totalPages, currentPage);
    const startEndPages = this.getStartEndPages(currentPage, totalPages, dots);
    const startPage = startEndPages.startPage;
    const endPage = startEndPages.endPage;
    const pages = [];
    for (let i = startPage; i < endPage + 1; i++) {
      pages.push(i);
    }
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      firstPage: this.firstPage,
      lastPage: totalPages,
      pages: pages,
      dots: dots
    };
  }

  private getStartEndPages(
    currentPage: number = this.firstPage,
    totalPages: number,
    dots: DotsVisibility
  ): { startPage: number; endPage: number } {
    let startPage: number;
    let endPage: number;
    if (totalPages <= (this.startedFromZero ? 6 : 7)) {
      startPage = this.firstPage;
      endPage = totalPages;
    } else {
      if (!dots.beginning && dots.end) {
        startPage = this.firstPage;
        endPage = (this.startedFromZero ? 4 : 5);
      } else if (dots.beginning && !dots.end) {
        startPage = (this.startedFromZero ? totalPages - 4 : totalPages - 5);
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }
    return {
      startPage: startPage,
      endPage: endPage,
    };
  }

  private getDots(totalPages: number, currentPage: number): DotsVisibility {
    if (totalPages <= (this.startedFromZero ? 6 : 7)) {
      return {beginning: false, end: false};
    } else {
      const beginning = currentPage > (this.startedFromZero ? 3 : 4);
      const end = currentPage <= (this.startedFromZero ? totalPages - 4 : totalPages - 5);
      return {beginning: beginning, end: end};
    }
  }
}
