/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */


import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import * as MediumEditor from 'medium-editor';
import { FONT_SIZE_FORM } from './font-size.extension';
import { COLOR_PICKER_FORM } from './color-picker.extension';

/**
 * Medium Editor wrapper directive.
 * Source: https://github.com/codiak/angular2-medium-editor
 *
 * Examples
 * <medium-editor[(editorModel)]="textVar"
 * [editorOptions]="{'toolbar': {'buttons': ['bold', 'italic', 'underline', 'h1', 'h2', 'h3']}}"
 * [editorPlaceholder]="placeholderVar"></medium-editor>
 */

@Directive({
  /* eslint-disable */
  selector: 'medium-editor',
  /* eslint-enable */
})
export class MediumEditorDirective implements OnInit, OnChanges, OnDestroy {

  private lastViewModel: string;
  private element: HTMLElement;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public editor: any;

  @Input() editorModel: any;
  @Input() editorOptions: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */

  @Input() editorPlaceholder: string;
  @Input() lastEditor: boolean;

  @Output() editorModelChange = new EventEmitter();

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.element = this.el.nativeElement;
    this.element.innerHTML = '<div class="me-editable">' + this.editorModel + '</div>';
    this.element.firstElementChild.setAttribute('tabindex', '0');
    this.element.firstElementChild.setAttribute('aria-label', 'Add your comment');
    if (this.lastEditor) {
      this.element.firstElementChild.classList.add('last-editor');
    }

    if (this.editorPlaceholder) {
      this.editorOptions.placeholder = {
        text: this.editorPlaceholder,
      };
    }

    // Global MediumEditor
    this.editor = new MediumEditor(this.el.nativeElement.firstChild, {
      ...this.editorOptions,
      // adding custom extensions
      extensions: {
        fontsize: new FONT_SIZE_FORM(),
        colorPicker: new COLOR_PICKER_FORM(),
        imageDragging: {},
      },
      paste: {
        forcePlainText: true,
      },
    });
    this.editor.subscribe('editableInput', () => {
      this.updateModel();
    });
  }

  getElement(): HTMLElement {
    return this.element;
  }

  refreshView() {
    if (this.editor) {
      this.editor.setContent(this.editorModel);
    }
  }

  ngOnChanges(changes) {
    if (this.isPropertyUpdated(changes, this.lastViewModel)) {
      this.lastViewModel = this.editorModel;
      this.refreshView();
    }

    if (this.isPropertyUpdated(changes, this.lastEditor) && this.element) {
      const editor = this.element.firstElementChild;

      if (this.lastEditor) {
        editor.classList.add('last-editor');
      } else {
        editor.classList.remove('last-editor');
      }
    }
    if (
      changes.editorModel?.currentValue.indexOf('<blockquote></blockquote>') !== -1 ||
      changes.editorModel?.currentValue.indexOf('<blockquote class=""></blockquote>') !== -1) {
      this.removeBrokenBlockquote(changes.editorModel?.currentValue);
    }
  }

  /**
   * Emit updated model
   */
  updateModel() {
    const value = this.editor.getContent();
    this.lastViewModel = value;
    this.editorModelChange.emit(value);
  }

  /**
   * Remove MediumEditor on destruction of directive
   */
  ngOnDestroy() {
    this.editor.destroy();
  }

  isPropertyUpdated(changes, viewModel) {
    if (!changes.hasOwnProperty('editorModel')) {
      return false;
    }

    const change = changes.editorModel;

    if (change.isFirstChange()) {
      return true;
    }

    return !Object.is(viewModel, change.currentValue);
  }

  private removeBrokenBlockquote(currentValue: string) {
    if (currentValue) {
      currentValue = currentValue.replace(/<blockquote><\/blockquote>/g, '');
      currentValue = currentValue.replace(/<blockquote class=""><\/blockquote>/g, '');
      this.editorModel = currentValue;
    }
    this.refreshView();
  }
}
