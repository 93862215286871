<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div class="chart-section">
  <div class="chart-section__top">
    <div class="chart-section__select-option">
      <span>{{ 'translations.chart' | transloco }}</span>
      <ng-container *ngIf="isEditing">
        <mat-select
          [(value)]="selectedChartType"
          (selectionChange)="onChartChange($event)"
          class="chart-section__select">
          <mat-option *ngFor="let chart of charts" [value]="chart.type">{{ chart.title }}</mat-option>
        </mat-select>
        <mat-checkbox color="primary" class="members-list__checkbox" labelPosition="before" [(ngModel)]="percentageShown"
                      [tabIndex]="0"
                      [aria-label]="('translations.global.ariaLabels.showPercentage' | transloco)">
          {{ 'translations.showPercentage' | transloco }}
        </mat-checkbox>
      </ng-container>
      <span *ngIf="!isEditing">{{ selectedChartTitle }}</span>
    </div>
    <div class="chart-section__actions">
      <button
        *ngIf="isEditing"
        mat-flat-button
        class="uppercase"
        title="save"
        type="button"
        color="primary"
        [attr.aria-label]="('translations.global.ariaLabels.saveChart' | transloco)"
        (click)="saveChart()">{{ 'translations.global.button.save' | transloco }}
      </button>
      <button
        *ngIf="!isEditing"
        mat-flat-button
        class="uppercase"
        title="save"
        type="button"
        color="primary"
        [attr.aria-label]="('translations.global.ariaLabels.editChart' | transloco)"
        (click)="editChart()">{{ 'translations.global.button.edit' | transloco }}
      </button>
      <button
        mat-icon-button
        class="chart-section__delete-btn"
        title="Edit card"
        [attr.aria-label]="('translations.global.ariaLabels.deleteChart' | transloco)"
        *ngIf="canRemoveItem"
        type="button"
        (click)="deleteChart()">
        <mat-icon aria-hidden="true">clear</mat-icon>
      </button>
    </div>
  </div>


  <div class="chart-section__body" *ngIf="isEditing">
    <div class="chart-section__progress">
      <span class="chart-section__progress-title">{{ 'translations.radialChart' | transloco }}</span>
      <img *ngIf="selectedChartType === 'RADIAL'" src="../assets/chart-radial.jpg" width="302" height="302" alt="chart">
      <img *ngIf="selectedChartType === 'BAR'" src="../assets/chart-bar.jpg" width="813" height="365" alt="chart">
    </div>
    <div class="chart-section__learner-goals">
      <div class="chart-section__learner-list-top">
        <span>{{ 'translations.label' | transloco }}</span>
        <span>{{ 'translations.color' | transloco }}</span>
      </div>
      <div
        cdkDropList
        [cdkDropListData]="learnerGoals"
        (cdkDropListDropped)="dropOption($event)"
        class="chart-section__learner-list chart-section__learner-list_icons">
        <div cdkDrag cdkDragLockAxis="y" class="chart-section__learner-item"
             *ngFor="let learnerGoal of learnerGoals; let first = first; let last = last">
          <div class="chart-section__learner-item-checkbox">
            <mat-checkbox color="primary" class="members-list__checkbox" [(ngModel)]="learnerGoal.checked"
                          (change)="onCheckboxChange(learnerGoal)" [checked]="learnerGoal.checked"
                          [tabIndex]="0"
                          [aria-label]="('translations.global.ariaLabels.check' | transloco: {name: ''})"></mat-checkbox>
          </div>
          <div class="chart-section__learner-item-drag" cdkDragHandle>
            <i class="material-icons" aria-hidden="true">unfold_more</i>
          </div>
          <div class="chart-section__learner-item-title">{{learnerGoal.title}}</div>
          <div class="chart-section__learner-item-color">
            <input
              readonly
              class="goals-badge__color-picker"
              [attr.aria-label]="('translations.global.ariaLabels.selectChartItemColor' | transloco)"
              [(colorPicker)]="learnerGoal.color"
              [cpOutputFormat]="'hex'"
              [cpPosition]="'bottom-left'"
              [style.background]="learnerGoal.color"/>
            <span>{{learnerGoal.color}}</span>
          </div>
        </div>
        <div class="max-min-icons-container">
          <div class="logic-section__attribute-icon-box" [class.has-icon]="charContent?.iconXMin">
            <label
              [ngStyle]="{'background-image': charContent?.iconXMin ? 'url(&quot;' +  charContent?.iconXMin + '&quot;)' : ''}"
              class="logic-section__attribute-icon">

              <input type="file" class="file-input" [accept]="'image/*'"
                     [attr.aria-label]="'translations.global.ariaLabels.uploadChartMinIcon' | transloco"
                     (change)="openIconUpload($event, 'MIN' )"/>
              <i class="ptl-icon-add-image" aria-hidden="true" *ngIf="!charContent?.iconXMin"></i>
            </label>
            <mat-icon
              class="remove-attribute-icon"
              *ngIf="charContent?.iconXMin"
              [attr.aria-label]="('translations.global.ariaLabels.removeChartMinimumIcon' | transloco)"
              (click)="removeChartIcon('MIN')"
              (keydown.enter)="removeChartIcon('MIN')">close
            </mat-icon>
          </div>
          <div class="logic-section__attribute-icon-box last" [class.has-icon]="charContent?.iconXMax">
            <label
              [ngStyle]="{'background-image': charContent?.iconXMax ? 'url(&quot;' +  charContent?.iconXMax + '&quot;)' : ''}"
              class="logic-section__attribute-icon">

              <input type="file" class="file-input" [accept]="'image/*'"
                     [attr.aria-label]="'translations.global.ariaLabels.uploadChartMaxIcon' | transloco"
                     (change)="openIconUpload($event, 'MAX')"/>
              <i class="ptl-icon-add-image" aria-hidden="true" *ngIf="!charContent?.iconXMax"></i>
            </label>
            <mat-icon class="remove-attribute-icon" *ngIf="charContent?.iconXMax"
                      [attr.aria-label]="('translations.global.ariaLabels.removeChartMaximumIcon' | transloco)"
                      (click)="removeChartIcon('MAX')"
                      (keydown.enter)="removeChartIcon('MAX')">close
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="chart-section__body chart-section__body-preview" *ngIf="!isEditing">
    <img *ngIf="selectedChartType === 'RADIAL'" src="../assets/chart-radial.jpg" width="302" height="302" alt="chart">
    <img *ngIf="selectedChartType === 'BAR'" src="../assets/chart-bar.jpg" width="813" height="365" alt="chart">
  </div>

</div>

