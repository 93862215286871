/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { Injectable } from '@angular/core';
import { BookingDataService } from './booking-data.service';
import {
  AvailableSlot,
  BookableAppointment,
  BookableAppointmentRequest,
  BookableResource,
  BookableResourceRequest,
  BookableSchedule, BookableSlotAdmin, BookableSlotAdminRequest,
  BookedAppointment,
  BookedAppointmentsRequest,
  BookingRequest,
  BulkCancelBookingsRequest,
  ScheduleAvailabilityRequest,
  ScheduleRequest, ScheduleWithSlots
} from '../../models/editor/booking-content.model';
import { Observable } from 'rxjs';

@Injectable()
export class MockBookingDataService implements BookingDataService {

  createBookableAppointment(request: BookableAppointmentRequest, cardUid: string, languageCode?: string)
    : ObservableResult<BookableAppointment> {
    return ObservableResult.ofSuccess();
  }

  createBookableResource(request: BookableResourceRequest, languageCode?: string)
    : ObservableResult<BookableResource> {
    return ObservableResult.ofSuccess();
  }

  updateBookableResource(request: BookableResourceRequest,
                         bookableResourceUid: string,
                         cardUid: string,
                         languageCode?: string): ObservableResult<BookableResource> {
    return ObservableResult.ofSuccess();
  }

  getBookableAppointment(bookableAppointmentUid: string, languageCode?: string)
    : ObservableResult<BookableAppointment> {
    return ObservableResult.ofSuccess();
  }

  createSchedule(request: ScheduleRequest, bookableResourceUid: string, cardUid: string, languageCode?: string)
    : ObservableResult<BookableSchedule> {
    return ObservableResult.ofSuccess();
  }

  getSchedule(cardUid: string, resourceUid: string, languageCode?: string): ObservableResult<ScheduleWithSlots> {
    return ObservableResult.ofSuccess();
  }

  setScheduleAvailability(request: ScheduleAvailabilityRequest,
                          bookableScheduleUid: string,
                          cardUid: string,
                          languageCode?: string):
    ObservableResult<BookableSlotAdmin> {
    return ObservableResult.ofSuccess();
  }

  getBookableSlots(bookableAppointmentId: string, from: string, to: string): ObservableResult<AvailableSlot[]> {
    return ObservableResult.ofSuccess();
  }

  placeBooking(bookableResourceUid: string, request: BookingRequest, languageCode: string)
    : ObservableResult<BookedAppointment> {
    return ObservableResult.ofSuccess();
  }

  getAppointmentResources(bookableAppointmentUid: string, cardUid: string): ObservableResult<BookableResource[]> {
    return ObservableResult.ofSuccess();
  }

  disassociate(cardUid: string, appointmentUid: string, resourceUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  getBooking(bookingUid: string, languageCode?: string): ObservableResult<BookedAppointment> {
    return ObservableResult.ofSuccess();
  }

  updateBookableAppointment(appointmentUid: string, request: BookableAppointmentRequest, cardUid: string, currentLanguage: string)
    : ObservableResult<BookableAppointment> {
    return ObservableResult.ofSuccess();
  }

  updateSchedule(request: ScheduleRequest,
                 bookableResourceUid: string,
                 cardUid: string,
                 bookableScheduleUid: string,
                 languageCode?: string): ObservableResult<BookableSchedule> {
    return ObservableResult.ofSuccess();
  }

  updateScheduleAvailability(request: ScheduleAvailabilityRequest,
                             bookableResourceUid: string,
                             bookableScheduleUid: string,
                             cardUid: string,
                             apiScheduleBlockUid: string,
                             languageCode?: string): ObservableResult<BookableSlotAdmin> {
    return ObservableResult.ofSuccess();
  }

  deleteScheduleAvailability(bookableResourceUid: string,
                             bookableScheduleUid: string,
                             cardUid: string,
                             apiScheduleBlockUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  updateResourceSlots(cardUid: string,
                      bookableResourceUid: string,
                      bookableScheduleUid: string,
                      request: BookableSlotAdminRequest[],
                      languageCode: string)
    : ObservableResult<BookableSlotAdmin[]> {
    return ObservableResult.ofSuccess();
  }

  cancelBooking(bookingUid: string, languageCode?: string): ObservableResult<BookedAppointment> {
    return ObservableResult.ofSuccess();
  }

  getBookingICalEvent(bookingUid: string, languageCode?: string): Observable<string> {
    return Observable.create();
  }

  cancelBulkBookings(request: BulkCancelBookingsRequest, languageCode?: string): ObservableResult<boolean> {
    return ObservableResult.ofSuccess();
  }

  isBookingsDataAvailable(): ObservableResult<boolean> {
    return ObservableResult.ofSuccess();
  }

  getBookings(request: BookedAppointmentsRequest, languageCode?: string): ObservableResult<BookedAppointment[]> {
    return ObservableResult.ofSuccess();
  }

  getBookedAppointments(request: BookedAppointmentsRequest, languageCode?: string): ObservableResult<BookedAppointment[]> {
    return ObservableResult.ofSuccess();
  }
}
