<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="help-center-content" *ngIf="showHelpCenter"
     [class.has-action-bar]="hasActionBar || viewsType === 'playlist' || viewsType === 'resource_card' || viewsType === 'assessment_card' || viewsType === 'event_card'">
  <div class="help-center-content-inner" *ngIf="expanded">
    <div class="help-center-items">
      <div class="help-center-item" *ngFor="let item of helpCenterData" tabindex="0"
           aria-label="{{'translations.global.ariaLabels.openPlaylist' | transloco}}"
           (click)="navigateTo(item.formattedUri)" (keydown.enter)="navigateTo(item.formattedUri)">
        <div class="title small">{{item.title}}</div>
      </div>
    </div>
  </div>
  <button mat-fab color="primary" class="help-center-button" (click)="openHelpCenter()" [attr.aria-label]="('translations.global.ariaLabels.openHelpCenter' | transloco)">
    <i class="ptl-icon-question-mark" aria-hidden="true" *ngIf="!expanded"></i>
    <svg class="svg svg-icon" *ngIf="expanded" aria-hidden="true">
      <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
    </svg>
  </button>
</div>
