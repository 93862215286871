/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

/* eslint-disable max-len */
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FileUploadHelper } from '../../../shared/helpers/file-upload-helper';
import {
  dataLoadedState,
  defaultLoadableState,
  errorState,
  LoadableState,
  loadingState,
  ObservableResult
} from '../../../shared/store';
import { AdminDataService, ADMIN_DATA_SERVICE } from '../services/data.service';
import { Inject, Injectable, NgZone } from '@angular/core';
import { tap, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  Framework,
  FrameworkDetails,
  OrganizationFramework
} from '../../../shared/models/admin/admin.model';
import * as FrameworkActions from './framework.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResourceTag } from '../../../shared/models';
import { CORE_TAG_DATA_SERVICE, TagDataService } from '../../../shared/services/tags/tag-data.service';
import {
  FileUploadService,
  RESOURCES_FILE_UPLOAD_DATA_SERVICE
} from '../../../shared/services/file-upload/file-upload.service';
import { ImageUploadNameResolver } from '../../../shared/helpers/image-upload-name-resolver';
import { TagErrorObject } from '../../playlist/models';
import {
  CompetenceCreationError,
  CompetenceLevel,
  CompetenceModel
} from '../../../shared/models/competence/competence.model';
import {
  CompetenceDataService,
  CORE_COMPETENCE_DATA_SERVICE
} from '../../../shared/services/competence/competence-data.service';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { LoadLtiFrameworks, FilterCategories } from './framework.actions';


/** The framework state definition. */
export interface FrameworkStateModel {
  frameworks: LoadableState<Framework[]>;
  frameworkDescendants: LoadableState<ResourceTag[]>;
  filteredMainTags: ResourceTag[];
  tagDescendants: LoadableState<ResourceTag[]>;
  organizationFramework: LoadableState<FrameworkDetails>;
  organizationFrameworks: LoadableState<OrganizationFramework[]>;
  addFrameworkActionState: LoadableState<boolean>;
  addTagState: LoadableState<ResourceTag>;
  allCompetences: LoadableState<CompetenceModel[]>;
  newCompetence: LoadableState<CompetenceModel>;
  competenceCreateUpdateError: CompetenceCreationError;
  competenceAssignment: boolean;
  competenceLevels: LoadableState<CompetenceLevel[]>;
}


@State<FrameworkStateModel>({
  name: 'framework',
  defaults: {
    frameworks: defaultLoadableState(),
    frameworkDescendants: defaultLoadableState(),
    tagDescendants: defaultLoadableState(),
    filteredMainTags: [],
    organizationFramework: defaultLoadableState(),
    organizationFrameworks: defaultLoadableState(),
    addFrameworkActionState: defaultLoadableState(),
    addTagState: defaultLoadableState(),
    allCompetences: defaultLoadableState(),
    newCompetence: defaultLoadableState(),
    competenceCreateUpdateError: '',
    competenceAssignment: false,
    competenceLevels: defaultLoadableState(),
  },
})
@Injectable()
export class FrameWorkState {
  @Selector()
  static frameworks({ frameworks }: FrameworkStateModel): LoadableState<Framework[]> {
    return frameworks;
  }

  @Selector()
  static frameworkDescendants({ frameworkDescendants }: FrameworkStateModel): LoadableState<ResourceTag[]> {
    return frameworkDescendants;
  }

  @Selector()
  static tagDescendants({ tagDescendants }: FrameworkStateModel): LoadableState<ResourceTag[]> {
    return tagDescendants;
  }

  @Selector()
  static addTagState({ addTagState }: FrameworkStateModel): LoadableState<ResourceTag> {
    return addTagState;
  }

  @Selector()
  static filteredMainTags({ filteredMainTags }: FrameworkStateModel): ResourceTag[] {
    return filteredMainTags;
  }

  @Selector()
  static organizationFrameworks({ organizationFrameworks }: FrameworkStateModel): LoadableState<OrganizationFramework[]> {
    return organizationFrameworks;
  }

  @Selector()
  static organizationFramework({ organizationFramework }: FrameworkStateModel): LoadableState<FrameworkDetails> {
    return organizationFramework;
  }

  @Selector()
  static addFrameworkActionState({ addFrameworkActionState }: FrameworkStateModel): LoadableState<boolean> {
    return addFrameworkActionState;
  }

  @Selector()
  static allCompetences({ allCompetences }: FrameworkStateModel): LoadableState<CompetenceModel[]> {
    return allCompetences;
  }

  @Selector()
  static newCompetence({ newCompetence }: FrameworkStateModel): LoadableState<CompetenceModel> {
    return newCompetence;
  }

  @Selector()
  static competenceLevels({ competenceLevels }: FrameworkStateModel): LoadableState<CompetenceLevel[]> {
    return competenceLevels;
  }

  @Selector()
  static competenceCreateUpdateError({ competenceCreateUpdateError }: FrameworkStateModel): CompetenceCreationError {
    return competenceCreateUpdateError;
  }

  @Selector()
  static competenceAssignment({ competenceAssignment }: FrameworkStateModel): boolean {
    return competenceAssignment;
  }

  constructor(
    private router: Router,
    private store: Store,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    @Inject(RESOURCES_FILE_UPLOAD_DATA_SERVICE) private fileUploadService: FileUploadService,
    @Inject(ADMIN_DATA_SERVICE) private dataService: AdminDataService,
    @Inject(CORE_TAG_DATA_SERVICE) private tagService: TagDataService,
    @Inject(CORE_COMPETENCE_DATA_SERVICE) private competenceDataService: CompetenceDataService,
    private translationService: TranslationService
  ) {
  }


  @Action(FrameworkActions.LoadFrameworks)
  loadFrameworks(
    { patchState }: StateContext<FrameworkStateModel>
  ) {

    patchState({
      frameworks: loadingState(),
    });

    return this.dataService.loadFrameworks().pipe(
      tap(({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              frameworks: dataLoadedState(value),
            });
          } else {
            patchState({
              frameworks: errorState(error),
            });
          }
        }
      ));
  }

  @Action(FrameworkActions.LoadLtiFrameworks)
  loadLtiFrameworks(
    { patchState }: StateContext<FrameworkStateModel>,
    { organizationUid, requestId }: FrameworkActions.LoadLtiFrameworks
    ) {

    patchState({
      frameworks: loadingState(),
    });

    return this.dataService.loadLtiFrameworks(requestId, organizationUid).pipe(
      tap(({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              frameworks: dataLoadedState(value),
            });
          } else {
            patchState({
              frameworks: errorState(error),
            });
          }
        }
      ));
  }


  @Action(FrameworkActions.LoadContentStoreFrameworks)
  loadContentStoreFrameworks(
    { patchState }: StateContext<FrameworkStateModel>
  ) {

    patchState({
      frameworks: loadingState(),
    });

    return this.dataService.loadContentStoreFrameworks().pipe(
      tap(({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              frameworks: dataLoadedState([ value ]),
            });
          } else {
            patchState({
              frameworks: errorState(error),
            });
          }
        }
      ));
  }

  @Action(FrameworkActions.LoadFrameworkCategories)
  loadDiscoveryFrameworks(
    { patchState }: StateContext<FrameworkStateModel>
  ) {

    patchState({
      frameworks: loadingState(),
    });

    return this.dataService.loadFrameworkCategories().pipe(
      tap(({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              frameworks: dataLoadedState([ value ]),
            });
          } else {
            patchState({
              frameworks: errorState(error),
            });
          }
        }
      ));
  }

  @Action(FrameworkActions.LoadOrganizationFrameworks)
  loadOrganizationFrameworks(
    { patchState }: StateContext<FrameworkStateModel>
  ) {
    patchState({
      organizationFrameworks: loadingState(),
    });

    return this.dataService.loadOrganizationFrameworks().pipe(
      tap(({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              organizationFrameworks: dataLoadedState(value),
            });
          } else {
            patchState({
              organizationFrameworks: errorState(error),
            });
          }
        }
      ));
  }

  @Action(FrameworkActions.PublishFramework)
  publishFramework(
    { getState, patchState }: StateContext<FrameworkStateModel>,
    { frameworkId, publish }: FrameworkActions.PublishFramework
  ) {

    patchState({
      organizationFrameworks: {
        ...getState().organizationFrameworks,
        loading: true,
      },
    });

    return this.dataService.publishFramework(frameworkId, publish).pipe(
      tap(({ isSuccess, value }) => {
          if ( isSuccess ) {

            const currentState = getState();
            let frameworks: Framework[] = currentState.frameworks.data;

            if ( frameworks ) {
              if ( publish ) {
                frameworks.push(value);
              } else {
                frameworks = frameworks.filter(framework => framework._id !== value._id);
              }
              patchState({
                frameworks: dataLoadedState(frameworks),
              });
            }

            patchState({
              organizationFrameworks: dataLoadedState(
                currentState.organizationFrameworks.data.map(framework => framework.id === frameworkId ?
                  ({
                    ...framework,
                    published: publish,
                  })
                  : framework)
              ),
            });

          }
        }
      ));
  }

  @Action(FrameworkActions.RemoveFrameworkFromOrganization)
  removeFrameworkFromOrganization(
    { getState, patchState }: StateContext<FrameworkStateModel>,
    { frameworkId }: FrameworkActions.RemoveFrameworkFromOrganization
  ) {

    patchState({
      organizationFrameworks: {
        ...getState().organizationFrameworks,
        loading: true,
      },
    });

    return this.dataService.removeFrameworkFromOrganization(frameworkId).pipe(
      tap(({ isSuccess }) => {
          if ( isSuccess ) {

            const state = getState();
            const organizationFrameworks =
              state.organizationFrameworks.data.filter(framework => framework.id !== frameworkId);

            patchState({
              organizationFrameworks: dataLoadedState(organizationFrameworks),
            });
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'framework.message.success.removed'
            );
          } else {
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'framework.message.error.removed'
            );
          }
        }
      ));
  }

  @Action(FrameworkActions.LoadOrganizationFrameworkById)
  loadOrganizationFrameworkById(
    { patchState }: StateContext<FrameworkStateModel>,
    { frameworkId }: FrameworkActions.LoadOrganizationFrameworkById
  ) {
    patchState({
      organizationFramework: loadingState(),
    });

    return this.dataService.loadFrameworkById(frameworkId).pipe(
      tap((({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              organizationFramework: dataLoadedState(value),
            });
          } else {
            patchState({
              organizationFramework: errorState(error),
            });
          }
        }
      )));
  }

  @Action(FrameworkActions.RemoveSelectedFrameworkFromStore)
  removeSelectedFrameworkFromStore(
    { patchState }: StateContext<FrameworkStateModel>
  ) {

    patchState({
      frameworkDescendants: defaultLoadableState(),
    });
  }

  @Action(FrameworkActions.LoadFrameworkDescendants)
  loadFrameworkDescendants(
    { patchState }: StateContext<FrameworkStateModel>,
    { frameworkId }: FrameworkActions.LoadFrameworkDescendants
  ) {

    patchState({
      frameworkDescendants: loadingState(),
    });

    return this.dataService.loadFrameworkDescendants(frameworkId).pipe(
      tap(({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              frameworkDescendants: dataLoadedState(value),
            });
          } else {
            patchState({
              frameworkDescendants: errorState(error),
            });
          }
        }
      ));
  }


  @Action(FrameworkActions.LoadFrameworkTagDescendants)
  loadFrameworkTagDescendants(
    { patchState }: StateContext<FrameworkStateModel>,
    { frameworkId, tagId }: FrameworkActions.LoadFrameworkTagDescendants
  ) {

    patchState({
      tagDescendants: loadingState(),
    });

    return this.dataService.loadFrameworkTagDescendants(frameworkId, tagId).pipe(
      tap(({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              tagDescendants: dataLoadedState(value),
            });
          } else {
            patchState({
              tagDescendants: errorState(error),
            });
          }
        }
      ));
  }


  @Action(FrameworkActions.AddFramework)
  addFramework(
    { patchState }: StateContext<FrameworkStateModel>,
    { frameworkParams }: FrameworkActions.AddFramework
  ) {
    patchState({
      addFrameworkActionState: loadingState(),
    });

    return this.dataService.createFramework(frameworkParams).pipe(
      tap(({ isSuccess, error }) => {
          if ( isSuccess ) {
            patchState({
              addFrameworkActionState: dataLoadedState(true),
            });
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'framework.message.success.created'
            );
          } else {
            patchState({
              addFrameworkActionState: errorState(error),
            });
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'framework.message.error.created'
            );
          }
        }
      ));
  }

  @Action(FrameworkActions.AddLtiFramework)
  addLtiFramework(
    { patchState }: StateContext<FrameworkStateModel>,
    { requestId, organizationUid, frameworkParams }: FrameworkActions.AddLtiFramework
  ) {
    patchState({
      addFrameworkActionState: loadingState(),
    });

    return this.dataService.createLtiFramework(requestId, organizationUid, frameworkParams).pipe(
      tap(({ isSuccess, error }) => {
          if ( isSuccess ) {
            patchState({
              addFrameworkActionState: dataLoadedState(true),
            });
            this.store.dispatch(new LoadLtiFrameworks(organizationUid, requestId));
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'framework.message.success.created'
            );
          } else {
            patchState({
              addFrameworkActionState: errorState(error),
            });
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'framework.message.error.created'
            );
          }
        }
      ));
  }

  @Action(FrameworkActions.UpdateFramework)
  updateFramework(
    { patchState }: StateContext<FrameworkStateModel>,
    { frameworkId, frameworkParams, frameworkImageFile }: FrameworkActions.UpdateFramework
  ) {
    patchState({
      addFrameworkActionState: loadingState(),
    });

    let originalImageName = '';
    if ( frameworkImageFile ) {
      originalImageName = ImageUploadNameResolver.resolveImageName(frameworkImageFile.name);
      frameworkParams.imageName = originalImageName;
    }

    return this.dataService.updateFramework(frameworkId, frameworkParams).pipe(
      tap(({ isSuccess, error }) => {
          if ( isSuccess ) {
            patchState({
              addFrameworkActionState: dataLoadedState(true),
            });
            SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'framework.message.success.updated');
          } else {
            patchState({
              addFrameworkActionState: errorState(error),
            });
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        }
      ),
      switchMap(({ isSuccess, value, error }) => {
        if ( isSuccess ) {
          if ( frameworkImageFile ) {
            return this.uploadLogoImage(value, frameworkImageFile);
          } else {
            return ObservableResult.ofSuccess();
          }
        } else {
          return ObservableResult.ofError(error);
        }
      })
    );
  }

  @Action(FrameworkActions.UpdateLtiFramework)
  updateLtiFramework(
    { patchState }: StateContext<FrameworkStateModel>,
    { frameworkId, organizationUid, requestId, frameworkParams }: FrameworkActions.UpdateLtiFramework
  ) {
    patchState({
      addFrameworkActionState: loadingState(),
    });

    return this.dataService.updateFramework(frameworkId, frameworkParams).pipe(
      tap(({ isSuccess, error }) => {
          if ( isSuccess ) {
            patchState({
              addFrameworkActionState: dataLoadedState(true),
            });
            this.store.dispatch(new LoadLtiFrameworks(organizationUid, requestId));
            SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'framework.message.success.updated');
          } else {
            patchState({
              addFrameworkActionState: errorState(error),
            });
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        }
      ));
  }

  private uploadLogoImage(frameworkUpdateResponse: Framework, frameworkImageFile: File): ObservableResult<void> {
    const imageUrl = frameworkUpdateResponse.imageUrl;
    const filePath = FileUploadHelper.filePath(imageUrl);
    return this.fileUploadService.uploadFile(filePath, frameworkImageFile).pipe(
      tap((result) => {
          if ( !result.isSuccess ) {
            SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'uploadImageError');
          }
        }
      )
    );
  }

  @Action(FrameworkActions.CreateTag)
  createTag(
    { getState, patchState }: StateContext<FrameworkStateModel>,
    { title }: FrameworkActions.CreateTag
  ) {

    patchState({
      addTagState: loadingState(),
    });

    const state = getState();
    const foundTag = state.filteredMainTags.find(tag => tag.title === title);

    if ( !foundTag ) {
      return this.tagService.createTag({ title: title }).pipe(
        tap(({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              addTagState: dataLoadedState(value),
            });
          } else {
            const errorObject: TagErrorObject = JSON.parse(error);

            patchState({
              addTagState: errorState(errorObject.errorMessage),
            });
          }
        })
      );
    } else {
      patchState({
        addTagState: dataLoadedState(foundTag),
      });
      return undefined;
    }
  }

  @Action(FrameworkActions.EmptyAddTagState)
  emptyAddTagState(
    { patchState }: StateContext<FrameworkStateModel>
  ) {
    patchState({
      addTagState: defaultLoadableState(),
    });
  }

  @Action(FrameworkActions.FilterMainTags)
  filterMainTags(
    { patchState }: StateContext<FrameworkStateModel>,
    { text }: FrameworkActions.FilterMainTags
  ) {
    const updateState = (newFilteredTags) => {
      patchState({
        filteredMainTags: newFilteredTags,
      });
    };
    if ( text ) {
      return this.tagService.findTagsWithTitleTerm(text).pipe(
        tap(({ value }) => {
          updateState(value);
        })
      );
    } else {
      return updateState([]);
    }
  }

  @Action(FrameworkActions.FilterCategories)
  filterCategories(
    { patchState }: StateContext<FrameworkStateModel>,
    { text }: FrameworkActions.FilterCategories
  ) {
    const updateState = (newFilteredTags) => {
      patchState({
        filteredMainTags: newFilteredTags,
      });
    };
    if ( text ) {
      return this.tagService.findCategoriesWithTitleTerm(text).pipe(
        tap(({ value }) => {
          updateState(value);
        })
      );
    } else {
      return updateState([]);
    }
  }


  @Action(FrameworkActions.FilterLtiTags)
  filterLtiTags(
    { patchState }: StateContext<FrameworkStateModel>,
    { organizationUid, requestId, text }: FrameworkActions.FilterLtiTags
  ) {
    const updateState = (newFilteredTags) => {
      patchState({
        filteredMainTags: newFilteredTags,
      });
    };
    if ( text ) {
      return this.tagService.findLtiTagsWithTitleTerm(organizationUid, requestId, text).pipe(
        tap(({ value }) => {
          updateState(value);
        })
      );
    } else {
      return updateState([]);
    }
  }

  @Action(FrameworkActions.AddFrameworkToOrganization)
  addFrameworkToOrganization(
    { getState, patchState }: StateContext<FrameworkStateModel>,
    { frameworkId }: FrameworkActions.AddFrameworkToOrganization
  ) {

    patchState({
      addFrameworkActionState: loadingState(),
    });

    return this.dataService.addFrameworkToOrganization(frameworkId).pipe(
      tap(({ isSuccess, error }) => {
          if ( isSuccess ) {
            patchState({
              addFrameworkActionState: dataLoadedState(true),
            });

            this.store.dispatch(new FrameworkActions.LoadOrganizationFrameworks());
            SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'uploadImageError');
          } else {
            const err = JSON.parse(error);
            patchState({
              addFrameworkActionState: errorState(
                this.translationService.getTranslation('framework.message.error.addToOrganization')
              ),
            });

            if ( err.status === 409 ) {
              const state = getState();
              const organizationFrameworkState = state.organizationFramework;
              const frameworkTitle = organizationFrameworkState.data.title;
              const organizationName = organizationFrameworkState.data.organization.name;
              const translation = this.translationService.getTranslation('framework.message.error.existsInOrganization');
              const message = translation.replace('{frameworkTitle}', frameworkTitle).replace('{organizationName}', organizationName);
              SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, message);
            } else {
              SnackbarHelper.showTranslatableSnackBar(
                this.ngZone, this.snackBar, this.translationService, 'framework.message.error.addToOrganization')
              ;
            }
          }
        }
      ));
  }

  @Action(FrameworkActions.LoadAllCompetencies)
  loadAllCompetencies(
    { patchState }: StateContext<FrameworkStateModel>,
    { global }: FrameworkActions.LoadAllCompetencies
  ) {
    patchState({
      allCompetences: loadingState(),
    });

    return this.competenceDataService.getOrganizationCompetenciesIncludingGlobal(global).pipe(
      tap(({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              allCompetences: dataLoadedState(value),
            });
          } else {
            patchState({
              allCompetences: errorState(error),
            });
          }
        }
      ));
  }

  @Action(FrameworkActions.AssignCompetenceToTag)
  assignCompetenceToTag(
    { patchState }: StateContext<FrameworkStateModel>,
    { tagUid, competencyUid }: FrameworkActions.AssignCompetenceToTag
  ) {

    patchState({
      competenceAssignment: false,
    });

    return this.competenceDataService.connectCompetenceToTag(tagUid, competencyUid).pipe(
      tap(({ isSuccess }) => {
        if ( isSuccess ) {
          patchState({
            competenceAssignment: true,
          });
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'framework.message.success.competenceAssigned'
          );
        } else {
          patchState({
            competenceAssignment: false,
          });
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'framework.message.error.competenceAssigning'
          );
        }
      }));
  }

  @Action(FrameworkActions.CreateCompetence)
  createCompetence(
    { patchState }: StateContext<FrameworkStateModel>,
    { request }: FrameworkActions.CreateCompetence
  ) {
    patchState({
      newCompetence: loadingState(),
    });

    return this.competenceDataService.createCompetence(request).pipe(
      tap(({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              newCompetence: dataLoadedState(value),
              competenceCreateUpdateError: '',
            });
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'framework.message.success.competenceCreated'
            );
          } else {
            const err = JSON.parse(error);
            let errorMsg = this.translationService.getTranslation('framework.message.error.competenceCreating');
            let errorStatus = 'ERROR';
            if ( err.status === 409 ) {
              errorMsg = this.translationService.getTranslation('framework.message.error.competenceNameExists');
              errorStatus = 'DUPLICATION';
            }

            patchState({
              newCompetence: errorState(errorMsg),
              competenceCreateUpdateError: (errorStatus as CompetenceCreationError),
            });
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, errorMsg);
          }
        }
      ));
  }

  @Action(FrameworkActions.UpdateCompetence)
  updateCompetence(
    { patchState }: StateContext<FrameworkStateModel>,
    { competenceUid, request }: FrameworkActions.UpdateCompetence
  ) {
    patchState({
      newCompetence: loadingState(),
    });

    return this.competenceDataService.updateCompetence(competenceUid, request).pipe(
      tap(({ isSuccess, value, error }) => {
          if ( isSuccess ) {
            patchState({
              newCompetence: dataLoadedState(value),
              competenceCreateUpdateError: '',
            });
            SnackbarHelper.showTranslatableSnackBar(
              this.ngZone, this.snackBar, this.translationService, 'framework.message.success.competenceUpdated'
            );
          } else {
            const err = JSON.parse(error);
            let errorMsg = this.translationService.getTranslation('framework.message.error.competenceUpdating');
            let errorStatus = 'ERROR';

            if ( err.status === 409 ) {
              errorMsg = this.translationService.getTranslation('framework.message.error.competenceNameExists');
              errorStatus = 'DUPLICATION';
            }

            patchState({
              newCompetence: errorState(errorMsg),
              competenceCreateUpdateError: (errorStatus as CompetenceCreationError),
            });
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, errorMsg);
          }
        }
      ));
  }

  @Action(FrameworkActions.AddCompetenceLevel)
  addCompetenceLevel(
    { patchState }: StateContext<FrameworkStateModel>,
    { competenceUid, request }: FrameworkActions.AddCompetenceLevel
  ) {

    patchState({
      competenceLevels: loadingState(),
    });

    return this.competenceDataService.addCompetenceLevel(competenceUid, request).pipe(
      tap(({ isSuccess, value, error }) => {
        if ( isSuccess ) {
          patchState({
            competenceLevels: dataLoadedState(value.levels),
          });
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'framework.message.success.competenceLevelAdded'
          );
        } else {
          patchState({
            competenceLevels: errorState(error),
          });
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      }));
  }

  @Action(FrameworkActions.UpdateCompetenceLevel)
  updateCompetenceLevel(
    { getState, patchState }: StateContext<FrameworkStateModel>,
    { competenceUid, competenceLevelUid, request }: FrameworkActions.UpdateCompetenceLevel
  ) {
    const state = getState();
    const competenceLevels = state.competenceLevels.data;

    patchState({
      competenceLevels: loadingState(),
    });

    return this.competenceDataService.updateCompetenceLevel(competenceUid, competenceLevelUid, request).pipe(
      tap(({ isSuccess, value, error }) => {
        if ( isSuccess ) {
          const levels = competenceLevels.map(level => {
            if ( level.uid === value.uid ) {
              return value;
            }
            return level;
          });

          patchState({
            competenceLevels: dataLoadedState(levels),
          });
          SnackbarHelper.showTranslatableSnackBar(
            this.ngZone, this.snackBar, this.translationService, 'framework.message.success.competenceLevelUpdated'
          );
        } else {
          patchState({
            competenceLevels: errorState(error),
          });
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      }));
  }

  @Action(FrameworkActions.EmptyNewCompetence)
  emptyNewCompetence(
    { patchState }: StateContext<FrameworkStateModel>
  ) {
    patchState({
      newCompetence: defaultLoadableState(),
    });
  }

  @Action(FrameworkActions.EmptyCompetenceLevels)
  emptyCompetenceLevels(
    { patchState }: StateContext<FrameworkStateModel>
  ) {
    patchState({
      competenceLevels: defaultLoadableState(),
    });
  }

  @Action(FrameworkActions.SetCompetenceAssignmentToFalse)
  setCompetenceAssignmentToFalse(
    { patchState }: StateContext<FrameworkStateModel>
  ) {
    patchState({
      competenceAssignment: false,
    });
  }

}
