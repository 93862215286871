/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

// -------------------------------------------------------------------------


import {LearningGoalIconRequest, Report, UpdateReportSectionResponse} from '../../../../shared/models';

export class UploadDynamicContentFiles {

  static readonly type = '[Diagnostics] Upload Report dynamic content files';

  constructor(public content: Report, public enrichedContentFromBackend: UpdateReportSectionResponse) {
  }
}

// -------------------------------------------------------------------------
export class UpdateAssessmentChartIcon {
  static readonly type = 'Update chart icons';

  constructor(
    public assessmentUid: string,
    public sectionUid: string,
    public dynamicContentUid: string,
    public type: 'MAX' | 'MIN',
    public data: LearningGoalIconRequest,
    public file: File,
  ) {
  }
}
// -------------------------------------------------------------------------
export class RemoveAssessmentChartIcon {
  static readonly type = 'Remove chart icons';

  constructor(
    public assessmentUid: string,
    public sectionUid: string,
    public dynamicContentUid: string,
    public type: 'MAX' | 'MIN',
  ) {
  }
}


export class GetReport {
  static readonly type = '[Diagnostics] Get report';

  constructor(public assessmentsUid: string, public responsesUid: string, public languageCode?: string) {
  }
}

export class ClearReportState {
  static readonly type = '[Diagnostics] Clear report';
}

