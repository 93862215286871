import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[ogCopyText]'
})
export class CopyTextDirective {
  @Input() copiedMessage = 'Text copied!';
  private messageElement: HTMLElement | null = null;

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.preventDefault();
    this.showCopiedMessage(event.target as HTMLElement);
  }

  private showCopiedMessage(targetElement: HTMLElement) {
    if (!this.messageElement) {
      this.messageElement = document.createElement('div');
      this.messageElement.textContent = this.copiedMessage;
      this.messageElement.style.position = 'absolute';
      this.messageElement.style.top = '-35px';
      this.messageElement.style.left = '50%';
      this.messageElement.style.transform = 'translateX(-50%)';
      this.messageElement.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
      this.messageElement.style.color = '#fff';
      this.messageElement.style.fontSize = '12px';
      this.messageElement.style.padding = '6px';
      this.messageElement.style.borderRadius = '5px';
      this.messageElement.style.zIndex = '9999';

      targetElement.parentElement?.appendChild(this.messageElement);

      setTimeout(() => {
        this.messageElement?.remove();
        this.messageElement = null;
      }, 1000);
    }
  }
}
