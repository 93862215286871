<div class="collector-review__section">
  <div class="collector-review__section-header">
    {{ 'translations.collector.title.reviewLogsSubmitted' | transloco: {number: learnerCollectorSummary?.loggedItems.length ?? 0} }}
  </div>

  <ptl-collector-table
    *ngIf="learnerCollectorSummary"
    [learnerCollectorSummary]="learnerCollectorSummary"
    [staticView]="true">
  </ptl-collector-table>
</div>
