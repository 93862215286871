import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {GroupElement, TextElement} from '../../../../../models';
import { LanguageCodeHelper } from '../../../../../helpers/language-code-helper';

@Component({
  selector: 'ptl-app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {

  @Input() option: TextElement[] | GroupElement[]
  @Input() selectedElement: {uId: string}
  @Input() uId: string
  @Input() i = 0
  @Input() groupNumber = 0
  @Output() groupSelectedData = new EventEmitter()

  currentElements = []
  languageDirection = LanguageCodeHelper.getBodyLanguageDir();


  ngOnInit() {
    if(this.option){
      if(!this.option.length){
        this.currentElements.push(this.option)
      }else{
        this.option.forEach(item => {
          this.currentElements.push(item)
        })
      }
    }
  }

  onGroupSelect($event, i, uid, children, value) {
    const allData = {data: {$event, i, uid, children, value}, fromChild: true}
    this.groupSelectedData.emit(allData)
  }

}
