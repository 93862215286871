<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<div class="image-crop-dialog__container">
  <div *ngIf="!isAnimatedGif" class="image-crop-dialog__cropper-container">

    <div class="image-crop-dialog__description" *ngIf="description">
      {{ description }}
    </div>

    <image-cropper
      [imageFile]="imageFile"
      [imageBase64]="imageBase64"
      [containWithinAspectRatio]="maintainAspectRatio"
      [maintainAspectRatio]="maintainAspectRatio"
      [aspectRatio]="aspectRatio"
      [roundCropper]="roundCropper"
      [resizeToWidth]="resizeToWidth"
      [resizeToHeight]="resizeToHeight"
      [backgroundColor]="backgroundColor"
      [format]="imageFormat"
      (imageCropped)="updateCroppedImage($event)"
    ></image-cropper>
  </div>

  <div *ngIf="isAnimatedGif" class="animated-gif-container">
    <img [src]="imageBase64" alt="Animated GIF" />
  </div>

  <div class="image-crop-dialog__footer">
    <div class="image-crop-dialog__button-container">
      <button
        mat-button
        class="image-crop-dialog__cancel-btn uppercase"
        title="Cancel"
        (click)="cancelCrop()"
        color="warn">{{ 'translations.global.button.cancel' | transloco }}
      </button>
      <button
        mat-button
        class="image-crop-dialog__add-btn uppercase"
        title="Apply"
        (click)="saveCrop()">{{ 'translations.global.button.apply' | transloco }}
      </button>
    </div>
  </div>
</div>
