<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div class="next-card-button-box">
  <a
    class="next-card-button"
    *ngIf="showPreviousButton"
    tabindex="0"
    [attr.aria-label]="('translations.global.ariaLabels.openPreviousCard' | transloco) + ':' + previousButtonTitle"
    (click)="openNextPreviousButtonClick(false)"
    (keydown.enter)="openNextPreviousButtonClick(false)"> <mat-icon aria-hidden="true">first_page</mat-icon> {{ previousButtonTitle }}
  </a>

  <a
    class="next-card-button next-button"
    *ngIf="showNextButton"
    tabindex="0"
    [attr.aria-label]="('translations.global.ariaLabels.openNextCard' | transloco) + ':' + nextButtonTitle"
    (click)="openNextPreviousButtonClick(true)"
    (keydown.enter)="openNextPreviousButtonClick(true)">{{ nextButtonTitle }} <mat-icon aria-hidden="true">last_page</mat-icon>
  </a>

  <a
    class="next-card-button next-button"
    *ngIf="!showNextButton"
    tabindex="0"
    [attr.aria-label]="('translations.global.ariaLabels.returnTo' | transloco) + ' ' + isNavigationInGroup ? ('translations.global.ariaLabels.group' | transloco) : ('translations.global.ariaLabels.playlist' | transloco)"
    (click)="returnToPlaylistOrGroup()"
    (keydown.enter)="returnToPlaylistOrGroup()">
    <span *ngIf="!isNavigationInGroup">{{ 'translations.returnToPlaylist' | transloco }}</span>
    <span *ngIf="isNavigationInGroup">{{ 'translations.returnToGroup' | transloco }}</span>
  </a>
</div>
