/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, NgZone, OnDestroy, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { filter, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthState } from '../../store/user-auth.state';
import { Observable, Subscription } from 'rxjs';
import { Organization, OrganizationAuthType } from '../../../shared/models';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';

@Component({
  selector: 'ptl-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss'],
})
export class SignupFormComponent implements OnDestroy {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  /** Emits on back icon click. */
  @Output() backClicked = new EventEmitter<void>();

  showVerificationCodeForm = false;
  invitationId: string;
  invitationToken: string;
  showMaintenanceOverlay: boolean;

  private isPrearrivalPage: boolean;
  private accountCreationSubscription: Subscription;
  private organizationDataSubscription: Subscription;

  constructor(
    store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private maintenanceService: MaintenanceService,
  ) {
    this.showMaintenanceOverlay = this.maintenanceService.showMaintenanceMessage();
    this.isPrearrivalPage = document.location.pathname === '/prearrivals/signup';
    if ( this.router.url.includes('invitation/') ) {
      const params = this.router.parseUrl(this.router.url).queryParamMap;
      this.invitationToken = params.get('token');
      this.invitationId = this.route.snapshot.paramMap.get('invitationId');
    } else {
      this.accountCreationSubscription = store.select(
        state => state.userAuth.accountCreationState.accountCreationSucceeded
      ).pipe(
        filter(Boolean),
        tap(() => this.showVerificationCodeForm = true))
        .subscribe();

      this.organizationDataSubscription = this.organizationData$.subscribe(data => {
        if ( data ) {
          if ( (data.authType === OrganizationAuthType.SAML || data.authType === OrganizationAuthType.SAML_SP) && !data.preArrivals ) {
            this.onNavigateToSignIn();
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.accountCreationSubscription?.unsubscribe();
    this.organizationDataSubscription?.unsubscribe();
  }

  onNavigateToSignIn() {
    if ( this.isPrearrivalPage ) {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, '/prearrivals/signin');
    } else {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, '/signin');
    }
  }

}
