/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccessTokenService } from '../../user-auth/services/access-token.service';
import { TranslationService } from './translation/translation.service';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../user-auth/store/user-auth.state';
import { Organization } from '../models';
import { environment } from '../../../environments/environment';

/** Attaches Authorization header to every request. */
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  @Select(UserAuthState.organizationDetails)
  organizationDetailsData$: Observable<Organization>;

  private authorizationHeader = 'Authorization';
  private organizationHeader = 'X-Org-Id';
  private acceptLanguageHeader = 'Accept-Language';
  private contentLanguageHeader = 'Content-Language';

  constructor(
    private readonly accessTokenService: AccessTokenService,
    private readonly translationService: TranslationService
  ) {
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.accessTokenService.getAccessToken();

    const language = this.translationService.getLanguage();
    if (!request.headers.has(this.acceptLanguageHeader)) {
      request = request.clone({ headers: request.headers.set(this.acceptLanguageHeader, language) });
    }

    if (request.method !== 'GET') {
      if (!request.headers.has(this.contentLanguageHeader)) {
        request = request.clone({ headers: request.headers.set(this.contentLanguageHeader, language) });
      }
    }

    if (!!accessToken &&
      !request.url.startsWith('https://s3-') &&
      !request.url.startsWith('https://dev-yuna-origin.s3') &&
      !request.url.startsWith('https://prod-yuna-origin.s3') &&
      !request.url.includes('/organizations?domain')
    ) {
      this.organizationDetailsData$.subscribe(details => {
        const authHeaderValue = 'Bearer ' + accessToken;
        if (details && details._id && !request.url.startsWith(environment.realmUrl)) {
          request = request.clone(
            {
              headers: request.headers
                .set(this.authorizationHeader, authHeaderValue)
                .set(this.organizationHeader, details._id),
            });
        } else {
          request = request.clone(
            { headers: request.headers.set(this.authorizationHeader, authHeaderValue) });
        }
      });
    } else {
      request = request.clone({ headers: request.headers.delete(this.authorizationHeader) });
    }
    return next.handle(request);
  }
}
