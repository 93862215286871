<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<label
  tabindex="0"
  [hidden]="doNotShowButton"
  class="toolbar-action-button mat-stroked-button mat-button-base"
  [matTooltip]="(uploadType === 'IMAGE' ? 'translations.editor.toolbar.label.uploadImage' :
    uploadType === 'AUDIO' ? 'translations.editor.toolbar.label.uploadAudio' :
    uploadType === 'VIDEO' ? 'translations.editor.toolbar.label.uploadVideo' :
    'translations.editor.toolbar.label.uploadFile') | transloco"
  matRipple>
  <ng-container *ngIf="uploadType === 'IMAGE'">
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
      <path d="M0 5v40h50V5zm2 2h46v26H37.32l-7-5h-5.906l-4.086-4.086-4.867.977-4.305-6.461L2 27.586zm35.5 6a4.516 4.516 0 00-4.5 4.5c0 2.473 2.027 4.5 4.5 4.5s4.5-2.027 4.5-4.5-2.027-4.5-4.5-4.5zm0 2c1.39 0 2.5 1.11 2.5 2.5S38.89 20 37.5 20 35 18.89 35 17.5s1.11-2.5 2.5-2.5zm-26.656 6.57l3.695 5.54 5.133-1.024L23.586 30h6.094l7 5H48v8H2V30.414z"/>
    </svg>
    {{ 'translations.editor.toolbar.button.addImage' | transloco }}
  </ng-container>
  <ng-container *ngIf="uploadType === 'AUDIO'">
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
      <path d="M26.61 2c-.669 0-1.305.277-1.755.766L12.562 16H5c-1.645 0-3 1.355-3 3v12c0 1.645 1.355 3 3 3h7.563l12.292 13.234c.45.489 1.086.766 1.754.766A2.406 2.406 0 0029 45.61V4.39A2.406 2.406 0 0026.61 2zm0 2c.226 0 .39.164.39.39v41.22c0 .226-.164.39-.39.39a.395.395 0 01-.29-.125L14 32.609V17.391L26.316 4.125A.396.396 0 0126.61 4zm13.085 6.422l-1.12 1.652C43.007 14.68 46 19.492 46 25s-2.992 10.32-7.426 12.926l1.121 1.652C44.66 36.61 48 31.195 48 25s-3.34-11.61-8.305-14.578zm-3.37 4.957l-1.134 1.664C38.047 18.555 40 21.551 40 25c0 3.45-1.953 6.445-4.809 7.957l1.133 1.664C39.707 32.738 42 29.137 42 25c0-4.137-2.293-7.738-5.676-9.621zM5 18h7v14H5c-.559 0-1-.441-1-1V19c0-.559.441-1 1-1zm27.922 2.387l-1.168 1.722A2.991 2.991 0 0134 25a2.991 2.991 0 01-2.246 2.89l1.168 1.723A5.012 5.012 0 0036 25a5.007 5.007 0 00-3.078-4.613z"/>
    </svg>
  </ng-container>
  <ng-container *ngIf="uploadType === 'VIDEO'">
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
      <path d="M2 4a1 1 0 00-1 1v4.832a1 1 0 000 .326v29.674a1 1 0 000 .326V45a1 1 0 001 1h46a1 1 0 001-1v-4.832a1 1 0 000-.326V10.168a1 1 0 000-.326V5a1 1 0 00-1-1H2zm1 2h3v3H3V6zm5 0h4v3H8V6zm6 0h4v3h-4V6zm6 0h4v3h-4V6zm6 0h4v3h-4V6zm6 0h4v3h-4V6zm6 0h4v3h-4V6zm6 0h3v3h-3V6zM3 11h44v28H3V11zm17.941 7A1 1 0 0020 19v12a1 1 0 001.514.857l10-6a1 1 0 000-1.714l-10-6A1 1 0 0021.04 18a1 1 0 00-.1 0zM22 20.766L29.057 25 22 29.234v-8.468zM3 41h3v3H3v-3zm5 0h4v3H8v-3zm6 0h4v3h-4v-3zm6 0h4v3h-4v-3zm6 0h4v3h-4v-3zm6 0h4v3h-4v-3zm6 0h4v3h-4v-3zm6 0h3v3h-3v-3z"/>
    </svg>
  </ng-container>
  <ng-container *ngIf="uploadType === 'FILE'">
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-hidden="true">
      <path d="M25 .594l-.719.687-8 8a1.014 1.014 0 000 1.438c.399.398 1.04.398 1.438 0L24 4.438V32c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879V4.437l6.281 6.282c.399.398 1.04.398 1.438 0a1.014 1.014 0 000-1.438l-8-8zM7 16v34h36V16H33a1.006 1.006 0 00-.879.496 1.01 1.01 0 000 1.008c.184.312.52.5.879.496h8v30H9V18h8c.36.004.695-.184.879-.496a1.01 1.01 0 000-1.008c-.184-.312-.52-.5-.879-.496z"/>
    </svg>
    {{ 'translations.editor.toolbar.button.uploadFile' | transloco }}
  </ng-container>
  <input
    #uploadInput
    class="f_file-upload"
    type="file"
    [accept]="fileUploadTypes"
    (change)="onFileChanged($event)">
</label>
