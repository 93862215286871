/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { canTriggerSearch } from '../../helpers/content-helper';
import { LanguageValue, OrganizationRoles } from '../../models';
import { LanguageCodeHelper } from '../../helpers/language-code-helper';

@Component({
  selector: 'ptl-role-search-autocomplete',
  templateUrl: './role-search-autocomplete.component.html',
  styleUrls: ['./role-search-autocomplete.component.scss'],
})
export class RoleSearchAutocompleteComponent {

  @Input() roles: OrganizationRoles[];
  @Input() loadingRoles: boolean;
  @Input() disabled: boolean;
  @Input() placeholder = 'Search role';

  @Output() searchInputChanged = new EventEmitter<string>();
  @Output() searchLoadedMore = new EventEmitter<string>();
  @Output() roleSelected = new EventEmitter<OrganizationRoles>();

  canLoadMore: boolean;
  isLoadingMore: boolean;

  private searchString: string;

  onFocus(): void {
    if (this.searchString === undefined) {
      this.searchString = '';
      this.searchInputChanged.emit('');
    }
  }

  onTextPaste(event: ClipboardEvent) {
    if (event.type === 'paste') {
      setTimeout(() => {
        this.searchString = (event.target as HTMLInputElement).value;
        this.searchInputChanged.emit(this.searchString);
      }, 0);
    }
  }

  onSearchInputChange(event: KeyboardEvent) {
    if (canTriggerSearch(event)) {
      this.searchString = (event.target as HTMLInputElement).value;
      this.searchInputChanged.emit(this.searchString);
    }
  }

  disabledAutocomplete(): string {
    return '';
  }

  onRoleSelected({ option }: MatAutocompleteSelectedEvent) {
    const data = option.value as OrganizationRoles;
    this.roleSelected.emit(data);
  }

  onAutocompleteScroll() {
    if (!this.canLoadMore) {
      return;
    }
    if (!this.isLoadingMore) {
      this.searchLoadedMore.emit(this.searchString);
      this.isLoadingMore = true;
    }
  }

  getNameOfRole(roleNames: LanguageValue[]): string {
    return LanguageCodeHelper.getDataByLanguageCode(roleNames).value;
  }
}
