/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { delay } from 'rxjs/operators';
import { ObservableResult } from '../../shared/store';
import {
  AccountInvitationJoinRequest,
  OnBoardingDataRequest,
  UserAccountCreationRequest,
  UserDetails
} from '../models';
import { LtiResponse, SamlSPResponse } from '../models/lti.model';
import { BasicAuthService } from './basic-auth.service';
import { DEFAULT_LANGUAGE_CODE } from '../../shared/services/translation/translation.service';
import { LoginFinalizationResponse, TokenValidationResponse } from '../models/login-response.model';

/** Mock/disabled authentication service. */
@Injectable()
export class MockAuthService implements BasicAuthService {
  private readonly userDetails: UserDetails = {
    uid: '123CDA',
    firstName: 'Oliver',
    lastName: 'Bernard',
    email: 'oliver.bernard@email.com',
    imageUrl: 'assets/profile_image.png',
    eptid: '253451234gfrqwerb',
    roles: [{
      type: 'SUPER_ADMIN',
      _id: '123123',
      name: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: 'name' }],
      privileges: [{
        assigned: true,
        name: 'some-name',
        _id: 'someId',
        label: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: 'label' }],
        privilegeGroup: 'SUPER_ADMIN',
      }],
    }, {
      type: 'ADMIN',
      _id: '123123',
      name: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: 'name' }],
      privileges: [{
        assigned: true,
        name: 'some-name',
        _id: 'someId',
        label: [{ languageCode: DEFAULT_LANGUAGE_CODE, value: 'label' }],
        privilegeGroup: 'ADMIN',
      }],
    }],
    workspaces: [],
    organization: {
      _id: 'foo bar',
      name: 'Test Uni',
    },
  };

  constructor() {
    console.log(`
      --------------------------
      Authentication is disabled
      --------------------------
    `);
  }


  createAccount({ email }: UserAccountCreationRequest): ObservableResult<void> {
    console.log(`Signing up ${email}`);
    return ObservableResult.ofSuccess();
  }

  createAccountWithInvitationToken(
    signupRequest: UserAccountCreationRequest,
    invitationId: string,
    invitationToken: string,
    organizationDomain: string
  ): ObservableResult<void> {
    console.log(`Signing up invitation ${invitationId} with token ${invitationToken}`);
    return ObservableResult.ofSuccess();
  }

  verifyAccount(verificationToken: string): ObservableResult<void> {
    console.log(`Confirming signup using token ${verificationToken}`);
    return ObservableResult.ofSuccess().pipe(delay(1500));
  }

  resendVerificationEmail(email: string,
                          organizationUid: string,
                          captchaResponseV3?: string,
                          captchaResponseV2?: string): ObservableResult<void> {
    return ObservableResult.ofSuccess().pipe(delay(1500));
  }

  authenticate(): ObservableResult<UserDetails> {
    return ObservableResult.ofSuccess(this.userDetails);
  }

  fetchUserDetails(): ObservableResult<UserDetails> {
    return ObservableResult.ofSuccess(this.userDetails);
  }

  unauthenticate(redirectUri?: string): ObservableResult<void> {
    return ObservableResult.ofSuccess<void>();
  }

  getLtiToken(requestId: string, isPlaylist: boolean): ObservableResult<LtiResponse> {
    return ObservableResult.ofSuccess();
  }

  getSamlSPToken(requestId: string): ObservableResult<SamlSPResponse> {
    return ObservableResult.ofSuccess();
  }

  finalize(email?: string): ObservableResult<LoginFinalizationResponse> {
    return ObservableResult.ofSuccess();
  }

  finalizeFolioLogin(destinationOrganization: string, overrideToken: boolean, email?: string): ObservableResult<LoginFinalizationResponse> {
    return ObservableResult.ofSuccess();
  }

  sendOnBoardData(data: OnBoardingDataRequest): ObservableResult<UserDetails> {
    return ObservableResult.ofSuccess();
  }

  validateInvitationToken(invitationId: string, token: string): ObservableResult<TokenValidationResponse> {
    return ObservableResult.ofSuccess();
  }

  joinOrganizationWithInvitationToken(
    request: AccountInvitationJoinRequest,
    invitationId: string,
    invitationToken: string
  ): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }
}
