/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../store';
import { InjectionToken } from '@angular/core';
import { LeftNavItemSummary } from '../../models/sidebar-tree/sidebar-tree.model';

/** Injection token for the data service, used by angular to decide whether to inject Mock or API service at runtime. */
export const SIDEBAR_TREE_DATA_SERVICE =
  new InjectionToken<SidebarTreeDataService>('[resource] SidebarTreeDataService');

/** The service handling tag CRUD operations. */
export interface SidebarTreeDataService {


  loadPageChildren(pageUri: string, publisherUri?: string, contentPackageUri?: string): ObservableResult<LeftNavItemSummary[]>;

  loadPlaylistChildren(playlistUri: string, pageUri?: string, publisherUri?: string, contentPackageUri?: string):
    ObservableResult<LeftNavItemSummary[]>;

  loadGroupCardChildren(cardUri: string, pageUri?: string, publisherUri?: string, contentPackageUri?: string):
    ObservableResult<LeftNavItemSummary[]>;

  loadProjectChildren(playlistUri: string): ObservableResult<LeftNavItemSummary[]>;
}

