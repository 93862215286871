/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
  AssessmentSummary,
  CreateReportConditionalSectionRequest, CreateReportResourceSectionRequest, CreateReportStandardChartSectionRequest,
  CreateReportStandardSectionRequest, DiagnosticDescriptionQuestionResponse,
  DiagnosticLogic,
  DiagnosticQuestionRequest,
  DiagnosticQuestionResponse,
  Diagnostics,
  DiagnosticsRetake,
  GeneratedReport, LearningGoalIconRequest, LogicGoal, LogicGoalLevel, MyPagesAssessmentsResponse, QuestionEditor,
  QuestionResponseRequest,
  QuestionResponses,
  Report, ReportMode,
  ReportReordering, ShowReportLogoRequest,
  UpdateReportConditionalSectionRequest, UpdateReportResourceSectionRequest,
  UpdateReportSectionResponse,
  UpdateReportStandardSectionRequest,
  UpdateReportTitleRequest
} from '../../../shared/models';
import { RestClientService } from '../../../shared/services/rest-client.service';
import { ObservableResult } from '../../../shared/store';
import { DiagnosticsDataService } from './data.service';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { LanguageCodeHelper } from '../../../shared/helpers/language-code-helper';

@Injectable()
export class ApiDiagnosticsDataService implements DiagnosticsDataService {

  private assessmentsUrl = Location.joinWithSlash(environment.apiRootUrl || '', 'assessments');

  constructor(private client: RestClientService, private translationService: TranslationService) {
  }

  createQuestion(assessmentUid: string, questionData: DiagnosticQuestionRequest, languageCode?: string)
    : ObservableResult<DiagnosticQuestionResponse> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/questions`;
    return this.client.post<DiagnosticQuestionResponse>(
      url,
      questionData,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateQuestion')))
      );
  }

  updateQuestion(assessmentUid: string, questionUid: string, questionData: DiagnosticQuestionRequest, languageCode?: string):
    ObservableResult<DiagnosticQuestionResponse> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/questions/${questionUid}`;
    return this.client.patch<DiagnosticQuestionResponse>(url,
      questionData,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingQuestion')))
      );
  }

  updateQuestionDescription(assessmentUid: string, questionUid: string, questionData: QuestionEditor, languageCode?: string):
    ObservableResult<DiagnosticDescriptionQuestionResponse> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/questions/${questionUid}/description`;
    return this.client.patch<DiagnosticDescriptionQuestionResponse>(url,
      questionData,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingQuestionDescription')))
      );
  }

  copyQuestion(assessmentUid: string, questionUid: string): ObservableResult<DiagnosticQuestionResponse> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/questions/${questionUid}/clone`;
    return this.client.post<DiagnosticQuestionResponse>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCloneQuestion')))
      );
  }

  deleteQuestion(assessmentUid: string, questionUid: string): ObservableResult<void> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/questions/${questionUid}`;
    return this.client.delete<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorDeleteQuestion')))
      );
  }

  reorderQuestions(assessmentUid: string, questions: string[]): ObservableResult<DiagnosticQuestionResponse[]> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/questions/reorder`;
    return this.client.post(url, { questions }).pipe(
      switchMap(({ body }) => ObservableResult.ofSuccess(body)),
      catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorReorderQuestion')))
    );
  }

  getDiagnosticsLogicGoals(assessmentUid: string): ObservableResult<DiagnosticLogic[]> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/logic/goals`;
    return this.client.get<DiagnosticLogic[]>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetLearningGoals')))
      );
  }

  updateLogicGoal(assessmentUid: string, goalUid: string, goalData: LogicGoal): ObservableResult<Diagnostics> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/logic/goals/${goalUid}`;
    return this.client.patch<Diagnostics>(url, goalData)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateLearningGoals')))
      );
  }

  updateLogicGoalLevel(
    assessmentUid: string,
    goalUid: string,
    levelUid: string,
    levelData: LogicGoalLevel
  ): ObservableResult<Diagnostics> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/logic/goals/${goalUid}/levels/${levelUid}`;
    return this.client.patch<Diagnostics>(url, levelData)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateLearningGoalsLevel')))
      );
  }


  updateLearningGoalIcon(
    assessmentUid: string, goalUid: string, type: 'MAIN' | 'MAX' | 'MIN', iconData: LearningGoalIconRequest, file: File
  ): ObservableResult<void> {
    let url = `${this.assessmentsUrl}/${assessmentUid}/goals/${goalUid}/icons`;
    if (type === 'MIN') {
      url += '/min';
    } else if (type === 'MAX') {
      url += '/max';
    }
    return this.client.patch<{ item: DiagnosticLogic; uploadUrl: string }>(url, iconData)
      .pipe(
        switchMap(({ body }) => this.uploadLearningGoalFileDirectly(body.uploadUrl, file, false)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateLearningGoalIcon')))
      );
  }

  removeLearningGoalIcon(assessmentUid: string, goalUid: string, type: 'MAIN' | 'MAX' | 'MIN'): ObservableResult<void> {
    let url = `${this.assessmentsUrl}/${assessmentUid}/goals/${goalUid}/icons`;
    if (type === 'MIN') {
      url += '/min';
    } else if (type === 'MAX') {
      url += '/max';
    }
    return this.client.delete<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateLearningGoalIcon')))
      );
  }


  updateAssessmentChartIcon(
    assessmentUid: string, sectionUid: string, dynamicContentUid: string, type: 'MAX' | 'MIN', iconData: LearningGoalIconRequest, file: File
  ): ObservableResult<void> {
    let url = `${this.assessmentsUrl}/${assessmentUid}/report/sections/${sectionUid}/charts/${dynamicContentUid}`;
    if (type === 'MIN') {
      url += '/xmin';
    } else if (type === 'MAX') {
      url += '/xmax';
    }
    return this.client.patch<{ item: DiagnosticLogic; uploadUrl: string }>(url, iconData)
      .pipe(
        switchMap(({ body }) => this.uploadLearningGoalFileDirectly(body.uploadUrl, file, false)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateChartIcon')))
      );
  }

  removeAssessmentChartIcon(
    assessmentUid: string, sectionUid: string, dynamicContentUid, type: 'MAX' | 'MIN'
  ): ObservableResult<void> {
    let url = `${this.assessmentsUrl}/${assessmentUid}/report/sections/${sectionUid}/charts/${dynamicContentUid}`;
    if (type === 'MIN') {
      url += '/xmin';
    } else if (type === 'MAX') {
      url += '/xmax';
    }
    return this.client.delete<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveChartIcon')))
      );
  }

  getQuestionResponses(assessmentUid: string): ObservableResult<QuestionResponses> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/responses`;
    return this.client.get<QuestionResponses>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetQuestionResponse')))
      );
  }

  getAssessmentSummary(assessmentUid: string, languageCode?: string): ObservableResult<AssessmentSummary> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/responses/summary`;
    return this.client.get<AssessmentSummary>(url, null, languageCode ? { 'Accept-Language': languageCode } : null)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetAssessmentSummary')))
      );
  }


  getAssessmentSummaryAdmins(assessmentUid: string, userUid: string, languageCode?: string): ObservableResult<AssessmentSummary> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/responses/admin/summary?user=${userUid}`;
    return this.client.get<AssessmentSummary>(url, null, languageCode ? { 'Accept-Language': languageCode } : null)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetAssessmentSummary')))
      );
  }

  getDiagnosticsQuestions(assessmentUid: string, languageCode?: string): ObservableResult<DiagnosticQuestionResponse[]> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/questions`;
    return this.client.get<DiagnosticQuestionResponse[]>(url,
      null,
      languageCode ? { 'Accept-Language': languageCode } : null)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetAssessmentQuestions')))
      );
  }

  addQuestionResponse(
    assessmentUid: string, version: number = 1, request: QuestionResponseRequest)
    : ObservableResult<QuestionResponses> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/responses?version=${version}`;
    return this.client.post<QuestionResponses>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(error => ObservableResult.ofError(error))
      );
  }

  updateDiagnosticsRetake(
    assessmentUid: string,
    request: DiagnosticsRetake
  ): ObservableResult<QuestionResponses> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/retakes`;
    return this.client.patch<QuestionResponses>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateAssessmentRetake')))
      );
  }

  randomizeQuestions(
    assessmentUid: string
  ): ObservableResult<QuestionResponses> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/randomize`;
    return this.client.patch<QuestionResponses>(url, true)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateAssessmentRandomize')))
      );
  }

  deleteRandomizeQuestions(
    assessmentUid: string
  ): ObservableResult<QuestionResponses> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/randomize`;
    return this.client.delete<QuestionResponses>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorDeleteAssessmentRandomize')))
      );
  }

  /** Reports. */

  createReportStandardSection(
    assessmentUid: string,
    request: CreateReportStandardSectionRequest,
    languageCode?: string
  ): ObservableResult<UpdateReportSectionResponse> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/sections`;
    return this.client.post<UpdateReportSectionResponse>(
      url,
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateReportSection')))
      );
  }

  createReportStandardChartSection(
    assessmentUid: string,
    request: CreateReportStandardChartSectionRequest,
    languageCode?: string
  ): ObservableResult<UpdateReportSectionResponse> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/sections/charts`;
    return this.client.post<UpdateReportSectionResponse>(
      url,
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateReportSection')))
      );
  }

  createReportConditionalSection(
    assessmentUid: string, request: CreateReportConditionalSectionRequest, languageCode?: string
  ): ObservableResult<UpdateReportSectionResponse> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/sections`;
    return this.client.post<UpdateReportSectionResponse>(
      url,
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateReportConditionalSection')))
      );
  }


  createReportResourceSection(
    assessmentUid: string,
    request: CreateReportResourceSectionRequest,
    languageCode?: string
  ): ObservableResult<UpdateReportSectionResponse> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/sections`;
    return this.client.post<UpdateReportSectionResponse>(
      url,
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorCreateReportSection')))
      );
  }

  updateReportTitle(assessmentUid: string, request: UpdateReportTitleRequest, languageCode?: string): ObservableResult<void> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/title`;
    return this.client.patch<void>(
      url,
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateReportTitle')))
      );
  }

  updateReportStandardSection(
    assessmentUid: string, sectionUid: string, request: UpdateReportStandardSectionRequest, languageCode?: string
  ): ObservableResult<UpdateReportSectionResponse> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/sections/standard/${sectionUid}`;
    return this.client.patch<UpdateReportSectionResponse>(
      url,
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateReportSection')))
      );
  }

  updateReportConditionalSection(
    assessmentUid: string, sectionUid: string, request: UpdateReportConditionalSectionRequest, languageCode?: string
  ): ObservableResult<UpdateReportSectionResponse> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/sections/conditional/${sectionUid}`;
    return this.client.patch<UpdateReportSectionResponse>(
      url,
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateReportSection')))
      );
  }

  updateReportResourceSection(
    assessmentUid: string, sectionUid: string, request: UpdateReportResourceSectionRequest, languageCode?: string
  ): ObservableResult<UpdateReportSectionResponse> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/sections/standard/${sectionUid}`;
    return this.client.patch<UpdateReportSectionResponse>(
      url,
      request,
      null,
      languageCode ? LanguageCodeHelper.checkAndGetContentLanguageCode(languageCode) : null,
    )
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdateReportSection')))
      );
  }

  deleteReportSection(assessmentUid: string, sectionUid: string): ObservableResult<void> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/sections/${sectionUid}`;
    return this.client.delete<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorDeleteReportSection')))
      );
  }

  reorderReportSections(assessmentUid: string, request: ReportReordering): ObservableResult<Report> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/sections/reorder`;
    return this.client.post<Report>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorReorderReportSection')))
      );
  }

  getReport(assessmentUid: string, responsesUid: string, languageCode?: string): ObservableResult<GeneratedReport> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/responses/${responsesUid}/report`;
    return this.client.get<GeneratedReport>(url, null, languageCode ? { 'Accept-Language': languageCode } : null)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorGetAssessmentReport')))
      );
  }

  showReportTitle(assessmentUid: string): ObservableResult<void> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/title/enable`;
    return this.client.patch<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorShowAssessmentReportTitle')))
      );
  }


  hideReportTitle(assessmentUid: string): ObservableResult<void> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/title/enable`;
    return this.client.delete<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorHideAssessmentReportTitle')))
      );
  }

  showHideReportOrgLogo(assessmentUid: string, request: ShowReportLogoRequest): ObservableResult<void> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/logo`;
    return this.client.patch<void>(url, request)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorShowHideAssessmentReportTitle')))
      );
  }

  updateReportMode(assessmentUid: string, mode: ReportMode): ObservableResult<void> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/mode`;
    return this.client.patch<void>(url, { reportMode: mode })
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorUpdatingReportMode')))
      );
  }

  addReportResourceTag(assessmentUid: string, sectionUid: string, tagUid: string): ObservableResult<void> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/sections/${sectionUid}/tags/${tagUid}`;
    return this.client.put<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorAddReportResourceTag')))
      );
  }

  deleteReportResourceTag(assessmentUid: string, sectionUid: string, tagUid: string): ObservableResult<void> {
    const url = `${this.assessmentsUrl}/${assessmentUid}/report/sections/${sectionUid}/tags/${tagUid}`;
    return this.client.delete<void>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveReportResourceTag')))
      );
  }

  getAssessmentsResponses(): ObservableResult<MyPagesAssessmentsResponse[]> {
    const url = `${this.assessmentsUrl}/responses`;
    return this.client.get<MyPagesAssessmentsResponse[]>(url)
      .pipe(
        switchMap(({ body }) => ObservableResult.ofSuccess(body)),
        catchError(() => ObservableResult.ofError(this.translationService.getTranslation('errors.errorRemoveReportResourceTag')))
      );
  }

  private uploadLearningGoalFileDirectly(presignedUrl: string, file: File, waitForOptimizer: boolean): ObservableResult<void> {
    return this.client.put<void>(presignedUrl, file, {}, { 'cache-control': 'public, max-age=31536000' })
      .pipe(
        switchMap(() => ObservableResult.ofSuccess()),
        catchError(() => ObservableResult.ofError(this.translationService?.getTranslation('errors.errorSavingImage'))),
        delay(waitForOptimizer ? 1500 : 0) // optimizer is uploading other image sizes
      );
  }


}
