/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, Inject, NgZone, OnDestroy, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '../../../../../../shared/services/translation/translation.service';
import {
  LEARNER_PLAYLIST_DATA_SERVICE,
  LearnerPlaylistDataService
} from '../../../../../../shared/services/learner-playlist/data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReviewerDetails } from '../../../../models';
import {
  UserSearchAutocompleteNewComponent
} from '../../../../../../shared/components/user-search-autocomplete-new/user-search-autocomplete-new.component';
import { SubmitPlaylistSummary } from '../../../../../../shared/models/playlist/playlist-report.model';
import { SnackbarHelper } from '../../../../../../shared/helpers/snackbar-helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserPlaylistSubmissionSummary } from '../../../../../../shared/models';
import { SubmissionMode } from 'src/app/page-modules/playlist/models/submission-logic.model';
import { LEARNER_VIEW_DATA_SERVICE, LearnerViewDataService } from 'src/app/page-modules/resource/services/data.service';
import { RefreshStateAfterFormSubmission } from 'src/app/page-modules/resource/store/learner-view.actions';
import { COLLECTOR_DATA_SERVICE, CollectorDataService } from 'src/app/shared/services/collector/collector-data.service';
import { RefreshUserPlaylistSubmissionSummary } from 'src/app/page-modules/playlist/store/view/playlist-view.state.actions';

@Component({
  templateUrl: './submit-dialog.component.html',
  styleUrls: ['./submit-dialog.component.scss'],
})
export class SubmitPlaylistReviewDialogComponent implements OnDestroy, AfterViewInit {

  @ViewChild('userSearchAutocomplete') private userSearchAutocomplete: UserSearchAutocompleteNewComponent;
  @ViewChild('dialogWrapper') private dialogWrapper: ElementRef;

  loadingMembers: boolean;
  members: ReviewerDetails[] = [];
  selectedReviewers: ReviewerDetails[] = [];
  disabledReviewers: ReviewerDetails[] = [];
  reviewNote = '';
  maxReviewersCount = 0;

  private userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
  private playlistUid: string;
  private playlistTitle: string;
  playlistSubmissionMode: SubmissionMode;
  mode: 'FORM' | 'PLAYLIST' | 'COLLECTOR';
  private currentPage = 0;
  private pageSize = 20;
  private searchInputSubscription: Subscription;
  private searchInputSubject = new Subject<string>();

  // form submission
  private resourceUid: string;
  private formUid: string;
  private learnerCollectorUid: string;
  private isProjectResource: boolean;
  private playlistUri: string;
  private resourceUri: string;
  private groupUid: string;
  private publisherUri: string;
  private packageUri: string;
  private pageUri: string;
  private languageCode: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private dialogRef: MatDialogRef<SubmitPlaylistReviewDialogComponent>,
    private translationService: TranslationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      playlistUid: string;
      playlistTitle: string;
      userPlaylistSubmissionSummary: UserPlaylistSubmissionSummary;
      mode: 'FORM' | 'PLAYLIST' | 'COLLECTOR';
      resourceUid: string;
      formUid: string;
      learnerCollectorUid: string;
      isProjectResource: boolean;
      playlistUri: string;
      resourceUri: string;
      groupUid: string;
      publisherUri: string;
      packageUri: string;
      pageUri: string;
      languageCode: string;
    },
    @Inject(LEARNER_PLAYLIST_DATA_SERVICE) private playlistDataService: LearnerPlaylistDataService,
    @Inject(LEARNER_VIEW_DATA_SERVICE) private learnerViewDataService: LearnerViewDataService,
    @Inject(COLLECTOR_DATA_SERVICE) private collectorDataService: CollectorDataService,
  ) {

    this.searchInputSubscription = this.searchInputSubject
      .pipe(debounceTime(500)).subscribe((searchValue) => this.fireSearch(searchValue, true));

    if (data) {
      this.playlistUid = data.playlistUid;
      this.playlistTitle = data.playlistTitle;
      this.userPlaylistSubmissionSummary = data.userPlaylistSubmissionSummary;
      this.playlistSubmissionMode = data.userPlaylistSubmissionSummary?.submissionMode;
      this.mode = data.mode;
      this.resourceUid = data.resourceUid;
      this.formUid = data.formUid;
      this.learnerCollectorUid = data.learnerCollectorUid;
      this.playlistUri = data.playlistUri;
      this.resourceUri = data.resourceUri;
      this.groupUid = data.groupUid;
      this.publisherUri = data.publisherUri;
      this.packageUri = data.packageUri;
      this.pageUri = data.pageUri;
      this.languageCode = data.languageCode;

      if (this.userPlaylistSubmissionSummary && this.userPlaylistSubmissionSummary.userReviewSummary.reviewType === 'REVIEWER'
        && this.userPlaylistSubmissionSummary.userReviewSummary.maxSelectableReviewers) {
        this.maxReviewersCount = this.userPlaylistSubmissionSummary.userReviewSummary.maxSelectableReviewers;
      }

      if (this.userPlaylistSubmissionSummary && this.userPlaylistSubmissionSummary.userReviewSummary.reviewers?.length > 0) {
        this.userPlaylistSubmissionSummary.userReviewSummary.reviewers
          .forEach(s => s.rejected ? this.disabledReviewers.push(s) : this.selectedReviewers.push(s));
      }
    }

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dialogWrapper.nativeElement.focus();
    }, 0)
  }

  ngOnDestroy(): void {
    this.searchInputSubscription?.unsubscribe();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSearchInputChange(searchValue: string) {
    this.searchInputSubject.next(searchValue);
  }

  disabledAutocomplete(): string {
    return '';
  }

  onMemberSelected(data: ReviewerDetails): void {
    if (this.maxReviewersCount && this.selectedReviewers.length >= this.maxReviewersCount) {
      return;
    }

    this.members.splice(this.members.findIndex(s => s.uid === data.uid), 1);
    this.selectedReviewers.splice(0, 0, { ...data, reviewerAdded: undefined, rejected: false });
  }

  onRemoveCollaborator(index: number): void {
    this.members.push(this.selectedReviewers[index] as ReviewerDetails);
    this.selectedReviewers.splice(index, 1);
  }

  onSearchLoadingMore(searchValue: string): void {
    this.fireSearch(searchValue, false);
  }

  submitForReview(): void {
    const request: SubmitPlaylistSummary = {
      commentToReviewers: this.reviewNote,
      reviewers: this.getReviewerIds(),
    };
    switch (this.mode) {
      case 'FORM': {
        this.learnerViewDataService.submitFormAndPlaylist(
          this.playlistUid,
          this.resourceUid,
          this.formUid,
          request
        ).subscribe(({ isSuccess, error }) => {
          if (isSuccess) {
            this.refreshStateAndFinishSubmission(isSuccess);
          } else {
            this.finishSubmission(isSuccess, undefined, error);
          }
        })
        break;
      }
      case 'PLAYLIST': {
        this.playlistDataService.submitPlaylistSummary(this.playlistUid, request).subscribe(({ isSuccess, error }) => {
          this.finishSubmission(isSuccess, 'successfullySubmittedForReview', error);
        })
        break;
      }
      case 'COLLECTOR': {
        this.collectorDataService.submitLearnerCollectorSummaryAndPlaylist(
          this.playlistUid,
          this.learnerCollectorUid,
          request
        ).subscribe(({ isSuccess, error }) => {
          if (isSuccess) {
            this.refreshStateAndFinishSubmission(isSuccess);
          } else {
            this.finishSubmission(isSuccess, undefined, error);
          }
        })
      }
    }
  }

  private refreshStateAndFinishSubmission(formSubmissionSuccess: boolean) {
    this.store.dispatch(new RefreshUserPlaylistSubmissionSummary(this.playlistUid));
    this.learnerViewDataService.getCardWithProgress(
      this.isProjectResource,
      this.playlistUri,
      this.resourceUri,
      this.groupUid,
      this.publisherUri,
      this.packageUri,
      this.pageUri,
      this.languageCode
  ).subscribe(
    ({ isSuccess, value }) => {
      if (isSuccess) {
        this.store.dispatch(new RefreshStateAfterFormSubmission(value));
        this.finishSubmission(formSubmissionSuccess, 'successfullyStartedProgressiveReview', undefined);
      }
    });
  }

  private finishSubmission(isSuccess: boolean, translationMessage?: string, error?: string) {
    if (isSuccess) {
      const message = this.translationService.getTranslation(`playlist.submissions.message.success.${translationMessage}`)
        .replace('{playlistTitle}', this.playlistTitle);
      SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, message);
      this.dialogRef.close(true);
    } else {
      SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
    }
  }

  private getReviewerIds(): string[] {
    const ids = [];
    for (const member of this.selectedReviewers) {
      if (!member.rejected) {
        ids.push(member.uid);
      }
    }
    return ids;
  }

  private fireSearch(searchValue: string, override: boolean): void {
    this.currentPage = override ? 0 : this.currentPage + 1;
    this.loadingMembers = true;

    this.playlistDataService.loadPlaylistReviewers(this.playlistUid, this.currentPage, this.pageSize, searchValue)
      .subscribe(({ isSuccess, value }) => {
        this.loadingMembers = false;
        if (isSuccess) {
          if (override) {
            this.members = value.content;
          } else {
            this.members = this.members.concat(value.content);
          }
          this.members = this.members.filter(s => !this.selectedReviewers.concat(this.disabledReviewers).some(t => s.uid === t.uid));
        }
        this.userSearchAutocomplete.canLoadMore = value.totalNumberOfElement > this.members.length;
        this.userSearchAutocomplete.isLoadingMore = false;
      });
  }
}

