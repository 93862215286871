import { FileUploadHelper } from './file-upload-helper';
import { v4 as uuidv4 } from 'uuid';

export class ImageUploadNameResolver {
  /** Appends a '_original' suffix to an image name. */
  static resolveImageName(name: string): string {
    const formattedName = FileUploadHelper.formatFileNameForUpload(name);
    const originalName = this.withOriginalSuffix(formattedName);
    const uuid = uuidv4();
    return uuid + '_' + originalName;
  }

  private static withOriginalSuffix(formattedName: string): string {
    const extensionIndex = formattedName.lastIndexOf('.');
    return formattedName.substring(0, extensionIndex)
      + '_original'
      + formattedName.substring(extensionIndex);
  }
}
