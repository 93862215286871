<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->

<span class="skeleton__header slide-row"></span>
<div class="box">
  <div class="skeleton__text skeleton__text--90 skeleton__text--margin slide-row"></div>
  <div class="skeleton__text skeleton__text--50 skeleton__text--margin slide-row"></div>
  <div class="skeleton__text skeleton__text--90 skeleton__text--margin slide-row"></div>
  <div class="skeleton__text skeleton__text--90 skeleton__text--margin slide-row"></div>
  <div class="skeleton__text skeleton__text--30 skeleton__text--margin slide-row"></div>
  <div class="skeleton__text skeleton__text--90 skeleton__text--margin slide-row"></div>
  <div class="skeleton__text skeleton__text--70 skeleton__text--margin slide-row"></div>
  <div class="skeleton__text skeleton__text--90 skeleton__text--margin slide-row"></div>
  <div class="skeleton__text skeleton__text--90 skeleton__text--margin slide-row"></div>
  <div class="skeleton__text skeleton__text--70 skeleton__text--margin slide-row"></div>
  <div class="skeleton__text skeleton__text--70 skeleton__text--margin slide-row"></div>
  <div class="skeleton__text skeleton__text--30 skeleton__text--margin slide-row"></div>
</div>
<div class="skeleton__card__container">
  <mat-card class="skeleton__card-item" *ngFor="let item of [1, 2, 3, 4]">
    <mat-card-title class="skeleton__card-top">
      <div class="skeleton__avatar"></div>
      <div class="skeleton__text skeleton__text-title slide-row"></div>
    </mat-card-title>
    <mat-card-content class="skeleton__card-content">
      <div class="skeleton__card-image"></div>
    </mat-card-content>
    <mat-card-footer class="skeleton__card-footer">
      <span class="skeleton__text skeleton__text-footer slide-row"></span>
    </mat-card-footer>
  </mat-card>
</div>
