/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FrameWorkState } from '../../../page-modules/admin/store/framework.state';
import { Observable, Subscription } from 'rxjs';
import { LoadableState } from '../../store';
import { Framework, FrameworkType } from '../../models/admin/admin.model';
import {
	LoadContentStoreFrameworks,
	LoadFrameworkCategories,
	LoadFrameworkDescendants,
	LoadFrameworks,
	LoadFrameworkTagDescendants,
	LoadLtiFrameworks,
	RemoveSelectedFrameworkFromStore
} from '../../../page-modules/admin/store/framework.actions';
import { filter, map, takeUntil } from 'rxjs/operators';
import { TagChangeEmitterData } from './framework-tags-list/framework-tags-list.component';
import { Organization, ResourceTag } from '../../models';
import { VersionHelper } from '../../helpers/version.helper';
import { FrameworkCreateComponent } from './create-framework/framework-create.component';
import { LTI_AUTH_SERVICE, LtiAuthService } from '../../../user-auth/services/lti-auth.service';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { UserDetails } from '../../../user-auth/models';
import { TagsList } from './frameworks-models/tag-list.model';


@Component({
  selector: 'ptl-add-framework-tags',
  templateUrl: './add-framework-tags.component.html',
  styleUrls: ['./add-framework-tags.component.scss', './add-framework-tags-new.component.scss'],
})
export class AddFrameworkTagsComponent implements OnInit, OnDestroy {

  /** Is only in lti module */
  @Input() isLtiModule: boolean;
  /** Is dialog container or static folio page */
  @Input() momentTags: ResourceTag[];
  /** Is dialog container or static folio page */
  @Input() isDialogPage: boolean;
  /** Is only a single selection allowed */
  @Input() singleSelection: boolean;
  /** Only framework can be selected */
  @Input() selectFrameworks: boolean;
  /** Only content store tags can be selected */
  @Input() selectContentStoreTags: boolean;
  /** Only discovery tags can be selected */
  @Input() selectDiscoveryTags: boolean;
  /** Selected Tag Ids for default select */
  @Input() selectedTagIds: string[];
  /** Disable flag for checkboxes */
  @Input() isTagDisabled = false;
  /** Show admin actions */
  @Input() type: 'DEFAULT' | 'ADMIN' = 'DEFAULT';
  /** Framework type to be managed */
  @Input() frameworkType: FrameworkType = 'STANDARD';

  /** Emits when a palette action was evoked. */
  @Output() addTagBulk = new EventEmitter<ResourceTag[]>();
  /** Emits when a palette action was evoked. */
  @Output() addFrameworkBulk = new EventEmitter<Framework[]>();

  /** Emits event to close framework tags table. */
  @Output() closeFrameworkTags = new EventEmitter<boolean>();

  @Select(FrameWorkState.frameworks)
  frameworks$: Observable<LoadableState<Framework[]>>;

  @Select(FrameWorkState.frameworkDescendants)
  frameworkDescendants$: Observable<LoadableState<ResourceTag[]>>;

  @Select(FrameWorkState.tagDescendants)
  tagDescendants$: Observable<LoadableState<ResourceTag[]>>;

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @Select(UserAuthState.userDetailsData)
  userDetailsData$: Observable<UserDetails>;

  @Select(UserAuthState.userHasSuperAdminRole)
  isUserSuperAdmin$: Observable<boolean>;

  @Select(UserAuthState.userHasAdminRole)
  isUserAdmin$: Observable<boolean>;

  @ViewChild('frameworkCreateComponent') frameworkCreateComponent: FrameworkCreateComponent;

  frameworks: Framework[];
  frameworkTags: ResourceTag[];
  nestedTags: ResourceTag[];
  newVersionEnabled = VersionHelper.newVersionEnabled();
  isCreateFrameworkState = false;

  selectedFramework: Framework = null;
  selectedFrameworkId: string;
  selectedTags: ResourceTag[] = [];
  selectedFrameworks: Framework[] = [];
  opened = false;

  private tagsHierarchyIds: string[] = [];
  private loadedTags: { [uid: string]: (ResourceTag & { checked?: boolean })[] } = {};
  private frameworksSubscription: Subscription;
  private frameworkDescendantsSubscription: Subscription;
  private subscriptionEnd$ = new EventEmitter<void>();
  private selectLastCreatedFramework: boolean;

  constructor(private store: Store,
    private cd: ChangeDetectorRef,
    @Inject(LTI_AUTH_SERVICE) private ltiAuthService: LtiAuthService) {
  }

  ngOnInit(): void {
    this.loadFrameworks();

    this.setFrameworksData();
    if (!this.selectFrameworks) {
      this.setFrameworkTags();
    }
  }

  ngOnDestroy() {
    this.frameworkDescendantsSubscription?.unsubscribe();
    this.frameworksSubscription?.unsubscribe();
    this.subscriptionEnd$?.emit();
  }

  onLoadFrameworkTags(frameworkId: string) {
    this.isCreateFrameworkState = false;
    this.cd.detectChanges();

    this.selectedFramework = this.frameworks.find((item) => {
      return item.uid === frameworkId
    });
    this.selectedFrameworkId = frameworkId;
    this.store.dispatch(new LoadFrameworkDescendants(frameworkId));
    this.resetTagsHierarchy();
  }

  onFrameworkSelected(framework: Framework) {
    if (this.singleSelection) {
      this.selectedFrameworks.push(framework);
      this.onAddFramework();
    } else {
      if (framework.active) {
        this.selectedFrameworks.push(framework);
      } else {
        this.selectedFrameworks = this.selectedFrameworks.filter(item => item._id !== framework._id);
      }
    }
  }

  onCheckboxChange(event: TagChangeEmitterData) {
    if (event.checked) {
      this.selectedTags.push({
        _id: event.tag._id, // TODO will remove in feature
        id: event.tag._id,
        title: event.tag.title,
      });
    } else {
      const index = this.selectedTags.findIndex(tag => tag._id === event.tag._id);

      if (index !== -1) {
        this.selectedTags.splice(index, 1);
      }
    }

    this.selectedTags = this.makeArrayUniqueByProperty(this.selectedTags, '_id');

    if (this.singleSelection) {
      this.onAddTag();
    }
  }

  onCancel() {
    this.closeFrameWorkPanel();
    if (this.frameworkType === 'CATEGORIES') {
      this.isCreateFrameworkState = false;
    }
  }

  onAddTag() {
    this.addTagBulk.emit(this.selectedTags.map(tag => ({ ...tag, frameworkId: this.selectedFrameworkId })));
    this.closeFrameWorkPanel();
  }

  onAddFramework() {
    if (this.selectedFrameworks && this.selectedFrameworks.length) {
      this.addFrameworkBulk.emit(this.selectedFrameworks);
      this.closeFrameWorkPanel();
    }
  }

  onLoadNestedTags(tagId: string) {
    this.store.dispatch(new LoadFrameworkTagDescendants(this.selectedFrameworkId, tagId)).toPromise().then(() => {
      this.tagDescendants$.pipe(
        filter(({ loading, data }) => !loading && !!data),
        map(({ data }) => data)).subscribe(tags => {
        if (tags.length) {
          this.nestedTags = this.setAndGetLoadedTags(tagId, tags);
          this.tagsHierarchyIds.push(tagId);
        }
      }).unsubscribe();
    });
  }

  onNestedTagsBack() {
    this.tagsHierarchyIds.pop();
    const previousTagId = this.tagsHierarchyIds[this.tagsHierarchyIds.length - 1];

    if (!previousTagId) {
      this.nestedTags = undefined;
    } else {
      this.nestedTags = this.loadedTags[previousTagId];
    }
  }

  onOpenAddFrameworkView() {
    this.selectedFrameworkId = null;
    this.selectedFramework = null;
    this.isCreateFrameworkState = true;
    this.cd.detectChanges();
  }

  onCreateFramework() {
    this.frameworkCreateComponent.onAddFramework();
  }

  onUpdateFramework() {
    this.frameworkCreateComponent.onUpdateFramework();
    if (this.frameworkType === 'CATEGORIES') {
      this.isCreateFrameworkState = false;
    }
  }

  onEditTag() {
    this.isCreateFrameworkState = true;
    this.cd.detectChanges();
  }

  onFrameworkCreated() {
    this.selectLastCreatedFramework = true;
  }

  closeComponent(): void {
    this.closeFrameworkTags.emit(false);
  }

  private loadFrameworks() {
    if (!this.isLtiModule) {
      if (this.selectContentStoreTags) {
        this.store.dispatch(new LoadContentStoreFrameworks());
      } else if (this.selectDiscoveryTags) {
        this.store.dispatch(new LoadFrameworkCategories());
      } else if (this.frameworkType === 'CATEGORIES'){
        this.store.dispatch(new LoadFrameworkCategories());
      } else {
        this.store.dispatch(new LoadFrameworks());
      }

      return;
    }

    this.organizationData$.pipe(
      takeUntil(this.subscriptionEnd$)
    ).subscribe((org) => {
      this.subscriptionEnd$?.emit();
      const organizationUid = org._id;
      const requestId = this.ltiAuthService.getLtiRequestId();

      this.store.dispatch(new LoadLtiFrameworks(organizationUid, requestId));
    });
  }

  private makeArrayUniqueByProperty(arr: TagsList[], property: string): TagsList[] {
    const uniqueProps: Set<keyof TagsList> = new Set();
    return arr.filter((obj: TagsList) => {
      if (uniqueProps.has(obj[property])) {
        return false;
      }
      uniqueProps.add(obj[property]);
      return true;
    });
  }

  private setAndGetLoadedTags(key: string, tags: (ResourceTag & { checked?: boolean })[]) {
    if (!this.loadedTags[key]) {
      this.loadedTags[key] = tags;
      return this.loadedTags[key];
    } else {
      for (const item of this.loadedTags[key]) {
        tags.map((tag) => {
          if (item._id === tag._id) {
            tag.checked = item.checked;
          }
          return tag;
        });
      }
      this.loadedTags[key] = tags;
      return this.loadedTags[key];
    }
  }

  private resetTagsHierarchy() {
    this.nestedTags = undefined;
    this.tagsHierarchyIds = [];
  }

  private setFrameworksData() {
    this.frameworksSubscription = this.frameworks$.pipe(
      filter(({ loading, data }) => !loading && !!data),
      map(({ data }) => data.map((framework: Framework) => {
          return framework;
        })
      )).subscribe(frameworks => {
      this.frameworks = frameworks;
        this.opened = true;
      this.triggerFrameworkItemClick();
    });
  }

  private triggerFrameworkItemClick() {
    if (this.selectLastCreatedFramework) {
      setTimeout(() => {
        const frameworksList = document.querySelectorAll('.f_framework-list-item');

        if (frameworksList) {
          const lastElement = frameworksList[frameworksList.length - 1] as HTMLElement;

          if (lastElement) {
            lastElement.click();
            lastElement.scrollIntoView();
          }
        }
      }, 0)
    }

    if (this.selectedFrameworkId) {
      setTimeout(() => {
        const element = document.querySelector('.f_framework-item-' + this.selectedFrameworkId);
        (element as HTMLElement)?.click();
        element?.scrollIntoView();
      }, 0)
    }
  }

  private setFrameworkTags() {
    this.frameworkDescendantsSubscription = this.frameworkDescendants$.pipe(
      filter(({ loading, data }) => !loading && !!data),
      map(({ data }) => data))
      .subscribe(frameworkTags => {
        this.frameworkTags = this.setAndGetLoadedTags(this.selectedFrameworkId, frameworkTags);
      });
  }

  private closeFrameWorkPanel() {
    this.closeFrameworkTags.emit(false);
    this.removeFrameworkFromStore();
  }

  private removeFrameworkFromStore() {
    this.store.dispatch(new RemoveSelectedFrameworkFromStore());
    if (this.frameworkType === 'CATEGORIES' && this.type === 'ADMIN') {
      this.store.dispatch(new LoadFrameworkCategories());
    }
  }

}
