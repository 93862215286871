/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { ChartContent } from '../../../../models/editor/chart-content.model';
import { ChartDataset } from 'chart.js';
import { LocalTimeHelper } from '../../../../helpers/local-time-helper';

@Component({
  selector: 'ptl-bar-chart-preview',
  templateUrl: './bar-chart-preview.component.html',
  styleUrls: ['./bar-chart-preview.component.scss'],
})
export class BarChartPreviewComponent implements OnInit, OnChanges {

  @Input() chartContent: ChartContent;

  @Input() datasets: ChartDataset[];

  @Input() labels: string[];

  @Input() chartVersionStart: number;
  @Input() chartVersionEnd: number;

  @Input() chartSectionItems: {title:string; sectionUid:string}[];

  iconExists: boolean;
  completedEndText: string;
  completedStartText: string;

  ngOnInit() {
    this.updateCompletionText();
    if (this.chartContent) {
      this.checkIfIconsExists();
    }
  }

  ngOnChanges() {
    this.updateCompletionText();
  }

  scrollTo(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getLocalDateTimeFormat( date: Date ) {
    return LocalTimeHelper.getLocalDateTime(date).format('DD MMMM YYYY, HH:mm');
  }

  private updateCompletionText() {
    if(this.chartContent && this.chartVersionEnd || this.chartVersionStart) {
      if(this.chartVersionEnd) {
        this.completedEndText = this.getLocalDateTimeFormat(
          this.chartContent?.versionedScores.find(version => version.version === this.chartVersionEnd).completedOn
        )
      }
      if (this.chartVersionStart) {
        this.completedStartText = this.getLocalDateTimeFormat(
          this.chartContent?.versionedScores.find(version => version.version === this.chartVersionStart).completedOn
        )
      }
    }
  }

  private checkIfIconsExists() {
    this.iconExists = false;
    for ( const item of this.chartContent.items ) {
      if (item.icon && (item.icon.iconImageUrl || item.icon.iconClass)) {
        this.iconExists = true;
        break
      }
    }
  }
}
