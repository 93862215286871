<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="collector__dialog-container">
  <div class="collector__dialog-main">
    <button mat-button [mat-dialog-close]="false" class="collector__dialog-close">
      <i class="material-icons" aria-hidden="true">close</i>
    </button>

    <img class="collector__dialog-potential" src="{{(organizationData$| async)?.logo}}" width="88" height="67" alt="">

    <h2 class="collector__dialog-subtitle">{{ 'translations.collector.title.addFormsToLog' | transloco }}</h2>

      <ptl-editor
        class="collector__dialog-editor"
        [textFormOnly]="true"
        [link]="true"
        [form]="true"
        [embed]="true"
        [upload]="true"
        [separator]="true"
        [content]="formData"
        (contentUpdate)="onContentUpdate($event, formData)"
        (contentRemoval)="onContentRemoval($event, formData)"
        (contentCreated)="onContentCreation($event, formData)">
      </ptl-editor>
  </div>
</div>
<div class="collector__view-btn-container">
  <button
    mat-flat-button
    type="button"
    class="uppercase mat-green"
    (click)="updateCollectorFormLog()">
    {{ 'translations.collector.button.addFormsToLog' | transloco }}
  </button>
</div>
