<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="import-users-dialog-content" *ngIf="!confirmationPageShown else confirmationPage">
  <div class="dialog-top-info">
    <h1 class="dialog-title">{{ isEvent ? ('translations.membersNew.controlPanel.import.event.title' | transloco) : ('translations.membersNew.controlPanel.import.cardPlaylist.title' | transloco) }}</h1>
    <p *ngIf="isEvent" class="dialog-description medium">{{ ('translations.membersNew.controlPanel.import.event.welcomePageDescription' | transloco) }}</p>
    <p *ngIf="isPlaylistPage" class="dialog-description medium">{{ ('translations.membersNew.controlPanel.import.cardPlaylist.welcomePageDescriptionPlaylist' | transloco) }}</p>
    <p *ngIf="isCardPage && !isEvent && !isPlaylistPage" class="dialog-description medium">{{ ('translations.membersNew.controlPanel.import.cardPlaylist.welcomePageDescriptionPlaylist' | transloco) }}</p>
    <button class="close-dialog-btn"
            mat-icon-button color="primary"
            (click)="closeDialog()"
            [attr.aria-label]="('translations.global.ariaLabels.closeDialog' | transloco )">
      <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.close' | transloco)">
        <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <div class="import-users-image-container">
    <img alt="" width="266" height="150" [src]="'/assets/members/import_users_csv_format.png'"/>
    <a
      class="download-template-link"
      href="https://static.yuna.potential.ly/public/files/account_invitations_template.csv"
      target="_blank">
      {{ 'translations.invitations.label.inviteMultipleEmails.downloadTemplate' | transloco }}
      </a>&nbsp;
  </div>

  <div class="upload-btn-container">
    <ng-container>
      <input
        #csvFileInput
        type="file"
        accept=".csv"
        hidden
        [attr.aria-label]="('translations.global.ariaLabels.uploadCSV' | transloco)"
        (change)="onFileChanged($event)">
      <button
        mat-stroked-button
        [disabled]="importInProgress"
        class="upload-csv-file-btn"
        type="button"
        [attr.aria-label]="('translations.global.ariaLabels.uploadCSV' | transloco)"
        (click)="csvFileInput.click()">
        <div class="button-content">
          <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.upload' | transloco)">
            <use ogSvgUnify="assets/sprite/sprite.svg#upload"></use>
          </svg>
          <span class="small bold">{{ 'translations.membersNew.controlPanel.import.uploadCsvFile' | transloco }}</span>
        </div>
      </button>
    </ng-container>
  </div>
</div>

<ng-template #confirmationPage>
  <div class="import-users-dialog-content">
    <div class="dialog-top-info">
      <h1 class="dialog-title">{{ 'translations.membersNew.controlPanel.import.importAttendee' | transloco }}</h1>
      <button class="close-dialog-btn"
              mat-icon-button color="primary"
              (click)="closeDialog()"
              [attr.aria-label]="('translations.global.ariaLabels.icons.close' | transloco)">
        <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.close' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
        </svg>
      </button>
    </div>

    <div *ngIf="invalidEmails.length > 0" class="not-matched-emails-container">
      <div class="section-description">
        <svg class="svg warning" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.close' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
        </svg>
        <span class="medium">
          {{ 'translations.membersNew.controlPanel.import.notMatchedEmails' | transloco: {count: invalidEmails.length} }}
        </span>
      </div>
      <div *ngFor="let email of invalidEmailsVisible"
        class="validation-entry not-matched-email">
        <span class="small">
          {{ email }}
        </span>
      </div>
      <div class="pagination-wrapper" *ngIf="invalidEmailsPaginationLength > 1">
        <ptl-pagination-block
          [pageSize]="pageSize"
          [totalNumberOfElement]="invalidEmailsPaginationLength"
          [currentPage]="invalidEmailsPageNumber"
          [showPageSize]="false"
          [startedFromZero]="false"
          (pageChanged)="onInvalidEmailsPageChange($event)"></ptl-pagination-block>
      </div>
    </div>

    <div *ngIf="validUsers.length > 0" class="valid-users-container">
      <div class="section-description">
        <svg class="svg positive" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.check' | transloco)">
          <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
        </svg>
        <span class="medium">
          {{ 'translations.membersNew.controlPanel.import.validUsers' | transloco: {count: validUsers.length} }}
        </span>
      </div>
      <div *ngFor="let user of validUsersVisible"
        class="validation-entry valid-user">
        <div class="valid-user-profile-image-with-name">
          <img class="" src="{{ user.image ? user.image : '../../../../../../../../assets/profile_image.png' }}"
            alt="{{ user.firstName }} {{ user.lastName }}"/>
          <span class="small">{{ user.firstName }} {{ user.lastName }}</span>
        </div>
        <div class="valid-user-email">
          <span class="small">{{ user.email }}</span>
        </div>
      </div>
      <div class="pagination-wrapper" *ngIf="validUsersPaginationLength > 1">

        <ptl-pagination-block
          [pageSize]="pageSize"
          [totalNumberOfElement]="validUsersPaginationLength"
          [currentPage]="validUsersPageNumber"
          [showPageSize]="false"
          [startedFromZero]="false"
          (pageChanged)="onValidUsersPageChange($event)"></ptl-pagination-block>
      </div>
    </div>

    <div *ngIf="validUsers.length === 0 && invalidEmails.length === 0"
      class="no-entries-found-message-container">
      <div class="no-entries-found-message-container-inner">
        <div class="header medium bold">
          {{ 'translations.membersNew.controlPanel.import.noEntriesFoundTitle' | transloco }}
        </div>
        <div class="small">
          {{ 'translations.membersNew.controlPanel.import.noEntriesFoundDescription' | transloco }}
        </div>
      </div>
    </div>

    <div *ngIf="invalidEmails.length > 0 || validUsers.length > 0" class="import-users-action-bar">
      <button
        *ngIf="invalidEmails.length > 0"
        mat-stroked-button
        type="button"
        [attr.aria-label]="('translations.global.ariaLabels.downloadUnmatchedEmails' | transloco)"
        (click)="downloadUnmatchedEmails()">
        <span class="small bold">
          {{ 'translations.membersNew.controlPanel.import.downloadUnmatched' | transloco }}
        </span>
      </button>
      <button
        *ngIf="isEvent && validUsers.length > 0"
        mat-stroked-button
        class="suggested"
        type="button"
        [attr.aria-label]="('translations.global.ariaLabels.addUsersToAttendeeList' | transloco)"
        (click)="participateValidUsersFromCsvFile()">
        <span class="small bold">
          {{ validUsers.length === 1 ? ('translations.membersNew.controlPanel.import.event.addToTheAttendeeListSingular' | transloco: {count: validUsers.length}) : ('translations.membersNew.controlPanel.import.event.addToTheAttendeeListPlural' | transloco: {count: validUsers.length}) }}
        </span>
      </button>
      <button
        *ngIf="!isEvent && validUsers.length > 0"
        mat-stroked-button
        class="suggested"
        type="button"
        [attr.aria-label]="('translations.global.ariaLabels.importUsers' | transloco)"
        (click)="importValidUsersFromCsvFile()">
        <span class="small bold">
          {{ validUsers.length === 1 ? ('translations.membersNew.controlPanel.import.cardPlaylist.importSingular' | transloco: {count: validUsers.length}) : ('translations.membersNew.controlPanel.import.cardPlaylist.importPlural' | transloco: {count: validUsers.length}) }}
        </span>
      </button>
    </div>
  </div>
</ng-template>
