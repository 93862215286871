/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output
} from '@angular/core';
import {
  LEARNER_PLAYLIST_DATA_SERVICE,
  LearnerPlaylistDataService
} from '../../../services/learner-playlist/data.service';


@Component({
  selector: 'ptl-favorite-new-button',
  templateUrl: './favorite-new-button.component.html',
  styleUrls: ['./favorite-new-button.component.scss'],
})
export class FavoriteNewButtonComponent {

  @Input() uid: string;
  @Input() title: string;
  @Input() editMode: boolean;
  @Input() favorite: boolean;

  @Output() favoriteToggle = new EventEmitter<void>();

  constructor(
    @Inject(LEARNER_PLAYLIST_DATA_SERVICE) private learnerPlaylistDataService: LearnerPlaylistDataService
  ) {
  }

  onFavoriteToggleClick() {
    if (!this.editMode) {
      if (this.favorite) {
        this.learnerPlaylistDataService.removePlaylistFromFavorites(this.uid).subscribe(({ isSuccess }) => {
          if (isSuccess) {
            this.favoriteToggle.emit();
          }
        });
      } else {
        this.learnerPlaylistDataService.addPlaylistToFavorites(this.uid).subscribe(({ isSuccess }) => {
          if (isSuccess) {
            this.favoriteToggle.emit();
          }
        });
      }
    }
  }

}

