<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<ng-container *ngIf="true">
  <div class="framework-tracker-view__header" *ngIf="title" tabindex="0" [attr.aria-label]="('translations.global.ariaLabels.section' | transloco: {title: title} )">
    <h1>{{title}}</h1>
  </div>
  <div class="framework-tracker-view__content">
    <div class="framework-tracker-view__item" *ngFor="let tag of section?.learnerTags, let i = index">
      <span class="framework-tracker-view__item-score-info" tabindex="0">{{tag.tag.title}} ({{tag.userScore}})</span>
      <div class="framework-tracker-view__item-progress-box">
        <div
          class="framework-tracker-view__item-progress-bar"
          [ngStyle]="{'background-color' : generateColorStyle(i)}">
          <div
            class="framework-tracker-view__item-progress-bar-inner"
            [ngStyle]="{
              'width' : (viewInitialized ? tag.progress + '%' : ''),
              'background-color' : generateColorStyle(i)
            }"
          >
          </div>
        </div>
      </div>
      <span class="framework-tracker-view__item-progress-info" tabindex="0">{{tag.scaleLevel.name}}
        <span *ngIf="tag.nextScaleLevel"> - {{tag.nextScaleLevel.minPoints - tag.userScore}} to {{tag.nextScaleLevel.name}}</span>
      </span>
    </div>
  </div>
</ng-container>
