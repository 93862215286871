/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */


// -------------------------------------------------------------------------
import { Playlist, PlaylistCardShort } from '../../../../shared/models';

export class LoadPlaylistViewDetails {
  static readonly type = '[Playlist view] Load playlist details';

  constructor(
    public playlistUri: string,
    public publisherUri: string,
    public packageUri: string,
    public pageUri: string,
    public languageCode?: string
  ) {
  }
}

export class LoadProjectPlaylistViewDetails {
  static readonly type = '[Playlist view] Load project playlist details';

  constructor(
    public playlistUri: string,
    public languageCode?: string
  ) {
  }
}

export class LoadPlaylistViewDetailsInLanguage {
  static readonly type = '[Playlist view] Load playlist details in a language';

  constructor(
    public playlistUri: string,
    public publisherUri: string,
    public packageUri: string,
    public pageUri: string,
    public languageCode: string
  ) {
  }
}

export class RefreshPlaylistViewDetails {
  static readonly type = '[Playlist view] Refresh playlist Details';

  constructor(
    public playlistUri: string,
    public publisherUri: string,
    public packageUri: string,
    public pageUri: string,
    public languageCode?: string
  ) {
  }
}

// -------------------------------------------------------------------------
export class NavigateToRootPage {
  static readonly type = '[Playlist view] Navigate to root page';
}

export class OpenResourceDetails {
  static readonly type = '[Playlist view] Open resource details';

  constructor(public resource: PlaylistCardShort,
    public isEditView?: boolean,
    public folioPublicId?: string,
    public queryUriParam?: string) {
  }
}

export class OpenPlaylistEdit {
  static readonly type = '[Playlist view] Open playlist edit';

  constructor(public playlistUri: string, public formattedUri: string, public editParam?: string) {
  }
}

export class SetPlaylistViewDetails {
  static readonly type = '[Playlist view] Set playlist view details';

  constructor(public playlist: Playlist) {
  }
}

export class OpenWebLink {
  static readonly type = '[Playlist view] Open weblink in a new tab';

  constructor(public resource: PlaylistCardShort) {
  }
}

export class OpenWebLinkEdit {
  static readonly type = '[Playlist view] Open weblink edit';

  constructor(public resource: PlaylistCardShort, public groupUri?: string) {
  }
}

export class CloneCard {
  static readonly type = '[Card clone] Clone the card';

  constructor(public card: PlaylistCardShort, public sectionUid: string) {
  }
}

export class UpdateCardUri {
  static readonly type = '[Playlist view] Update card uri';

  constructor(public cardUid: string, public cardUri: string) {
  }
}

export class UpdatePlaylistLearnerViewCards {
  static readonly type = '[Playlist view] Update section cards';

  constructor(public cards: PlaylistCardShort[], public sectionUid: string) {
  }
}

export class RefreshUserPlaylistSubmissionSummary {
  static readonly type = '[Playlist view] Refresh user playlist submission summary';

  constructor(public playlistUid: string) {
  }
}

export class PlaylistCardWasOpened {
  static readonly type = '[Playlist view] Dispatch playlist card opened change';

  constructor(public opened: boolean) {
  }
}
