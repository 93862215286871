/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */


export enum FeatureCheckModel {
	LMS_SKILLS = 'lms_skills',
	AI_ASSISTANT = 'ai_assistant',
  EDITOR_JS = 'editor_js',
  PROFILE_CERTS = 'profile_certs',
  ENROLLMENT = 'enrollment',
  EMAIL_NOTIFICATIONS = 'email_notifications',
  CATEGORIES = 'categories',
  EVENTS_TOP_BAR_HIDDEN = 'events_top_bar_hidden'
}
