/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Organization } from '../../../shared/models';
import { TriggerFolioUserLogin } from '../../store/user-auth.actions';
import { UserAuthState } from '../../store/user-auth.state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../../page-modules/account/services/account-data.service';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { environment } from '../../../../environments/environment';
import { InvisibleReCaptchaComponent, ReCaptchaV3Service, ScriptService } from 'ngx-captcha';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { filter } from 'rxjs/operators';
import { LanguageCodeHelper } from '../../../shared/helpers/language-code-helper';
import { VersionHelper } from '../../../shared/helpers/version.helper';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';

@Component({
  templateUrl: './alumni-login.component.html',
  styleUrls: ['./alumni-login.component.scss'],
})
export class AlumniLoginComponent implements OnInit, OnDestroy {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @Select(UserAuthState.isLoginInProgress)
  loginInProgress$: Observable<boolean>;

  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;

  loginFrom: FormGroup;
  forgotPasswordForm: FormGroup;
  showPasswordSelected: boolean;
  year: number;
  isForgotForm = false;
  forgotIsProgress: boolean;
  loginUrl: string;
  reCaptchaElement = null;
  reCaptchaSiteKeyV2 = environment.reCaptchaSiteKeyV2;
  isBcuLogin: boolean;
  isCardiffLogin: boolean;
  isCardiffMetLogin: boolean;

  newVersionEnabled = VersionHelper.newVersionEnabled();
  showMaintenanceOverlay: boolean

  private organization: Organization;
  private organizationDataSubscription: Subscription;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    private cdr: ChangeDetectorRef,
    private captchaService: ReCaptchaV3Service,
    private captchaScriptService: ScriptService,
    private maintenanceService: MaintenanceService,
  ) {
    this.showMaintenanceOverlay = this.maintenanceService.showMaintenanceMessage();
  }

  ngOnInit() {

    this.organizationDataSubscription = this.organizationData$.pipe(
      filter(data => !!data))
      .subscribe(data => {
        this.organization = data;
        this.isBcuLogin = this.isBcuPage(this.organization?.domain);
        this.isCardiffLogin = this.isCardiffPage(this.organization?.domain);
        this.isCardiffMetLogin = this.isCardiffMetPage(this.organization?.domain);
      });

    this.year = (new Date()).getFullYear();
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
    this.loginFrom = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
    this.loginUrl = window.location.origin + '/signin';

    const userEmail = this.route.snapshot.queryParamMap.get('email');
    if (userEmail) {
      this.forgotPasswordForm.controls['email'].setValue(userEmail);
      this.loginFrom.controls['email'].setValue(userEmail);
    }
  }

  ngOnDestroy() {
    this.organizationDataSubscription?.unsubscribe();
  }

  onLoginUser() {
    if (this.loginFrom.valid) {
      this.store.dispatch(new TriggerFolioUserLogin(
        this.loginFrom.get('email').value?.trim(),
        this.loginFrom.get('password').value?.trim(),
        this.organization._id
      ));
    }
  }

  onForgotSubmit(captchaResponseV2: string) {
    if (this.forgotPasswordForm.valid) {
      this.captchaScriptService.cleanup();
      this.forgotIsProgress = true;
      const email = this.forgotPasswordForm.get('email').value?.trim();
      this.captchaService.execute(environment.reCaptchaSiteKeyV3, 'forgotPassword', (captchaResponseV3: string) => {
        this.accountService.resetPasswordByEmail(email, captchaResponseV3, captchaResponseV2)
          .subscribe(({ isSuccess, error }) => {
            this.forgotIsProgress = false;
            if (isSuccess) {
              SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'resetPasswordInstructionSend');
            } else {
              SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
            }
          });
      });
    }
  }

  onBackClicked() {
    this.isForgotForm = false;
  }

  onDescriptionClick(event: MouseEvent) {
    event.preventDefault();
    const target = event.target as HTMLElement;
    if (target.closest('.f_forgot') !== null) {
      this.onForgotPassword();
    } else if (target.closest('.f_saml') !== null) {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, 'signin');
    } else if (target.closest('.f_singin') !== null) {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.route, 'signin');
    }
  }

  onForgotPassword() {
    this.isForgotForm = true;
  }

  resetCaptcha(): void {
    this.cdr.detectChanges();
  }

  proceedIfValid(captchaResponseV2: string) {
    this.onForgotSubmit(captchaResponseV2);
    setTimeout(() => {
      this.captchaScriptService.cleanup();
      this.captchaElem.resetCaptcha();
    }, 1500);
  }

  sendRecoveryRequest() {
    if (this.forgotPasswordForm.valid) {
      this.captchaElem.execute();
    }
  }

  private isBcuPage(domain: string) {
    if (!domain) {
      return false;
    }
    return [
      'bcu.yuna.dev.potential.ly',
      'bcu.potential.ly',
    ].includes(domain);
  }


  private isCardiffPage(domain: string) {
    if (!domain) {
      return false;
    }
    return [
      'cardiff.yuna.dev.potential.ly',
      'cardiff.potential.ly',
    ].includes(domain);
  }

  private isCardiffMetPage(domain: string) {
    if (!domain) {
      return false;
    }
    return [
      'cardiffmet.yuna.dev.potential.ly',
      'cardiffmet.potential.ly'
    ].includes(domain);
  }
}
