<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div class="alert alert-{{type}}">
  <div class="alert-content">
    <div tabindex="0" class="alert-message small" [innerHTML]="message" (click)="onActionClicked()" (keydown.enter)="onActionClicked()"></div>
    <button
      mat-icon-button
      type="button"
      (click)="onCloseClicked()"
      class="close-alert"
      [attr.aria-label]="('translations.global.ariaLabels.close' | transloco)">
      <mat-icon aria-hidden="true">close</mat-icon>
    </button>
  </div>
</div>
