/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { Organization } from '@app/app/shared/models';

@Injectable()
export class PageTitleService {
  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;
  private organizationName: string;
  private titles: string[] = [];

  constructor(private titleService: Title) {
   const organizationDataSubscription = this.organizationData$.subscribe(data => {
      if (data) {
        this.organizationName = data.name;
        this.refreshTitle();
        organizationDataSubscription.unsubscribe();
      }
    });
  }

  setTitle(newTitle: string) {
    this.titles = [newTitle];
    this.refreshTitle();
  }

  setOrganizationTitle() {
    this.titles = [];
    this.refreshTitle();
  }

  appendTitle(appendedTitle: string, maxDepth: number) {
    if (maxDepth < 1) {
      maxDepth = 1;
    }

    if (this.titles.length >= maxDepth) {
      this.titles.length = maxDepth - 1;
    }

    this.titles.push(appendedTitle);
    this.refreshTitle();
  }

  refreshTitle(newOrganizationName?: string) {
    if (newOrganizationName) {
      this.organizationName = newOrganizationName;
    }

    let newTitle = this.organizationName;

    for (const title of this.titles) {
      newTitle += ' | ' + title;
    }

    this.titleService.setTitle(newTitle);
  }
}
