<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
-->
<button mat-stroked-button
        class="filter-btn"
        [matMenuTriggerFor]="contextMenu"
        [class.expanded]="expanded"
        (menuOpened)="expand()"
        (menuClosed)="expand()">
  <div class="filter-btn-content">
    <svg class="svg" role="img" [attr.aria-label]="('translations.global.ariaLabels.icons.filterList' | transloco)">
      <use ogSvgUnify="assets/sprite/sprite.svg#filter-list"></use>
    </svg>
    <span class="small bold">
      {{ 'translations.global.button.filter' | transloco }}
    </span>
  </div>
</button>
<mat-menu #contextMenu xPosition="before">
  <ng-template matMenuContent>
    <button
      class="filter-item"
      *ngFor="let label of labels"
      mat-menu-item
      tabindex="0"
      [attr.aria-label]="label.value"
      (click)="selectFilter(label.type)">
      {{ label.value }}
    </button>
  </ng-template>
</mat-menu>
